import React, { useEffect, useState } from 'react';
import FileDropZone from '../../../../custom-components/file-drop-zone/FileDropZone';
import SearchSelect from '../../../../custom-components/search-select/SearchSelect';
import Modal from 'react-bootstrap/Modal';
import IntentService from '../../intent-service/IntentService';
import MessageBanner from "../../../../custom-components/MessageBanner/MessageBanner";
import Spinner from "../../../../custom-components/spinner/Spinner";
import Joyride, { CallBackProps, STATUS, Step } from 'react-joyride';
import CheckUncheck from '../../../dialog-module/dialog-components/DialogScreen/Nodes/ActionNode/Checklist';
import ExpressionService from "../../expression-service/ExpressionService";
import SkillService from "../../../skill-module/skill-services/SkillService";
import EntityService from '../../../entity-module/entity-services/EntityService';
import isAlphanumeric from 'validator/lib/isAlphanumeric';
import InputSelect from '../../../../custom-components/CreatableSelect/CreatableSelect';

export const IntentView = () => {
    let expressionId = 0
    const getExpressionId = () => expressionId++
    const [skillList, setSkillList] = useState([{ label: 'Create Skill', value: "newSkill" }]);
    const [skillObjList, setSkillObjList] = useState([]);
    const [showCreateIntentScreen, setShowCreateIntentScreen] = useState(false);
    const [showEditIntentScreen, setShowEditIntentScreen] = useState(false);
    const [showDeleteIntentScreen, setShowDeleteIntentScreen] = useState(false);
    const [showCreateExpressionScreen, setShowCreateExpressionScreen] = useState(false);
    const [showDeleteExpressionScreen, setShowDeleteExpressionScreen] = useState(false);
    const [showEditEntity, setShowEditEntity] = useState(false);
    const [selectedTextForEditEntity, setSelectedTextForEditEntity] = useState('');
    const [selectedEntityObject, setSelectedEntityObject] = useState({});
    const [selectedSkill, setSelectedSkill] = useState({ label: 'Select a Skill', value: "default" })
    const [selectedSkillObj, setSelectedSkillObj] = useState(undefined)
    const [newSkillModal, setNewSkillModal] = useState(false)
    const [showMessageBanner, setShowMessageBanner] = useState(false);
    const [bannerMessage, setBannerMessage] = useState('');
    const [bannerMessageType, setBannerMessageType] = useState('success');
    const [isDataLoading, setDataLoading] = useState(false);
    const [showPageTour, setShowPageTour] = useState(false);
    const [expressions, setExpressions] = useState([])
    const [expression, setExpression] = useState('')
    const [fileUploadData, setFileUploadData] = useState([]);
    const [checkedAll, setCheckedAll] = useState(false)
    const [intentObjList, setIntentObjList] = useState([]);
    const [selectedIntentId, setSelectedIntentId] = useState('');
    const [intentName, setIntentName] = useState('');
    const [intentDescription, setIntentDescription] = useState('');
    const [showGuidance, setShowGuidance] = useState(false)
    const [expressionObjList, setExpressionObjList] = useState([]);
    const [selectedExpressionId, setSelectedExpressionId] = useState('');
    const [expressionVal, setExpressionVal] = useState('');
    const [skillAction, setSkillAction] = useState([{ name: "", description: "" }]);
    const [skillSubmitBtnEnable, setSkillSubmitBtnEnable] = useState(false);
    const [layoutType, setLayoutType] = useState(window.innerWidth > 1000 ? 'horizontal' : 'verticle');
    let [tab_selected, switchUploadTab] = useState('form-upload');
    const [isIntentSelectedInMobileView, setIsIntentSelectedInMobileView] = useState(false);
    const [entities, setEntities] = useState([]);
    const [systemEntities, setSystemEntities] = useState([]);
    const [selectedEntitie, setSelectedEntitie] = useState()
    const [roles, setRoles] = useState([]);
    const [deleteIntentList, setDeleteIntentList] = useState([])
    const [deleteExpressionList, setDeleteExpressionList] = useState([])
    const [selectedExpressionsList, setSelectedExpressionsList] = useState([])
    const [selectedRole, setSelectedRole] = useState(roles[0])
    const [intentIndex, setIntent] = useState(undefined);
    const [intentData, setIntentField] = useState({ name: "", description: "" });
    const [submitBtnEnable, setSubmitBtnEnable] = useState(false)
    const [submitBtnDeleteEnable, setSubmitBtnDeleteEnable] = useState(false)
    const [submitBtnDeleteExpEnable, setSubmitBtnDeleteExpEnable] = useState(false)
    const [dataValidationErrorMessage, setDataValidationErrorMessage] = useState('');
    const [showValidationErrorMessage, setShowValidationErrorMessage] = useState(false);
    const [showParaphraseModal, setShowParaphraseModal] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [counter, setCounter] = useState(true)
    const [selectedNtt, setSelectedNtt] = useState()
    const [selectedRol, setSelectedRol] = useState()
    const [trained, setTrained] = useState(true)

    const minWindowWidth = 1400;
    const handleResize = (() => {
        setLayoutType(window.innerWidth > 1000 ? 'horizontal' : 'verticle');
        setWindowWidth(window.innerWidth);
    })
    const [mobileViewHeightOffset, setMobileViewOffset] = useState('100px');
    useEffect(() => {
        let alertDiv = document.querySelector(".alert");
        if (alertDiv) {
            let divHeight = String(alertDiv.clientHeight + 80);
            setMobileViewOffset(divHeight + "px");
        }

        else {
            setMobileViewOffset('100px');
        }
    })

    useEffect(() => {
        setShowPageTour(false);
        if (isDataLoading === false && intentObjList && intentObjList.length === 0) {
            setShowPageTour(true);
        }
    }, [intentObjList, isDataLoading])

    const [{ run, steps }, setState] = useState({
        run: true,
        steps: [
            {
                content: <h6>Welcome to <b>Intents screen</b>. Here, you view/create a Intents. <br></br><br></br>An Intent is a mapping between what the user asks for and the required action to be taken by the bot's logic.</h6>,
                locale: { skip: <span aria-label="skip">Skip</span> },
                placement: 'center',
                target: '.pageTour1',
            },
            {
                content: <h6>Here, you can select from a list of skills and We can also create a new skill by entering the skill name and description.</h6>,
                floaterProps: {
                    disableAnimation: true,
                },
                spotlightPadding: 20,
                target: '.pageTour2',
            },
            {
                content: <h6>By clicking the (+) icon you can add Intent.</h6>,
                floaterProps: {
                    disableAnimation: true,
                },
                spotlightPadding: 20,
                target: '.pageTour3',
            },
            {
                content: <h6>By clicking the (+) icon you can add Expressions.</h6>,
                floaterProps: {
                    disableAnimation: true,
                },
                spotlightPadding: 20,
                target: '.pageTour4',
            },
            {
                content: <h6>Here you can delete, edit and check the Intent Expressions</h6>,
                floaterProps: {
                    disableAnimation: true,
                },
                spotlightPadding: 20,
                target: '.pageTour5',
            }
        ]
    });

    const handleJoyrideCallback = (data) => {
        const { status, type } = data;
        const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

        if (finishedStatuses.includes(status)) {
            setState({ run: false });
        }
    };

    useEffect(() => {
        let expressionsCopy = [...expressions];
        for (let element of fileUploadData) {
            if (element["text"] && element["text"].length > 0) {
                expressionsCopy.push({
                    "id": getExpressionId(),
                    "name": element["text"],
                    "isChecked": false,
                    "annotations": element["annotations"]
                });
            }

        }
        setExpressions(expressionsCopy);
    }, [fileUploadData])

    const displayBannerMessage = (messageType, message) => {
        setShowMessageBanner(true);
        setBannerMessageType(messageType);
        setBannerMessage(message);
    }
    const getEntityData = (skillId) => {
        setDataLoading(true);
        EntityService.listEntity(skillId).then(res => res.json()).then((res) => {
            if (res['status'] == 'success') {
                if (res.entities.length > 0) {
                    var vals = res.entities.map((data) => {
                        return (
                            { "label": data.name, "value": data._id }
                        )
                    })
                    var systemEntite = res.system_entities.map((data) => {
                        let temp = data.sys_entity_name + `<p style="color:grey;font-size:0.6em;margin-top:2px">${data.examples}</p>`;
                        // console.log(temp)
                        return (

                            {
                                'value': data.sys_entity_name, 'label': <span dangerouslySetInnerHTML={{ __html: temp }} />
                            }
                        )
                    })
                    var roless = res.entities.map((data) => {
                        return (
                            { "roles": data.roles }
                        )
                    })
                    setSystemEntities(systemEntite);
                    setEntities(vals)
                    setSelectedEntitie(vals[0]);
                    setRoles(() => {
                        let roles = [];
                        for (let r of roless[0].roles) {
                            roles.push({
                                "label": r,
                                "value": r
                            })
                        }
                        return roles;
                    });
                    setShowMessageBanner(false)
                    setDataLoading(false);
                }
            }
            else {
                setBannerMessageType('error');
                if (res['details']) {
                    setBannerMessage(res['details']);
                } else {
                    setBannerMessage('Failed to retrieve Entity list.');
                }
                setShowMessageBanner(true);
                setDataLoading(false);
            }
        }).catch(err => {
            setBannerMessageType('error');
            setBannerMessage('Failed to retrieve Entity list.');
            setShowMessageBanner(true);
            setDataLoading(false);
        })
    }


    const getSkillList = (index) => {
        setDataLoading(true);
        setDeleteExpressionList([])
        setSelectedExpressionsList([])
        setDeleteIntentList([])
        SkillService.listSkills().then(res => res.json()).then(res => {
            setDataLoading(false);
            if (res['status'] == 'success') {
                setDataLoading(false);
                let skillList = res["skills"];
                if (skillList.length > 0) {
                    setSkillObjList(res.skills);
                    var vals = res.skills.map((data) => {
                        return (
                            { "label": data.name, "value": data._id }
                        )
                    })
                    vals.unshift({ label: 'Create Skill', value: 'newSkill' })
                    setSkillList(vals)
                    if (localStorage.getItem("skill_name") && localStorage.getItem("skill_value")) {
                        setSelectedSkill({ 'value': localStorage.getItem("skill_value"), 'label': localStorage.getItem("skill_name") });
                        getEntityData(localStorage.getItem("skill_value"));
                        fetchIntentList({ 'value': localStorage.getItem("skill_value"), 'label': localStorage.getItem("skill_name") });
                        setSelectedSkillObj({ 'value': localStorage.getItem("skill_value"), 'label': localStorage.getItem("skill_name") });
                    }
                    else {
                        setSelectedSkill(vals[index]);
                        getEntityData(vals[index].value);
                        fetchIntentList(vals[index]);
                        setSelectedSkillObj(res.skills[index - 1]);
                    }
                    // console.log("Skill:\n" + JSON.stringify(res.skills[index - 1]));
                    // setSelectedSkillObj(res.skills[index - 1]);
                    // getEntityData(vals[index].value);
                    // fetchIntentList(vals[index]);
                    setShowGuidance(false);
                }
                else {
                    setSelectedSkill({ label: 'Select a Skill', value: 'default' })
                    setDataLoading(false);
                    setShowGuidance(true);
                }
            }
            else {
                setDataLoading(false);
                let messageType = "error";
                let message = "Failed to fetch skill list";
                displayBannerMessage(messageType, message);
            }
        }).catch(err => {
            console.log(err);
            setDataLoading(false);
            let messageType = "error";
            let message = "Failed to fetch skill list";
            displayBannerMessage(messageType, message);
        })
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        getSkillList(1);
    }, [])

    const isReadOnlySkill = () => {
        let readOnly = false;
        if (selectedSkillObj == undefined) {
            readOnly = false;
        } else if (selectedSkillObj.read_only && selectedSkillObj.read_only == true) {
            readOnly = true;
        }
        return readOnly;
    }

    const fetchExpressionsByIntentId = (skillId, intentId) => {
        setDataLoading(true);
        setExpressionObjList([]);
        setDeleteExpressionList([])
        setSelectedExpressionsList([])
        ExpressionService.getExpressions(intentId, skillId.value).then(res => res.json()).then(res => {
            if (res["status"] == 'success') {

                let expressionListResponse = res["expressions"];
                if (expressionListResponse.length > 0) {
                    setExpressionObjList(expressionListResponse);
                    setDataLoading(false);
                    //setShowPageTour(true)
                }
                else {
                    setExpressionObjList([]);
                    setDataLoading(false);

                }
            }
            else {
                setBannerMessageType('error');
                setBannerMessage(res['details']);
                setShowMessageBanner(true);
                setDataLoading(false);
            }
        }).catch(err => {
            setBannerMessageType('error');
            setBannerMessage('Failed to load the expressions. Please try later.');
            setShowMessageBanner(true);
            setDataLoading(false);
        })
    }

    const addExpression = (skillId) => {
        setShowCreateExpressionScreen(false);
        setDataLoading(true);
        let expressionObjs = [];
        for (let exp of expressions) {
            expressionObjs.push({
                "intent_id": selectedIntentId,
                "value": exp["name"],
                "entities": exp["annotations"]
            }
            )
        }
        ExpressionService.addExpression(expressionObjs, skillId.value, selectedIntentId).then(res => res.json()).then(res => {
            if (res['status'] == 'success') {
                setExpressionVal('');
                setExpressions([]);

                fetchExpressionsByIntentId(skillId, selectedIntentId);
                setBannerMessageType('success');
                setBannerMessage('Successfully added Expression.');
                setShowMessageBanner(true);
                setDataLoading(false);
                setShowCreateExpressionScreen(false);
            }
            else {
                setBannerMessageType('error');
                setBannerMessage(res['details']);
                setShowMessageBanner(true);
                setDataLoading(false);
            }

        }).catch(err => {
            setBannerMessageType('error');
            setBannerMessage('Failed to add expressions. Please try later!');
            setShowMessageBanner(true);
            setDataLoading(false);
        })
    }

    const deleteExpression = (skillId) => {
        setShowDeleteExpressionScreen(false);
        setDataLoading(true);
        setDeleteExpressionList([])
        setSelectedExpressionsList([])
        setDeleteIntentList([])
        ExpressionService.deleteExpression(selectedExpressionId, selectedIntentId, skillId.value).then(res => res.json()).then(res => {
            if (res['status'] == 'success') {
                const updatedExpObjList = expressionObjList.filter(expressionObj => expressionObj["_id"] != selectedExpressionId);

                setDataLoading(false);
                setBannerMessageType('success');
                setBannerMessage('Successfully deleted Expression.');
                setShowMessageBanner(true);
                setShowCreateExpressionScreen(false);
                setExpressionObjList(updatedExpObjList);
                clearSelectedExpressionValues();
                fetchExpressionsByIntentId(skillId, selectedIntentId)
            }
            else {
                setDataLoading(false);
                setBannerMessageType('error');
                setBannerMessage(res['details']);
                setShowMessageBanner(true);
            }
        }).catch(err => {
            setDataLoading(false);
            setBannerMessageType('error');
            setBannerMessage('Failed to delete the Expression. Please try later.');
            setShowMessageBanner(true);
        })
    }
    const bulkDeleteExpression = (skillId) => {
        setShowDeleteExpressionScreen(false);
        setDataLoading(true);
        let payload = {
            "expression_ids": deleteExpressionList
        }
        ExpressionService.deleteExpressions(payload, selectedIntentId, skillId.value).then(res => res.json()).then(res => {
            if (res['status'] == 'success') {
                const updatedExpObjList = expressionObjList.filter(expressionObj => expressionObj["_id"] != selectedExpressionId);

                setDataLoading(false);
                setBannerMessageType('success');
                setBannerMessage('Successfully deleted Expressions.');
                setShowMessageBanner(true);
                setShowCreateExpressionScreen(false);
                setExpressionObjList(updatedExpObjList);
                clearSelectedExpressionValues();
                setDeleteExpressionList([])
                setSelectedExpressionsList([])
                fetchExpressionsByIntentId(skillId, selectedIntentId)
            }
            else {
                setDataLoading(false);
                setBannerMessageType('error');
                setBannerMessage(res['details']);
                setShowMessageBanner(true);
                setDeleteExpressionList([])
                setSelectedExpressionsList([])
            }
        }).catch(err => {
            setDataLoading(false);
            setBannerMessageType('error');
            setBannerMessage('Failed to delete the Expressions. Please try later.');
            setShowMessageBanner(true);
            setDeleteExpressionList([])
            setSelectedExpressionsList([])
        })
    }
    const inputHandlerChange = (event, type) => {
        setShowValidationErrorMessage(false);
        let newdata = intentData;
        newdata[type] = event.target.value
        setIntentField(newdata)
        const empty = hasEmpty(newdata);
        let valid;
        if (!empty) {
            valid = validateIntentCreationForm(type, newdata)
        }
        else {
            valid = validateIntentCreationForm("all", newdata)
        }

        const result = empty && valid
        console.log("create inent is valid", result);
        setSubmitBtnEnable(result);
    }

    const inputHandlerEditChange = (event, type) => {
        setShowValidationErrorMessage(false);
        let newdata = intentData;
        newdata[type] = event.target.value

        if (type == 'name') {
            setIntentName(event.target.value)
        }
        else {
            setIntentDescription(event.target.value)
        }
        setIntentField(newdata)
        const empty = hasEmpty(newdata);
        let valid = validateIntentCreationForm(type, newdata)
        const result = empty && valid
        setSubmitBtnEnable(result);
    }
    const skillInputHandlerChange = (event, type) => {
        setShowValidationErrorMessage(false);
        let newdata = skillAction[0];

        newdata[type] = event.target.value
        setSkillAction([newdata])
        const empty = hasEmpty(newdata);
        let valid;
        if (empty) {
            valid = validateSkillCreationForm(type, newdata);
        }
        else {
            valid = validateSkillCreationForm("all", newdata);
        }

        const result = empty && valid;
        setSkillSubmitBtnEnable(result);
    }

    const hasEmpty = (element) => {
        return (
            (element.name != "") &&
            (element.description != "")
        );
    };

    const validateAlphaNumericString = (data) => {
        let sanitisedData = data.replaceAll(/[@.,_'"&\s]/g, "");
        return isAlphanumeric(sanitisedData);
    }

    const validateSkillCreationForm = (key, skillData) => {

        if (key == "name" || key == "all") {
            if (skillData.name === '' || skillData.name.length < 3) {
                setShowValidationErrorMessage(true);
                setDataValidationErrorMessage('Skill name should be of minimum 3 characters.');
                return false;

            }

            if (!isAlphanumeric(skillData.name, 'en-US', { "ignore": "_" })) {

                setShowValidationErrorMessage(true);
                setDataValidationErrorMessage('Special characters other than _ is not allowed in skill name.');
                return false;
            }
        }
        else if (key == "description" || key == "all") {
            if (skillData.description === '' || skillData.description.length < 20) {

                setShowValidationErrorMessage(true);
                setDataValidationErrorMessage('Skill description should be greater than 20 characters.');
                return false;

            }
            if (!validateAlphaNumericString(skillData.description)) {

                setShowValidationErrorMessage(true);
                setDataValidationErrorMessage('Special characters other than _ @ . " \' & is not allowed in skill description.');
                return false;
            }
        }
        return true;
    }
    const validateIntentCreationForm = (key, intent) => {
        //console.log(key)
        if (intent) {
            //console.log("537")
            if ((key === "name" || key === "all") && (intent.name === '' || intent.name.length < 2)) {

                setShowValidationErrorMessage(true);
                setDataValidationErrorMessage('Intent name should be greater than 2 characters.');
                return false;

            }
            if ((key === "name" || key === "all") && intent.name === '__') {
                setShowValidationErrorMessage(true);
                setDataValidationErrorMessage('Intent name can not be __');
                return false;
            }
            if ((key === "name" || key === "all") && intent.name[0] === '_') {
                setShowValidationErrorMessage(true);
                setDataValidationErrorMessage('Intent name can not start with _ character.');
                return false;
            }
            if ((key === "name" || key === "all") && intent.name.length > 50) {

                setShowValidationErrorMessage(true);
                setDataValidationErrorMessage('Intent name can not exceed more than 50 characters.');
                return false;

            }
            if ((key === "name" || key === "all") && !isAlphanumeric(intent.name, 'en-US', { "ignore": "_" })) {

                setShowValidationErrorMessage(true);
                setDataValidationErrorMessage('Special characters other than _ is not allowed in intent name.');
                return false;
            }
            if ((key === "description" || key === "all") && (intent.description === '' || intent.description.length < 20)) {
                console.log(568)
                setShowValidationErrorMessage(true);
                setDataValidationErrorMessage('Intent description should be greater than 20 characters.');
                return false;

            }
            if ((key === "description" || key === "all") && !validateAlphaNumericString(intent.description)) {
                console.log(575)
                setShowValidationErrorMessage(true);
                setDataValidationErrorMessage('Special characters other than _ @ . " \' & is not allowed in intent description.');
                return false;
            }
            if ((key === "description" || key === "all") && intent.description.length > 200) {
                console.log(581)
                setShowValidationErrorMessage(true);
                setDataValidationErrorMessage('Intent description can not exceed more than 200 characters.');
                return false;
            }
        }

        return true;
    }

    const clearSkillAndDesc = () => {
        setSkillSubmitBtnEnable(false)
        setSkillAction([{ name: "", description: "" }])
    }

    const intentChange = (index, intentObj) => {
        console.log(intentObj)
        setShowMessageBanner(false);
        setShowDeleteExpressionScreen(false);
        setShowEditEntity(false);
        setIntent(index);
        setSelectedIntentId(intentObj['_id']);
        fetchExpressionsByIntentId(selectedSkill, intentObj['_id']);
    }

    const clearSelectedIntentValues = () => {
        // setSelectedIntentId('');
        setIntentName('');
        setIntentDescription('');
    }

    const clearSelectedExpressionValues = () => {
        setSelectedExpressionId('');
        setExpressionVal('');
        setExpressions([])
        setExpression('')
    }

    const initiateIntentEdit = (intentObj) => {
        if (isReadOnlySkill())
            return;
        setShowEditIntentScreen(true);
        setSelectedIntentId(intentObj['_id']);
        setIntentName(intentObj['name']);
        setIntentDescription(intentObj['description']);
        setIntentField({ ...intentObj })
    }

    const initiateIntentDelete = (intentObj) => {
        if (isReadOnlySkill())
            return;
        setShowDeleteIntentScreen(true);
        setSelectedIntentId(intentObj['_id']);
        setIntentName(intentObj['name']);
    }

    const initiateExpressionDelete = (expressionObj) => {
        if (isReadOnlySkill())
            return;
        setShowDeleteExpressionScreen(true);
        setSelectedExpressionId(expressionObj['_id']);
        setExpressionVal(expressionObj['value']);
    }

    const changeSkillSelection = (data) => {
        if (data.value !== "newSkill") {
            setDataLoading(true);
            setShowMessageBanner(false);
            setSelectedSkill(data);
            localStorage.setItem("skill_name", data.label)
            localStorage.setItem("skill_value", data.value)
            localStorage.removeItem('rule_name')
            localStorage.removeItem('rule_value')
            let selectedSkill = skillObjList.find(skill => skill._id === data.value);
            console.log("selected Skill\n" + JSON.stringify(selectedSkill));
            setSelectedSkillObj(selectedSkill);
            getEntityData(data.value);
            fetchIntentList(data);
        }

    }

    const findSkills = (data) => {
        if (data.value == 'newSkill')
            return true

        return false
    }

    const fetchIntentList = (skillId) => {

        setIntentObjList([]);
        setExpressionObjList([]);
        setDataLoading(true);
        IntentService.listIntents(skillId.value).then(res => res.json()).then(res => {
            if (res["status"] == 'success') {
                let intentListResponse = res["intents"];
                setIntentObjList(intentListResponse);
                setDataLoading(false);

                if (intentListResponse.length > 0) {
                    if (localStorage.getItem('intent_name') && localStorage.getItem('intent_value')) {
                        let i = intentListResponse.findIndex(data => { return data._id == localStorage.getItem('intent_value') }
                        )
                        let index = i != -1 ? i : 0;
                        console.log(index)
                        setIntent(index);
                        setSelectedIntentId(intentListResponse[index]._id)
                        fetchExpressionsByIntentId(skillId, intentListResponse[index]._id);
                    } else {
                        setIntent(0);
                        setSelectedIntentId(intentListResponse[0]._id)
                        fetchExpressionsByIntentId(skillId, intentListResponse[0]._id);

                    }
                }
            }
            else {

                setDataLoading(false);
                setBannerMessageType('error');
                setBannerMessage(res['details']);
                setShowMessageBanner(true);

            }
        }).catch(err => {
            setDataLoading(false);
            setBannerMessageType('error');
            setBannerMessage("Failed to fetch the Intents!");
            setShowMessageBanner(true);

        })
    }
    const addIntent = (skillId) => {
        setDataLoading(true);
        let payload = [{
            "name": intentData.name.trim(),
            "description": intentData.description.trim()
        }]

        IntentService.addIntent(payload, skillId.value).then(res => res.json()).then(res => {
            if (res['status'] == "success") {
                setDataLoading(false);
                setBannerMessageType('success');
                setBannerMessage("A new Intent has been successfully created!");
                setShowMessageBanner(true);
                setShowCreateIntentScreen(false);
                fetchIntentList(skillId);
                clearSelectedIntentValues();
                if(intentData.name && res['intent_ids'] && res['intent_ids'][0]){
                    localStorage.setItem('intent_name', intentData.name)
                    localStorage.setItem('intent_value', res['intent_ids'][0])
                }

            }
            else {
                setDataLoading(false);
                setBannerMessageType('error');
                setBannerMessage("Failed to create an Intent! Please try again.");
                setShowMessageBanner(true);
                setShowCreateIntentScreen(false);
            }

        }).catch(err => {
            setDataLoading(false);
            setBannerMessageType('error');
            setBannerMessage("Failed to create an Intent! Please try again.");
            setShowMessageBanner(true);
            setShowCreateIntentScreen(false);
        })

    }

    const updateIntent = (skillId) => {
        setShowEditIntentScreen(false);
        setDataLoading(true);
        let intentObj = {
            "_id": selectedIntentId,
            "name": intentName,
            "description": intentDescription
        }

        IntentService.updateIntent(intentObj, skillId.value).then(res => res.json()).then(res => {
            if (res['status'] == 'success') {

                // updateIntentList(intentObj);
                fetchIntentList(skillId);
                clearSelectedIntentValues();
                setDataLoading(false);
                setBannerMessageType('success');
                setBannerMessage("Succesfully edited the Intent!");
                setShowMessageBanner(true);
                setShowEditIntentScreen(false);
            }
            else {
                setDataLoading(false);
                setBannerMessageType('error');
                setBannerMessage(res['details']);
                setShowMessageBanner(true);
                setShowEditIntentScreen(false);
            }

        }).catch(err => {
            setDataLoading(false);
            setBannerMessageType('error');
            setBannerMessage("Failed to edit an Intent! Please try again.");
            setShowMessageBanner(true);
            setShowEditIntentScreen(false);
        })
    }

    const deleteIntent = (skillId) => {
        setShowDeleteIntentScreen(false);
        setDataLoading(true);

        IntentService.deleteIntent(selectedIntentId, skillId.value).then(res => res.json()).then(res => {
            if (res['status'] == 'success') {
                setExpressionObjList([]);
                clearSelectedIntentValues();
                fetchIntentList(skillId);
                setDataLoading(false);
                setBannerMessageType('success');
                setBannerMessage("Succesfully deleted the Intent!");
                setShowMessageBanner(true);

            }
            else {
                setDataLoading(false);
                setBannerMessageType('error');
                setBannerMessage(res['details']);
                setShowMessageBanner(true);
            }
        }).catch(err => {
            setDataLoading(false);
            setBannerMessageType('error');
            setBannerMessage("Failed to delete the intent. Please try later.");
            setShowMessageBanner(true);
        })
    }
    const bulkDeleteIntents = (skillId) => {
        setShowDeleteIntentScreen(false);
        setDataLoading(true);
        let payload = {
            "intent_ids": deleteIntentList
        }

        IntentService.deleteIntents(skillId.value, payload).then(res => res.json()).then(res => {
            if (res['status'] == 'success') {
                setExpressionObjList([]);
                clearSelectedIntentValues();
                fetchIntentList(skillId);
                setDataLoading(false);
                setBannerMessageType('success');
                setBannerMessage("Succesfully deleted the Intents!");
                setShowMessageBanner(true);
                setDeleteIntentList([])

            }
            else {
                setDataLoading(false);
                setBannerMessageType('error');
                setBannerMessage(res['details']);
                setShowMessageBanner(true);
                setDeleteIntentList([])
            }
        }).catch(err => {
            setDataLoading(false);
            setBannerMessageType('error');
            setBannerMessage("Failed to delete the intents. Please try later.");
            setShowMessageBanner(true);
            setDeleteIntentList([])
        })
    }

    const onWordSelected = (index, expression, expressionId, intentid) => {
        let selectedObject = window.getSelection();
        let expressionValue = selectedObject.toString();
        var offsetTextIndex = 0; var startIndex = 0; var endIndex = 0;

        if (showEditEntity === false) {
            setSelectedTextForEditEntity(expressionValue);
            var startingIndex = window.getSelection().anchorOffset;
            var endingIndex = window.getSelection().focusOffset;

            console.log("startingIndex", startingIndex);
            console.log("endingIndex", endingIndex);

            if (startingIndex > endingIndex) {
                var tempIndex = startingIndex;
                startingIndex = endingIndex;
                endingIndex = tempIndex;
            }
            endingIndex--;
            if (expression.length == 0) {
                startIndex = startingIndex;
                endIndex = endingIndex;
            } else if (selectedObject['anchorNode'].parentElement.localName == "div") {
                var offsetText = selectedObject['anchorNode'].textContent;
                var offsetTextIndex = expression.indexOf(offsetText);
                // var selectedTextIndex = offsetText.indexOf(expressionValue);
                startIndex = offsetTextIndex + startingIndex;
                endIndex = startIndex + expressionValue.length - 1;
                setSelectedNtt();
                setSelectedRol();
                setTrained(true)
            } else if (selectedObject['anchorNode'].parentElement.localName == "span") {
                // var indexAttrVal = selectedObject['anchorNode'].textContent;
                // var offsetTextIndex = expression.indexOf(indexAttrVal);
                // var selectedTextIndex = indexAttrVal.indexOf(expressionValue);
                // // var selectedTextIndex = indexAttrVal.indexOf(expressionValue);
                // startIndex = offsetTextIndex + startingIndex;
                // endIndex = startIndex + expressionValue.length - 1;
                startIndex = Number(selectedObject['anchorNode'].parentElement.attributes['startPosition'].value);
                endIndex = Number(selectedObject['anchorNode'].parentElement.attributes['endPosition'].value);
                let selectedEntityId = selectedObject['anchorNode'].parentElement.attributes['entityId'].value;
                if (selectedEntityId.match("sys_")) {
                    console.log("selectedEntityId", selectedEntityId);
                } else {
                    setSelectedNtt(selectedEntityId);
                }


                let selectedRole = ""
                console.log("selectedObject['anchorNode'].parentElement.attributes['rolename']", selectedObject['anchorNode'].parentElement.attributes['rolename']);
                if (selectedObject['anchorNode'].parentElement.attributes['rolename'].value != undefined && selectedObject['anchorNode'].parentElement.attributes['rolename'].value != "") {
                    selectedRole = selectedObject['anchorNode'].parentElement.attributes['rolename'].value;
                    setRuleShow(true)
                } else {
                    setRuleShow(false)
                }
                setSelectedRol(selectedRole);
                setTrained(false)
            }
            let trimval = expressionValue.trim();
            if (expressionValue && expression && expression.length > 0 && expressionValue.length > 0) {
                if (startIndex >= 0 && endIndex > 0 && endIndex < expression.length && trimval.length > 0) {
                    setSelectedEntityObject({
                        "index": index,
                        "startIndex": startIndex,
                        "endIndex": endIndex,
                        "value": expressionValue,
                        "expressionId": expressionId,
                        "intentid": intentid,
                    })
                    setShowEditEntity(true);
                }
            }
        }
    }

    const highlightEntityInExpression = (expression, entitiesList) => {
        console.log("entitiesList", entitiesList);
        let result = [];
        let i = 0, ptr = 0;
        let temp = "";
        entitiesList = entitiesList.sort((a, b) => (a.start > b.start) ? 1 : -1)
        while (i < expression.length) {
            if (ptr < entitiesList.length && entitiesList[ptr].start != undefined && entitiesList[ptr].end != undefined && i === entitiesList[ptr].start) {
                if (temp !== "") {
                    result.push(temp);
                    temp = "";
                }
                const st = entitiesList[ptr].start;
                const en = entitiesList[ptr].end;
                const rolename = entitiesList[ptr].roleName != undefined ? entitiesList[ptr].roleName : "";
                result.push(<span tabIndex={"highlight__" + (ptr + 1)} entityId={entitiesList[ptr].entityId !== undefined ? entitiesList[ptr].entityId : entitiesList[ptr].sys_entityId} rolename={rolename} startPosition={st} endPosition={en} className='selectedText' >{expression.slice(entitiesList[ptr].start, entitiesList[ptr].end + 1)}</span>);
                i = entitiesList[ptr].end + 1;
                ptr++;
            }
            else {
                temp = temp + expression[i];
                i++;
            }
        }
        if (temp !== "") {
            result.push(<>{temp}</>);
        }
        return result;
    }

    const addEntityMapping = (skillId, intentID, selectedExpressionIndex) => {

        if (selectedEntityObject !== undefined && selectedEntityObject.startIndex != undefined && selectedEntityObject.endIndex != undefined) {
            let oldEntities = expressionObjList[selectedExpressionIndex].entities;
            // if (selectedRole != undefined) {
            //     oldEntities.push({
            //         "start": selectedEntityObject.startIndex,
            //         "end": selectedEntityObject.endIndex,
            //         "value": selectedEntityObject.value,
            //         "entityId": selectedEntitie.value,
            //         "roleId": selectedRole.value
            //     })
            // } else {
            //     oldEntities.push({
            //         "start": selectedEntityObject.startIndex,
            //         "end": selectedEntityObject.endIndex,
            //         "value": selectedEntityObject.value,
            //         "entityId": selectedEntitie.value,
            //     })
            // }

            //oldEntities.sort((a, b) => (a.start > b.start) ? 1 : -1)
            let payloadData = {
                "_id": selectedEntityObject.expressionId,
                // "intent_id": selectedEntityObject.intentid,
                "value": selectedEntityObject.value,
                "start": selectedEntityObject.startIndex,
                "end": selectedEntityObject.endIndex,
                // "_id": selectedEntityObject.expressionId,
                // "intent_id": selectedEntityObject.intentid,
                // "entities": oldEntities
            }
            if (entitiesType === "Entities") {
                payloadData.entityId = selectedEntitie.value
            } else {
                payloadData.sys_entity_name = selectedEntitie.value
            }
            if (selectedRole != undefined && ruleShow != false) {
                payloadData.roleName = selectedRole.value
            }
            if (trained === false) {
                payloadData.trained = false
            }

            setShowEditEntity(false);
            setDataLoading(true);

            IntentService.addAnnotations(payloadData, skillId.value, intentID).then(res => res.json()).then(res => {
                if (res['status'] == 'success') {
                    setDataLoading(false);
                    let messageType = "success";
                    let message = "Succesfully added the annotations!";
                    displayBannerMessage(messageType, message);
                    fetchExpressionsByIntentId(skillId, intentID);
                }
                else {
                    setDataLoading(false);
                    let messageType = "error";
                    let message = res['details'];
                    displayBannerMessage(messageType, message);
                }
            }).catch(err => {
                setDataLoading(false);
                let messageType = "error";
                let message = "Failed to add the annotations. Please try later.";
                displayBannerMessage(messageType, message);
            })
        }
    }
    const processFileData = (data) => {
        let data_expressions = [];
        for (let d of data) {

            if (d.type === "text/plain") {
                data_expressions = data_expressions.concat(d["data"].split("\n"));
            }
            else {
                data_expressions = data_expressions.concat(d["data"].split("\r\n"));
            }
        }

        console.log("data_expressions", data_expressions)

        data_expressions = handleAnnotation(data_expressions);
        let correct_exp = []
        for (let exp of data_expressions) {
            if (!isAlphanumeric(exp['text'], 'en-US', { "ignore": /[,.!?'";{ }| ]/g })) {
                setShowValidationErrorMessage(true);
                setDataValidationErrorMessage('Special characters other than ,.!?\'\"; are not allowed in an expression. Please try to upload another file.');
            }
            else {
                correct_exp.push(exp)
            }
        }
        setFileUploadData(correct_exp);
    }

    const handleAnnotation = (data) => {
        let result = [];
        for (let i = 0; i < data.length; i++) {
            result.push({ "text": data[i], "annotations": [] })
            let idx = result.length;
            while (true) {
                let res = result[idx - 1]["text"].match(/{[^}]*}/);
                if (res === undefined || res === null) {
                    break;
                }

                let start = res.index;
                let end = start + res[0].length;
                let entity_value = res.input.slice(start, end).split("|")[0].slice(1);
                result[idx - 1]["text"] = res.input.slice(0, start) + entity_value + res.input.slice(end, res.input.length);
                result[idx - 1]["annotations"].push({ "start": start, "end": start + entity_value.length });
            }
        }
        return result;
    }

    const initSkillDropdown = () => {
        return <SearchSelect
            id='skillName'
            className='augmentSelect'
            defaultValue={selectedSkill}
            options={skillList}
            onValueChange={(data) => {
                if (findSkills(data)) {
                    setNewSkillModal(true)
                    clearSkillAndDesc();
                }
                else {
                    setNewSkillModal(false)
                    changeSkillSelection(data);
                    setDeleteExpressionList([])
                    setSelectedExpressionsList([])
                    setDeleteIntentList([])
                }
            }}
        />
    }

    const addSkill = () => {
        let payload = skillAction[0];
        payload["shared"] = false;
        SkillService.createSkill(payload).then(res => res.json()).then(res => {
            if (res['status'] == 'success') {
                getSkillList(skillList.length);
                setDataLoading(false);
                setBannerMessageType('success');
                setBannerMessage('A new Skill has been created successfully!');
                setShowMessageBanner(true);
                setShowGuidance(false);
            }
            else {
                console.log("Failed to retrieve Skills");
                setDataLoading(false);
                setBannerMessageType('error');
                setBannerMessage(res['details']);
                setShowMessageBanner(true);
            }
        }).catch(err => {
            console.log(err);
            setDataLoading(false);
            setBannerMessageType('error');
            setBannerMessage("Failed to  retrieve Skills!");
            setShowMessageBanner(true);
        })
    }

    const createNewSkill = () => {
        return <Modal size="md" centered show={newSkillModal} onHide={() => { setNewSkillModal(false) }} animation={false} >
            <Modal.Header >
                <h5>Create a Skill</h5>
                <div className=' btn--small btn--icon' onClick={() => { setNewSkillModal(false) }}>
                    <span className="icon-close icon-size-24"></span>
                </div>
            </Modal.Header>
            <Modal.Body>
                {
                    showValidationErrorMessage && (<MessageBanner message={dataValidationErrorMessage} messageType={"error"} onCloseClicked={() => { setShowValidationErrorMessage(false) }}></MessageBanner>)
                }
                <form >
                    <div className='center base-padding'>
                        <div className='warningMessage txtAlignRight'>Please fill the (*) mandatory fields</div>
                        <div className="form-group base-margin-bottom">
                            <div className="form-group__text">
                                <input id="input-type-number" type="text" autoComplete='off' autoFocus name="skill_name" onChange={event => { skillInputHandlerChange(event, "name") }} />
                                <label htmlFor="input-type-number">Skill Name <span className='required'>*</span></label>
                            </div>
                        </div>
                        <div className="form-group base-margin-bottom">
                            <div className="form-group__text">
                                <textarea id="textarea-state-default" rows={6} name="skill_description" onChange={event => { skillInputHandlerChange(event, "description") }}></textarea>
                                <label className='positionRelative' htmlFor="input-type-number">Skill Description <span className='required'>*</span></label>
                            </div>
                        </div>
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer>
                {/* <button className="btn btn--secondary" onClick={() => { setNewSkillModal(false) }} style={{ paddingLeft: "16px", paddingRight: "16px" }}>
                    Cancel
                    <span className="icon-close icon-size-18 base-margin-left"></span>
                </button> */}
                <button className="btn btn--secondary" disabled={!skillSubmitBtnEnable} onClick={() => { setNewSkillModal(false); addSkill(); }} style={{ paddingLeft: "16px", paddingRight: "16px" }}>
                    Create
                    {/* <span className="icon-add-contain icon-size-18 base-margin-left"></span> */}
                </button>
            </Modal.Footer>
        </Modal>
    }
    const handleExpressions = () => {
        let isRowSelected = false;
        const changeFunction = index => {
            let expressionsCopy = [...expressions]
            const temp = !expressionsCopy[index].isChecked
            expressionsCopy[index].isChecked = temp
            setExpressions(expressionsCopy);
            for (let element of expressionsCopy)
                if (element.isChecked === false) {
                    setCheckedAll(false)
                    return
                }
            setCheckedAll(true)
        }

        const changeAllFunction = () => {
            let expressionsCopy = [...expressions]
            let temp = !checkedAll
            for (let element of expressionsCopy)
                element.isChecked = temp
            setExpressions(expressionsCopy)
            setCheckedAll(temp)
        }

        const deleteFunction = () => {
            let expressionsCopy = []
            for (let element of expressions)
                if (element.isChecked === false)
                    expressionsCopy.push(element)
            setExpressions(expressionsCopy)
            setCheckedAll(false)
        }

        for (let element of expressions) {
            if (element.isChecked === true) {
                isRowSelected = true;
                break;
            }
        }
        return (
            <div className="panel panel--raised">
                <CheckUncheck listname="Expressions" checkboxlist={expressions} changeFunction={changeFunction} changeAllFunction={changeAllFunction} checkedAll={checkedAll} >
                    <div className="deletebtn base-margin">
                        <button disabled={isRowSelected === false} className="btn btn--small btn--secondary" onClick={() => { deleteFunction() }} style={{ paddingLeft: "16px", paddingRight: "16px" }}>Delete</button>
                    </div>
                </CheckUncheck>
            </div>
        )
    }
    const getDefaultRole = () => {
        for (let role of roles) {
            if (selectedRol === role.value) {
                return role
            }
        }
        return selectedRole
    }
    const rolesListBind = () => {
        return <SearchSelect
            id='rolesName'
            className='augmentSelect'
            placeholder={"Select the Role"}
            // defaultValue={getDefaultRole}
            options={roles}
            onValueChange={(data) => {
                setSelectedRole(data);
            }}
        />
    }
    const getDefaultEntity = () => {
        for (let entitie of entities) {
            if (selectedNtt === entitie.value) {
                return entitie
            }
        }
        return entities[0]
    }
    const entitiesListBind = () => {
        return <SearchSelect
            id='entitiesName'
            className='augmentSelect'
            defaultValue={getDefaultEntity()}
            options={entities}
            onValueChange={(data) => {
                setSelectedEntitie(data);
            }}
        />
    }

    const createIntent = () => {
        return <Modal size="md" show={showCreateIntentScreen} animation={false}>
            <Modal.Header>
                <h6>Create Intent</h6>
                <span className='icon-close icon-size-24' onClick={() => { setShowCreateIntentScreen(false) }}></span>
            </Modal.Header>
            <Modal.Body>
                {
                    showValidationErrorMessage && (<MessageBanner message={dataValidationErrorMessage} messageType={"error"} onCloseClicked={() => { setShowValidationErrorMessage(false) }}></MessageBanner>)
                }
                <div className="form-group base-margin-bottom">
                    <div className="form-group__text">
                        <input id="input-type-number" type="text" autoComplete='off' onChange={event => inputHandlerChange(event, 'name')} autoFocus name="intent_name" />
                        <label htmlFor="input-type-number">Intent Name <span className='required'>*</span></label>
                    </div>
                </div>
                <div className="form-group base-margin-bottom">
                    <div className="form-group__text">
                        <textarea id="textarea-state-default" rows={2} name="intent_description" onChange={event => inputHandlerChange(event, 'description')}></textarea>
                        <label htmlFor="input-type-number">Intent Description <span className='required'>*</span></label>
                    </div>
                </div>
                <button className="btn btn--secondary" disabled={!submitBtnEnable} onClick={() => { addIntent(selectedSkill); setShowCreateIntentScreen(false); }} style={{ float: 'right' }}>
                    Submit
                    {/* <span className="icon-add-contain icon-size-18 base-margin-left"></span> */}
                </button>
                {/* <button className="btn btn--secondary" onClick={() => { setShowCreateIntentScreen(false); clearSelectedIntentValues(); setShowValidationErrorMessage(false); }} style={{ paddingLeft: "16px", paddingRight: "16px" }}>
                    Cancel
                    <span className="icon-close icon-size-18 base-margin-left"></span>
                </button> */}
            </Modal.Body>
        </Modal>
    }
    const editIntent = () => {
        return <Modal size="md" show={showEditIntentScreen} animation={false}>
            <Modal.Header>
                <h6>Edit an Intent</h6>
                <span className='icon-close icon-size-24' onClick={() => { setShowEditIntentScreen(false); setShowValidationErrorMessage(false); }}></span>
            </Modal.Header>
            <Modal.Body>
                {
                    showValidationErrorMessage && (<MessageBanner message={dataValidationErrorMessage} messageType={"error"} onCloseClicked={() => { setShowValidationErrorMessage(false) }}></MessageBanner>)
                }
                <div className="form-group base-margin-bottom">
                    <div className="form-group__text">
                        <input id="input-type-number" autoComplete='off' type="text" value={intentData.name} autoFocus name="intent_name" disabled={true} />
                        <label htmlFor="input-type-number">Intent Name <span className='required'>*</span></label>
                    </div>
                </div>
                <div className="form-group base-margin-bottom">
                    <div className="form-group__text">
                        <textarea id="textarea-state-default" rows={2} name="intent_description" value={intentData.description} onChange={event => inputHandlerEditChange(event, 'description')}></textarea>
                        <label htmlFor="input-type-number">Intent Description <span className='required'>*</span></label>
                    </div>
                </div>
                <button className="btn btn--secondary" disabled={!submitBtnEnable} onClick={() => { updateIntent(selectedSkill); setShowEditIntentScreen(false) }} style={{ paddingLeft: "16px", paddingRight: "16px" }}>Update</button>
                {/* <button className="btn btn--secondary" onClick={() => { setShowEditIntentScreen(false) }} style={{ paddingLeft: "16px", paddingRight: "16px" }}>Cancel</button> */}
            </Modal.Body>
        </Modal>

    }

    const createExpression = () => {
        return <Modal size="lg" show={showCreateExpressionScreen} animation={false}>
            <Modal.Header>
                <h6>Create Expression</h6>
                <span className='icon-close icon-size-24' onClick={() => { setShowCreateExpressionScreen(false); setExpressions([]); setShowValidationErrorMessage(false); }}></span>
            </Modal.Header>
            <Modal.Body>
                <MessageBanner message={'Please provide at least five different expressions related to the given intent. This will help effectively train the model to identify the intent increasing the efficacy of the chatbot.'} messageType={"info"} onCloseClicked={() => { setShowValidationErrorMessage(false) }}></MessageBanner>
                {
                    showValidationErrorMessage && (<MessageBanner message={dataValidationErrorMessage} messageType={"error"} onCloseClicked={() => { setShowValidationErrorMessage(false) }}></MessageBanner>)
                }

                <div className="">
                    <ul className="tabs tabs--justified" style={{ "height": "100%" }}>
                        <li id="static-entity-tab" className={tab_selected == "form-upload" ? "active" : "" + "tab"} onClick={() => switchUploadTab("form-upload")}>
                            <a tabindex="0">Static</a>
                        </li>
                        <li id="api-entity-tab" className={tab_selected == "file-upload" ? "active" : "" + "tab"} onClick={() => switchUploadTab("file-upload")}>
                            <a tabindex="0">File Upload</a>
                        </li>
                    </ul>
                    <div className='tab-content'>
                        <div className={tab_selected === "form-upload" ? "active " : "" + 'panel panel--raised tab-pane'}>
                            <div className="panel panel--raised base-margin-top">
                                <div className="row" style={{ "marginLeft": "0px" }}>
                                    <div className="col-10 col-sm-10 col-md-11 col-lg-11 col-xl-11 col-xxl-12 col-xxxl-11 form-group base-margin-bottom">
                                        <div className="form-group__text">
                                            <input id="input-type-email" type="text" autoComplete='off' value={expression} onChange={event => { setExpression(event.target.value) }} onKeyDown={(e) => {
                                                if (e.key === 'Enter' && expression.length > 0) {
                                                    setShowValidationErrorMessage(false);
                                                    if (isAlphanumeric(expression, 'en-US', { "ignore": /[,.!?'"; ]/g })) {
                                                        setExpressions(arr => [...arr, { id: getExpressionId(), name: expression, isChecked: false, annotations: [] }])
                                                        setExpression('');
                                                    }
                                                    else {
                                                        setShowValidationErrorMessage(true);
                                                        setDataValidationErrorMessage('Special characters other than ,.!?\'\"; are not allowed in an expression.');

                                                    }
                                                }
                                            }} placeholder="Press enter to add expression" />
                                            <label htmlFor="input-type-email">Expression</label>
                                        </div>
                                    </div>
                                    <div className="col-2 col-sm-2 col-md-1 col-lg-1 col-xl-1 col-xxl-1 col-xxxl-1">
                                        <span className={(expression.length == 0 ? "disabled " : "") + "icon-add-outline icon-size-20"} style={{ "marginTop": "25px" }} onClick={() => {
                                            // setExpressions(arr => [...arr, { id: getExpressionId(), name: expression, isChecked: false, annotations: [] }])
                                            // setExpression('');
                                            setShowValidationErrorMessage(false);
                                            if (isAlphanumeric(expression, 'en-US', { "ignore": /[,.!?'"; ]/g })) {
                                                setExpressions(arr => [...arr, { id: getExpressionId(), name: expression, isChecked: false, annotations: [] }])
                                                setExpression('');
                                            }
                                            else {
                                                setShowValidationErrorMessage(true);
                                                setDataValidationErrorMessage('Special characters other than ,.!?\'\"; are not allowed in an expression.');

                                            }
                                        }}></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <FileDropZone className={(tab_selected === "file-upload" ? "active " : "") + 'tab-pane base-margin-top base-margin-bottom'} onFileuploaded={(data) => processFileData(data)} allowedFileTypes={["text/csv", "text/plain"]}></FileDropZone>

                    </div>
                    {expressions.length > 0 ? <hr></hr> : ""}
                    {expressions.length > 0 ? handleExpressions() : ""}
                </div>


                <button disabled={expressions.length < 1} className="btn btn--secondary base-margin-top" onClick={() => { addExpression(selectedSkill) }} style={{ float: 'right' }}>
                    Submit
                    {/* <span className="icon-add-contain icon-size-18 base-margin-left"></span> */}
                </button>
                {/* <button className="btn btn--secondary base-margin" onClick={() => { setShowCreateExpressionScreen(false); clearSelectedExpressionValues() }} style={{ paddingLeft: "16px", paddingRight: "16px" }}>
                        Cancel
                        <span className="icon-close icon-size-18 base-margin-left"></span>
                    </button> */}

            </Modal.Body>
        </Modal>
    }

    const deleteMultipleIntent = (event) => {
        const { name, checked } = event.target;
        let temp = [];
        if (name === "allselect") {
            let tempdata = intentObjList.map((intent) => {
                if (event.target.checked) {
                    temp.push(intent._id)
                } else {
                    temp.push()
                }
                let newlist = { ...intent, isChecked: checked }
                return newlist
            })
            setIntentObjList(tempdata)
            setDeleteIntentList(temp)
        } else {
            let tempdata = intentObjList.map((intent) =>
                intent._id === name ? { ...intent, isChecked: checked } : intent
            )
            if (event.target.checked) {
                temp = deleteIntentList;
                temp.push(name)
                setDeleteIntentList(temp)
            } else {
                temp = deleteIntentList.filter((i) => { if (i != name) return i; });
                setDeleteIntentList(temp)
            }
            setIntentObjList(tempdata)
        }
        if (temp.length == 0) {
            setSubmitBtnDeleteEnable(false)
            setShowDeleteIntentScreen(false);
            setSelectedIntentId();
            setIntentName();
        }
        else {
            setSubmitBtnDeleteEnable(true)
        }
    }

    const deleteMultipleExpression = (event, expression) => {
        const { name, checked } = event.target;
        let temp = [];
        let temp_exp = [];
        if (name === "allselect") {
            let tempdata = expressionObjList.map((exp) => {
                if (event.target.checked) {
                    temp.push(exp._id)
                    temp_exp.push(exp.value)
                } else {
                    temp.push()
                    temp_exp.push()
                }
                let newlist = { ...exp, isChecked: checked }
                return newlist
            })
            setExpressionObjList(tempdata);
            setDeleteExpressionList(temp)
            setSelectedExpressionsList(temp_exp)
            if (temp.length == 0) {
                setSubmitBtnDeleteExpEnable(false)
            }
            else {
                setSubmitBtnDeleteExpEnable(true)
            }
        } else {
            let tempdata = expressionObjList.map((exp) =>
                exp._id === name ? { ...exp, isChecked: checked } : exp
            )
            if (event.target.checked) {
                temp = deleteExpressionList;
                temp.push(name)
                setDeleteExpressionList(temp)

                temp_exp = selectedExpressionsList
                temp_exp.push(expression['value'])
                setSelectedExpressionsList(temp_exp)
            } else {
                let temp = deleteExpressionList.filter((i) => { if (i != name) return i; });
                setDeleteExpressionList(temp)
                let tempexp = selectedExpressionsList.filter((i) => { if (i != expression['value']) return i; });
                setSelectedExpressionsList(tempexp)
            }
            setExpressionObjList(tempdata);
            if (temp.length == 0) {
                setSubmitBtnDeleteExpEnable(false)
                setShowDeleteExpressionScreen(false);
                setSelectedExpressionId();
                setExpressionVal();
            }
            else {
                setSubmitBtnDeleteExpEnable(true)
            }
        }
    }

    const paraphraseExpression = () => {
        setDataLoading(true);
        if (deleteExpressionList.length > 20) {
            setDataLoading(false);
            displayBannerMessage('error', 'Paraphrasing feature is supported only for less than 20 expressions at a time.')
        }
        else {
            let payload = {
                "queries": selectedExpressionsList,
                "numberOfSequence": 10,
                "max_length": 64,
                "temperature": 0.95
            }

            ExpressionService.paraphraseExpression(payload).then(res => res.json()).then(res => {
                if (res.status == 'success') {
                    setCounter(true);
                    let temp = []
                    let count = 0
                    for (let expression of res['expressions']) {
                        temp.push({ id: count, name: expression, isChecked: false, annotations: [] })
                        count += 1
                    }

                    let paraphrasedExpressions = temp;
                    setExpressions(paraphrasedExpressions)
                    setDataLoading(false);
                    setShowParaphraseModal(true);


                    // setBannerMessageType('success');
                    // setBannerMessage("Succesfully deleted the Intent!");
                    // setShowMessageBanner(true);
                }
                else {
                    setDataLoading(false);
                    setShowParaphraseModal(false);
                    setBannerMessageType('error');
                    setBannerMessage("Something went wrong while paraphrasing. Please try after sometime.");
                    setShowMessageBanner(true);
                }
            },
                err => {
                    if (counter) {
                        setCounter(false);
                        paraphraseExpression()
                    }
                    else {
                        setDataLoading(false);
                        setShowParaphraseModal(false);
                        setBannerMessageType('error');
                        setBannerMessage("Something went wrong while paraphrasing. Please try after sometime.");
                        setShowMessageBanner(true);
                    }

                })
        }

    }
    const downloadExpression = (intent) => {
        let tempexpression = expressionObjList.map((data) => {
            return data.value + "\n"
        });
        tempexpression = tempexpression.toString().replace(/\,/g, '');
        const blob = new Blob([tempexpression], { type: "text/plain" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.download = intent + '.txt'
        link.href = url;
        link.click();
    }

    // const systemEntities = [
    // 	{label: "User CEC", value: "User CEC" },
    // 	{label: "Date", value: "Date" },
    // 	{label: "Time", value: "Time" },
    // ];
    const [ruleShow, setRuleShow] = useState(false)
    const [entitiesType, setEntitiesType] = useState("Entities")
    const [disbleAnnotateBtn, setDisbleAnnotateBtn] = useState(true)
    const updateEntities = (data) => {
        setDisbleAnnotateBtn(false)
        setSelectedEntitie(data)
        if (data.label === data.value) {
            setRuleShow(false);
            setEntitiesType("System_Entities")
        } else {
            setRuleShow(true)
            setEntitiesType("Entities")
        }
    }
    const optionslist = [{ label: 'Entities', options: entities }, { label: 'System Entities', options: systemEntities }]
    const viewExpression = () => {
        return <div className="" style={{ "height": submitBtnDeleteExpEnable ? "calc(100% - 106px)" : "calc(100% - 46px)", "overflow": "auto" }}>
            {
                intentIndex !== undefined && expressionObjList && expressionObjList.map((expressionObj, index) => {
                    return (
                        <div className="base-margin-bottom panel panel--raised panel--bordered" style={{ padding: '0', width: '100%' }} key={index}>
                            <div className="row">
                                <span className='col-sm-10' onClick={() => { onWordSelected(index, expressionObj.value, expressionObj._id, expressionObj.intent_id) }}>
                                    <label className="checkbox" >
                                        <input type="checkbox" name={"allselect"} onChange={(e) => { deleteMultipleIntent(e, expressionObj) }} />
                                        <span className="checkbox__input"></span>
                                        <span className="checkbox__label"></span>
                                        <div style={{ paddingLeft: '10px' }}>
                                            {highlightEntityInExpression(expressionObj.value, expressionObj.entities).map((data) => {
                                                return (
                                                    <>{data}</>
                                                )
                                            })}</div>

                                    </label>
                                </span>
                                {/* <p className='col-sm-10 expressionList' onClick={() => { onWordSelected(index, expressionObj.value, expressionObj._id, expressionObj.intent_id) }}>

                                    {highlightEntityInExpression(expressionObj.value, expressionObj.entities).map((data) => {
                                        return (<span>{data}</span>);
                                    })}
                                </p> */}
                                <span className="btn--icon icon-delete text-right col-sm-2" style={{ cursor: 'pointer' }} onClick={() => { initiateExpressionDelete(expressionObj); }}></span>
                            </div>
                            {
                                (showEditEntity && selectedEntityObject.index === index) && (
                                    <div className='panel panel--bordered annotationsPopbox'>

                                        <div className='row annotations_header'>
                                            <div className='col-xl-12'>
                                                <span style={{ "fontWeight": "500", "fontSize": "15px" }}>Select Entity</span>
                                                <span className=" btn--icon icon-close text-right icon-size-20" onClick={() => { setShowEditEntity(false); setDisbleAnnotateBtn(true) }} style={{ "position": "absolute", "right": "15px", "top": "2px" }}></span>
                                            </div>
                                        </div>
                                        {entities.length == 0 && <div className="alert alert--info col-8 flex flex-center center" style={{ "position": "relative", "left": "35%", "transform": "translate(-300px,5px)", "zIndex": "100px" }}>
                                            <span>It seems like no entity has been created for the chosen skill yet. To create one, click on the <b>Create Entity</b> button. In case you need any help, you can either select the <b>Help</b> option or converse with the <b>AskBotLite</b> chatbot.</span>
                                        </div>}

                                        <div className='row'>
                                            <div className='col-xl-12'>
                                                <span>Selected Phrase:</span><span style={{ "paddingLeft": "5px", "color": "purple" }}>{selectedTextForEditEntity}</span>
                                            </div>
                                            <span className='col-xl-4 col-lg-6 col-md-7 col-sm-5 col-xs-8'>

                                                <div className="form-group" style={{ marginTop: '4.5px' }} >
                                                    <SearchSelect placeholder={"Select the Entity"} options={optionslist}
                                                        onValueChange={(data) => {
                                                            updateEntities(data);
                                                        }}
                                                    />
                                                    {/* {entitiesListBind()} */}
                                                </div>
                                            </span>
                                            {ruleShow && <span className='col-md-4 col-lg-6 col-md-7 col-sm-5 col-xs-8'>
                                                <div className="form-group" style={{ marginTop: '4.5px' }} >
                                                    {rolesListBind()}
                                                </div>
                                            </span>}
                                            <span style={{ "position": "absolute", "bottom": "20px", "right": "15px" }}>
                                                <button className="btn btn--secondary" disabled={disbleAnnotateBtn} onClick={() => { addEntityMapping(selectedSkill, intentObjList[intentIndex]._id, selectedEntityObject.index) }} style={{ paddingLeft: "16px", paddingRight: "16px" }}>Annotate the Expression</button>
                                            </span>

                                        </div>
                                    </div>
                                )
                            }

                        </div>
                    )
                })
            }
        </div >
    }

    const paraphraseModal = () => {
        return <Modal size="lg" centered show={showParaphraseModal} onHide={() => { setShowParaphraseModal(false) }} animation={false} >
            <Modal.Header >
                <h5>Select the Expressions to be added</h5>
                <div className='btn--small btn--icon' onClick={() => { setShowParaphraseModal(false) }}>
                    <span className="icon-close icon-size-24"></span>
                </div>
            </Modal.Header>
            <Modal.Body>
                {expressions.length > 0 ? handleExpressions() : ''}
            </Modal.Body>
            <Modal.Footer>
                {/* <button className="btn btn--secondary" onClick={() => { setShowParaphraseModal(false) }}>
                    Cancel
                    <span className="icon-close icon-size-18 base-margin-left"></span>
                </button> */}
                <button className="btn btn--secondary" onClick={() => { setShowParaphraseModal(false); addExpression(selectedSkill); }}>
                    Add Expressions
                    {/* <span className="icon-add-contain icon-size-18 base-margin-left"></span> */}
                </button>
            </Modal.Footer>
        </Modal>
    }

    if (layoutType !== "verticle") {
        return (
            <div style={{ "height": "calc(100% - 8px)" }}>
                {showPageTour === true ? <Joyride
                    callback={handleJoyrideCallback}
                    continuous
                    hideCloseButton
                    run={run}
                    scrollToFirstStep
                    showProgress
                    showSkipButton
                    steps={steps}
                    styles={{
                        options: {
                            zIndex: 10000,
                        },
                        buttonNext: {
                            background: '#1e4471',
                            border: '1px solid #1e4471',
                            borderRadius: '5px'
                        },
                        buttonBack: {
                            color: '#1e4471',
                            border: '1px solid #1e4471',
                            borderRadius: '5px'
                        },
                        buttonSkip: {
                            color: '#1e4471',
                            border: '1px solid #1e4471',
                            borderRadius: '5px'
                        },
                    }}
                /> : ""}
                {
                    isDataLoading && (<Spinner></Spinner>)
                }
                {/* <div style={{ borderBottom: '1px #ced4da solid' }}> */}
                <div className=''>
                    <div className=" row base-margin-right base-margin-top pageTour1" >
                        {/* <h5 className="col-7 col-md-8 col-sm-pageTour18 col-lg-9 col-xl-9 col-xxl-10 col-xxxl-10 base-margin-top">Intents View</h5> */}
                        <div className="col-7 col-md-8 col-sm-8 col-lg-9 col-xl-9 col-xxl-10 col-xxxl-10 base-margin-top"></div>
                        <div className='col-5 col-md-4 col-sm-4 col-lg-3 col-xl-3 col-xxl-2 col-xxxl-2 ' style={{ float: 'right' }}>
                            <div className='pageTour2'>{initSkillDropdown()}</div>
                        </div>
                    </div>
                </div>
                {
                    !showGuidance && showMessageBanner && <MessageBanner message={bannerMessage} messageType={bannerMessageType} onCloseClicked={() => { setShowMessageBanner(false) }}></MessageBanner>
                }
                {
                    showGuidance && <div className="alert alert--info col-8 flex flex-center center" style={{ "position": "relative", "left": "35%", "transform": "translate(-300px,5px)", "zIndex": "100px" }}>
                        <span>It seems like no skill has been created. To create one, please click on <b>Create Skill</b> option from the above dropdown. You can also create a skill by clicking on the <b>Skill View Screen</b> (to create a new skill) or you can explore the <b>Skill library</b> (to reuse already created skills) from the sidebar menu. In case you need any help, please click on the <b>Help</b> option or converse with the <b>AskBotLite chatbot</b>.</span>
                    </div>
                }
                {
                    !showGuidance && intentObjList.length == 0 && <div className="alert alert--info col-8 flex flex-center center" style={{ "position": "relative", "left": "35%", "transform": "translate(-300px,5px)", "zIndex": "100px" }}>
                        <span>It seems like no Intent has been created for the chosen skill yet. To create one, click on the <b>Create Intent</b> button. In case you need any help, you can either select the <b>Help</b> option or converse with the <b>AskBotLite</b> chatbot.</span>
                    </div>
                }

                {!showGuidance && <div style={{ "height": "calc(100% - 58px)" }}>
                    <div className="row base-margin" style={{ "height": "calc(100% - 8px)" }}>
                        <div className='col-12 col-xs-12  col-sm-12  col-md-4 col-lg-4  col-xl-4 col-xxl-4 initial_bot_width' style={{ borderRight: '1px solid #ced4da', height: '100%', overflow: "auto" }}>
                            <div className="row">
                                <h5 >Intents</h5>
                                <div style={{ "position": "absolute", "right": "12px" }}>
                                    <button className='btn btn--primary base-margin-left' style={{ float: 'right', paddingLeft: "5px", paddingRight: "5px", width: '100px ' }} disabled={deleteIntentList.length == 0 || isReadOnlySkill()} onClick={() => { setShowDeleteIntentScreen(true) }}>
                                        Delete
                                    </button>
                                    <button className='btn btn--secondary base-margin-left' style={{ float: 'right', paddingLeft: "16px", paddingRight: "16px", width: '100px ' }} disabled={isReadOnlySkill()}
                                        onClick={() => { setShowValidationErrorMessage(false); setShowCreateIntentScreen(true); setSubmitBtnEnable(false); }}>
                                        Create
                                    </button>
                                </div>
                            </div>
                            <hr />

                            <div className="panel panel--well" style={{ display: showDeleteIntentScreen ? 'block' : 'none', marginBottom: '10px' }}>
                                <span style={{ display: !submitBtnDeleteEnable ? 'block' : 'none' }}>
                                    <span > Delete Intent - </span><span style={{ textTransform: 'lowercase' }} ><i>{intentName}</i> </span>
                                </span>
                                <div className="alert alert--warning-alt" style={{ display: !submitBtnDeleteEnable ? 'block' : 'none' }}>
                                    <div className="alert__icon icon-warning-outline"></div>
                                    <div className="alert__message">Do you want to delete this Intent?</div>
                                    <button className="btn btn--small btn--secondary" onClick={() => { deleteIntent(selectedSkill) }}>Yes</button>
                                    <button className="btn btn--small btn--secondary" onClick={() => { setShowDeleteIntentScreen(false) }}>No</button>
                                </div>
                                <div className="alert alert--warning-alt" style={{ display: submitBtnDeleteEnable ? 'block' : 'none' }}>
                                    <div className="alert__icon icon-warning-outline"></div>
                                    <div className="alert__message">Do you want to delete the selected {deleteIntentList.length > 1 ? "Intents" : "Intent"}?</div>
                                    <button className="btn btn--small btn--secondary" onClick={() => { bulkDeleteIntents(selectedSkill) }}>Yes</button>
                                    <button className="btn btn--small btn--secondary" onClick={() => { setShowDeleteIntentScreen(false) }}>No</button>
                                </div>
                            </div>
                            {/* {intentObjList && intentObjList.length > 0 && <div className='deleteallbox' style={{ visibility: isReadOnlySkill() ? "hidden" : "visible" }}>
                                <div>
                                    <span className={deleteIntentList.length === intentObjList.length ? "icon-square-o-checked" : "icon-square-o"}></span>
                                    <input type='checkbox' checked={deleteIntentList.length === intentObjList.length ? true : false} name={"allselect"} onChange={(e) => { deleteMultipleIntent(e) }}></input>
                                </div>
                            </div>} */}
                            {intentObjList && <div id="IntentView" style={{ "height": "calc(100% - 56px)", "overflowY": "auto" }}>

                                <div className="pageTour5" style={{ height: 'calc(100% - 56px)', overflowY: 'auto' }}>
                                    {
                                        intentObjList.length != 0 && intentObjList.map((intent, index) => {
                                            return (
                                                <div key={index} className={`base-margin-bottom panel panel--bordered base-padding panel--raised intentsbox `} style={{ border: intent['_id'] === intentObjList[intentIndex]['_id'] ? '1px solid #0d274d' : '', width: '100%', cursor: "pointer" }} >
                                                    <div className=" row">
                                                        <span className='col-md-10'>
                                                            <label className="checkbox" >
                                                                <input type="checkbox" name={intent['_id']} checked={intent?.isChecked || false} onChange={(e) => { deleteMultipleIntent(e, intent['_id']) }} disabled={isReadOnlySkill()} />
                                                                <span className="checkbox__input"></span>
                                                                <span className="checkbox__label">
                                                                    <span className='base-padding-left'>
                                                                        <a href="#!" onClick={() => { intentChange(index, intent); }}>{intent['name']}</a>
                                                                    </span>
                                                                </span>
                                                            </label>
                                                        </span>
                                                        <span className="text-right col-md-1" data-balloon="Download expression" data-balloon-pos="left" style={{ cursor: 'pointer' }} onClick={() => { downloadExpression(intent['name']); }}><span className='btn--icon icon-download'></span></span>
                                                        {/* <span className="text-right col-md-1" data-balloon="Delete intent" data-balloon-pos="left" style={{ cursor: 'pointer' }} onClick={() => { initiateIntentDelete(intent); }}><span className='btn--icon icon-delete' style={{ opacity: isReadOnlySkill() ? '0.5' : '' }}></span></span> */}
                                                        <span className="text-right col-md-1" data-balloon="Edit intent" data-balloon-pos="left" style={{ cursor: 'pointer' }} onClick={() => { initiateIntentEdit(intent); }}><span className='btn--icon icon-edit' style={{ opacity: isReadOnlySkill() ? '0.5' : '' }}></span></span>
                                                    </div>
                                                    <span style={{ display: intent['description'].length == 0 ? 'none' : 'flex' }}></span>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div >}
                        </div >

                        <div className='col-12 col-xs-12  col-sm-12  col-md-8 col-lg-8  col-xl-8 col-xxl-8 initial_edit_width' style={{ height: "100%" }}>
                            <div className='pageTour4 row'>
                                <h5 className="col-2 col-xs-2  col-sm-2  col-md-3 col-lg-3  col-xl-3 col-xxl-3">Expressions</h5>
                                <div className="col-10 col-xs-10  col-sm-10  col-md-9 col-lg-9  col-xl-9 col-xxl-9" >
                                    <button className='btn btn--primary base-margin-left' style={{ float: 'right', width: '120px' }} disabled={intentObjList.length == 0 || deleteExpressionList.length == 0 || isReadOnlySkill()} onClick={() => { paraphraseExpression() }} >
                                        Paraphrase
                                    </button>
                                    <button className='btn btn--primary base-margin-left' style={{ float: 'right', width: '120px' }} disabled={deleteExpressionList.length == 0} onClick={() => { setShowDeleteExpressionScreen(true) }} >
                                        Delete
                                    </button>

                                    <button className='btn btn--secondary base-margin-left' style={{ float: 'right', width: '120px' }} onClick={() => { setShowValidationErrorMessage(false); setShowCreateExpressionScreen(true) }} disabled={intentObjList.length == 0 || isReadOnlySkill()} >
                                        Create
                                    </button>

                                </div>
                            </div>
                            <hr />
                            {
                                intentObjList.length > 0 && expressionObjList.length == 0 && <div className="alert alert--info col-12 flex flex-center center">
                                    <span>It seems like no Expression has been created for the chosen Intent yet. To create one, click on the <b>Create</b> button. In case you need any help, you can either select the <b>Help</b> option or converse with the <b>AskBotLite</b> chatbot.</span>
                                </div>
                            }
                            <div className="panel panel--well" style={{ display: showDeleteExpressionScreen ? 'block' : 'none', marginBottom: '10px' }}>
                                <span style={{ display: !submitBtnDeleteExpEnable ? 'block' : 'none' }}><span >Delete Expression -  </span><span style={{ textTransform: 'lowercase' }} ><i>{expressionVal}</i> </span></span>
                                <div className="alert alert--warning-alt" style={{ display: !submitBtnDeleteExpEnable ? 'block' : 'none' }}>
                                    <div className="alert__icon icon-warning-outline"></div>
                                    <div className="alert__message">Do you want to delete this Expression?&emsp;</div>
                                    <button className="btn btn--small btn--secondary" onClick={() => { deleteExpression(selectedSkill) }}>Yes</button>
                                    <button className="btn btn--small btn--secondary" onClick={() => { setShowDeleteExpressionScreen(false) }}>No</button>
                                </div>
                                <div className="alert alert--warning-alt" style={{ display: submitBtnDeleteExpEnable ? 'block' : 'none' }}>
                                    <div className="alert__icon icon-warning-outline"></div>
                                    <div className="alert__message">Do you want to delete the below {deleteExpressionList.length > 1 ? "Expressions" : "Expression"}?&emsp;</div>
                                    <button className="btn btn--small btn--secondary" onClick={() => { bulkDeleteExpression(selectedSkill) }}>Yes</button>
                                    <button className="btn btn--small btn--secondary" onClick={() => { setShowDeleteExpressionScreen(false) }}>No</button>
                                </div>
                            </div>
                            {/* {expressionObjList && expressionObjList.length > 0 && <div className='deleteallbox' style={{ visibility: isReadOnlySkill() ? "hidden" : "visible" }}>
                                <div>
                                    <span className={deleteExpressionList.length === expressionObjList.length ? "icon-square-o-checked" : "icon-square-o"}></span>
                                    <input type='checkbox' checked={deleteExpressionList.length === expressionObjList.length ? true : false} name={"allselect"} onChange={(e) => { deleteMultipleExpression(e) }}></input>
                                </div>
                            </div>} */}
                            <div className="pageTour6 expressionView" style={{ height: 'calc(100% - 64px)', overflow: 'auto' }}>
                                {
                                    intentIndex !== undefined && expressionObjList && expressionObjList.map((expressionObj, index) => {
                                        return (
                                            <div className="base-margin-bottom panel panel--raised panel--bordered" style={{ padding: '0', width: '100%' }} key={index}>
                                                <div className="row">
                                                    <span className='col-sm-10' onClick={() => { onWordSelected(index, expressionObj.value, expressionObj._id, expressionObj.intent_id) }}>
                                                        <label className="checkbox" >
                                                            <input type="checkbox" name={expressionObj._id} checked={expressionObj?.isChecked || false} onChange={(e) => { deleteMultipleExpression(e, expressionObj) }}
                                                                disabled={isReadOnlySkill()} />
                                                            <span className="checkbox__input"></span>
                                                            <span className="checkbox__label"></span>
                                                            <div style={{ paddingLeft: '10px' }}>
                                                                {highlightEntityInExpression(expressionObj.value, expressionObj.entities).map((data) => {
                                                                    return (
                                                                        <>{data}</>
                                                                    )
                                                                })}</div>

                                                        </label>
                                                    </span>
                                                    {/* <span className="btn--icon icon-delete text-right col-sm-2" onClick={() => { initiateExpressionDelete(expressionObj); }} style={{ cursor: 'pointer', opacity: isReadOnlySkill() ? '0.5' : '' }}></span> */}
                                                </div>

                                                {
                                                    (showEditEntity && selectedEntityObject.index === index) && (
                                                        <div className='panel panel--bordered annotationsPopbox'>
                                                            <div className='row annotations_header'>
                                                                <div className='col-xl-12'>
                                                                    <span style={{ "fontWeight": "500", "fontSize": "15px" }}>Select Entity</span>
                                                                    <span className=" btn--icon icon-close text-right icon-size-20" onClick={() => { setShowEditEntity(false); setDisbleAnnotateBtn(true) }} style={{ "position": "absolute", "right": "15px", "top": "2px" }}></span>
                                                                </div>
                                                            </div>
                                                            {entities.length == 0 && <div className="alert alert--info flex flex-center center">
                                                                <span>It seems like no entity has been created for the chosen skill yet. To create one, click on the <b>Create</b> button. In case you need any help, you can either select the <b>Help</b> option or converse with the <b>AskBotLite</b> chatbot.</span>
                                                            </div>}
                                                            <div className='row'>
                                                                <div className='col-xl-12'>
                                                                    <span>Selected Phrase:</span><span style={{ "paddingLeft": "5px", "color": "purple" }}>{selectedTextForEditEntity}</span>
                                                                </div>
                                                                <span className='col-lg-4 col-md-4 col-sm-4'>
                                                                    <div className="form-group" style={{ marginTop: '4.5px' }} >
                                                                        <SearchSelect placeholder={"Select the Entity"} options={optionslist}
                                                                            onValueChange={(data) => {
                                                                                updateEntities(data);
                                                                            }} />
                                                                        {/* {entitiesListBind()} */}
                                                                    </div>
                                                                </span>
                                                                {ruleShow && <span className='col-lg-4 col-md-4 col-sm-4'>
                                                                    <div className="form-group" style={{ marginTop: '4.5px' }} >
                                                                        {rolesListBind()}
                                                                    </div>
                                                                </span>}
                                                                <span className='col-lg-4 col-md-4 col-sm-4' style={{ "display": "flex", "alignItems": "center" }}>
                                                                    <button className="btn btn--secondary" disabled={disbleAnnotateBtn} onClick={() => { addEntityMapping(selectedSkill, intentObjList[intentIndex]._id, selectedEntityObject.index) }}>Annotate Expression</button>
                                                                </span>

                                                            </div>
                                                        </div>
                                                    )
                                                }

                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div >
                    </div >

                    <div className='col-12 col-xs-12  col-sm-12  col-md-8 col-lg-8  col-xl-8 col-xxl-8 initial_edit_width' >
                        <h5 className='pageTour4'>
                            Expressions
                            <span className=" btn--icon icon-add-contain float-right" style={{ cursor: 'pointer' }} onClick={() => { setShowCreateExpressionScreen(true) }}></span>
                        </h5>
                        <hr />
                        <div className="panel panel--well" style={{ display: showDeleteExpressionScreen ? 'block' : 'none', marginBottom: '10px' }}>
                            <span> Delete Expression - </span><span style={{ textTransform: 'lowercase' }} ><i>{expressionVal}</i> </span>
                            <div className="alert alert--warning-alt">
                                <div className="alert__icon icon-warning-outline"></div>
                                <div className="alert__message">Do you want to delete this Expression?&emsp;</div>
                                <button className="btn btn--small btn--secondary" onClick={() => { deleteExpression(selectedSkill) }}>Yes</button>
                                <button className="btn btn--small btn--secondary" onClick={() => { setShowDeleteExpressionScreen(false) }}>No</button>
                            </div>
                        </div>

                        {viewExpression()}
                    </div >
                </div >}
                {createNewSkill()}

                {createIntent()}
                {editIntent()}
                {createExpression()}
                {paraphraseModal()}


            </div >
        )
    }
    else {
        return (
            <div style={{ "height": "100%" }}>
                {showPageTour === true ? <Joyride
                    callback={handleJoyrideCallback}
                    continuous
                    hideCloseButton
                    run={run}
                    scrollToFirstStep
                    showProgress
                    showSkipButton
                    steps={steps}
                    styles={{
                        options: {
                            zIndex: 10000,
                        },
                        buttonNext: {
                            background: '#1e4471',
                            border: '1px solid #1e4471',
                            borderRadius: '5px'
                        },
                        buttonBack: {
                            color: '#1e4471',
                            border: '1px solid #1e4471',
                            borderRadius: '5px'
                        },
                        buttonSkip: {
                            color: '#1e4471',
                            border: '1px solid #1e4471',
                            borderRadius: '5px'
                        },
                    }}
                /> : ""}
                {
                    isDataLoading && (<Spinner></Spinner>)
                }
                <div style={{ borderBottom: '1px #ced4da solid' }}>
                    <div className="row base-margin-right base-margin-top pageTour1" >
                        <h5 className="col-7 col-md-8 col-sm-pageTour18 col-lg-9 col-xl-9 col-xxl-10 col-xxxl-10 base-margin-top">Intents View</h5>
                        <div className='col-5 col-md-4 col-sm-4 col-lg-3 col-xl-3 col-xxl-2 col-xxxl-2 '>
                            <div className='pageTour2'>{initSkillDropdown()}</div>
                        </div>
                        {expressions.length > 0 ? <hr></hr> : ""}
                        {expressions.length > 0 ? handleExpressions() : ""}
                    </div>
                </div>
                {
                    !showGuidance && showMessageBanner && <MessageBanner message={bannerMessage} messageType={bannerMessageType} onCloseClicked={() => { setShowMessageBanner(false) }}></MessageBanner>
                }
                {
                    showGuidance && <div className="alert alert--info flex flex-center center">
                        <span>It seems like no skill has been created. To create one, please click on <b>Create Skill</b> option from the above dropdown. You can also create a skill by clicking on the <b>Skill View Screen</b> (to create a new skill) or you can explore the <b>Skill library</b> (to reuse already created skills) from the sidebar menu. In case you need any help, please click on the <b>Help</b> option or converse with the <b>AskBotLite chatbot</b>.</span>
                    </div>
                }
                {
                    !showGuidance && intentObjList.length == 0 && <div className="alert alert--info flex flex-center center">
                        <span>It seems like no Intent has been created for the chosen skill yet. To create one, click on the <b>Create Intent</b> button. In case you need any help, you can either select the <b>Help</b> option or converse with the <b>AskBotLite</b> chatbot.</span>
                    </div>
                }
                <div style={{ "height": `calc(100% - ${mobileViewHeightOffset})` }}>
                    {
                        (() => {
                            if (isIntentSelectedInMobileView === false) {
                                //Intent's list
                                return (
                                    <div className='initial_bot_width' style={{ height: "100%" }}>
                                        <h5 className='pageTour3 base-margin-top row'>
                                            Intents
                                            <div>
                                                <span className=" btn--icon icon-add-contain float-right base-margin-left" style={{ cursor: 'pointer' }} title="Create Intent" onClick={() => { setShowCreateIntentScreen(true) }}></span>
                                                <span className='btn--icon icon-delete base-margin-left' disabled={deleteIntentList.length == 0} onClick={() => { setShowDeleteIntentScreen(true) }}></span>
                                            </div>
                                        </h5>
                                        <hr />
                                        <div className="panel panel--well" style={{ display: showDeleteIntentScreen ? 'block' : 'none', marginBottom: '10px' }}>
                                            <span> Delete Intent - </span><span style={{ textTransform: 'lowercase' }} ><i>{intentName}</i> </span>
                                            <div className="alert alert--warning-alt">
                                                <div className="alert__icon icon-warning-outline"></div>
                                                <div className="alert__message">Do you want to delete this Intent?</div>
                                                <button className="btn btn--small btn--secondary" onClick={() => { deleteIntent() }}>Yes</button>
                                                <button className="btn btn--small btn--secondary" onClick={() => { setShowDeleteIntentScreen(false) }}>No</button>
                                            </div>
                                        </div>
                                        {intentObjList && <div id="IntentView" style={{ "height": showDeleteIntentScreen ? "calc(100% - 180px)" : "calc(100% - 66px)", "overflow": "auto" }}>

                                            <div className="pageTour5">
                                                {
                                                    intentObjList.length != 0 && intentObjList.map((intent, index) => {
                                                        return (
                                                            <div key={index} className={`base-margin-bottom panel panel--bordered base-padding panel--raised intentsbox `} style={{ border: intent['_id'] === intentObjList[intentIndex]['_id'] ? '1px solid #0d274d' : '', width: '98%' }}>
                                                                <div className=" row">
                                                                    <span className='col-md-10'>
                                                                        <label className="checkbox" >
                                                                            <input type="checkbox" name={intent['_id']} checked={intent?.isChecked || false} onChange={(e) => { deleteMultipleIntent(e, intent['_id']) }} />
                                                                            <span className="checkbox__input"></span>
                                                                            <span className="checkbox__label">
                                                                                <span className='base-padding-left'>
                                                                                    <a href="#javascript" onClick={() => { setIsIntentSelectedInMobileView(true); intentChange(index, intent); }}>{intent['name']}</a>
                                                                                </span>
                                                                            </span>
                                                                        </label>
                                                                    </span>
                                                                    <span className=" btn--icon icon-delete text-right col-md-1" style={{ cursor: 'pointer' }} onClick={() => { initiateIntentDelete(intent); }}></span>
                                                                    <span className=" btn--icon icon-edit text-right col-md-1" style={{ cursor: 'pointer' }} onClick={() => { initiateIntentEdit(intent); }}></span>
                                                                </div>
                                                                <span style={{ display: intent['description'].length == 0 ? 'none' : 'flex' }}></span>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>}

                                    </div>
                                )
                            }
                            else {
                                //expression's list
                                return (
                                    <div className='initial_edit_width' style={{ height: "100%" }}>
                                        <h5 className='pageTour4 base-margin-top'>
                                            Expressions
                                            <span className="btn--icon icon-add-contain float-right base-margin-left" style={{ cursor: 'pointer' }} onClick={() => { setShowCreateExpressionScreen(true) }}></span>
                                            <span className='btn--icon icon-delete base-margin-left' style={{ cursor: 'pointer' }} disabled={deleteExpressionList.length == 0} onClick={() => { setShowDeleteExpressionScreen(true) }}></span>
                                            <span className='btn--icon icon-clipboard-md base-margin-left' style={{ cursor: 'pointer' }} disabled={deleteExpressionList.length == 0} onClick={() => { paraphraseExpression() }}></span>
                                            <span className=" btn--icon icon-close base-margin-right" style={{ cursor: 'pointer', float: 'right' }} onClick={() => { setIsIntentSelectedInMobileView(false) }}></span>
                                        </h5>
                                        <hr />
                                        <div className="panel panel--well" style={{ display: showDeleteExpressionScreen ? 'block' : 'none', marginBottom: '10px' }}>
                                            <span> Delete Expression - </span><span style={{ textTransform: 'lowercase' }} ><i>{expressionVal}</i> </span>
                                            <div className="alert alert--warning-alt">
                                                <div className="alert__icon icon-warning-outline"></div>
                                                <div className="alert__message">
                                                    Do you want to delete this Expression?&emsp;
                                                    <button className="btn btn--small btn--secondary" onClick={() => { deleteExpression(selectedSkill) }}>Yes</button>
                                                    <button className="btn btn--small btn--secondary" onClick={() => { setShowDeleteExpressionScreen(false) }}>No</button>
                                                </div>

                                            </div>
                                        </div>

                                        {viewExpression()}
                                        {/* {warningMessage()} */}


                                    </div>
                                )
                            }
                        })()
                    }

                </div >
                {createNewSkill()}
                {createIntent()}
                {editIntent()}
                {createExpression()}
                {paraphraseModal()}
            </div >
        )
    }
}
export default IntentView;