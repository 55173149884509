import React from 'react';
import { getBezierPath, MarkerType } from 'react-flow-renderer';

export default function CustomEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {
    strokeWidth: 10,
    stroke: '#FF0072',
  },
  markerEnd = {
    type: MarkerType.Arrow,
    width: 20,
    height: 20,
  },
}) {
  const [edgePath] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  return (
    <>
      <path
        id={id}
        style={style}
        className="react-flow__edge-path"
        d={edgePath}
        markerEnd={markerEnd}

      />
    </>
  );
}