import React, { useState, useEffect, useRef } from "react";
import "./FAQViewComponent.css";
import Modal from 'react-bootstrap/Modal';
import SearchSelect from "../../../../custom-components/search-select/SearchSelect";
import BotService from "../../../bot-module/bot-services/BotService";
import DataGridComponent from "../../../../custom-components/data-grid/DataGrid";
import FileDropZone from "../../../../custom-components/file-drop-zone/FileDropZone";
import FAQService from "../../faq-service/FAQService";
import { useNavigate } from "react-router-dom";
import Spinner from "../../../../custom-components/spinner/Spinner";
import Joyride, { CallBackProps, STATUS, Step } from 'react-joyride';
import FileParser from "../../../../utils/FileParser";
import MessageBanner from "../../../../custom-components/MessageBanner/MessageBanner";
import TimezoneConverter from "../../../../utils/TimeZoneConverter";
import { dateTimeFormatChange } from "../../../../utils/utils";
import MultiSelect from '../../../../custom-components/multi-select/MultiSelect';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ScheduleIcon from '@mui/icons-material/Schedule';
import MemoryIcon from '@mui/icons-material/Memory';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const ToolTip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize:12
  },
});

const FAQView = () => {
  const MAX_FILE_TRANSFER_SIZE = 3000000;
  const navigate = useNavigate();
  const [userCec, setUserCec] = useState(undefined);
  const [isDataLoading, setDataLoading] = useState(false);
  const [botslist, setBotslist] = useState()
  const [defaultbotvalue, setDefaultbotvalue] = useState()
  const [selectedBot, setSelectedBot] = useState();
  const [showGuidance, setShowGuidance] = useState(false);
  const [uploadData, setUploadData] = useState(false);
  const [showQnA, setShowQnA] = useState(false)
  const [addQnA, setAddQnA] = useState(false);
  const [formatted, setFormatted] = useState(true)
  const [filename, setFilename] = useState()
  const [faqFileRows, setFAQFileRows] = useState([]);
  const [qaRows, setQARows] = useState([]);
  const [showQARetrivalFailureMessage, setShowQARetrivalFailureMessage] = useState(false);
  const [qaRetrivalFailureMessage, setQARetrivalFailureMessage] = useState('Failed to retrieve data. Please contact dev team.');
  const [uploadedFile, setUploadedFile] = useState(undefined);
  const [QnAdata, setQnAdata] = useState({ botQuestion: "", botAnswer: "" })
  const [enableBtn, setEnableBtn] = useState(true)
  const [enableDeleteBtn, setEnableDeleteBtn] = useState(true);
  const [selectedFileName, setSelectedFileName] = useState("");
  const [showFileFormatHelp, setShowFileFormatHelp] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [showFaqErrorMessage, setShowFaqErrorMessage] = useState(false);
  const [faqErrorMessage, setFaqErrorMessage] = useState('');
  const [selectedFileIds, setSelectedFileIds] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [uploadIntegration, setUploadIntegration] = useState(false);
  const [integrationType, setIntegrationType] = useState("");
  const [integrationAccessToken, setIntegrationAccessToken] = useState("");
  const [githubOrganization, setGithubOrganization] = useState("");
  const [githubRepository, setGithubRepository] = useState("");
  const [githubBranchSelection, setGithubBranchSelection] = useState([]);
  const [githubBranch, setGithubBranch] = useState("");
  const [integrationFileSelection, setIntegrationFileSelection] = useState([]);
  const [integrationFiles, setIntegrationFiles] = useState([]);
  const [defaultLLMModel, setDefaultLLMModel] = useState({
    label: "Open Source Model 1",
    value: "osm1"
  });
  const [llmModelOptions, setLLMModelOptions] = useState([
    {label:"Open Source Model 1", value: "osm1"}, {label:"Cisco Enterprise Chat AI", value: "coai"}
  ])
  const [selectedLLMModel, setSelectedLLMModel] = useState(()=>{
    let llmFromLs = localStorage.getItem("llmModelChoice");
    if(llmFromLs === undefined){
      localStorage.setItem("llmModelChoice", defaultLLMModel.value);
      return defaultLLMModel;
    }
    else{
      for(let model of llmModelOptions){
        if(model.value === llmFromLs){
          return model;
        }
      }
    }
    return defaultLLMModel;
  });

  const [llmModelClientId, setLLMModelClientId] = useState('');
  const [llmModelClientSecret, setLLMModelClientSecret] = useState('');
  const [llmModelClientUrl, setLLMModelClientUrl] = useState('');
  const [llmModelClientAppKey, setLLMModelClientAppKey] = useState('');
  const [llmModelsWithToken, setLLMModelsWithToken] = useState(["coai"]);
  const [showLLMSelectionModal, setShowtLLMSelectionModal] = useState(false);
  const [isGenAiEnabled, setIsGenAiEnabled] = useState(false);
  const [genAi, setGenAi] = useState('')
  const [genAiBtnText,setGenAiBtnText] = useState('')
  const [isTrainButtonEnabled, setTrainingButtonEnabled] = useState(false);
  const [showFAQScreen, setShowFAQScreen] = useState(false);

  const [{ run, steps }, setState] = useState({
    run: true,
    steps: [
      {
        content: <h6>Welcome to <b>FAQ Bot</b>. Here, you can manage FAQ bot details.</h6>,
        locale: { skip: <span aria-label="skip">Skip</span> },
        placement: 'center',
        target: '.pageTour1',
      },
      {
        content: <h6>Here, you can select bot from this dropdown.</h6>,
        floaterProps: {
          disableAnimation: true,
        },
        spotlightPadding: 20,
        target: '.pageTour2',
      },
      {
        content: <h6>Here you can upload files.</h6>,
        floaterProps: {
          disableAnimation: true,
        },
        spotlightPadding: 20,
        target: '.pageTour3',
      },
      {
        content: <h6>On click of the button, you will be navigated to the training screen</h6>,
        floaterProps: {
          disableAnimation: true,
        },
        spotlightPadding: 20,
        target: '.pageTour4',
      },
      {
        content: <h6>This is the bot file details screen.</h6>,
        floaterProps: {
          disableAnimation: true,
        },
        spotlightPadding: 20,
        target: '.pageTour5',
      },
    ]
  });
  const handleJoyrideCallback = (data) => {
    const { status, type } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setState({ run: false });
    }
  };

  useEffect(() => {
    setDataLoading(true);
    getBotList();
    FAQService.getUserDetails().then(res => res.json()).then((res) => {
      if (res.status === "success") {
        setUserCec(res.user.email.split("@")[0]);
        setDataLoading(false);
      }
    }).catch((err) => {
      console.log(err);
      setDataLoading(false);
    })
  }, [])

  useEffect(() => {
    setShowGuidance(false);
    if (isDataLoading === false && faqFileRows && faqFileRows.length === 0) {
      setShowGuidance(true);
    }
  }, [faqFileRows, isDataLoading])

  useEffect(() => {
    setErrorMessage('');
    setShowErrorMessage(false);
    setDataLoading(true);
    setIsGenAiEnabled(false);
    let tempIsTrainingButtonEnabled = true;
    //update FAQ file names based on bot selected
    if (selectedBot) {
      FAQService.getFAQFiles(selectedBot).then(res => res.json()).then(res => {
        setFAQFileRows(() => {
          let temp = [];
          if (res["files"]) {
            res["files"].map((row) => {
              // remove seconds field in time stamp if present
              // let timeStamp = row["updated_at"].split(":");
              // if (timeStamp && timeStamp.length > 1) {
              //   updated_at = `${timeStamp[0]}:${timeStamp[1]}:${timeStamp[2].split(".")[0]}`;
              // }
              let updated_at = TimezoneConverter(row["updated_at"]);
              updated_at = dateTimeFormatChange(updated_at)
              temp.push({
                id: row["_id"],
                FileName: row["filename"],
                FileType: row['type'],
                UpdatedBy: row["updated_by"],
                UpdatedAt: updated_at,
                Count: row["count"],
                is_processed: row["is_processed"],
                is_ga_enabled: row["is_ga_enabled"]
              })
              tempIsTrainingButtonEnabled = tempIsTrainingButtonEnabled &&  row["is_processed"];
              
            })
          }
          setTrainingButtonEnabled(tempIsTrainingButtonEnabled);
          setDataLoading(false);
          return temp;
        })
        FAQService.getAdvancedSettings(selectedBot).then((res)=>res.json()).then((res)=>{
          if(res["status"] === "success"){
            if(res["data"]["llm"]){
              //setSelectedLLMModel(res["llm"]);
              setIsGenAiEnabled(res["data"]["llm_enabled"]||false);
              let idx = llmModelOptions.indexOf(res["llm"]);
              if(idx !== -1){
                setSelectedLLMModel(llmModelOptions[idx]);
              }
            }
            if(res["data"]["llm_client_id"]){
              setLLMModelClientId(res["data"]["llm_client_id"]);
            }
            if(res["data"]["llm_client_url"]){
              setLLMModelClientUrl(res["data"]["llm_client_url"]);
            }
            if(res["data"]["llm_client_secret"]){
              setLLMModelClientSecret(res["data"]["llm_client_secret"]);
            }
            if(res["data"]["llm_client_appkey"]){
              setLLMModelClientAppKey(res["data"]["llm_client_appkey"]);
            }

          }
          
          
        },(err)=>{
          console.log(err);
          setErrorMessage('Failed to retrieve advanced settings');
          setShowErrorMessage(true);
          setDataLoading(false);
        })
        setDataLoading(false);
      }).catch(err => {
        console.log(err);
        setErrorMessage('Failed to retrieve FAQ file details');
        setShowErrorMessage(true);
        setDataLoading(false);
      })
    }

  }, [selectedBot])
 
  const getBotList = () => {
    setShowFAQScreen(false);
    setDataLoading(true);
    setErrorMessage('');
    setShowErrorMessage(false);
    BotService.listBots().then(res => res.json()).then(res => {
      if (res.status == 'success') {
        if (res.bots.length > 0) {
          setShowGuidance(false);
          setShowFAQScreen(true);
          setBotslist(res.bots.map((btslist, index) => {
            return {
              "label": btslist.display_name,
              "value": btslist.display_name,
              "key": btslist._id,
              "skills": btoa(JSON.stringify(btslist.skills)),
              "trained": btslist.upToDate
              // let encodedValue = btoa(myString);
              // let decodedValue = atob(encodedValue);
            }
          }
          ));
          if (localStorage.getItem('botSelectedName') && localStorage.getItem('botSelectedId')) {
            setDefaultbotvalue({
              "label": localStorage.getItem('botSelectedName'),
              "value": localStorage.getItem('botSelectedName'),
              "key": localStorage.getItem('botSelectedId'),
              "skills": JSON.parse(atob(localStorage.getItem('botSkills'))),
              "trained": localStorage.getItem('botTrained'),
            })
            //setSelectedBot(res.bots[0]._id);
            setSelectedBot(localStorage.getItem('botSelectedId'));
          }
          else {
            setDefaultbotvalue({
              "label": res.bots[0].display_name,
              "value": res.bots[0].display_name,
              "key": res.bots[0]._id,
              "skills": res.bots[0].skills,
              "trained": res.bots[0].upToDate
            })

            localStorage.setItem('botSelectedName', res.bots[0].display_name)
            localStorage.setItem('botSelectedId', res.bots[0]._id)
            localStorage.setItem('botSkills', btoa(JSON.stringify(res.bots[0].skills)))
            localStorage.setItem('botTrained', res.bots[0].upToDate)

            setSelectedBot(res.bots[0]._id);
          }

        } else {
          setDataLoading(false);
          setShowGuidance(true);
        }
        setDataLoading(false);
      } else {
        setDataLoading(false);
      }

      setDataLoading(false);
    }, err => {
      console.log('error')
      setErrorMessage('Failed to retrieve bots');
      setShowErrorMessage(true);
      setDataLoading(false);
    })
  }
  const botsName = () => {
    return <SearchSelect
      id='botsName'
      className='augmentSelect'
      options={botslist}
      defaultValue={defaultbotvalue}
      onValueChange={(data) => {
        localStorage.setItem('botSelectedName', data.label)
        localStorage.setItem('botSelectedId', data.key)
        localStorage.setItem('botSkills', btoa(JSON.stringify(data.skills)))
        localStorage.setItem('botTrained', data.upToDate || data.trained)
        setSelectedBot(data.key)

      }}
    />
  }

  const faq_col = [
    {
      field: 'FileName',
      headerName: 'File Name',
      flex: 1.5,
      renderCell: (params) => {
        const fileHandler = (e) => {
          e.stopPropagation();
          setDataLoading(true);
          setShowQnA(true);
          setShowFaqErrorMessage(false);
          const api = params.api;
          const thisRow = {};
          setFilename(params.row.FileName);
          setGenAi(params.row.is_ga_enabled)
          params.row.is_ga_enabled === true ? setGenAiBtnText("Disable") : setGenAiBtnText("Enable")

          //fetch QA rows based on the file clicked
          setSelectedFileName(params.row.FileName);
          setShowQARetrivalFailureMessage(false);
          FAQService.getQA(selectedBot, params.row.FileName).then(res => res.json()).then(res => {
            if (res["status"] && res["status"] == "success") {
              setQARows(() => {
                let temp = [];
                res["question_answers"].map(row => {
                  temp.push({
                    id: row["_id"],
                    question: row["question"],
                    answer: row["answer"],
                    editrow: false,
                    checked: false
                  })
                })
                setDataLoading(false);
                return temp;
              })
            }
            else {
              if (res["details"]) {
                setQARetrivalFailureMessage(res["details"]);
                setShowQARetrivalFailureMessage(true);
              }
              setDataLoading(false);
            }
          }).catch(err => {
            console.log(err);
            setQARetrivalFailureMessage('Failed to retrieve data. Please contact dev team.');
            setShowQARetrivalFailureMessage(true);
            setDataLoading(false);
          })
        };
        if (params.row.FileType !== "Unformatted") {
          return <div className="fileNameColumnCell"><a className="" onClick={fileHandler}>{params.row.FileName}</a> 
          <div className="statusIcon">{params["row"]["is_ga_enabled"] === true ? <ToolTip title="Generative AI is Enabled" arrow><MemoryIcon style={{marginLeft:10, fontSize:19 }}/></ToolTip> : ""}{params["row"]["is_processed"] === true ? <ToolTip title="Ready to Train Bot" arrow><CheckCircleOutlineIcon color="success" style={{marginLeft:10, color: "green", fontSize:18 }}/></ToolTip> : <ToolTip title="File is not ready to Train Bot" arrow><ScheduleIcon color="primary" style={{marginLeft:10, fontSize:18 }}/></ToolTip>}</div>
          </div>
        }
        else {
          return <div className="fileNameColumnCell"><span>{params.row.FileName}</span> 
          <div className="statusIcon">{params["row"]["is_ga_enabled"] === true ? <ToolTip title="Generative AI is Enabled" arrow><MemoryIcon style={{marginLeft:10, fontSize:19 }}/></ToolTip> : ""}{params["row"]["is_processed"] === true ? <ToolTip title="Ready to Train Bot" arrow><CheckCircleOutlineIcon color="success" style={{marginLeft:10, color: "green", fontSize:18 }}/></ToolTip> : <ToolTip title="File is not ready to Train Bot" arrow><ScheduleIcon color="primary" style={{marginLeft:10, fontSize:18 }}/></ToolTip>}</div>
          </div>
        }
      }
    },
    {
      field: 'FileType',
      headerName: 'FileType',
      flex: 1,
    },
    {
      field: 'UpdatedBy',
      headerName: 'Updated By',
      flex: 1,
    },
    {
      field: 'UpdatedAt',
      headerName: 'Updated At',
      flex: 1,
    },
    {
      field: 'Count',
      headerName: 'Count',
      flex: 0.5,
    }
  ]



  const QnA_col = [
    {
      field: 'Question',
      headerName: 'Question',
      flex: 1,
      editable: true,
    }, {
      field: 'Answer',
      headerName: 'Answer',
      flex: 1,
      editable: true,
    }, {
      field: 'Edit',
      headerName: 'Edit',
      maxwidth: 70,
      sortable: false,
      // renderCell: (params) => {
      //   const editlist = (e) => {
      //     e.stopPropagation();
      //     const api = params.api;
      //     const thisRow = {};
      //     console.log(params.row.id)
      //   };
      //   return <>
      //     <span className="icon-edit editIcon" onClick={editlist} />
      //   </>;
      // }
    }
  ]


  const inputHandler = (e) => {
    let newData = QnAdata;
    let type = e.target.name;
    newData[type] = e.target.value;
    setQnAdata(newData);
    if (QnAdata.botQuestion.length > 0 && QnAdata.botAnswer.length > 0)
      setEnableBtn(false)
    else setEnableBtn(true)
  }

  const addFaq = () => {
    setFaqErrorMessage('');
    setShowFaqErrorMessage(false);
    setDataLoading(true);
    FAQService.addFaq(selectedBot, selectedFileName, QnAdata["botQuestion"], QnAdata["botAnswer"]).then(res => res.json()).then(res => {
      if (res["status"] && res["status"] === "success") {
        setAddQnA(false);
        FAQService.getQA(selectedBot, selectedFileName, 1).then(res => res.json()).then(res => {
          if (res["status"] && res["status"] == "success") {
            setQARows(() => {
              let temp = [];
              res["question_answers"].map(row => {
                temp.push({
                  id: row["_id"],
                  question: row["question"],
                  answer: row["answer"],
                  editrow: false,
                  checked: false
                })
              })
              setDataLoading(false);
              return temp;
            })
          }
          else {
            setFaqErrorMessage('Failed to add new QA pair. Please contact dev team.');
            setShowFaqErrorMessage(true);
            setDataLoading(false);
          }
        }).catch(err => {
          console.log(err);
          setFaqErrorMessage('Failed to add new QA pair. Please contact dev team.');
          setShowFaqErrorMessage(true);
          setDataLoading(false);
        })
      }
      else {
        setFaqErrorMessage('Failed to add new QA pair. Please contact dev team.');
        setShowFaqErrorMessage(true);
        setDataLoading(false);
      }
    }).catch(err => {
      console.log(err);
      setErrorMessage('Failed to add new QA pair. Please contact dev team.');
      setShowErrorMessage(true);
    })
  }

  const uploadFile = async () => {
    
    setErrorMessage('');
    setShowErrorMessage(false);
    setDataLoading(true);
    setUploadData(false);
    let isUnformattedUpload = uploadType === "Formatted" ? false : true;

    if(!isGenAiEnabled && isUnformattedUpload){
      //user our FAQ formatter service to gen QA pair
      // this service dosent allow upload in chunk due to time out of 30sec in Lambda
      let maxTimeOutRetry = 3;

      for(let i=0;i<maxTimeOutRetry;i++){
        try{
          let responseObj = await FAQService.faqQAFormatter(uploadedFile.data, uploadedFile.name);
          if(responseObj.status === 504){
            console.log("Time out!");
          }
          else if (responseObj.status !== 200) {
            //display error message
            setErrorMessage('Failed to upload file');
            setShowErrorMessage(true);
            setDataLoading(false);
            return;
          }
          else{
            let response = await responseObj.json();
            if (response["status"] && response["status"] === "failure") {
              //display error message
              setErrorMessage('Failed to upload file');
              setShowErrorMessage(true);
              setDataLoading(false);
              return;
            }

            let qaDataList = [];
            for(let key in response["data"]["answers"]){
              qaDataList.push({
                "question": response["data"]["questions"][key],
                "answer": response["data"]["answers"][key]
              })
            }

            let qaData = btoa(unescape(encodeURIComponent(JSON.stringify(qaDataList))));
            let newFileName = uploadedFile.name.split(".")[0]+".json";
            let fileUploadResponseObj = await FAQService.uplodDocumentInChunks(selectedBot, false, qaData, newFileName, 0, true, userCec);

            if (fileUploadResponseObj.status !== 200) {
              //display error message
              setErrorMessage('Failed to upload file');
              setShowErrorMessage(true);

              setDataLoading(false);
              break;
            }
            response = await fileUploadResponseObj.json();
            if (response["status"] && response["status"] === "failure") {
              //display error message
              setErrorMessage('Failed to upload QA pairs!');
              setShowErrorMessage(true);
              setDataLoading(false);
              break;
            }

            else{
              FAQService.getFAQFiles(selectedBot).then(res => res.json()).then(res => {
                setFAQFileRows(() => {
                  let temp = [];
                  if(res && res["files"]) {
                    res["files"].map((row) => {
                      // remove seconds field in time stamp if present
                      // if (timeStamp && timeStamp.length > 1) {
                      //   updated_at = `${timeStamp[0]}:${timeStamp[1]}:${timeStamp[2].split(".")[0]}`;
                      // }
                      let updated_at = TimezoneConverter(row["updated_at"]);
                      temp.push({
                        id: row["_id"],
                        FileName: row["filename"],
                        FileType: row['type'],
                        UpdatedBy: row["updated_by"],
                        UpdatedAt: updated_at,
                        Count: row["count"],
                        is_processed: row["is_processed"],
                        is_ga_enabled: row["is_ga_enabled"]
                      })
                    })
                  }
                  setDataLoading(false);
                  return temp;
                })
      
              }).catch(err => {
                console.log(err);
                setErrorMessage('Failed to upload file');
                setDataLoading(false);
                setShowErrorMessage(true);
              })
              return;
            }
    
          }
        }
        catch(err){
            console.log(err);
        }

      }

    }
    else{
      
      FAQService.getPresignedURL(selectedBot, uploadedFile.name).then(res=>res.json()).then((res)=>{
        if(res["status"] && res["status"] === "success"){
          let s3_filename = res["file_name"]
          let presignedURLDetails = res["presigned_url_details"];

          const formData = new FormData();
          for (const key in presignedURLDetails['fields']) {
              formData.append(key, presignedURLDetails['fields'][key]);
          }
          formData.append('file', uploadedFile.file);
  
          fetch(presignedURLDetails["url"], {
            method: 'POST',
            body: formData,
          }).then(res=>res.text()).then((res)=>{
            
            
  
            var presignedURLPayload = {
                "bot_id": selectedBot,
                "user_cec": userCec,
                "filename": uploadedFile.name,
                "is_unformatted_upload": isUnformattedUpload,
                "s3_filename": s3_filename,
                "integration": "fileupload"
            };

  
            FAQService.uploadFileToPresignedURL(selectedBot, presignedURLPayload).then(res=>res.json()).then((res)=>{
                // once file is uploaded succesfully
                FAQService.getFAQFiles(selectedBot).then(res => res.json()).then(res => {
                  setFAQFileRows(() => {
                    let temp = [];
                    let tempIsTrainingButtonEnabled = true;
                    if(res["files"]) {
                      res["files"].map((row) => {
                        let updated_at = TimezoneConverter(row["updated_at"]);
                        temp.push({
                          id: row["_id"],
                          FileName: row["filename"],
                          FileType: row['type'],
                          UpdatedBy: row["updated_by"],
                          UpdatedAt: updated_at,
                          Count: row["count"],
                          is_processed: row["is_processed"],
                          is_ga_enabled: row["is_ga_enabled"]                
                        })
                        tempIsTrainingButtonEnabled = tempIsTrainingButtonEnabled &&  row["is_processed"];
                      })
                      setTrainingButtonEnabled(tempIsTrainingButtonEnabled);
                    }
                    setDataLoading(false);
                    return temp;
                  })

                }).catch(err => {
                  console.log(err);
                  setErrorMessage('Failed to upload file');
                  setDataLoading(false);
                  setShowErrorMessage(true);
                })
            })
      
          },(err)=>{
              setErrorMessage('Failed to upload file');
              setDataLoading(false);
              setShowErrorMessage(true);
          });
        }
        else{
          setErrorMessage('Failed to generate upload link');
          setDataLoading(false);
          setShowErrorMessage(true);
        }
      })
    }


  }

  const [updateQnA, setUpdateQnA] = useState([{ "qst": "", "ans": "" }])


  const editlist = (id) => {
    setQARows((prev) => {
      let temp = [...prev];
      for (let i of temp) {
        if (i.id === id) {
          i.editrow = true;
        }
        else {
          i.editrow = false;
        }
      }
      return temp;
    })
  }
  const txtHandler = (e, type, id) => {
    setQARows((prev) => {
      let temp = [...prev];
      for (let i of temp) {
        if (i.id === id) {
          if (type === "question") {
            i.question = e.target.value;
          } else {
            i.answer = e.target.value;
          }
        }
      }
      return temp;
    })
    setUpdateQnA((prev) => {
      let temp = [...prev]
      if (type === "qst") {
        //i.question = e.target.value;
      } else {
        //i.answer = e.target.value;
      }
      return temp
    })
  }

  const setDeleteList = (id) => {
    setQARows((prev) => {
      let temp = [...prev];
      for (let i = 0; i < temp.length; i++) {
        if (temp[i].id === id) {
          temp[i].checked = !temp[i].checked;
        }
      }
      return temp;
    })
  }

  const deleteFAQs = () => {
    setShowFaqErrorMessage(false);
    setFaqErrorMessage('');
    setDataLoading(true);
    let rowSelected = [];
    for (let row of qaRows) {
      if (row.checked) {
        rowSelected.push(row.id);
      }
    }
    FAQService.deleteFAQs(selectedBot, selectedFileName, rowSelected).then((res) => res.json()).then((res) => {
      setShowFaqErrorMessage(false);
      if (res["status"] === "success") {
        setQARows((prev) => {
          let temp = [];
          for (let row of prev) {
            if (rowSelected.indexOf(row.id) === -1) {
              temp.push(row);
            }
          }
          setDataLoading(false);
          return temp;
        })
      } else {
        setDataLoading(false);
        setShowFaqErrorMessage(true);
        setFaqErrorMessage(res["details"] || 'Could not delete the selected FAQ/s, please try again.')
      }
    }, (err) => {
      setDataLoading(false);
      setShowFaqErrorMessage(true);
      setFaqErrorMessage('Could not delete the selected FAQ/s, please try again.')
    })
  }
  const deleteFiles = () => {
    setShowErrorMessage(false);
    setErrorMessage('');
    setDataLoading(true);

    FAQService.deleteFiles(selectedBot, selectedFileIds).then((res) => res.json()).then((res) => {
      if (res["status"] === "success") {
        FAQService.getFAQFiles(selectedBot).then(res => res.json()).then(res => {
          setFAQFileRows(() => {
            let temp = [];
            if(res["files"]) {
              res["files"].map((row) => {
                let updated_at = TimezoneConverter(row["updated_at"]);
                temp.push({
                  id: row["_id"],
                  FileName: row["filename"],
                  FileType: row['type'],
                  UpdatedBy: row["updated_by"],
                  UpdatedAt: updated_at,
                  Count: row["count"],
                  is_processed: row["is_processed"],
                  is_ga_enabled: row["is_ga_enabled"]
                })
              })
            }
            setDataLoading(false);
            return temp;
          })
          setDataLoading(false);
        }).catch(err => {
          console.log(err);
          setErrorMessage('Failed to retrieve FAQ file details');
          setShowErrorMessage(true);
          setDataLoading(false);
        })

      }
      else {
        setDataLoading(false);
        setShowErrorMessage(true);
        setErrorMessage(res['details'] || 'Could not delete selected files please try again.');
      }
    }, (err) => {
      setDataLoading(false);
      setShowErrorMessage(true);
      setErrorMessage('Could not delete selected files please try again.');
    })
  }

  const updateQA = (id, index) => {
    setShowFaqErrorMessage(false);
    setDataLoading(true);
    FAQService.updateFaq(selectedBot, id, selectedFileName, qaRows[index].question, qaRows[index].answer, userCec).then((res) => res.json()).then((res) => {
      if (res["status"] === "success") {
        setQARows((prev) => {
          let temp = [...prev];
          for (let i of temp) {
            i.editrow = false
          }
          setDataLoading(false);
          return temp;
        })
      }
      else {
        setDataLoading(false);
        setShowFaqErrorMessage(true);
        setFaqErrorMessage(res['details'] || 'Failed to update FAQ please try again.')
      }
    }, (err) => {
      setDataLoading(false);
      setFaqErrorMessage('Failed to update FAQ please try again.');
      setShowFaqErrorMessage(true);
      console.log(err);
    })

  }
  const [uploadType, setUploadType] = useState("Formatted")
  const [unformattedType, setUnformattedType] = useState("FormatFAQ")
  const [tab_selected, switchTab] = useState('github')
  const [taglist, setTaglist] = useState(["#open", "#close", "#new", "#format", "#details", "#other", "#state", "#list", "#tags", "#data", "#right", "#left", "#type", "#employee", "#userDetails", "#projectDetails", "#taglist", "#datalist"])
  const [addedTaglist, setAddedTaglist] = useState([])
  const [btnEnable, setBtnEnable] = useState(true);

  const urlChange = (e) => {
    let temp = e.target.value;
    setBtnEnable(true);
    const url = isUrlValid(temp)
    if (url) {
      setBtnEnable(false)
    } else {
      setBtnEnable(true)
    }
  }
  const isUrlValid = (userInput) => {
    var res = userInput.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
    if (res == null)
      return false;
    else
      return true;
  }
  const addTag = (list) => {
    setAddedTaglist((prev) => {
      let temp = [...prev];
      temp.push(list);
      return temp;
    })
    setTaglist(oldValues => {
      return oldValues.filter(taglist => taglist !== list)
    })
  }
  const deleteTag = (list) => {
    setAddedTaglist(oldValues => {
      return oldValues.filter(taglist => taglist !== list)
    })
    setTaglist((prev) => {
      let temp = [...prev];
      temp.push(list);
      return temp;
    })
  }

  const processFileData = (data) => {
    setBtnEnable(true);
    if (data != "" || data != undefined) {
      setUploadedFile(data[0]);
      setBtnEnable(false)
    }
  }

  const filterQna = () => {
    setDataLoading(true);
    FAQService.filterQA(selectedBot, filename, 0, searchQuery).then((res) => res.json()).then((res) => {
      setQARows(() => {
        let temp = [];
        res["question_answers"].map(row => {
          temp.push({
            id: row["_id"],
            question: row["question"],
            answer: row["answer"],
            editrow: false,
            checked: false
          })
        })
        setDataLoading(false);
        return temp;
      })
    })
  }

  const LLMNames = () => {
    return <>
      <label>Please select the LLM Model</label>
      <SearchSelect
        id='LLMName'
        className='augmentSelect'
        options={llmModelOptions}
        defaultValue={selectedLLMModel}
        onValueChange={(data) => {
          setLLMModelClientId('');
          setLLMModelClientSecret('');
          setSelectedLLMModel(data);
        }}
      />
    </>

  }

  const openLLMSelectionModal = ()=>{
    setShowtLLMSelectionModal(true);
  }

  const saveAdvancedSettings = ()=>{
    setDataLoading(true);
    setShowtLLMSelectionModal(false);
    setShowErrorMessage(false);
    setErrorMessage('');
    localStorage.setItem('llmModelChoice', selectedLLMModel.value);
    FAQService.updateAdvancedSettings(selectedBot, selectedLLMModel.value, llmModelClientId || "", llmModelClientSecret || "", llmModelClientUrl || "", llmModelClientAppKey || "").then((res)=>res.json()).then((res)=>{
      if(res["status"] !== "success"){
        setDataLoading(false);
        setFaqErrorMessage('Failed to model settings.');
        setShowFaqErrorMessage(true);
      }
      setDataLoading(false);
    },(err)=>{
      setDataLoading(false);
      setDataLoading(false);
      setFaqErrorMessage('Failed to model settings.');
      setShowFaqErrorMessage(true);
      console.log(err);
    })
    
  }

  const enableGenerativeAi = () => {
    let status = genAi === true ? false : true
    setDataLoading(true);
    FAQService.enableGenAi(selectedBot, selectedFileName, status).then(res => res.json()).then(res => {
      genAiBtnText === "Enable" ? setGenAiBtnText("Disable") : setGenAiBtnText("Enable")

      if(res["status"] === "success"){
        FAQService.getFAQFiles(selectedBot).then(res => res.json()).then(res => {
          setFAQFileRows(() => {
            let temp = [];
            if(res["files"]) {
              res["files"].map((row) => {
                let updated_at = TimezoneConverter(row["updated_at"]);
                temp.push({
                  id: row["_id"],
                  FileName: row["filename"],
                  FileType: row['type'],
                  UpdatedBy: row["updated_by"],
                  UpdatedAt: updated_at,
                  Count: row["count"],
                  is_processed: row["is_processed"],
                  is_ga_enabled: row["is_ga_enabled"]
                })
              })
              return temp;
            }
          })

        })
      }
      setDataLoading(false);
    }).catch(err => {
      setDataLoading(false);
      console.log(err);
    })
  }

  const integrationAuth = (integration)=>{
    setDataLoading(true);
    setShowErrorMessage(false);
    setErrorMessage('');
    setIntegrationType(integration);
    setIntegrationAccessToken(document.getElementById('faq-github-token').value);
    try{
      let github_url = new URL(document.getElementById('faq-github-repository').value);
      github_url = github_url.pathname.split("/");
      setGithubOrganization(github_url[1]);
      setGithubRepository(github_url[2]);
    }
    catch(err){
      setErrorMessage('Invalid URL inputted. Please try entering the URL again.');
      setShowErrorMessage(true);
    }
    
    FAQService.faqIntegrationAuth(integrationType, integrationAccessToken, githubOrganization, githubRepository).then((res)=>res.json()).then((res)=>{
      if(res["status"] !== "success"){
        setDataLoading(false);
        setGithubBranchSelection(res["repo_branches"]);
      }
      setDataLoading(false);
    },(err)=>{
      setDataLoading(false);
      setErrorMessage('Failed to authenticate integration.');
      setShowErrorMessage(true);
    })
  }

  const integrationFileRetrieval = () => {
    setDataLoading(true);
    setShowErrorMessage(false);
    setErrorMessage('');
    FAQService.faqIntegrationFiles(integrationType, integrationAccessToken, githubOrganization, githubRepository, githubBranch).then((res)=>res.json()).then((res)=>{
      if(res["status"] !== "success"){
        setDataLoading(false);
        setIntegrationFileSelection(res["repo_files"]);
      }
      setDataLoading(false);
    },(err)=>{
      setDataLoading(false);
      setErrorMessage('Failed to retrieve integration files.');
      setShowErrorMessage(true);
    })
  }

  const integrationContentsUpload = () => {
    setDataLoading(true);
    setShowErrorMessage(false);
    setErrorMessage('');
    setUploadType("Unformatted");
    FAQService.faqIntegrationFileContents(integrationType, integrationAccessToken, githubOrganization, githubRepository, githubBranch, integrationFiles).then((res)=>res.json()).then((res)=>{
      if(res["status"] !== "success"){
        setDataLoading(false);
        setIntegrationFiles(res["file_contents"]);
        for(let file in integrationFiles){
          if (file["data"] != "" || file["data"] != undefined) {
            file["data"] = btoa(file["data"]);
            setUploadedFile(file);
            uploadFile();
          }
        }
      }
      setDataLoading(false);
    },(err)=>{
      setDataLoading(false);
      setErrorMessage('Failed to get integration file contents.');
      setShowErrorMessage(true);
    })
  }

  return (
    <div style={{ height: "100%" }}>
      {showErrorMessage && <MessageBanner messageType="error" message={errorMessage} onCloseClicked={() => { setShowErrorMessage(false) }}></MessageBanner>}
      {!showErrorMessage && !showFAQScreen && <div style={{"fontSize":"large", "position":"relative", "top":"calc(50vh - 60px)"}}><MessageBanner messageType="info" message={"Please create a bot to use this feature. If your bot request is in pending state, please wait for the request to get approved."}></MessageBanner></div>}
      {showFAQScreen && isDataLoading != true && showGuidance ? <Joyride
        callback={handleJoyrideCallback}
        continuous
        hideCloseButton
        run={run}
        scrollToFirstStep
        showProgress
        showSkipButton
        steps={steps}
        styles={{
          options: {
            zIndex: 10000,
          },
          buttonNext: {
            background: '#1e4471',
            border: '1px solid #1e4471',
            borderRadius: '5px'
          },
          buttonBack: {
            color: '#1e4471',
            border: '1px solid #1e4471',
            borderRadius: '5px'
          },
          buttonSkip: {
            color: '#1e4471',
            border: '1px solid #1e4471',
            borderRadius: '5px'
          },
        }}
      /> : ""}
      {
        isDataLoading && (<Spinner></Spinner>)
      }
      {showFAQScreen && <>
        <div style={{ borderBottom: '1px #ced4da solid' }}>
        <div className="row base-margin-right pageTour1" >
          <h5 className="col-8 col-md-8 col-sm-8 col-lg-9 col-xl-9 col-xxl-10 col-xxxl-10 module-header-text">FAQ Bot</h5>
          <div className='col-4 col-md-4 col-sm-4 col-lg-3 col-xl-3 col-xxl-2 col-xxxl-2 '>
            <div className='pageTour2'>
              {botsName()}
            </div>
          </div>
        </div>
      </div>
      <div className="faqContainer">
        <div className='row' style={{ marginRight: '16px' }}>
          <div className="col-12 col-lg-12" style={{ textAlign: "right" }}>
            <button className='pageTour3 btn btn--secondary' onClick={() => { setUploadData(true) }}>
              Upload Data
            </button>
            {/* This section has button to enable GitHub, Quip and Sharepoint integration.
                This can be enabled once we are ready to enable this feature
            <button className='pageTour3 btn btn--secondary' onClick={() => { setUploadIntegration(true) }}>
              Configure Integration
            </button> */}
            <button className='pageTour4 btn btn--secondary' onClick={() => navigate("/training")} disabled={!isTrainButtonEnabled}>
              Train Bot
            </button>
            <button className='pageTour5 btn btn--primary' onClick={() => { deleteFiles() }}>
              Delete
            </button>
            <button className='pageTour6 btn btn--primary' onClick={() => { openLLMSelectionModal() }}>
              LLM Model Settings
            </button>
          </div>
        </div>
        <div className="row" style={{ height: "calc(100% - 68px)", marginRight: '16px' }}>
          <div className="col-lg-12 faqDataTable pageTour5" style={{"height":"100%"}}>
            <DataGridComponent rows={faqFileRows} columns={faq_col} rowHeight={70} ht={'100%'} setSelectedExpression={(data) => { setSelectedFileIds(data) }} checkbox={true} disableSelection={true} />
          </div>
        </div>
      </div>
      </>}

      {showGuidance && <div className="alert alert--info col-8 flex flex-center center" style={{ "position": "relative", "left": "35%", "transform": "translate(-300px,5px)", "zIndex": "100px" }}>
        <span>It seems like you have not yet created a bot. To do so, please click on the button that says <b>Create</b> and proceed with creating your own. If you require assistance, please click on the <b>Help</b> option or chat with the <b>AskBotLite chatbot</b>.</span>
      </div>}
      <Modal size="lg" show={uploadData} animation={false}>
        <Modal.Header>
          <h6>Upload Data</h6>
          <span className='icon-close icon-size-24' onClick={() => { setUploadData(false) }}></span>
        </Modal.Header>
        <Modal.Body>
          <div className="popbodybox">
            {/* <div className="popHeaderBox">Please Select the Data Type
              <label className="toggle">
                <input type="checkbox" checked={formatted} onChange={() => { setFormatted(prevState => { return !prevState }) }} />
                <span className="slider"></span>
                <span className="labels" data-on="Formatted Data" data-off="Type Data"></span>
              </label>
            </div> */}
            <div className="radiolist">
              <div className="radiobtn">
                <input type="radio" id="Formatted" name="uploadOption" checked={uploadType === "Formatted" ? true : false} onChange={() => { setUploadType("Formatted"); setBtnEnable(true)}} />
                <label htmlFor="Formatted">Formatted</label>
              </div>

              <div className="radiobtn">
                <input type="radio" id="Unformatted" name="uploadOption" checked={uploadType === "Unformatted" ? true : false} onChange={() => { setUploadType("Unformatted"); setBtnEnable(true)}} />
                <label htmlFor="Unformatted">Unformatted</label>
              </div>

              {/* <div className="radiobtn">
                  <input type="radio" id="URL" name="uploadOption" checked={uploadType === "URL" ? true : false} onChange={()=>{setUploadType("URL"); setBtnEnable(true)}}/>
                  <label for="URL">URL</label>
                </div> */}

            </div>
            <div style={{ "position": "absolute", "right": "16px", "top": "16px" }}>
              <button className="btn btn--dark" onClick={() => { setShowFileFormatHelp((prev) => !prev) }}>Help</button>
            </div>
            <div className="base-margin-top" style={{ maxHeight: "400px", overflowY: "auto" }}>
              {uploadType === "Unformatted" && <FileDropZone allowedFileTypes={
                  isGenAiEnabled?["text/csv", "text/plain", "application/json", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "application/pdf", "application/vnd.openxmlformats-officedocument.presentationml.presentation"]:["text/plain","application/vnd.openxmlformats-officedocument.wordprocessingml.document"]
                } onFileuploaded={(data) => processFileData(data)} readAsBinary={false}></FileDropZone>}
              {/* {uploadType === "URL" && <div><input className="urlInput" placeholder="Enter the link.." onChange={(e)=>{urlChange(e)}}></input></div>} */}
              {uploadType === "Formatted" && <FileDropZone allowedFileTypes={["text/csv", "text/plain", "application/json", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"]} onFileuploaded={(data) => processFileData(data)} readAsBinary={true}></FileDropZone>}
              <br></br>
              {showFileFormatHelp && <hr style={{ marginTop: "0px" }}></hr>}
              {showFileFormatHelp && <MessageBanner>

                <div style={{ marginBottom: "20px" }}><h5>Formatted data can be uploaded in following file formats: </h5></div>

                <div style={{ marginLeft: "-8px" }}><b>CSV:</b> The file should consist of two columns. First column should contain questions, and the second column should have the corresponding answers as shown below. Each row in the file should contain a question-and-answer pair in a comma separated fashion. If the question and answer contain comma in it, please enclose the data in double quotes (i.e., ") </div>
                <div style={{ paddingLeft: "calc(50% - 350px)" }}>
                  <img src={process.env.PUBLIC_URL + "/images/faqCSVFile.png"} style={{ width: "700px", height: "200px" }} />
                </div>
                <br />
                <div style={{ marginLeft: "-8px;" }}><b>JSON:</b>Sample formatted question and answer in CSV format.</div>
                <div style={{ paddingLeft: "calc(50% - 350px)" }}>
                  <img src={process.env.PUBLIC_URL + "/images/faqJSONFile.png"} style={{ width: "700px", height: "200px" }} />
                </div>
                <br />
                <div style={{ marginLeft: "-8px" }}><b>DOCX:</b>Each question-and-answer pair must be kept adjacent to each other in the file. The question should be prefixed with Q:/q: and the answer should be prefixed with A:/a: . These special annotations should not be used within the question and answer itself. </div>
                <div style={{ paddingLeft: "calc(50% - 350px)" }}>
                  <img src={process.env.PUBLIC_URL + "/images/faqDOCXFile.png"} style={{ width: "700px", height: "200px" }} />
                </div>
                <br /><br />
                <div style={{ marginTop: "24px" }}><h5>UnFormatted data: </h5></div>
                <br />
                <div>UnFormatted data can be uploaded in .docx, .ppt, .pptx, .pdf, .html, .txt, .png, .jpeg file format. Documents with multiple images are also allowed. Out backend engine will try to extract data from images also.</div>
                <br /><br />
              </MessageBanner>
              }
            </div>

          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn--secondary" onClick={() => { setUploadData(false) }}>
            Close
          </button>
          <button className="btn btn--secondary" onClick={() => { uploadFile() }} disabled={btnEnable}>
            Upload
          </button>
        </Modal.Footer>
      </Modal>
      <Modal size="xl" show={showQnA} animation={false}>
        {
          isDataLoading && (<Spinner></Spinner>)
        }
        <Modal.Header>
          <h6>{filename}</h6>
          <span className='icon-close icon-size-24' onClick={() => { setShowQnA(false); setShowFaqErrorMessage(false); }}></span>
        </Modal.Header>
        <Modal.Body style={{ height: "80vh" }}>
          <div className="popbodyboxQnA">
            <div style={{ height: "100%" }}>
              {
                (!addQnA) && <>
                  <div className="" style={{ position: "relative" }}>

                    <div className='faqButtons faqaddbtn'>
                      {/* LLM for structred FAQ would be enabled once backend part is ready
                      <button className='btn btn--secondary'>
                        <span className='faqButtonText' onClick={() => enableGenerativeAi(genAi)}>{genAiBtnText} Generative AI</span>
                      </button> */}
                      <button className='btn btn--secondary' onClick={() => setAddQnA(true)} >
                        <span className='faqButtonText'>Add FAQ</span>
                      </button>
                    </div>
                  </div>
                </>
              }

              {addQnA &&
                <div className="popAddFAQ">
                  <div className="form-group base-margin-bottom">
                    <div className="form-group__text">
                      <textarea id="input-faq-question" type="text" name="botQuestion" onChange={inputHandler} />
                      <label htmlFor="input-faq-question">Enter your question <span className='required'>*</span></label>
                    </div>
                  </div>
                  <div className="form-group base-margin-bottom">
                    <div className="form-group__text">
                      <textarea id="input-faq-answer" type="text" name="botAnswer" onChange={inputHandler} />
                      <label htmlFor="input-faq-answer">Enter your Answer<span className='required'>*</span></label>
                    </div>
                  </div>
                  <div style={{ textAlign: "right" }}>
                    <button className='btn btn--secondary' style={{ paddingLeft: "16px", paddingRight: "16px" }} disabled={enableBtn} onClick={() => addFaq()} >
                      <span className='faqButtonText'>Add</span>
                    </button>
                    <button className='btn btn--secondary' style={{ paddingLeft: "16px", paddingRight: "16px" }} onClick={() => setAddQnA(false)} >
                      <span className='faqButtonText'>Cancel</span>
                    </button>
                  </div>
                </div>
              }
              {
                (() => {
                  if (showQARetrivalFailureMessage) {
                    return (
                      <div>{qaRetrivalFailureMessage}</div>
                    )
                  }
                  else {
                    return (
                      // <DataGridComponent rows={qaRows} columns={QnA_col} rowHeight={50} checkbox={true} disableSelection={true}/>/
                      <>
                        {showFaqErrorMessage && <MessageBanner messageType="error" onCloseClicked={() => { setShowFaqErrorMessage(false) }}>{faqErrorMessage}</MessageBanner>}
                        <div className="searchbox">
                          <span className="icon-search" onClick={() => { filterQna() }}></span>
                          <input type="text" placeholder="Search.." onKeyUp={(event) => {
                            if (event.keyCode === 13) {
                              filterQna();
                            }
                          }} value={searchQuery} onChange={(event) => { setSearchQuery(event.target.value) }}></input>
                        </div>
                        <br></br>

                        {isDataLoading === false && qaRows && qaRows.length > 0 && <div style={{ height: (addQnA ? "calc(100% - 338px)" : "calc(100% - 86px)"), overflow: "auto" }}>
                          <table className="table table--lined table--selectable table--wrapped">
                            <thead style={{ "position": "sticky", "top": "0px", background: "white", zIndex: "100" }}>
                              <tr>
                                <th>
                                  <span className="icon-delete " onClick={deleteFAQs}></span>
                                </th>
                                <th>Question</th>
                                <th>Answer</th>
                                <th style={{ width: "50px", zIndex: "100" }}>Edit</th>
                              </tr>
                            </thead>
                            <tbody style={{ zIndex: "0" }}>
                              {qaRows.map((data, index) => {
                                return (<tr>
                                  <td>
                                    <label className="checkbox">
                                      <input type="checkbox" checked={data.checked} onChange={(e) => { setDeleteList(data.id) }} />
                                      <span className="checkbox__input"></span>
                                    </label>
                                  </td>
                                  {data.editrow === true ? <><td>
                                    <textarea className="txtbox" value={data.question} rows="5" onChange={(e) => { txtHandler(e, "question", data.id) }}></textarea> </td>
                                    <td>
                                      <textarea className="txtbox" value={data.answer} rows="5" onChange={(e) => { txtHandler(e, "answer", data.id) }}></textarea></td></> :
                                    <><td>{data.question}</td><td>{data.answer}</td></>
                                  }


                                  <td>{data.editrow === true ? <span className="icon-save " onClick={() => { updateQA(data.id, index) }}></span> : <span className="icon-edit editIcon" onClick={() => { editlist(data.id) }}></span>}  </td>
                                </tr>)
                              })
                              }


                            </tbody>
                          </table>
                        </div>}

                      </>
                    )
                  }
                })()
              }
            </div>
            {
              /*
              Commenting add Tag for time being as backend is not yet supporting this feature
            <ul id="hjustified" className="tabs tabs--justified" style={{ marginBottom: "10px" }}>
              <li id="hjustified-1" className={tab_selected == "faq" ? "active" : "" + "tab"} onClick={() => switchTab("faq")}>
                <a tabindex="0">Faq</a>
              </li>
              <li id="hjustified-2" className={tab_selected == "tag" ? "active" : "" + "tab"} onClick={() => switchTab("tag")}>
                <a tabindex="0">Tag</a>
              </li>
            </ul>
            <div id="hjustified-content" className="tab-content" >
              <div id="hjustified-1-content" className={tab_selected == "faq" ? "active tab-pane" : "tab-pane"}>
                {
                  (!addQnA) && <>
                    <div className="" style={{ position: "relative" }}>
  
                      <div className='faqButtons faqaddbtn'>
                        <button className='btn btn--secondary btn--small text-center-xs-down text-center-sm-down text-center-md-down text-center-lg-down text-center-xl-down text-center-xxl-down text-center-xxxl-down' style={{ paddingLeft: "16px", paddingRight: "16px" }} onClick={() => setAddQnA(true)} >
                          <span className='faqButtonText'>Add FAQ</span>
                        </button>
                      </div>
                    </div>
                  </>
                }
  
                {addQnA &&
                  <div className="popAddFAQ">
                    <div className="form-group base-margin-bottom">
                      <div className="form-group__text">
                        <textarea id="input-faq-question" type="text" name="botQuestion" onChange={inputHandler} />
                        <label htmlFor="input-faq-question">Enter your question <span className='required'>*</span></label>
                      </div>
                    </div>
                    <div className="form-group base-margin-bottom">
                      <div className="form-group__text">
                        <textarea id="input-faq-answer" type="text" name="botAnswer" onChange={inputHandler} />
                        <label htmlFor="input-faq-answer">Enter your Answer<span className='required'>*</span></label>
                      </div>
                    </div>
                    <div style={{ textAlign: "right" }}>
                      <button className='btn btn--secondary' style={{ paddingLeft: "16px", paddingRight: "16px" }} disabled={enableBtn} onClick={() => addFaq()} >
                        <span className='faqButtonText'>Add</span>
                      </button>
                      <button className='btn btn--secondary' style={{ paddingLeft: "16px", paddingRight: "16px" }} onClick={() => setAddQnA(false)} >
                        <span className='faqButtonText'>Cancel</span>
                      </button>
                    </div>
                  </div>
                }
                {
                  (() => {
                    if (showQARetrivalFailureMessage) {
                      return (
                        <div>{qaRetrivalFailureMessage}</div>
                      )
                    }
                    else {
                      return (
                        // <DataGridComponent rows={qaRows} columns={QnA_col} rowHeight={50} checkbox={true} disableSelection={true}/>/
                        <>
                          <div className="searchbox">
                            <span className="icon-search"></span>
                            <input type="text" placeholder="Search.."></input>
                          </div>
                          <br></br>
                          <table className="table table--lined table--selectable">
                            <thead>
                              <tr>
                                <th>
                                  <span className="icon-delete " onClick={deleteFAQs}></span>
                                </th>
                                <th>Question</th>
                                <th>Answer</th>
                                <th style={{ width: "50px" }}>Edit</th>
                              </tr>
                            </thead>
                            <tbody>
  
                              {questionList && questionList.length > 0 && questionList.map((data, index) => {
                                return (<tr>
                                  <td>
                                    <label className="checkbox">
                                      <input type="checkbox" onChange={(e) => { deleteCheck(e, index) }} />
                                      <span className="checkbox__input"></span>
                                    </label>
                                  </td>
                                  {data.editrow === true ? <><td>
                                    <textarea className="txtbox" value={data.question} rows="5" onChange={(e) => { txtHandler(e, "qst", index) }}></textarea> </td>
                                    <td>
                                      <textarea className="txtbox" value={data.answer} rows="5" onChange={(e) => { txtHandler(e, "ans", index) }}></textarea></td></> :
                                    <><td>{data.question}</td><td>{data.answer}</td></>
                                  }
  
  
                                  <td>{data.editrow === true ? <span className="icon-save " onClick={() => { updateQA() }}></span> : <span className="icon-edit editIcon" onClick={() => { editlist(index) }}></span>}  </td>
                                </tr>)
                              })
                              }
  
  
                            </tbody>
                          </table></>
                      )
                    }
                  })()
                }
              </div>
              <div id="hjustified-2-content" className={tab_selected == "tag" ? "active tab-pane" : "tab-pane"}>
                <div className="tags">
                  {taglist && taglist.map((list) => {
                    return (
                      <span onClick={() => { addTag(list) }}>{list}</span>
                    )
                  })}
                </div>
                <div className="taglistbox">
                  <p>{addedTaglist.length > 0 ? "Added List" : "Click the above list to add #tag"}</p>
                  {addedTaglist && addedTaglist.map((list) => {
                    return (
                      <label>{list} <span className="icon-close" onClick={() => { deleteTag(list) }}></span></label>
                    )
                  })}
                </div>
              </div>
            </div>              
              */
            }

          </div>
        </Modal.Body>
      </Modal>
      {/* 
      Commenting the GitHub integration modal until feature is ready for prod deployment
      <Modal size="xl" show={uploadIntegration} animation={false}>
        <Modal.Header>
          <h6>FAQ Integration</h6>
          <span className='icon-close icon-size-24' onClick={() => { setUploadIntegration(false) }}></span>
        </Modal.Header>
        <Modal.Body>
          {showErrorMessage && <MessageBanner messageType="error" message={errorMessage} onCloseClicked={() => { setShowErrorMessage(false) }}></MessageBanner>}
          <div className="popbodybox">
            {<div>
              <div className='trainingTabWrapper'>
                <div className='trainingtab-selector'>
                    <ul className="tabs tabs--justified trainPage-tabSelector">
                        <li id="api-entity-tab" className={tab_selected == "github" ? "active" : "" + "tab"} onClick={() => switchTab("github")}>
                            <a tabindex="0">Github</a>
                        </li>
                        <li id="api-entity-tab" className={tab_selected == "sharepoint" ? "active" : "" + "tab"} onClick={() => switchTab("sharepoint")}>
                            <a tabindex="0">Sharepoint</a>
                        </li>
                        <li id="api-entity-tab" className={tab_selected == "quip" ? "active" : "" + "tab"} onClick={() => switchTab("quip")}>
                            <a tabindex="0">Quip</a>
                        </li>
                    </ul>
                </div>
              </div>
              <div className={tab_selected == "github" ? "active tab-pane" : "tab-pane"}>
                <div className="panel panel--raised base-margin-top" style={{ marginBottom: "5%" }}>
                  <div className="row" style={{ "marginLeft": "0px" }}>
                    <div className="col-10 col-sm-10 col-md-11 col-lg-11 col-xl-11 col-xxl-12 col-xxxl-11 form-group base-margin-bottom">
                      <h6>1. Github Integration Details</h6>
                      <br></br>
                      <div className="form-group__text">
                        <form>
                          <div >
                              <div className="form-group base-margin-bottom">
                                  <div className="form-group__text">
                                      <input id="faq-github-repository" type="text" autoComplete="off" autoFocus name="RepositoryURL"  />
                                      <label htmlFor="faq-repository">Github Repository URL <span className='required'>*</span></label>
                                  </div>
                              </div>
                              <div className="form-group base-margin-bottom">
                                  <div className="form-group__text">
                                      <input id="faq-github-token" type="text" autoComplete="off" autoFocus name="AccessToken"  />
                                      <label htmlFor="faq-github-token">Github Access Token <span className='required'>*</span></label>
                                  </div>
                              </div>
                          </div>
                        </form>
                      </div>
                      <div className="copyDataButton">
                        <button className='btn btn--primary text-center-xs-down text-center-sm-down text-center-md-down text-center-lg-down text-center-xl-down text-center-xxl-down text-center-xxxl-down base-margin-left' onClick={() => { integrationAuth("github") }} style={{ justifyContent: "center", paddingLeft: "16px", paddingRight: "16px", marginTop: "2%" }} >
                          <span>Submit Integration</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="panel panel--raised base-margin-top" style={{ marginBottom: "5%" }}>
                  <div className="row" style={{ "marginLeft": "0px" }}>
                    <div className="col-10 col-sm-10 col-md-11 col-lg-11 col-xl-11 col-xxl-12 col-xxxl-11 form-group base-margin-bottom">
                      <h6>2. Select Branch</h6>
                      <br></br>
                      
                      <div className="form-group dropdown " style={{ paddingTop: '5px' }}>
                        <div className="form-group">
                          <label htmlFor="select-type-basic">Github Repository Branch </label>
                          <SearchSelect placeholder={'Select the Github Repository Branch'} options={githubBranchSelection} onValueChange={(data) => { }} />
                        </div>
                      </div>
                      
                      <div className="copyDataButton">
                        <button className='btn btn--primary text-center-xs-down text-center-sm-down text-center-md-down text-center-lg-down text-center-xl-down text-center-xxl-down text-center-xxxl-down base-margin-left' onClick={() => { }} style={{ justifyContent: "center", paddingLeft: "16px", paddingRight: "16px", marginTop: "2%" }} >
                          <span>Submit Branch</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="panel panel--raised base-margin-top" style={{ marginBottom: "5%" }}>
                  <div className="row" style={{ "marginLeft": "0px" }}>
                    <div className="col-10 col-sm-10 col-md-11 col-lg-11 col-xl-11 col-xxl-12 col-xxxl-11 form-group base-margin-bottom">
                      <h6>3. Select File(s)</h6>
                      <br></br>
                      <div className="form-group__text">
                        <form>
                          <div >
                            <MultiSelect
                              option={integrationFileSelection}
                              placeholder="Select Github Files"
                              onValueChange={(data) => { setIntegrationFiles(data) }}
                            />

                          </div>
                        </form>
                      </div>
                      <div className="copyDataButton">
                        <button className='btn btn--primary text-center-xs-down text-center-sm-down text-center-md-down text-center-lg-down text-center-xl-down text-center-xxl-down text-center-xxxl-down base-margin-left' onClick={() => { integrationContentsUpload() }} style={{ justifyContent: "center", paddingLeft: "16px", paddingRight: "16px", marginTop: "2%" }} >
                          <span>Submit File(s)</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <br></br>
              </div>
            </div>}
          </div>
          
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn--secondary" onClick={() => { setUploadIntegration(false) }}>
            Close
          </button>
        </Modal.Footer>
      </Modal> */}
      <Modal size="lg" show={showLLMSelectionModal} animation={false} centered>
        <Modal.Header>
          <h6>LLM Model Settings</h6>
          <span className='icon-close icon-size-24' onClick={() => { setShowtLLMSelectionModal(false) }}></span>
        </Modal.Header>
        <Modal.Body>{
          (()=>{
            if(isGenAiEnabled){
              return(
                <>
                {LLMNames()}
                {selectedLLMModel && llmModelsWithToken.indexOf(selectedLLMModel.value) !== -1 && (
                  <div className='form-group base-margin-top'>
                      <div className="form-group__text">
                          <input id="input-llm-model-token" type="text" autoFocus name="llm_model_token" value={llmModelClientUrl} onChange={event => { setLLMModelClientUrl(event.target.value) }} />
                          <label htmlFor="input-entity-name">URL<span className='required' style={{ "color": "red" }}>*</span></label>
                      </div>
                      <div className="form-group__text base-margin-top">
                          <input id="input-llm-model-token" type="password" autoFocus name="llm_model_token" value={llmModelClientAppKey} onChange={event => { setLLMModelClientAppKey(event.target.value) }} />
                          <label htmlFor="input-entity-name">App Key<span className='required' style={{ "color": "red" }}>*</span></label>
                      </div>
                      <div className="form-group__text base-margin-top">
                          <input id="input-llm-model-token" type="password" autoFocus name="llm_model_token" value={llmModelClientId} onChange={event => { setLLMModelClientId(event.target.value) }} />
                          <label htmlFor="input-entity-name">Client Id<span className='required' style={{ "color": "red" }}>*</span></label>
                      </div>
                      <div className="form-group__text base-margin-top">
                          <input id="input-llm-model-token" type="password" autoFocus name="llm_model_token" value={llmModelClientSecret} onChange={event => { setLLMModelClientSecret(event.target.value) }} />
                          <label htmlFor="input-entity-name">Client Secret<span className='required' style={{ "color": "red" }}>*</span></label>
                      </div>
                  </div>
                )}
                </>)
            }else{
              return(
                <div>
                  <MessageBanner messageType={"info"} message="Please contact BotLite team at botlite-connect@cisco.com to enable this feature."></MessageBanner>
                </div>
              )
            }
          })()
          }

        </Modal.Body>
        {isGenAiEnabled && (<Modal.Footer>
          <button className="btn btn--secondary" onClick={() => { setShowtLLMSelectionModal(false) }}>
            Close
          </button>
          <button className="btn btn--secondary" onClick={() => { saveAdvancedSettings() }} disabled={selectedLLMModel && llmModelsWithToken.indexOf(selectedLLMModel.value) !== -1 && (llmModelClientId.length === 0 || llmModelClientSecret.length === 0 || llmModelClientAppKey.length === 0 || llmModelClientUrl.length === 0)}>
            Save
          </button>
        </Modal.Footer>)}
      </Modal>
    </div>
  );

};

export default FAQView;
