import { BOT_TYPE_OPTIONS } from "../../analytics-module/constants";

const bot_url = process.env.REACT_APP_ROOT_URL + "/bot";
const user_url = process.env.REACT_APP_ROOT_URL + "/user";
const analytics_url = process.env.REACT_APP_ROOT_URL + "/analytics";
const BotService = {
  // createBot: (data) => {
  //     var reqHeaders = new Headers();
  //     reqHeaders.append("Content-Type", "application/json");

  //     var requestOptions = {
  //         method: 'POST',
  //         headers: reqHeaders,
  //         body: JSON.stringify(data)
  //     };

  //     return fetch(bot_url, requestOptions)

  // },
  createBot: (data) => {
    var reqHeaders = new Headers();
    reqHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "POST",
      headers: reqHeaders,
      body: JSON.stringify(data),
    };

    return fetch(`${bot_url}/register`, requestOptions);
  },

  checkDuplicateName: (data) => {
    var reqHeaders = new Headers();
    reqHeaders.append('Content-Type', 'application/json')
    
    var requestOptions = {
	method: 'POST',
	headers: reqHeaders,
	body: JSON.stringify(data)
    };

    return fetch(`${bot_url}/names`, requestOptions);
  },

  getBot: (botId) => {
    var reqHeaders = new Headers();
    reqHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "GET",
      body: {},
    };

    return fetch(`${bot_url}/${botId}`, requestOptions);
  },

  listBots: () => {
    var reqHeaders = new Headers();
    reqHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: "GET",
      headers: reqHeaders,
    };

    return fetch(bot_url, requestOptions);
  },

  updateBot: (data) => {
    var reqHeaders = new Headers();
    reqHeaders.append("Content-Type", "application/json");
    console.log(data);
    var requestOptions = {
      method: "PUT",
      headers: reqHeaders,
      body: JSON.stringify(data),
    };

    return fetch(bot_url, requestOptions);
  },

  deleteBot: (botId) => {
    var reqHeaders = new Headers();
    reqHeaders.append("Content-Type", "application/json");
    console.log(botId);
    var requestOptions = {
      method: "DELETE",
      headers: reqHeaders,
    };

    return fetch(`${bot_url}/${botId}`, requestOptions);
  },

  getBusinessOrg: () => {
    var reqHeaders = new Headers();
    reqHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: "GET",
      headers: reqHeaders,
    };

    return fetch(bot_url + "/business_org", requestOptions);
  },

  validateUsers: (data) => {
    var reqHeaders = new Headers();
    reqHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "POST",
      headers: reqHeaders,
      body: JSON.stringify(data),
    };

    return fetch(user_url + "/validate", requestOptions);
  },

  topBots: () => {
    var reqHeaders = new Headers();
    reqHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "GET",
      headers: reqHeaders,
    };

    return fetch(analytics_url + "/bots_by_usage", requestOptions);
  },
};

export default BotService;
