import React, { useState, useEffect, useRef } from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import MessageBanner from "../../../../custom-components/MessageBanner/MessageBanner";
import SearchSelect from "../../../../custom-components/search-select/SearchSelect";
import BotService from "../../../bot-module/bot-services/BotService";
import AnalyticsService from "../../analytics-services/AnalyticsService";
import "../../../../../node_modules/react-resizable/css/styles.css";
import "../../../../../node_modules/react-grid-layout/css/styles.css";
import "./AnalyticsViewComponent.css";
import { ChartBoxLayout, LayoutBox, BarChartBoxLayout } from "../../style";
import {
  BOT_CHART_DETAILS,
  BOT_CHART_DETAILS_2,
  CHART_KEYS,
} from "../../constants";
import AnalyticsConvoChart from "./AnalyticsConvoChart";
import AnalyticsBotInternalChart from "./AnalyticsBotInternalChart";
import AnalyticsBarChart from "./AnalyticsBarChart";
const ResponsiveGridLayout = WidthProvider(Responsive);

const AnalyticsView = () => {
  const getFromLS = (key) => {
    let ls = {};
    if (global.localStorage.getItem("layout")) {
      try {
        //consol
        ls = JSON.parse(global.localStorage.getItem("layout")) || {};
      } catch (e) {
        /*Ignore*/
      }
    }
    console.log("ls[key]: " + ls[key]);
    return ls[key];
  };

  const originalLayouts = getFromLS("layouts") || {};
  const originalInternalLayouts = getFromLS("internalLayouts") || {};

  const [layouts, setLayouts] = useState(
    JSON.parse(JSON.stringify(originalLayouts))
  );
  const [internalLayouts, setInternalLayout] = useState(
    JSON.parse(JSON.stringify(originalInternalLayouts))
  );
  const [isDataLoading, setDataLoading] = useState(false);
  const [showMessageBanner, setShowMessageBanner] = useState(false);
  const [bannerMessage, setBannerMessage] = useState("");
  const [bannerMessageType, setBannerMessageType] = useState("success");
  const refWidth = useRef(null);

  const [bot, setBot] = useState({ label: "Select a Bot", value: "" });
  const [bots, setBotList] = useState([]);

  const availableHandles = ["s", "w", "e", "n", "sw", "nw", "se", "ne"];

  const getDivNum = (windowSize) => {
    if (windowSize >= 1200) {
      return 12;
    } else if (windowSize >= 992) {
      return 10;
    } else if (windowSize >= 768) {
      return 6;
    } else {
      return 4;
    }
  };

  let width = window.innerWidth;
  let unitWidth = width / getDivNum(width);
  let unitHeight = 33;
  useEffect(() => {
    setDataLoading(true);
    BotService.listBots()
      .then((res) => res.json())
      .then((res) => {
        if (res["bots"]?.length > 0) {
          let bot_list = [];
          for (let bot of res["bots"]) {
            bot_list.push({ label: bot["display_name"], value: bot["_id"] });
          }
          console.log("botlist:::", bot_list);
          setBot(bot_list[0]);
          loadLayout(bot_list[0]?.value);
          setBotList(bot_list);
          const botSelectedName = localStorage.getItem("botSelectedName");
          const botSelectedId = localStorage.getItem("botSelectedId");
          console.log("check botselectnsme", botSelectedName);
          if (botSelectedName !== null && botSelectedId !== null) {
            console.log("integration", localStorage);
            setBot({
              label: botSelectedName,
              value: botSelectedId,
            });
          } else {
            setBot(bot_list[0]);
            localStorage.setItem("botSelectedName", bot_list[0].label);
            localStorage.setItem("botSelectedId", bot_list[0].value);
          }
        }
        setDataLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setDataLoading(false);
        setBannerMessageType("error");
        setBannerMessage("Failed to retrieve bots!");
        setShowMessageBanner(true);
      });
  }, []);

  const loadLayout = (bot_id) => {
    setDataLoading(true);
    let ls = {};
    AnalyticsService.getLayout(bot_id)
      .then((res) => res.json())
      .then((res) => {
        ls = res["bot"] || {};
        setDataLoading(false);
      })
      .catch((err) => {
        console.log("Failed to retrieve latout", err);
        setDataLoading(false);
        setBannerMessageType("error");
        setBannerMessage("Failed to retrieve layout!");
        setShowMessageBanner(true);
      });
  };

  useEffect(() => {
    width = refWidth.current ? refWidth.current.offsetWidth : window.innerWidth;
    unitWidth = width / getDivNum(width) - 10;
  }, [refWidth.current]);

  const saveToLS = (key, value) => {
    var existingData = {};
    if (global.localStorage.getItem("layout")) {
      existingData = JSON.parse(global.localStorage.getItem("layout"));
    }
    var uploadData = {
      ...existingData,
      [key]: value,
    };
    global.localStorage.setItem("layout", JSON.stringify(uploadData));
    console.log("saveToLS:::", bot.value);
    if (bot.value) {
      AnalyticsService.savelayout({
        data: {
          layout: uploadData,
          bot_id: bot.value,
        },
      }).then((res) => {
        console.log(res);
      });
    }
  };

  const onLayoutChange = (layout, elayouts) => {
    saveToLS("layouts", elayouts);
    setLayouts(elayouts);
  };

  const onInternalLayoutChange = (layout, elayouts) => {
    saveToLS("internalLayouts", elayouts);
    setInternalLayout(elayouts);
  };

  const getBotCharts = (data) => {
    console.log("getBotChart:::", data);
    setBot(data);
    localStorage.setItem("botSelectedName", data.label);
    localStorage.setItem("botSelectedId", data.value);
  };

  const BotList = () => {
    return (
      <SearchSelect
        id="botList"
        className="augmentSelect"
        defaultValue={bot}
        options={bots}
        onValueChange={(data) => {
          getBotCharts(data);
        }}
        noDataMessage="No Bots available"
      />
    );
  };

  const chartWidth = 6;
  const internalChartWidth = 2;
  const chartHeight = 13.5;
  const internalChartHeight = 6;
  const chartMWidth = 6;
  const chartMHeight = 12;

  return (
    <div ref={refWidth}>
      <div>
        <div className="row base-margin-top base-margin-right">
          <h5 className="col-7 col-md-8 col-sm-8 col-lg-8 col-xl-8 col-xxl-9 col-xxxl-9 ">
            {/* Analytics View */}
          </h5>
          <div
            className="col-5 col-md-4 col-sm-4 col-lg-4 col-xl-4 col-xxl-3 col-xxxl-3"
            style={{ marginBottom: "15px" }}
          >
            {BotList()}
          </div>
        </div>
      </div>
      {showMessageBanner && (
        <MessageBanner
          message={bannerMessage}
          messageType={bannerMessageType}
          onCloseClicked={() => {
            setShowMessageBanner(false);
          }}
        ></MessageBanner>
      )}

      <div className="charts">
        <ResponsiveGridLayout
          className="layout"
          cols={{ lg: 12, md: 10, sm: 6, xs: 4, xl: 12, xxl: 12, xxs: 4 }}
          rowHeight={30}
          layouts={layouts}
          margin={[10, 10]}
          onLayoutChange={(layout, layouts) => onLayoutChange(layout, layouts)}
        >
          <LayoutBox
            key="1"
            data-grid={{
              w: 12,
              h: chartHeight,
              x: 0,
              y: 0,
              minW: chartMWidth,
              minH: chartMHeight,
              resizeHandles: availableHandles,
            }}
          >
            <ResponsiveGridLayout
              className="layout"
              cols={{ lg: 12, md: 10, sm: 6, xs: 4, xl: 12, xxl: 12, xxs: 4 }}
              rowHeight={30}
              layouts={internalLayouts}
              margin={[10, 10]}
              onLayoutChange={(layout, layouts) =>
                onInternalLayoutChange(layout, layouts)
              }
              isBounded={true}
            >
              {BOT_CHART_DETAILS.map((chart, index) => {
                return (
                  <ChartBoxLayout
                    key={chart.key}
                    data-grid={{
                      w: internalChartWidth,
                      h: internalChartHeight,
                      x: chart.x,
                      y: chart.y,
                    }}
                  >
                    <AnalyticsBotInternalChart
                      chart={chart}
                      unitHeight={unitHeight}
                      unitWidth={unitWidth}
                      bot={bot}
                    />
                  </ChartBoxLayout>
                );
              })}
            </ResponsiveGridLayout>
          </LayoutBox>

          {BOT_CHART_DETAILS_2.map((chart, index) => {
            if (chart.key === CHART_KEYS.BOT.DAILY_USAGE) {
              return (
                <BarChartBoxLayout
                  key={chart.key}
                  data-grid={{
                    w: 2 * chartWidth,
                    h: chartHeight,
                    x: chart.x,
                    y: chart.y,
                    minW: chartMWidth,
                    minH: chartMHeight,
                    resizeHandles: availableHandles,
                  }}
                >
                  <AnalyticsBarChart
                    chart={chart}
                    unitHeight={unitHeight}
                    unitWidth={unitWidth}
                    chartHeight={chartHeight}
                    chartWidth={2 * chartWidth}
                    layouts={layouts}
                    index={index}
                    bot={bot}
                  />
                </BarChartBoxLayout>
              );
            }
            return (
              <BarChartBoxLayout
                key={chart.key}
                data-grid={{
                  w: chartWidth,
                  h: chartHeight,
                  x: chart.x,
                  y: chart.y,
                  minW: chartMWidth,
                  minH: chartMHeight,
                  resizeHandles: availableHandles,
                }}
              >
                <AnalyticsBarChart
                  chart={chart}
                  unitHeight={unitHeight}
                  unitWidth={unitWidth}
                  chartHeight={chartHeight}
                  chartWidth={chartWidth}
                  layouts={layouts}
                  index={index}
                  bot={bot}
                />
              </BarChartBoxLayout>
            );
          })}
          {/* <BarChartBoxLayout
             key="Bot_Chart_Key_Convo"
             data-grid={{
               w: chartWidth,
               h: chartHeight,
               x: 6,
               y: 70,
               minW: chartMWidth,
               minH: chartMHeight,
               resizeHandles: availableHandles,
             }}
           >
             <AnalyticsConvoChart
               layouts={layouts}
               bot_id={bot?.value}
               unitHeight={unitHeight}
               unitWidth={unitWidth}
             />
           </BarChartBoxLayout> */}
        </ResponsiveGridLayout>
      </div>

      <div style={{ display: width < 992 ? "block" : "none" }}>
        <MessageBanner
          message={
            "This device doesn't support to view this screen. This view is restricted only for laptops and tablets. Please open it in a different device."
          }
          messageType={"error"}
        ></MessageBanner>
      </div>
    </div>
  );
};

export default AnalyticsView;
