import styled from "styled-components";

export const ChartBoxLayout = styled.div`
  border: 1px solid #64bbe3;
  text-align: center;
  padding: 5px;
  color: #58585b;
  box-shadow: 0 10px 24px -6px rgba(0, 0, 0, 0.25);
  font-size: 1.2rem;
`;

export const BarChartBoxLayout = styled.div`
  border: 1px solid #64bbe3;
  color: #58585b;
  box-shadow: 0 10px 24px -6px rgba(0, 0, 0, 0.25);
  font-size: 1.2rem;
`;

export const ChartHeader = styled.h6`
  text-align: center;
  font-size: 1.6rem;
  font-weight: bold !important;
  font-family: CiscoSans, Arial, sans-serif;
  color: #58585b;
  line-height: 0.5rem !important;
`;

export const BarChartHeader = styled.h5`
  font-size: 1.6rem;
  font-weight: bold !important;
  font-family: CiscoSans, Arial, sans-serif;
  color: #58585b;
  line-height: 0.5rem !important;
  display: inline;
`;

export const LayoutBox = styled.div`
  box-shadow: 0 10px 24px -6px rgba(0, 0, 0, 0.25);
  border: 1px solid #dfdfdf;
  padding: 20px;
`;

export const ChartData = styled.div`
  color: black;
  padding: 20px;
  font-size: 3.5em;
`;

export const HideChart = styled.div`
  visibility: hidden;
`;
