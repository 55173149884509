import Papa from "papaparse";

const FileParser = (data, type) => {
    let dataRows = []
    console.log(type, data)
    if (type === "text/csv") {
        let flg = true;
        new Promise((resolve, reject) => {
            Papa.parse(data, {
                header: true,
                skipEmptyLines: true,
                complete: function (results) {

                    // Iterating data to get column name and their values
                    results.data.map((d) => {
                        if (flg) {
                            dataRows.push(Object.keys(d));
                            flg = false;
                        }
                        dataRows.push(Object.values(d));
                    });
                    resolve(dataRows);
                },
            });
        })

        return dataRows;
    }
    else {

        let dataString = data.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, '')
        console.log(dataString)
        let dataRows = dataString.split(/\n/);
        return dataRows;
    }


}
export default FileParser;