import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import ChartsEmbedSDK from "@mongodb-js/charts-embed-dom";
import { CHART_KEYS } from "../../constants";
import { useSelector } from 'react-redux';
import { ChartData, HideChart } from "../../style";

const AnalyticsAtlasChartComponent = forwardRef(
  ({ chartId, height, width, chartFilter, insight, chartKey }, ref) => {
    const analyticsAccessToken = useSelector(state => state.analyticsToken.analyticsAccessToken);
    const sdk = new ChartsEmbedSDK({
      baseUrl: process.env.REACT_APP_ANALYTICS_MODULE_BASE_URL,
      getUserToken: async function () {
        return analyticsAccessToken;
      },
    });

    const chartDiv = useRef(null);
    const [rendered, setRendered] = useState(false);
    const [imgName, setImgName] = useState(null);
    const [h, setH] = useState(height);
    const [w, setW] = useState(width);
    const [data, setData] = useState(null);
    const [jsonData, setJsonData] = useState(null);

    if (h !== height) {
      setH(height);
    }
    if (w !== width) {
      setW(width);
    }

    const getFilter = () => {
      console.log("chartfilter", chartFilter);
      if (chartFilter === undefined || chartFilter === null) return undefined;
      return chartFilter;
    };

    const [chart, setChart] = useState(
      sdk.createChart({
        chartId: chartId,
        height: h,
        width: w,
        theme: "light",
        showAttribution: false,
        cacheEnabled: true,
        cacheTimeout: 600,  
        filter: getFilter(),
      })
    );

    const extractChartData = () => {
      chart.getData().then((collectData) => {
        const value = collectData?.documents[0]?.value;
        setData(value);
        setJsonData(collectData.documents);
        setImgName(
          value <= 4
            ? "good"
            : value > 4 && value < 9
            ? "bad"
            : value === undefined
            ? ""
            : "worse"
        );
      });
    };

    useEffect(() => {
      async function rendering() {
        chart
          .render(chartDiv.current)
          .then(() => {
            console.log("Chart Rendered");
            extractChartData();
            setRendered(true);
          })
          .catch((err) => console.log("Error during Charts rendering.", err));
      }
      rendering();
    }, [chart]);

    useImperativeHandle(ref, () => ({
      getDownloadData: () => {
        return jsonData;
      },
    }));

    useEffect(() => {
      setChart(
        sdk.createChart({
          chartId: chartId,
          height: h,
          width: w,
          theme: "light",
          showAttribution: false,
          cacheEnabled: true,
          cacheTimeout: 600,
          filter: getFilter(),
        })
      );

      extractChartData();
    }, [h, w, chartFilter]);

    let conditionalRenderChart;

    if (chartKey === CHART_KEYS.BOT.BOT_HEALTH && data !== null) {
      conditionalRenderChart = <HideChart ref={chartDiv} />;
    } else if (
      (chartKey === CHART_KEYS.BOT.NEW_USERS ||
        chartKey === CHART_KEYS.BOT.ACTIVE_USERS) &&
      jsonData !== null
    ) {
      conditionalRenderChart = (
        <HideChart className="chart" id="chart" ref={chartDiv} />
      );
    } else {
      conditionalRenderChart = (
        <div className="chart" id="chart" ref={chartDiv} />
      );
    }

    return (
      <div>
        {(chartKey === CHART_KEYS.BOT.NEW_USERS ||
          chartKey === CHART_KEYS.BOT.ACTIVE_USERS) &&
          jsonData !== null && <ChartData>{jsonData.length}</ChartData>}

        {chartKey === CHART_KEYS.BOT.BOT_HEALTH && data !== null && (
          <img
            src={process.env.PUBLIC_URL + "/images/" + imgName + ".png"}
            alt="no interaction in last 7 days"
            style={{ width: h, height: h }}
          />
        )}
        {conditionalRenderChart}
      </div>
    );
  }
);

export default AnalyticsAtlasChartComponent;
