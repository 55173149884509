const FileDownloader = async (data, fileName)=>{
    // let data = [
    //  "banglore,blr,bl",
    //  "delhi,dl,delhi"
    //   ];
    console.log(data);
    const csvData = 'data:text/csv;charset=utf-8,' + encodeURIComponent(
        data.join('\n')
      );
      const link = document.createElement('a');
      link.setAttribute('href', csvData);
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
}

export default FileDownloader;
