import { useState, useEffect } from 'react';
import './ActionNode.css';
import APIForm from '../../../../../../custom-components/api-form/APIForm';
import DBForm from './DBForm'
import SearchSelect from '../../../../../../custom-components/search-select/SearchSelect';
import AceEditor from 'react-ace'
import isAlphanumeric from 'validator/lib/isAlphanumeric';
import DialogService from '../../../../dialog-service/DialogService';
import APIFormSecret from './APIFormSecretManagement';
import ScheduleForm from '../../../../../../custom-components/schedule-form/ScheduleForm';
// import DialogScreen from '../../../dialog-screen-component/DialogScreenComponent';

function ActionNodeDetails({ node, closeFunction, actions }) {
    const [forceReRender, setForceReRender] = useState(false)
    const [API, setAPI] = useState({ method: 'GET', url: '', headers: [], payload: [], params: [] })
    // const [code, setCode] = useState('')
    const [DB, setDB] = useState({ db: undefined, query: '' })
    const [link, setLink] = useState('')
    const [showValidationErrorMessage, setShowValidationErrorMessage] = useState(false);
    const [dataValidationErrorMessage, setDataValidationErrorMessage] = useState('');
    const [actionData, setActionData] = useState([{ name: "", description: "" }]);
    const [newNodeInfo, setNewNodeInfo] = useState({})
    const [submitBtnEnable, setSubmitBtnEnable] = useState(false)
    const [actionList, setActionList] = useState(actions)
    const [selectedTab, setSelectedTab] = useState('action')

    useEffect(() => {
        switch (node.data.type) {
            case 'api':
                // setAPI({ method: node.data.method, url: node.data.url, headers: node.data.headers, params: node.data.params, payload: node.data.payload })
                setAPI({ api: node.data.api })
                break
            case 'db':
                setDB({ db: node.data.db, query: node.data.query })
                // getDBData(node.data.skill)
                break
            case 'link':
                setLink(node.data.link)
                break
            // case 'code':
            //     setCode(node.data.code)
            //     break
            default:
                break
        }
    }, [node])


    const closeSplit = () => { closeFunction() }

    const inputHandlerActionChange = (data, type) => {
        setShowValidationErrorMessage(false);
        let newdata = actionData[0];
        newdata[type] = data

        if (type == 'name')
            node.data.name = data
        else
            node.data.description = data
        setNewNodeInfo(obj => { let objCopy = { ...obj }; objCopy[type] = data; return objCopy })
        const empty = hasEmpty(newdata);
        let valid = validateActionCreationForm(type, newdata)

        const result = empty && valid;

        setSubmitBtnEnable(result);
    }
    const validateActionCreationForm = (key, actionData) => {
        if (key == "name" || key == "all") {
            if (actionData.name === '' || actionData.name.length < 5) {
                setShowValidationErrorMessage(true);
                setDataValidationErrorMessage('Action name should be greater than 5 characters.');
                return false;
            }

            if (!isAlphanumeric(actionData.name, 'en-US', { "ignore": "_" })) {
                setShowValidationErrorMessage(true);
                setDataValidationErrorMessage('Special characters other than _ is not allowed in action name.');
                return false;
            }
        }
        else if (key == "description" || key == "all") {
            if (actionData.description === '' || actionData.description.length < 20) {
                setShowValidationErrorMessage(true);
                setDataValidationErrorMessage('Action description should be greater than 20 characters.');
                return false;
            }
            if (!validateAlphaNumericString(actionData.description)) {
                setShowValidationErrorMessage(true);
                setDataValidationErrorMessage('Special characters other than _ @ . " \' & is not allowed in action description.');
                return false;
            }
        }
        return true;
    }
    const validateAlphaNumericString = (data) => {
        let sanitisedData = data.replaceAll(/[@.,'"&\s]/g, "");
        return isAlphanumeric(sanitisedData);
    }
    const hasEmpty = (element) => {
        return (
            (element.name != "" && element.name.length > 1) &&
            (element.description != "" && element.description.length > 19)
        );
    };

    const displayAugment = () => {
        switch (node.data.type) {
            case 'api':
                return (
                    <div className='panel' style={{ height: '60vh', overflowY: 'auto' }}>
                        <APIFormSecret node={node} changeValue={(key, value) => { node.data[key] = value; setAPI({ method: node.data.method, url: node.data.url, headers: node.data.headers, payload: node.data.payload, params: node.data.params }) }} method={node.data.method} URL={node.data.URL} headers={node.data.headers} payload={node.data.payload} params={node.data.params} oAuth={node.data.oAuth} client_ID={node.data.clientID} client_Secret={node.data.clientSecret} o_Auth_URL={node.data.oAuthURL} grant_Type={node.data.grantType} skill={node.data.skill} />
                        <div className='base-margin-top'>
                            {/* <input type='button' className={`btn btn--small btn--success node${node.id}switchCodeButton`} value='Switch to code' onClick={() => { node.data.type = 'code'; setForceReRender(!forceReRender) }} /> */}
                            <input type='button' className={`btn btn--small btn--success node${node.id}switchDBButton`} value='Switch to DB' onClick={() => { node.data.type = 'db'; setForceReRender(!forceReRender) }} />
                            <input type='button' className={`btn btn--small btn--success node${node.id}switchLinkButton`} value='Switch to reuse action' onClick={() => { node.data.type = 'link'; setForceReRender(!forceReRender) }} />
                        </div>
                        <div style={{ float: 'right' }} className="base-margin-right">
                            {/* <button className='btn btn--primary' onClick={() => { setSelectedTab('action') }}>Back</button>
                            <button className='btn btn--primary' onClick={() => { setSelectedTab('schedule') }}>Next</button> */}
                            <button className='btn btn--primary' onClick={() => { setSelectedTab('action') }}>Back</button>
                            <button className='btn btn--primary' onClick={() => { closeSplit() }}>Finish</button>
                        </div>
                    </div>
                )

            case 'db':
                return (
                    <div className='panel' style={{ height: '60vh', overflowY: 'auto' }}>
                        <DBForm changeValue={(key, value) => { node.data[key] = value; setDB(node.data.db) }} node={node} />
                        <div className='base-margin-top'>
                            <input type='button' className={`btn btn--small btn--success node${node.id}switchAPIButton`} value='Switch to API' onClick={() => { node.data.type = 'api'; setForceReRender(!forceReRender) }} />
                            {/* <input type='button' className={`btn btn--small btn--success node${node.id}switchCodeButton`} value='Switch to code' onClick={() => { node.data.type = 'code'; setForceReRender(!forceReRender) }} /> */}
                            <input type='button' className={`btn btn--small btn--success node${node.id}switchLinkButton`} value='Switch to reuse action' onClick={() => { node.data.type = 'link'; setForceReRender(!forceReRender) }} />
                        </div>
                        <div style={{ float: 'right' }} >
                            <button className='btn btn--primary' onClick={() => { setSelectedTab('action') }}>Back</button>
                            <button className='btn btn--primary' onClick={() => { closeSplit() }}>Finish</button></div>
                    </div>
                )
            case 'link':
                return (
                    <div className='panel base-margin-left base-margin-right'>
                        <div className='form-group base-margin-left base-margin-right paddingBottom12'>
                            <div className='form-group__text'>
                                <label><span>Reuse Action</span><span className='required'>*</span></label>
                            </div>
                            <SearchSelect
                                defaultValue={node.data.link}
                                options={actionList}
                                onValueChange={value => { node.data.link = value; setLink(value) }}
                            />
                        </div>
                        <div className='base-margin-top'>
                            <input type='button' className={`btn btn--small btn--success node${node.id}switchAPIButton`} value='Switch to API' onClick={() => { node.data.type = 'api'; setForceReRender(!forceReRender) }} />
                            {/* <input type='button' className={`btn btn--small btn--success node${node.id}switchCodeButton`} value='Switch to code' onClick={() => { node.data.type = 'code'; setForceReRender(!forceReRender) }} /> */}
                            <input type='button' className={`btn btn--small btn--success node${node.id}switchDBButton`} value='Switch to DB' onClick={() => { node.data.type = 'db'; setForceReRender(!forceReRender) }} />
                        </div>
                        <div style={{ float: 'right' }} >
                            <button className='btn btn--primary' onClick={() => { setSelectedTab('action') }}>Back</button>
                            <button className='btn btn--primary' onClick={() => { closeSplit() }}>Finish</button></div>
                    </div>
                )
            default:
                return (
                    <div className='panel'>
                        <div>Choose Action</div>
                        <div className='base-margin-top'>
                            <input type='button' className={`btn btn--small btn--success node${node.id}switchAPIButton`} value='API' onClick={() => { node.data.type = 'api'; setForceReRender(!forceReRender) }} />
                            {/* <input type='button' className={`btn btn--small btn--success node${node.id}switchCodeButton`} value='Code' onClick={() => { node.data.type = 'code'; setForceReRender(!forceReRender) }} /> */}
                            <input type='button' className={`btn btn--small btn--success node${node.id}switchDBButton`} value='DB' onClick={() => { node.data.type = 'db'; setForceReRender(!forceReRender) }} />
                            <input type='button' className={`btn btn--small btn--success node${node.id}switchLinkButton`} value='Reuse Action' onClick={() => { node.data.type = 'link'; setForceReRender(!forceReRender) }} />
                        </div>
                        <div style={{ float: 'right' }} >
                            <button className='btn btn--primary' onClick={() => { setSelectedTab('action') }}>Back</button>
                            <button className='btn btn--primary' onClick={() => { closeSplit() }}>Finish</button></div>
                    </div>
                )
        }
    }

    // const scheduleAction = () => {
    //         let payload = {
    //             "type":"ActionNode",
    //             "flag_scheduler":"True",
    //             "start_time":"2023-10-19 12:30 pm",
    //             "end_time":"2024-04-19 12:30 pm",
    //             "bot_id":"64e5984528424c3472b13fef",
    //             "webexRooms":["Y2lzY29zcGFyazovL3VzL1JPT00vNzIyYWFiNTAtNWQwNi0xMWVlLWJkOWItNTUxNjFmMzQwMWJh","Y2lzY29zcGFyazovL3VzL1JPT00vMzkzMTAyZjAtNjQ2Mi0xMWVlLTk2MmMtN2ZmN2YzMWQ5OTIx"],
    //             "userlist_schedule":["rohkumbh","dthagunn","shrsuvar","golewis"],
    //             "myidgroup_schedule":[],
    //             "time_zone":"Europe/London",
    //             "freq":"604800",
    //             "weekdays":[0,1,2,3]
    //         }
    // }
    // const stopScheduleAction = () => {

    // }

    return (
        <div className="panel panel--raised panel--bordered" style={{ height: '80vh' }}>
            <h6>Edit Action Node <span onClick={() => closeSplit()} className='icon-close' style={{ float: 'right' }}></span></h6>
            <hr />
            <div className="stepper-wrapper">
                <div className={`stepper-item action-stepper-item ${selectedTab === 'action' ? 'active' : 'completed'}`} onClick={() => { setSelectedTab('action') }}>
                    <div className="step-counter" style={{ borderColor: '#6abf4b', backgroundColor: selectedTab === 'action' ? '#fff' : '#6abf4b' }}></div>
                    <div className="step-name">Action Details</div>
                </div>
                <div className={`stepper-item action-stepper-item ${selectedTab === 'type' ? 'active' : selectedTab === 'action' ? '' : 'completed'}`} onClick={() => { setSelectedTab('type') }}>
                    <div className="step-counter" style={{ borderColor: selectedTab === 'action' ? '#ccc' : '#6abf4b', backgroundColor: selectedTab === 'schedule' ? '#6abf4b' : '#fff' }}></div>
                    <div className="step-name">{node.data.type === 'api' ? 'API Details' : node.data.type === 'db' ? 'DB Details' : node.data.type === 'link' ? 'Reuse Action' : 'Select Action'}</div>
                </div>
                {/* <div className={`stepper-item action-stepper-item ${selectedTab === 'schedule' ? 'active' : ''}`} onClick={() => { setSelectedTab('schedule') }}>
                    <div className="step-counter" style={{ borderColor: selectedTab === 'schedule' ? '#6abf4b' : '#ccc' }}></div>
                    <div className="step-name">Schedule Action</div>
                </div> */}
            </div>
            <div style={{ height: '72vh' }}>
                <div className="panel" style={{ display: selectedTab === 'action' ? 'block' : 'none' }}>
                    <div className='base-margin-left base-margin-right base-margin-bottom'>
                        {
                            showValidationErrorMessage && (<div className="popBanner">{dataValidationErrorMessage}</div>)
                        }
                        <div>
                            <div className="form-group base-margin-left base-margin-right">
                                <div className="form-group__text">
                                    <input id={`node${node.id}name`} type="text" placeholder="Please Enter the Action Name" value={node.data.name} onChange={event => { inputHandlerActionChange(event.target.value, 'name'); setForceReRender(!forceReRender) }} required />
                                    <label htmlFor={`node${node.id}name`}>
                                        <span>Name</span>
                                        <span title="Please Enter the Rule Name" className='required'>*</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div>
                            <div className="form-group base-margin-right base-margin-left">
                                <div className="form-group__text">
                                    <textarea id="textarea-state-required" rows="3" placeholder="Please Enter the Action Description" value={node.data.description} onChange={event => { inputHandlerActionChange(event.target.value, 'description'); setForceReRender(!forceReRender) }} required></textarea>
                                    <label htmlFor="textarea-state-required">
                                        <span>Description</span>
                                        <span title="Please Enter the Rule Description" className='required'>*</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div style={{ float: 'right' }} className="base-margin-right base-margin-top">
                            <button className='btn btn--primary' onClick={() => { setSelectedTab('type') }}>Next</button>
                        </div>
                    </div>

                </div>
                <div style={{ height: '50px', display: selectedTab === 'type' ? 'block' : 'none' }}>{displayAugment()}


                </div>
                {/* <div className='panel base-margin-left base-margin-right' style={{ display: selectedTab === 'schedule' ? 'block' : 'none' }}>
                    <div className='base-margin-left base-margin-right'>
                        <label className="switch">
                            <input type="checkbox" checked={node.data.schedule} onChange={event => { node.data.schedule = event.target.checked; setForceReRender(!forceReRender) }} />
                            <span className='switch__label'>Enable scheduling</span>
                            <span className="switch__input"></span>
                        </label>
                        <ScheduleForm action={node.id} rule={node.data.rule} skill={node.data.skill} />
                    </div>
                    <div style={{ float: 'right' }} className="base-margin-right">
                        <button className='btn btn--primary' onClick={() => { setSelectedTab('type') }}>Back</button>
                        <button className='btn btn--primary' onClick={() => { closeSplit() }}>Finish</button>
                    </div>
                </div > */}
            </div>
        </div>
    )
}

export default ActionNodeDetails
