import './CodeEditor.css';

import Editor from "@monaco-editor/react";
import { useState } from "react";
import { ClockLoader as Loader } from "react-spinners";

const CodeEditor = ({ editorLanguage = "json", updateFormatScriptFromEditor, code = `def formatScriptExecutor(result):\n\t# [{"value":"Bangalore","synonyms":["Bengaluru","BLG"]}...]\n\tentities = result["entities"] \n\treturn entities` }) => {
  const [theme, setTheme] = useState("vs-dark");
  const [language, setLanguage] = useState(editorLanguage);
  const [isEditorReady, setIsEditorReady] = useState(false);
  const [data, setData] = useState(() => {
    if (language === "json") {
      return code;
    }
    else if (language === "python") {
      return code;
    }
    else {
      return '';
    }
  })

  // this style object is to dynamically calculate the height on main content
  const divstyle = {
    height: 'calc(100% - 63px)',
    fallbacks: [
      { height: '-moz-calc(100% - 63px)' },
      { height: '-webkit-calc(100% - 63px)' },
      { height: '-o-calc(100% - 63px)' }
    ],
    overflow: "auto",
    width: "calc(100% - 30px)",
    margin: "5px 25px 5px 5px"
  }

  const handleEditorDidMount = () => {
    setIsEditorReady(true);
  }

  const toggleTheme = () => {
    setTheme(() => {
      return theme === "vs-dark" ? "light" : "vs-dark";
    })
  }
  const clearData = () => {
    setData('');
    updateFormatScriptFromEditor('');
  }
  const copyData = () => {
    console.log(data)
  }
  const prettifyData = () => {
    try {
      let parsedData = JSON.parse(data);
      setData(JSON.stringify(parsedData, undefined, 4));
      updateFormatScriptFromEditor(JSON.stringify(parsedData, undefined, 4));
    }
    catch (err) {
      console.log(err);
    }

  }

  const renderEditorOptions = () => {
    if (language === "json") {
      return (
        <>
          <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3' onClick={() => { prettifyData() }} style={{ cursor: "pointer" }}>
            <span className="icon-file-code-o icon-size-24 code-editor-options-icon"></span>
            <span className='code-editor-options-lable'>Prettify</span>
          </div>
          <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3' onClick={() => { copyData() }} style={{ cursor: "pointer" }}>
            <span className="icon-file-o icon-size-24 code-editor-options-icon"></span>
            <span className='code-editor-options-lable'>Copy</span>
          </div>
          <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3' onClick={() => { clearData() }} style={{ cursor: "pointer" }}>
            <span className="icon-delete icon-size-24 code-editor-options-icon"></span>
            <span className='code-editor-options-lable'>Clear</span>
          </div>
          <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3' onClick={() => { toggleTheme() }} style={{ cursor: "pointer" }}>
            <span className="icon-team-collapsed-view icon-size-24 code-editor-options-icon"></span>
            <span className='code-editor-options-lable'>Theme</span>
          </div>
        </>
      )
    }
    else {
      return (
        <>
          <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4' onClick={() => { copyData() }} style={{ cursor: "pointer" }}>
            <span className="icon-file-o icon-size-24 code-editor-options-icon"></span>
            <span className='code-editor-options-lable'>Copy</span>
          </div>
          <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4' onClick={() => { clearData() }} style={{ cursor: "pointer" }}>
            <span className="icon-delete icon-size-24 code-editor-options-icon"></span>
            <span className='code-editor-options-lable'>Clear</span>
          </div>
          <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4' onClick={() => { toggleTheme() }} style={{ cursor: "pointer" }}>
            <span className="icon-team-collapsed-view icon-size-24 code-editor-options-icon"></span>
            <span className='code-editor-options-lable'>Theme</span>
          </div>
        </>
      )
    }
  }

  return (
    <div style={{ "height": "100%", "width": "100%" }}>

      <div className="code-editor-options">
        <div className='row'>
          {
            renderEditorOptions()
          }

        </div>
      </div>

      <hr></hr>
      <div style={divstyle}>
        <Editor
          height="100%"
          theme={theme}
          language={language}
          loading={<Loader />}
          value={data}
          editorDidMount={handleEditorDidMount}
          onChange={(value, event) => { updateFormatScriptFromEditor(value); setData(value) }}
        />
      </div>

    </div>

  )
}

export default CodeEditor;

