import './RuleNode.css';

import ReactFlow, {
	Handle, Position,
	useReactFlow,
	getConnectedEdges,
	useStore,
	useViewport
} from 'react-flow-renderer';
import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import DialogService from '../../../../dialog-service/DialogService';

const ConnectableFn = (id, direction) => {
	const edgesCount = useStore((store) => store.edges.length);
	const { getNode, getEdges } = useReactFlow();

	const [hasConnections, setHasConnections] = useState(false);

	useEffect(() => {
		setHasConnections(() => {
			const edges = getConnectedEdges([getNode(id)], getEdges()).filter((e) => e[direction] === id);
			return edges.length > 0;
		});
	}, [getNode, getEdges, id, edgesCount]);

	return hasConnections;
};


function RuleNode({ id, data }) {

	const [open, setOpen] = useState(false);
	const [slots, setSlots] = useState(data.slots)
	const [forceReRender, setForceReRender] = useState(false)
	const { getNodes, getEdges, setNodes, setEdges } = useReactFlow();
	const nodes = getNodes();
	const edges = getEdges();

	useEffect(() => {
		setOpen(data.open)
		setSlots(data.slots)
	}, [])

	useEffect(() => {
		editRuleFunction()
	})

	let viewport = useViewport()
	//if (viewport.x !== 0 || viewport.y !== 0 || viewport.zoom !== 1) {
	localStorage.setItem('viewport-x', viewport.x)
	localStorage.setItem('viewport-y', viewport.y)
	localStorage.setItem('viewport-zoom', viewport.zoom)
	//}

	function updateReRender() {
		setForceReRender(!forceReRender)
	}

	function addSlot() {
		setSlots(slots => [...slots, {}])
	}

	function deleteSlot() {
		setSlots(slots => slots.slice(0, slots.length - 1))
	}

	const editRuleFunction = () => {
		if (document.getElementById(`node${id}name`))
			document.getElementById(`node${id}name`).onkeyup = updateReRender
		if (document.getElementById(`node${id}addSlotButton`))
			document.getElementById(`node${id}addSlotButton`).onmouseup = addSlot
		for (let element of document.getElementsByClassName(`node${id}deleteSlotButton`))
			element.onmouseup = deleteSlot
	}

	const onDoubleClick = () => {
		if (open) {
			setOpen(false);
			data.open = false;
		} else {
			setOpen(true);
			data.open = true;
		}
	};

	const onDragOver = event => event.preventDefault();

	const onDrop = event => {
		event.preventDefault();
		const augmentData = event.dataTransfer.getData('augment');
		if (augmentData === 'slot')
			data.addSlot();
	};

	const returnType = type => {
		if (type === 'custom')
			return 'Custom Variable';
		if (type === 'entity')
			return 'Entity';
	};

	const getSlots = () => {
		if (data.slots.length && open)
			return (
				<div className='augmentsContainer' id={`slots${id}`}>
					<ul className='augmentsList ' id={`ul${id}`}>
						{data.slots.map((slot, index) => <li key={index} className="augmentsListElement">{returnType(slot.type)} - {slot.save_as}</li>)}
					</ul>
				</div>
			)
	};


	const deleteNodeById = (id) => {
		DialogService.deleteNodeById(data.skill, data.rule, id).then(res => res.json()).then(res => {
			if (res.status == 'success') {
				setEdges([])
				setNodes([])
				localStorage.removeItem('rule_name')
				localStorage.removeItem('rule_value')
			}
			else {
				setEdges([])
				setNodes([])
				localStorage.removeItem('rule_name')
				localStorage.removeItem('rule_value')
				// setDataLoading(false);
				// setBannerMessageType('error');
				// setBannerMessage(res['details']);
				// setShowMessageBanner(true);
			}
		}).catch(err => {
			// setDataLoading(false);
			// setBannerMessageType('error');
			// setBannerMessage('Something went wrong. Please try again later!');
			// setShowMessageBanner(true);
		})
	}


	const hasSourceConnections = ConnectableFn(id, "source");

	const truncateStrings = (str, length) => {
		if (str) {
			if (length > str.length) {
				return str
			}
			else {
				str = str.substring(0, length) + '...'
				return str;
			}
		}
		else {
			return '';
		}
	}

	return (

		<div className='nodeContainer ruleNodeContainer' id={`nodeContainer${id}`} onDragOver={onDragOver} onDrop={onDrop}>
			{data.showConfirmation == true &&
				<div style={{ margin: '3px' }}>
					<div style={{ fontSize: '0.8em' }}>
						Are you sure you want to delete the Rule Node - {data.name}?
					</div>
					<div className='text-center' style={{ marginTop: '2px' }}>
						<button className="btn btn--small btn--secondary" style={{ fontSize: '0.8em' }} onClick={() => { data.showConfirmation = false; }} >
							No
						</button>
						<button className="btn  btn--small btn--secondary" style={{ fontSize: '0.8em' }} onClick={() => { deleteNodeById(id) }} >
							Yes
						</button>
					</div>
					<hr />
				</div>
			}
			<div className='node' id={`node${id}`} onDoubleClick={onDoubleClick}>
				<Handle
					type='source'
					position={Position.Bottom}
					id='ruleHandle'
					className="react-flow_handle"
					isConnectable={!hasSourceConnections}
				/>
				<div>
					<div className='nodeType'>
						Rule
					</div>
					<div className='nodeDeleteType' >
						<span className='icon-delete' onClick={() => { console.log(id); data.showConfirmation = true; }}></span>
					</div>
					<div className='nodeCount'>
						{slots.length === 1 ? '1 Slot' : `${slots.length} Slots`}
					</div>
				</div>
				<div className='nodeName'>
					{truncateStrings(data.name, 20)}
				</div>
			</div>
			{getSlots()}

		</div>
	);
}

export default RuleNode;
