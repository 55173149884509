const WrapperService = {
    getDynamicMessage: (path)=>{
        let b64path = btoa(path);
        var reqHeaders = new Headers();
        reqHeaders.append("Content-Type", "application/json");
        var requestOptions = {
          method: "GET",
          headers: reqHeaders,
        };
        return fetch(`${process.env.REACT_APP_ROOT_URL}/${b64path}/getDynamicMessage`,  requestOptions);
    }

};

export default WrapperService;