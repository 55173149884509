import React from "react";
import '../skill-library-view-component/SkillLibraryViewComponent.css';

export default function SkillLibrary({ key, index, isMySkill, skillLibrary, onSkillCloneClick,  showEditSill, selectedID }) {
    //let activate_bg =  isMySkill ? "#ced4da" : "white";
    //let activate = skillLibrary['activate']?"black":"#212529"

    let activate_bg = "white";
    let activate = "#212529"
    let cloned = skillLibrary['cloned'] ? true : false;
    let clone_pending_approval = skillLibrary['clone_pending_approval'] ? true : false;

    function truncateStrings(str, length) {
        if (length > str.length) {
            str = str + " ".repeat(length - str.length);
            return str
        }

        str = str.substring(0, length) + "..."
        return str;
    }

    function len(str) {
        return str.length
    }

    return (

        <>
            <div className={showEditSill ? skillLibrary["_id"] === selectedID ? "align-items-center panel panel--well panel--bordered base-margin col-12 col-xs-12 col-sm-12 col-md-12 col-lg-5 col-xl-12 col-xxl-12 col-xxxl-12" : "align-items-center panel panel--raised panel--bordered base-margin col-12 col-xs-12 col-sm-12 col-md-12 col-lg-5 col-xl-12 col-xxl-12 col-xxxl-12" : "align-items-center panel panel--raised panel--bordered base-margin col-12 col-xs-12 col-sm-12 col-md-8 col-lg-5 col-xl-3 col-xxl-4 col-xxxl-4 pageTour3"} style={{ borderRadius: '3vh', background: activate_bg, color: activate, height: 'fit-content', opacity: (isMySkill | cloned | skillLibrary['clone_pending_approval'])  ? '0.5' : '', width: "calc(100% - 40px)" }} key={index}>

                <div className="flex-center-vertical" style={{ float: "right", display: cloned ? "block" : "none", marginRight: '15px', marginTop: '15px' }} data-balloon="You have already cloned this Skill!" data-balloon-pos="down" data-balloon-length="medium">
                    <div className="flex-center">
                        <span className="icon-certified icon-size-24"></span>
                    </div>
                </div>
				
				<div className="flex-center-vertical" style={{ float: "right", display: clone_pending_approval ? "block" : "none", marginRight: '15px', marginTop: '15px' }} data-balloon="Your request to copy/clone this Skill is pending Approval!" data-balloon-pos="down" data-balloon-length="medium">
                    <div className="flex-center">
                        <span className="icon-check-outline icon-size-24"></span>
                    </div>
                </div>
               
                <div>
                    <div className="base-margin">

                        <div className="row">
                            {
                                <div className="col-3 col-sm-3 col-xs-3 col-md-3 col-lg-3 col-xl-3 col-2xl-3">
                                    <img src={process.env.PUBLIC_URL + "/images/skills.png"} alt="skillLogo" style={{ borderRadius: '50%' }} />
                                </div>

                            }
                            <div className="col-9 col-sm-9 col-xs-9 col-md-9 col-lg-9 col-xl-9 col-2xl-9" >
                                <div className="skill-name">
                                    <h6 style={{ "color": "black" }}><b>{skillLibrary['name']}</b></h6>
                                </div>
                                <div className="row base-margin-top base-margin-right">
                                    <div style={{ textAlign: 'justify', height: '70px', marginLeft: '14px' }}> {truncateStrings(skillLibrary['description'], 75)}{len(skillLibrary['description']) > 100 ? '...' : ''}</div>
                                </div>
                            </div>
                        </div>

                        <div className="base-margin-top base-margin-bottom flex flex-wrap flex-center col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 col-xxxl-12">
                            <div style={{ visibility: (skillLibrary['clonedCount'] && skillLibrary['clonedCount'] > 0) ? 'visible' : 'hidden' }}>
                                <div className="panel panel--well panel--bordered" style={{ "width": "fit-content" }}>

                                    <div className="flex">
                                        <div style={{ "margin": "0px 3px" }}>
                                            <span className='icon-contact icon-size-24' ></span>
                                        </div>
                                        <div style={{ "margin": "0px 3px" }}>
                                            <span>{skillLibrary['clonedCount']}</span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div>
                               
                            </div>
                        </div>
                        <hr style={{ marginBottom: '0px' }} />
                        <br />

                        <div className="base-margin-left  base-margin-bottom flex flex-wrap " style={{ position: 'absolute', right: '0', bottom: '0', marginTop: '5px' }}>
                            <div style={{ display: (skillLibrary['replica_type'] == "read_only") ? 'block' : 'none' }}>
                                {
                                    <div className="flex-center-vertical skill-action">
                                       <div className="flex-center" data-balloon="Click to clone this skill" 
											data-balloon-pos="left" data-balloon-length="medium" 
											style={{ display: ! (cloned | clone_pending_approval | isMySkill) ? 'block' : 'none' }} >
                                            <span className="icon-clone icon-size-24" 
												onClick={() => onSkillCloneClick(skillLibrary["_id"])} 
												 style={{marginRight: '22px'}}>
											</span>
                                            {/*<span className="icon-clone icon-size-24" 
												style={{ display: (cloned | clone_pending_approval | isMySkill) ? 'block' : 'none' }}>
											</span>*/}
                                        </div>
										<div className="flex-center" data-balloon="You cannot clone a Skill if it is either published or already cloned by you!" 
											data-balloon-pos="left" data-balloon-length="medium" 
											style={{ display: (cloned | clone_pending_approval | isMySkill) ? 'block' : 'none' }}>
											{/*<span className="icon-clone icon-size-24" 
												style={{ display: ! (cloned | clone_pending_approval | isMySkill) ? 'block' : 'none' }} 
												onClick={() => onSkillCloneClick(skillLibrary["_id"])}>
											</span>*/}
                                            <span className="icon-clone icon-size-24" style={{marginRight: '22px'}}>
											</span>
                                        </div>
                                    </div>                             
								}
                            </div>

                            <div style={{ display: (skillLibrary['replica_type'] == "clone") ? 'block' : 'none'}}>
                                {
                                    <div className="flex-center-vertical skill-action">
                                        <div className="flex-center" data-balloon="Click to clone this skill" 
											data-balloon-pos="left" data-balloon-length="medium" 
											style={{ display: ! (cloned | clone_pending_approval | isMySkill) ? 'block' : 'none' }} >
                                            <span className="icon-clone icon-size-24" style={{marginRight: '22px'}}
												onClick={() => onSkillCloneClick(skillLibrary["_id"])}>
											</span>
                                            {/*<span className="icon-clone icon-size-24" 
												style={{ display: (cloned | clone_pending_approval | isMySkill) ? 'block' : 'none' }}>
											</span>*/}
                                        </div>
										<div className="flex-center" data-balloon="You cannot clone a Skill if it is either published or already cloned by you!" 
											data-balloon-pos="left" data-balloon-length="medium" 
											style={{ display: (cloned | clone_pending_approval | isMySkill) ? 'block' : 'none' }}>
											{/*<span className="icon-clone icon-size-24" 
												style={{ display: ! (cloned | clone_pending_approval | isMySkill) ? 'block' : 'none' }} 
												onClick={() => onSkillCloneClick(skillLibrary["_id"])}>
											</span>*/}
                                            <span className="icon-clone icon-size-24" style={{marginRight: '22px'}}>
											</span>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}