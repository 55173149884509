import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import MessageBanner from '../../../../custom-components/MessageBanner/MessageBanner';
import SearchSelect from '../../../../custom-components/search-select/SearchSelect';
import BotService from '../../../bot-module/bot-services/BotService';
import IntegrationBlock from '../Integration-block-component/IntegrationBlockComponent';
import IntegrationService from '../../integration-service/IntegrationService';
import Joyride, { CallBackProps, STATUS, Step } from 'react-joyride';
import Spinner from "../../../../custom-components/spinner/Spinner";

const IntegrationView = () => {
    const [isDataLoading, setDataLoading] = useState(false);
    const [showMessageBanner, setShowMessageBanner] = useState(false);
    const [bannerMessage, setBannerMessage] = useState('');
    const [bannerMessageType, setBannerMessageType] = useState('success');
    const [bot, setBot] = useState();
    const [bots, setBotList] = useState([]);
    const [botsDetails, setBotsDetails] = useState([])
    const [integrationOfBot, setIntegrationOfBot] = useState({})
    const [showGuidance, setShowGuidance] = useState(false);

    useEffect(() => {
        setShowGuidance(false);
        if (isDataLoading === false && bots && bots.length === 0) {
            setShowGuidance(true);
        }
    }, [bots, isDataLoading])

    const display_message = (type, message, show) => {
        setShowMessageBanner(show);
        setBannerMessage(message);
        setBannerMessageType(type);
    }
    useEffect(() => {
        setDataLoading(true);
        BotService.listBots()
            .then((res) => res.json())
            .then((res) => {
                if (res["bots"] != undefined) {
                    let bot_list = [];
                    let bot_integration_details = {};
                    setBotsDetails(res['bots'])
                    for (let bot of res["bots"]) {
                        bot_list.push({
                            label: bot["display_name"], value: bot["_id"], "skills": bot.skills,
                            "trained": bot.upToDate,
                            //"integrations": bot.integrations.length
                        });

                    }
                    setBotList(bot_list);
                    if (localStorage.getItem('botSelectedName') && localStorage.getItem('botSelectedId')) {
                        console.log("integration", localStorage)
                        setBot({
                            "label": localStorage.getItem('botSelectedName'),
                            "value": localStorage.getItem('botSelectedId'),
                        })
                    }
                    else {
                        setBot(bot_list[0]);
                        localStorage.setItem('botSelectedName', bot_list[0].display_name)
                        localStorage.setItem('botSelectedId', bot_list[0]._id)
                        localStorage.setItem('botSkills', btoa(JSON.stringify(bot_list[0].skills)))
                        localStorage.setItem('botTrained', bot_list[0].upToDate)
                        //localStorage.setItem('botIntegration', bot_list[0].integrations.length)
                    }

                    setDataLoading(false);
                } else {
                    setDataLoading(false);
                    setBannerMessageType("error");
                    setBannerMessage("Failed to retrieve bots!");
                    setShowMessageBanner(true);
                }
            })
            .catch((err) => {
                setDataLoading(false);
                setBannerMessageType("error");
                setBannerMessage("Failed to retrieve bots!");
                setShowMessageBanner(true);
            });
    }, []);

    const getIntegration = (bot) => {
        IntegrationService.getIntegrations(bot["value"]).then(res => res.json()).then(res => {
            if (res["status"] === "success") {
                res = res["integrations"] || []
                let bot_integration_details = {};
                for (let integration of res) {
                    if (integration["type"] === "webex") {
                        bot_integration_details["webex"] = {
                            "active_users": 0,
                            "externalise": integration["externalise"],
                            "activate": !integration["activate"],
                            "externalised_users": [],
                            "username": integration["username"],
                            "access_token": integration["access_token"],
                            "help_info": integration["help_info"],
                            "default_response": integration["default_response"]
                        }
                    }
                    else if (integration["type"] === "widget") {
                        bot_integration_details["widget"] = {
                            "active_users": 0,
                            "externalise": integration["externalise"],
                            "activate": integration["activate"],
                            "externalised_users": [],
                            "username": "",
                            "data": ""
                        }
                    }
                    else if (integration["type"] === "widget_code") {
                        bot_integration_details["widget_code"] = {
                            "active_users": 0,
                            "externalise": integration["externalise"],
                            "activate": !integration["activate"],
                            "externalised_users": [],
                            "username": "",
                            "data": ""
                        }
                    }
                    else if (integration["type"] === "widget_chathandler") {
                        bot_integration_details["widget_chathandler"] = {
                            "active_users": 0,
                            "externalise": integration["externalise"],
                            "activate": !integration["activate"],
                            "externalised_users": [],
                            "username": "",
                            "data": ""
                        }
                    }
                }
                setIntegrationOfBot(bot_integration_details);
            }

            setDataLoading(false);
        }).catch(err => {
            console.log(err);
        })

    }
    useEffect(() => {
        if (bot) {
            setDataLoading(true);
            getIntegration(bot)
        }

    }, [bot])

    const updateBot = (data) => {
        setBot(data);
        localStorage.setItem('botSelectedName', data.label)
        localStorage.setItem('botSelectedId', data.value)
        localStorage.setItem('botSkills', btoa(JSON.stringify(data.skills)))
        localStorage.setItem('botTrained', data.upToDate || data.trained)
        //localStorage.setItem("botIntegration", data.integrations.length)
        getIntegration(data);
    }

    const BotList = () => {
        return (
            <SearchSelect
                id="botList"
                className="augmentSelect"
                defaultValue={bot}
                options={bots}
                onValueChange={(data) => {
                    updateBot(data);
                    display_message('error', '', false)
                }}
            />
        );
    };

    const [{ run, steps }, setState] = useState({
        run: true,
        steps: [
            {
                content: <h6>Welcome to <b>Integration Screen</b>. Here, you can manage Integration details.</h6>,
                locale: { skip: <span aria-label="skip">Skip</span> },
                placement: 'center',
                target: '.pageTour1',
            },
            {
                content: <h6>Here, you can select bot from this dropdown.</h6>,
                floaterProps: {
                    disableAnimation: true,
                },
                spotlightPadding: 20,
                target: '.pageTour2',
            },
            {
                content: <h6>Here you can see all the lists.</h6>,
                floaterProps: {
                    disableAnimation: true,
                },
                spotlightPadding: 20,
                target: '.pageTour3',
            }
        ]
    });
    const handleJoyrideCallback = (data) => {
        const { status, type } = data;
        const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

        if (finishedStatuses.includes(status)) {
            setState({ run: false });
        }
    };


    return <div style={{ height: "calc(100% - 8px)" }}>
        {isDataLoading != true && showGuidance ? <Joyride
            callback={handleJoyrideCallback}
            continuous
            hideCloseButton
            run={run}
            scrollToFirstStep
            showProgress
            showSkipButton
            steps={steps}
            styles={{
                options: {
                    zIndex: 10000,
                },
                buttonNext: {
                    background: '#1e4471',
                    border: '1px solid #1e4471',
                    borderRadius: '5px'
                },
                buttonBack: {
                    color: '#1e4471',
                    border: '1px solid #1e4471',
                    borderRadius: '5px'
                },
                buttonSkip: {
                    color: '#1e4471',
                    border: '1px solid #1e4471',
                    borderRadius: '5px'
                },
            }}
        /> : ""}
        {
            isDataLoading && (<Spinner></Spinner>)
        }
        <div style={{ borderBottom: "1px #ced4da solid" }} className='pageTour1'>
            <div className="row base-margin-top base-margin-right">
                <h5 className="col-7 col-md-8 col-sm-8 col-lg-8 col-xl-8 col-xxl-9 col-xxxl-9 ">
                    Integration
                </h5>
                <div
                    className="col-5 col-md-4 col-sm-4 col-lg-4 col-xl-4 col-xxl-3 col-xxxl-3 pageTour2"
                    style={{ marginBottom: "15px" }}
                >
                    {BotList()}
                </div>
            </div>
        </div>
        {showMessageBanner && <MessageBanner message={bannerMessage} messageType={bannerMessageType} onCloseClicked={() => { setShowMessageBanner(false) }}></MessageBanner>}
        <div style={{ height: "calc(100% - 70px)" }}>
            <div style={{ height: "100%" }} className='pageTour3'>
                <IntegrationBlock platform={integrationOfBot} bot={bot} display_message={(type, message, show) => display_message(type, message, show)} getIntegrations={(bot) => getIntegration(bot)} />
            </div>
        </div>
    </div>
};

export default IntegrationView;
