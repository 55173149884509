import { useState } from 'react';
import SearchSelect from '../../../../../../custom-components/search-select/SearchSelect';
import './JumpToNode.css';
import isAlphanumeric from 'validator/lib/isAlphanumeric';

function JumpToNodeDetails({ node, closeFunction, rules }) {
    const closeSplit = () => { closeFunction() }
    const [forceReRender, setForceReRender] = useState(false)
    const [showValidationErrorMessage, setShowValidationErrorMessage] = useState(false);
    const [dataValidationErrorMessage, setDataValidationErrorMessage] = useState('');

    const inputHandlerNameChange = (data) => {

        setShowValidationErrorMessage(false);

        node.data.name = data
        console.log(data)
        const validName = () => {
            if (data === '' || data.length < 5) {
                setShowValidationErrorMessage(true);
                setDataValidationErrorMessage('Name should be greater than 5 characters.');
                return false;
            }

            if (!isAlphanumeric(data, 'en-US', { "ignore": "_" })) {
                setShowValidationErrorMessage(true);
                setDataValidationErrorMessage('Special characters other than _ is not allowed in name.');
                return false;
            }
        }

        let valid = validName();
        // node.data.name = temp;
        const empty = data != '' && data.length > 1;

        const result = empty && valid


    }
    return <div className="panel panel--raised panel--bordered" style={{ maxHeight: '83vh', overflowY: 'auto' }}>
        <h6>Edit Jump To Node <span onClick={() => closeSplit()} className='icon-close' style={{ float: 'right' }}></span></h6>
        <hr />
        <div className="panel" style={{ height: '75vh' }}>
            {
                showValidationErrorMessage && (<div className="popBanner">{dataValidationErrorMessage}</div>)
            }
            <form>
                <div className="form-group base-margin-bottom" >
                    <div className="form-group__text">
                        {/* <input id="input-type-number" type="text" name="rule_name" onChange={event => { setNewNodeInfo(obj => { let objCopy = { ...obj }; objCopy.name = event.target.value; return objCopy }) }} /> */}
                        <input id="input-type-number" type="text" name="rule_name" autoComplete='off' value={node.data.name} onChange={event => { inputHandlerNameChange(event.target.value); setForceReRender(!forceReRender) }} />
                        <label htmlFor="input-type-number">JumpTo Name <span className='required'>*</span> </label>
                    </div>
                </div>
                <div >
                    <div className="form-group ">
                        <div className="form-group">
                            <div className='form-group__text'>
                                <label htmlFor="select-type-basic">Destination Rule<span className='required'>*</span></label>
                            </div>
                            <SearchSelect
                                id={`node${node.id}jumpToNode`}
                                style={{ zIndex: '1000' }}
                                defaultValue={node.data.toNode}
                                options={rules}
                                onValueChange={data => { node.data.toNode = data; setForceReRender(!forceReRender) }}
                            />
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
}

export default JumpToNodeDetails