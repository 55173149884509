const integration_url = `${process.env.REACT_APP_ROOT_URL}/bot`;

const IntegrationService = {
 getIntegrations: (bot_id)=>{
    let requestOptions = {
        method: 'GET',
        redirect: 'follow'
      };
      
    return fetch(`${integration_url}/${bot_id}/integration`, requestOptions);
 },

 createIntegration: (bot_id, data)=>{
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify(data),
      redirect: 'follow'
    };
    
    return fetch(`${integration_url}/${bot_id}/integration`, requestOptions);
 },

 updateIntegration: (bot_id, data)=>{
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: JSON.stringify(data),
      redirect: 'follow'
    };
    
    return fetch(`${integration_url}/${bot_id}/integration`, requestOptions);
 },

 deactivateIntegration: (bot_id, channel)=>{
  var requestOptions = {
      method: 'DELETE'
  };

  return fetch(`${integration_url}/${bot_id}/integration/${channel}`, requestOptions);
 }

};

export default IntegrationService;