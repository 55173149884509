import './SkillLibraryViewComponent.css';
import isAlpha from 'validator/lib/isAlpha';
import isAlphanumeric from 'validator/lib/isAlphanumeric';

import React, { useState, useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import SearchSelect from "../../../../custom-components/search-select/SearchSelect";
import Spinner from "../../../../custom-components/spinner/Spinner";
import MessageBanner from "../../../../custom-components/MessageBanner/MessageBanner";
import SkillLibrary from '../skill-library-component/SkillLibraryComponent';
import SkillService from "../../../skill-module/skill-services/SkillService"
import SkillLibraryService from "../../skill-library-service/SkillLibraryService";
import { useEffect } from "react";
import Joyride, { CallBackProps, STATUS, Step } from 'react-joyride';

export const SkillLibraryView = () => {

    const [categoryList, setCategoryList] = useState([])
    const [category, setCategory] = useState({});
    const [userId, setUserId] = useState('');
    const [skillList, setSkillList] = useState([]);
    const [skillLibraryList, setSkillLibraryList] = useState([]);
    const [isDataLoading, setDataLoading] = useState(false);
    const [showMessageBanner, setShowMessageBanner] = useState(false);

    const [bannerMessage, setBannerMessage] = useState('');
    const [bannerMessageType, setBannerMessageType] = useState('success');

    const [skillLibId, setSkillLibId] = useState('');
	const [skillId, setSkillId] = useState('');
    const [cloneName, setCloneName] = useState("");
    const [cloneDescription, setCloneDescription] = useState("");
	const [cloneApprovalRequired, setCloneApprovalRequired] = useState(false);
    const [showCloneSkill, setShowCloneSkill] = useState(false);
	const [isDuplidateSkillName, setIsDuplidateSkillName] = useState(false);
	const [checkDuplicateSkill, setCheckDuplicateSkill] = useState(false);
	const [enableCloneSkillButton, setEnableCloneSkillButton] = useState(false);
	
    const [dataValidationErrorMessage, setDataValidationErrorMessage] = useState('');
    const [showValidationErrorMessage, setShowValidationErrorMessage] = useState(false);
    const [showEditSkill, setShowEditSkill] = useState(false);
    const [selectedSkillId, setSelectedSkillId] = useState(undefined);
    
	const [layoutType, setLayoutType] = useState(window.innerWidth > 899.9 ? 'horizontal' : 'verticle');

    const handleResize = (() => {
        setLayoutType(window.innerWidth > 899.9 ? 'horizontal' : 'verticle');
    })
	
	const cloneSkillInputRef = useRef(null);
	
	useEffect(() => {
        window.addEventListener('resize', handleResize);
        let userEmail = localStorage.getItem("email");
        if (userEmail !== undefined) {
            setUserId(userEmail);
        } else {
            setUserId("$context.authorizer.principalId")
        }

    }, [])

    useEffect(() => {
        fetchAllSkills()
    }, [userId])
	
	useEffect(() => {
		activateSkillClone();
	}, [cloneName, cloneDescription, checkDuplicateSkill])
	
	const activateSkillClone = () => {
		let validInputs = (cloneName && cloneName.length > 2) && (cloneDescription && cloneDescription.length > 19);
		console.log("checkDuplicateSkill :"+checkDuplicateSkill)
		console.log("isDuplidateSkillName :"+isDuplidateSkillName)
		setEnableCloneSkillButton(validInputs && checkDuplicateSkill && !isDuplidateSkillName);
	}
	
	const handleSkillNameOnBlur = (event) => {
		let skillName = event.target.value;
		console.log("entered skill name: " + skillName);
		if (!checkDuplicateSkill && skillName.trim().length > 3) {
			console.log("entered skill name inside: " + skillName);
			checkForDuplicateSkill(skillName);
		}
	}
	
	const checkForDuplicateSkill = (skillName) => {
		setShowValidationErrorMessage(false);
		setShowMessageBanner(false);
		setDataLoading(true);
		setIsDuplidateSkillName(false);
		setEnableCloneSkillButton(false);
		SkillService.getSkillByName(skillName).then(res => res.json()).then(res => {
			console.log("Got response: " + res);
			setCheckDuplicateSkill(true);
			setDataLoading(false);
			if (res["status"] == "success" && res["skill"]) {
				let response = res["skill"];
				if (response.name) {
					setIsDuplidateSkillName(true);
					setShowMessageBanner(true);
					setBannerMessageType('error');
					setBannerMessage("A Skill with the same name already exists!");
					cloneSkillInputRef.current.focus();
					cloneSkillInputRef.current.select();
				}
			} else {
				activateSkillClone()
				//enableDisableSkillCloneButton("skillName")
			}
		}).catch(err => {
			setDataLoading(false);
			console.log("Error in creating skill version: " + err);
			setDataLoading(false);
			setBannerMessageType('error');
			setBannerMessage("Operation failed! Please try again.");
			setShowMessageBanner(true);
		})
	}

	const inputChangeHandler = (e, type) => {
		setEnableCloneSkillButton(false);
		var validataTxt = "";
		
		if (type === "skillName") {
			setCheckDuplicateSkill(false)
			setIsDuplidateSkillName(false)
			let userText = e.target.value
			userText = userText.replace(/ /g,"")
			var charATT = e.target.value.charAt(e.target.value.length - 1)
			if (e.target.value.length === 1 && (!isNaN(e.target.value) || e.target.value === "_")) {
				validataTxt = e.target.value.replace(e.target.value, "")
			} else {
				if (charATT === "_") {
					validataTxt = e.target.value
				} else {
					validataTxt = e.target.value.replace(/[^\w\s]/gi, "")
				}
			}
			validataTxt = validataTxt.replace(/ /g,"")
			setCloneName(validataTxt)
		}
		else if (type === "skillDescription") {
			setCloneDescription(e.target.value)
		}
	}
	
	const validateSkillCloneForm = (key) => {
		if (key == "skillName" || key == "all") {
			if (cloneName === undefined || cloneName.length < 3) {
				setShowValidationErrorMessage(true);
				setDataValidationErrorMessage('Skill name should be of minimum 3 characters.');
				return false;
			}

			if (!isAlphanumeric(cloneName, 'en-US', { "ignore": "_" })) {
				setShowValidationErrorMessage(true);
				setDataValidationErrorMessage('Special characters other than _ is not allowed in Skill name.');
				return false;
			}
		}
		if (key == "skillDescription" || key == "all") {
			if (cloneDescription === undefined || cloneDescription.length < 20) {
				setShowValidationErrorMessage(true);
				setDataValidationErrorMessage('Skill description should be minimum 20 characters.');
				return false;

			}
			if (!validateAlphaNumericString(cloneDescription)) {
				setShowValidationErrorMessage(true);
				setDataValidationErrorMessage('Special characters other than _ @ . " \' & is not allowed in Skill description.');
				return false;
			}
		}
		return true;
	}

    function truncateStrings(str, length) {
        if (length > str.length) {
            str = str + " ".repeat(length - str.length)
            return str
        }

        str = str.substring(0, length)
        return str;
    }

    const handleSkillTagSelection = (data) => {
        console.log(JSON.stringify(data))
        setCategory(data);
        if (data.value == "all") {
            fetchAllSkills();
        } else {
            fetchSkillsByTag(data.value);
        }
    }

    const enableSkillClone = (skillLibObj) => {
		setIsDuplidateSkillName(false);
		setEnableCloneSkillButton(false);
        setSkillLibId(skillLibObj._id);
        setSkillId(skillLibObj.skill_id)
        setCloneName(skillLibObj.name);
        setCloneDescription(skillLibObj.description);
        setCloneApprovalRequired(skillLibObj.approval_required)
        setShowCloneSkill(true);
    }

    const populateSkillCategories = () => {
        return (
            <SearchSelect
                id="skillCategories"
                className="augmentSelect"
                defaultValue={category}
                options={categoryList}
                onValueChange={(data) => {
                    handleSkillTagSelection(data)
                }}
            />
        );
    };

    const handleSkillConsumptionSubmit = () => {
        if (cloneApprovalRequired) {
            submitSkillCopyRequest()
        } else {
            copySkill()
        }
    }

    const submitSkillCopyRequest = () => {
        setShowValidationErrorMessage(false);
		setShowMessageBanner(false);
        setDataValidationErrorMessage('');
        setShowCloneSkill(false);
        setDataLoading(true);
        SkillLibraryService.submitSkillConsumptionRequest({
            "skill_version_id": skillLibId,
            "skill_id": skillId,
            "name": cloneName,
            "description": cloneDescription
        }).then(res => res.json()).then(res => {
            if (res['status'] == 'success') {
                setBannerMessageType('success');
                setBannerMessage("Submitted the request successfully!");
                setShowMessageBanner(true);
                setDataLoading(false);
				for (let skillLibItem of skillLibraryList) {
					if(skillLibItem["_id"] === skillLibId) {
						skillLibItem.clone_pending_approval = true;
					}
				}
			}
            else {
                setBannerMessageType('error');
                setBannerMessage(res['details']);
                setShowMessageBanner(true);
                setDataLoading(false);
            }
        }).catch(err => {
            console.log(err);
            setDataLoading(false);
            setBannerMessageType('error');
            setBannerMessage("Failed to place the request! Please try again.");
            setShowMessageBanner(true);
        })
    }

    const copySkill = () => {
        setShowValidationErrorMessage(false);
        setDataValidationErrorMessage('');
        setShowCloneSkill(false);
        setDataLoading(true);
        console.log("cloneApprovalRequired: " + cloneApprovalRequired);
        SkillLibraryService.cloneSkill(skillId, {
            "skill_version_id": skillLibId,
            "name": cloneName,
            "description": cloneDescription
        }).then(res => res.json()).then(res => {
            if (res['status'] == 'success') {
                let skillResponse = res["result"];
                console.log("Skill created");
                setBannerMessageType('success');
                setBannerMessage("Skill cloned Succesfully!");
                setShowMessageBanner(true);
                setDataLoading(false);
				for (let skillLibItem of skillLibraryList) {
					if(skillLibItem["_id"] === skillLibId) {
						skillLibItem.cloned = true;
					}
				}
            }
            else {
                setBannerMessageType('error');
                setBannerMessage(res['details']);
                setShowMessageBanner(true);
                setDataLoading(false);
            }
        }).catch(err => {
            console.log(err);
            setDataLoading(false);
            setBannerMessageType('error');
            setBannerMessage("Failed to clone the skill! Please try again.");
            setShowMessageBanner(true);
        })
    }

    const fetchAllSkills = () => {
        setDataLoading(true);
        console.log("userId: " + userId)
        SkillLibraryService.getPublishedSkills().then(res => res.json()).then(res => {
            if (res["status"] == 'success') {
                let skillTags = [];
                let responseSkillVersionList = res["skill_list"];
                if (responseSkillVersionList.length > 0) {
                    let skillLibList = []
                    for (let responseSkillVerObj of responseSkillVersionList) {
                        if (responseSkillVerObj.published) {
                            let skillVersionObj = {}
                            skillVersionObj._id = responseSkillVerObj._id
                            skillVersionObj.skill_id = responseSkillVerObj.skill_id
                            skillVersionObj.name = responseSkillVerObj.name
                            skillVersionObj.description = responseSkillVerObj.description
                            skillVersionObj.published = responseSkillVerObj.published
                            skillVersionObj.replica_type = responseSkillVerObj.replica_type
                            skillVersionObj.approval_required = responseSkillVerObj.approval_required
                            skillVersionObj.created_by = responseSkillVerObj.created_by
                            if (responseSkillVerObj.cloned_users && responseSkillVerObj.cloned_users.length > 0) {
                                let clonedCount = responseSkillVerObj.cloned_users.length;
                                skillVersionObj.clonedCount = clonedCount;
                                for (let cloned_user of responseSkillVerObj.cloned_users) {
                                    if (cloned_user === userId) {
                                        skillVersionObj.cloned = true;
                                    }
                                }
                            }

                            if (responseSkillVerObj.clone_requestors_pending_approval && responseSkillVerObj.clone_requestors_pending_approval.length > 0) {
                                for (let clone_requestor of responseSkillVerObj.clone_requestors_pending_approval) {
                                    if (clone_requestor === userId) {
                                        skillVersionObj.clone_pending_approval = true;
                                    }
                                }
                            }
                            
                            if (responseSkillVerObj.tags && responseSkillVerObj.tags.length > 0) {
                                let tags = responseSkillVerObj.tags;
                                for (let tag of tags) {
                                    skillTags.push(tag);
                                }
                            }
                            skillLibList.push(skillVersionObj);
                        }
                    }
                    if (skillTags.length > 0) {
                        let skillTagCount = {}
                        for (let skillTag of skillTags) {
                            if (skillTagCount[skillTag]) {
                                skillTagCount[skillTag] += 1
                            } else {
                                skillTagCount[skillTag] = 1
                            }
                        }

                        const sortedSkillTags = Object.fromEntries(
                            Object.entries(skillTagCount).sort(([, a], [, b]) => b - a)
                        );
                        let dropDownList = []
                        dropDownList.push({ label: `All (${responseSkillVersionList.length})`, value: "all" })
                        for (let tag in sortedSkillTags) {
                            if (sortedSkillTags.hasOwnProperty(tag)) {
                                let value = sortedSkillTags[tag];
                                dropDownList.push({ label: `${tag} (${value})`, value: tag })
                            }
                        }
                        setCategory(dropDownList[0])
                        setCategoryList(dropDownList)
                    }

                    setSkillLibraryList(skillLibList);
                    setDataLoading(false);
                }
            }
            else {
                console.log("Failed to retrieve skill library items");
                setDataLoading(false);
                setBannerMessageType('error');
                setBannerMessage("Failed to retrieve skill library items!");
                setShowMessageBanner(true);
            }
        }).catch(err => {
            console.log(err);
            setDataLoading(false);
            setBannerMessageType('error');
            setBannerMessage("Failed to retrieve skill library items!");
            setShowMessageBanner(true);
        })
    };

    const fetchSkillsByTag = (tag) => {
        setDataLoading(true);
        SkillLibraryService.getPublishedSkillsByTag(tag).then(res => res.json()).then(res => {
            if (res["status"] == 'success') {
                let skillTags = [];
                let responseSkillVersionList = res["skill_list"];
                if (responseSkillVersionList.length > 0) {
                    let skillLibList = []
                    for (let responseSkillVerObj of responseSkillVersionList) {
                        if (responseSkillVerObj.published) {
                            let skillVersionObj = {}
                            skillVersionObj._id = responseSkillVerObj._id
                            skillVersionObj.skill_id = responseSkillVerObj.skill_id
                            skillVersionObj.name = responseSkillVerObj.name
                            skillVersionObj.description = responseSkillVerObj.description
                            skillVersionObj.published = responseSkillVerObj.published
                            skillVersionObj.replica_type = responseSkillVerObj.replica_type
                            skillVersionObj.approval_required = responseSkillVerObj.approval_required
                            skillVersionObj.created_by = responseSkillVerObj.created_by
                            if (responseSkillVerObj.cloned_users && responseSkillVerObj.cloned_users.length > 0) {
                                let clonedCount = responseSkillVerObj.cloned_users.length;
                                skillVersionObj.clonedCount = clonedCount;
                                for (let cloned_user of responseSkillVerObj.cloned_users) {
                                    if (cloned_user === userId) {
                                        skillVersionObj.cloned = true;
                                    }
                                }
                            }
							
							if (responseSkillVerObj.clone_requestors_pending_approval && responseSkillVerObj.clone_requestors_pending_approval.length > 0) {
                                for (let clone_requestor of responseSkillVerObj.clone_requestors_pending_approval) {
                                    if (clone_requestor === userId) {
                                        skillVersionObj.clone_pending_approval = true;
                                    }
                                }
                            }

                            skillLibList.push(skillVersionObj);
                        }
                    }

                    setSkillLibraryList(skillLibList);
                    setDataLoading(false);
                }
            }
            else {
                console.log("Failed to retrieve skill library items");
                setDataLoading(false);
                setBannerMessageType('error');
                setBannerMessage("Failed to retrieve skill library items!");
                setShowMessageBanner(true);
            }
        }).catch(err => {
            console.log(err);
            setDataLoading(false);
            setBannerMessageType('error');
            setBannerMessage("Failed to retrieve skill library items!");
            setShowMessageBanner(true);
        })
    };

    const validateAlphaNumericString = (data) => {
        let sanitisedData = data.replaceAll(/[@.,'"&\s]/g, "");
        return isAlphanumeric(sanitisedData);
    }

    const resizer = document.getElementById('skill_view_panel')

    const [{ run, steps }, setState] = useState({
        run: true,
        steps: [
            {
                content: <h6>Welcome to <b>Skill Library</b>. Here, you view/clone skills.</h6>,
                locale: { skip: <span aria-label="skip">Skip</span> },
                placement: 'center',
                target: '.pageTour1',
            },
            {
                content: <h6>By clicking this dropdown list you can select the list.</h6>,
                floaterProps: {
                    disableAnimation: true,
                },
                spotlightPadding: 20,
                target: '.pageTour2',
            },
            {
                content: <h6>Here you can see the skill details.</h6>,
                floaterProps: {
                    disableAnimation: true,
                },
                spotlightPadding: 10,
                target: '.pageTour3',
            }
        ]
    });

    const handleJoyrideCallback = (data) => {
        const { status, type } = data;
        const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

        if (finishedStatuses.includes(status)) {
            setState({ run: false });
        }
    };
    
    return <div>

        {isDataLoading != true ? <Joyride
            callback={handleJoyrideCallback}
            continuous
            hideCloseButton
            run={run}
            scrollToFirstStep
            showProgress
            showSkipButton
            steps={steps}
            styles={{
                options: {
                    zIndex: 10000,
                },
                buttonNext: {
                    background: '#1e4471',
                    border: '1px solid #1e4471',
                    borderRadius: '5px'
                },
                buttonBack: {
                    color: '#1e4471',
                    border: '1px solid #1e4471',
                    borderRadius: '5px'
                },
                buttonSkip: {
                    color: '#1e4471',
                    border: '1px solid #1e4471',
                    borderRadius: '5px'
                },
            }}
        /> : ""}
        {
            isDataLoading && (<Spinner></Spinner>)
        }

        <div style={{ borderBottom: "1px #ced4da solid" }}>
            <div className="row base-margin-top base-margin-right pageTour1">
                <h5 className="col-7 col-md-8 col-sm-8 col-lg-8 col-xl-8 col-xxl-9 col-xxxl-9 ">
                    Skill Library
                </h5>
                <div
                    className="col-5 col-md-4 col-sm-4 col-lg-4 col-xl-4 col-xxl-3 col-xxxl-3 pageTour2"
                    style={{ marginBottom: "15px" }}
                >
                    {populateSkillCategories()}
                </div>
            </div>
        </div>

        {
            showMessageBanner && <MessageBanner message={bannerMessage} messageType={bannerMessageType} onCloseClicked={() => { setShowMessageBanner(false) }}></MessageBanner>
        }

        <div className='row base-margin' id="wrapper_div" style={{ 'display': 'flex', height: 'calc(100vh - 200px)' }}>

            {skillLibraryList && <div id="skill_view_panel" className={showEditSkill ? 'hide-scroll-bar flex flex-wrap flex-center base-padding' : 'flex flex-wrap flex-center'} style={{ "maxHeight": "75vh", "overflowY": showEditSkill ? "" : "auto", "display": showEditSkill && resizer.parentNode.getBoundingClientRect().width < 1199.98 ? 'none' : 'flex', 'width': showEditSkill && resizer.parentNode.getBoundingClientRect().width < 1199.98 ? '100%' : showEditSkill ? '35%' : '100%', borderRight: !(showEditSkill && resizer.parentNode.getBoundingClientRect().width > 1199.98) ? '' : '1px solid #adb5bd' }}>
                {showEditSkill && <div >
                    <div className="panel panel--bordered panel--raised" style={{ "borderColor": "#64bbe3", "borderWidth": "2px", "backgroundColor": "rgb(100 187 227 / 60%)", "borderRadius": "10px" }}>
                        <div style={{ height: "80px" }}>
                            <div style={{ height: "100%" }}>
                                <button className="btn btn--circle btn--ghost" style={{ visibility: false ? 'visible' : 'hidden', float: "right" }}>
                                    <span className="icon-star"></span>
                                </button>
                                <div className="row">
                                    <div className="col-3 col-sm-3 col-xs-3 col-md-3 col-lg-3 col-xl-3 col-2xl-3">
                                        <img src={process.env.PUBLIC_URL + "/images/boticon.png"} alt="skillLogo" style={{ borderRadius: '50%', width: "100px" }} />
                                    </div>
                                    <div className="col-9 col-sm-9 col-xs-9 col-md-9 col-lg-9 col-xl-9 col-2xl-9" >
                                        <div className="skill-name" style={{ "position": "relative", "top": "25px" }}>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                }

                {
                    !showEditSkill && skillLibraryList.map((skillLib, index) => {
                        return <SkillLibrary key={index} index={index} isMySkill={skillLib.created_by===userId} skillLibrary={skillLib} onSkillCloneClick={(id) => { enableSkillClone(skillLib); }} showEditSkill={showEditSkill} selectedID={selectedSkillId} />
                    })
                }

            </div>}

        </div>

        <Modal size="md" centered show={showCloneSkill} animation={false} >
            <Modal.Header >
                <h5>Clone/Copy Skill</h5>
                <div className=' btn--small btn--icon' onClick={() => { setShowCloneSkill(false) }}>
                    <span className="icon-close icon-size-24"></span>
                </div>
            </Modal.Header>
            <Modal.Body>
                {
                    showValidationErrorMessage && (<div style={{ "textAlign": "center", "color": "red" }}>{dataValidationErrorMessage}</div>)
                }
				
				{
					showMessageBanner && <MessageBanner message={bannerMessage} messageType={bannerMessageType} onCloseClicked={() => { setShowMessageBanner(false) }}></MessageBanner>
				}
				
				{
					cloneApprovalRequired &&
					<div class="alert alert--warning">
						<div class="alert__icon icon-warning-outline"></div>
						<div class="alert__message">Approval required from the Publisher to consume this Skill.</div>
					</div>
				}

                <div className='base-padding'>
                    {

                        <>
                            <div >
                                <div className="">
                                    <div className="form-group base-margin-bottom">
                                        <div className="form-group__text">
                                            <input id="input-type-number" type="text" autoFocus name="skill_version_name" 
												value={cloneName}  
												ref={cloneSkillInputRef} onBlur={handleSkillNameOnBlur}
												onChange={e => { inputChangeHandler(e, "skillName") }} />
                                            <label htmlFor="input-type-number">Name <span className='required'>*</span></label>
                                        </div>
                                    </div>
                                </div>
                                <div className="">
                                    <div className="form-group base-margin-bottom">
                                        <div className="form-group__text">
                                            <textarea id="textarea-state-default" rows={6} name="skill_version_description" value={cloneDescription} 
											onChange={e => { inputChangeHandler(e, "skillDescription") }}> </textarea>
                                            <label htmlFor="input-type-number">Description <span className='required'>*</span></label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>

                    }
                </div>

            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn--secondary"  disabled={!enableCloneSkillButton} onClick={() => { handleSkillConsumptionSubmit() }}>
                    {cloneApprovalRequired ? "Submit Request" : "Submit"}
                </button>
            </Modal.Footer>
        </Modal>

    </div>

}

export default SkillLibraryView;