import Wrapper from "../../../../common-components/wrapper/Wrapper";
import SkillLibraryView from '../skill-library-view-component/SkillLibraryViewComponent';

const SkillLibraryScreen = ()=>{
    return(
        <Wrapper>
            <SkillLibraryView/>
        </Wrapper>
    )
}

export default SkillLibraryScreen;