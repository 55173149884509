const app_url = process.env.REACT_APP_ROOT_URL
const analytics_url = process.env.REACT_APP_ROOT_URL + "/analytics";
const ApprovalService = {
	getRequestsByStatus: (approval_status) => {

        var reqHeaders = new Headers();
        reqHeaders.append("Content-Type", "application/json");
        var requestOptions = {
            method: 'GET',
            headers: reqHeaders
        };

        return fetch(`${app_url}/approval_request/status/${approval_status}`, requestOptions);
    },

    getExternalizationData: () => {

        var reqHeaders = new Headers();
        reqHeaders.append("Content-Type", "application/json");
        var requestOptions = {
            method: 'GET',
            headers: reqHeaders
        };

        return fetch(`${app_url}/externalization`, requestOptions);
    },

    updateExternalizationPutRequest: (data) => {

        var reqHeaders = new Headers();
        reqHeaders.append("Content-Type", "application/json");
        console.log(data);
        var requestOptions = {
            method: 'PUT',
            headers: reqHeaders.append,
            body: JSON.stringify(data)
        };

        return fetch(`${app_url}/externalization/bot/${data.bot_id}/request`, requestOptions);
    },

    updateExternalizationPostRequest: (data) => {

        var reqHeaders = new Headers();
        reqHeaders.append("Content-Type", "application/json");
        console.log(data);
        var requestOptions = {
            method: 'POST',
            headers: reqHeaders.append,
            body: JSON.stringify(data)
        };

        return fetch(`${app_url}/externalization/bot/${data.bot_id}/request`, requestOptions);
    },
	
	updateSkillRequest: (data) => {
        var reqHeaders = new Headers();
        reqHeaders.append("Content-Type", "application/json");
        console.log(data);
        var requestOptions = {
            method: 'PUT',
            headers: reqHeaders,
            body: JSON.stringify(data)
        };

        return fetch(`${app_url}/approval_request`, requestOptions);
    },

    updateBotRequest: (data) => {
        var reqHeaders = new Headers();
        reqHeaders.append("Content-Type", "application/json");
        console.log(data);
        var requestOptions = {
            method: 'POST',
            headers: reqHeaders,
            body: JSON.stringify(data)
        };

        return fetch(`${app_url}/bot/approve`, requestOptions);
    },

    getBotRequestsByStatus: () => {

        var reqHeaders = new Headers();
        reqHeaders.append("Content-Type", "application/json");
        var requestOptions = {
            method: 'GET',
            headers: reqHeaders
        };

        return fetch(`${app_url}/bot/requests`, requestOptions);
    },

    getAdmins: () => {
        var reqHeaders = new Headers();
        reqHeaders.append("Content-Type", "application/json");
        var requestOptions = {
          method: "GET",
          headers: reqHeaders,
        };
    
        const admin_access_url = analytics_url + "/layoutAdmin";
    
        return fetch(`${admin_access_url}`, requestOptions);
      },
};

export default ApprovalService;