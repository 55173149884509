import { useState, useEffect } from "react";
import './CheckUncheck.css';

function CheckUncheck({ listname, checkboxlist, changeFunction = () => { }, changeAllFunction = () => { }, checkedAll = false, children }) {
  const [checklists, setChecklists] = useState(checkboxlist);

  useEffect(() => {
    setChecklists(checkboxlist)
  }, [checkboxlist])

  const toggleCheck = index => {
    changeFunction(index)
  };
  const selectAll = () => {
    changeAllFunction()
  }

  return (
    <div className="responsive-table tblDeleteList table_div">
      <table className="table table--bordered">
        <thead>
          <tr>
            <th className="sortable">{listname} </th>

            <th>
              <span>Select All</span>
              <label className="checkbox">
                <input
                  type="checkbox"
                  onChange={() => selectAll()}
                  checked={checkedAll}
                />
                <span className="checkbox__input"></span>
              </label>
            </th>
          </tr>
        </thead>
        <tbody>
          {checklists &&
            checklists.length > 0 &&
            checklists.map((lists, index) => (
              <tr key={index}>
                <td>{lists.name}</td>

                <td>
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      name={lists.name}
                      onChange={() => toggleCheck(index)}
                      checked={lists.isChecked}
                    />
                    <span className="checkbox__input"></span>
                  </label>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      {children}
    </div>
  );
}

export default CheckUncheck;
