const entity_url = process.env.REACT_APP_ROOT_URL
const EntityService = {
    createEntity: (data, skill) => {
        var reqHeaders = new Headers();
        reqHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'POST',
            headers: reqHeaders,
            body: JSON.stringify(data)
        };

        return fetch(`${entity_url}/skill/${skill}/entity`, requestOptions)
    },
    listEntity: (skill) => {
        var reqHeaders = new Headers();
        reqHeaders.append("Content-Type", "application/json");
        var requestOptions = {
            method: 'GET',
            headers: reqHeaders
        };

        return fetch(`${entity_url}/skill/${skill}/entity`, requestOptions);
    },
    updateEntity: (data, skill) => {
        var reqHeaders = new Headers();
        reqHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'PUT',
            headers: reqHeaders,
            body: JSON.stringify(data)
        };

        return fetch(`${entity_url}/skill/${skill}/entity`, requestOptions);
    },
    deleteEntity: (data, skill) => {
        var reqHeaders = new Headers();
        reqHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'DELETE',
            headers: reqHeaders
        };

        return fetch(`${entity_url}/skill/${skill}/entity/${data["id"]}`, requestOptions);
    },
    deleteEntities: (data, skill) => {
        var reqHeaders = new Headers();
        reqHeaders.append("Content-Type", "application/json");
        var requestOptions = {
            method: 'DELETE',
            headers: reqHeaders,
            body: JSON.stringify(data)
        };

        return fetch(`${entity_url}/skill/${skill}/entity`, requestOptions);
    }
}

export default EntityService;