import { useState, useRef, useEffect } from 'react'
import { propTypes } from 'react-bootstrap/esm/Image'
import APIForm from '../../../../../../custom-components/api-form/APIForm'
import InputSelect from '../../../../../../custom-components/CreatableSelect/CreatableSelect'
import MultiSelect from '../../../../../../custom-components/multi-select/MultiSelect'
import DialogService from '../../../../dialog-service/DialogService'
import CheckUncheck from './Checklist'
import Spinner from '../../../../../../custom-components/spinner/Spinner'
import SearchSelect from '../../../../../../custom-components/search-select/SearchSelect';
import MessageBanner from '../../../../../../custom-components/MessageBanner/MessageBanner';

let lastEditMode = 2
let initialId = 5
const getId = () => initialId++

export default function APIFormSecret({ node, skill, changeValue = () => { } }) {
    const [newAPIInfo, setNewAPIInfo] = useState({ secret: '', method: '', URL: '', headers: [], payload: '', oauth: '', client_ID: '', client_Secret: '', o_Auth_URL: '', grant_Type: '', internal: false, external: false })
    const [oldAPIInfo, setOldAPIInfo] = useState({})
    const [showEditAPI, setShowEditAPI] = useState(1)
    const [showEdit, setShowEdit] = useState(false)
    const [checkedAll, setCheckedAll] = useState(false)
    const [showValidationMessage, setShowValidationMessage] = useState(false);
    const [dataValidationMessage, setDataValidationMessage] = useState('');
    const [validationMessageType, setValidationMessageType] = useState('success')
    const [showDelete, setShowDelete] = useState(false)
    const [isDataLoading, setDataLoading] = useState(false)
    const [isAPIClickedForExpansion, setIsAPIClickedForExpansion] = useState(false);
    useEffect(() => {
        getAPIList();
    }, [])
    useEffect(()=>{
        setIsAPIClickedForExpansion(false);
    },[showEditAPI])

    // useEffect(() => {
    //     let apivalue = getAPIFromValue(node.data.api)
    //     console.log("apivalue", apivalue)
    //     if (apivalue) {
    //         getAPI(apivalue.value, apivalue.label)
    //     }
    // }, node.data.api)

    const [apis, setApis] = useState([]);

    const getAPIList = () => {
        var re = new RegExp("^(api_[a-zA-Z]+[_a-zA-Z0-9]*)$");
        DialogService.getAllSecret(skill).then(res => res.json()).then(res => {
            if (res.status == 'success') {
                let secret = res.secret;
                let api_secret = []
                for (let i of secret) {
                    if (re.test(i.label)) {
                        api_secret.push({
                            id: api_secret.length,
                            name: i.label,
                            label: i.label,
                            value: i.value,
                            editable: i.editable,
                            isChecked: false,
                        })
                    }
                }
                setApis(api_secret);
            }
            else {
                setValidationMessageType('warning')
                setDataValidationMessage(res.details)
                setShowValidationMessage(true)
            }
        }).catch(err => {

            setValidationMessageType('warning')
            setDataValidationMessage('Something went wrong while retrieving the apis. Please try again later.')
            setShowValidationMessage(true)
        })
    }

    const getAPI = (value, label) => {
        setDataLoading(true);
        setIsAPIClickedForExpansion(false);
        DialogService.getSecret(skill, value).then(res => res.json()).then(res => {
            if (res.status == 'success') {
                let obj = JSON.parse(res.secret)
                obj.secret = label;
                //rename the payload field as it is been used in backend service
                obj.payload = JSON.stringify(obj.request_body, undefined, 4);
                delete obj.request_body;
                setOldAPIInfo(obj)
            }
            setDataLoading(false);
            setIsAPIClickedForExpansion(true);
        }).catch(err => {
            setValidationMessageType('warning')
            setDataValidationMessage('Something went wrong while retrieving the apis. Please try again later.')
            setShowValidationMessage(true)
            setDataLoading(false);
        })
    }

    const objectToArray = obj => {
        let arr = []
        for (let key in obj)
            arr.push({ key, value: obj[key] })

        return arr
    }

    const arrayToObject = arr => {
        let obj = {}
        for (let element of arr)
            obj[element.key] = element.value
        return obj
    }

    const editAPIChange = (key, value) => {
        let realKey = key
        switch (key) {
            case 'headers':
                value = arrayToObject(value)
                break
            case 'params':
                realKey = 'request_params'
                value = arrayToObject(value)
                break
            case 'URL':
                realKey = 'url'
                break
            case 'client_ID':
                realKey = 'client_id'
                break
            case 'client_Secret':
                realKey = 'client_secret'
                break
            case 'o_Auth_URL':
                realKey = 'oauth_url'
                break
            case 'grant_Type':
                realKey = 'grant_type'
                break
        }
        oldAPIInfo[realKey] = value
    }

    const deleteFunction = () => {
        let deletedArray = []
        for (let api of apis)
            if (api.isChecked)
                deletedArray.push(api)

        deleteAPIData(deletedArray)
        setShowDelete(false)
        setCheckedAll(false)

    }

    const changeFunction = index => {
        let dbsCopy = [...apis]
        const temp = !dbsCopy[index].isChecked
        dbsCopy[index].isChecked = temp
        setApis(dbsCopy)
        for (let element of dbsCopy)
            if (element.isChecked === false) {
                setCheckedAll(false)
                return
            }
        setCheckedAll(true)
    }

    const changeAllFunction = () => {
        let dbsCopy = [...apis]
        let temp = !checkedAll
        for (let element of dbsCopy)
            element.isChecked = temp
        setApis(dbsCopy)
        setCheckedAll(temp)
    }

    const addAPIData = (skill) => {
        setDataLoading(true)
        setShowValidationMessage(false)
        let processed_headers = {}
        let secret = {}
        let request_params = {}
        for (let i in newAPIInfo.headers) {
            let temp = newAPIInfo.headers[i]
            processed_headers[temp["key"]] = temp["value"]
        }
        // if (newAPIInfo.headers.length == 1) {
        //     let temp = newAPIInfo.headers[0]
        //     processed_headers = "{'" + temp['key'] + "':'" + temp['value'] + "'}"
        // }
        // else {
        //     for (let i in newAPIInfo.headers) {
        //         if (i == newAPIInfo.headers.length - 1) {
        //             let temp = newAPIInfo.headers[i][0]
        //             processed_headers = processed_headers + ",'" + temp['key'] + "':'" + temp['value'] + "'}"
        //         }
        //         else if (i == 0) {
        //             let temp = newAPIInfo.headers[i]
        //             processed_headers = "{'" + temp['key'] + "':'" + temp['value'] + "'"
        //         }
        //         else {
        //             let temp = newAPIInfo.headers[i]
        //             processed_headers = processed_headers + ",'" + temp['key'] + "':'" + temp['value'] + "'"
        //         }
        //     }
        //}
        secret["headers"] = processed_headers
        secret["auth_type"] = newAPIInfo.auth_type == undefined ? '' : newAPIInfo.auth_type
        if (newAPIInfo.auth_type != '' || newAPIInfo.auth_type != undefined) {
            secret["grant_type"] = newAPIInfo.grant_type
            secret["client_id"] = newAPIInfo.client_id
            secret["client_secret"] = newAPIInfo.client_secret
            secret["oauth_url"] = newAPIInfo.oauth_url
        }
        secret["type"] = newAPIInfo.internal ? "internal" : "external"
        secret["url"] = newAPIInfo.URL
        secret["method"] = newAPIInfo.method
        // let extra_values = newAPIInfo.auth_type == '' || newAPIInfo.auth_type == undefined ? "" : "','grant_type':'" + newAPIInfo.grant_type + "','client_id':'" + newAPIInfo.client_id + "','client_secret':'" + newAPIInfo.client_secret + "','oauth_url':'" + newAPIInfo.oauth_url + "',"
        // let auth_type_value = newAPIInfo.auth_type == undefined ? '' : newAPIInfo.auth_type
        // let parameters = ""
        // let internal_external_values = newAPIInfo.internal ? "'type':'internal'" : "'type':'external'"
        // let header_values = processed_headers != "" ? processed_headers : "{}"
        for (let i in newAPIInfo.params) {
            let temp = newAPIInfo.params[i]
            request_params[temp["key"]] = temp["value"]
        }
        secret["request_params"] = request_params

        // for (let i in newAPIInfo.payload) {
        //     let temp = JSON.parse(newAPIInfo.payload[i])
        //     for (let i of temp) {
        //         console.log(i)
        //         // request_payload[] = temp[1]
        //     }
        // }
        secret["request_body"] = newAPIInfo['payload']
        // if (newAPIInfo.params.length == 1) {
        //     let temp = newAPIInfo.params[0]
        //     parameters = "'request_params':{'" + temp['key'] + "':'" + temp['value'] + "'},"
        // }
        // else {
        //     for (let i in newAPIInfo.params) {
        //         console.log(i)
        //         let temp = newAPIInfo.params[i]
        //         if (i == newAPIInfo.params.length - 1) {
        //             parameters = parameters + ",'" + temp['key'] + "':'" + temp['value'] + "'},"
        //         }
        //         else if (i == 0) {
        //             parameters = "'request_params':{'" + temp['key'] + "':'" + temp['value'] + "'"
        //         }
        //         else {
        //             parameters = parameters + ",'" + temp['key'] + "':'" + temp['value'] + "'"
        //         }
        //     }
        // }

        let payload = {
            "secret_name": "api_" + newAPIInfo.secret,
            // "secret_value": "{'url':'" + newAPIInfo.URL + "','headers':" + header_values + ",'method':'" + newAPIInfo.method + "'," + parameters + "'auth_type':'" + auth_type_value + "'" + extra_values + "," + internal_external_values + "}"
            "secret_value": JSON.stringify(secret)
        }
        // console.log("payload", payload)
        // console.log(skill)
        DialogService.createDB(skill, payload).then(res => res.json()).then(res => {
            if (res.status == 'success') {
                setValidationMessageType('success')
                setDataValidationMessage("Successfully created API!")
                setShowValidationMessage(true)
                setTimeout(() => {
                    setShowValidationMessage(false)
                }, 15000)
                setDataLoading(false)
                getAPIList(skill)
                clearDataFromNode(newAPIInfo.secret);
                setShowEditAPI(1)
            }
            else {
                setValidationMessageType('warning')
                setDataValidationMessage(res.details)
                setShowValidationMessage(true)
                setTimeout(() => {
                    setShowValidationMessage(false)
                }, 15000)
                setDataLoading(false)
            }
        }).catch(err => {
            setValidationMessageType('warning')
            setDataValidationMessage('Something went wrong. Please try again later.')
            setShowValidationMessage(true)
            setTimeout(() => {
                setShowValidationMessage(false)
            }, 15000)
            setDataLoading(false)
        })
    }

    const updateAPIData = (skill) => {
        setDataLoading(true)
        let secretName = oldAPIInfo.secret;
        let secretPayload = {...oldAPIInfo};
        delete secretPayload.secret
        if (secretPayload.payload) {
            secretPayload.request_body = JSON.parse(secretPayload.payload);
            delete secretPayload.payload;
        }
        else {
            secretPayload.request_body = "{}";
        }
        if (secretPayload.payload) {
            delete secretPayload.payload
        }

        let payload = {
            "secret_name": `${secretName}__${skill}`,
            "secret_value": JSON.stringify(secretPayload)
        }
        DialogService.updateSecret(skill, payload).then(res => res.json()).then(res => {
            if (res.status == 'success') {
                setValidationMessageType('success')
                setDataValidationMessage('Successfully updated API')
                setShowValidationMessage(true)
                setTimeout(() => setShowValidationMessage(false), 15000)
                setDataLoading(false)
            } else {
                setValidationMessageType('warning')
                setDataValidationMessage(res.details)
                setShowValidationMessage(true)
                setTimeout(() => setShowValidationMessage(false), 15000)
                setDataLoading(false)
            }
        }).catch(err => {
            setValidationMessageType('warning')
            setDataValidationMessage('Something went wrong. Please try again later.')
            setShowValidationMessage(true)
            setTimeout(() => setShowValidationMessage(false), 15000)
            setDataLoading(false)
        })
    }

    const clearDataFromNode = (secret) => {
        // let skill = newAPIInfo.skill;
        // let type = newAPIInfo.type;
        // let rule = newAPIInfo.rule;
        // let secret_value = secret;
        // let name = newAPIInfo.name;
        // let description = newAPIInfo.description;

        // newAPIInfo = {
        //     secret: secret_value,
        //     skill,
        //     type,
        //     rule,
        //     name,
        //     description
        // }
        // delete newAPIInfo.headers;
        // delete newAPIInfo.oauth_url;
        // delete newAPIInfo.params;
        // delete newAPIInfo.payload;
        setNewAPIInfo({ secret: '', method: '', URL: '', headers: [], payload: '', oauth: '', client_ID: '', client_Secret: '', o_Auth_URL: '', grant_Type: '', internal: false, external: false })
        // newAPIInfo.secret = secret;

    }

    const deleteAPIData = (data) => {
        setDataLoading(true)
        let secretList = []
        for (let i of data) {
            secretList.push(i['value'])
        }

        let payload = {
            "secret_names": secretList
        }
        DialogService.deleteSecrets(skill, payload).then(res => res.json()).then(res => {
            if (res.status == 'success') {
                setValidationMessageType('success')
                setDataValidationMessage(res.details || "API(s) deleted successfully")
                setShowValidationMessage(true)
                setTimeout(() => {
                    setShowValidationMessage(false)
                }, 15000)
                getAPIList()
                setDataLoading(false)
                setShowEditAPI(1)
            }
            else {
                setValidationMessageType('warning')
                setDataValidationMessage(res.details || "Failed to delete API(s)")
                setShowValidationMessage(true)
                setTimeout(() => {
                    setShowValidationMessage(false)
                }, 15000)
                setDataLoading(false)
            }
        }).catch(err => {
            setValidationMessageType('warning')
            setDataValidationMessage('Something went wrong. Please try again later.')
            setShowValidationMessage(true)
            setTimeout(() => {
                setShowValidationMessage(false)
            }, 15000)
            setDataLoading(false)
        })
    }

    const checkEmptyData = () => {
        // if (newAPIInfo.secret == '' || newAPIInfo.secret == undefined || newAPIInfo.URL == '' || newAPIInfo.method == undefined || (newAPIInfo.auth_type != '' && (newAPIInfo.grant_type == '' || newAPIInfo.client_id == '' || newAPIInfo.client_secret == '' || newAPIInfo.oauth_url == '')))
        if (newAPIInfo.secret == '' || newAPIInfo.secret == undefined || newAPIInfo.URL == '' || newAPIInfo.URL == undefined || newAPIInfo.method == undefined || newAPIInfo.method == '' || (newAPIInfo.auth_type != '' && (newAPIInfo.grant_type == '' || newAPIInfo.client_id == '' || newAPIInfo.client_secret == '' || newAPIInfo.oauth_url == '')) || (newAPIInfo.internal == false && newAPIInfo.external == false))
            return true
        return false
    }

    const setRadioButton = (event, type) => {
        if (type == 'internal') {
            let temp = document.getElementById('internal')
            let temp2 = document.getElementById('external')
            temp.checked = Boolean(event.target.value)
            temp2.checked = !Boolean(event.target.value)
            setNewAPIInfo({ ...newAPIInfo, internal: Boolean(event.target.value), external: !Boolean(event.target.value) })
        }
        else {
            let temp = document.getElementById('internal')
            let temp2 = document.getElementById('external')
            temp2.checked = Boolean(event.target.value)
            temp.checked = !Boolean(event.target.value)
            setNewAPIInfo({ ...newAPIInfo, internal: !Boolean(event.target.value), external: Boolean(event.target.value) })

        }


    }
    const getAPIFromValue = (value) => {
        for (let i of apis) {
            if (i.value == value) {
                // getAPI(i.value, i.label)
                return i
            }
        }
    }

    return (<div>
        {
            isDataLoading && (<Spinner></Spinner>)
        }
        <div className="row">
            {showValidationMessage && <div className='col-12'><MessageBanner message={dataValidationMessage} messageType={validationMessageType} onCloseClicked={() => { setShowValidationMessage(false) }}></MessageBanner></div>}
            <div className='row addDeleteDbToggle base-margin'>
                <div className="col-lg-12 col-md-12 col-12">
                    <label>Add / Delete / Edit API</label>
                    <label className="switch base-margin-left">
                        <input type="checkbox" checked={showEditAPI !== 1} onChange={() => { if (showEditAPI == 1) { setShowEditAPI(lastEditMode); } else { setShowEditAPI(1) } }} />
                        <span className="switch__input"></span>
                    </label>
                </div>
            </div>
            {showEditAPI !== 1 && <div className='addDeleteDbContner base-margin'>
                <div className="">
                    <div className='addDeleteDb'>
                        <ul id="bordered" className="tabs tabs--bordered">
                            <li id="bordered-1" class={showEditAPI == 2 ? "tab active" : "tab"}>
                                <a tabindex="0" onClick={() => { setShowEditAPI(2); lastEditMode = 2 }}>Add New API</a>
                            </li>
                            <li id="bordered-2" class={showEditAPI == 3 ? "tab active" : "tab"}>
                                <a tabindex="1" onClick={() => { setShowEditAPI(3); lastEditMode = 3 }}>Delete API</a>
                            </li>
                            <li id='bordered-3' class={showEditAPI == 4 ? "tab active" : 'tab'}>
                                <a tabindex='2' onClick={() => { setShowEditAPI(4); lastEditMode = 4 }}>Edit API</a>
                            </li>
                        </ul>
                        <div id="bordered-content" className="tab-content">
                            <div id="bordered-1-content" className="tab-pane active">
                                {showEditAPI == 2 && <div className='addDb'>
                                    {
                                        newAPIInfo && <div className="separatorBorder">
                                        <div className='row' style={{ marginLeft: '8px' }}>

                                            <div className="form-group col-9">
                                                <div className="form-group__text">
                                                    <input id="input-layout-stacked-1" placeholder='Enter Connection Name' type="text" value={newAPIInfo.secret} onChange={event => { setNewAPIInfo({ ...newAPIInfo, secret: event.target.value }) }} />
                                                    <label htmlFor="input-layout-stacked-1">Connection Name<span className='mandatoryIcon'>*</span></label>
                                                </div>
                                            </div>
                                            <div className="col-3" style={{ marginTop: '8px' }}>
                                                <div class="form-group">
                                                    <label className=" radio " >
                                                        <input type="radio" id="internal" value={newAPIInfo.internal} onClick={event => { setRadioButton(event, 'internal') }} />
                                                        <span class="radio__input" ></span>
                                                        <span class="radio__label">Internal</span>
                                                    </label>
                                                </div>
                                                <div class="form-group">
                                                    <label className=" radio " >
                                                        <input type="radio" id="external" value={newAPIInfo.external} onClick={event => { setRadioButton(event, 'external') }} />
                                                        <span class="radio__input" ></span>
                                                        <span class="radio__label">External</span>
                                                    </label>
                                                </div>
                                            </div>

                                        </div>

                                        <APIForm changeValue={(key, value) => { newAPIInfo[key] = value; setNewAPIInfo({ ...newAPIInfo, method: newAPIInfo.method, URL: newAPIInfo.URL, headers: newAPIInfo.headers, payload: newAPIInfo.payload, params: newAPIInfo.params }) }} method={newAPIInfo.method} URL={newAPIInfo.URL} headers={newAPIInfo.headers} params={newAPIInfo.params} payload={newAPIInfo.payload} oAuth={newAPIInfo.oAuth} client_ID={newAPIInfo.clientID} client_Secret={newAPIInfo.clientSecret} o_Auth_URL={newAPIInfo.oAuthURL} grant_Type={newAPIInfo.grantType} />
                                        <br />
                                        <div className="col-6 col-lg-6 base-margin-top">
                                            <button className="btn btn--secondary btn--small" onClick={() => { setApis(arr => [...arr, newAPIInfo]); addAPIData(skill); setNewAPIInfo({ secret: '', method: '', URL: '', headers: [], payload: '', oauth: '', client_ID: '', client_Secret: '', o_Auth_URL: '', grant_Type: '', internal: false, external: false }); setShowEditAPI(1) }} disabled={checkEmptyData()}>Add API</button>
                                        </div>
                                    </div>
                                    }

                                </div>}
                            </div>
                            <div id="bordered-2-content" className="tab-pane active">
                                {showDelete && <div className="base-margin-left base-margin-right col-11">
                                    <div class="alert alert--warning">
                                        <div class="alert__message">Are you sure you want to delete the selected DB?</div>
                                        <button className='btn btn--small btn--secondary base-margin-right base-margin-left' onClick={() => { deleteFunction() }}>Yes</button><button className='btn btn--small btn--secondary' onClick={() => { setShowDelete(false) }}>No</button>
                                    </div>
                                </div>}
                                {showEditAPI == 3 && <CheckUncheck listname="API list" checkboxlist={apis} changeFunction={changeFunction} changeAllFunction={changeAllFunction} checkedAll={checkedAll} >
                                    <div className="deletebtn">
                                        <button className="btn btn--small btn--secondary" onClick={() => { deleteFunction(); }}>Delete</button>
                                    </div>
                                </CheckUncheck>}
                            </div>
                            <div id='bordered-3-content' className='tab-pane active'>
                                {showEditAPI == 4 && <div>
                                    <div class="responsive-table tblDeleteList table_div" style={{"padding":"15px 8px"}}>
                                        <table class="table table table--bordered base-margin" style={{ border: '1px solid #ced4da', width: '90%' }}>
                                            <thead  >
                                                <tr>
                                                    <th>Select the API to be edited</th></tr>
                                            </thead>
                                            <tbody>
                                                {apis.map((api, index) => <tr><td ><a onClick={() => { getAPI(api.value, api.label) }}>{api.label}</a></td></tr>)}
                                            </tbody>
                                        </table>
                                    </div>
                                    { isAPIClickedForExpansion && <hr></hr>}
                                    {
                                        isAPIClickedForExpansion && <div className='addDb'>
                                        <div className="separatorBorder">
                                            <div className='row' style={{ marginLeft: '8px' }}>
                                                <div className="form-group col-9" >
                                                    <div className="form-group__text">
                                                        <input type="text" value={oldAPIInfo.secret} disabled />
                                                        <label >Connection Name</label>
                                                    </div>
                                                </div>
                                                <div style={{ marginTop: '8px' }} className="col-3">
                                                    <div class="form-group">
                                                        <label className="radio">
                                                            <input type="radio" id="internal" checked={oldAPIInfo.type === 'internal'} onChange={event => { setOldAPIInfo({ ...oldAPIInfo, type: 'internal' }) }} />
                                                            <span class="radio__input" ></span>
                                                            <span class="radio__label">Internal</span>
                                                        </label>
                                                    </div>
                                                    <div class="form-group">
                                                        <label className=" radio " >
                                                            <input type="radio" id="external" checked={oldAPIInfo.type === 'external'} onChange={event => { setOldAPIInfo({ ...oldAPIInfo, type: 'external' }) }} />
                                                            <span class="radio__input" ></span>
                                                            <span class="radio__label">External</span>
                                                        </label>
                                                    </div>
                                                </div>

                                            </div>

                                            <APIForm changeValue={(key, value) => { editAPIChange(key, value) }} method={oldAPIInfo.method} URL={oldAPIInfo.url} headers={objectToArray(oldAPIInfo.headers)} params={objectToArray(oldAPIInfo.request_params)} payload={oldAPIInfo.payload} oAuth={oldAPIInfo.oAuth} client_ID={oldAPIInfo.client_id} client_Secret={oldAPIInfo.client_secret} o_Auth_URL={oldAPIInfo.oauth_url} grant_Type={oldAPIInfo.grant_type} />
                                            <br />
                                            <div className="col-6 col-lg-6 base-margin-top">
                                                <button className="btn btn--secondary btn--small" onClick={() => { setApis(arr => [...arr, newAPIInfo]); updateAPIData(skill); setNewAPIInfo({ secret: '', method: '', URL: '', headers: [], payload: '', oauth: '', client_ID: '', client_Secret: '', o_Auth_URL: '', grant_Type: '', internal: false, external: false }); setShowEditAPI(1) }}>Edit API</button>
                                            </div>
                                        </div>
                                    </div>
                                    }
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
            <div className="row contnerSectionbox selectDb base-margin">
                <label className='lbPosition'>Select Connection<span className='mandatoryIcon'>*</span></label>
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className=""  >
                        {/* <select className='gblbalSelectbox' value={apiName} onChange={event => { setApiName(event.target.value); changeValue('api', event.target.value) }} >
                            {apis.length > 0 && apis.map(api => <option key={api.id}>{api.name}</option>)}
                            <option value='' disabled hidden>Choose an API</option>
                        </select> */}
                        <SearchSelect
                            defaultValue={getAPIFromValue(node.data.api)}
                            options={[...apis, {label: 'Create Connection', value: 'newConn'}]}
                            onValueChange={data => { if (data.value !== 'newConn') {node.data.api = data.value; getAPI(data.value, data.label); } else { setShowEditAPI(2) }}}
                        />
                    </div>
                </div>
            </div>
        </div>
    </div >)
}
