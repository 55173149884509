import React, { useEffect } from "react";

import './IntegrationBlockComponent.css';
import '../../../../common-css/Modal.css';
import FileParser from '../../../../utils/FileParser';
import { useState } from "react";
import CodeViewCopier from "../../../../custom-components/code-view-copier/CodeViewCopier";
import Modal from 'react-bootstrap/Modal';
import DataGridComponent from "../../../../custom-components/data-grid/DataGrid";
import FileDropZone from "../../../../custom-components/file-drop-zone/FileDropZone";
import IntegrationService from "../../integration-service/IntegrationService";
import Spinner from "../../../../custom-components/spinner/Spinner";
import MessageBanner from '../../../../custom-components/MessageBanner/MessageBanner';

export default function IntegrationBlock({ platform, index, showEditIntegration, bot, display_message, getIntegrations }) {
    const [isDataLoading, setDataLoading] = useState(false);
    const [showIntegration, setShowIntegration] = useState(false)
    const [showExternalizeIntegration, setShowExternalizeIntegration] = useState(false)
    const [isFileProcessingInProgress, setFileProcessingFlag] = useState(false);
    const [submitBtnEnable, setSubmitBtnEnable] = useState(false)
    const [integrations, setIntegrations] = useState(platform)
    const [integration, setIntegration] = useState('webex')
    const [dataValidationErrorMessage, setDataValidationErrorMessage] = useState('');
    const [showValidationErrorMessage, setShowValidationErrorMessage] = useState(false);
    const [integrationDetails, setIntegrationDetails] = useState({ UserName: "", AccessToken: "", HelpInformation: "", DefaultResponse: "" });
    const [showActivateIntegration, setShowActivateIntegration] = useState(false);
    const [showDeleteIntegration, setShowDeleteIntegration] = useState(false);
    const [integrationFailureMessage, setIntegrationFailureMessage] = useState('');
    const [showIntegrationFailureMessage, setShowIntegrationFailureMessage] = useState(false);

    useEffect(() => {
        setIntegrations(platform);
    }, [platform])

    const truncateStrings = (str, length) => {
        let len_str = len(str)
        if (length > len(str)) {
            str = str + " ".repeat(length - len_str)

        }
        return str
    }
    const len = (str) => {
        return str.length
    }
    let fileData = [];
    let rows = [
        { id: 1, email: 'vidyavenkappa@gmail.com', date: '2023-03-13', messages: '20' },
        { id: 2, email: 'manthan@gmail.com', date: '2023-11-02', messages: '10' },
        { id: 3, email: 'azam@gmail.com', date: '2023-02-03', messages: '2' },
        { id: 4, email: 'vinu@gmail.com', date: '2021-01-23', messages: '13' },
        { id: 5, email: 'deependra@gmail.com', date: '2013-02-23', messages: '77' }
    ];
    let columns = [
        { field: 'email', headerName: 'External Emails', flex: 0.4 },
        {
            field: 'date',
            headerName: 'Last Active Date',
            flex: 0.2,
            editable: true,
        },
        {
            field: 'messages',
            headerName: 'Total Messages',
            flex: 0.15,
            editable: true,
        }
    ];
    const openCreateIntegrationModal = (int_platform) => {
        setShowIntegrationFailureMessage(false);
        setIntegrationFailureMessage('');
        switch (int_platform) {
            case 'webex':
                setIntegration('webex')
                setShowIntegration(true)
                break;
            case 'widget':
                setIntegration('widget')
                setShowIntegration(true)
                break;
            case 'widget_code':
                setIntegration('widget_code')
                setShowIntegration(true)
                break;
            case 'widget_chathandler':
                setIntegration('widget_chathandler')
                setShowIntegration(true)
                break;
            default:
                console.log('not defined')
        }
    }

    useEffect(() => {
        // update input filed with values when modal is opned
        if (showIntegration && integrations['webex']) {
            setIntegrationDetails({ UserName: integrations['webex']["username"], AccessToken: integrations['webex']["access_token"], HelpInformation: integrations['webex']["help_info"], DefaultResponse: integrations['webex']["default_response"] });

            document.getElementById("integration-bot-username").value = integrations['webex']["username"];
            document.getElementById("integration-bot-accesstoken").value = integrations['webex']["access_token"];
            document.getElementById("integration-bot-helptext").value = integrations['webex']["help_info"];
            document.getElementById("integration-bot-defaultresponse").value = integrations['webex']["default_response"];

            setSubmitBtnEnable(true);
        }
    }, [showIntegration])

    const showIntegrationWindow = (int_platform) => {
        setShowIntegrationFailureMessage(false);
        setIntegrationFailureMessage('');
        switch (int_platform) {
            case 'webex':
                setIntegration('webex')
                setShowIntegration(true)
                break;
            case 'widget':
                setIntegration('widget')
                setShowIntegration(true)
                break;
            case 'widget_code':
                setIntegration('widget_code')
                setShowIntegration(true)
                break;
            case 'widget_chathandler':
                setIntegration('widget_chathandler')
                setShowIntegration(true)
                break;
            default:
                console.log('not defined')
        }
    }
    const createExternalization = (int_platform) => {
        setShowIntegrationFailureMessage(false);
        setIntegrationFailureMessage('');
        switch (int_platform) {
            case 'webex':
                setIntegration('webex')
                setShowExternalizeIntegration(true)
                break;
            case 'widget':
                setIntegration('widget')
                setShowExternalizeIntegration(true)
                break;
            case 'widget_code':
                setIntegration('widget_code')
                setShowExternalizeIntegration(true)
                break;
            case 'widget_chathandler':
                setIntegration('widget_chathandler')
                setShowExternalizeIntegration(true)
                break;
            default:
                console.log('not defined')
        }

    }
    const togglePlatformActivation = (int_platform) => {
        console.log("inside this 3")
        setShowIntegrationFailureMessage(false);
        setIntegrationFailureMessage('');

        switch (int_platform) {
            case 'webex':
                setIntegration('webex')
                console.log(integrations)
                if (integrations && integrations[int_platform] && integrations[int_platform]['activate']) {
                    console.log("inside this 4")
                    // call deactivate api
                    // setShowActivateIntegration(true);
                    setDataLoading(true);
                    let int_obj = {
                        active_users: 0,
                        externalise: false,
                        activate: false,
                        externalised_users: [],
                        username: integrations[int_platform]["access_token"],
                        access_token: integrations[int_platform]["access_token"],
                        help_info: integrations[int_platform]["help_info"],
                        default_response: integrations[int_platform]["default_response"],
                        type: int_platform
                    }
                    console.log(int_obj)
                    display_message('error', '', false);
                    IntegrationService.updateIntegration(bot["value"], int_obj).then(res => res.json()).then(res => {
                        if (res.status == 'success') {
                            setDataLoading(false);
                            display_message('success', 'Successfully Deactivated the Integration', true);

                        }
                        else {
                            display_message('error', res['details'], true);
                        }

                    }).catch(err => {
                        setDataLoading(false);
                        console.log(err);
                        display_message('error', 'Something went wrong while deactivating the integrations. Please try again later!', true);
                    })
                }
                else {
                    // call activate api
                    setShowActivateIntegration(false);
                }
                break;
            case 'widget':
                setIntegration('widget');
                if (integrations && integrations[int_platform] && !integrations[int_platform]['activate']) {
                    // call deactivate api
                    setShowActivateIntegration(true);
                    setDataLoading(true);
                    IntegrationService.deactivateIntegration(bot["value"], int_platform).then(res => res.json()).then(res => {
                        setDataLoading(false);
                    }).catch(err => {
                        setDataLoading(false);
                        console.log(err);
                    })
                }
                else {
                    // call activate api
                    setShowActivateIntegration(false);
                }
                break;
            case 'widget_code':
                setIntegration('widget_code');
                if (integrations && integrations[int_platform] && !integrations[int_platform]['activate']) {
                    // call deactivate api
                    setShowActivateIntegration(true);
                    setDataLoading(true);
                    IntegrationService.deactivateIntegration(bot["value"], int_platform).then(res => res.json()).then(res => {
                        setDataLoading(false);
                    }).catch(err => {
                        setDataLoading(false);
                        console.log(err);
                    })
                }
                else {
                    // call activate api
                    setShowActivateIntegration(false);
                }
                break;
            case 'widget_chathandler':
                setIntegration('widget_chathandler');
                if (integrations && integrations[int_platform] && !integrations[int_platform]['activate']) {
                    // call deactivate api
                    setShowActivateIntegration(true);
                    setDataLoading(true);
                    IntegrationService.deactivateIntegration(bot["value"], int_platform).then(res => res.json()).then(res => {
                        setDataLoading(false);
                    }).catch(err => {
                        setDataLoading(false);
                        console.log(err);
                    })
                }
                else {
                    // call activate api
                    setShowActivateIntegration(false);
                }
                break;
            default:
                console.log('not defined')
        }

    }


    const inputHandlerChange = (event, type) => {
        setShowValidationErrorMessage(false);
        // // let integrationForm = integrationDetails;
        // // if (type === "UserName")
        // //     integrationForm = { ...integrationDetails, "UserName" : event.target.value }
        // // else if (type === "AccessToken")
        // //     integrationForm = { ...integrationDetails, "AccessToken" : event.target.value }
        // // else if (type === "HelpInformation")
        // //     integrationForm = { ...integrationDetails, "HelpInformation" : event.target.value }
        // // else
        // //     integrationForm = { ...integrationDetails, "DefaultResponse" : event.target.value }
        // // setIntegrationDetails(integrationForm)



        // const { name, value } = event.target;
        let newData = integrationDetails
        newData[type] = event.target.value;
        setIntegrationDetails(newData);


        let empty = hasEmpty(newData)
        let valid = validateIntegrationForm(type, newData)
        const result = empty && valid
        setSubmitBtnEnable(result)
    }
    const validateIntegrationForm = (key, dataIntegration) => {
        if (key == "UserName" || key == "all") {
            if (dataIntegration.UserName === undefined || dataIntegration.UserName.length < 2) {
                setShowValidationErrorMessage(true);
                setDataValidationErrorMessage('User Name should be greater than 2 characters.');
                return false;
            }
        }
        if (key == "AccessToken" || key == "all") {
            if (dataIntegration.AccessToken === undefined || dataIntegration.AccessToken.length < 10) {
                setShowValidationErrorMessage(true);
                setDataValidationErrorMessage('Access Token should be greater then 10 characters.');
                return false;
            }
        }
        if (key == "HelpInformation" || key == "all") {
            if (dataIntegration.HelpInformation === undefined || dataIntegration.HelpInformation.length < 4) {
                setShowValidationErrorMessage(true);
                setDataValidationErrorMessage('Help Information should be greater than 4 characters.');
                return false;
            }
        }
        if (key == "DefaultResponse" || key == "all") {
            if (dataIntegration.DefaultResponse === undefined || dataIntegration.DefaultResponse.length < 5) {
                setShowValidationErrorMessage(true);
                setDataValidationErrorMessage('Default Response should be greater than 5 characters.');
                return false;
            }
        }
        return true
    }

    const hasEmpty = (element) => {
        return (
            (element.UserName != "" && element.UserName.length > 0) &&
            (element.AccessToken != "" && element.AccessToken.length > 0) &&
            (element.HelpInformation != "" && element.HelpInformation.length > 0) &&
            (element.DefaultResponse != "" && element.DefaultResponse.length > 0)
        );
    };

    const createIntegration = (int_platform) => {
        let int_obj = {};
        let temp = integrations;
        setShowIntegrationFailureMessage(false);
        setIntegrationFailureMessage('');
        switch (int_platform) {
            case 'webex':
                int_obj = {
                    active_users: 0,
                    externalise: false,
                    activate: true,
                    externalised_users: [],
                    username: integrationDetails["UserName"],
                    access_token: integrationDetails["AccessToken"],
                    help_info: integrationDetails["HelpInformation"],
                    default_response: integrationDetails["DefaultResponse"],
                    type: int_platform
                }
                break;
            case 'widget':
                int_obj = {
                    active_users: 0,
                    externalise: false,
                    activate: true,
                    externalised_users: [],
                    data: '<a href="www.google.com">',
                    type: int_platform
                }
                break;
            case 'widget_code':
                int_obj = {
                    active_users: 0,
                    externalise: false,
                    activate: true,
                    externalised_users: [],
                    data: 'console.log("www.google.com")',
                    type: int_platform
                }
                break;
            case 'widget_chathandler':
                int_obj = {
                    active_users: 0,
                    externalise: false,
                    activate: true,
                    externalised_users: [],
                    data: 'print("www.google.com")',
                    type: int_platform
                }
                break;
            default:
                console.log('not defined')
        }

        setDataLoading(true);
        setShowIntegration(false);
        display_message('error', '', false);
        IntegrationService.createIntegration(bot["value"], int_obj).then(res => res.json()).then(res => {
            // if integration is succesful
            if (res["status"] === "success") {
                temp[int_platform] = int_obj
                console.log("integ obj", int_obj)
                setIntegrations(temp);
                console.log("res", res.result)
                display_message('success', 'Successfully created integration', true)
                getIntegrations(bot);

            }
            else {
                console.log(res);
                setIntegrationFailureMessage(res["details"] || 'Falied to integrate the bot. Please try again.');
                setShowIntegrationFailureMessage(true);
                display_message('success', res["details"], true)
                //also show error message in screen
            }
            setDataLoading(false);
        }).catch(err => {
            console.log(err);
            setDataLoading(false);
            //also show error message in screen
            display_message('success', 'Falied to integrate the bot. Please try again or contact dev team.', true)
            setIntegrationFailureMessage('Falied to integrate the bot. Please try again or contact dev team.');
            setShowIntegrationFailureMessage(true);
        })

    }

    const updateIntegration = (int_platform) => {
        let int_obj = {};
        let temp = integrations;
        setShowIntegrationFailureMessage(false);
        setIntegrationFailureMessage('');
        switch (int_platform) {
            case 'webex':
                int_obj = {
                    active_users: 0,
                    externalise: false,
                    activate: true,
                    externalised_users: [],
                    username: integrationDetails["UserName"],
                    access_token: integrationDetails["AccessToken"],
                    help_info: integrationDetails["HelpInformation"],
                    default_response: integrationDetails["DefaultResponse"],
                    type: int_platform
                }
                break;
            case 'widget':
                int_obj = {
                    active_users: 0,
                    externalise: false,
                    activate: true,
                    externalised_users: [],
                    data: '<a href="www.google.com">',
                    type: int_platform
                }
                break;
            case 'widget_code':
                int_obj = {
                    active_users: 0,
                    externalise: false,
                    activate: true,
                    externalised_users: [],
                    data: 'console.log("www.google.com")',
                    type: int_platform
                }
                break;
            case 'widget_chathandler':
                int_obj = {
                    active_users: 0,
                    externalise: false,
                    activate: true,
                    externalised_users: [],
                    data: 'print("www.google.com")',
                    type: int_platform
                }
                break;
            default:
                console.log('not defined')
        }
        IntegrationService.updateIntegration(bot["value"], int_obj).then(res => res.json()).then(res => {
            // if integration is succesful
            if (res["status"] === "success") {
                temp[int_platform] = int_obj
                setIntegrations(temp);
                setShowIntegration(false);
                display_message('success', 'Successfully Updated the Integration', true);
                getIntegrations(bot);
            }
            else {
                console.log(res);
                setIntegrationFailureMessage(res["details"] || 'Falied to integrate the bot. Please try again.');
                setShowIntegrationFailureMessage(true);

                //also show error message in screen
            }

        }).catch(err => {
            console.log(err);
            //also show error message in screen
            setIntegrationFailureMessage('Falied to integrate the bot. Please try again or contact dev team.');
            setShowIntegrationFailureMessage(true);
        })

    }

    const updateExternalization = (int_platform) => {
        if (int_platform == 'all') {
            let temp = integrations
            console.log(integrations)
            for (let pltfm in integrations) {
                temp[pltfm]['externalise'] = true;
                console.log(temp[pltfm])
                setIntegrations(temp)
            }
        }
        else {
            let temp = integrations
            temp[int_platform]['externalise'] = true;
            setIntegrations(temp)
        }

    }

    const handleClick = () => {
        window.open('https://developer.webex.com/my-apps/new/bot');
    }
    const deleteIntegration = (platform) => {
        let temp = integrations;
        delete temp[platform];
        console.log(temp)
        setIntegrations(temp)
    }
    const processFileData = (data) => {
        setFileProcessingFlag(true);
        for (let i = 0; i < data.length; i++) {
            data[i] = FileParser(data[i]["data"], data[i].type);
        }
        setFileProcessingFlag(false);
    }

    const integrationConnection = () => {
        return <Modal size="lg" centered show={showIntegration} animation={false} >
            <Modal.Header >
                <div>
                    <h5 style={{ display: integration == 'webex' ? 'inherit' : 'none' }}>Webex Integration</h5>
                    <h5 style={{ display: integration == 'widget' ? 'inherit' : 'none' }}>Widget Integration</h5>
                    <h5 style={{ display: integration == 'widget_code' ? 'inherit' : 'none' }}>Widget Code Integration</h5>
                    <h5 style={{ display: integration == 'widget_chathandler' ? 'inherit' : 'none' }}>Widget ChatHandler Integration</h5>
                </div>

                <div className=' btn--icon' onClick={() => { setShowIntegration(false) }}>
                    <span className="icon-close icon-size-24"></span>
                </div>
            </Modal.Header>
            <Modal.Body>
                {
                    showIntegrationFailureMessage && (<MessageBanner message={integrationFailureMessage} messageType={"error"} onCloseClicked={() => { setShowIntegrationFailureMessage(false) }}></MessageBanner>)
                }
                {
                    integration == 'webex' && showValidationErrorMessage && (<MessageBanner message={dataValidationErrorMessage} messageType={"error"} onCloseClicked={() => { setShowValidationErrorMessage(false) }}></MessageBanner>)
                }
                {integration == 'webex' && <div className="alert alert--info flex flex-center center base-margin" style={{ width: '95%' }} >
                    <span>Please use <a onClick={handleClick}><b>this link</b></a> to add your new bot to the Webex. Once registration is complete, please add the same details here to create an integration with Webex. In case you need any help, please click on the <b>Help</b> option or converse with the <b>AskBotLite chatbot</b>.</span>
                </div>}
                {!showDeleteIntegration && <div style={{ display: integration == 'webex' ? 'inherit' : 'none' }}>
                    <form>
                        {bot && <div className="row">
                            <div className="col">
                                <div className="form-group base-margin-bottom">
                                    <div className="form-group__text">
                                        <input id="input-type-number" type="text" disabled autoFocus name="bot_name" value={bot.label} />
                                        <label htmlFor="input-type-number">Bot Name <span className='required'>*</span></label>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="form-group base-margin-bottom">
                                    <div className="form-group__text usernamebox">
                                        <b>@webex.bot</b>
                                        <input id="integration-bot-username" type="text" autoComplete="off" autoFocus name="UserName" onChange={e => { inputHandlerChange(e, "UserName") }} />
                                        <label htmlFor="integration-bot-username">Bot UserName <span className='required'>*</span></label>
                                    </div>
                                </div>
                            </div>
                        </div>}
                        <div >
                            <div className="form-group base-margin-bottom">
                                <div className="form-group__text">
                                    <input id="integration-bot-accesstoken" type="password" autoComplete="off" autoFocus name="AccessToken" onChange={e => { inputHandlerChange(e, "AccessToken") }} />
                                    <label htmlFor="integration-bot-accesstoken">Bot Access Token <span className='required'>*</span></label>
                                </div>
                            </div>
                        </div>
                        <div className="row">

                            <div className="col">
                                <div className="form-group base-margin-bottom">
                                    <div className="form-group__text">
                                        <input id="integration-bot-helptext" type="text" autoComplete="off" autoFocus name="HelpInformation" onChange={e => { inputHandlerChange(e, "HelpInformation") }} />
                                        <label htmlFor="integration-bot-helptext">Help Information <span className='required'>*</span></label>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="form-group base-margin-bottom">
                                    <div className="form-group__text">
                                        <input id="integration-bot-defaultresponse" type="text" autoComplete="off" autoFocus name="DefaultResponse" onChange={e => { inputHandlerChange(e, "DefaultResponse") }} />
                                        <label htmlFor="integration-bot-defaultresponse">Default Response <span className='required'>*</span></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>}
                {!showDeleteIntegration && <div style={{ display: integration == 'widget' ? 'inherit' : 'none' }}>
                    {integrations && !integrations['widget'] && <div>
                        <h6>To make the connection click on the below Create Integration button.</h6>

                    </div>}
                    {integrations && integrations['widget'] && <div>
                        <div>
                            <h6>You can easily access your bot now using the below URL. Copy the link and paste the link in any of the browsers and start interacting with your bot.</h6>
                        </div>
                        <div className="panel panel--bordered panel--well base-margin" style={{ border: '1px solid #6c757d' }}>
                            <CodeViewCopier editorLanguage={'html'} dataCode={'www.google.com'} showLineNumbers={false} />
                        </div>
                    </div>}
                </div>}
                {!showDeleteIntegration && <div style={{ display: integration == 'widget_code' ? 'inherit' : 'none' }}>
                    {integrations && !integrations['widget_code'] && <div>
                        <h6>To make the connection click on the below Create Integration button.</h6>

                    </div>}
                    {integrations && integrations['widget_code'] && <div>
                        <div>
                            <h6>You can use the below JS code to attach your bot to any website easily. Copy the code and paste it in the frontend codebase and start interacting with your bot on the website.</h6>
                        </div>
                        <div className="panel panel--bordered panel--well base-margin" style={{ border: '1px solid #6c757d' }}>
                            <CodeViewCopier editorLanguage={'javascript'} dataCode={'console.log("www.google.com")'} showLineNumbers={true} />
                        </div>
                    </div>}
                </div>}
                {!showDeleteIntegration && <div style={{ display: integration == 'widget_chathandler' ? 'inherit' : 'none' }}>

                    {integrations && !integrations['widget_chathandler'] && <div>
                        <h6>To make the connection click on the below Create Integration button.</h6>

                    </div>}
                    {integrations && integrations['widget_chathandler'] && <div>
                        <div>
                            <h6>You can easily access only the nlp backend and build your own the custom widget. Copy the below code to use the bot as a query engine.</h6>
                        </div>
                        <div className="panel panel--bordered panel--well base-margin" style={{ border: '1px solid #6c757d' }}>
                            <CodeViewCopier editorLanguage={'python'} dataCode={'print("www.google.com")'} showLineNumbers={true} />
                        </div>
                    </div>}
                </div>}
                {showDeleteIntegration && <div>
                    Are you sure you want to delete the integration?



                </div>}

            </Modal.Body>
            <Modal.Footer>
                {showDeleteIntegration && <div>
                    <button className="btn btn--secondary" onClick={() => { setShowDeleteIntegration(false); }}>
                        No
                    </button>
                    <button className="btn btn--secondary" onClick={() => { deleteIntegration(integration); setShowDeleteIntegration(false); setShowIntegration(false) }}>
                        Yes
                    </button>
                </div>}
                {!showDeleteIntegration && <button className="btn btn--secondary" onClick={() => { setShowIntegration(false) }}>
                    Cancel
                </button>}
                <div style={{ display: integration == 'webex' ? 'inherit' : 'none' }}>
                    {integrations && !integrations['webex'] && <button className="btn btn--secondary" onClick={() => { createIntegration('webex'); }} disabled={!submitBtnEnable}>
                        Create Integration
                    </button>}
                    {integrations && integrations['webex'] && <button className="btn btn--secondary" onClick={() => { updateIntegration('webex'); }} disabled={!submitBtnEnable}>
                        Update Integration
                    </button>}
                    {integrations && integrations['webex'] && !showDeleteIntegration && <button className="btn btn--secondary" onClick={() => setShowDeleteIntegration(true)}>
                        Delete Integration
                    </button>}
                </div>
                <div style={{ display: integration == 'widget' ? 'inherit' : 'none' }}>
                    {integrations && !integrations['widget'] && <button className="btn btn--secondary" onClick={() => { createIntegration('widget'); }}>
                        Create Integration
                    </button>
                    }
                    {integrations && integrations['widget'] && !showDeleteIntegration && <button className="btn btn--secondary" onClick={() => setShowDeleteIntegration(true)}>
                        Delete Integration
                    </button>}
                </div>
                <div style={{ display: integration == 'widget_code' ? 'inherit' : 'none' }}>
                    {integrations && !integrations['widget_code'] && <button className="btn btn--secondary" onClick={() => { createIntegration('widget_code'); }}>
                        Create Integration
                    </button>
                    }
                    {integrations && integrations['widget_code'] && !showDeleteIntegration && <button className="btn btn--secondary" onClick={() => setShowDeleteIntegration(true)}>
                        Delete Integration
                    </button>}
                </div>
                <div style={{ display: integration == 'widget_chathandler' ? 'inherit' : 'none' }}>
                    {integrations && !integrations['widget_chathandler'] && <button className="btn btn--secondary" onClick={() => { createIntegration('widget_chathandler'); }}>
                        Create Integration
                    </button>
                    }
                    {integrations && integrations['widget_chathandler'] && !showDeleteIntegration && <button className="btn btn--secondary" onClick={() => setShowDeleteIntegration(true)}>
                        Delete Integration
                    </button>}
                </div>
            </Modal.Footer>
        </Modal >
    }
    const activateIntegration = (platform) => {
        console.log("inside this")
        let temp = integrations;
        let value = temp[platform]['activate'];
        temp[platform]['activate'] = !value;
        setIntegrations(temp);
        console.log("inside this 2")
        togglePlatformActivation(platform)
    }
    const activateIntegrationConfirmation = () => {
        return <Modal size="sm" centered show={showActivateIntegration} animation={false} >
            <Modal.Header >
                <div>
                    {integrations && integrations[integration] && !integrations[integration]['activate'] && <h5>Deactivate Integration</h5>}
                    {integrations && integrations[integration] && integrations[integration]['activate'] && <h5>Activate Integration</h5>}
                </div>

                <div className=' btn--icon' onClick={() => { setShowActivateIntegration(false) }}>
                    <span className="icon-close icon-size-24"></span>
                </div>
            </Modal.Header>
            <Modal.Body>
                {integrations && integrations[integration] && !integrations[integration]['activate'] && <span>Are you sure you want to <b>Deactivate</b> the integration?</span>}
                {integrations && integrations[integration] && integrations[integration]['activate'] && <span>Are you sure you want to <b>Activate</b> the integration?</span>}
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn--secondary" onClick={() => { setShowActivateIntegration(false) }}>
                    Cancel

                </button>

                <button className="btn btn--secondary" onClick={() => { activateIntegration(integration); setShowActivateIntegration(false) }}>
                    Yes

                </button>
            </Modal.Footer>
        </Modal>
    }


    const externalizeIntegration = () => {
        return <Modal size="lg" centered show={showExternalizeIntegration} animation={false} >
            <Modal.Header >
                <div>
                    <h5 style={{ display: integration == 'webex' ? 'inherit' : 'none' }}>Externalize Webex</h5>
                    <h5 style={{ display: integration == 'widget' ? 'inherit' : 'none' }}>Externalize Widget</h5>
                    <h5 style={{ display: integration == 'widget_code' ? 'inherit' : 'none' }}> Externalize Widget Code</h5>
                    <h5 style={{ display: integration == 'widget_chathandler' ? 'inherit' : 'none' }}> Externalize Widget ChatHandler</h5>
                </div>

                <div className=' btn--icon' onClick={() => { setShowExternalizeIntegration(false) }}>
                    <span className="icon-close icon-size-24"></span>
                </div>
            </Modal.Header>
            <Modal.Body>

                <div className="base-margin-bottom">
                    <div class="form-group base-margin-bottom">
                        <div class="form-group__text">
                            <textarea id="textarea-state-default" rows="3"></textarea>
                            <label htmlFor="textarea-state-default">Enter comma separated Emails</label>
                        </div>
                    </div>
                    <div className="flex flex-center"><span className="btn btn--dark"> OR </span></div>
                    <FileDropZone onFileuploaded={(data) => processFileData(data)} allowedFileTypes={["text/csv", "text/plain"]}></FileDropZone>
                    <div className="flex flex-center"><button className="btn btn--secondary"> Add External Emails</button></div>
                </div>


                <hr />
                <div>
                    <h6>Externalization User List</h6>
                    <br />
                    <DataGridComponent rows={rows} columns={columns}></DataGridComponent>
                </div>


            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn--secondary" onClick={() => { setShowExternalizeIntegration(false) }}>
                    Cancel
                </button>
                <button className="btn btn--secondary" onClick={() => { updateExternalization(integration); setShowExternalizeIntegration(false) }}>
                    Update Externalization
                </button>
                <button className="btn btn--secondary" onClick={() => { updateExternalization('all'); setShowExternalizeIntegration(false) }}>
                    Global Update Externalization
                </button>
            </Modal.Footer>
        </Modal>
    }

    return (
        <div className='base-margin' style={{ height: "calc(100% - 16px)", overflowY: "auto" }}>
            {
                isDataLoading && (<Spinner></Spinner>)
            }
            <div className="row" >
                {
                    integrations && <div className="base-margin-top col-4" /*col-xs-0 col-sm-0 col-md-0 col-lg-0 col-xl-2 col-xxl-3"*/></div>
                }
                {
                    integrations && <div className="base-margin-top col-4" /*col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-3"*/ >
                        <div className={integrations.hasOwnProperty('webex') ? 'panel panel--bordered panel--raised integrated' : 'panel panel--bordered panel--raised'} style={{ borderRadius: '3vh', height: 'fit-content', width: "100%", backgroundColor: integrations && integrations['webex'] && !integrations['webex']['activate'] ? '#ffffff' : '#dee2e6' }} key={index}>
                            <div className="externalised-icon" style={{ display: integrations && integrations['webex'] && integrations['webex']['externalise'] ? 'inherit' : 'none', position: 'absolute', top: '5px', right: '5px' }}>
                                <button className="btn btn--circle btn--ghost">
                                    <span className="icon-star"></span>
                                </button>
                            </div>
                            <div>
                                <div className="base-margin" >

                                    <div className="row">
                                        <div className="col-3 col-sm-3 col-xs-3 col-md-3 col-lg-3 col-xl-3 col-2xl-3">
                                            <img src={process.env.PUBLIC_URL + "/images/web-logos/WebexLogo.svg"} alt="webexLogo" style={{ borderRadius: '50%', width: '50px', height: '50px', marginLeft: '10px' }} />
                                        </div>

                                        <div className="col-9 col-sm-9 col-xs-9 col-md-9 col-lg-9 col-xl-9 col-2xl-9" >
                                            <div className="row" style={{ height: '40px' }}>
                                                <h6 className="col"><b>Webex</b></h6>
                                                <div className="col" style={{ "display": "flex", "justifyContent": "flex-end" }}>

                                                    <img src={process.env.PUBLIC_URL + "/images/web-logos/disconnect.svg"} alt="Disconnected" style={{ borderRadius: '50%', width: '30px', height: '30px', marginLeft: '10px', display: !integrations.hasOwnProperty('webex') ? 'inherit' : 'none' }} />
                                                    <img src={process.env.PUBLIC_URL + "/images/web-logos/connect.svg"} alt="Connected" style={{ borderRadius: '50%', width: '30px', height: '30px', marginLeft: '10px', display: integrations.hasOwnProperty('webex') ? 'inherit' : 'none' }} />
                                                </div>
                                            </div>
                                            <div className="row base-margin-top base-margin-right" style={{ height: '80px' }}>
                                                <div>
                                                    <span>{truncateStrings('Integrate with Webex to interact with your bot on Webex.', 80)} </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="base-margin-top base-margin-bottom flex flex-wrap flex-center col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 col-xxxl-12" style={{ height: '38px' }}>
                                        {integrations.hasOwnProperty('webex') && <div>
                                            <div className="panel panel--well panel--bordered" style={{ "width": "fit-content" }}>
                                                <div className="flex">
                                                    <div style={{ "margin": "0px 5px" }}>
                                                        <span className='icon-communities icon-size-24' ></span>
                                                    </div>
                                                    <div style={{ "margin": "0px 5px" }}>
                                                        {integrations['webex'] && <span>{integrations['webex']['active_users'].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>}
                                                        {!integrations['webex'] && <span>0</span>}
                                                    </div>
                                                </div>

                                            </div>
                                        </div>}
                                    </div>
                                    <hr />
                                    <br />
                                    <div className="base-margin-left base-margin-right base-margin-bottom flex flex-wrap " style={{ position: 'absolute', right: '0', bottom: '0' }}>
                                        <div style={{ display: integrations.hasOwnProperty('webex') ? 'inherit' : 'none' }} >
                                            <span className="btn btn--success" onClick={() => showIntegrationWindow('webex')} disabled={integrations && integrations['webex'] && integrations['webex']['activate']}>Connected</span>
                                            <span className={integrations && integrations['webex'] && !integrations['webex']['activate'] ? "btn btn--ghost" : "btn btn--dark"} onClick={() => { setShowActivateIntegration(true) }}>{integrations && integrations['webex'] && !integrations['webex']['activate'] ? 'Deactivate' : 'Activate'}</span>
                                            {/* {integrations && integrations['webex'] && !integrations['webex']['activate'] && <span className={integrations && integrations['webex'] && integrations['webex']['externalise'] ? "btn btn--ghost" : "btn btn--dark"} onClick={() => createExternalization('webex')}>{integrations && integrations['webex'] && integrations['webex']['externalise'] ? 'Externalized' : 'Externalize'}</span>} */}
                                        </div>
                                        <div>
                                            <span className="btn btn--dark" style={{ display: !integrations.hasOwnProperty('webex') ? 'inherit' : 'none' }} onClick={() => openCreateIntegrationModal('webex')}>Connect</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {/*
                    integrations && <div className="base-margin-top col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-3">
                        <div className={integrations.hasOwnProperty('widget') ? 'panel panel--bordered panel--raised integrated' : 'panel panel--bordered panel--raised'} style={{ borderRadius: '3vh', height: 'fit-content', width: "100%", backgroundColor: integrations && integrations['widget'] && integrations['widget']['activate'] ? '#ffffff' : '#dee2e6' }} key={index}>
                            <div className="externalised-icon" style={{ display: integrations && integrations['widget'] && integrations['widget']['externalise'] ? 'inherit' : 'none', position: 'absolute', top: '5px', right: '5px' }}>
                                <button className="btn btn--circle btn--ghost">
                                    <span className="icon-star"></span>
                                </button>
                            </div>
                            <div >
                                <div className="base-margin">

                                    <div className="row">
                                        <div className="col-3 col-sm-3 col-xs-3 col-md-3 col-lg-3 col-xl-3 col-2xl-3 ">
                                            <img src={process.env.PUBLIC_URL + "/images/web-logos/widget.svg"} alt="WidgetLogo" style={{ borderRadius: '50%', width: '50px', height: '50px', marginLeft: '10px', border: '2px solid black' }} />
                                        </div>

                                        <div className="col-9 col-sm-9 col-xs-9 col-md-9 col-lg-9 col-xl-9 col-2xl-9" >
                                            <div className="row" style={{ height: '40px' }}>
                                                <h6 className="col"><b>Widget</b></h6>
                                                <div className="col" style={{"display":"flex", "justifyContent":"flex-end"}}>

                                                    <img src={process.env.PUBLIC_URL + "/images/web-logos/disconnect.svg"} alt="Disconnected" style={{ borderRadius: '50%', width: '30px', height: '30px', marginLeft: '10px', display: !integrations.hasOwnProperty('widget') ? 'inherit' : 'none' }} />
                                                    <img src={process.env.PUBLIC_URL + "/images/web-logos/connect.svg"} alt="Connected" style={{ borderRadius: '50%', width: '30px', height: '30px', marginLeft: '10px', display: integrations.hasOwnProperty('widget') ? 'inherit' : 'none' }} />
                                                </div>
                                            </div>
                                            <div className="row base-margin-top base-margin-right" style={{ height: '80px' }} >
                                                <div >
                                                    <span>{truncateStrings('Integrate with Widget to get a link to access your bot.', 80)}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="base-margin-top base-margin-bottom flex flex-wrap flex-center col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 col-xxxl-12" style={{ height: '38px' }}>
                                        {integrations.hasOwnProperty('widget') && <div>
                                            <div className="panel panel--well panel--bordered" style={{ "width": "fit-content" }}>
                                                <div className="flex">
                                                    <div style={{ "margin": "0px 5px" }}>
                                                        <span className='icon-communities icon-size-24' ></span>
                                                    </div>
                                                    < div style={{ "margin": "0px 5px" }}>
                                                        {integrations['widget'] && <span>{integrations['widget']['active_users'].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>}
                                                        {!integrations['widget'] && <span>0</span>}
                                                    </div>
                                                </div>

                                            </div>
                                        </div>}
                                    </div>

                                    <hr />
                                    <br />
                                    <div className="base-margin-left base-margin-right base-margin-bottom flex flex-wrap " style={{ position: 'absolute', right: '0', bottom: '0' }}>
                                        <div style={{ display: integrations.hasOwnProperty('widget') ? 'inherit' : 'none' }} >
                                            <span className="btn btn--success" onClick={() => openCreateIntegrationModal('widget')} disabled={integrations && integrations['widget'] && !integrations['widget']['activate']}>Connected</span>
                                            <span className={integrations && integrations['widget'] && integrations['widget']['activate'] ? "btn btn--ghost" : "btn btn--dark"} onClick={() => togglePlatformActivation('widget')}>{integrations && integrations['widget'] && integrations['widget']['activate'] ? 'Deactivate' : 'Activate'}</span>
                                            {integrations && integrations['widget'] && integrations['widget']['activate'] && <span className={integrations && integrations['widget'] && integrations['widget']['externalise'] ? "btn btn--ghost" : "btn btn--dark"} onClick={() => createExternalization('widget')}>{integrations && integrations['widget'] && integrations['widget']['externalise'] ? 'Externalized' : 'Externalize'}</span>}
                                        </div>
                                        <div>
                                            <span className="btn btn--dark" style={{ display: !integrations.hasOwnProperty('widget') ? 'inherit' : 'none' }} onClick={() => openCreateIntegrationModal('widget')} > Connect</span>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                }
                {
                    integrations && <div className="base-margin-top col-xs-0 col-sm-0 col-md-0 col-lg-0 col-xl-2 col-xxl-3"></div>
                }
            </div>
            <div className="row">
                {
                    integrations && <div className="base-margin-top col-xs-0 col-sm-0 col-md-0 col-lg-0 col-xl-2 col-xxl-3"></div>
                }
                {
                    integrations && <div className="base-margin-top col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-3">
                        <div className={integrations.hasOwnProperty('widget_code') ? 'panel panel--bordered panel--raised integrated' : 'panel panel--bordered panel--raised'} style={{ borderRadius: '3vh', height: 'fit-content', width: "100%", backgroundColor: integrations && integrations['widget_code'] && integrations['widget_code']['activate'] ? '#ffffff' : '#dee2e6' }} key={index}>
                            <div className="externalised-icon" style={{ display: integrations && integrations['widget_code'] && integrations['widget_code']['externalise'] ? 'inherit' : 'none', position: 'absolute', top: '5px', right: '5px' }}>
                                <button className="btn btn--circle btn--ghost">
                                    <span className="icon-star"></span>
                                </button>
                            </div>
                            <div >
                                <div className="base-margin">

                                    <div className="row">
                                        <div className="col-3 col-sm-3 col-xs-3 col-md-3 col-lg-3 col-xl-3 col-2xl-3 ">
                                            <img src={process.env.PUBLIC_URL + "/images/web-logos/code.svg"} alt="CodeLogo" style={{ borderRadius: '50%', width: '50px', height: '50px', marginLeft: '10px', border: '2px solid black' }} />
                                        </div>

                                        <div className="col-9 col-sm-9 col-xs-9 col-md-9 col-lg-9 col-xl-9 col-2xl-9" >
                                            <div className="row" style={{ height: '40px' }}>
                                                <h6 className="col"><b>Widget Code</b></h6>
                                                <div className="col" style={{"display":"flex", "justifyContent":"flex-end"}}>

                                                    <img src={process.env.PUBLIC_URL + "/images/web-logos/disconnect.svg"} alt="Disconnected" style={{ borderRadius: '50%', width: '30px', height: '30px', marginLeft: '10px', display: !integrations.hasOwnProperty('widget_code') ? 'inherit' : 'none' }} />
                                                    <img src={process.env.PUBLIC_URL + "/images/web-logos/connect.svg"} alt="Connected" style={{ borderRadius: '50%', width: '30px', height: '30px', marginLeft: '10px', display: integrations.hasOwnProperty('widget_code') ? 'inherit' : 'none' }} />
                                                </div>
                                            </div>
                                            <div className="row base-margin-top base-margin-right" style={{ height: '80px' }}>
                                                <div>
                                                    <span>{truncateStrings('Integrate with Widget Code to get JS code which can be added to any website.', 80)}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="base-margin-top base-margin-bottom flex flex-wrap flex-center col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 col-xxxl-12" style={{ height: '38px' }}>
                                        {integrations.hasOwnProperty('widget_code') && <div>
                                            <div className="panel panel--well panel--bordered" style={{ "width": "fit-content" }}>
                                                <div className="flex">
                                                    <div style={{ "margin": "0px 5px" }}>
                                                        <span className='icon-communities icon-size-24' ></span>
                                                    </div>
                                                    < div style={{ "margin": "0px 5px" }}>
                                                        {integrations['widget_code'] && <span>{integrations['widget_code']['active_users'].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>}
                                                        {!integrations['widget_code'] && <span>0</span>}
                                                    </div>
                                                </div>

                                            </div>
                                        </div>}
                                    </div>

                                    <hr />
                                    <br />
                                    <div className="base-margin-left base-margin-right base-margin-bottom flex flex-wrap " style={{ position: 'absolute', right: '0', bottom: '0' }}>
                                        <div style={{ display: integrations.hasOwnProperty('widget_code') ? 'inherit' : 'none' }} >
                                            <span className="btn btn--success" onClick={() => openCreateIntegrationModal('widget_code')} disabled={integrations && integrations['widget_code'] && !integrations['widget_code']['activate']}>Connected</span>
                                            <span className={integrations && integrations['widget_code'] && integrations['widget_code']['activate'] ? "btn btn--ghost" : "btn btn--dark"} onClick={() => togglePlatformActivation('widget_code')}>{integrations && integrations['widget_code'] && integrations['widget_code']['activate'] ? 'Deactivate' : 'Activate'}</span>
                                            {integrations && integrations['widget_code'] && integrations['widget_code']['activate'] && <span className={integrations && integrations['widget_code'] && integrations['widget_code']['externalise'] ? "btn btn--ghost" : "btn btn--dark"} onClick={() => createExternalization('widget_code')}>{integrations && integrations['widget_code'] && integrations['widget_code']['externalise'] ? 'Externalized' : 'Externalize'}</span>}
                                        </div>
                                        <div>
                                            <span className="btn btn--dark" style={{ display: !integrations.hasOwnProperty('widget_code') ? 'inherit' : 'none' }} onClick={() => openCreateIntegrationModal('widget_code')} > Connect</span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div >
                }
                {
                    integrations && <div className="base-margin-top col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-3">
                        <div className={integrations.hasOwnProperty('widget_chathandler') ? 'panel panel--bordered panel--raised integrated' : 'panel panel--bordered panel--raised'} style={{ borderRadius: '3vh', height: 'fit-content', width: "100%", backgroundColor: integrations && integrations['widget_chathandler'] && integrations['widget_chathandler']['activate'] ? '#ffffff' : '#dee2e6' }} key={index}>
                            <div className="externalised-icon" style={{ display: integrations && integrations['widget_chathandler'] && integrations['widget_chathandler']['externalise'] ? 'inherit' : 'none', position: 'absolute', top: '5px', right: '5px' }}>
                                <button className="btn btn--circle btn--ghost">
                                    <span className="icon-star"></span>
                                </button>
                            </div>
                            <div >
                                <div className="base-margin">

                                    <div className="row">
                                        <div className="col-3 col-sm-3 col-xs-3 col-md-3 col-lg-3 col-xl-3 col-2xl-3 ">
                                            <img src={process.env.PUBLIC_URL + "/images/web-logos/chathandler.svg"} alt="ChathandlerLogo" style={{ borderRadius: '50%', width: '50px', height: '50px', marginLeft: '10px', border: '2px solid black' }} />
                                        </div>

                                        <div className="col-9 col-sm-9 col-xs-9 col-md-9 col-lg-9 col-xl-9 col-2xl-9" >
                                            <div className="row" style={{ height: '40px' }}>
                                                <h6 className="col"><b>Widget ChatHandler</b></h6>
                                                <div className="col" style={{"display":"flex", "justifyContent":"flex-end"}}>

                                                    <img src={process.env.PUBLIC_URL + "/images/web-logos/disconnect.svg"} alt="Disconnected" style={{ borderRadius: '50%', width: '30px', height: '30px', marginLeft: '10px', display: !integrations.hasOwnProperty('widget_chathandler') ? 'inherit' : 'none' }} />
                                                    <img src={process.env.PUBLIC_URL + "/images/web-logos/connect.svg"} alt="Connected" style={{ borderRadius: '50%', width: '30px', height: '30px', marginLeft: '10px', display: integrations.hasOwnProperty('widget_chathandler') ? 'inherit' : 'none' }} />
                                                </div>
                                            </div>
                                            <div className="row base-margin-top base-margin-right" style={{ height: '80px' }}>
                                                <div>
                                                    <span>{truncateStrings('Integrate with Widget ChatHandler to get chathandler api details.', 80)}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="base-margin-top base-margin-bottom flex flex-wrap flex-center col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 col-xxxl-12" style={{ height: '38px' }}>
                                        {integrations.hasOwnProperty('widget_chathandler') && <div>
                                            <div className="panel panel--well panel--bordered" style={{ "width": "fit-content" }}>
                                                <div className="flex">
                                                    <div style={{ "margin": "0px 5px" }}>
                                                        <span className='icon-communities icon-size-24' ></span>
                                                    </div>
                                                    < div style={{ "margin": "0px 5px" }}>
                                                        {integrations['widget_chathandler'] && <span>{integrations['widget_chathandler']['active_users'].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>}
                                                        {!integrations['widget_chathandler'] && <span>0</span>}
                                                    </div>
                                                </div>

                                            </div>
                                        </div>}
                                    </div>

                                    <hr />
                                    <br />
                                    <div className="base-margin-left base-margin-right base-margin-bottom flex flex-wrap " style={{ position: 'absolute', right: '0', bottom: '0' }}>
                                        <div style={{ display: integrations.hasOwnProperty('widget_chathandler') ? 'inherit' : 'none' }} >
                                            <span className="btn btn--success" onClick={() => openCreateIntegrationModal('widget_chathandler')} disabled={integrations && integrations['widget_chathandler'] && !integrations['widget_chathandler']['activate']}>Connected</span>
                                            <span className={integrations && integrations['widget_chathandler'] && integrations['widget_chathandler']['activate'] ? "btn btn--ghost" : "btn btn--dark"} onClick={() => togglePlatformActivation('widget_chathandler')}>{integrations && integrations['widget_chathandler'] && integrations['widget_chathandler']['activate'] ? 'Deactivate' : 'Activate'}</span>
                                            {integrations && integrations['widget_chathandler'] && integrations['widget_chathandler']['activate'] && <span className={integrations && integrations['widget_chathandler'] && integrations['widget_chathandler']['externalise'] ? "btn btn--ghost" : "btn btn--dark"} onClick={() => createExternalization('widget_chathandler')}>{integrations && integrations['widget_chathandler'] && integrations['widget_chathandler']['externalise'] ? 'Externalized' : 'Externalize'}</span>}
                                        </div>
                                        <div>
                                            <span className="btn btn--dark" style={{ display: !integrations.hasOwnProperty('widget_chathandler') ? 'inherit' : 'none' }} onClick={() => openCreateIntegrationModal('widget_chathandler')} > Connect</span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div >
                */}
                {
                    integrations && <div className="base-margin-top col-4" /*col-xs-0 col-sm-0 col-md-0 col-lg-0 col-xl-2 col-xxl-3"*/></div>
                }
            </div>

            {integrationConnection()}
            {externalizeIntegration()}
            {activateIntegrationConfirmation()}
        </div >
    );
}