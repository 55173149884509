import Wrapper from "../../../../common-components/wrapper/Wrapper";
import FAQView from "../faq-view-component/FAQViewComponent";
import FAQService from "../../faq-service/FAQService";
import { useEffect } from "react";

const FAQScreen = () => {
  useEffect(()=>{
    FAQService.faqFormatterLambdaWarmpUpCall().then((res)=>res.json()).then((res)=>{

    },(err)=>{
      console.log(err);
    })
  },[])
  
  return (
    <Wrapper>
      <FAQView />
    </Wrapper>
  );
};

export default FAQScreen;
