import './Spinner.css';

const Spinner = ()=>{

    //old
    // return(
    //     <div style={{"position":"fixed","top":"0px","left":"0px","width":"100vw","height":"100vh","backgroundColor":"#808080b0","zIndex":"100"}}>
    //     <div className="loader loader--large" aria-label="Loading, please wait..." style={{"position":"fixed","top":"50vh","left":"50vw","transform":"translate(-80px,-100px)"}}>
    //         <div className="wrapper">
    //             <div className="wheel"></div>
    //         </div>     
    //     </div>
    // </div>
    // )
    return(
        <div style={{"position":"fixed","top":"0px","left":"0px","width":"100vw","height":"100vh","backgroundColor":"#808080b0","zIndex":"100"}}>
            <div className="cisco-spinner">
                <span className="spinner-dot"></span>
                <span className="spinner-dot"></span>
                <span className="spinner-dot"></span>
                <span className="spinner-dot"></span>
                <span className="spinner-dot"></span>
                <span className="spinner-dot"></span>
                <span className="spinner-dot"></span>
                <span className="spinner-dot"></span>
                <span className="spinner-dot"></span>
            </div>
        </div>
    )
}

export default Spinner;