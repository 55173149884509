import Wrapper from "../../../../common-components/wrapper/Wrapper";
import ApprovalView from '../approval-view-component/ApprovalViewComponent';

const ApprovalScreen = () => {
  return (
    <Wrapper>
      <ApprovalView />
    </Wrapper>
  );
};

export default ApprovalScreen;
