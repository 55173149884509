import { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import FileDropZone from "../../../../custom-components/file-drop-zone/FileDropZone";
import './ModelValidationComponent.css';
import TrainingService from '../../training-service/TrainingService';
import IntentService from '../../../intent-module/intent-service/IntentService'
import DataGridComponent from "../../../../custom-components/data-grid/DataGrid";
import FileParser from "../../../../utils/FileParser"
import FileDownloader from "../../../../utils/FileDownloader";
import Spinner from "../../../../custom-components/spinner/Spinner";


const ModelValidationComponent = ({ selectedBot, displayMessage = (type, message) => { } }) => {
    const [isDataLoading, setDataLoading] = useState(false);
    const [skillsIntentlist, setSkillsIntentList] = useState([])
    const [validation_row, setValidation_row] = useState([])
    const [showExpressionUploadModal, setShowExpressionUploadModal] = useState(false);
    const [validationDataList, setvalidationDataList] = useState([])
    const [updatedValidationDataList, setUpdatedValidationDataList] = useState([])
    const [layoutType, setLayoutType] = useState(window.innerWidth > 1099.9 ? 'horizontal' : 'verticle');
    // const [intentList, setIntentList] = useState([]);
    // const [selectedSkill, setSelectedSkill] = useState()
    const [selectedExpression, setSelectedExpression] = useState([])
    const truncateExpression = (expression) => {
        let maxExpressionLength = 100;
        if (expression.length > maxExpressionLength) {
            return expression.slice(0, maxExpressionLength) + "...";
        }
        return expression;
    }


    useEffect(() => {
        getIntentSkills();
    }, [selectedBot])

    const getIntentSkills = () => {
        setDataLoading(true)
        if (selectedBot) {
            TrainingService.getIntentSkill(selectedBot.value).then(res => res.json()).then(res => {
                if (res.status == 'success') {
                    if (res.skill_intents.length > 0) {
                        setSkillsIntentList(res.skill_intents)
                    }
                    // console.log(res.skill_intents)
                    setDataLoading(false)
                }
                else {
                    displayMessage('error', res['details'])
                    setDataLoading(false)
                }
            }).catch(err => {
                displayMessage('error', 'Something went wrong. Please try later!')
                setDataLoading(false)
            })
        }
    }
    function roundToTwo(num) {
        return +(Math.round(num + "e+2") + "e-2");
    }


    const onChangeSkill = (skill_intent, data) => {
        let skillobj = skill_intent.split(',')
        console.log(skillobj)
        console.log(data)
        let temp = [...validationDataList];
        console.log("ValidationDataList", validationDataList)
        console.log("updatedValidationDataList", updatedValidationDataList)
        //find the expression id
        let index = temp.findIndex(obj => obj.id == data.id)
        console.log(index)
        let data_temp = {}
        //not present
        if (index == -1) {

            if (data.skill != skillobj[3]) {

                let expression = {
                    "_id": data.id,
                    "text": data.expression,
                    "skill": data.skill,
                    "intent": data.intent,
                    "modified_intent": {
                        "name": skillobj[1],
                        "id": skillobj[0]
                    },
                    "modified_skill": {
                        "name": skillobj[3],
                        "id": skillobj[2]
                    },
                    "agent_id": selectedBot.value
                }
                data_temp = expression
            }
            setUpdatedValidationDataList((prev) => {
                let temp = [...prev];
                temp.push(data_temp);
                return temp
            })
        }
        //present
        else {
            data_temp = validationDataList[index]
            data_temp["agent_id"] = selectedBot.value
            if (data_temp["_id"] === undefined) {
                data_temp["_id"] = data_temp["id"]
                delete data_temp["id"];
            }
            if (data.intent != skillobj[1]) {
                console.log('Inside10')
                data_temp["modified_intent"] = {
                    "name": skillobj[1],
                    "id": skillobj[0]
                }
                data_temp["modified_skill"] = {
                    "name": skillobj[3],
                    "id": skillobj[2]
                }
            }
            else {
                console.log('Inside11')
                temp.splice(index, 1)
            }
            setUpdatedValidationDataList((prev) => {
                let temp = [...prev];
                temp.push(data_temp);
                return temp
            })
        }
    }

    // const onChangeIntent = (intent, data) => {
    //     console.log('Inside6')
    //     let intentobj = intent.split(',')
    //     console.log(data)
    //     let temp = updatedValidationDataList
    //     let index = temp.findIndex(obj => obj._id === data.id)
    //     //not present
    //     if (index == -1) {
    //         console.log('Inside7')
    //         if (data.intent != intentobj[1]) {
    //             console.log('Inside8')
    //             let expression = {
    //                 "_id": data.id,
    //                 "text": data.expression,
    //                 "skill": data.skill,
    //                 "intent": data.intent,
    //                 "modified_intent": {
    //                     "name": intentobj[1],
    //                     "id": intentobj[0]
    //                 },

    //                 "agent_id": selectedBot.value
    //             }
    //             temp.push(expression)
    //         }
    //     }
    //     //present
    //     else {
    //         console.log('Inside9')
    //         if (data.intent != intentobj[1]) {
    //             console.log('Inside10')
    //             temp[index]["modified_intent"] = {
    //                 "name": intentobj[1],
    //                 "id": intentobj[0]
    //             }
    //         }
    //         else {
    //             console.log('Inside11')
    //             temp.splice(index, 1)
    //         }

    //         setUpdatedValidationDataList(temp)
    //     }

    //     console.log("INSIDE ON CHANGE INTENTS", temp)
    // }


    useEffect(() => {
        console.log("selectedBot....", selectedBot.value);
        // getIntentList(selectedBot.skills[0].id);
        getExpression(selectedBot.value)
    }, [selectedBot])

    // const getIntentList = (skillid) => {
    //     setDataLoading(true);
    //     IntentService.listIntents(skillid).then(res => res.json()).then(res => {
    //         if (res['status'] == 'success') {
    //             setIntentList(res.intents)
    //             console.log("setIntentList", res.intents)
    //             setDataLoading(false);
    //         } else {
    //             setDataLoading(false);
    //             displayMessage('error', "Failed to retrieve intent list")
    //         }
    //     }).catch(err => {
    //         console.log(err);
    //         setDataLoading(false);
    //         displayMessage('error', "Failed to retrieve intent list")
    //     })
    // }



    const handleResize = (() => {
        setLayoutType(window.innerWidth > 1099.9 ? 'horizontal' : 'verticle');

    })

    useEffect(() => {
        window.addEventListener('resize', handleResize)
    }, [])


    const validation_col = [
        {
            field: 'expression',
            headerName: 'Expression',
            flex: 0.4,
            headerAlign: 'center',
        },
        {
            field: 'predicted',
            headerName: 'Predicted',
            flex: 0.2,
            headerAlign: 'center',
            renderCell: (params) => {
                return <div className="col-12">
                    <span ><span style={{ "marginleft": "0px", "color": "purple" }}>Skill  :</span> {params.row.skill}</span>
                    <br />
                    <span><span style={{ "marginleft": "0px", "color": "purple" }}>Intent : </span>{params.row.intent}</span>
                </div>
            }

        },
        {
            field: 'expected',
            headerName: 'Expected',
            flex: 0.2,
            sortable: false,
            headerAlign: 'center',

            renderCell: (params) => {
                let index_temp = 0;
                return <div className="col-12">
                    <span className="base-margin-bottom">
                        <div class="form-group">
                            <div class="form-group__text select">
                                {skillsIntentlist && <select id={"skill_data" + params.row.id} onChange={(e) => { console.log(e.target.value, params.row); onChangeSkill(e.target.value, params.row) }}>
                                    {/* <option value='default' key='0' disabled selected> Select expected Skill-Intent</option> */}

                                    {
                                        skillsIntentlist && skillsIntentlist.length > 0 && skillsIntentlist.map((skill_intent, index) => {
                                            index_temp = index;
                                            return <optgroup label={skill_intent['skill']['name']} >
                                                {renderOptions(skill_intent['matched_intents'], skill_intent['skill'])}
                                            </optgroup>
                                            // return (<option value={[skill.value, skill.label]} key={skill.value} >{skill.label}</option>)
                                        })
                                    }
                                </select>}
                                <label for="select-type-basic" style={{ "marginleft": "0px", "color": "purple" }}> Skill-Intent  :</label>
                            </div>
                        </div>

                    </span>
                </div >
            }

        },
        {
            field: 'accuracy',
            headerName: 'Accuracy',
            flex: 0.15,
            align: 'center',
            headerAlign: 'center',

        },
        {
            field: 'delete',
            headerName: '',
            sortable: false,
            flex: 0.1,
            align: 'center',
            headerAlign: 'center',
            renderHeader: (params) => {
                const DeleteExpression = (e) => {
                    e.stopPropagation();
                    // const api = params.api;
                    // console.log(params)
                    // const thisRow = {};
                    deleteMultipleExpressions()
                };
                return <button className="btn btn--small btn--secondary flex flex-center" onClick={DeleteExpression}>Delete</button>
            },
            renderCell: (params) => {
                const DeleteExpression = (e) => {
                    e.stopPropagation();
                    const api = params.api;
                    const thisRow = {};
                    deleteValidationExpression(params.row.id)
                };

                return <div className="row flex flex-center">
                    <button className="btn btn--large btn--icon btn--ghost" onClick={DeleteExpression}><span className="icon-delete"></span></button>
                </div>;
            },

        }
    ]

    const deleteMultipleExpressions = () => {
        console.log(selectedExpression)
        let payload = { "expression_ids": selectedExpression }
        setDataLoading(true);
        TrainingService.deleteValidation(payload, selectedBot.value).then(res => res.json()).then(res => {
            if (res['status'] == 'success') {
                displayMessage('success', res['details'])
                getExpression(selectedBot.value)
                setDataLoading(false);
            } else {
                setDataLoading(false);
                displayMessage('error', res['details'])
            }
        }).catch(err => {
            console.log(err);
            setDataLoading(false);
            displayMessage('error', "Failed to delete the validation expression")
        })
    }
    const deleteValidationExpression = (id) => {
        console.log(id)
        let payload = { "expression_ids": [id] }
        setDataLoading(true);
        TrainingService.deleteValidation(payload, selectedBot.value).then(res => res.json()).then(res => {
            if (res['status'] == 'success') {
                displayMessage('success', res['details'])
                getExpression(selectedBot.value)
                setDataLoading(false);
            } else {
                setDataLoading(false);
                displayMessage('error', res['details'])
            }
        }).catch(err => {
            console.log(err);
            setDataLoading(false);
            displayMessage('error', "Failed to delete the validation expression")
        })
    }

    const getExpression = (botId) => {
        console.log(botId)
        if (botId != undefined) {

            setDataLoading(true);
            TrainingService.getListExpression(botId).then(res => res.json()).then(res => {
                if (res['status'] == 'success') {
                    console.log("res", res)
                    if (res.expressions != undefined && res.expressions.length > 0) {
                        // let temp = validation_row;
                        let newdata = res.expressions.map((data) => {
                            return (
                                {
                                    id: data._id,
                                    expression: data.text,
                                    skill: data.skill,
                                    intent: data.intent,
                                    accuracy: data.accuracy ? roundToTwo(data.accuracy) + "%" : "-",
                                    modified_skill: data.modified_skill ? data.modified_skill : data.skill,
                                    modified_intent: data.modified_intent ? data.modified_intent : data.intent
                                }
                            )
                        })
                        console.log(newdata)
                        setValidation_row(newdata)
                        setvalidationDataList(newdata)
                    }
                    else {
                        setValidation_row([])
                        setvalidationDataList([])
                    }
                    setDataLoading(false);
                } else {
                    setDataLoading(false);
                    displayMessage('error', res['details'])
                }
            }).catch(err => {
                console.log(err);
                setDataLoading(false);
                displayMessage('error', "Failed to retrieve expression details")
            })
        }
    }

    const renderOptions = (intents, skill) => {
        return intents.map(intent => {
            return (
                <option key={intent._id + "_" + skill.id} value={[intent._id, intent.name, skill.id, skill.name]}>
                    {intent.name}
                </option>
            );
        });
    }

    const ValidationCardSmall = (data) => {
        return (
            <div className="panel panel--raised validationCard-wrapper-small">
                <div className="row validationCard-row base-margin-bottom">
                    <div className="panel">Expression</div>
                    <div className="panel" style={{ "fontWeight": "700" }}>{data.expression}</div>
                </div>
                <hr />
                <div className="row validationCard-row">
                    <div className="panel">Predicted</div>
                    <div className="panel">
                        <div className="row" style={{ "marginLeft": "-5px" }}>
                            <span style={{ "color": "purple", "marginRight": "15px" }}>Skill:</span>
                            <span>
                                {
                                    data.skill
                                }
                            </span>
                        </div>
                        <div className="row" style={{ "marginLeft": "-5px" }}>
                            <span style={{ "color": "purple", "marginRight": "6px" }}>Intent:</span>
                            <span>
                                {
                                    data.intent
                                }
                            </span>

                        </div>
                    </div>
                </div>
                <hr />
                <div className="row validationCard-row">
                    <div className="panel">Selected</div>
                    <div className="panel">
                        <div className="row form-group base-margin-bottom" style={{ "marginleft": "0px", "color": "purple" }}>Skill:
                            <div className="form-group__text select" style={{ "marginleft": "12px" }}>

                                {skillsIntentlist && <select id={"skill_data" + data.id} value={[data.modified_intent.id, data.modified_intent.name, data.modified_skill.id, data.modified_skill.name]} onChange={(e) => { onChangeSkill(e.target.value, data) }}>
                                    {/* <option value='default' key='0' disabled selected> Select expected Skill-Intent</option> */}
                                    {
                                        skillsIntentlist && skillsIntentlist.length > 0 && skillsIntentlist.map((skill_intent) => {
                                            return <optgroup label={skill_intent['skill']['name']}>
                                                {renderOptions(skill_intent['matched_intents'], skill_intent['skill'])}
                                            </optgroup>
                                        })
                                    }
                                </select>}
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <div className="row validationCard-row">
                    <div className="panel">Accuracy</div>
                    <div className="panel" style={{ "fontWeight": "700" }}>{data.accuracy}  </div>
                    <span className="icon-delete icon-size-18" style={{ "position": "absolute", "right": "25px", "bottom": "30px" }}></span>
                </div>
            </div>
        )
    }

    const UplodExpressionComponent = () => {
        let [expressions, addExpression] = useState([]);
        let [expression, setExpression] = useState('');
        let [tab_selected, switchUploadTab] = useState('form-upload');
        const [addedExp, setAddedExp] = useState([])
        const [alert, setAlert] = useState(false);
        const [disabledBtn, setDisabledBtn] = useState(true)
        const [uploaddata, setUploadData] = useState();

        const uploadTab = (tab) => {
            setDisabledBtn(true)
            switchUploadTab(tab);
            if (tab === "form-upload" && (expression.length > 0 || expressions.length > 0)) {
                setDisabledBtn(false)
            }
            if (uploaddata != "" && uploaddata != undefined) {
                setDisabledBtn(false)
            }
        }

        const addExpList = () => {
            if (expression != "") {
                if (expressions.includes(expression)) {
                    setAlert(true)
                } else {
                    setAlert(false)
                    addExpression((prev) => {
                        let temp = [...prev];
                        temp.push(expression);
                        setExpression('');
                        return temp;
                    })
                }
            }
            if (expression.length > 0 || expressions.length > 0)
                setDisabledBtn(false)
            else
                setDisabledBtn(true)
        }
        const checkedlist = (e, ind) => {
            if (e.target.checked) {
                setAddedExp((prev) => {
                    let temp = [...prev];
                    temp.push(ind);
                    return temp;
                })
            } else {
                setAddedExp((prev) => {
                    if (prev.length > 0) {
                        let temp = [];
                        for (let i = 0; i < prev.length; i++) {
                            if (prev[i] != ind) {
                                temp.push(prev[i]);
                            }
                        }
                        return temp;
                    }
                })
            }

        }
        const deleteExp = () => {
            if (addedExp.length > 0) {
                addExpression((prev) => {
                    let temp = [];
                    for (let i = 0; i < prev.length - 1; i++) {
                        if (!addedExp.includes(i)) {
                            temp.push(prev[i]);
                        }
                    }
                    return temp;
                })
                setAddedExp([])
            }
        }
        useEffect(() => {
            if (expressions.length > 0)
                setDisabledBtn(false)
            else
                setDisabledBtn(true)
        }, [expressions])

        const uploadExpression = () => {
            setDataLoading(true);
            let expressionlist = undefined;
            if (tab_selected === "form-upload") {
                expressionlist = expressions;
            } else {
                expressionlist = uploaddata[0];
            }
            var raw = {
                "agent_id": selectedBot.value,
                "expressions": expressionlist,
                "agent_name": selectedBot.label,
                "beta": false
            };
            TrainingService.uploadExpressionDetails(raw, selectedBot.value).then(res => res.json()).then(res => {
                if (res['status'] == 'success') {
                    setDataLoading(false);
                    displayMessage('success', "Upload expressions successfully")
                    getExpression(selectedBot.value)
                } else {
                    setDataLoading(false);
                    displayMessage('error', "Failed to Upload expressions")
                }
            }).catch(err => {
                console.log(err);
                setDataLoading(false);
                displayMessage('error', "Failed to Upload expressions")
            })
            setShowExpressionUploadModal(false)
            addExpression([])
        }

        const processFileData = (data) => {
            for (let i = 0; i < data.length; i++) {
                data[i] = FileParser(data[i]["data"], data[i].type);
            }
            setUploadData(data)

            if (data != "" || data != undefined) {
                setDisabledBtn(false)
            }
        }



        return (
            <div>

                <Modal show={showExpressionUploadModal} onHide={() => { }} animation={false} size='lg' scrollable={true} fullscreen={'sm-down'}>
                    <Modal.Header>
                        <h6>Upload Expressions</h6>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <ul className="tabs tabs--justified" style={{ "height": "100%" }}>
                                <li id="static-entity-tab" className={tab_selected == "form-upload" ? "active" : "" + "tab"} onClick={() => { uploadTab("form-upload") }}>
                                    <a tabindex="0">Static</a>
                                </li>
                                <li id="api-entity-tab" className={tab_selected == "file-upload" ? "active" : "" + "tab"} onClick={() => { uploadTab("file-upload") }}>
                                    <a tabindex="0">File Upload</a>
                                </li>
                            </ul>
                            <div className="tab-content" >
                                <div className={tab_selected == "form-upload" ? "active" : "" + "panel panel--raised tab-pane"}>
                                    <div className="panel panel--raised">
                                        <div className="row" style={{ "marginLeft": "0px" }}>
                                            <div className="col-10 col-sm-10 col-md-11 col-lg-11 col-xl-11 col-xxl-12 col-xxxl-11 form-group">
                                                <div className="form-group__text">
                                                    {/* <input id="input-type-email" type="text" autoComplete="off" value={expression} onChange={(e) => { setExpression(e.target.value) }} /> */}
                                                    <input id="input-type-email" type="text" autoComplete='off' value={expression} onChange={e => { setExpression(e.target.value) }} onKeyDown={(e) => {
                                                        if (e.key === 'Enter' && expression.length > 0) {
                                                            // setShowValidationErrorMessage(false);
                                                            // if (isAlphanumeric(expression, 'en-US', { "ignore": /[,.!?'"; ]/g })) {
                                                            addExpression(arr => [...arr, expression])
                                                            setExpression('');
                                                            // }
                                                            // else {
                                                            //     setShowValidationErrorMessage(true);
                                                            //     setDataValidationErrorMessage('Special characters other than ,.!?\'\"; are not allowed in an expression.');

                                                            // }
                                                        }
                                                    }} />
                                                    <label htmlFor="input-type-email">Expression</label>
                                                </div>
                                            </div>
                                            <div className="col-2 col-sm-2 col-md-1 col-lg-1 col-xl-1 col-xxl-1 col-xxxl-1">
                                                <span className="icon-add-outline icon-size-20 cursorPointer" style={{ "marginTop": "25px" }} onClick={() => { addExpList() }}></span>
                                            </div>
                                            {alert && <div className="expAlert">Expression already exist</div>}
                                        </div>
                                    </div>
                                    {expressions.length > 0 && <hr></hr>}
                                    {expressions.length > 0 && <div className="panel panel--raised">
                                        <table className="table table--bordered" style={{ "width": "100%", "height": "50px", "overflow": "auto" }}>
                                            <thead style={{ "width": "100%", "background": "#00bceb60", "color": "#1e4471", "fontWeight": "700", "height": "30px" }}>
                                                <tr style={{ "width": "100%", }}>
                                                    <td style={{ "width": "95%" }}>Expression</td>
                                                    <td style={{ "width": "5%" }}><span className="icon-delete icon-size-20 cursorPointer" onClick={() => { deleteExp() }} /></td>
                                                </tr>
                                            </thead>
                                            <tbody style={{ "height": "calc(100% - 35px)" }}>
                                                {
                                                    expressions.map((value, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>{value}</td>
                                                                <td>
                                                                    <input type="checkbox" onChange={(e) => { checkedlist(e, index) }} />
                                                                    <span className="checkbox__input"></span>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }

                                            </tbody>
                                        </table>

                                    </div>}
                                </div>
                                <div className={tab_selected == "file-upload" ? "active" : "" + "panel panel--raised tab-pane"}>
                                    <FileDropZone onFileuploaded={(data) => processFileData(data)}></FileDropZone>
                                </div>
                            </div>


                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn--secondary" onClick={uploadExpression} disabled={disabledBtn}>Save</button>
                        <button className="btn btn--secondary" onClick={() => { setShowExpressionUploadModal(false); setUploadData(); setDisabledBtn(true); addExpression([]) }}>Close</button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }



    const validateExpressions = () => {
        setDataLoading(true);
        TrainingService.revalidateExpression(selectedBot.value).then(res => res.json()).then(res => {
            if (res['status'] == 'success') {
                setDataLoading(false);
                displayMessage('success', 'Successfully revalidated the expressions')
                getExpression(selectedBot.value);
            }
            else {
                setDataLoading(false);
                displayMessage('error', res['details'])
            }
        }).catch(err => {
            setDataLoading(false);
            displayMessage('error', 'Failed the revalidate the expressions. Please try again later.')
        })
    }
    const saveExpressions = () => {
        setDataLoading(true);
        let payload = {
            "modified_expressions": updatedValidationDataList,
            "agent_name": selectedBot.label,
            "beta": false
        }
        TrainingService.saveExpressionDetails(payload, selectedBot.value).then(res => res.json()).then(res => {
            if (res['status'] == 'success') {
                setDataLoading(false);
                displayMessage('success', res['details'])
            }
            else {
                setDataLoading(false);
                displayMessage('error', res['details'])
            }

        }).catch(err => {
            setDataLoading(false);
            displayMessage('error', "Something went wrong. Please try later.")
        })
    }

    const downloadExpressions = () => {
        setDataLoading(true);
        let download_list = ['Expression,Predicted Skill,Predicted Intent,Accuracy']
        for (let i of validation_row) {
            download_list.push([i.expression, i.skill, i.intent, i.accuracy].join(','))
        }
        FileDownloader(download_list, 'validation_expression.csv');
        setDataLoading(false);
    }

    return (
        <>
            {
                isDataLoading && (<Spinner></Spinner>)
            }
            <div className="validationData-wrapper">
                {

                    layoutType === 'verticle' ? (
                        <div className="validationData-tableWrapper-small">
                            <div className="panel panel--raised validationData-table-small">
                                {validationDataList && validationDataList.length > 0 &&
                                    validationDataList.map((value, index) => {
                                        return ValidationCardSmall(value);
                                    })
                                }
                            </div>
                            <div className="panel validationData-buttonWrapper-small">
                                <div className="row" style={{ "margin": "5px 0px" }}>

                                    <div className="col-3 col-sm-3 col-md-4 col-xl-4 col-xxl-4 col-xxxl-4" style={{ "textAlign": "center" }}>
                                        <button className="btn btn--secondary validationScreenButton" onClick={() => saveExpressions()}>
                                            Save
                                        </button>
                                    </div>
                                    <div className="col-3 col-sm-3 col-md-4 col-xl-4 col-xxl-4 col-xxxl-4" style={{ "textAlign": "center" }}>
                                        <button className="btn btn--primary validationScreenButton" onClick={() => validateExpressions()}>
                                            Revalidate
                                        </button>
                                    </div>
                                    <div className="col-5 col-sm-5 col-md-4 col-xl-4 col-xxl-4 col-xxxl-4" style={{ "textAlign": "center" }}>
                                        <button className="btn btn--primary validationScreenButton" onClick={() => downloadExpressions()}>
                                            Download
                                        </button>
                                    </div>

                                    <div className="col-4 col-sm-4 col-md-4 col-xl-4 col-xxl-4 col-xxxl-4" style={{ "textAlign": "center" }}>
                                        <button className="btn btn--primary validationScreenButton" onClick={() => { setShowExpressionUploadModal(true) }} style={{ "paddingLeft": "24px !important", "paddingRight": "24px !important" }}>
                                            Upload
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        // <div className="validationData-searchAndTable">
                        //     <div className="panel panel--raised">
                        //         <div className="row validationData-searchbarWrapper base-margin-bottom">

                        //             <div className="flex col-6 col-sm-6 col-md-6 col-lg-7 col-xl-8 col-xxl-8">
                        //                 <div className="validationScreenButtonWrapper">
                        //                     <button className="btn  btn--primary btn--small" onClick={() => saveExpressions()}>
                        //                         Save
                        //                     </button>
                        //                     <button className="btn btn--secondary btn--small" onClick={() => downloadExpressions()} >
                        //                         Download
                        //                     </button>
                        //                     <button className="btn btn--secondary btn--small" onClick={() => { setShowExpressionUploadModal(true) }}>
                        //                         Upload
                        //                     </button>
                        //                 </div>

                        //             </div>
                        //         </div>
                        //     </div>
                        //     <div className="validationData-tableWrapper">
                        //         <div className="row">
                        //             <div className="col-12 validateTable">
                        //                 <DataGridComponent rows={validation_row}
                        //                     columns={validation_col}
                        //                     noToolbar={true}
                        //                     rowHeight={150}
                        //                     ht={700}
                        //                     checkbox={true}
                        //                     setSelectedExpression={(data) => setSelectedExpression(data)}


                        //                 // onChangeData={(ids) => {
                        //                 //     console.log(ids)
                        //                 //     const selectedIDs = new Set(ids);
                        //                 //     const selectedRows = validation_row.filter((row) => {
                        //                 //         selectedIDs.has(row.id)
                        //                 //     }
                        //                 //     );
                        //                 //     setSelectedExpression(selectedRows)

                        //                 // }}
                        //                 />
                        //             </div>
                        //         </div>
                        //     </div>
                        // </div>

                        <div className="panel panel--raised panel--bordered base-margin" style={{ height: "calc(100% - 16px)" }}>
                            <h6 className="row">
                                <span className="col" style={{ marginTop: '0.5em' }}>Validate Your Bot</span>
                                <span className="col flex flex-right">

                                    <button className="btn  btn--secondary btn--small" onClick={() => saveExpressions()}>
                                        Save
                                    </button>
                                    <button className="btn btn--primary btn--small" onClick={() => validateExpressions()}>
                                        Revalidate
                                    </button>
                                    <button className="btn btn--primary btn--small" onClick={() => downloadExpressions()} >
                                        Download
                                    </button>
                                    <button className="btn btn--primary btn--small" onClick={() => { setShowExpressionUploadModal(true) }}>
                                        Upload
                                    </button>
                                </span>
                            </h6>
                            <hr />
                            <div style={{ height: "calc(100% - 62px)" }}>
                                <DataGridComponent rows={validation_row}
                                    columns={validation_col}
                                    noToolbar={true}
                                    rowHeight={60}
                                    ht={"100%"}
                                    checkbox={true}
                                    setSelectedExpression={(data) => setSelectedExpression(data)} />
                            </div>
                        </div>

                    )
                }

                {
                    UplodExpressionComponent()
                }
            </div>

        </>

    )
}
export default ModelValidationComponent;