import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import { styled } from "@mui/material/styles";
import React, { useEffect, useRef, useState } from "react";
import SearchSelect from "../../../../custom-components/search-select/SearchSelect";
import BotService from "../../../bot-module/bot-services/BotService";
import "./NotificationViewComponent.css";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import VerifiedIcon from "@mui/icons-material/Verified";
import CancelIcon from '@mui/icons-material/Cancel';
import { Icon } from "@mui/material";
import { green, grey, red } from "@mui/material/colors";
import AceEditor from "react-ace";
import Editor from "@monaco-editor/react";
import MdEditor from 'react-markdown-editor-lite';
import SplitScreenWrapper from "../../../../custom-components/split-screen-wrapper/SplitScreenWrapper";
import FileDropZone from "../../../../custom-components/file-drop-zone/FileDropZone";
import Modal from 'react-bootstrap/Modal';
import DataGridComponent from "../../../../custom-components/data-grid/DataGrid";
import FileParser from "../../../../utils/FileParser";
import { AdaptiveCard } from "adaptivecards-react";
import NotificationService from "../../notification-services/NotificationService";
import sampleWxTCardsPayLoad from "./SampleNotifictaionPayload";
import Spinner from "../../../../custom-components/spinner/Spinner";
import ReactMarkdown from 'react-markdown'
import Joyride, { CallBackProps, STATUS, Step } from 'react-joyride';
import FAQService from "../../../faq-module/faq-service/FAQService";
import MessageBanner from "../../../../custom-components/MessageBanner/MessageBanner";
import { saveAs } from 'file-saver';
import TimezoneConverter from "../../../../utils/TimeZoneConverter";
import IntegrationService from "../../../integration-module/integration-service/IntegrationService";
import { dateTimeFormatChange } from "../../../../utils/utils.js"

// import style manually
import 'react-markdown-editor-lite/lib/index.css';
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid #ced4da`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <>
        {
          props.isVerified && (<Icon sx={{ color: green[500] }}><VerifiedIcon /></Icon>)
        }
        {
          !props.isVerified && (<Icon sx={{ color: red[500] }}><CancelIcon /></Icon>)
        }

        <Icon sx={{ color: grey[900] }}>
          <ArrowDropDownIcon />
        </Icon>
      </>
    }
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, 1)"
      : "rgba(0, 0, 0, 0)",
  flexDirection: "coulmn-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    display: "none",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));
const AccordionSummaryHist = styled((props) => (
  <MuiAccordionSummary

    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, 1)"
      : "rgba(0, 0, 0, 0)",
  flexDirection: "coulmn-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    display: "none",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(1),
  borderTop: "1px solid #ced4da",
}));

const NotificationView = () => {
  const refWidth = useRef(null);
  //default selection to UTC
  const defaultTimeZone = 'Europe/London';
  const [timeZone, setTimeZone] = useState(defaultTimeZone);
  const [isTimeZoneLoading, setIsTimeZoneLoading] = useState(false);
  const [showMessageBanner, setShowMessageBanner] = useState(false);
  const [bannerMessage, setBannerMessage] = useState("");
  const [bannerMessageType, setBannerMessageType] = useState("success");
  const [bot, setBot] = useState();
  const [bots, setBotList] = useState([]);
  const [tab, setTab] = useState("scheduleNotificationTab");
  const [panelHeader, setPanelHeader] = useState("Notification Message");
  const [messageType, setMessageType] = useState("Webex Card");
  const [editorType, setEditorType] = useState("Raw Editor");
  const [notificationTimeZoneType, setNotificationTimeZoneType] = useState("Local TimeZone");
  const [userType, setUserType] = useState("Custom Users");
  const [userNotificationType, setUserNotificationType] = useState("Generic")
  const [notifHistData, setNotificationHistory] = useState([]);
  const [notificationHistoryLoadErrorMessage, setNotificationHistoryLoadErrorMessage] = useState(undefined);
  const [notificationCardPayload, setNotificationCardPayload] = useState(sampleWxTCardsPayLoad);
  const [notificationMarkdownPayload, setNotificationMarkdownPayload] = useState(`Add markdown text here\n
  To know more about Webex Markdown [click here](https://help.webex.com/en-us/article/nbf0ydx/Webex-App-%7C-Format-Messages)
  `);
  const [simpleText, setSimpleText] = useState(`Add markdown text here\n
  To know more about Webex Markdown [click here](https://help.webex.com/en-us/article/nbf0ydx/Webex-App-%7C-Format-Messages)
  `)
  const [timeZoneList, setTimeZoneList] = useState([]);
  const [expanded, setExpanded] = useState("panel1");
  const [expandedHist, setExpandedHist] = useState("");
  const [expandedNotificationContent, setExpandedNotificationContent] = useState('');
  const [isNotificationContentUpdated, setNotificationContentUpdated] = useState(false);
  const [uploadedCustomisedUserCSVFile, setCustomisedUserCSVFile] = useState(undefined);
  const [activeUserList, setActiveUsersList] = useState([]);
  const [showNotificationConfirmationWindow, setShowNotificationConfirmationWindow] = useState(false);
  const [showSubmitNotificationButton, setShowSubmitNotificationButton] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [showFileValidationError, setShowFileValidationError] = useState(false);
  const [fileValidationErrorMessage, setFileValidationErrorMessage] = useState(undefined);
  const [selectedEmailAndMailerGroups, setSelectedEmailAndMailerGroups] = useState([]);
  const [notificationConfirmationMessage, setNotificationConfirmationMessage] = useState("");
  const notificationMinuteOptions = ["00", "15", "30", "45"];
  const notificationHoursOptions = ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"];
  const [notificationDate, setNotificationDate] = useState(() => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    const dateString = yyyy + "-" + mm + "-" + dd;
    return dateString;
  });
  const [updatedNotificationDate, setUpdatedNotificationDate] = useState(() => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    const dateString = yyyy + "-" + mm + "-" + dd;
    return dateString;
  });
  const [notificationMinute, setNotificationMinute] = useState("00");
  const [updatedNotificationMinute, setUpdatedNotificationMinute] = useState("00");
  const [notificationHour, setNotificationHour] = useState("00");
  const [updatedNotificationHour, setUpdatedNotificationHour] = useState("00");
  const [loggedInUser, setLoggedInUser] = useState(undefined);
  const [emailsAndMailerGroupsList, setEmailsAndMailerGroupsList] = useState([]);
  const [validateJSONTxt, setValidateJSONTxt] = useState();
  const [scheduleNotificationErrorMessage, setScheduleNotificationErrorMessage] = useState(undefined);
  const [notificationUpdationErrorMessage, setNotificationUpdationErrorMessage] = useState(undefined);
  const [messageValidationFlag, setMessageValidationFlag] = useState(false);
  const [isPanelDataVerfied, setIsPanelDataVerified] = useState({
    "panel1": false,
    "panel2": false,
    "panel3": false
  })
  const [fileValidationSuccessMessage, setFileValidationSuccessMessage] = useState(undefined);
  const [showCreateDataUriScreen, setShowCreateDataUriScreen] = useState(false);
  const [dataUriUploaded, setDataUriUploaded] = useState(false);
  const [showValidationErrorMessage, setShowValidationErrorMessage] = useState(false);
  const [dataValidationErrorMessage, setDataValidationErrorMessage] = useState('');
  const [showDataUriHelp, setShowDataUriHelp] = useState(false);
  const [editNotificationScheduleTime, setEditNotificationScheduleTime] = useState(undefined);
  const [isBotIntegratedWithWebex, setIsBotIntegratedWithWebex] = useState(false);
	const [sortMethod, setSortMethod] = useState('created_at')
	const [sortDirection, setSortDirection] = useState(true)

  const getActiveUsers = () => {
    NotificationService.getActiveUsers(bot["value"]).then(res => res.json()).then(res => {
      console.log(res);
      setActiveUsersList(() => {
        let temp = [];
        for (let i = 0; i < res["active_users"].length; i++) {
          temp.push({
            id: i,
            UserList: res["active_users"][i],
            UserType: "User"
          })
        }
        return temp;
      })
    }).catch(err => {
      setBannerMessage("Failed to fetch active user!");
      setShowMessageBanner(true);
      setBannerMessageType("error");
      console.log(err);
    })
  }

  const getTimeZoneList = () => {
    setIsTimeZoneLoading(true);
    NotificationService.getTimeZone().then((res) => res.json()).then((res) => {

      if (res["status"] === "success") {
        let temp = [];
        for (let tz of res["timezones"]) {
          temp.push([
            tz["name"],
            tz["value"]
          ])
        }
        temp = temp.sort((s1, s2) => {
          return s1[0].localeCompare(s2[0])
        })
        setTimeZoneList(temp);
      }
      setIsTimeZoneLoading(false);

    }, (err) => {
      setIsTimeZoneLoading(false);
    })
  }
  //update the value for active user or notification history based on bot selection
  useEffect((res) => {
    setBannerMessage("");
    setShowMessageBanner(false);
    if (bot && bot["value"]) {
      setShowSpinner(true);
      localStorage.setItem("selectedBot", bot["value"]);
      setIsBotIntegratedWithWebex(false);
      IntegrationService.getIntegrations(bot["value"]).then((res) => res.json()).then((res) => {
        if (res["status"] === "success") {
          if (res["integrations"]) {
            for (let integration of res["integrations"]) {
              if (integration["type"] === "webex") {
                if (userType === "Active Users") {
                  getActiveUsers();
                }

                if (tab === "notificationHistoryTab") {
                  loadNotificationHistory();
                }
                setShowSpinner(false);
                console.log("Bot is integrated with webex")
                setIsBotIntegratedWithWebex(true);
                break;
              }
            }
          }
          setShowSpinner(false);

        }
        else {
          setBannerMessage(res["details"] || "Failed to retrieve bot integration details!");
          setShowMessageBanner(true);
          setBannerMessageType("error");
        }
        setShowSpinner(false);
      }, (err) => {
        setBannerMessage("Failed to retrieve bot integration details!");
        setShowMessageBanner(true);
        setBannerMessageType("error");
        console.log(err);
        setShowSpinner(false);
      })

    }

  }, [bot])

  const getUserDetails = () => {
    FAQService.getUserDetails().then((res) => res.json()).then((res) => {
      if (res["status"] === "success") {
        setLoggedInUser(res["user"]["email"].split("@")[0]);
        setEmailsAndMailerGroupsList([{
          id: 0,
          UserList: res["user"]["email"].split("@")[0],
          UserType: "user"

        }])
        // if generic user option is selected(this is default selection)
        if (userType === "Custom Users" && userNotificationType !== "Customised") {
          setIsPanelDataVerified((prev) => {
            prev["panel3"] = true;
            return prev;
          })
        }
      }
    }, (err) => {

    })
  }

  useEffect(() => {
    getTimeZoneList();

    getUserDetails()
  }, [])


  //validate if submit button can be enabled or not
  useEffect((res) => {
    if (expanded === "panel3") {
      setShowSubmitNotificationButton(false);
      setIsPanelDataVerified((prev) => {
        prev["panel3"] = false;
        return prev;
      })
      //validate all the fields and enable submit button accordingly
      if (userType === "Custom Users") {
        if (userNotificationType === "Customised") {
          if (uploadedCustomisedUserCSVFile !== undefined && uploadedCustomisedUserCSVFile["data"] !== undefined) {
            setShowSubmitNotificationButton(true);
            setIsPanelDataVerified((prev) => {
              prev["panel3"] = true;
              return prev;
            })
          }
        }
        else {
          if (emailsAndMailerGroupsList.length > 0) {
            setShowSubmitNotificationButton(true);
            setIsPanelDataVerified((prev) => {
              prev["panel3"] = true;
              return prev;
            })
          }
        }
      }
      else {
        if (activeUserList.length > 0) {
          setShowSubmitNotificationButton(true);
          setIsPanelDataVerified((prev) => {
            prev["panel3"] = true;
            return prev;
          })
        }
      }


    }
  }, [expanded, activeUserList, userNotificationType, userType, emailsAndMailerGroupsList, uploadedCustomisedUserCSVFile])


  // below 3 use effect is for validating the payload and enable/disable the next button accordingly
  useEffect(() => {
    if (messageType === "Webex Card") {
      setMessageValidationFlag(false);
      setIsPanelDataVerified((prev) => {
        prev["panel1"] = false;
        return prev;
      })
      if (notificationCardPayload !== undefined && notificationCardPayload.length > 0) {
        try {
          JSON.parse(notificationCardPayload);
          setIsPanelDataVerified((prev) => {
            prev["panel1"] = true;
            prev["panel2"] = true;
            return prev;
          })
          setMessageValidationFlag(true);
        }
        catch (err) {
          setIsPanelDataVerified((prev) => {
            prev["panel1"] = false;
            return prev;
          })
          console.log(err)
        }
      }
    }

  }, [notificationCardPayload])

  useEffect(() => {
    if (messageType !== "Webex Card") {
      setMessageValidationFlag(false);
      setIsPanelDataVerified((prev) => {
        prev["panel1"] = false;
        return prev;
      })
      if (notificationMarkdownPayload !== undefined && notificationMarkdownPayload.length > 10) {
        setIsPanelDataVerified((prev) => {
          prev["panel1"] = true;
          prev["panel2"] = true;
          return prev;
        })
        setMessageValidationFlag(true);
      }
      else {
        setIsPanelDataVerified((prev) => {
          prev["panel1"] = false;
          return prev;
        })
        console.log("markdown can not be empty");
      }
    }
  }, [notificationMarkdownPayload])

  useEffect(() => {
    if (messageType !== "Webex Card") {
      setIsPanelDataVerified((prev) => {
        prev["panel1"] = false;
        return prev;
      })
      setMessageValidationFlag(false);
      if (simpleText !== undefined && simpleText.length > 10) {
        setMessageValidationFlag(true);
        setIsPanelDataVerified((prev) => {
          prev["panel1"] = true;
          prev["panel2"] = true;
          return prev;
        })
      }
      else {
        console.log("markdown can not be empty");
      }
    }
  }, [simpleText])

  const markdownToText = (markdown) => {
    return (
      <ReactMarkdown>{markdown}</ReactMarkdown>
    )
  }

  const loadNotificationHistory = () => {
    setNotificationHistoryLoadErrorMessage(undefined);
    setShowSpinner(true);

    NotificationService.listNotifications(bot["value"]).then((res) => res.json()).then((res) => {
      if (res["status"] === "success") {
	      let source = res['result']['notification_list']
	      let temp = []
	      for (let element of source)
		      temp.unshift(element)
        setNotificationHistory(temp);
        setShowSpinner(false);
      }
      else {
        setShowSpinner(false);
        setNotificationHistoryLoadErrorMessage(res["message"] || "Failed to load notification history. Please try again later");
      }

    }).catch((err) => {
      console.log(err);
      setShowSpinner(false);
      setNotificationHistoryLoadErrorMessage("Failed to load notification history. Please try again later");
    })
  }

  const validateData = () => {
    setShowSpinner(true);
    setFileValidationSuccessMessage(undefined);
    setShowFileValidationError(false);
    setFileValidationErrorMessage(undefined);
    setScheduleNotificationErrorMessage(undefined);
    if (bot === undefined || bot && bot["value"] === undefined) {
      setScheduleNotificationErrorMessage("Please select a bot to schedule notification!");
      return;
    }
    setUploadUser(false);
    NotificationService.validateFile(uploadedCustomisedUserCSVFile["data"]).then(res => res.json()).then(res => {
      if (res["user_count"] > 0) {
        setShowSpinner(false);
        setFileValidationSuccessMessage("Succesfully uploaded " + res["user_count"] + (res["user_count"] == 1 ? " user." : " users."))
      }
      else {
        setShowSpinner(false);
        setShowFileValidationError(true);
        setFileValidationErrorMessage("Please try again with 1 or more valid users.")
        setCustomisedUserCSVFile(undefined);
      }

    }).catch(err => {
      console.log(err);
      setShowSpinner(false);
      setUploadUser(false);
      setShowFileValidationError(true);
      setFileValidationErrorMessage("Invaliad file uploaded. Please try again with a valid file.");
      setCustomisedUserCSVFile(undefined);
    })
  }

  const validateJSON = () => {
    try {
      JSON.parse(notificationCardPayload);
      setValidateJSONTxt();
      setExpanded('panel2');
    } catch (e) {
      console.log(e);
      setValidateJSONTxt(e.message)
    }
  }

  const disablePastDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    const dateString = yyyy + "-" + mm + "-" + dd;
    return dateString;
  };

  const getUserCount = () => {
    setScheduleNotificationErrorMessage(undefined);
    setShowMessageBanner(false);
    setBannerMessage("");

    if (bot === undefined || bot && bot["value"] === undefined) {
      setScheduleNotificationErrorMessage("Please select a bot to schedule notification!");
      return;
    }

    setShowSpinner(true);
    let emails = [];
    let mailerGroups = [];
    let scheduleType = notificationTimeZoneType === "Local TimeZone" ? "L" : "S";
    let payload;

    //based on type of editor and message type select the payload
    if (messageType === "Markdown Text") {
      if (editorType === "Raw Editor") {
        payload = notificationMarkdownPayload;
      }
      else {
        payload = simpleText;
      }
    }
    else {
      payload = notificationCardPayload;
    }

    let fileData = undefined;
    if (userType === "Custom Users") {
      if (userNotificationType === "Customised") {
        fileData = uploadedCustomisedUserCSVFile["data"];
      }
      else {
        for (let i = 0; i < emailsAndMailerGroupsList.length; i++) {
          if (emailsAndMailerGroupsList[i]["UserType"] === "user") {
            emails.push(emailsAndMailerGroupsList[i]["UserList"])
          }
          else {
            mailerGroups.push(emailsAndMailerGroupsList[i]["UserList"])
          }
        }
      }
    }
    else {
      for (let i = 0; i < activeUserList.length; i++) {
        if (activeUserList[i]["UserType"] === "user") {
          emails.push(activeUserList[i]["UserList"])
        }
        else {
          mailerGroups.push(activeUserList[i]["UserList"])
        }
      }
    }


    NotificationService.getUserCount(emails, mailerGroups, fileData, payload, scheduleType).then(res => res.json()).then(res => {
      console.log(res);
      if (res["status"] === "success") {
        if (res["eta"]) {
          setNotificationConfirmationMessage(
            <div style={{ textAlign: "center" }}>
              <div>{`Are you sure you want to send the message to ${res["user_count"]} users.`} </div>
              <div className="base-margin-top"><b>Note:</b>{` Estimated time for completion of this request would be ${res["eta"]} minute(s).`}</div>
            </div>);
        }
        else {
          setNotificationConfirmationMessage(`Are you sure you want to send the message to ${res["user_count"]} users.`);
        }
        setShowNotificationConfirmationWindow(true);
      }
      else {
        setShowNotificationConfirmationWindow(false);
        setScheduleNotificationErrorMessage(res["message"] || "Failed to schedule notification. Please try again!");
      }

      setShowSpinner(false);
    }).catch(err => {
      console.log(err);
      setScheduleNotificationErrorMessage("Failed to schedule notification. Please try again!");
      setShowSpinner(false);
    })

  }
  const scheduleNotification = () => {
    setScheduleNotificationErrorMessage(undefined);
    if (bot === undefined || bot && bot["value"] === undefined) {
      setScheduleNotificationErrorMessage("Please select a bot to schedule notification!");
      return;
    }

    setShowSpinner(true);
    setShowNotificationConfirmationWindow(false);
    let emails = [];
    let mailerGroups = [];
    let parseMessageStringToMarkdown = false;
    let payload;
    let scheduleType = notificationTimeZoneType === "Local TimeZone" ? "L" : "S";
    // converting time stamp string to format <dd/mm/yyyy <space> hh:mm:ss>
    let dateFormatted = notificationDate.split("-")[2] + "/" + notificationDate.split("-")[1] + "/" + notificationDate.split("-")[0];
    let notificationScheduleTimeStamp = `${dateFormatted} ${notificationHour}:${notificationMinute}:00`;

    //based on type of editor and message type select the payload
    if (messageType === "Markdown Text") {
      if (editorType === "Raw Editor") {
        payload = notificationMarkdownPayload;
      }
      else {
        payload = simpleText;
      }
    }
    else {
      try {
        let cardJson = JSON.parse(notificationCardPayload);
        if ("content" in cardJson) {
          payload = JSON.stringify(cardJson);
        }
        else {
          payload = notificationCardPayload;
        }
      }
      catch (err) {
        setShowSpinner(false);
        setScheduleNotificationErrorMessage("Invalid Webex Adaptive card payload. Please try again!");
        return;
      }

      payload = notificationCardPayload;
    }


    for (let i = 0; i < emailsAndMailerGroupsList.length; i++) {
      if (emailsAndMailerGroupsList[i]["UserType"] === "user") {
        emails.push(emailsAndMailerGroupsList[i]["UserList"])
      }
      else {
        mailerGroups.push(emailsAndMailerGroupsList[i]["UserList"])
      }
    }

    let fileData = undefined;
    if (userNotificationType === "Customised") {
      fileData = uploadedCustomisedUserCSVFile["data"];
    }
    setShowSpinner(true);
    NotificationService.scheduleNotification(bot["value"], emails, mailerGroups, fileData, payload, scheduleType, messageType, notificationScheduleTimeStamp, parseMessageStringToMarkdown, loggedInUser, timeZone).then(res => res.json()).then(res => {
      console.log(res);
      if (res["status"] === "success") {
        setShowNotificationConfirmationWindow(false);
        resetNotificationPannel();
        setBannerMessage("Notification scheduled successfully!")
        setBannerMessageType("success");
        setShowMessageBanner(true);
      }
      else {
        setShowNotificationConfirmationWindow(false);
        setScheduleNotificationErrorMessage(res["message"] || "Failed to schedule notification. Please try again!");
      }

      setShowSpinner(false);
    }).catch(err => {
      console.log(err);
      setShowSpinner(false);
      setScheduleNotificationErrorMessage("Failed to schedule notification. Please try again!");
    })

  }

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

	const sortChangeHist = (method) => {
		let newArr = []
		for (let index = 0; index < notifHistData.length; index++) {
			let least = notifHistData[index][method]
			let leastIndex = index
			for (let comp = index + 1; comp < notifHistData.length; comp++) {
				if (notifHistData[comp][method] > least) {
					least = notifHistData[comp][method]
					leastIndex = comp
				}
			}
			let temp = {...notifHistData[index]}
			notifHistData[index] = notifHistData[leastIndex]
			notifHistData[leastIndex] = temp
			newArr.push(notifHistData[index])
		}
		if (method === sortMethod) {
			if (sortDirection) {
				let temp = []
				for (let element of newArr)
		      			temp.unshift(element)
				newArr = temp
			}
			setSortDirection(!sortDirection)
		} else {
			setSortDirection(true)
			setSortMethod(method)
		}
		setNotificationHistory(newArr)
	}

  const handleChangeHist = (index) => {
    setNotificationUpdationErrorMessage(undefined);
    setEditNotificationScheduleTime(undefined);
    let panel = "panel" + index;
    setNotificationContentUpdated(false);
    if (expandedHist !== "") {
      if (expandedHist === panel) {
        setExpandedHist("")
      }
      else {
        setExpandedHist(panel);
        if (notifHistData[index].attachment === "") {
          setExpandedNotificationContent(notifHistData[index].message);
        }
        else {
          setExpandedNotificationContent(JSON.stringify(JSON.parse(notifHistData[index].attachment), undefined, 4));
        }

      }

    }
    else {
      setExpandedHist(panel);
      if (notifHistData[index].attachment === "") {
        setExpandedNotificationContent(notifHistData[index].message);
      }
      else {
        setExpandedNotificationContent(JSON.stringify(JSON.parse(notifHistData[index].attachment), undefined, 4));
      }
    }

  };

  const listBot = () => {
    BotService.listBots()
      .then((res) => res.json())
      .then((res) => {
        if (res['status'] === 'success') {
          if (res['bots'].length > 0) {
            let bot_list = [];
            for (let bot of res["bots"]) {
              bot_list.push({
                label: bot["display_name"],
                value: bot["_id"],
                "skills": bot.skills,
                "trained": bot.upToDate
              });
            }
            setBotList(bot_list);
            // check local storage if bots is pre selected
            let idx = 0;
            console.log(localStorage.getItem("selectedBot"));
            // if(localStorage.getItem("selectedBot") !== undefined){
            //   for(let i=0;i<bot_list.length;i++){
            //     if(bot_list[i]["value"] === localStorage.getItem("selectedBot")){
            //       idx = i;
            //       break;
            //     }
            //   }
            // }
            // setBot(bot_list[idx]);
            if (localStorage.getItem('botSelectedName') && localStorage.getItem('botSelectedId')) {

              setBot({
                "label": localStorage.getItem('botSelectedName'),
                "value": localStorage.getItem('botSelectedId'),
              })
            }
            else {
              console.log("bot_list", bot_list)
              setBot(
                {
                  "label": bot_list[0].label,
                  "value": bot_list[0].value,
                  "skills": bot_list[0].skills,
                  "trained": bot_list[0].trained
                });
              localStorage.setItem('botSelectedName', bot_list[0].label)
              localStorage.setItem('botSelectedId', bot_list[0].value)
              localStorage.setItem('botSkills', btoa(JSON.stringify(bot_list[0].skills)))
              localStorage.setItem('botTrained', bot_list[0].trained)
            }

            // localStorage.setItem("selectedBot", bot_list[idx]["value"]);
            setShowSpinner(false);
          } else {
            setShowSpinner(false)
          }
        } else {
          console.log("Failed to retrieve bots");
          setShowSpinner(false);
          setBannerMessageType("error");
          setBannerMessage("Failed to retrieve bots!");
          setShowMessageBanner(true);
        }
      })
      .catch((err) => {
        console.log(err);
        setShowSpinner(false);
        setBannerMessageType("error");
        setBannerMessage("Failed to retrieve bots!");
        setShowMessageBanner(true);
      });

  }

  useEffect(() => {
    setShowSpinner(true);
    listBot()
    switch (tab) {
      case "scheduleNotificationTab":
        setPanelHeader("Notification Message");
        break;
      case "":
        setPanelHeader("Notification Schedule Info");
        break;
      default:
        break;
    }
  }, [tab, panelHeader]);

  function handleEditorChange(payloadType, payload) {

    if (payloadType === "Webex Card") {
      setNotificationCardPayload(payload);
    }
    else if (payloadType === "Raw Editor") {
      setNotificationMarkdownPayload(payload);
    }
    else {
      setSimpleText(payload);
    }

  }


  const BotList = () => {
    return (
      <SearchSelect
        id="botList"
        className="augmentSelect"
        defaultValue={bot}
        options={bots}
        onValueChange={(data) => {
          setBot(data);
          localStorage.setItem('botSelectedName', data.label)
          localStorage.setItem('botSelectedId', data.value)
          localStorage.setItem('botSkills', btoa(JSON.stringify(data.skills)))
          localStorage.setItem('botTrained', data.upToDate || data.trained)
        }}
      />
    );
  };


  const [uploadUser, setUploadUser] = useState(false);
  let [datalists, addDatalist] = useState([]);
  let [datalist, setDatalist] = useState('');
  const [saveBtnEnable, setSaveBtnEnable] = useState(false);
  const [bulkUserUpload, setBulkUserUpload] = useState(false);
  let [tab_selected, switchUploadTab] = useState('form-upload');
  const [disabledBtn, setDisabledBtn] = useState(true);
  const [alert, setAlert] = useState(false);
  const [genericUserListFromFile, setGenericUserListFromFile] = useState(); // this field holds list list uploaded as part of generic user option
  const [typeUser, setTypeUser] = useState("user");

  const uploadTab = (tab) => {
    setDisabledBtn(true)
    switchUploadTab(tab);
    if (tab === "form-upload" && (datalist.length > 0 || datalists.length > 0)) {
      setDisabledBtn(false)
    }
    if (genericUserListFromFile != "" && genericUserListFromFile != undefined) {
      setDisabledBtn(false)
    }
  }

  const addExpList = () => {
    if (datalist != "") {
      if (datalists.includes(datalist)) {
        setAlert(true)
      } else {
        setAlert(false)
        addDatalist((prev) => {
          let temp = [...prev];
          temp.push({ "UserType": typeUser, "UserList": datalist, "isChecked": false });
          setDatalist('');
          return temp;
        })
      }
    }
    if (datalist.length > 0 || datalists.length > 0)
      setDisabledBtn(false)
    else
      setDisabledBtn(true)
  }

  const deletelist = () => {
    addDatalist((prev) => {
      let temp = [];
      console.log(prev);
      for (let i = 0; i < prev.length; i++) {
        if (prev[i]["isChecked"] === false) {
          temp.push(prev[i]);
        }
      }
      setDisabledBtn(true);
      if (document && document.getElementById("addUserDataCheckBox")) {
        document.getElementById("addUserDataCheckBox").setAttribute("checked", false);
      }
      // do not remove the below line. It is to prevent checkbox having older value for checked
      document.getElementById("emailAndMailerCheckbox").checked = false;
      return temp;
    })
  }

  const resetNotificationPannel = () => {
    setNotificationCardPayload(sampleWxTCardsPayLoad);
    setNotificationMarkdownPayload(`Add markdown text here\n
    To know more about Webex Markdown [click here](https://help.webex.com/en-us/article/nbf0ydx/Webex-App-%7C-Format-Messages)
    `);
    setSimpleText(`Add markdown text here\n
    To know more about Webex Markdown [click here](https://help.webex.com/en-us/article/nbf0ydx/Webex-App-%7C-Format-Messages)
    `);

    setNotificationDate(() => {
      const today = new Date();
      const dd = String(today.getDate()).padStart(2, "0");
      const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      const yyyy = today.getFullYear();
      const dateString = yyyy + "-" + mm + "-" + dd;
      return dateString;
    })
    setNotificationMinute("00");
    setNotificationHour("00");
    setPanelHeader("Notification Message");
    setMessageType("Webex Card");
    setEditorType("Raw Editor");
    setNotificationTimeZoneType("Local TimeZone");
    setUserType("Custom Users");
    setUserNotificationType("Generic");
    setActiveUsersList([]);
    setExpandedHist("");
    setExpanded("");
    setEmailsAndMailerGroupsList([{
      id: 0,
      UserList: loggedInUser,
      UserType: "user"

    }])

  }
  const deleteNotificationByTimeZone = (notificationId, timeZone) => {
    setNotificationUpdationErrorMessage(undefined);
    setShowMessageBanner(false);
    setBannerMessage("");
    setShowSpinner(true);
    NotificationService.deleteNotificationByTimeZone(bot["value"], notificationId, timeZone).then(res => res.json()).then(res => {
      if (res["status"] === "success") {
        setNotificationHistory((prev) => {
          let temp = [];
          for (let i = 0; i < prev.length; i++) {
            if (prev[i].notification_id === notificationId) {
              let new_val = { ...prev[i] };
              new_val.trigger_check = [];
              for (let j = 0; j < prev[i].trigger_check.length; j++) {
                console.log(prev[i].trigger_check[j]);
                console.log(prev[i].trigger_check[j].time_zone, timeZone)
                console.log(prev[i].trigger_check[j].time_zone !== timeZone)
                if (prev[i].trigger_check[j].time_zone !== timeZone) {
                  new_val.trigger_check.push(prev[i].trigger_check[j]);
                }
              }
              if (new_val.trigger_check.length > 0) {
                temp.push(new_val);
              }
              setExpandedHist("");
            }
            else {
              temp.push(prev[i]);
            }
          }
          return temp;
        })
        setBannerMessage("Succesfully deleted the time zone " + timeZone)
        setBannerMessageType("success");
        setShowSpinner(false);
        setShowMessageBanner(true);
      }
      else {
        setShowSpinner(false);
        setNotificationUpdationErrorMessage("Failed to delete time zone. Please contact dev team!" || res["details"]);
      }


    }).catch(err => {
      console.log(err);
      setShowSpinner(false);
      setNotificationUpdationErrorMessage("Failed to delete time zone. Please contact dev team!");
    })
  }

  const updateNotification = (notificationId, type) => {
    setShowMessageBanner(false);
    setBannerMessage("");
    setShowSpinner(true);
    NotificationService.updateNotification(bot["value"], notificationId, expandedNotificationContent, type).then(res => res.json()).then(res => {
      console.log(res);
      setBannerMessage("Succesfully updated the notification!")
      setBannerMessageType("success");
      setShowMessageBanner(true);
      setShowSpinner(false);
      setNotificationContentUpdated(false);
    }).catch(err => {
      console.log(err);
      setBannerMessage("Failed to updated the notification!")
      setBannerMessageType("error");
      setShowSpinner(false);
      setShowMessageBanner(true);
    })
  }

  const updateNotificationDateTime = (index, notificationId) => {
    setShowMessageBanner(false);
    setBannerMessage("");
    setShowSpinner(true);

    let dateFormatted = updatedNotificationDate.split("-")[2] + "/" + updatedNotificationDate.split("-")[1] + "/" + updatedNotificationDate.split("-")[0];
    let notificationScheduleTimeStamp = `${dateFormatted} ${updatedNotificationHour}:${updatedNotificationMinute}:00`;

    NotificationService.updateNotificationSchedule(bot["value"], notificationId, notificationScheduleTimeStamp).then((res) => res.json()).then((res) => {
      setNotificationHistory((prev) => {
        let temp = [...prev];
        temp[index]["scheduled_at"] = notificationScheduleTimeStamp;
        return temp;
      })

      setEditNotificationScheduleTime(undefined);
      setBannerMessage("Succesfully updated the notification!")
      setBannerMessageType("success");
      setShowMessageBanner(true);
      setShowSpinner(false);
      setNotificationContentUpdated(false);
    }, (err) => {
      setEditNotificationScheduleTime(undefined);
      setBannerMessage("Failed to updated the notification!")
      setBannerMessageType("error");
      setShowMessageBanner(true);
      setShowSpinner(false);
      setNotificationContentUpdated(false);
    })




  }
  const processFileData = (data) => {
    for (let i = 0; i < data.length; i++) {
      data[i] = FileParser(data[i]["data"], data[i].type);
    }
    let idx = -1;
    for (let i = 0; i < data[0][0].length; i++) {
      if (data[0][0][i] === "EMAIL" || data[0][0][i] === "email") {
        idx = i;
        break;
      }
    }

    if (idx === -1) {
      setGenericUserListFromFile([]);
    }
    else {
      setGenericUserListFromFile(() => {
        let temp = [];
        for (let row of data[0]) {
          temp.push(row[idx].split("@")[0]);
        }
        return temp;
      })
    }


    if (data != "" || data != undefined) {
      setDisabledBtn(false)
    }
  }
  const uploadDatalist = () => {
    if (tab_selected === "form-upload") {
      console.log(datalists);
      if (userType === "Custom Users") {
        setEmailsAndMailerGroupsList((prev) => {
          let temp = [...prev];
          let idx = emailsAndMailerGroupsList.length;
          for (let i = 0; i < datalists.length; i++) {
            temp.push({
              "id": idx,
              "UserList": datalists[i]["UserList"],
              "UserType": datalists[i]["UserType"]
            })
            idx += 1;
          }
          setBulkUserUpload(false);
          addDatalist([]);
          return temp;
        })
      }
      else {
        setActiveUsersList((prev) => {
          let temp = [...prev];
          let idx = activeUserList.length;
          for (let i = 0; i < datalists.length; i++) {
            temp.push({
              "id": idx,
              "UserList": datalists[i]["UserList"],
              "UserType": datalists[i]["UserType"]
            })
            idx += 1;
          }
          setBulkUserUpload(false);
          addDatalist([]);
          return temp;
        })
      }

    }
    else {
      console.log(genericUserListFromFile)
      if (userType === "Custom Users") {
        setEmailsAndMailerGroupsList((prev) => {
          let temp = [...prev];
          console.log("before", temp);
          let idx = emailsAndMailerGroupsList.length;
          //idx 1 is column name
          for (let i = 1; i < genericUserListFromFile.length; i++) {
            temp.push({
              "id": idx,
              "UserList": genericUserListFromFile[i],
              "UserType": typeUser
            })
            idx += 1;
          }
          setBulkUserUpload(false);
          addDatalist([]);
          return temp;
        })
      }
    }

  }

  const removeUserEmailFromList = () => {
    console.log(selectedEmailAndMailerGroups);
    setEmailsAndMailerGroupsList((prev) => {
      let temp = [];
      for (let i = 0; i < prev.length; i++) {
        if (selectedEmailAndMailerGroups.indexOf(i) === -1) {
          temp.push(prev[i]);
        }
      }
      for (let i = 0; i < temp.length; i++) {
        temp[i]["id"] = i;
      }
      // to uncheck the row, its not happending by default
      document.getElementsByClassName("PrivateSwitchBase-input").item(0).click();
      console.log("before deletion", emailsAndMailerGroupsList);
      console.log("after deletion", temp);
      return temp;
    })
  }

  const dowloadTemplate = () => {
    setShowSpinner(true);
    let data = "EMAIL,VAR1,VAR2";
    const blob = new Blob([data], { type: 'application/octet-stream' });
    let fileExtensionName = 'template.csv'
    saveAs(blob, fileExtensionName);
    setShowSpinner(false);
  }

  const onNotificationContentUpdated = (newvalue, dtype) => {
    if (isNotificationContentUpdated === false) {
      setNotificationContentUpdated(true);
    }
    setExpandedNotificationContent(newvalue);
  }

  const NotificationMessagePanelContent = () => {
    return (
      <div className="base-margin">
        <div className="row base-margin-bottom">
          <div className="col ">
            <div className="row">
              <div className="marginBtom base-margin-right">Message Type:</div>
              <div className=" form-group form-group--inline">
                <div className="form-group__text">
                  <div className="btn-group btn-group--square ">
                    <button className={"radiusBtn1 radiusBtnSize btn btn--ghost " + (messageType === "Webex Card" ? " btn--secondary selected" : "")} onClick={() => {
                      setMessageType("Webex Card");
                      setMessageValidationFlag(false);
                      setIsPanelDataVerified((prev) => {
                        prev["panel1"] = false;
                        return prev;
                      })
                      try {
                        JSON.parse(notificationCardPayload);
                        setMessageValidationFlag(true);
                        setIsPanelDataVerified((prev) => {
                          prev["panel1"] = true;
                          prev["panel2"] = true;
                          return prev;
                        })
                      }
                      catch (err) {
                        console.log(err);
                      }
                    }}>
                      Webex Card
                    </button>
                    <button className={"radiusBtn2 radiusBtnSize btn btn--ghost " + (messageType === "Markdown Text" ? " btn--secondary selected" : "")} onClick={() => {
                      setMessageType("Markdown Text");
                      setMessageValidationFlag(false);
                      if (editorType === "Raw Editor") {
                        setIsPanelDataVerified((prev) => {
                          prev["panel1"] = false;
                          return prev;
                        })
                        if (notificationMarkdownPayload !== undefined && notificationMarkdownPayload.length > 10) {
                          setMessageValidationFlag(true);
                          setIsPanelDataVerified((prev) => {
                            prev["panel1"] = true;
                            prev["panel2"] = true;
                            return prev;
                          })
                        }
                      }
                      else {
                        setIsPanelDataVerified((prev) => {
                          prev["panel1"] = false;
                          return prev;
                        })
                        if (simpleText !== undefined && simpleText.length > 10) {
                          setMessageValidationFlag(true);
                          setIsPanelDataVerified((prev) => {
                            prev["panel1"] = true;
                            prev["panel2"] = true;
                            return prev;
                          })
                        }
                      }

                    }}>
                      Markdown Text
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col">
            {messageType === "Webex Card" && (<div className="row justify-content-end">
              <div className=" form-group form-group--inline">
                <div className="form-group__text">
                  <button className="btn btn--secondary" onClick={() => setShowCreateDataUriScreen(true)} >Image to Data URI</button>
                </div>
              </div>
            </div>
            )}
            {messageType === "Markdown Text" && (<div className="row">
              <div className="marginBtom base-margin-right">Editor Type:</div>
              <div className=" form-group form-group--inline">
                <div className="form-group__text">
                  <div className="btn-group btn-group--square ">
                    <button className={"radiusBtn1 radiusBtnSize btn btn--ghost " + (editorType === "Raw Editor" ? " btn--secondary selected" : "")} onClick={() => {
                      setEditorType("Raw Editor");
                      setMessageValidationFlag(false);
                      if (notificationMarkdownPayload !== undefined && notificationMarkdownPayload.length > 10) {
                        setMessageValidationFlag(true);
                      }

                    }}>
                      Raw Editor
                    </button>
                    <button className={"radiusBtn2 radiusBtnSize btn btn--ghost " + (editorType === "Simple Editor" ? " btn--secondary selected" : "")} onClick={() => {
                      setEditorType("Simple Editor");
                      setMessageValidationFlag(false);
                      if (simpleText !== undefined && simpleText.length > 10) {
                        setMessageValidationFlag(true);
                      }

                    }}>
                      Simple Editor
                    </button>
                  </div>
                </div>
              </div>
            </div>
            )}
          </div>
        </div>

        {/* <div className="row ">
          <label >
            Notification Message:
          </label>
          <textarea
            className="row"
            id="notification-message"
            rows="10"
            cols="80"
            placeholder="add Text"
          ></textarea>
        </div> */}
        <div className="row">
          {validateJSONTxt !== "" && validateJSONTxt !== undefined && <div className='warningMessage txtAlignRight' style={{ width: "100%", textAlign: "center", paddingBottom: "8px" }}>{validateJSONTxt}</div>}
          <div className="form-group base-margin-bottom" style={{ "width": "100%" }}>
            <div className="form-group__text">
              <label for="textarea-state-default"> Notification Message:</label>
            </div>
          </div>
          {messageType === "Webex Card" &&
            <Editor
              width="100%"
              height="500px"
              theme={"light"}
              language={"json"}
              value={notificationCardPayload}
              onChange={(e) => handleEditorChange("Webex Card", e)}

            />

          }
          {messageType === "Markdown Text" && editorType === "Raw Editor" &&
            <Editor
              width="100%"
              height="500px"
              theme={"light"}
              language={"markdown"}
              value={notificationMarkdownPayload}
              onChange={(e) => handleEditorChange("Raw Editor", e)}
            />
          }
          {messageType === "Markdown Text" && editorType === "Simple Editor" &&
            <div className="flex flex--center" style={{ "width": "100%" }}>
              <MdEditor style={{ height: '400px', width: '100%' }}
                value={simpleText}
                plugins={
                  ['header', 'divider', 'font-italic', 'font-bold', 'block-quote', 'list-unordered', 'list-ordered', 'link', 'clear', 'mode-toggle']
                }
                onChange={(e) => handleEditorChange("Simple Editor", e.text)} />
            </div>
          }
        </div>

        <div className="row d-flex justify-content-end base-margin-top">
          <button
            className="btn btn--secondary padding"
            disabled={!messageValidationFlag}
            onClick={() => {
              validateJSON()
            }}
          >
            Next
          </button>
        </div>
      </div >
    );
  };
  const NotificationScheduleInfoPanelContent = () => {
    return (
      <div className="base-margin">
        <div className="row d-flex justify-content-center base-margin-top">
          <div className="col-12">
            <div className="form-group form-group--horizontal">
              <div className="form-group__text">
                <label className="col-4" for="input-size-default">
                  Notification Type
                </label>
                <div className="btn-group btn-group--square btn-group--large base-margin-left">
                  <button
                    className={
                      "radiusBtn1 radiusBtnSize btn btn--ghost btn--secondary " +
                      (notificationTimeZoneType === "Local TimeZone" ? "selected" : "")
                    }
                    onClick={() => {
                      setNotificationTimeZoneType("Local TimeZone");
                    }}
                  >
                    Local TimeZone
                  </button>
                  <button
                    className={
                      "radiusBtn2 radiusBtnSize btn--ghost btn btn--secondary " +
                      (notificationTimeZoneType === "Specific TimeZone" ? "selected" : "")
                    }
                    onClick={() => {
                      setNotificationTimeZoneType("Specific TimeZone");
                    }}
                  >
                    Specific TimeZone
                  </button>
                </div>
              </div>
            </div>
            <div className="form-group form-group--horizontal">
              <div className="form-group__text">
                <label className="col-4" for="input-size-default">
                  Date, Hour and Minutes
                </label>
                <div style={{ "width": "130px", "marginRight": "2px" }} className="base-margin-left">
                  <input id="input-size-default" type="date" onChange={(e) => { setNotificationDate(e.target.value) }} min={disablePastDate()} value={notificationDate} />
                </div>
                <div style={{ "width": "50px", "marginRight": "10px" }}>
                  <select id="notification-hours" onChange={(e) => { setNotificationHour(e.target.value) }}>
                    {
                      notificationHoursOptions.map((val) => {
                        return (<option value={val}>{val}</option>)
                      })
                    }
                  </select>
                </div>

                <div style={{ "width": "50px" }}>
                  <select id="notification-minutes" onChange={(e) => { setNotificationMinute(e.target.value) }}>
                    {
                      notificationMinuteOptions.map((val) => {
                        return (<option value={val}>{val}</option>)
                      })
                    }
                  </select>
                </div>
              </div>
            </div>
            {
              notificationTimeZoneType === "Specific TimeZone" && (<div className="form-group form-group--horizontal">
                <div className="form-group__text">
                  <label className="col-4" for="input-size-default">
                    TimeZone
                  </label>
                  <div className="form-group dropdown col-7 base-margin-left">
                    <div className="form-group__text select">
                      <select id="select-schedule-timezone" onChange={(e) => { console.log(e.target.value); setTimeZone(e.target.value) }}>
                        {
                          timeZoneList.map((value) => {
                            if (value[1] === defaultTimeZone) {
                              return (
                                <option value={value[1]} selected>{value[0]}</option>
                              )
                            }
                            else {
                              return (
                                <option value={value[1]}>{value[0]}</option>
                              )
                            }
                          })
                        }
                      </select>
                    </div>
                  </div>
                </div>
              </div>)
            }



          </div>
        </div>
        <div className="row d-flex justify-content-end base-margin-top">
          <button
            className="btn btn--secondary "
            onClick={() => {
              setExpanded('panel1')
            }}
          >
            Back
          </button>
          <button
            className="btn btn--secondary "
            onClick={() => {
              setExpanded('panel3')
            }}
          >
            Next
          </button>
        </div>
      </div>
    );
  };

  const colData = [
    {
      field: 'UserList',
      headerName: 'User List',
      flex: 1,
      editable: false,
    }, {
      field: 'UserType',
      headerName: 'User Type',
      flex: 0.3,
      editable: false,
    }
  ]


  const NotificationUserInfoPanelContent = () => {
    return (
      <div className="base-margin">
        <div className="row d-flex justify-content-center base-margin-top">
          <div className="col-12">
            <div className="form-group form-group--horizontal">
              <div className="form-group__text">
                <label className="col-4" for="input-size-default">
                  User Type
                </label>
                <div className="btn-group btn-group--square btn-group--large base-margin-left">
                  <button
                    className={
                      "radiusBtn1 radiusBtnSize btn btn--ghost btn--secondary " +
                      (userType === "Active Users" ? "selected" : "")
                    }
                    onClick={() => {
                      //get list of active user from API based on bot id
                      NotificationService.getActiveUsers(bot["value"]).then(res => res.json()).then(res => {
                        console.log(res);
                        setActiveUsersList(() => {
                          let temp = [];
                          for (let i = 0; i < res["active_users"].length; i++) {
                            temp.push({
                              id: i,
                              UserList: res["active_users"][i],
                              UserType: "User"
                            })
                          }
                          return temp;
                        })
                      }).catch(err => {
                        console.log(err);
                      })
                      setUserType("Active Users");
                    }}
                  >
                    Active Users
                  </button>
                  <button
                    className={
                      "radiusBtn2 radiusBtnSize btn--ghost btn btn--secondary " +
                      (userType === "Custom Users" ? "selected" : "")
                    }
                    onClick={() => {
                      setUserType("Custom Users");
                    }}
                  >
                    Custom Users
                  </button>
                </div>
              </div>
            </div>
            {userType === "Custom Users" ?
              <div className="customUserBox form-group form-group--horizontal">
                <div className="form-group__text">
                  <label className="col-4" for="input-size-default">
                    Notification Type
                  </label>
                  <div className="btn-group btn-group--square btn-group--large base-margin-left">
                    <button
                      className={
                        "radiusBtn1 radiusBtnSize btn btn--ghost btn--secondary " +
                        (userNotificationType === "Customised" ? "selected" : "")
                      }
                      onClick={() => {
                        setUserNotificationType("Customised");
                      }}
                    >
                      Customised
                    </button>
                    <button
                      className={
                        "radiusBtn2 radiusBtnSize btn--ghost btn btn--secondary " +
                        (userNotificationType === "Generic" ? "selected" : "")
                      }
                      onClick={() => {
                        setUserNotificationType("Generic");
                      }}
                    >
                      Generic
                    </button>
                  </div>
                </div>
                {userNotificationType === "Generic" ?
                  <div className="base-margin-top">
                    <div className="form-group form-group--horizontal">
                      <div className="form-group__text" style={{ position: "relative" }}>
                        <div className="addAndDeleteUser-buttonWrapper">
                          <button className="btn btn--secondary" onClick={() => { setBulkUserUpload(true); }} > Add User(s) </button>
                          <button className="btn btn--secondary" onClick={() => { removeUserEmailFromList() }} > Delete User(s) </button>
                        </div>
                        <DataGridComponent noToolbar={true} rows={emailsAndMailerGroupsList} columns={colData} rowHeight={50} ht={250} checkbox={true} disableSelection={false} setSelectedExpression={setSelectedEmailAndMailerGroups} />
                      </div>
                    </div>
                  </div> :
                  <>
                    <div className="row d-flex justify-content-center base-margin-top">
                      <button className="btn btn--secondary" onClick={() => { setUploadUser(true); setSaveBtnEnable(false); }} >Upload Users  </button>
                      <button className="btn btn--secondary" onClick={() => { dowloadTemplate() }}>Download Template </button>
                    </div>
                    {fileValidationSuccessMessage !== undefined && <div className="row d-flex justify-content-center base-margin-top" style={{ fontSize: "18px" }}>{fileValidationSuccessMessage}</div>}
                  </>

                }
              </div> :
              <div className="form-group form-group--horizontal">
                <div className="form-group__text" style={{ position: "relative" }}>
                  <div className="addAndDeleteUser-buttonWrapper">
                    <button className="btn btn--secondary addUserEmail" onClick={() => { setBulkUserUpload(true); }} > Add User(s) </button>
                    <button className="btn btn--secondary addUserEmail" onClick={() => { removeUserEmailFromList() }} > Delete User(s) </button>
                  </div>
                  <DataGridComponent noToolbar={true} rows={activeUserList} columns={colData} rowHeight={50} ht={250} checkbox={true} disableSelection={false} setSelectedExpression={setSelectedEmailAndMailerGroups} />
                </div>
              </div>
            }


          </div>
        </div>
        <div className="row d-flex justify-content-end base-margin-top">
          <button
            className="btn btn--secondary "
            onClick={() => {
              setExpanded('panel2')
            }}
          >
            Back
          </button>
          <button
            className="btn btn--secondary"
            disabled={!showSubmitNotificationButton}
            onClick={() => {
              getUserCount();
            }}
          >
            Submit
          </button>
        </div>

        <Modal show={bulkUserUpload} onHide={() => { }} animation={false} size='lg' scrollable={true} fullscreen={'sm-down'}>
          <Modal.Header>
            {/* <Modal.Title>Add User/Email</Modal.Title> */}
            <h6>Add User(s)</h6>
            <span className='icon-close icon-size-24' onClick={() => { setBulkUserUpload(false); setGenericUserListFromFile(); setDisabledBtn(true); addDatalist([]) }}></span>
          </Modal.Header>
          <Modal.Body>
            <div>
              <ul className="tabs tabs--justified" style={{ "height": "0%" }}>
                <li id="static-entity-tab" className={tab_selected == "form-upload" ? "active" : "" + "tab"} onClick={() => { uploadTab("form-upload") }}>
                  <a tabindex="0">Static</a>
                </li>
                <li id="api-entity-tab" className={tab_selected == "file-upload" ? "active" : "" + "tab"} onClick={() => { uploadTab("file-upload") }}>
                  <a tabindex="0">File Upload</a>
                </li>
              </ul>
              <div className="row">
                <div className="col-10">
                  <div className="tab-content" >
                    <div className={tab_selected == "form-upload" ? "active" : "" + "panel panel--raised tab-pane"}>
                      <div className="panel panel--raised">
                        <div className="row" style={{ "marginLeft": "0px" }}>
                          <div className="col-10 col-sm-10 col-md-11 col-lg-11 col-xl-11 col-xxl-12 col-xxxl-11 form-group">
                            <div className="form-group__text">
                              <input id="input-type-email" type="text" autoComplete="off" value={datalist} onChange={(e) => { setDatalist(e.target.value) }} hint={typeUser === "user" ? "Enter user's CEC without @cisco.com" : "If mailer is abcd@cisco.com, then only enter acb"} onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                  addExpList()
                                }
                              }} />
                              <label htmlFor="input-type-email">{typeUser === "user" ? "User's CEC ID" : "Mailer"}</label>
                            </div>
                          </div>
                          <div className="col-2 col-sm-2 col-md-1 col-lg-1 col-xl-1 col-xxl-1 col-xxxl-1">
                            <span className="icon-add-outline icon-size-20 cursorPointer" style={{ "marginTop": "25px" }} onClick={() => { addExpList() }}></span>
                          </div>
                          {alert && <div className="expAlert">datalist already exist</div>}
                        </div>
                      </div>
                      {datalists.length > 0 && <hr></hr>}
                      {datalists.length > 0 && <div className="panel panel--raised">
                        <table className="table table--bordered" style={{ "width": "100%", "height": "50px", "overflow": "auto" }}>
                          <thead style={{ "width": "100%", "background": "#00bceb60", "color": "#1e4471", "fontWeight": "700", "height": "30px" }}>
                            <tr style={{ "width": "100%", }}>
                              <td style={{ "width": "95%" }}>{"Text"}</td>
                              <td style={{ "width": "95%" }}>{"Type"}</td>
                              <td style={{ "width": "5%" }}><span className="icon-delete icon-size-20 cursorPointer" onClick={() => { deletelist() }} /></td>
                            </tr>
                          </thead>
                          <tbody style={{ "height": "calc(100% - 35px)" }}>
                            {
                              datalists.map((value, index) => {
                                return (
                                  <tr key={index}>
                                    <td>{value["UserList"]}</td>
                                    <td>{value["UserType"]}</td>
                                    <td>
                                      <input id='emailAndMailerCheckbox' type="checkbox" onClick={() =>
                                        addDatalist((prev) => {
                                          let temp = [...prev];
                                          temp[index]["isChecked"] = !temp[index]["isChecked"];
                                          return temp;
                                        })
                                      } checked={value["isChecked"]} />
                                      <span className="checkbox__input"></span>
                                    </td>
                                  </tr>
                                )
                              })
                            }

                          </tbody>
                        </table>

                      </div>}
                    </div>
                    <div className={tab_selected == "file-upload" ? "active filedata" : "" + "panel panel--raised tab-pane"}>
                      <FileDropZone onFileuploaded={(data) => processFileData(data)}></FileDropZone>
                    </div>
                  </div>
                </div>
                <div className="col-2" style={{ paddingTop: "15px" }}>
                  <div className="flexbox"><input type="radio" name="typeuser" checked={typeUser === "user" ? true : false} onClick={() => { setTypeUser("user") }} /><span>User</span></div>
                  <div className="flexbox"><input type="radio" name="typeuser" checked={typeUser === "mailer" ? true : false} onClick={() => { setTypeUser("mailer") }} /><span>Mailer</span></div>
                </div>
              </div>
            </div>

          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn--secondary" onClick={uploadDatalist} disabled={disabledBtn}>Save</button>
            {/* <button className="btn btn--secondary" onClick={() => { setBulkUserUpload(false); setGenericUserListFromFile(); setDisabledBtn(true); addDatalist([]) }}>Close</button> */}
          </Modal.Footer>
        </Modal>

        <Modal size="lg" show={uploadUser} animation={false}>
          <Modal.Header>
            <h6>Upload Users</h6>
            <span className='icon-close icon-size-24' onClick={() => { setUploadUser(false) }}></span>
          </Modal.Header>
          <Modal.Body>
            <div className="popbodybox">
              {showFileValidationError !== undefined &&
                <div style={{ "color": "red" }}>
                  {fileValidationErrorMessage}
                </div>
              }

              <FileDropZone allowedFileTypes={["text/csv"]} onFileuploaded={(data) => { setCustomisedUserCSVFile(data[0]); setSaveBtnEnable(true) }}></FileDropZone>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {/* <button className="btn btn--secondary" onClick={() => { setUploadUser(false) }}>
              Close
              {/* <span className="icon-close icon-size-18 base-margin-left"></span>
            </button> */}
            <button className="btn btn--secondary" disabled={!saveBtnEnable} onClick={() => { validateData() }}>
              Submit
              {/* <span className="icon-add-contain icon-size-18 base-margin-left"></span> */}
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  };


  const renderAccordionContent = () => {
    return (
      <div className="pageTour3">
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" isVerified={isPanelDataVerfied["panel1"]}>
            <label>Notification Message</label>
          </AccordionSummary>

          <AccordionDetails>
            {NotificationMessagePanelContent()}
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
        >
          <AccordionSummary aria-controls="panel2d-content" id="panel2d-header" isVerified={isPanelDataVerfied["panel2"]}>
            <label>Notification Schedule Info</label>
          </AccordionSummary>
          <AccordionDetails>
            {NotificationScheduleInfoPanelContent()}
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
        >
          <AccordionSummary aria-controls="panel3d-content" id="panel3d-header" isVerified={isPanelDataVerfied["panel3"]}>
            <label>Notification User Info </label>
          </AccordionSummary>
          <AccordionDetails>
            {NotificationUserInfoPanelContent()}
          </AccordionDetails>
        </Accordion>
      </div>
    );
  };

  const deleteNotification = (index, notificationId) => {
    setBannerMessage(undefined);
    setShowMessageBanner(false);
    setShowSpinner(true);

    NotificationService.deleteNotification(bot["value"], notificationId).then((res) => res.json()).then((res) => {
      if (res["status"] === "success") {
        setNotificationHistory((prev) => {
          let temp = [];
          for (let i = 0; i < prev.length; i++) {
            if (i !== index) {
              temp.push(prev[i]);
            }
          }
          setBannerMessage("Deleted notification " + notificationId + " successfully.");
          setShowMessageBanner(true);
          setShowSpinner(false);
          return temp;
        })
      }
      else {
        setNotificationUpdationErrorMessage(res["message"] || "Failed to delete notification " + notificationId);
        setShowSpinner(false);
      }
    }, (err) => {
      setNotificationUpdationErrorMessage("Failed to delete notification " + notificationId);
      setShowSpinner(false);
    })
  }

  const notificationHistory = () => {
    return <div className="panel" style={{ "height": "100%", "padding": "0px" }}>
      {notificationHistoryLoadErrorMessage !== undefined && <div>{notificationHistoryLoadErrorMessage}</div>}
      <div style={{ "height": "100%" }}>
        <span className="row col-md-12 base-padding" style={{ border: '1px solid #ced4da', marginLeft: '0' }}>
          <span className="col" style={{ textAlign: "center" }}>
            <b>Notification ID</b>
          </span>
          <span className="col" style={{ textAlign: "center" }}>
            <b>Created By</b>
          </span>
          <span className="col" style={{ textAlign: "center" }} onClick={() => {sortChangeHist('created_at')}}> 
            <b>Created At </b>
		{sortMethod === 'created_at' && sortDirection && <span className='icon-chevron-down'></span>}
		{sortMethod === 'created_at' && !sortDirection && <span className='icon-chevron-up'></span>}
          </span>
          <span className="col" style={{ textAlign: "center" }} onClick={() => {sortChangeHist('scheduled_at')}}>
            <b>Scheduled At </b>
		{sortMethod === 'scheduled_at' && sortDirection && <span className='icon-chevron-down'></span>}
	  	{sortMethod === 'scheduled_at' && !sortDirection && <span className='icon-chevron-up'></span>}
          </span>
          <span className="col" style={{ textAlign: "center" }}>
            <b>Total Count</b>
          </span>
          <span className="col" style={{ textAlign: "center" }}>
            <b>Status</b>
          </span>

        </span>
        <div style={{ "height": "calc(100% - 55px)", "overflowY": "auto" }}>
          {notifHistData.map((data, index) => {
            let timeZoneNameSuffix = "";
            if(data.notification_type != 'S'){
              timeZoneNameSuffix = "(in UTC)"
            }
            let createdAtFormatDate = dateTimeFormatChange(TimezoneConverter(data.created_at))
            let updatedAtFormatDate = dateTimeFormatChange(TimezoneConverter(data.updated_at))
            let scheduledAtFormatDate = dateTimeFormatChange(data.scheduled_at)
            if(data.notification_type != 'S'){
              scheduledAtFormatDate =  dateTimeFormatChange(TimezoneConverter(data.scheduled_at))
            }
            return <Accordion
              expanded={expandedHist === "panel" + index} style={{ border: (expandedHist === "panel" + index ? " solid 1px #00bceb" : "") }}
            >
              <AccordionSummaryHist id={"panel" + index + "1d-header"} onClick={() => handleChangeHist(index)}>
                <span className="row col-md-12  base-padding-left  base-padding-right">
                  <span className="col" style={{ textAlign: "center" }}>
                    {data.notification_id}
                  </span>
                  <span className="col" style={{ textAlign: "center", transform: "translateX(-1%)" }}>
                    {data.created_by}
                  </span>
                  <span className="col" style={{ textAlign: "center", transform: "translateX(6%)" }}>
                    {createdAtFormatDate}
                  </span>
                  <span className="col" style={{ textAlign: "center", transform: "translateX(6%)" }}>
                    {scheduledAtFormatDate}
                  </span>
                  <span className="col" style={{ textAlign: "center", transform: "translateX(6%)" }}>
                    {data.total_user_count}
                  </span>
                  <span className="col" style={{ textAlign: "center", transform: "translateX(calc(6% + 10px))" }}>
                    {data.status}
                  </span>

                </span>
              </AccordionSummaryHist>
              <AccordionDetails>
                {notificationUpdationErrorMessage != undefined && <div className="base-margin-bottom base-margin-left" style={{ "color": "red" }}>{notificationUpdationErrorMessage}</div>}
                <div className="panel responsive-table">
                  <table className="table table--bordered table--loose base-margin-bottom">
                    <tr>
                      <td>Updated At</td>
                      <td>{updatedAtFormatDate}</td>
                    </tr>
                    <tr>
                      <td>Scheduled At {timeZoneNameSuffix}</td>
                      <td>
                        {(() => {
                          if (editNotificationScheduleTime === index) {
                            return (
                              <div className="form-group form-group--horizontal">
                                <div className="form-group__text" style={{ marginLeft: "-16px" }}>
                                  <div style={{ "width": "130px", "marginRight": "2px" }} className="base-margin-left">
                                    <input id="input-size-default" type="date" onChange={(e) => { setUpdatedNotificationDate(e.target.value) }} min={disablePastDate()} value={updatedNotificationDate} />
                                  </div>
                                  <div style={{ "width": "50px", "marginRight": "10px" }}>
                                    <select id="notification-hours" onChange={(e) => { setUpdatedNotificationHour(e.target.value) }} value={updatedNotificationHour}>
                                      {
                                        notificationHoursOptions.map((val) => {
                                          return (<option value={val}>{val}</option>)
                                        })
                                      }
                                    </select>
                                  </div>
                                  <div style={{ "width": "50px" }}>
                                    <select id="notification-minutes" onChange={(e) => { setUpdatedNotificationMinute(e.target.value) }} value={updatedNotificationMinute}>
                                      {
                                        notificationMinuteOptions.map((val) => {
                                          return (<option value={val}>{val}</option>)
                                        })
                                      }
                                    </select>
                                  </div>
                                  <div>
                                    <button className="btn btn--secondary base-margin-left" onClick={() => { setEditNotificationScheduleTime(undefined) }}>Cancel</button>
                                    <button className="btn btn--secondary base-margin-left" onClick={() => { updateNotificationDateTime(index, data.notification_id) }}>Save</button>
                                  </div>
                                </div>
                              </div>
                            )
                          }
                          else {
                            return scheduledAtFormatDate;
                          }

                        })()}
                        {
                          editNotificationScheduleTime !== index && data.status === "submitted" && (
                            <button className="btn btn--secondary base-margin-left" onClick={() => {
                              setEditNotificationScheduleTime(index);
                              let scheduleAt = data.scheduled_at;
                              console.log(scheduleAt.split(" ")[1].split(":")[0], scheduleAt.split(" ")[1].split(":")[1]);
                              setUpdatedNotificationDate(scheduleAt.split(" ")[0]);
                              setUpdatedNotificationHour(scheduleAt.split(" ")[1].split(":")[0]);
                              setUpdatedNotificationMinute(scheduleAt.split(" ")[1].split(":")[1]);
                            }}>Edit</button>
                          )
                        }

                      </td>
                    </tr>
                    <tr>
                      <td>Success User count</td>
                      <td>{data.success_user_count}</td>
                    </tr>
                    <tr>
                      <td>Failed User count</td>
                      <td>{data.failure_count}</td>
                    </tr>
                    {data.notification_type == 'S' && <tr>
                      <td>Time Zone</td>
                      <td>{data.trigger_check[0].time_zone}</td>
                    </tr>}

                  </table>

                  {data.attachment === '' && <hr />}
                  {data.attachment === '' && <div>
                    <b>Message Content</b>
                  </div>}
                  {data.attachment === '' && <AceEditor
                    mode="markdown"
                    theme="dawn"
                    width="100%"
                    height="300px"
                    onChange={(data) => onNotificationContentUpdated(data, "markdown")}
                    value={expandedNotificationContent} />}

                  {data.attachment != '' && <hr />}
                  {data.attachment != '' && <div>
                    <b>WebEx Adaptive Card Content</b>
                  </div>}
                  {data.attachment != '' && <AceEditor
                    mode="json"
                    theme="dawn"
                    width="100%"
                    height="500px"
                    onChange={(data) => onNotificationContentUpdated(data, "card")}
                    value={expandedNotificationContent} />}
                  {data.notification_type != 'S' && <hr />}
                  {data.notification_type != 'S' && <span>
                    <div>
                      <b>Time Zone Data</b>
                    </div>
                    <div className="panel responsive-table">
                      <table className="table table--bordered table--loose base-margin-bottom">
                        <thead>
                          <th>Time zone</th>
                          <th>User count</th>
                          <th>Scheduled At (UTC +00:00)</th>
                          {
                            data.status === "submitted" && <th>Delete</th>
                          }
                          
                        </thead>
                        {data.trigger_check.map(tz => {
                          let scheduledDateFormat = dateTimeFormatChange(tz.UTC_value)
                          return <tr>
                            <td>{tz.time_zone}</td>
                            <td>{tz.number_of_users}</td>
                            <td>{scheduledDateFormat}</td>
                            {
                              data.status === "submitted" &&  <td>
                                <button className="btn btn--secondary" onClick={() => { deleteNotificationByTimeZone(data.notification_id, tz.time_zone) }}>Delete</button>
                              </td>
                            }

                          </tr>
                        })}
                      </table>
                    </div>

                  </span>}
                </div>
                <div style={{ "textAlign": "right", "paddingRight": "16px" }}>
                  {
                    data.status === "submitted" && (
                      <button className="btn btn--secondary" onClick={() => { deleteNotification(index, data.notification_id) }}>Delete</button>
                    )
                  }
                  {
                    isNotificationContentUpdated && (
                      <button className="btn btn--secondary" onClick={() => { updateNotification(data.notification_id, data.attachment === '' ? "message" : "webex") }}>Update</button>
                    )
                  }
                </div>
              </AccordionDetails>
            </Accordion>
          })}
        </div>


      </div>
    </div>
  }

  let hostConfig = {
    fontFamily: "Segoe UI, Helvetica Neue, sans-serif"
  };



  const viewPreview = () => {
    let previewPayload;

    if (messageType === "Markdown Text") {
      if (editorType === "Raw Editor") {
        previewPayload = <span className="messageTxt pageTour4">{markdownToText(notificationMarkdownPayload)}</span>;
      }
      else {
        previewPayload = <span className="messageTxt pageTour4">{markdownToText(simpleText)}</span>;
      }
    }
    else {
      previewPayload = <span className="messageTxt pageTour4"><AdaptiveCard payload={(() => {
        try {
          let jsonPayload = JSON.parse(notificationCardPayload);
          if ("content" in jsonPayload) {
            return jsonPayload["content"]
          }
          return jsonPayload;
        }
        catch (e) {
          console.log(e)
          return {};
        }

      })()} hostConfig={hostConfig} /></span>;
    }

    return <div className="panel" style={{ height: "calc(100% - 32px)" }}>
      <div style={{ "height": "28px" }}>
        <span className="botname"><h5>Notification Preview</h5></span>
      </div>
      <hr></hr>
      <div>
        <img src={process.env.PUBLIC_URL + "/images/notification.png"} style={{ "height": "73vh", width: "calc(100% - 16px)" }} />
        {previewPayload}
      </div>


    </div>
  }

  const [{ run, steps }, setState] = useState({
    run: true,
    steps: [
      {
        content: <h6>Welcome to <b>Schedule Notification Screen</b>. Here, you can schedule notifications and also view notification history.</h6>,
        locale: { skip: <span aria-label="skip">Skip</span> },
        placement: 'center',
        target: '.pageTour1',
      },
      {
        content: <h6>Here, you can select bot from this dropdown.</h6>,
        floaterProps: {
          disableAnimation: true,
        },
        spotlightPadding: 20,
        target: '.pageTour2',
      },
      {
        content: <h6>Here, you can fill the details to schedule notification.</h6>,
        floaterProps: {
          disableAnimation: true,
        },
        spotlightPadding: 20,
        target: '.pageTour3',
      },
      {
        content: <h6>Here, you can preview the WebEx card/markdown text that would be sent to user.</h6>,
        floaterProps: {
          disableAnimation: true,
        },
        spotlightPadding: 20,
        target: '.pageTour4',
      },
      {
        content: <h6>On click of the <b>Notification History</b> button, history of notifications sent can be seen.</h6>,
        floaterProps: {
          disableAnimation: true,
        },
        spotlightPadding: 10,
        target: '.pageTour5',
      }
    ]
  });

  const processImageData = (data) => {
    let base64String = "";
    let file = data[0];
    if (file.size > 1000000) {
      setShowValidationErrorMessage(true);
      setDataValidationErrorMessage('File size is too large. Max file size limit is 1 MB.');
      setDataUriUploaded(false);
      return false;
    } else {
      setDataUriUploaded(true);
      setShowValidationErrorMessage(false);
      let reader = new FileReader();
      reader.onloadend = function () {
        base64String = reader.result;
        console.log(base64String);
        let output = document.getElementById('output').value = base64String;
      }
      reader.readAsDataURL(file);
    }
  }

  const copyData = () => {
    var copyText = document.getElementById("output");
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    navigator.clipboard.writeText(copyText.value);
  }

  const imageToDataUri = () => {
    return <Modal size="lg" show={showCreateDataUriScreen} animation={false}>
      <Modal.Header>
        <h5>Image to Data URI Converter</h5>
        <span className='icon-close icon-size-24' onClick={() => { setShowCreateDataUriScreen(false); }}></span>
      </Modal.Header>
      <Modal.Body>
        {
          showValidationErrorMessage && (<MessageBanner message={dataValidationErrorMessage} messageType={"error"} onCloseClicked={() => { setShowValidationErrorMessage(false) }}></MessageBanner>)
        }
        <div style={{ "position": "absolute", "right": "16px", "top": "16px" }}>
          <button className="btn btn--dark" onClick={() => { setShowDataUriHelp((prev) => !prev) }}>Help</button>
        </div>

        <FileDropZone style={{ marginTop: "50px" }} type="file" onFileuploaded={(data) => processImageData(data)} allowedFileTypes={[".jpeg/.png", "image/jpeg", "image/png"]}></FileDropZone>

        <br></br>
        {showDataUriHelp && <MessageBanner>

          <div style={{ marginBottom: "20px" }}><h5>How to use Image to Data URI converter: </h5></div>

          <div style={{ marginLeft: "-8px" }}>This converter can be used to include images in Adapative Cards. First, click the above area to browse for an image file or drag and drop the image into the zone. After the image is inputted, the Data URI text box will populate with the corresponding Data URI for the selected image. This converter only accepts .png or .jpeg images which are under 1 MB.</div>
          <br></br>
        </MessageBanner>
        }

        <div className="panel panel--raised base-margin-top" style={{ marginBottom: "5%" }}>
          <div className="row" style={{ "marginLeft": "0px" }}>
            <div className="col-10 col-sm-10 col-md-11 col-lg-11 col-xl-11 col-xxl-12 col-xxxl-11 form-group base-margin-bottom">
              <div className="form-group__text">
                <input id="output" type="text" name="Data URI" autoComplete='off' value="" readonly />
                <h6 htmlFor="input-type-email">Data URI</h6>
              </div>
              <div className="copyDataButton">
                <button className='btn btn--primary text-center-xs-down text-center-sm-down text-center-md-down text-center-lg-down text-center-xl-down text-center-xxl-down text-center-xxxl-down base-margin-left' onClick={() => { copyData(false) }} style={{ justifyContent: "center", paddingLeft: "16px", paddingRight: "16px", marginTop: "2%" }} disabled={dataUriUploaded === false}>
                  <span>Copy Data URI to Clipboard</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn--dark" onClick={() => { setShowCreateDataUriScreen(false) }}>Close</button>
      </Modal.Footer>
    </Modal>
  }

  const handleJoyrideCallback = (data) => {
    const { status, type } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setState({ run: false });
    }
  };
  return (
    <div ref={refWidth} style={{ "height": "calc(100% - 16px)", "marginLeft": "-8px" }}>
      {showMessageBanner && <MessageBanner message={bannerMessage} messageType={bannerMessageType} onCloseClicked={() => { setShowMessageBanner(false); }}></MessageBanner>}
      {notificationHistoryLoadErrorMessage !== undefined && <MessageBanner message={notificationHistoryLoadErrorMessage} messageType={"error"} onCloseClicked={() => { setNotificationHistoryLoadErrorMessage(undefined) }}></MessageBanner>}
      {fileValidationErrorMessage !== undefined && <MessageBanner message={fileValidationErrorMessage} messageType={"error"} onCloseClicked={() => { setFileValidationErrorMessage(undefined) }}></MessageBanner>}
      {scheduleNotificationErrorMessage !== undefined && <MessageBanner message={scheduleNotificationErrorMessage} messageType={"error"} onCloseClicked={() => { setScheduleNotificationErrorMessage(undefined) }}></MessageBanner>}
      {notificationUpdationErrorMessage !== undefined && <MessageBanner message={notificationUpdationErrorMessage} messageType={"error"} onCloseClicked={() => { setNotificationUpdationErrorMessage(undefined) }}></MessageBanner>}

      {showSpinner != true && isBotIntegratedWithWebex ? <Joyride
        callback={handleJoyrideCallback}
        continuous
        hideCloseButton
        run={run}
        scrollToFirstStep
        showProgress
        showSkipButton
        steps={steps}
        styles={{
          options: {
            zIndex: 10000,
          },
          buttonNext: {
            background: '#1e4471',
            border: '1px solid #1e4471',
            borderRadius: '5px'
          },
          buttonBack: {
            color: '#1e4471',
            border: '1px solid #1e4471',
            borderRadius: '5px'
          },
          buttonSkip: {
            color: '#1e4471',
            border: '1px solid #1e4471',
            borderRadius: '5px'
          },
        }}
      /> : ""}
      {(showSpinner || isTimeZoneLoading) && <Spinner></Spinner>}
      <Modal size="lg" show={scheduleNotificationErrorMessage !== undefined} animation={false} centered>
        <Modal.Body>
          <div className="popbodybox">
            <div style={{ "color": "red" }}><h6>{scheduleNotificationErrorMessage}</h6></div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn--secondary" onClick={() => { setScheduleNotificationErrorMessage(undefined) }}>
            Close
            {/* <span className="icon-close icon-size-18 base-margin-left"></span> */}
          </button>
        </Modal.Footer>
      </Modal>

      <div style={{ "height": "100%" }}>
        <div
          className="row base-margin pageTour1"
          style={{ borderBottom: "1px solid #ced4da" }}
        >
          <h5 className="col-6 col-md-6 col-sm-6 col-lg-6 col-xl-6 col-xxl-6 col-xxxl-6">
            Notification
          </h5>
          <div
            className="col-4 col-md-4 col-sm-4 col-lg-4 col-xl-4 col-xxl-4 col-xxxl-4 pageTour2"
            style={{ marginBottom: "15px" }}>
            {BotList()}
          </div>
          {isBotIntegratedWithWebex && <div className="col-2 col-md-2 col-sm-2 col-lg-2 col-xl-2 col-xxl-2 col-xxxl-2 pageTour5" style={{ "textAlign": "right" }}>
            {tab == 'notificationHistoryTab' && <button className="btn btn--secondary" onClick={() => { setTab("scheduleNotificationTab") }} style={{ "marginRight": "-16px" }}>Schedule Notification</button>}
            {tab == 'scheduleNotificationTab' && <button className="btn btn--secondary" onClick={() => { setTab('notificationHistoryTab'); loadNotificationHistory() }} style={{ "marginRight": "-16px" }}>Notification History</button>}
          </div>}
        </div>
        {
          (() => {
            if (isBotIntegratedWithWebex) {
              return (
                <div style={{ "height": "calc(100% - 53px)" }}>
                  {/* <ul id="hcenter" className="tabs tabs--justified">
                    <li id="hcenter-1" className={`tab ${tab == '1' ? 'active' : null}`} onClick={() => setTab(1)}>
                      <a tabindex="0">Send Notification</a>
                    </li>
                    <li id="hcenter-2" className={`tab pageTour4 ${tab == '2' ? 'active' : null}`} onClick={() => setTab(2)}>
                      <a tabindex="0">Notification History</a>
                    </li>
                  </ul> */}
                  <div id="hcenter-content" className="tab-content center" style={{ "height": "100%", "width": "100%", "margin": "8px" }}>
                    <div id="hcenter-1-content" className={`tab-pane  ${tab == 'scheduleNotificationTab' ? 'active' : null}`}>
                      <SplitScreenWrapper isSplitScreenEnabled={true} leftPanelElement={<div className="panel" >{renderAccordionContent()}</div>} rightPanelElement={<div>{viewPreview()}</div>} />
                    </div>
                    <div id="hcenter-1-content" className={`tab-pane ${tab == 'notificationHistoryTab' ? 'active' : null}`} style={{ "height": "100%", "width": "100%", "margin": "8px" }}>
                      <div className="panel" style={{ "height": "100%" }}>{notificationHistory()}</div>
                    </div>
                  </div>
                  {imageToDataUri()}
                </div>
              )
            }
            else {
              return (
                <MessageBanner message={"Please integrate the bot with webex to use notification feature"} messageType={"info"}></MessageBanner>
              )
            }

          })()

        }

      </div>

      <Modal size="lg" show={showNotificationConfirmationWindow} animation={false} centered>
        <Modal.Header>
          <h6>Confirm and Schedule Notification</h6>
          <span className='icon-close icon-size-24' onClick={() => { setShowNotificationConfirmationWindow(false) }}></span>
        </Modal.Header>
        <Modal.Body>
          <div className="popbodybox">
            <div>{notificationConfirmationMessage}</div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {/* <button className="btn btn--secondary" onClick={() => { setShowNotificationConfirmationWindow(false) }}>
            Cancel
            <span className="icon-close icon-size-18 base-margin-left"></span>
          </button> */}
          <button className="btn btn--secondary" onClick={() => { scheduleNotification() }}>
            Confirm
            {/* <span className="icon-check icon-size-18 base-margin-left"></span> */}
          </button>
        </Modal.Footer>
      </Modal>

    </div >
  );
};

export default NotificationView;
