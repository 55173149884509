import './ClearContextNode.css';

import { useEffect, useState } from 'react';
import CheckUncheck from '../ActionNode/Checklist';
import isAlphanumeric from 'validator/lib/isAlphanumeric';
import MessageBanner from '../../../../../../custom-components/MessageBanner/MessageBanner';

function ClearContextNodeDetails({ node, closeFunction }) {
    const [checkedAll, setCheckedAll] = useState(false)
    const [forceReRender, setForceReRender] = useState(false)
    const [custom, setCustom] = useState('')
    const [showValidationErrorMessage, setShowValidationErrorMessage] = useState(false)
    const [dataValidationErrorMessage, setDataValidationErrorMessage] = useState('')
    const closeSplit = () => { closeFunction() }
    console.log(node.data)
    useEffect(() => {
        for (let element of node.data.entities)
            if (element.isChecked === false) {
                setCheckedAll(false)
                return
            }
        setCheckedAll(true)
    }, [node])

    const changeFunction = index => {
        setForceReRender(!forceReRender)
        node.data.entities[index].isChecked = !node.data.entities[index].isChecked
        for (let element of node.data.entities)
            if (element.isChecked === false) {
                setCheckedAll(false)
                return
            }
        setCheckedAll(true)
    }

    const changeAllFunction = () => {
        node.data.entities.forEach((entity, index, array) => { array[index].isChecked = !checkedAll })
        setCheckedAll(!checkedAll)
    }
    const inputHandlerNameChange = (data) => {

        setShowValidationErrorMessage(false);

        node.data.name = data
        console.log(data)
        const validName = () => {
            if (data === '' || data.length < 5) {
                setShowValidationErrorMessage(true);
                setDataValidationErrorMessage('Name should be greater than 5 characters.');
                return false;
            }

            if (!isAlphanumeric(data, 'en-US', { "ignore": "_" })) {
                setShowValidationErrorMessage(true);
                setDataValidationErrorMessage('Special characters other than _ is not allowed in name.');
                return false;
            }
        }

        let valid = validName();
        // node.data.name = temp;
        const empty = data != '' && data.length > 1;

        const result = empty && valid


    }

    return <div>
        <div className="panel panel--raised panel--bordered" style={{ height: '83vh' }}>
            <h6>Edit Clear Context Node <span onClick={() => closeSplit()} className='icon-close' style={{ float: 'right' }}></span></h6>
            <hr />
            <div className="row no-gutters" style={{ maxHeight: '75vh', overflowY: 'auto' }}>


                <div className="col-lg-12 col-12">
                    {
                        showValidationErrorMessage && (<div className="popBanner">{dataValidationErrorMessage}</div>)
                    }
                    <div className="form-group base-margin-bottom base-margin-top" >
                        <div className="form-group__text">
                            {/* <input id="input-type-number" type="text" name="rule_name" onChange={event => { setNewNodeInfo(obj => { let objCopy = { ...obj }; objCopy.name = event.target.value; return objCopy }) }} /> */}
                            <input id="input-type-number" type="text" name="rule_name" autoComplete='off' value={node.data.name} onChange={event => { inputHandlerNameChange(event.target.value); setForceReRender(!forceReRender) }} />
                            <label htmlFor="input-type-number">Clear Context Node Name <span className='required'>*</span> </label>
                        </div>
                    </div>
                    <div className='radiolists'>
                        <div className="form-group form-group--inline">
                            <label className="radio">
                                <input type="radio" checked={node.data.type === 'all'} onChange={() => { node.data.type = 'all'; setForceReRender(!forceReRender) }} name="radio-default" />
                                <span className="radio__input"></span>
                                <span className="radio__label">All</span>
                            </label>
                        </div>
                        <div className="form-group form-group--inline">
                            <label className="radio">
                                <input type="radio" checked={node.data.type === 'custom'} onChange={() => { node.data.type = 'custom'; setForceReRender(!forceReRender) }} name="radio-default" />
                                <span className="radio__input"></span>
                                <span className="radio__label">Custom</span>
                            </label>
                        </div>
                    </div>
                    {node.data.type === 'custom' && <div className='base-margin-top'>
                        {
                            showValidationErrorMessage && (<div className="popBanner">{dataValidationErrorMessage}</div>)
                        }
                        <div style={{ height: '70%', overflowY: 'hidden' }}>
                            <CheckUncheck listname="Enitity list" checkboxlist={node.data.entities} changeFunction={changeFunction} changeAllFunction={changeAllFunction} checkedAll={checkedAll} />
                        </div>
                        <div>
                            <div className="row" style={{ "marginLeft": "0px" }}>
                                <div className="col-10 col-sm-10 col-md-11 col-lg-11 col-xl-11 col-xxl-11 col-xxxl-11 form-group base-margin-bottom">
                                    <div className="form-group__text">
                                        <input id="input-type-email" type="text" autoComplete='off' value={custom} placeholder="Enter the Custom Variable " onChange={event => { setCustom(event.target.value) }} />
                                    </div>
                                </div>
                                <div className="col-2 col-sm-2 col-md-1 col-lg-1 col-xl-1 col-xxl-1 col-xxxl-1">
                                    <span className={(custom.length == 0 ? "disabled " : "") + "icon-add-outline icon-size-20"} style={{ "marginTop": "5px" }} onClick={() => {
                                        setShowValidationErrorMessage(false);
                                        if (isAlphanumeric(custom, 'en-US', { "ignore": "_" })) {
                                            let temp = node.data.entities
                                            console.log(temp)
                                            temp.push({
                                                id: node.data.entities.length,
                                                name: custom,
                                                isChecked: false
                                            })
                                            node.data.entities = temp
                                            setCustom('');
                                            setShowValidationErrorMessage(false);
                                        }
                                        else {
                                            setShowValidationErrorMessage(true);
                                            setDataValidationErrorMessage('Special characters other than _ are not allowed in an expression.');
                                        }
                                    }}></span>
                                </div>
                            </div>
                        </div>
                    </div>}
                </div>
            </div>
        </div>
    </div>
}

export default ClearContextNodeDetails;
