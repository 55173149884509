import React from "react";
import './SkillComponent.css';

export default function Skill({ index, skill, onSkillClick, onSkillVersionClick, onSkillUsersClick, onSkillDelete }) {
	/*let activate_bg =  skill['activate']?"white":"#ced4da";
	let activate = skill['activate']?"black":"#212529"*/
	let activate_bg = skill['deletionFlag'] ? "#ced4da" : "white";
	// let activate_bg = "white";
	// let activate = "#212529"

	function truncateStrings(str, length) {
		if (length > str.length) {
			str = str + " ".repeat(length - str.length)
			return str
		}

		str = str.substring(0, length)
		return str;
	}

	function len(str) {
		return str.length
	}

	return (

		<>
			<div className={"align-items-center panel panel--raised panel--bordered base-margin col-12 col-xs-12 col-sm-12 col-md-8 col-lg-5 col-xl-3 col-xxl-4 col-xxxl-4"} style={{ borderRadius: '3vh', background: activate_bg, height: 'fit-content', opacity: skill['activate'] ? '0.5' : '', width: "calc(100% - 40px)" }} key={index}>

				<div >
					<div className="base-margin">
						<div className="row">
							<div className="col-3 col-sm-3 col-xs-3 col-md-3 col-lg-3 col-xl-3 col-2xl-3">
								<img src={process.env.PUBLIC_URL + "/images/skills.png"} alt="botLogo" style={{ borderRadius: '50%' }} />
							</div>

							<div className="col-9 col-sm-9 col-xs-9 col-md-9 col-lg-9 col-xl-9 col-2xl-9" >
								<div className="skill-name">
									<h6 ><a href="#" onClick={() => { if (!skill['deletionFlag']) { onSkillClick(skill); } }}><b>{truncateStrings(skill['name'], 25)}</b></a></h6>
								</div>
								<div className="row base-margin-top base-margin-right">
									<div style={{ textAlign: 'justify', wordBreak: 'keep-all', height: '70px', marginLeft: '10px' }}> {truncateStrings(skill['description'], 100)}{len(skill['description']) > 100 ? '...' : ''}</div>
								</div>
							</div>
						</div>

						{

							<div className="base-margin-top base-margin-bottom flex flex-wrap flex-center col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 col-xxxl-12">
								<div style={{ visibility: (skill['clonedCount'] && skill['clonedCount'] > 0) ? 'visible' : 'hidden', cursor: 'pointer' }}>
									<div className="panel panel--well panel--bordered" style={{ "width": "fit-content" }} onClick={() => onSkillUsersClick(skill)}>

										<div className="flex">
											<div style={{ "margin": "0px 3px" }}>
												<span className='icon-contact icon-size-24'></span>
											</div>
											<div style={{ "margin": "0px 3px" }}>
												<span>{skill['clonedCount']}</span>
											</div>
										</div>

									</div>
								</div>
							</div>
						}
						<hr style={{ marginBottom: '10px' }} />
						<br />

						<div className="base-margin-left base-margin-right base-margin-bottom flex flex-wrap " style={{ position: 'absolute', left: '15px', bottom: '0', marginTop: '5px' }}>
							<div className="" style={{ display: (skill['versionCount'] && skill['versionCount'] > 0) ? 'block' : 'none', marginRight: '20px' }}>
								<div className="btn btn--secondary btn--small" style={{ opacity: skill['deletionFlag'] ? '0.25' : '' }}>
									{skill['versionCount']} {skill['versionCount'] > 1 ? "Versions" : "Version"}
								</div>
							</div>
						</div>
						<div className=" flex flex-wrap  " style={{ position: 'absolute', right: '0', bottom: '0', marginTop: '10px', marginBottom: '10px', marginRight: '20px' }}>

							<div style={{ display: !skill['read_only'] ? 'block' : 'none' }}>
								{!skill['deletionFlag'] &&
									<div className="flex flex-wrap" style={{ float: 'right' }} >
										<div className="flex-center-vertical base-margin-right skill-action" data-balloon="Delete this skill" data-balloon-pos="left" data-balloon-length="medium">

											<span className="icon-delete icon-size-24" onClick={() => onSkillDelete(skill)}></span>

										</div>
										{
																			
											!skill['shared'] && (skill['versionCount'] && skill['versionCount'] == 4 ? <div className="flex-center-vertical skill-action" data-balloon="You cannot create more than 4 Versions per Skill" data-balloon-pos="left" data-balloon-length="medium">
												<span style={{ width: '30px', opacity: '0.5' }} >
													<img src={process.env.PUBLIC_URL + "/images/version-control.png"} />
												</span>
											</div> 
											:
											<div className="flex-center-vertical skill-action" data-balloon="Create a Version to save the current state of this Skill" data-balloon-pos="left" data-balloon-length="medium">
												<span style={{ width: '30px' }} onClick={() => onSkillVersionClick(skill["_id"])} >
													<img src={process.env.PUBLIC_URL + "/images/version-control.png"} />
												</span>
											</div>)
										}
									</div>
								}
							</div>

							<div style={{ display: !skill['shared'] ? 'block' : 'none' }}>
								{skill['deletionFlag'] &&
									<div className="flex flex-wrap" style={{ float: 'right' }}>
										<div className="base-margin-right">

											<div className="flex-center-vertical skill-action" data-balloon="Delete this skill" data-balloon-pos="left" data-balloon-length="medium">

												<span className="icon-delete icon-size-24" ></span>

											</div>
											<div className="flex-center-vertical skill-action" data-balloon="Create a Version to save the current state of this Skill" data-balloon-pos="left" data-balloon-length="medium">

												<span style={{ width: '30px' }} ><img src={process.env.PUBLIC_URL + "/images/version-control.png"} /></span>
											</div>
										</div>
									</div>
								}
							</div>
						</div>
					</div>
				</div>

			</div>
		</>
	);
}