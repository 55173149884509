import { DataGrid, GridToolbarContainer, GridToolbarFilterButton, GridToolbar, useGridApiRef } from '@mui/x-data-grid';
import * as React from 'react';
import Alert from '@mui/material/Alert';
import { useState, useEffect } from 'react';
import './DataGrid.css';

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarFilterButton />
        </GridToolbarContainer>
    );
}

export default function DataGridComponent({ rows, columns, rowHeight, checkbox, setSelectedExpression, ht = 500, noToolbar = false, getCellClassName = (params)=>''}) {
    const [data, setData] = useState(rows);
    const [col, setCol] = useState(columns);
    const [justification, setJustification] = React.useState('');
    const [businessBenefit, setBusinessBenefit] = React.useState('');
    const apiRef = useGridApiRef();


    useEffect(() => {
        setData(rows)
        setCol(columns)
    })

    const dataGridDefaultStyle = {
        '& .MuiDataGrid-root':{
            border: '0px solid'

        },
        '& .MuiDataGrid-main':{
            border: '10px solid #ced4da'
        },
        '& .MuiDataGrid-cell': {
            fontSize: 14,
            
        },
        '& .MuiDataGrid-columnHeaderTitle': {
            fontSize: 14,
            fontWeight: 'bold',
            textAlign: 'left'
        },
        '& .MuiDataGrid-main': {
            border: '1px solid #ced4da',
        },
        '& .MuiInputBase-root': {
            fontSize: 11,
        },
        '& .MuiTablePagination-displayedRows': {
            fontSize: 11
        },
        '& .MuiTablePagination-selectLabel': {
            fontSize: 11
        },
        '& .MuiDataGrid-toolbarContainer':{
            border: '0px',
            padding: '0px',
            marginBottom: '2px'
        },
        '& .MuiDataGrid-columnHeader': {
            backgroundColor: '#64bbe3',
            borderRadius: '0px',
            border: '1px solid #ced4da',
            fontWeight: '500px',
            color: 'black',
            textAlign: 'left'
        },
        '& .enableGAI':{
            backgroundColor: '#eed202',   
        }
        
    };
    
    React.useEffect(() => {
        const handleRowClick = (params) => {
          if (params.row.Justification != null){
            setJustification(`Justification: ${params.row.Justification}`);
          }
          if (params.row.BusinessBenefit != null){
            setBusinessBenefit(`Business benefit: ${params.row.BusinessBenefit}`);
          }
        };
    
        return apiRef.current.subscribeEvent('rowClick', handleRowClick);
      }, [apiRef]);
    
    return (
        <div style={{ height: ht, width: '100%' }}>
            <DataGrid
                rows={data}
                columns={col}
                checkboxSelection={checkbox}
                onRowSelectionModelChange={(newSelectionModel) => {
                    // setSelectionModel(newSelectionModel);
                    setSelectedExpression(newSelectionModel)
                }}
                // disableRowSelectionOnClick={disableSelection}
                // disableToolbarButton={noToolbar}
                getCellClassName = {getCellClassName}
                slots={{ toolbar: noToolbar ? CustomToolbar : GridToolbar }}
                sx={dataGridDefaultStyle}
                rowHeight={rowHeight}
                apiRef={apiRef} {...data}
            />
            {justification && <Alert severity="info" style={{fontSize:"15px"}}>{justification }</Alert>}
            {businessBenefit && <Alert severity="info" style={{fontSize:"15px"}}>{businessBenefit }</Alert>}
        </div>
    );
}

