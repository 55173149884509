import './EntityEditComponent.css';
import isAlphanumeric from 'validator/lib/isAlphanumeric';
import { useEffect, useState } from 'react';
import isAlpha from 'validator/lib/isAlpha';
import APIForm from '../../../../custom-components/api-form/APIForm';
import FileDropZone from '../../../../custom-components/file-drop-zone/FileDropZone';
import FileParser from '../../../../utils/FileParser';
import EntityService from '../../entity-services/EntityService';
import MessageBanner from '../../../../custom-components/MessageBanner/MessageBanner';
import Spinner from '../../../../custom-components/spinner/Spinner';

const EntityEditPanel = ({ entity_object, selectedSkill = { value: '' }, onCloseButtonClicked = () => { }, creationMode = false, onEntityCreated = () => { }, onEntityUpdated = () => { }, getEntityData, displayMessage = (type, message) => { }, setData = (data) => { }, dialogCreation = false, validateEntityCreation = (newEntityName) => true }) => {
    const entity_id = entity_object["id"];
    const skillId = selectedSkill['value']
    const [entity_name, setEntityName] = useState(entity_object["name"]);
    const [entity_description, setEntityDescription] = useState(entity_object["description"]);
    const [entity_value_list, setEntityValue] = useState(entity_object["values"]);
    const [entity_role_value_list, setEntityRole] = useState(entity_object["roles"]);
    const [entity_value_checkbox_list, setEntityCheckboxValue] = useState([]);
    const [entity_role_checkbox_value_list, setEntityRoleCheckboxValue] = useState([]);
    const [tab_selected, switch_tab] = useState('static');
    const [entityAPIUrl, setEntityAPIUrl] = useState('');
    const [triggerAPIExecution, setAPIExecutionTrigger] = useState(false);
    const [isFileProcessingInProgress, setFileProcessingFlag] = useState(false);
    const [isDataLoading, setDataLoading] = useState(false);
    const [showMessageBanner, setShowMessageBanner] = useState(false);
    const [bannerMessage, setBannerMessage] = useState('');
    const [bannerMessageType, setBannerMessageType] = useState('success');
    const [mandatoryField, setMandatoryField] = useState(creationMode ? [{ name: "", description: "", value: "" }] : [{ name: entity_object["name"], description: entity_object["description"], value: entity_object["values"] }]);
    const [submitBtnEnable, setSubmitBtnEnable] = useState(false)
    const [layoutType, setLayoutType] = useState(
        window.innerWidth > 799.9 ? "verticle" : "horizontal"
    );
    const [showGuidance, setShowGuidance] = useState(false)
    const [dataValidationErrorMessage, setDataValidationErrorMessage] = useState('');
    const [showValidationErrorMessage, setShowValidationErrorMessage] = useState(false);
    const [entity_value, setEntityValueOnTyping] = useState('')
    const [entity_role, setEntityRoleOnTyping] = useState('')

    const handleResize = () => {
        setLayoutType(window.innerWidth > 799.9 ? "verticle" : "horizontal");
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);
    }, []);

    const inputHandlerChange = (event, type) => {
        setShowValidationErrorMessage(false);
        setSubmitBtnEnable(false)
        let newdata = mandatoryField[0];
        let valid = false;
        let empty = hasEmpty(newdata);
        if (type === "name") {
            setEntityName(event.target.value)
            newdata[type] = event.target.value;
        }
        else if (type === "description") {
            setEntityDescription(event.target.value);
            newdata[type] = event.target.value;
        }
        else if (type === 'role') {
            newdata[type] = event
            setEntityRoleOnTyping('');
        }
        else {
            newdata[type] = event.replaceAll(" ", "");
            setEntityValueOnTyping('');
        }
        if (tab_selected === "static") {
            setMandatoryField([newdata])
            if (!empty) {
                valid = validateEntityForm(type, newdata);
            }
            else {
                valid = validateEntityForm("all", newdata);
            }
            if (type == 'value' && valid) {
                addEntityValue();
            }
            else if (type == 'role' && valid) {
                addEntityRole();
            }
        } else {
            if (!empty) {
                valid = validateEntityForm(type, newdata);
            }
            else {
                valid = validateEntityForm("all", newdata);
            }

        }

        const result = empty && valid;
        setSubmitBtnEnable(result);
    }
    const hasEmpty = (element) => {
        return (
            (element.name != "" && element.name.length > 1) &&
            (element.description != "" && element.description.length > 19)
        );
    };

    const validateAlphaNumericString = (data) => {
        let sanitisedData = data.replaceAll(/[_@.,'"&\s]/g, "");
        return isAlphanumeric(sanitisedData);
    }

    const validateRoleString = (data) => {
        let sanitisedData = data.replaceAll(/_/g, "");
        return isAlphanumeric(sanitisedData);
    }

    const validateEntityValueAlphaNumericString = (data) => {
        let data_list = data.split(',')
        for (let i of data_list) {
            if (!isAlphanumeric(i)) {
                return false;
            }
        }
        return true;
    }

    const validateEntityForm = (key, entityData) => {

        if (key == "name" || key == "all") {
            if (entityData.name === '' || entityData.name.length < 2) {
                setShowValidationErrorMessage(true);
                setDataValidationErrorMessage('Entity name should be of minimum 2 characters.');
                return false;
            }

            if (!isAlphanumeric(entityData.name, 'en-US', { "ignore": "_" })) {
                setShowValidationErrorMessage(true);
                setDataValidationErrorMessage('Special characters other than _ is not allowed in entity name.');
                return false;
            }
        }
        else if (key == "description" || key == "all") {
            if (entityData.description === '' || entityData.description.length < 20) {
                setShowValidationErrorMessage(true);
                setDataValidationErrorMessage('Entity description should be greater than 20 characters.');
                return false;

            }
            if (!validateAlphaNumericString(entityData.description)) {
                setShowValidationErrorMessage(true);
                setDataValidationErrorMessage('Special characters other than _ @ . " \' & is not allowed in entity description.');
                return false;
            }
        }
        else if (key == "value" || key == "all") {
            if (entity_value_list === [] || entityData.value.length < 2) {
                setShowValidationErrorMessage(true);
                setDataValidationErrorMessage('Entity value can not be empty and have atleast should one character.');
                return false;
            }

            if (!validateEntityValueAlphaNumericString(entityData.value)) {
                setShowValidationErrorMessage(true);
                setDataValidationErrorMessage('Special characters are not allowed in entity values.');
                return false;
            }
        }
        else if (key == "role" || key == "all") {
            if (entityData.role == '' || entityData.role.length < 2) {
                setShowValidationErrorMessage(true);
                setDataValidationErrorMessage('Entity role can not be empty and have atleast should one character.');
                return false;
            }
            if (!validateRoleString(entityData.role)) {
                setShowValidationErrorMessage(true);
                setDataValidationErrorMessage('Special characters other than _ are not allowed in entity role.');
                return false;
            }
        }
        return true;
    }
    const [fileData, setFileData] = useState([]);

    const apiTriggerCallBack = (data) => {
        setDataLoading(true);
        if (data.status === "success") {
            if (creationMode) {
                let reqPayload = {
                    "name": entity_name,
                    "description": entity_description,
                    "values": data["result"]["values"],
                    "roles": data["result"]["roles"]
                };

                EntityService.createEntity(reqPayload, skillId).then((res) => res.json()).then((res) => {
                    if (res['status'] == "success") {
                        setDataLoading(false);

                        displayMessage('success', "Entity created successfully!")
                        onEntityCreated();
                        getEntityData();
                        if (dialogCreation) {
                            setData({ label: entity_name, value: res['entity_id'] })
                        }
                    } else {
                        displayMessage('error', res['details'])
                        setDataLoading(false);
                    }
                }).catch(err => {
                    console.log(err);
                    displayMessage('error', "Failed to create entity. Please try again later.")
                    setDataLoading(false);
                })
            }
            else {
                EntityService.updateEntity({
                    "id": entity_id,
                    "skill_id": skillId,
                    "name": entity_name,
                    "values": data["result"]["values"],
                    "roles": data["result"]["roles"]
                }, skillId).then((res) => res.json()).then((res) => {

                    if (res['status'] == "success") {
                        setDataLoading(false);
                        displayMessage('success', "Entity updated successfully!")
                        onEntityUpdated();
                        getEntityData();
                    } else {
                        setDataLoading(false);
                        displayMessage('error', res['details'])
                    }
                }).catch(err => {
                    console.log(err);
                    displayMessage('error', "Failed to update entity. Please try again later.")
                    setShowMessageBanner(true);
                    setDataLoading(false);
                })

            }
        } else {
            setShowValidationErrorMessage(true);
            setDataValidationErrorMessage('Please check the URL with correct header or payload');
            setDataLoading(false);
        }

    }

    const addEntityValue = () => {
        let value = document.getElementById("input-entity-value").value;
        let entityvalue = value.replaceAll(" ", "")
        document.getElementById("input-entity-value").value = "";
        entity_value_list.push(entityvalue);
        setEntityValue(() => {
            let result = []
            for (let i = 0; i < entity_value_list.length; i++) {
                result.push(entity_value_list[i]);
            }
            setEntityCheckboxValue((prev) => {
                let temp = [];
                for (let i = 0; i < result.length - 1; i++) {
                    temp.push(prev[i]);
                }
                temp.push(false);
                return temp;
            })
            return result;
        });
    }

    const addEntityRole = () => {
        let value = document.getElementById("input-entity-role").value;
        document.getElementById("input-entity-role").value = "";

        setEntityRole(() => {
            let result = []
            //looping though each row and pushing bcz state change will not get triggered is list's reference is change b/w variables
            for (let i = 0; i < entity_role_value_list.length; i++) {
                result.push(entity_role_value_list[i]);
            }
            result.push(value);
            setEntityRoleCheckboxValue((prev) => {
                let temp = [];
                for (let i = 0; i < result.length - 1; i++) {
                    temp.push(prev[i]);
                }
                temp.push(false);
                return temp;
            });
            return result;
        });
    }
    const processFileData = (data) => {
        setFileProcessingFlag(true);
        for (let i = 0; i < data.length; i++) {
            data[i] = FileParser(data[i]["data"], data[i].type);
        }
        setFileProcessingFlag(false);
        setFileData(data);
    }
    const onEntityValueDeleteClicked = () => {
        //delete selected entities value
        setEntityValue((prev) => {
            let newEntityList = [];
            for (let i = 0; i < entity_value_checkbox_list.length; i++) {
                if (entity_value_checkbox_list[i] === false) {
                    newEntityList.push(prev[i]);
                }
            }
            setEntityCheckboxValue(() => {
                let temp = [];
                for (let i = 0; i < newEntityList.length; i++) {
                    temp.push(false);
                }
                return temp;
            })
            // if(newEntityList.length<1){
            //     setSubmitBtnEnable(false)
            // }
            return newEntityList;

        })
    }
    const onRoleDeleteClicked = () => {
        // delete selected roles
        setEntityRole((prev) => {
            let newRoleList = [];
            for (let i = 0; i < entity_role_checkbox_value_list.length; i++) {
                if (entity_role_checkbox_value_list[i] === false) {
                    newRoleList.push(prev[i]);
                }
            }
            setEntityRoleCheckboxValue(() => {
                let temp = [];
                for (let i = 0; i < newRoleList.length; i++) {
                    temp.push(false);
                }
                return temp;
            })
            return newRoleList;
        })
    }
    const closeEditEntityPanel = () => {
        onCloseButtonClicked();

    }
    const updateEntityChanges = async () => {
        setDataLoading(true);
        if (tab_selected === "static") {
            let values = [];

            for (let value of entity_value_list) {
                value = value.split(",");
                let synonyms = [];

                for (let i = 1; i < value.length; i++) {
                    synonyms.push(value[i]);
                }

                values.push({
                    "cname": value[0],
                    "synonyms": synonyms
                })
            }

            EntityService.updateEntity({
                "_id": entity_id,
                "name": entity_name,
                "values": values,
                "roles": entity_role_value_list,
                "description": entity_description
            }, skillId).then((res) => res.json()).then((res) => {
                if (res['status'] == "success") {
                    setDataLoading(false);
                    displayMessage('success', "Entity updated successfully!")
                    closeEditEntityPanel();
                    onEntityUpdated();
                    getEntityData();
                } else {
                    setDataLoading(false);
                    displayMessage('error', res['details'])
                    closeEditEntityPanel();
                }
            }).catch(err => {
                console.log(err);
                setDataLoading(false);
                displayMessage('error', "Failed to update entity. Please try again later.")
                closeEditEntityPanel();
            })

        }
        else if (tab_selected === "api") {

            setAPIExecutionTrigger((prev) => {
                if (prev) {
                    setDataLoading(false);
                    return false
                }
                setDataLoading(false);
                return true;
            })
        }
        else {
            while (isFileProcessingInProgress) {
                //wait for file to process
                await Promise(() => setTimeout(1000));
            }
            //call api to create new entity
            let values = [];
            if (fileData.length > 0) {
                for (let value of fileData[0]) {
                    value = value.split(",");
                    let synonyms = [];

                    for (let i = 1; i < value.length; i++) {
                        synonyms.push(value[i]);
                    }

                    values.push({
                        "cname": value[0],
                        "synonyms": synonyms
                    })
                }
            }

            let reqPayload = {
                "name": entity_name,
                "description": entity_description,
                "values": values,
                "roles": []
            };

            EntityService.createEntity(reqPayload, skillId).then((res) => res.json()).then((res) => {
                if (res['status'] == 'success') {
                    setDataLoading(false);
                    displayMessage('success', "Entity created successfully!")
                    onEntityCreated();
                    getEntityData();
                    closeEditEntityPanel();
                }
                else {
                    setDataLoading(false);
                    displayMessage('error', res['details'])
                    closeEditEntityPanel();
                }
            }, (err) => {
                setDataLoading(false);
                displayMessage('error', "Failed to create entity. Please try again later.")
                closeEditEntityPanel();
            })

        }

    }
    const onCreateEntityClick = async () => {
        setSubmitBtnEnable(false);
        if (validateEntityCreation(entity_name) === false) {
            setShowValidationErrorMessage(true);
            setDataValidationErrorMessage('Entity with name ' + entity_name + ' exist. Please try creating with a different name.');
            setSubmitBtnEnable(true);
            return;
        }
        if (entity_name.length < 2 || entity_name === undefined) {
            setShowValidationErrorMessage(true);
            setDataValidationErrorMessage('Entity name should be of minimum 2 characters.');
        } else if (entity_description.length < 20 || entity_description === undefined) {
            setShowValidationErrorMessage(true);
            setDataValidationErrorMessage('Entity description should be greater than 20 characters.');
        } else {
            if (tab_selected === "static") {
                if (entity_value_list.length < 1) {
                    setShowValidationErrorMessage(true);
                    setDataValidationErrorMessage('Entity value can not be empty.');
                } else {
                    let values = [];
                    for (let value of entity_value_list) {
                        value = value.split(",");
                        let synonyms = [];

                        for (let i = 1; i < value.length; i++) {
                            synonyms.push(value[i]);
                        }

                        values.push({
                            "cname": value[0],
                            "synonyms": synonyms
                        })
                    }
                    let reqPayload = {
                        "name": entity_name,
                        "description": entity_description,
                        "values": values,
                        "roles": entity_role_value_list
                    };
                    createEntityByApi(reqPayload, skillId)
                }
            }
            else if (tab_selected === "api") {
                var res = entityAPIUrl.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
                if (res === null) {
                    setShowValidationErrorMessage(true);
                    setDataValidationErrorMessage('Please check the URL with correct header or payload');
                } else {
                    setAPIExecutionTrigger((prev) => {
                        if (prev) {
                            return false
                        }
                        return true;
                    })
                }
            }
            else {
                while (isFileProcessingInProgress) {
                    //wait for file to process
                    await Promise(() => setTimeout(1000));
                }
                let values = [];
                if (fileData.length > 0) {
                    console.log("inside this if", fileData[0])
                    for (let value of fileData[0]) {
                        if (typeof value == 'object') {
                            let synonyms = []

                            for (let data in value) {
                                if (data == 0) {
                                    continue
                                }
                                else {
                                    synonyms.push(value[data])
                                }
                            }
                            // let synonyms = value[1].split(",")
                            values.push({
                                "cname": value[0],
                                "synonyms": synonyms
                            })
                        }
                        else {
                            console.log("value", value)
                            value = value.split(",");
                            let synonyms = [];

                            for (let i = 1; i < value.length; i++) {
                                synonyms.push(value[i]);
                            }

                            values.push({
                                "cname": value[0],
                                "synonyms": synonyms
                            })
                        }

                    }
                }
                let reqPayload = {
                    "name": entity_name,
                    "description": entity_description,
                    "values": values,
                    "roles": []
                };
                if (values.length > 0) {
                    createEntityByApi(reqPayload, skillId)
                } else {
                    setShowValidationErrorMessage(true);
                    setDataValidationErrorMessage('Please upload file');
                }
            }
        }
        setSubmitBtnEnable(true);
        return;
    }
    const createEntityByApi = (payloadData, skillIdData) => {
        EntityService.createEntity(payloadData, skillIdData).then((res) => res.json()).then((res) => {
            if (res['status'] == "success") {
                setDataLoading(false);
                displayMessage('success', 'Entity created successfully!')
                onEntityCreated();
                getEntityData();
                closeEditEntityPanel();
            } else {
                setDataLoading(false);
                displayMessage('error', res['details'])
                closeEditEntityPanel();
            }
        }).catch(err => {
            console.log(err);
            setDataLoading(false);
            displayMessage('error', "Failed to create entity. Please try again later.")
            closeEditEntityPanel();
        })
    }

    useEffect(() => {
        if (creationMode) {
            // this block will be executed when edit entity component is opened to create a new entity
            setEntityName('');
            setEntityValue([]);
            setEntityRole([]);
            setEntityDescription('')


            setEntityRoleCheckboxValue(() => {
                let temp = [];
                for (let i = 0; i < entity_role_value_list.length; i++) {
                    temp.push(false);
                }
                return temp;
            })

            setEntityCheckboxValue(() => {
                let temp = [];
                for (let i = 0; i < entity_value_list.length; i++) {
                    temp.push(false);
                }
                return temp;
            })
        }
        else {
            setEntityName(entity_object["name"]);
            setEntityValue(entity_object["values"]);
            setEntityRole(entity_object["roles"]);
            setEntityDescription(entity_object["description"]);


            setEntityRoleCheckboxValue(() => {
                let temp = [];
                for (let i = 0; i < entity_role_value_list.length; i++) {
                    temp.push(false);
                }
                return temp;
            })

            setEntityCheckboxValue(() => {
                let temp = [];
                for (let i = 0; i < entity_value_list.length; i++) {
                    temp.push(false);
                }
                return temp;
            })
        }


    }, [entity_object])
    const validatedata = (val) => {
        setEntityAPIUrl(val)
    }

    return (
        <div className='panel' style={{ height: "100%" }}>
            {isDataLoading && <Spinner></Spinner>}
            {
                (!creationMode) && (<><div className='row'>
                    <h6 className='col-11 col-xs-10 col-sm-10 col-md-11 col-lg-11 col-xl-11 col xxl-11'>Edit Entity</h6>
                    <h6 className='col-1 col-xs-2 col-sm-2 col-md-1 col-lg-1 col-xl-1 col-xxl-1'><span className='icon-close ' style={{ float: 'right' }} onClick={() => { closeEditEntityPanel() }}></span></h6>
                </div>
                    <hr /></>)}
            {
                showValidationErrorMessage && (<MessageBanner message={dataValidationErrorMessage} messageType={"error"} onCloseClicked={() => { setShowValidationErrorMessage(false) }}></MessageBanner>)
            }
            <div style={{ height: 'calc(100% - 108px)', overflow: 'auto' }}>
                <div >
                    <div >
                        {/* {!submitBtnEnable ? <div className='warningMessage txtAlignRight'>Please fill the (*) mandatory fields</div> : ""} */}
                        <div className='form-group' style={{ "padding": "8px" }}>
                            <div className="form-group__text">
                                <input id="input-entity-name" type="text" autoFocus name="entity_name" value={entity_name} onChange={event => { inputHandlerChange(event, "name") }} />
                                <label htmlFor="input-entity-name">Entity Name <span className='required' style={{ "color": "red" }}>*</span></label>
                            </div>
                        </div>
                    </div>
                    {

                        <div>
                            <div className='form-group' style={{ "padding": "8px" }}>
                                <div className="form-group__text">
                                    <textarea id="input-entity-description" rows={3} value={entity_description} onChange={event => { inputHandlerChange(event, "description") }} ></textarea>
                                    <label htmlFor="input-entity-description" className='positionRelative'>Entity Description <span className='required' style={{ "color": "red" }}>*</span></label>
                                </div>
                            </div>

                        </div>

                    }
                </div>
	    	<MessageBanner><div style={{textAlign: 'justify'}}>An entity can have many different values, called synonyms. If multiple values of an entity can be present in a user's query in different contexts, it may be beneficial to define entity roles. These can be tagged to the relevant phrases in the expressions during annotation. For example, in relation to a trip, origin and destination are both picked from the same pool of cities, but they have very different meanings.</div></MessageBanner>
                <div>
                    <div style={{ "height": "50px" }}>
                        <ul className="tabs tabs--justified" style={{ "height": "100%" }}>
                            <li id="static-entity-tab" className={tab_selected === "static" ? "active" : "" + "tab"} onClick={() => { switch_tab("static"); setShowValidationErrorMessage(false) }}>
                                <a tabindex="0">Static</a>
                            </li>
                            <li id="api-entity-tab" className={tab_selected === "api" ? "active" : "" + "tab"} onClick={() => { switch_tab("api"); setShowValidationErrorMessage(false); }}>
                                <a tabindex="0">API</a>
                            </li>
                            <li id="api-entity-file" className={tab_selected === "file" ? "active" : "" + "tab"} onClick={() => { switch_tab("file"); setShowValidationErrorMessage(false) }}>
                                <a tabindex="0">File Upload</a>
                            </li>
                        </ul>
                    </div>
                    <div className="editEntityTab-content base-margin-top">

                        <div id="static-entity-tab" className={(tab_selected === "static" ? "active" : "" + "tab-pane") + " entity-staticForm"}>
                            <div className='editEntity-valueAndRole' style={{ "padding": "8px" }}>
                                <div className='row base-margin-bottom' style={{ "width": "100%" }}>
                                    <div className={(layoutType === "verticle" ? 'col-8 col-sm-8' : 'col-11 col-sm-11') + ' col-md-8 col-lg-8 col-xl-6 col-xxl-6 col-xxxl-5'}>
                                        <div className='form-group'>
                                            <div className="form-group__text">
                                                <label htmlFor="input-entity-role">Entity Role</label>
                                                {/* onChange={event => { inputHandlerChange(event, "role")}} */}
                                                <input id="input-entity-role" type="text" autoFocus name="entity_name" placeholder="Role Name" onChange={(e) => { setEntityRoleOnTyping(e.target.value) }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={(layoutType === "verticle" ? 'col-4 col-sm-4' : 'col-1 col-sm-1') + ' col-md-4 col-lg-4 col-xl-4 col-xxl-4 col-xxxl-4'} style={{ "textAlign": "center" }}>
                                        {
                                            (() => {
                                                if (layoutType === "verticle") {
                                                    return (
                                                        <button className="btn btn--small btn--primary entityEditPanel-button entityEditPanel-buttonMargin" onClick={() => { inputHandlerChange(entity_role, 'role'); }}>
                                                            Add
                                                            {/* <span className="icon-add-outline icon-size-18 base-margin-left"></span> */}
                                                        </button>
                                                    )
                                                }
                                                else {
                                                    return (
                                                        <span className="icon-add-outline icon-size-18" onClick={() => { inputHandlerChange(entity_role, 'role'); }} style={{ "position": "relative", "top": "calc(50% - 3.8px)" }}></span>  // height of label(16.8px) - height of icon(18px)/2 = 3.8px
                                                    )
                                                }
                                            })()
                                        }

                                    </div>
                                </div>
                                {
                                    (entity_role_value_list.length > 0) && (
                                        <table className="table table--bordered" style={{ "width": "100%" }}>
                                            <thead style={{ "width": "100%", "background": "#00bceb60" }}>
                                                <tr style={{ "width": "100%" }}>
                                                    <th style={{ "width": "90%" }}>Role</th>
                                                    <th style={{ "width": "10%", "textAlign": "center" }} onClick={() => { onRoleDeleteClicked() }}>
                                                        <span className="icon-delete icon-size-18"></span>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    entity_role_value_list.map((value, index) => {
                                                        return (
                                                            <tr>
                                                                <td>{value}</td>
                                                                <td style={{ "textAlign": "center" }}>
                                                                    {
                                                                        (() => {
                                                                            if (entity_role_checkbox_value_list[index]) {
                                                                                return (
                                                                                    <input type="checkbox" checked onClick={() => {
                                                                                        setEntityRoleCheckboxValue((prev) => {
                                                                                            let temp = [];
                                                                                            for (let i = 0; i < prev.length; i++) {
                                                                                                if (i === index) {
                                                                                                    temp.push(!prev[i]);
                                                                                                }
                                                                                                else {
                                                                                                    temp.push(prev[i]);
                                                                                                }
                                                                                            }
                                                                                            return temp;
                                                                                        })
                                                                                    }} />
                                                                                )
                                                                            }
                                                                            else {
                                                                                return (
                                                                                    <input type="checkbox" onClick={() => {
                                                                                        setEntityRoleCheckboxValue((prev) => {
                                                                                            let temp = [];
                                                                                            for (let i = 0; i < prev.length; i++) {
                                                                                                if (i === index) {
                                                                                                    temp.push(!prev[i]);
                                                                                                }
                                                                                                else {
                                                                                                    temp.push(prev[i]);
                                                                                                }
                                                                                            }
                                                                                            return temp;
                                                                                        })
                                                                                    }} />
                                                                                )
                                                                            }

                                                                        })()
                                                                    }

                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }

                                            </tbody>
                                        </table>
                                    )
                                }

                                {/* <hr></hr> */}


                                <div className='row base-margin-bottom base-margin-top' style={{ "width": "100%" }}>
                                    <div className={(layoutType === "verticle" ? 'col-8 col-sm-8' : 'col-11 col-sm-11') + ' col-md-8 col-lg-8 col-xl-6 col-xxl-6 col-xxxl-5'}>
                                        <div className='form-group'>
                                            <div className="form-group__text">
                                                <label htmlFor="input-entity-value">Entity Value<span className='required' style={{ "color": "red" }}>*</span></label>
                                                <input id="input-entity-value" type="text" autoFocus name="entity_name" placeholder="Value,synonyms 1,synonym 2 ..." onChange={e => { setEntityValueOnTyping(e.target.value) }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={(layoutType === "verticle" ? 'col-4 col-sm-4' : 'col-1 col-sm-1') + ' col-md-4 col-lg-4 col-xl-4 col-xxl-4 col-xxxl-4'} style={{ "textAlign": "center" }}>
                                        {
                                            (() => {
                                                if (layoutType === "verticle") {
                                                    return (
                                                        <button className="btn btn--primary entityEditPanel-button entityEditPanel-buttonMargin btn--small" onClick={() => { inputHandlerChange(entity_value, "value") }}>
                                                            Add
                                                            {/* <span className="icon-add-outline icon-size-18 base-margin-left"></span> */}
                                                        </button>
                                                    )
                                                }
                                                else {
                                                    return (
                                                        <span className="icon-add-outline icon-size-18" onClick={() => { inputHandlerChange(entity_value, "value") }} style={{ "position": "relative", "top": "calc(50% - 3.8px)" }}></span>  // height of label(16.8px) - height of icon(18px)/2 = 3.8px
                                                    )
                                                }
                                            })()
                                        }

                                    </div>
                                </div>

                                {
                                    (entity_value_list.length > 0) && (
                                        <table className="table table--bordered base-margin-bottom" style={{ "width": "100%" }}>
                                            <thead style={{ "width": "100%", "background": "#00bceb60" }}>
                                                <tr style={{ "width": "100%" }}>
                                                    <th style={{ "width": "20%" }}>Value</th>
                                                    <th style={{ "width": "70%" }}>Synonyms</th>
                                                    <th style={{ "width": "10%", "textAlign": "center" }} onClick={() => { onEntityValueDeleteClicked() }}>
                                                        <span className="icon-delete icon-size-18"></span>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    entity_value_list.map((value, index) => {
                                                        value = value.split(",");
                                                        let cname = value[0];
                                                        let entity_value = "";
                                                        for (let i = 1; i < value.length; i++) {
                                                            entity_value += value[i];

                                                            if (i != value.length - 1) {
                                                                entity_value += ",";
                                                            }
                                                        }
                                                        return (
                                                            <tr>
                                                                <td>{cname}</td>
                                                                <td>{entity_value}</td>
                                                                <td style={{ "textAlign": "center" }}>

                                                                    {
                                                                        (() => {
                                                                            if (entity_value_checkbox_list[index]) {
                                                                                return (
                                                                                    <input type="checkbox" checked onClick={() => {
                                                                                        setEntityCheckboxValue((prev) => {
                                                                                            let temp = [];
                                                                                            for (let i = 0; i < prev.length; i++) {
                                                                                                if (i === index) {
                                                                                                    temp.push(!prev[i]);
                                                                                                }
                                                                                                else {
                                                                                                    temp.push(prev[i]);
                                                                                                }
                                                                                            }
                                                                                            return temp;
                                                                                        })
                                                                                    }} />
                                                                                )
                                                                            }
                                                                            else {
                                                                                return (
                                                                                    <input type="checkbox" onClick={() => {
                                                                                        setEntityCheckboxValue((prev) => {
                                                                                            let temp = [];
                                                                                            for (let i = 0; i < prev.length; i++) {
                                                                                                if (i === index) {
                                                                                                    temp.push(!prev[i]);
                                                                                                }
                                                                                                else {
                                                                                                    temp.push(prev[i]);
                                                                                                }
                                                                                            }
                                                                                            return temp;
                                                                                        })
                                                                                    }} />
                                                                                )
                                                                            }

                                                                        })()
                                                                    }


                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }

                                            </tbody>
                                        </table>
                                    )
                                }

                            </div>
                        </div>
                        <div id="api-entity-tab" className={(tab_selected == "api" ? "active" : "" + "tab-pane") + " entity-apiForm"}>
                            <APIForm validatedata={validatedata} executionTrigger={triggerAPIExecution} executionTriggerCallBack={(data) => { apiTriggerCallBack(data) }} showScript={true}></APIForm>
                        </div>
                        <div id="api-entity-file" className={(tab_selected == "file" ? "active" : "" + "tab-pane") + " entity-fileUploadForm"}>
                            <FileDropZone onFileuploaded={(data) => processFileData(data)} allowedFileTypes={["text/csv", "text/plain"]} style={{ "marginTop": "-58px" }}></FileDropZone>
                            <MessageBanner messageType={"info"}>
                                <div className="base-margin-top base-margin-bottom">To upload entities using a file. Follow the below format:
                                    <br />
                                    <p><b>For TXT format</b> : each entity value should be entered in a new line. The value and synonyms for each entity value should be written in a comma separated fashion. For example : Bangalore,BLG,blg.</p>
                                    <p><b>For CSV format</b> : each entity value should be in a new row. The value of the entity should be written in the first column and the corresponding synonyms should be written in a comma seprated fashion in the second column.</p>
                                </div>
                            </MessageBanner>
                        </div>
                    </div>
                </div>
            </div>
            {/* <hr /> */}

            {
                (!creationMode) && (
                    <div className="entity-saveSection">
                        <hr></hr>
                        <div style={{ marginRight: '9px', float: 'right', position: 'sticky', bottom: '10px' }}>
                            <button className="btn btn--secondary entityEditPanel-button" onClick={() => updateEntityChanges()} >
                                Update
                            </button>
                        </div>

                        {/* <button className="btn btn--secondary entityEditPanel-button" onClick={() => closeEditEntityPanel()}>
                                Cancel
                                <span className="icon-close icon-size-18 base-margin-left"></span>
                            </button> */}

                    </div>
                )
            }
            {
                (creationMode) && (
                    <div className="entity-saveSection base-margin-top" style={{ marginRight: '10px', float: 'right', position: 'sticky', bottom: '10px' }}>
                        <div>
                            <button className="btn btn--secondary entityEditPanel-button" onClick={() => { onCreateEntityClick(); }} disabled={!submitBtnEnable}>
                                Submit
                                {/* <span className="icon-add-contain icon-size-18 base-margin-left"></span> */}
                            </button>
                            {/* <button className="btn btn--secondary entityEditPanel-button" onClick={() => closeEditEntityPanel()}>
                                Cancel
                                <span className="icon-close icon-size-18 base-margin-left"></span>
                            </button> */}

                        </div>
                    </div>
                )

            }

        </div >
    )
}

export default EntityEditPanel;
