import { useState } from "react";
import { ACTION_TYPE_OPTIONS } from "../../constants";
import AnalyticsFilterComponent from "../analytics-filter-component/AnalyticsFilterComponent";
import ActionSelect from "../../../../custom-components/action-select/ActionSelect";
import { ChartHeader } from "../../style";

const AnalyticsSkillInternalChart = ({
  chart,
  unitHeight,
  unitWidth,
  skill,
}) => {
  const [action, setAction] = useState(ACTION_TYPE_OPTIONS[0]);
  const onActionChange = (data) => {
    setAction(data);
  };

  return (
    <div>
      <div className="base-margin">
        <ChartHeader>{chart.header}</ChartHeader>
        <span className="base-margin-left info-display">
          {chart.subHeader} &nbsp;
          <span
            tabindex="0"
            data-balloon-break
            data-balloon={chart.chartInfo}
            data-balloon-pos="down"
            data-balloon-length="medium"
          >
            <span className="  icon-info-outline"></span>
          </span>
          {chart.downloadInfo !== "" && (
            <span
              tabindex="1"
              data-balloon-break
              data-balloon={chart.downloadInfo}
              data-balloon-pos="down"
              data-balloon-length="medium"
            >
              &nbsp; <span className="icon-download"></span>
            </span>
          )}
        </span>

        {chart.key === "No" && (
          <span
            style={{ display: "inline-block", padding: "10px" }}
            key={chart.key}
          >
            <ActionSelect
              key={chart.key}
              defaultValue={action}
              options={ACTION_TYPE_OPTIONS}
              onValueChange={(data) => {
                onActionChange(data);
              }}
            />
          </span>
        )}
      </div>
      <hr />
      <AnalyticsFilterComponent
        chartKey={chart.key}
        chartId={chart.id}
        height={(7 * unitHeight) / 2}
        width={1.5 * unitWidth}
        skill={skill}
        action={action.value}
        insight="skill"
      />
    </div>
  );
};

export default AnalyticsSkillInternalChart;
