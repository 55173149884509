import Wrapper from "../../../../common-components/wrapper/Wrapper";
import AnalyticsAdminView from "../../analytics-admin-component/analytics-admin-view-component/AnalyticsAdminViewComponent";
import AnalyticsView from "../analytics-view-component/AnalyticsViewComponent";
import AnalyticsSkill from "../analytics-skill-component/AnalyticsSkillComponent";
import { Link } from "react-router-dom";
import { useState,useEffect } from "react";

import UserManagementService from "../../../user-management-module/user-management-service/UserManagementService";

const AnalyticsScreen = ({ tabValue }) => {
  const [tab, setTab] = useState(tabValue);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    console.log("Checking BotLite admin.");
		UserManagementService.isBotLiteAdmin()
			.then((res) => res.json())
			.then((res) => {
				if (res["status"] === "success" && res["isBotLiteAdmin"]) {
					setIsAdmin(true);
				}
			})
			.catch((err) => {
				console.log("Not BotLite admin.");
			});
	}, [])

  return (
    <Wrapper>
      <ul id="hjustified" class="tabs tabs--justified">
        <li
          id="hjustified-1"
          className={"tab " + (tab == 0 ? "active" : "")}
          onClick={() => setTab(0)}
        >
          <Link tabindex="0" to="/analytics/bot">
            Bot Insights
          </Link>
        </li>
        {
        isAdmin && <li
          id="hjustified-2"
          className={"tab " + (tab == 1 ? "active" : "")}
          onClick={() => setTab(1)}
        >
           <Link tabindex="0" to="/analytics/metrics">
            Platform Insights
          </Link>
        </li>
        }
        <li
          id="hjustified-3"
          className={"tab " + (tab == 2 ? "active" : "")}
          onClick={() => setTab(2)}
        >
          <Link tabindex="0" to="/analytics/skill">
            Skill Insights
          </Link>
        </li>
      </ul>
      <div id="hjustified-content" class="tab-content ">
        <div
          id="hjustified-1-content"
          className={"tab-pane " + (tab == 0 ? "active" : "")}
        >
         <AnalyticsView />
        </div>
        {
        isAdmin && <div
          id="hjustified-2-content"
          className={"tab-pane " + (tab == 1 ? "active" : "")}
        >
         <AnalyticsAdminView />
        </div>
        }
        <div
          id="hjustified-3-content"
          className={"tab-pane " + (tab == 2 ? "active" : "")}
        >
          <AnalyticsSkill />
        </div> 
      </div>
    </Wrapper>
  );
};

export default AnalyticsScreen;
