import { useState, useEffect } from 'react';
import './ModelTestingComponent.css';
import TrainingService from '../../training-service/TrainingService';
import Spinner from '../../../../custom-components/spinner/Spinner';
import MessageBanner from '../../../../custom-components/MessageBanner/MessageBanner';
import JsonView from '../../../../custom-components/json-view/JsonView';

const ModelTestingComponent = ({ selectedBot, displayMessage = (type, message) => { } }) => {
    const [messages, setMessages] = useState([])
    const [showMessageBanner, setShowMessageBanner] = useState(false);
    const [bannerMessage, setBannerMessage] = useState('');
    const [bannerMessageType, setBannerMessageType] = useState('success');
    const [showGuidance, setShowGuidance] = useState(false);
    const [isDataLoading, setDataLoading] = useState(false);
    const [json, setJson] = useState()
    const handleSubmit = event => {
        event.preventDefault();
        // this.sendMessage();
        event.target.reset();
    };

    const handleChange = event => {
        setDataLoading(true);
        setMessages([{ 'sender': 'User', 'text': event.target.value }]);
        let payload = {
            "text": event.target.value,
            "loggedInUser": "vivenkap",
            "agent_name": selectedBot.label,
            "beta": false

        }
        TrainingService.queryExpressions(selectedBot.value, payload).then(res => res.json()).then(res => {
            if (res.status == 'success') {
                setDataLoading(false);
                // setBannerMessageType('error');
                // setBannerMessage("Failed to query the expressions!");
                // setShowMessageBanner(true);
                setShowGuidance(false);
                setJson(res.result)
            }
            else {
                setJson({})
                setDataLoading(false);
                setBannerMessageType('error');
                setBannerMessage(res['details']);
                setShowMessageBanner(true);
                setShowGuidance(false);
            }
        }).catch(err => {
            setJson({})
            console.log(err);
            setDataLoading(false);
            setBannerMessageType('error');
            setBannerMessage("Failed to query the expressions!");
            setShowMessageBanner(true);
            setShowGuidance(false);
        })
    };
    return <>
        {
            isDataLoading && (<Spinner></Spinner>)
        }
        {
            showMessageBanner && <MessageBanner message={bannerMessage} messageType={bannerMessageType} onCloseClicked={() => { setShowMessageBanner(false) }}></MessageBanner>
        }
        {showGuidance && <div className="alert alert--info col-8 flex flex-center center" style={{ "position": "relative", "left": "35%", "transform": "translate(-300px,5px)", "zIndex": "100px" }}>
            <span>It seems like you have not yet created a bot. You can create a bot by clicking on the <b>Bot Screen</b> from the sidebar menu. If you require assistance, please click on the <b>Help</b> option or chat with the <b>AskBotLite chatbot</b>.</span>
        </div>}
        <div className="chatWindow panel panel--bordered base-margin center" style={{ height: 'calc(100vh - 200px)', width: '30vw', borderRadius: '2px', left: '33%' }}>
            <div className="chatInputWrapper" style={{ bottom: '0' }}>
                <form onSubmit={handleSubmit}>
                    <div class="form-group base-margin-bottom input--icon">
                        <div class="form-group__text">
                            <input id="input-type-email" type="text" placeholder="Enter your message..." onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    handleChange(e)
                                }
                            }} />
                            <button type="button" class="link" tabindex="-1">
                                <span class="icon-user"></span>
                            </button>
                        </div>
                    </div>
                    {/* <input
                    className="textarea input"
                    type="text"
                    placeholder="Enter your message..."
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            handleChange(e)
                        }
                    }}
                /> */}
                </form>
            </div>
            <ul className="chat" id="chatList">
                {messages.map(data => (
                    <div key={data.id}>
                        {(<li className="other">
                            <div className="msg">
                                <p>{data.sender}</p>
                                <div className="message"> {data.text} </div>
                            </div>
                        </li>
                        )}
                    </div>
                ))}
            </ul>
            {json && <div className='panel panel--well panel--bordered' style={{height:"calc(100% - 200px)", overflowY:"auto", wordBreak:"break-word"}}>
                <JsonView json_message={json}></JsonView>
            </div>}
        </div>
    </>

}


export default ModelTestingComponent;