import React, { useEffect, useRef, useState } from "react";
import Plotly from "plotly.js/dist/plotly";

const AnalyticsSankeyChartComponent = ({ data, layout }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    createChart();
  }, [data]);

  function createChart() {
    Plotly.newPlot(chartRef.current, data, layout);
  }

  return <div ref={chartRef}></div>;
};

export default AnalyticsSankeyChartComponent;
