import React from 'react';

export const HomeFooter=()=>{
    return (
        <div className="footer" style={{position: 'fixed', bottom:'0px',width:"100vw"}}>
            <div className="footer__links">
                <ul className="list list--inline">
                    <li><a href="http://www.cisco.com/cisco/web/siteassets/contacts/index.html" >Contacts</a></li>
                    <li><a href="https://secure.opinionlab.com/ccc01/o.asp?id=jBjOhqOJ" >Feedback</a></li>
                    <li><a href="https://www.cisco.com/c/en/us/about/help.html" >Help</a></li>
                    <li><a href="http://www.cisco.com/c/en/us/about/sitemap.html" >Site Map</a></li>
                    <li><a href="https://www.cisco.com/c/en/us/about/legal/terms-conditions.html" >Terms & Conditions</a></li>
                    <li><a href="https://www.cisco.com/c/en/us/about/legal/privacy-full.html" >Privacy Statement</a></li>
                    <li><a href="https://www.cisco.com/c/en/us/about/legal/privacy-full.html#cookies" >Cookie Policy</a></li>
                    <li><a href="https://www.cisco.com/c/en/us/about/legal/trademarks.html" >Trademarks</a></li>
                </ul>
            </div>
        </div>
    )
}

export default HomeFooter;

