const dialog_url = process.env.REACT_APP_ROOT_URL
const DialogService = {

    getRules: (skill) => {
        var reqHeaders = new Headers();
        reqHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'GET',
            headers: reqHeaders
        };

        return fetch(`${dialog_url}/skill/${skill}/dialog`, requestOptions)

    },

    getNodes: (skill, ruleId) => {

        var reqHeaders = new Headers();
        reqHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'GET',
            headers: reqHeaders
        };

        return fetch(`${dialog_url}/skill/${skill}/dialog/${ruleId}`, requestOptions);

    },

    saveDialog: (skill, data) => {

        var reqHeaders = new Headers();
        reqHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'POST',
            headers: reqHeaders,
            body: JSON.stringify(data)
        };

        return fetch(`${dialog_url}/skill/${skill}/dialog`, requestOptions);
    },
    getActions: (skill) => {
        var reqHeaders = new Headers();
        reqHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'GET',
            headers: reqHeaders
        };

        return fetch(`${dialog_url}/skill/${skill}/actions`, requestOptions);
    },
    getEntitiesAndContextVariables: (skill) => {
        var reqHeaders = new Headers();
        reqHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'GET',
            headers: reqHeaders
        };

        return fetch(`${dialog_url}/skill/${skill}/entitiesAndContextVariables`, requestOptions);
    },
    deleteNodeById: (skill_id, dialog_id, node_id) => {
        var reqHeaders = new Headers();
        reqHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'DELETE',
            headers: reqHeaders
        };
        return fetch(`${dialog_url}/skill/${skill_id}/dialog/${dialog_id}/node/${node_id}`, requestOptions)
    },
    getSecret: (skill, secret) => {
        var reqHeaders = new Headers();
        reqHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'GET',
            headers: reqHeaders
        };

        return fetch(`${dialog_url}/skill/${skill}/secret/${secret}`, requestOptions);
    },
    getAllSecret: (skill) => {
        var reqHeaders = new Headers();
        reqHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'GET',
            headers: reqHeaders
        };

        return fetch(`${dialog_url}/skill/${skill}/secret`, requestOptions);
    },
    createAPI: (skill, data) => {

        var reqHeaders = new Headers();
        reqHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'POST',
            headers: reqHeaders,
            body: JSON.stringify(data)
        };

        return fetch(`${dialog_url}/skill/${skill}/secret`, requestOptions);
    },
    createDB: (skill, data) => {

        var reqHeaders = new Headers();
        reqHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'POST',
            headers: reqHeaders,
            body: JSON.stringify(data)
        };

        return fetch(`${dialog_url}/skill/${skill}/secret`, requestOptions);
    },
    deleteSecret: (skill, secret) => {
        var reqHeaders = new Headers();
        reqHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'DELETE',
            headers: reqHeaders
        };

        return fetch(`${dialog_url}/skill/${skill}/secret/${secret}`, requestOptions);
    },
    deleteSecrets: (skill, secret) => {
        var reqHeaders = new Headers();
        reqHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'DELETE',
            headers: reqHeaders,
            body: JSON.stringify(secret)
        };

        return fetch(`${dialog_url}/skill/${skill}/secret`, requestOptions);
    },
    updateSecret: (skill, data) => {
        var reqHeaders = new Headers();
        reqHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'PUT',
            headers: reqHeaders,
            body: JSON.stringify(data)
        };

        return fetch(`${dialog_url}/skill/${skill}/secret`, requestOptions);
    }
}

export default DialogService;