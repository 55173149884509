import './ResponseNode.css';
import { useEffect, useState } from 'react';
import AceEditor from "react-ace";
import { AdaptiveCard } from 'adaptivecards-react';
import CodeEditor from '../../../../../../custom-components/code-editor/CodeEditor';
import Modal from 'react-bootstrap/Modal';
import MessageBanner from "../../../../../../custom-components/MessageBanner/MessageBanner";
import FileDropZone from "../../../../../../custom-components/file-drop-zone/FileDropZone";
import isAlphanumeric from 'validator/lib/isAlphanumeric';

const defaultWxTCardsPayLoad = `{
		"contentType": "application/vnd.microsoft.card.adaptive",
		"content": {
			"$schema": "http://adaptivecards.io/schemas/adaptive-card.json",
			"type": "AdaptiveCard",
			"version": "1.0",
			"body": [
			{
				"type": "ColumnSet",
				"columns": [
					{
						"type": "Column",
						"width": 2,
						"items": [
							{
								"type": "TextBlock",
								"text": "Instruction goes here",
								"weight": "bolder",
								"size": "medium"
							},
							{
								"type": "TextBlock",
								"text": "Input Field 1",
								"wrap": true
							},
							{
								"type": "Input.Text",
								"id": "myInput1",
								"maxLength":10000,
								"placeholder": "placeholder text"
							},
							{
								"type": "TextBlock",
								"text": "Input Field 2",
								 "wrap": true
							},
							{
								"type": "Input.Text",
								"id": "myInput2",
								"maxLength":10000,
								"placeholder": "placeholder text"
							}
						]
					}
				]
			}
		],
		"actions": [
			{
				"type": "Action.Submit",
				"title": "Submit"
			}
		]
	}}`;

function ResponseNodeDetails({ node, closeFunction }) {
	const [forceReRender, setForceReRender] = useState(false)
	const [focusedTextareaIndex, setFocusedTextareaIndex] = useState(undefined)
	const [text, setText] = useState([''])
	const [card, setCard] = useState(defaultWxTCardsPayLoad)
	const [code, setCode] = useState(node.data.formatScript)
	const [file, setFile] = useState({ rows: 0, columns: 0, format: 'CSV' })
	const [preview, setShowPreview] = useState(false);
	const [showCreateDataUriScreen, setShowCreateDataUriScreen] = useState(false);
	const [dataUriUploaded, setDataUriUploaded] = useState(false);
	const [showValidationErrorMessage, setShowValidationErrorMessage] = useState(false);
	const [dataValidationErrorMessage, setDataValidationErrorMessage] = useState('');
	const [showDataUriHelp, setShowDataUriHelp] = useState(false);
	let hostConfig = {
		fontFamily: "Segoe UI, Helvetica Neue, sans-serif"
	};

	useEffect(() => {
		switch (node.data.type) {
			case 'card':
				setCard(node.data.card)
				break
			case 'file':
				setFile({ rows: node.data.rows, columns: node.data.columns, format: node.data.format })
				break
			case 'text':
				setText(node.data.response)
				break
			default:
				break
		}
	}, [node])

	const closeSplit = () => { closeFunction() }

	const previewPayload = () => {
		let previewData = <span className="messageTxt pageTour4"><AdaptiveCard payload={(() => {
			try {
				let jsonPayload = JSON.parse(node.data.card);
				if ("content" in jsonPayload) {
					return jsonPayload["content"]
				}
				return jsonPayload;

			}
			catch (e) {
				console.log(e)
				return {};
			}

		})()} hostConfig={hostConfig} /></span>;

		return <>{previewData}</>;
	}

	const previewModal = () => {
		return <Modal size="lg" centered show={preview} onHide={() => { setShowPreview(false) }} animation={false} >
			<Modal.Header >
				<h5>Preview Card</h5>
				<div className=' btn--small btn--icon' onClick={() => { setShowPreview(false) }}>
					<span className="icon-close icon-size-24"></span>
				</div>
			</Modal.Header>
			<Modal.Body>
				<div className='panel panel--bordered'>
					<div className="borderBottom paddingBottom12">Card Preview</div>
					<div className='base-margin-top'>
						<img src={process.env.PUBLIC_URL + "/images/notification.png"} />
						{previewPayload()}
					</div>
				</div>
			</Modal.Body >
		</Modal >

	}

	const processImageData = (data) => {
		let base64String = "";
		let file = data[0];
		if (file.size > 1000000) {
			setShowValidationErrorMessage(true);
			setDataValidationErrorMessage('File size is too large. Max file size limit is 1 MB.');
			setDataUriUploaded(false);
			return false;
		} else {
			setDataUriUploaded(true);
			setShowValidationErrorMessage(false);
			let reader = new FileReader();
			reader.onloadend = function () {
				base64String = reader.result;
				console.log(base64String);
				let output = document.getElementById('output').value = base64String;
			}
			reader.readAsDataURL(file);
		}
	}

	const copyData = () => {
		var copyText = document.getElementById("output");
		copyText.select();
		copyText.setSelectionRange(0, 99999);
		navigator.clipboard.writeText(copyText.value);
	}

	const imageToDataUri = () => {
		return <Modal size="lg" show={showCreateDataUriScreen} animation={false}>
			<Modal.Header>
				<h5>Image to Data URI Converter</h5>
				<span className='icon-close icon-size-24' onClick={() => { setShowCreateDataUriScreen(false); }}></span>
			</Modal.Header>
			<Modal.Body>
				{
					showValidationErrorMessage && (<MessageBanner message={dataValidationErrorMessage} messageType={"error"} onCloseClicked={() => { setShowValidationErrorMessage(false) }}></MessageBanner>)
				}
				<div style={{ "position": "absolute", "right": "16px", "top": "16px" }}>
					<button className="btn btn--dark" onClick={() => { setShowDataUriHelp((prev) => !prev) }}>Help</button>
				</div>

				<FileDropZone style={{ marginTop: "50px" }} type="file" onFileuploaded={(data) => processImageData(data)} allowedFileTypes={[".jpeg/.png", "image/jpeg", "image/png"]}></FileDropZone>

				<br></br>
				{showDataUriHelp && <MessageBanner>

					<div style={{ marginBottom: "20px" }}><h5>How to use Image to Data URI converter: </h5></div>

					<div style={{ marginLeft: "-8px" }}>This converter can be used to include images in Adapative Cards. First, click the above area to browse for an image file or drag and drop the image into the zone. After the image is inputted, the Data URI text box will populate with the corresponding Data URI for the selected image. This converter only accepts .png or .jpeg images which are under 1 MB.</div>
					<br></br>
				</MessageBanner>
				}

				<div className="panel panel--raised base-margin-top" style={{ marginBottom: "5%" }}>
					<div className="row" style={{ "marginLeft": "0px" }}>
						<div className="col-10 col-sm-10 col-md-11 col-lg-11 col-xl-11 col-xxl-12 col-xxxl-11 form-group base-margin-bottom">
							<div className="form-group__text">
								<input id="output" type="text" name="Data URI" autoComplete='off' value="" readonly />
								<h6 htmlFor="input-type-email">Data URI</h6>
							</div>
							<div className="copyDataButton">
								<button className='btn btn--primary text-center-xs-down text-center-sm-down text-center-md-down text-center-lg-down text-center-xl-down text-center-xxl-down text-center-xxxl-down base-margin-left' onClick={() => { copyData(false) }} style={{ justifyContent: "center", paddingLeft: "16px", paddingRight: "16px", marginTop: "2%" }} disabled={dataUriUploaded === false}>
									<span>Copy Data URI to Clipboard</span>
								</button>
							</div>
						</div>
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<button className="btn btn--dark" onClick={() => { setShowCreateDataUriScreen(false) }}>Close</button>
			</Modal.Footer>
		</Modal>
	}

	const displayAugment = () => {
		switch (node.data.type) {
			case 'file':
				return (<>
					<div className="panel panel--bordered" >
						<div className="row borderBottom paddingBottom12">
							<div className='col'>Response as Attachment</div>
						</div>
						<div className='base-margin'>
							<div className="row responseBox">
								<div className="col-lg- col-md-6 col-sm-6">
									<div className="form-group base-margin-bottom">
										<div className="form-group__text">
											<input type="number" placeholder="Number of Rows" id="rowsNum" name="rows" min="1" max="100000" value={node.data.rows} onChange={event => { node.data.rows = event.target.value; setForceReRender(!forceReRender) }}></input>
										</div>
									</div>
								</div>
								<div className="col-lg-6 col-md-6 col-sm-6">
									<div className="form-group base-margin-bottom">
										<div className="form-group__text">
											<input type="number" placeholder="Numbar of Columns" id="columnsNum" name="columns" min="1" max="100000" value={node.data.columns} onChange={event => { node.data.columns = event.target.value; setForceReRender(!forceReRender) }}></input>
										</div>
									</div>
								</div>
							</div>
							<div className='row'>
								<div className='col-3'>Select the file format</div>
								<div className='col-9'>
									<div className="form-group form-group--inline">
										<label className="radio">
											<input type="radio" checked={node.data.format === 'CSV'} onChange={() => { node.data.format = 'CSV'; setForceReRender(!forceReRender) }} name="radio-inline" />
											<span className="radio__input"></span>
											<span className="radio__label">CSV</span>
										</label>
									</div>
									<div className="form-group form-group--inline">
										<label className="radio">
											<input type="radio" checked={node.data.format === 'TXT'} onChange={() => { node.data.format = 'TXT'; setForceReRender(!forceReRender) }} name="radio-inline" />
											<span className="radio__input"></span>
											<span className="radio__label">TXT</span>
										</label>
									</div>
									<div className="form-group form-group--inline">
										<label className="radio">
											<input type="radio" checked={node.data.format === 'JSON'} onChange={() => { node.data.format = 'JSON'; setForceReRender(!forceReRender) }} name="radio-inline" />
											<span className="radio__input"></span>
											<span className="radio__label">JSON</span>
										</label>
									</div>
								</div>
							</div>
						</div>


					</div>
					<div className="row base-margin">
						<input type='button' className='btn btn--small btn--secondary' value='Switch to Static Message' onClick={() => { node.data.type = 'text'; node.data.response = ['']; setForceReRender(!forceReRender) }} />
						<input type='button' className='btn btn--small btn--secondary' value='Switch to Adaptive Card' onClick={() => { node.data.type = 'card'; node.data.card = defaultWxTCardsPayLoad; setForceReRender(!forceReRender) }} />
					</div>
				</>
				)
			case 'card':
				return (<>
					<div className='btn-group base-margin-bottom'>
						<button className={node.data.cardType && node.data.cardType == 'static' ? 'btn btn--dark btn--small' : 'btn btn--ghost btn--small'} onClick={() => { node.data.cardType = 'static'; setForceReRender(!forceReRender) }}>Static Card</button>
						<button className={node.data.cardType && node.data.cardType == 'dynamic' ? 'btn btn--dark btn--small' : 'btn btn--ghost btn--small'} onClick={() => { node.data.cardType = 'dynamic'; node.data.showFormatScript = true; setForceReRender(!forceReRender) }}>Dynamic Card</button>
					</div>
					<div className="panel panel--bordered">
						<div className="row borderBottom paddingBottom12">
							<div className='col-9'>Attach Webex Input Card</div>
							<div className='col-3' style={{ float: 'right' }}>
								<button className='btn btn--primary btn--small' onClick={() => { setShowPreview({ preview: !preview }) }}>Show Preview</button>
							</div>


						</div>
						<div className='margin_left_zero base-margin'>

							{node.data.cardType && node.data.cardType == 'static' && <div className="row no-gutters">
								<div className="col">
									<div className="form-group base-margin-bottom">
										<div className="form-group__text">
											<AceEditor
												mode="json"
												theme="dawn"
												width="100%"
												value={node.data.card}
												onChange={value => { node.data.card = value; setForceReRender(!forceReRender) }}
											/>
										</div>
									</div>
								</div>
							</div>}
							<div className=" row justify-content-beginning">
								<button className="btn btn--small btn--secondary" onClick={() => setShowCreateDataUriScreen(true)} >Image to Data URI</button>
							</div>
						</div>
						{previewModal()}
						{imageToDataUri()}

						{/* {preview && <div className='panel panel--bordered'>
							<div className="borderBottom paddingBottom12">Card Preview</div>
							<div className='base-margin-top'>
								<img src={process.env.PUBLIC_URL + "/images/notification.png"} />
								{previewPayload()}
							</div>
						</div>} */}
					</div>

					<div className="row base-margin">
						<input type='button' className='btn btn--small btn--secondary' value='Switch to Static Message' onClick={() => { node.data.type = 'text'; node.data.response = ['']; setForceReRender(!forceReRender) }} />
						<input type='button' className='btn btn--small btn--secondary' value='Switch to File Attachment' onClick={() => { node.data.type = 'file'; node.data.rows = 1; node.data.columns = 1; node.data.format = 'CSV'; setForceReRender(!forceReRender) }} />
					</div>
				</>
				)

			case 'text':
				return (<>
					<div className="panel panel--bordered" >
						<div>
							<div className="row borderBottom paddingBottom12"  >
								<div className="col">Display Message</div>
							</div>

						</div>
						<div className='base-margin'>
							<form className='col base-margin'>
								{
									node.data.response && node.data.response.map((value, index, array) => {
										return <div key={index} id="responseDisplayMessage" className='row'>
											<div className="form-group base-margin-bottom col-11">
												<div className="form-group__text">
													<div className='row'>
														<textarea id="textarea-state-default" rows="8" value={value} autoFocus={focusedTextareaIndex === index} onChange={event => { array[index] = event.target.value; setForceReRender(!forceReRender) }} className="base-margin-right"></textarea>
													</div>
												</div>
											</div>
											<button className='btn btn--small btn--secondary btn--icon' onClick={() => {node.data.response.splice(index, 1); setForceReRender(!forceReRender);}}><span className='icon-delete'></span></button>
										</div>
									})
								}
								{node.data.response != [] && <div id="responseDisplayMessage">
									<div className="form-group base-margin-bottom">
										<input className='btn btn--small btn--secondary' type='button' value='Add another option' onClick={() => { console.log(node.data.response.length); setFocusedTextareaIndex(node.data.response.push('') - 1); setForceReRender(!forceReRender) }} disabled={node.data.response[node.data.response.length - 1] == ''} />
									</div>
								</div>}
							</form>
						</div>
					</div>
					<div className="row base-margin">
						<input type='button' className='btn btn--small btn--secondary' value='Switch to Adaptive Card' onClick={() => { node.data.type = 'card'; node.data.card = defaultWxTCardsPayLoad; setForceReRender(!forceReRender) }} />
						<input type='button' className='btn btn--small btn--secondary' value='Switch to File Attachment' onClick={() => { node.data.type = 'file'; node.data.rows = 1; node.data.columns = 1; node.data.format = 'CSV'; setForceReRender(!forceReRender) }} />
					</div>
				</>
				)


			default:
				return (
					<div className="row">
						<input type='button' className='btn btn--small btn--secondary' value='Static Message' onClick={() => { node.data.type = 'text'; node.data.response = ['']; setForceReRender(!forceReRender) }} />
						<input type='button' className='btn btn--small btn--secondary' value='Adaptive Card' onClick={() => { node.data.type = 'card'; node.data.card = defaultWxTCardsPayLoad; node.data.cardType = 'static'; setForceReRender(!forceReRender) }} />
						<input type='button' className='btn btn--small btn--secondary' value='File Attachment' onClick={() => { node.data.type = 'file'; node.data.rows = 1; node.data.columns = 1; node.data.format = 'CSV'; setForceReRender(!forceReRender) }} />
					</div>
				)
		}
	}

	const inputHandlerNameChange = (data) => {

		setShowValidationErrorMessage(false);

		node.data.name = data
		console.log(data)
		const validName = () => {
			if (data === '' || data.length < 5) {
				setShowValidationErrorMessage(true);
				setDataValidationErrorMessage('Name should be greater than 5 characters.');
				return false;
			}

			if (!isAlphanumeric(data, 'en-US', { "ignore": "_" })) {
				setShowValidationErrorMessage(true);
				setDataValidationErrorMessage('Special characters other than _ is not allowed in name.');
				return false;
			}
		}

		let valid = validName();
		// node.data.name = temp;
		const empty = data != '' && data.length > 1;

		const result = empty && valid


	}

	return <div className="panel panel--raised panel--bordered" syle={{ height: '100%' }}>
		<h6>Edit Response Node <span onClick={() => closeSplit()} className='icon-close' style={{ float: 'right' }}></span></h6>
		<hr />
		<div style={{ maxHeight: '65vh', overflowY: 'auto' }}>
			{
				showValidationErrorMessage && (<div className="popBanner">{dataValidationErrorMessage}</div>)
			}

			<div className="form-group base-margin-bottom" >
				<div className="form-group__text">
					{/* <input id="input-type-number" type="text" name="rule_name" onChange={event => { setNewNodeInfo(obj => { let objCopy = { ...obj }; objCopy.name = event.target.value; return objCopy }) }} /> */}
					<input id="input-type-number" type="text" name="rule_name" autoComplete='off' value={node.data.name} onChange={event => { inputHandlerNameChange(event.target.value); setForceReRender(!forceReRender) }} />
					<label htmlFor="input-type-number">Response Node Name <span className='required'>*</span> </label>
				</div>
			</div>

			{node.data.type != undefined && <input type='button' className='btn btn--small btn--secondary base-margin-bottom' value={node.data.showFormatScript ? 'Remove Format Script' : 'Add Format Script'} onClick={() => {
				node.data.showFormatScript = !node.data.showFormatScript; setForceReRender(!forceReRender); if (!node.data.showFormatScript) {
					node.data.formatScript = '';
				}
			}} />}
			{node.data.showFormatScript && <div className='panel panel--bordered base-margin-bottom'>
				<div className='row borderBottom paddingBottom12'>
					<div className='col'>Format Script</div>
				</div>
				<div className='base-margin-top'>
					{/* <AceEditor
						mode='python'
						width="100%"
						value={node.data.formatScript}
						onChange={value => { node.data.formatScript = value; setCode(value) }}
					/> */}
					<div style={{ height: '45vh', overflowY: 'auto' }}>
						<CodeEditor editorLanguage="python" updateFormatScriptFromEditor={(data) => { node.data.formatScript = data; setCode(data) }} code={node.data.formatScript}></CodeEditor>
					</div>
				</div>
			</div>}

			<div className='base-margin-left'>
				{displayAugment()}
			</div>
		</div>
	</div >
}

export default ResponseNodeDetails;
