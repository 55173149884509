import { useState, useRef, useEffect } from 'react'
import { propTypes } from 'react-bootstrap/esm/Image'
import InputSelect from '../../../../../../custom-components/CreatableSelect/CreatableSelect'
import MultiSelect from '../../../../../../custom-components/multi-select/MultiSelect'
import DialogService from '../../../../dialog-service/DialogService'
import CheckUncheck from './Checklist'
import Spinner from '../../../../../../custom-components/spinner/Spinner'
import SearchSelect from '../../../../../../custom-components/search-select/SearchSelect';
import { isGridCellRoot } from '@mui/x-data-grid/utils/domUtils'

let lastEditMode = 0

export default function DBForm({ node, changeValue }) {
    const [newDBInfo, setNewDBInfo] = useState({ name: '', host: '', userId: '', password: '', secret: '', skills: [] })
    const [oldDBInfo, setOldDBInfo] = useState({})
    const [showEditDB, setShowEditDB] = useState(3)  // value 3 is used to close and hide the content it dosent correspond to any tab
    const [dbName, setDbName] = useState(node.data.db)
    const [dbQuery, setDbQuery] = useState(node.data.query)
    const [checkedAll, setCheckedAll] = useState(false)
    const [showValidationMessage, setShowValidationMessage] = useState(false);
    const [dataValidationMessage, setDataValidationMessage] = useState('');
    const [validationMessageType, setValidationMessageType] = useState('success')
    const [showDelete, setShowDelete] = useState(false)
    const [isDataLoading, setDataLoading] = useState(false)
    const [isDBClickedForExpansion, setIsDBClickedForExpansion] = useState(false);
    useEffect(() => {
        getDBList();
    }, [])
    useEffect(()=>{
        setIsDBClickedForExpansion(false);
    },[showEditDB])
    const [dbs, setDbs] = useState([]);

    const getDBList = () => {
        var re = new RegExp("^(db_[a-zA-Z]+[_a-zA-Z0-9]*)$");
        DialogService.getAllSecret(node.data.skill).then(res => res.json()).then(res => {
            if (res.status == 'success') {
                let secret = res.secret;
                let db_secret = []

                console.log(re.test('db_abs'))
                for (let i of secret) {
                    console.log("test", re.test(i.label))
                    if (re.test(i.label)) {
                        db_secret.push({
                            id: db_secret.length,
                            name: i.label,
                            label: i.label,
                            value: i.value,
                            isChecked: false,
                        })
                    }
                }
                //update only if no db is selected before
                if (dbName === undefined) {
                    setDbName(db_secret[0])
                    changeValue('db', db_secret[0])

                }
                setDbs(db_secret);
            }
            else {
                setValidationMessageType('warning')
                setValidationMessageType(res.details)
                setShowValidationMessage(true)
            }
        }).catch(err => {
            setValidationMessageType('warning')
            setValidationMessageType('Something went wrong. Please try again later.')
            setShowValidationMessage(true)
        })
    }

    const getDB = (value, label) => {
        setDataLoading(true);
        setIsDBClickedForExpansion(false);
        DialogService.getSecret(node.data.skill, value).then(res => res.json()).then(res => {
            if (res.status === 'success') {
                let obj = JSON.parse(res.secret)
                obj.db_secret = label
                setOldDBInfo(obj)
            }
            setDataLoading(false);
            setIsDBClickedForExpansion(true);
        }).catch(err => {
            setValidationMessageType('warning')
            setDataValidationMessage('Something went wrong while retrieving the dbs. Please try again later.')
            setShowValidationMessage(true)
            setDataLoading(false)
        })
    }

    const deleteDBSecret = (data) => {
        setDataLoading(true)
        let secretList = []
        for (let i of data) {
            secretList.push(i['value'])
        }

        console.log(secretList)
        let payload = {
            "secret_names": secretList
        }
        DialogService.deleteSecrets(node.data.skill, payload).then(res => res.json()).then(res => {
            if (res.status == 'success') {
                setValidationMessageType('success')
                setDataValidationMessage(res.details || 'DB(s) deleted successfully')
                setShowValidationMessage(true)
                setTimeout(() => {
                    setShowValidationMessage(false)
                }, 15000)
                getDBList()
                setDataLoading(false)
                setShowEditDB(3)
                setCheckedAll(false)
            }
            else {
                setValidationMessageType('warning')
                setDataValidationMessage(res.details || 'Failed to delete DB(s)')
                setShowValidationMessage(true)
                setTimeout(() => {
                    setShowValidationMessage(false)
                }, 15000)
                setDataLoading(false)
            }
        }).catch(err => {
            setValidationMessageType('warning')
            setDataValidationMessage('Something went wrong. Please try again later.')
            setShowValidationMessage(true)
            setTimeout(() => {
                setShowValidationMessage(false)
            }, 15000)
            setDataLoading(false)
        })
    }

    const deleteFunction = () => {
        let deletedArray = []
        for (let db of dbs)
            if (db.isChecked)
                deletedArray.push(db)

        console.log(deletedArray)
        deleteDBSecret(deletedArray)
        setShowDelete(false)
        setCheckedAll(false)
    }

    const changeFunction = index => {
        let dbsCopy = [...dbs]
        const temp = !dbsCopy[index].isChecked
        dbsCopy[index].isChecked = temp
        setDbs(dbsCopy)
        for (let element of dbsCopy)
            if (element.isChecked === false) {
                setCheckedAll(false)
                return
            }
        setCheckedAll(true)
    }

    const changeAllFunction = () => {
        let dbsCopy = [...dbs]
        let temp = !checkedAll
        for (let element of dbsCopy)
            element.isChecked = temp
        setDbs(dbsCopy)
        setCheckedAll(temp)
    }

    const addDBData = () => {
        setDataLoading(true)
        let secret = {
            "db_host": newDBInfo.host,
            "db_name": newDBInfo.name,
            "username": newDBInfo.userId,
            "password": newDBInfo.password
        }
        let payload = {
            "secret_name": "db_" + newDBInfo.secret,
            // "secret_value": "{'db_host':'"+ newDBInfo.host + "','db_name':'" + newDBInfo.name + "','username':'" + newDBInfo.userId + "','password':'" + newDBInfo.password + "'}"
            "secret_value": JSON.stringify(secret)
        }
        //console.log(payload)
        DialogService.createDB(node.data.skill, payload).then(res => res.json()).then(res => {
            if (res.status == 'success') {
                console.log(res)
                setValidationMessageType('success')
                setDataValidationMessage("Successfully created DB!")
                setShowValidationMessage(true)
                setTimeout(() => {
                    setShowValidationMessage(false)
                }, 15000)
                setDataLoading(false)
                console.log(showValidationMessage)
                getDBList(node.data.skill)
                setShowEditDB(3)
            }
            else {
                setValidationMessageType('warning')
                setDataValidationMessage(res.details)
                setShowValidationMessage(true)
                setTimeout(() => {
                    setShowValidationMessage(false)
                }, 15000)
                setDataLoading(false)
            }
        }).catch(err => {
            setValidationMessageType('warning')
            setDataValidationMessage('Something went wrong. Please try again later.')
            setShowValidationMessage(true)
            setTimeout(() => {
                setShowValidationMessage(false)
            }, 15000)
            setDataLoading(false)
        })
    }

    const updateDBData = () => {
        setDataLoading(true)
        let secret = {
            "db_host": oldDBInfo.db_host,
            "db_name": oldDBInfo.db_name,
            "username": oldDBInfo.username,
            "password": oldDBInfo.password
        }
        let payload = {
            "secret_name": `${oldDBInfo.db_secret}__${node.data.skill}`,
            "secret_value": JSON.stringify(secret)
        }
        DialogService.updateSecret(node.data.skill, payload).then(res => res.json()).then(res => {
            if (res.status === 'success') {
                setValidationMessageType('success')
                setDataValidationMessage('Successfully updated DB')
                setShowValidationMessage(true)
                setTimeout(() => setShowValidationMessage(false), 15000)
                setDataLoading(false)
                setShowEditDB(3)
            } else {
                setValidationMessageType('warning')
                setDataValidationMessage(res.details)
                setShowValidationMessage(true)
                setTimeout(() => setShowValidationMessage(false), 15000)
                setDataLoading(false)
            }
        }).catch(err => {
            setValidationMessageType('warning')
            setDataValidationMessage('Something went wrong. Please try again later.')
            setShowValidationMessage(true)
            setTimeout(() => setShowValidationMessage(false), 15000)
            setDataLoading(false)
        })
    }

    const checkEmptyData = () => {
        if (oldDBInfo.db_host == '' || oldDBInfo.db_secret == '' || oldDBInfo.db_name == '' || oldDBInfo.password == '' || oldDBInfo.username == '')
            return true
        return false
    }

    const getDBFromValue = (value) => {
        for (let i of dbs) {
            if (i.value == value) {
                // getDB(i.value, i.label)
                return i
            }
        }
    }

    return <div>
        {
            isDataLoading && (<Spinner></Spinner>)
        }
        {showValidationMessage && <div className={`alert ${validationMessageType == 'success' ? 'alert--success' : 'alert--warning'}`}>
            <div class="alert__message">{dataValidationMessage}</div>
        </div>}
        <div className="row">
            <div className='row addDeleteDbToggle base-margin'>
                <div className="col-lg-12 col-md-12 col-12">
                    <label>Add / Delete / Edit DB</label>
                    <label className="switch base-margin-left">
                        <input type="checkbox" checked={showEditDB !== 3} onChange={() => { if (showEditDB === 3) setShowEditDB(lastEditMode); else setShowEditDB(3) }} />
                        <span className="switch__input"></span>
                    </label>
                </div>
            </div>
            <br></br>

            {showEditDB !== 3 && <div className='addDeleteDbContner base-margin'>
                <div className="">
                    <div className='addDeleteDb'>
                        <ul id="bordered" className="tabs tabs--bordered">
                            <li id="bordered-1" class={showEditDB === 0 ? "tab active" : "tab"}>
                                <a tabindex="0" onClick={() => { setShowEditDB(0); lastEditMode = 0 }}>Add New DB</a>
                            </li>
                            <li id="bordered-2" class={showEditDB === 1 ? "tab active" : "tab"}>
                                <a tabindex="1" onClick={() => { setShowEditDB(1); lastEditMode = 1 }}>Delete DB</a>
                            </li>
                            <li id='bordered-3' className={showEditDB === 2 ? 'tab active' : 'tab'}>
                                <a tabindex='2' onClick={() => { setShowEditDB(2); lastEditMode = 2 }}>Edit DB</a>
                            </li>
                        </ul>
                        <div id="bordered-content" className="tab-content">
                            <div id="bordered-1-content" className="tab-pane active">
                                {showEditDB === 0 && <div className='addDb'>

                                    <div className="separatorBorder">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <div className="form-group__text">
                                                    <input id="input-layout-stacked-1" placeholder='Enter Connection Name' type="text" value={newDBInfo.secret} onInput={event => { setNewDBInfo({ ...newDBInfo, secret: event.target.value }) }} />
                                                    <label htmlFor="input-layout-stacked-1">Connection Name<span className='mandatoryIcon'>*</span></label>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="form-group__text">
                                                    <input id="input-layout-stacked-1" placeholder='Enter DB Name' type="text" value={newDBInfo.name} onInput={event => { setNewDBInfo({ ...newDBInfo, name: event.target.value }) }} />
                                                    <label htmlFor="input-layout-stacked-1">DB Name<span className='mandatoryIcon'>*</span></label>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="form-group__text">
                                                    <input id="input-layout-stacked-2" placeholder='Enter DB Host' type="text" value={newDBInfo.host} onInput={event => { setNewDBInfo({ ...newDBInfo, host: event.target.value }) }} />
                                                    <label htmlFor="input-layout-stacked-2">DB Host<span className='mandatoryIcon'>*</span></label>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="form-group__text">
                                                    <input id="input-layout-stacked-2" placeholder='Enter User Id' type="text" value={newDBInfo.userId} onInput={event => { setNewDBInfo({ ...newDBInfo, userId: event.target.value }) }} />
                                                    <label htmlFor="input-layout-stacked-2">User Id<span className='mandatoryIcon'>*</span></label>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="form-group__text">
                                                    <input id="input-layout-stacked-2" placeholder='Enter Password' type="password" value={newDBInfo.password} onInput={event => { setNewDBInfo({ ...newDBInfo, password: event.target.value }) }} style={{ fontSize: '12px' }} />
                                                    <label htmlFor="input-layout-stacked-2">Password<span className='mandatoryIcon'>*</span></label>
                                                </div>
                                            </div>
                                            {/* <div className="form-group">
                                                <div className="form-group__text">
                                                    <label htmlFor="input-layout-stacked-2">Add to other Skills<span className='mandatoryIcon'>*</span></label>
                                                    <MultiSelect
                                                        option={dbs}
                                                        placeholder="Select domain"
                                                        selectedValues={newDBInfo.skills}
                                                        onValueChange={values => { setNewDBInfo({ ...newDBInfo, skills: values }) }}
                                                    />
                                                </div>
                                            </div> */}
                                            <div className="col-6 col-lg-6 base-margin-top">
                                                <button className="btn btn--secondary btn--small" onClick={() => { addDBData(); setNewDBInfo({ name: '', host: '', userId: '', password: '', secret: '' }); }} disabled={checkEmptyData()} >Add DB</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                            </div>
                            <div id="bordered-2-content" className="tab-pane active">
                                {showEditDB === 1 && <div>
                                    {showDelete && <div className="base-margin-left base-margin-right col-11">
                                        <div class="alert alert--warning">
                                            <div class="alert__message">Are you sure you want to delete the selected DB?</div>
                                            <button className='btn btn--small btn--secondary base-margin-right base-margin-left' onClick={() => { deleteFunction() }}>Yes</button><button className='btn btn--small btn--secondary' onClick={() => { setShowDelete(false) }}>No</button>
                                        </div>
                                    </div>}
                                    <CheckUncheck listname="DB list" checkboxlist={dbs} changeFunction={changeFunction} changeAllFunction={changeAllFunction} checkedAll={checkedAll} >
                                        <div className="deletebtn">
                                            <button className="btn btn--small btn--secondary" onClick={() => { setShowDelete(true); }}>Delete</button>
                                        </div>
                                    </CheckUncheck></div>}
                            </div>
                            <div id='bordered-3-content' className='tab-pane active'>
                                {showEditDB === 2 && <div>
                                    {/*<div className='responsive-table table_div'>
                                    <table className='table table--bordered'>
                                        <tbody>
                                            {dbs.map((db, index) => <tr><td onClick={() => { getDB(db.value, db.label) }}>{db.label}</td></tr>)}
                                        </tbody>
                                    </table>
                                </div> */}
                                    <div class="responsive-table tblDeleteList table_div">
                                        <table class="table table table--bordered base-margin" style={{ border: '1px solid #ced4da', width: '90%' }}>
                                            <thead  >
                                                <tr>
                                                    <th>Select the DB to be edited</th></tr>
                                            </thead>
                                            <tbody>
                                                {dbs.map((db, index) => <tr><td ><a onClick={() => { getDB(db.value, db.label) }}>{db.label}</a></td></tr>)}
                                            </tbody>
                                        </table>
                                    </div>
                                    {
                                        isDBClickedForExpansion && <div className='addDb'>
                                        <div className="separatorBorder">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <div className="form-group__text">
                                                        <input id="input-layout-stacked-1" placeholder='Enter Connection Name' type="text" value={oldDBInfo.db_secret} onInput={event => { setOldDBInfo({ ...oldDBInfo, db_secret: event.target.value }) }} />
                                                        <label htmlFor="input-layout-stacked-1">Connection Name<span className='mandatoryIcon'>*</span></label>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <div className="form-group__text">
                                                        <input id="input-layout-stacked-1" placeholder='Enter DB Name' type="text" value={oldDBInfo.db_name} onInput={event => { setOldDBInfo({ ...oldDBInfo, db_name: event.target.value }) }} />
                                                        <label htmlFor="input-layout-stacked-1">DB Name<span className='mandatoryIcon'>*</span></label>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <div className="form-group__text">
                                                        <input id="input-layout-stacked-2" placeholder='Enter DB Host' type="text" value={oldDBInfo.db_host} onInput={event => { setOldDBInfo({ ...oldDBInfo, db_host: event.target.value }) }} />
                                                        <label htmlFor="input-layout-stacked-2">DB Host<span className='mandatoryIcon'>*</span></label>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <div className="form-group__text">
                                                        <input id="input-layout-stacked-2" placeholder='Enter User Id' type="text" value={oldDBInfo.username} onInput={event => { setOldDBInfo({ ...oldDBInfo, username: event.target.value }) }} />
                                                        <label htmlFor="input-layout-stacked-2">User Id<span className='mandatoryIcon'>*</span></label>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <div className="form-group__text">
                                                        <input id="input-layout-stacked-2" placeholder='Enter Password' type="password" value={oldDBInfo.password} onInput={event => { setOldDBInfo({ ...oldDBInfo, password: event.target.value }) }} style={{ fontSize: '12px' }} />
                                                        <label htmlFor="input-layout-stacked-2">Password<span className='mandatoryIcon'>*</span></label>
                                                    </div>
                                                </div>
                                                {/* <div className="form-group">
                                                <div className="form-group__text">
                                                    <label htmlFor="input-layout-stacked-2">Add to other Skills<span className='mandatoryIcon'>*</span></label>
                                                    <MultiSelect
                                                        option={dbs}
                                                        placeholder="Select domain"
                                                        selectedValues={newDBInfo.skills}
                                                        onValueChange={values => { setNewDBInfo({ ...newDBInfo, skills: values }) }}
                                                    />
                                                </div>
                                            </div> */}
                                                <div className="col-6 col-lg-6 base-margin-top">
                                                    <button className="btn btn--secondary btn--small" onClick={() => { updateDBData(); setOldDBInfo({ name: '', host: '', userId: '', password: '', secret: '' }); }} disabled={checkEmptyData()}>Edit DB</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    }
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
            <div className="row contnerSectionbox selectDb base-margin">
                <label className='lbPosition'>Select Connection<span className='mandatoryIcon'>*</span></label>
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className=""  >
                        {/* <select className='gblbalSelectbox' value={dbName} onChange={event => { setDbName(event.target.value); changeValue('db', event.target.value) }} >
                            {dbs.length > 0 && dbs.map(db => <option key={db.id}>{db.name}</option>)}
                            <option value='' disabled hidden>Choose a Database</option>
                        </select> */}
                        <SearchSelect
                            defaultValue={getDBFromValue(node.data.db)}
                            options={[...dbs, {value: 'newConn', label: 'Create Connection'}]}
                            onValueChange={data => { if (data.value !== 'newConn') {node.data.db = data.value; getDB(data.value, data.label)} else { setShowEditDB(0) } }}
                        />
                    </div>
                </div>
            </div>
            <div className="row contnerSectionbox queryDb base-margin">
                <label className='lbPosition'>Query<span className='mandatoryIcon'>*</span></label>
                <div className="col-lg-12 col-md-12 col-sm-12">
                    {/* <div className=""  >
                        
                    </div> */}
                    <div className="form-group base-margin-bottom">
                        <div className="form-group__text">
                            <textarea id="textarea-state-default" rows="3" value={dbQuery} onInput={event => { setDbQuery(event.target.value); changeValue('query', event.target.value) }}></textarea>
                            {/* <textarea id="textarea-state-required" rows="3" placeholder="Please Enter the Action Description" value={node.data.description} onChange={event => { inputHandlerActionChange(event.target.value, 'description'); setForceReRender(!forceReRender) }} required></textarea>
                            <label htmlFor="textarea-state-required">
                                <span>Action Description</span>
                                <span title="Please Enter the Rule Description">*</span>
                            </label> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
}
