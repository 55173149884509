import Wrapper from "../../../../common-components/wrapper/Wrapper";
import BotView from '../bot-view-component/BotViewComponent';

const BotScreen = ()=>{
    return(
        <Wrapper>
            <BotView/>
        </Wrapper>
    )
}

export default BotScreen;