const analytics_url = process.env.REACT_APP_ROOT_URL + "/analytics";

const AnalyticsService = {
  getStatsConvoFlow: (bot_id) => {
    var reqHeaders = new Headers();
    reqHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: "GET",
      headers: reqHeaders,
    };

    const convo_flow_url = analytics_url + "/convo_flow";

    return fetch(`${convo_flow_url}/${bot_id}`, requestOptions);
  },

  getAdminLayout: () => {
    var reqHeaders = new Headers();
    reqHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: "GET",
      headers: reqHeaders,
    };

    const admin_access_url = analytics_url + "/layoutAdmin";

    return fetch(`${admin_access_url}`, requestOptions);
  },

  saveAdminLayout: ({ data }) => {
    var reqHeaders = new Headers();
    reqHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: "POST",
      headers: reqHeaders,
      body: JSON.stringify(data),
    };
    console.log(data);

    const admin_access_url = analytics_url + "/layoutAdmin";

    return fetch(`${admin_access_url}`, requestOptions);
  },

  getLayout: (bot_id) => {
    var reqHeaders = new Headers();
    reqHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: "GET",
      headrers: reqHeaders,
    };

    const layout_url = analytics_url + "/layout";

    return fetch(`${layout_url}/${bot_id}`, requestOptions);
  },

  savelayout: ({ data }) => {
    var reqHeaders = new Headers();
    reqHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: "POST",
      headers: reqHeaders,
      body: JSON.stringify(data),
    };

    const layout_url = analytics_url + "/layout";

    return fetch(`${layout_url}`, requestOptions);
  },
};

export default AnalyticsService;