import "./ConditionNode.css";
import SearchSelect from "../../../../../../custom-components/search-select/SearchSelect";
import { useState } from "react";
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import AddBoxIcon from '@mui/icons-material/AddBox';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import DeleteIcon from '@mui/icons-material/Delete';
import InputSelect from '../../../../../../custom-components/CreatableSelect/CreatableSelect';

const GlobalConditionNode = ({ initialConditions, skillEntityList, ruleEntityList, systemEntityList, editParentConditions = () => { } }) => {
	const [conditions, setConditions] = useState(initialConditions)
	const [forceReRender, setForceReRender] = useState(false)

	const operators = [
		{ label: '<', value: '<' },
		{ label: '>', value: '>' },
		{ label: '<=', value: '<=' },
		{ label: '>=', value: '>=' },
		{ label: '=', value: '=' },
		{ label: '!=', value: '!=' }
	]

	const options = [{ label: 'Entities', options: skillEntityList }, { label: 'Context Variables', options: ruleEntityList }, { label: 'System Entities', options: systemEntityList }]

	const getCondition = (condition, index, array) => {
		if (condition.group)
			return (
				<div>
					<div style={{ marginLeft: '25px', borderRadius: '10px' }} className='panel panel--bordered'>
						<div className="base-margin">
							<button className="btn btn--small btn--secondary" onClick={() => { let id = 0; if (condition.group.length > 0) { array[index].group[condition.group.length - 1].operator = 'and'; id = condition.group[condition.group.length - 1].id + 1 } array[index].group.push({ condition: { entityName: systemEntityList[0], entityCondition: operators[4], entityValue: '0' }, id }); editParentConditions(conditions); setForceReRender(!forceReRender) }}>Add Condition</button>
							<button className="btn btn--small btn--secondary" onClick={() => { let id = 0; if (condition.group.length > 0) { array[index].group[condition.group.length - 1].operator = 'and'; id = condition.group[condition.group.length - 1].id + 1 } array[index].group.push({ group: [], id }); editParentConditions(conditions); setForceReRender(!forceReRender) }}>Add Group </button>
							<button className="btn btn--small btn--secondary" onClick={() => { array.splice(index, 1); if (index > 0 && index === array.length) delete array[index - 1].operator; editParentConditions(conditions); setForceReRender(!forceReRender) }}>Delete Group </button>
						</div>
						{condition.group.map((subCondition, subIndex, subArray) => getCondition(subCondition, subIndex, subArray))}
					</div>
					{condition.operator ? <div style={{ width: 'fit-content', margin: 'auto' }}>
						<div className="btn-group btn-group--square base-margin ">
							<button className={`btn btn--ghost ${condition.operator === 'and' ? 'selected' : null}`} onClick={() => { array[index].operator = 'and'; editParentConditions(conditions); setForceReRender(!forceReRender) }}>And</button>
							<button className={`btn btn--ghost ${condition.operator === 'or' ? 'selected' : null}`} onClick={() => { array[index].operator = 'or'; editParentConditions(conditions); setForceReRender(!forceReRender) }}>Or</button>
						</div>
					</div> : null}
				</div>
			)
		else if (condition.condition)
			return (
				<div key={index}>
					<div>
						<div className="form-group form-group--inline base-margin-top" style={{ width: '95%' }}>
							<div className="col-4">
								<InputSelect defaultValue={condition.condition.entityName} options={options} onValueChange={data => { array[index].condition.entityName = data; editParentConditions(conditions); setForceReRender(!forceReRender) }} />
							</div>
							<div className="col-2">
								<SearchSelect defaultValue={condition.condition.entityCondition} options={operators} onValueChange={data => { array[index].condition.entityCondition = data; editParentConditions(conditions); setForceReRender(!forceReRender) }} />
							</div>
							<div className="form-group__text col-4">
								<input id="conditionValue" value={condition.condition.entityValue} onChange={event => { array[index].condition.entityValue = event.target.value; editParentConditions(conditions); setForceReRender(!forceReRender) }} />
							</div>
							<div className='col-2'>
								<button className='btn btn--icon btn--secondary' style={{ float: 'right' }} onClick={() => { array.splice(index, 1); if (index > 0) delete array[index - 1].operator; editParentConditions(conditions); setForceReRender(!forceReRender) }}><span className='icon-delete'></span></button>
							</div>
						</div>
					</div>
					{condition.operator ? <div style={{ width: 'fit-content', margin: 'auto' }}>
						<div className="btn-group btn-group--square base-margin">
							<button className={`btn btn--ghost ${condition.operator === 'and' ? 'selected' : null}`} onClick={() => { array[index].operator = 'and'; editParentConditions(conditions); setForceReRender(!forceReRender) }}>And</button>
							<button className={`btn btn--ghost ${condition.operator === 'or' ? 'selected' : null}`} onClick={() => { array[index].operator = 'or'; editParentConditions(conditions); setForceReRender(!forceReRender) }}>Or</button>
						</div>
					</div> : null}
				</div>
			)
	}

	return <div>
		<div className="base-margin-down">
			<button className='btn btn--small btn--secondary' onClick={() => { let id = 0; if (conditions.length > 0) { conditions[conditions.length - 1].operator = 'and'; id = conditions[conditions.length - 1].id + 1 } conditions.push({ condition: { entityName: systemEntityList[0], entityCondition: operators[4], entityValue: '0' }, id }); editParentConditions(conditions); setForceReRender(!forceReRender) }}>Add Condition</button>
			<button className='btn btn--small btn--secondary' onClick={() => { let id = 0; if (conditions.length > 0) { conditions[conditions.length - 1].operator = 'and'; id = conditions[conditions.length - 1].id + 1 } conditions.push({ group: [], id }); editParentConditions(conditions); setForceReRender(!forceReRender) }}>Add Group</button>
		</div>
		{conditions.map((condition, index, array) => getCondition(condition, index, array))}
	</div>
}

export default GlobalConditionNode;
