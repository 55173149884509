const expression_url = process.env.REACT_APP_ROOT_URL

const paraphrase_url = 'https://alkppcs4f8.execute-api.us-east-1.amazonaws.com/Prod/api/paraphrase'
const ExpressionService = {

    getExpressions: (intentId, skill) => {
        console.log("INSIDE THE SERVICE ", intentId)
        //apiUrl = apiUrl + "/intent/"+intentId;
        var reqHeaders = new Headers();
        reqHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'GET'
        };

        return fetch(`${expression_url}/skill/${skill}/intent/${intentId}/expression`, requestOptions);

    },
    getExpression: (intentId, skill, expressionId) => {
        var reqHeaders = new Headers();
        reqHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'GET'
        };

        return fetch(`${expression_url}/skill/${skill}/intent/${intentId}/expression/${expressionId}`, requestOptions);

    },

    addExpression: (data, skill, intentId) => {
        var reqHeaders = new Headers();
        reqHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'POST',
            headers: reqHeaders,
            body: JSON.stringify(data)
        };

        return fetch(`${expression_url}/skill/${skill}/intent/${intentId}/expression`, requestOptions)

    },

    deleteExpression: (id, intentId, skill) => {
        var reqHeaders = new Headers();
        reqHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'DELETE',
            headers: reqHeaders
        };

        return fetch(`${expression_url}/skill/${skill}/intent/${intentId}/expression/${id}`, requestOptions);
    },
    deleteExpressions: (data, intentId, skill) => {
        var reqHeaders = new Headers();
        reqHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'DELETE',
            headers: reqHeaders,
            body: JSON.stringify(data)
        };

        return fetch(`${expression_url}/skill/${skill}/intent/${intentId}/expression`, requestOptions);
    },
    paraphraseExpression: (data) => {
        var reqHeaders = new Headers();
        reqHeaders.append("Content-Type", "application/json");
        // reqHeaders.append("x-api-key", "lIMDkW4sLS4l2mzOpAPpQaQidEy4Uz8g1c883oXp")

        var requestOptions = {
            method: 'POST',
            headers: reqHeaders,
            body: JSON.stringify(data)
        };
        return fetch(`${expression_url}/paraphrase`, requestOptions)
    }


}

export default ExpressionService;