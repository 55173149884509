const userManagementUrl = process.env.REACT_APP_ROOT_URL

const UserManagementService = {
    getUserRoles: (botId)=>{
        let requestOptions = {
            method: "GET"
        };

        return fetch(`${userManagementUrl}/bot/${botId}/userrole`, requestOptions);
    },
    getAllowListForActions : (skillId)=>{
        let requestOptions = {
            method: "GET"
        };
        return fetch(`${userManagementUrl}/skill/${skillId}/actionAllowList`, requestOptions);
    },
    getActionList: (botId)=>{
        let requestOptions = {
            method: "GET"
        };
        return fetch(`${userManagementUrl}/bot/${botId}/listAvailableAction`, requestOptions);
    },
    createAllowListForActions : (skillId, payload)=>{
        let reqHeaders = new Headers();
        reqHeaders.append("Content-Type", "application/json");

        let requestOptions = {
            method: "PUT",
            body: JSON.stringify(payload),
            headers: reqHeaders
        }
        return fetch(`${userManagementUrl}/skill/${skillId}/actionAllowList`, requestOptions);
    },
    getExternalizationInfo:(botId)=>{
        let requestOptions = {
            method: "GET"
        }
        return fetch(`${userManagementUrl}/externalization/bot/${botId}`, requestOptions);
    },
    getExternalizationUserInfo: (botId)=>{
        let requestOptions = {
            method: "GET"
        }
        return fetch(`${userManagementUrl}/externalization/bot/${botId}/users`, requestOptions);
    },
    createExternalizationRequest:(botId)=>{
        let reqHeaders = new Headers();
        reqHeaders.append("Content-Type", "application/json");

        let requestOptions = {
            method: "POST",
            body: JSON.stringify({
                "bot_id":botId,
                "activity":"enable"
            }),
            headers: reqHeaders
        }
        return fetch(`${userManagementUrl}/externalization/bot/${botId}/request`, requestOptions);
    },
    updateExternalizationUserList:(botId, users) =>{
        let reqHeaders = new Headers();
        reqHeaders.append("Content-Type", "application/json");

        let requestOptions = {
            method: "PUT",
            body: JSON.stringify({
                "user_list": users
            }),
            headers: reqHeaders
        }
        return fetch(`${userManagementUrl}/externalization/bot/${botId}/users`, requestOptions);
    },
    updateUserRole: (botId, payload)=>{
        let reqHeaders = new Headers();
        reqHeaders.append("Content-Type", "application/json");

        let requestOptions = {
            method: "POST",
            body: JSON.stringify(payload),
            headers: reqHeaders
        }
        return fetch(`${userManagementUrl}/bot/${botId}/userrole`, requestOptions);
    },
    deleteUserFromRoles:(botId, email)=>{
        let reqHeaders = new Headers();
        reqHeaders.append("Content-Type", "application/json");
        let requestOptions = {
            method: "DELETE",
            body: JSON.stringify({
                "emails":[email]
            }),
            headers: reqHeaders
        }
        return fetch(`${userManagementUrl}/bot/${botId}/userrole`, requestOptions)
    },
    modifyIntegrationAllowList(botId, payload){
        let reqHeaders = new Headers();
        reqHeaders.append("Content-Type", "application/json");

        let requestOptions = {
            method: "POST",
            body: JSON.stringify(payload),
            headers: reqHeaders
        }
        return fetch(`${userManagementUrl}/bot/${botId}/integrationAllowList`, requestOptions);
    },
     
    isBotLiteAdmin: ()=>{
        let requestOptions = {
            method: "GET"
        };

        return fetch(`${userManagementUrl}/isBotLiteAdmin`, requestOptions);
    }
};

export default UserManagementService;
