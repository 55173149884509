import React,{useState,useEffect} from 'react';
import CreatableSelect from 'react-select/creatable';

const InputSelect = ({defaultValue, options, border = 'lightgrey solid', onValueChange}) => {
    const [selected_item, setSelectedItem] = useState(defaultValue);


    useEffect(()=>{
        setSelectedItem(defaultValue);
    },[defaultValue])

    return <CreatableSelect
        value={selected_item}
        // isSearchable={true}
        // isClearable={true}
        options={options}
        onChange={(e)=>{onValueChange(e);setSelectedItem(e)}}
		styles={{
			control: (baseStyles, state) => ({
				...baseStyles,
				borderColor: border.split(' ')[0],
				borderStyle: border.split(' ')[1]
			})
		}}
	/>
}

export default InputSelect;