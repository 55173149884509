import { useState, forwardRef } from "react";
import AnalyticsAtlasChartComponent from "../analytics-chart-component/AnalyticsAtlasChartComponent";
import { CHART_KEYS } from "../../constants";

const AnalyticsFilterComponent = forwardRef(
  ({ chartId, height, width, bot, skill, insight, action, chartKey }, ref) => {
    const [w, setW] = useState(width);

    if (w !== width) {
      setW(width);
    }

    const filterFunc = () => {
      if (insight === "bot") {
        if (chartKey === CHART_KEYS.BOT.AVG_RESPONSE_TIME_BY_ACTION)
          return { agent: bot?.label, action_type: action };
        return { agent: bot?.label };
      } else if (insight === "skill") {
        if (skill?.value === undefined || skill?.value === null)
          return undefined;
        if (chartKey === CHART_KEYS.SKILL.AVG_RESPONSE_TIME)
          return { "context_after.var_req_domain": skill?.label };
        return { skill: skill?.value };
      } else {
        if (
          insight === "platform" &&
          chartKey === CHART_KEYS.PLATFORM.MOST_USED_KEYWORDS &&
          action !== "reset"
        ) {
          return { agent: action };
        }
        return undefined;
      }
    };

    return (
      <div>
        <AnalyticsAtlasChartComponent
          width={width}
          height={height}
          chartId={chartId}
          bot_name={bot?.label}
          chartFilter={filterFunc()}
          insight={insight}
          chartKey={chartKey}
          ref={ref}
        />
      </div>
    );
  }
);

export default AnalyticsFilterComponent;
