import './DragNodesFrom.css'

export default function NodeDragBar() {
    const onDragStart = (event, nodeType) => {
        event.dataTransfer.setData('application/reactflow', nodeType);
        event.dataTransfer.effectAllowed = 'move';
    };

    return (
        <div className='dragContainer col-6'>
            {/* <div id='ruleDragElement' className='dragElement' onDragStart={(event) => onDragStart(event, 'RuleNode')} draggable>Rule</div> */}
            <div id='actionDragElement' className='dragElement' onDragStart={(event) => onDragStart(event, 'ActionNode')} draggable>Action</div>
            <div id='conditionDragElement' className='dragElement' onDragStart={(event) => onDragStart(event, 'ConditionNode')} draggable>Condition</div>
            <div id='jumpToDragElement' className='dragElement' onDragStart={(event) => onDragStart(event, 'JumpToNode')} draggable>Jump To</div>
            <div id='clearContextDragElement' className='dragElement' onDragStart={(event) => onDragStart(event, 'ClearContextNode')} draggable>Clear Context</div>
            <div id='responseDragElement' className='dragElement' onDragStart={(event) => onDragStart(event, 'ResponseNode')} draggable>Response</div>
        </div>
    );
};