import React, { useState, useEffect, useRef } from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import AnalyticsFilterComponent from "../../analytics-component/analytics-filter-component/AnalyticsFilterComponent";
import AnalyticsAdminBarChart from "./AnalyticsAdminBarCharts";
import MessageBanner from "../../../../custom-components/MessageBanner/MessageBanner";
import AnalyticsService from "../../analytics-services/AnalyticsService";
import "../../../../../node_modules/react-resizable/css/styles.css";
import "../../../../../node_modules/react-grid-layout/css/styles.css";
import "./AnalyticsAdminViewComponent.css";
import {
  PLATFORM_CHART_DETAILS_2,
  PLATFORM_CHART_DETAILS,
  CHART_KEYS,
} from "../../constants";

import {
  LayoutBox,
  ChartHeader,
  ChartBoxLayout,
  BarChartBoxLayout,
} from "../../style";
const ResponsiveGridLayout = WidthProvider(Responsive);

const AnalyticsAdminView = () => {
  const refWidth = useRef(null);
  const [isDataLoading, setDataLoading] = useState(false);
  const [showMessageBanner, setShowMessageBanner] = useState(false);
  const [bannerMessage, setBannerMessage] = useState("");
  const [bannerMessageType, setBannerMessageType] = useState("success");

  const availableHandles = ["s", "w", "e", "n", "sw", "nw", "se", "ne"];

  const getDivNum = (windowSize) => {
    if (windowSize >= 1200) {
      return 12;
    } else if (windowSize >= 992) {
      return 10;
    } else if (windowSize >= 768) {
      return 6;
    } else {
      return 4;
    }
  };

  let width = window.innerWidth;
  let unitWidth = width / getDivNum(width);
  const unitHeight = 33;

  useEffect(() => {
    width = refWidth.current ? refWidth.current.offsetWidth : window.innerWidth;
    unitWidth = width / getDivNum(width) - 10;
  }, [refWidth.current]);

  var originalLayouts = {};
  var originalInternalLayouts = {};
  var initialLoad = 0;

  const [layouts, setLayouts] = useState({});

  const [internalLayouts, setInternalLayout] = useState({});

  const loadLayout = () => {
    setDataLoading(true);
    let ls = {};
    AnalyticsService.getAdminLayout()
      .then((res) => res.json())
      .then((res) => {
        ls = res["analytics"] || {};
        originalLayouts = ls["layouts"] || {};
        setLayouts(JSON.parse(JSON.stringify(originalLayouts)));
        originalInternalLayouts = ls["internalLayouts"] || {};
        setInternalLayout(JSON.parse(JSON.stringify(originalInternalLayouts)));
        setDataLoading(false);
      })
      .catch((err) => {
        if (initialLoad === 0) {
          initialLoad = 1;
          saveToLS("layouts", layouts);
          saveToLS("internalLayouts", internalLayouts);
        } else {
          console.log(err);
          setDataLoading(false);
          setBannerMessageType("error");
          setBannerMessage("Failed to retrieve layout!");
          setShowMessageBanner(true);
        }
      });
  };

  useEffect(() => {
    loadLayout();
  }, []);

  const saveToLS = (key, value) => {
    var existingData = {
      layouts: layouts,
      internalLayouts: internalLayouts,
    };
    var uploadData = {
      ...existingData,
      [key]: value,
    };
    AnalyticsService.saveAdminLayout({
      data: {
        layout: uploadData,
      },
    }).then((res) => {
      console.log(res);
    });
    loadLayout();
  };

  const onLayoutChange = (layout, elayouts) => {
    saveToLS("layouts", elayouts);
    setLayouts(elayouts);
  };

  const onInternalLayoutChange = (layout, elayouts) => {
    saveToLS("internalLayouts", elayouts);
    setInternalLayout(elayouts);
  };

  const chartWidth = 6;
  const internalChartWidth = 2;
  const chartHeight = 13.5;
  const internalChartHeight = 6;
  const chartMWidth = 6;
  const chartMHeight = 12;

  return (
    <div ref={refWidth}>
      {/* <div style={{ borderBottom: "1px #ced4da solid" }}>
        <div className="row base-margin-top base-margin-right">
          <h5 className="col-7 col-md-8 col-sm-8 col-lg-8 col-xl-8 col-xxl-9 col-xxxl-9 ">
            Platform Metrics View
          </h5>
        </div>
      </div> */}
      {showMessageBanner && (
        <MessageBanner
          message={bannerMessage}
          messageType={bannerMessageType}
          onCloseClicked={() => {
            setShowMessageBanner(false);
          }}
        ></MessageBanner>
      )}

      <div className="charts">
        <ResponsiveGridLayout
          className="layout"
          cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 4, xl: 12, xxl: 12 }}
          rowHeight={30}
          layouts={layouts}
          margin={[10, 10]}
          onLayoutChange={(layout, layouts) => onLayoutChange(layout, layouts)}
        >
          <LayoutBox
            key="1"
            data-grid={{
              w: 12,
              h: chartHeight,
              x: 0,
              y: 0,
              minW: chartMWidth,
              minH: chartMHeight,
              resizeHandles: availableHandles,
            }}
          >
            <ResponsiveGridLayout
              className="layout"
              cols={{ lg: 12, md: 10, sm: 6, xs: 4,xxs: 4, xl: 12, xxl: 12 }}
              rowHeight={30}
              layouts={internalLayouts}
              margin={[10, 10]}
              onLayoutChange={(layout, layouts) =>
                onInternalLayoutChange(layout, layouts)
              }
              isBounded={true}
            >
              {PLATFORM_CHART_DETAILS.map((chart, index) => {
                return (
                  <ChartBoxLayout
                    key={chart.key}
                    data-grid={{
                      w: internalChartWidth,
                      h: internalChartHeight,
                      x: chart.x,
                      y: chart.y,
                    }}
                  >
                    <div className="base-margin">
                      <ChartHeader>{chart.header}</ChartHeader>
                      <span className="base-margin-left info-display">
                        {chart.subHeader} &nbsp;
                        <span
                          tabindex="0"
                          data-balloon-break
                          data-balloon={chart.chartInfo}
                          data-balloon-pos="down"
                          data-balloon-length="medium"
                        >
                          <span className="  icon-info-outline"></span>
                        </span>
                        {chart.downloadInfo !== "" && (
                          <span
                            tabindex="1"
                            data-balloon-break
                            data-balloon={chart.downloadInfo}
                            data-balloon-pos="down"
                            data-balloon-length="medium"
                          >
                            &nbsp; <span className="icon-download"></span>
                          </span>
                        )}
                      </span>
                    </div>
                    <hr />
                    <AnalyticsFilterComponent
                      key={chart.key}
                      chartId={chart.id}
                      height={(7 * unitHeight) / 2}
                      width={1.5 * unitWidth}
                      insight="platform"
                    />
                  </ChartBoxLayout>
                );
              })}
            </ResponsiveGridLayout>
          </LayoutBox>
          {PLATFORM_CHART_DETAILS_2.map((chart, index) => {
            if(chart.key === CHART_KEYS.PLATFORM.MOST_USED_KEYWORDS){
              return (
                <BarChartBoxLayout
                  key={chart.key}
                  data-grid={{
                    w: 2*chartWidth,
                    h: chartHeight,
                    x: chart.x,
                    y: chart.y,
                    minW: chartMWidth,
                    minH: chartMHeight,
                    resizeHandles: availableHandles,
                  }}
                >
                  <AnalyticsAdminBarChart
                    chart={chart}
                    unitHeight={unitHeight}
                    unitWidth={unitWidth}
                    chartHeight={chartHeight}
                    chartWidth={2*chartWidth}
                    layouts={layouts}
                    index={index}
                  />
                </BarChartBoxLayout>
              );
            }
            return (
              <BarChartBoxLayout
                key={chart.key}
                data-grid={{
                  w: chartWidth,
                  h: chartHeight,
                  x: chart.x,
                  y: chart.y,
                  minW: chartMWidth,
                  minH: chartMHeight,
                  resizeHandles: availableHandles,
                }}
              >
                <AnalyticsAdminBarChart
                  chart={chart}
                  unitHeight={unitHeight}
                  unitWidth={unitWidth}
                  chartHeight={chartHeight}
                  chartWidth={chartWidth}
                  layouts={layouts}
                  index={index}
                />
              </BarChartBoxLayout>
            );
          })}
        </ResponsiveGridLayout>
      </div>

      <div style={{ display: width < 992 ? "block" : "none" }}>
        <MessageBanner
          message={
            "This device doesn't support to view this screen. This view is restricted only for laptops and tablets. Please open it in a different device."
          }
          messageType={"error"}
        ></MessageBanner>
      </div>
    </div>
  );
};

export default AnalyticsAdminView;
