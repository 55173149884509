
const base_url = process.env.REACT_APP_ROOT_URL
// const train_url = process.env.REACT_APP_MINDMELD_URL

const TrainingService = {
    
    getListModels: async (botid) => {
        var reqHeaders = new Headers();
        reqHeaders.append("Content-Type", "application/json");
        var requestOptions = {
            method: 'GET',
            headers: reqHeaders
        };
        try {
            return await fetch(base_url + "/bot/" +botid+"/mm/model", requestOptions);
        } catch (error) {
            return error;
        }
    },
    getListExpression: async (botid) => {
        var reqHeaders = new Headers();
        reqHeaders.append("Content-Type", "application/json");
        var requestOptions = {
            method: 'GET',
            headers: reqHeaders
        };
        try {
            return await fetch(base_url + "/bot/" +botid+"/mm/bulkValidate", requestOptions);
        } catch (error) {
            return error;
        }
    },
    deleteModel: async (selectedBot, modelData) => {
        var reqHeaders = new Headers();
        reqHeaders.append("Content-Type", "application/json");
        var requestOptions = {
            method: 'DELETE',
            headers: reqHeaders,
            body: JSON.stringify(modelData)
        };
        try {
            return await fetch(base_url + "/bot/" + selectedBot + "/mm/model", requestOptions);
        } catch (error) {
            return error;
        }
        
    },
    trainBot: async (botid,agentid) => {
        var reqHeaders = new Headers();
        reqHeaders.append("Content-Type", "application/json");
        var requestOptions = {
            method: 'POST',
            headers: reqHeaders,
            body: JSON.stringify(agentid)
        };
        try {
            return await fetch(base_url + "/bot/" +botid+"/mm/train", requestOptions);
        } catch (error) {
            return error;
        }
    },
    uploadExpressionDetails: async (data, botid) => {
        var reqHeaders = new Headers();
        reqHeaders.append("Content-Type", "application/json");
        var requestOptions = {
            method: 'POST',
            headers: reqHeaders,
            body: JSON.stringify(data),
            redirect: 'follow'
        };
        try {
            return await fetch(base_url + "/bot/" +botid+"/mm/bulkValidate", requestOptions);
        } catch (error) {
            return error;
        }
    },
    saveExpressionDetails: async (data, botid) => {
        var reqHeaders = new Headers();
        reqHeaders.append("Content-Type", "application/json");
        var requestOptions = {
            method: 'PUT',
            headers: reqHeaders,
            body: JSON.stringify(data),
            redirect: 'follow'
        };
        try {
            return await fetch(base_url + "/bot/" +botid+"/mm/bulkValidate", requestOptions);
        } catch (error) {
            return error;
        }
    },
    configBot: async (data, botid) => {
        var reqHeaders = new Headers();
        reqHeaders.append("Content-Type", "application/json");
        var requestOptions = {
            method: 'POST',
            headers: reqHeaders,
            body: JSON.stringify(data),
            redirect: 'follow'
        };
        try {
            return await fetch(base_url + "/bot/" + botid + "/mm/configureModelClassifier" , requestOptions);
        } catch (error) {
            return error;
        }
    },
    testBot: async (data, botid) => {
        var reqHeaders = new Headers();
        reqHeaders.append("Content-Type", "application/json");
        var requestOptions = {
            method: 'POST',
            headers: reqHeaders,
            body: JSON.stringify(data),
            redirect: 'follow'
        };
        try {
            return await fetch(base_url + "/bot/" + botid + "/mm/query" , requestOptions);
        } catch (error) {
            return error;
        }
    },
    deleteValidation:async (data, botid) => {
        var reqHeaders = new Headers();
        reqHeaders.append("Content-Type", "application/json");
        var requestOptions = {
            method: 'DELETE',
            headers: reqHeaders,
            body: JSON.stringify(data),
            redirect: 'follow'
        };
        try {
            return await fetch(base_url + "/bot/" +botid+"/mm/bulkValidate", requestOptions);
        } catch (error) {
            return error;
        }
    },
    getIntentSkill:  async (botid) => {
        var reqHeaders = new Headers();
        reqHeaders.append("Content-Type", "application/json");
        var requestOptions = {
            method: 'GET',
            headers: reqHeaders
        };
        try {
            return await fetch(base_url + "/bot/" +botid+"/skillintents", requestOptions);
        } catch (error) {
            return error;
        }
    },
    revalidateExpression:  async (botid)=>{
        var reqHeaders = new Headers();
        reqHeaders.append("Content-Type", "application/json");
        var requestOptions = {
            method: 'GET',
            headers: reqHeaders
        };
        try {
            return await fetch(base_url + "/bot/" +botid+"/skillintents", requestOptions);
        } catch (error) {
            return error;
        }
    },
    queryExpressions: async (botid,data)=>{
        var reqHeaders = new Headers();
        reqHeaders.append("Content-Type", "application/json");
        var requestOptions = {
            method: 'POST',
            headers: reqHeaders,
            body: JSON.stringify(data),
            redirect: 'follow'
        };
        try {
            return await fetch(base_url + "/bot/" + botid + "/mm/query" , requestOptions);
        } catch (error) {
            return error;
        }
    }


}

export default TrainingService;