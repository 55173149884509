const app_url = process.env.REACT_APP_ROOT_URL

const SkillLibraryService = {

	getPublishedSkills: () => {
        var reqHeaders = new Headers();
        reqHeaders.append("Content-Type", "application/json");
        var requestOptions = {
            method: 'GET',
            headers: reqHeaders
        };

        return fetch(`${app_url}/skill_library`, requestOptions);
    },
	
	getPublishedSkillsByTag: (tag) => {
        var reqHeaders = new Headers();
        reqHeaders.append("Content-Type", "application/json");
        var requestOptions = {
            method: 'GET',
            headers: reqHeaders
        };

        return fetch(`${app_url}/skill_library/tag/${tag}`, requestOptions);
    },
	
    cloneSkill:(skill_id, data)=>{
        var reqHeaders = new Headers();
        reqHeaders.append("Content-Type", "application/json");

        var requestOptions = {
          method: 'POST',
          headers: reqHeaders,
          body: JSON.stringify(data)
        };
        
        return fetch(`${app_url}/skill_clone`, requestOptions)
    },
	
	submitSkillConsumptionRequest:(data)=>{
        var reqHeaders = new Headers();
        reqHeaders.append("Content-Type", "application/json");

        var requestOptions = {
          method: 'POST',
          headers: reqHeaders,
          body: JSON.stringify(data)
        };
        
        return fetch(`${app_url}/approval_request`, requestOptions)
    },
	
}

export default SkillLibraryService;