import './Wrapper.css';

import { useState, useEffect, createContext } from 'react';
import { useLocation } from 'react-router-dom'
import ReactHtmlParser from 'react-html-parser';

import Header from '../header/Header';
import Sidebar from '../sidebar/Sidebar';
import MessageBanner from '../../custom-components/MessageBanner/MessageBanner';
import WrapperService from './WrapperService';
import BotLiteUpdatesService from "../../modules/botlite-updates-module/botlite-updates-services/BotLiteUpdatesService";
import Modal from 'react-bootstrap/Modal';

const Wrapper = (props)=>{
  const [isNavbarOpen,setMenubarOpen] = useState(true);

  const location = useLocation();
  const [showPage,setShowPage] = useState(true);
  const [messageBody, setMessageBody] = useState('<p>This is an page is not available at the moment.</p>');
  const [isDoNotShowBotLiteUpdates, setIsDoNotShowBotLiteUpdates] = useState(false)
  const [isNewBotLiteUpdate, setIsNewBotLiteUpdate] = useState(false)
 
  const [botLiteMessageObj, setBotLiteMessageObj] = useState({
    title: '',
    notification_type: '',
    message: '',
    showMessage : true
  })
  const [botLiteUpdatesAlertType, setBotLiteUpdatesAlertType] = useState('alert--info')
  const [botLiteUpdatesAlertIcon, setbotLiteUpdatesAlertIcon] = useState('icon-info-outline')
  const [botliteMessageUpdatedTime, setBotliteMessageUpdatedTime] = useState('')
  
  const MESSAGE_STORED_ATTR = "botlite_message_stored";

  useEffect(()=>{
    setShowPage(true)
    WrapperService.getDynamicMessage(location.pathname).then((res)=>res.json()).then((res)=>{
      if(res["status"] === "success" && res["data"] && res["data"]["disable_page"] && res["data"]["message"] !== undefined){
        setMessageBody(res["data"]["message"]);
        setShowPage(!res["data"]["disable_page"]);
      }
    },(err)=>{
      console.log(err);
    })
    checkForBotLiteUpdates()
  },[])

  const toggleNavBar = ()=>{
    setMenubarOpen(isNavbarOpen?false:true);
  }

  // this function conditional renders the menu bar
  const renderMenuBar = ()=>{
    if(isNavbarOpen){
        return <Sidebar></Sidebar>
    } 
  
  }

  const renderChild = ()=>{
    if(showPage){
      return props.children
    }
    else{
      return (
          <div style={{position:"relative","top":"calc(50vh - 105px)"}}>
            <MessageBanner messageType="info">
              {ReactHtmlParser(messageBody)}
            </MessageBanner>
          </div>
      );
    }
  }

  const checkForBotLiteUpdates = () => {
    let currentPage = location.pathname
    console.log("isNewBotLiteUpdate: "+isNewBotLiteUpdate)
    if(currentPage.indexOf("botlite_updates") == -1 && !isNewBotLiteUpdate) {
      BotLiteUpdatesService.getBotLiteUpdates().then(res => res.json()).then(response => {
        if (response["status"] == 'success' && response['result']) {
          let result = response['result'];
          let notifyObj = {}
          notifyObj['title'] = result['title'];
          notifyObj['notification_type'] = result['notification_type'];
          notifyObj['message'] = result['message'];
          notifyObj['showMessage'] = result['showMessage'];
          updateNotificationType(notifyObj['notification_type']);
          setBotLiteMessageObj(notifyObj);	
          if (result['showMessage']) {
            let storedUserPref = window.localStorage.getItem(MESSAGE_STORED_ATTR);	
            if (storedUserPref == null || storedUserPref == "") {
              if (botliteMessageUpdatedTime == "") {
                setBotliteMessageUpdatedTime(result['updated_date']);
                setIsNewBotLiteUpdate(true)
              } else if (botliteMessageUpdatedTime != result['updated_date']) {
                setBotliteMessageUpdatedTime(result['updated_date']);
                setIsNewBotLiteUpdate(true)
              }
            } else {
              if (storedUserPref != result['updated_date']) {
                setBotliteMessageUpdatedTime(result['updated_date']);
                window.localStorage.removeItem(MESSAGE_STORED_ATTR);
                setIsNewBotLiteUpdate(true)
              }
            }
          }
        }
      }).catch(err => {
          
      })
    }
  }

  const updateNotificationType = (selectedType) => {
    if (selectedType == "info") {
      setBotLiteUpdatesAlertType("");
      setbotLiteUpdatesAlertIcon("icon-info-outline");
    } else if (selectedType == "warning") {
      setBotLiteUpdatesAlertType("alert--warning");
      setbotLiteUpdatesAlertIcon("icon-warning-outline");
    } else if (selectedType == "critical") {
      setBotLiteUpdatesAlertType("alert--danger");
      setbotLiteUpdatesAlertIcon("icon-error-outline");
    }
  };

  const handleBotLiteUpdatesModalClose = () => {
    setIsNewBotLiteUpdate(false)
    if(isDoNotShowBotLiteUpdates) {
      window.localStorage.setItem(MESSAGE_STORED_ATTR, botliteMessageUpdatedTime);
    }
  }

  return (
    <div className="main-wrapper">
      <Header onToggleClick={()=>{toggleNavBar()}} isNavbarOpen={isNavbarOpen}></Header>
        <div className='content main-content'>
          <div className={(isNavbarOpen?"":"no-navbar ")+"width-sidebar-xs width-sidebar-sm width-sidebar-md width-sidebar-lg width-sidebar-xl width-sidebar-xxl width-sidebar-xxxl"}>
            {renderMenuBar()}
          </div>
          <div className={(isNavbarOpen?"":"only-content ")+'width-content-xs width-content-sm width-content-md width-content-lg width-content-xl width-content-xxl width-content-xxxl'}>
            <div className='panel' style={{"height":"100%"}}>
                {
                  renderChild()
                }
            </div>
          </div>
        </div>

        <Modal size="lg" centered show={isNewBotLiteUpdate} animation={false} >
          <Modal.Header>
              <h5>{botLiteMessageObj.title}</h5>
              <div className=' btn--small btn--icon' onClick={() => { handleBotLiteUpdatesModalClose() }}>
                  <span className="icon-close icon-size-24"></span>
              </div>
          </Modal.Header>
      
          <Modal.Body>
              <div className='base-padding'>
                  <div className={`alert ${botLiteUpdatesAlertType} panel`} style={{"zIndex": "100px" }}>
                      <div class={`alert__icon ${botLiteUpdatesAlertIcon}`}>

                      </div>
                      <div class="alert__message" dangerouslySetInnerHTML={{__html: botLiteMessageObj.message}}>

                      </div>
                  </div>
              </div>
          </Modal.Body>

          <Modal.Footer>
            <div className="row">
              <div className="col" style={{marginTop: "10px"}}>
                <div className="form-group form-group--inline">
                  <label className="checkbox">
                    <input type="checkbox" checked={isDoNotShowBotLiteUpdates} onChange={(e) => setIsDoNotShowBotLiteUpdates(e.target.checked)} name="botlite_updates_show" />
                    <span className="checkbox__input"></span>
                    <span className="checkbox__label">Don't show this again</span>
                  </label>
                </div>
              </div>
              <button className="btn btn--secondary" onClick={() => { handleBotLiteUpdatesModalClose() }}>
                  Close
              </button>
            </div>
          </Modal.Footer>
        </Modal>
    </div>
  )

}

export default Wrapper;