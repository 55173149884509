import { createSlice } from '@reduxjs/toolkit'

const analyticsSlice = createSlice({
    name: "analyticsSlice",
    initialState:{
        analyticsAccessToken: undefined
    },
    reducers: {
        updateAnalyticsAccessToken: (state, action) => {
          state.analyticsAccessToken = action.payload;
        },
      },
})

export const {updateAnalyticsAccessToken} = analyticsSlice.actions;

export default analyticsSlice.reducer;
