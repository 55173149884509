import React from "react";
import '../skill-component/SkillComponent.css';

export default function SkillVersion({ index, skillVersion, onSkillVersionPublish, onSkillVersionDelete, onSkillVersionRevert, selectedID }) {
	/*let activate_bg =  skillVersion['activate']?"white":"#ced4da";
	let activate = skillVersion['activate']?"black":"#212529"*/

	let activate_bg = "white";
	let activate = "#212529"

	function truncateStrings(str, length) {
		if (length > str.length) {
			str = str + " ".repeat(length - str.length)
			return str
		}

		str = str.substring(0, length)
		return str;
	}

	function len(str) {
		return str.length
	}

	return (

		<>
			<div className={"align-items-center panel panel--raised panel--bordered base-margin col-12 col-xs-12 col-sm-12 col-md-8 col-lg-5 col-xl-5 col-xxl-4 col-xxxl-4"} style={{ borderRadius: '3vh', background: activate_bg, color: activate, height: 'fit-content', opacity: skillVersion['activate'] ? '0.5' : '', width: "calc(100% - 40px)", marginTop: '0 !important', }} key={index}>
				{
					<div className="flex-center-vertical" style={{ float: "right", visibility: skillVersion['published'] ? "visible" : "hidden" }} data-balloon="This version is published to the Skill Library" data-balloon-pos="up" data-balloon-length="medium">
						<div className="flex-center">
							<span className="icon-certified icon-size-24"></span>
						</div>
					</div>
				}
				<div >
					<div className="base-margin">

						<div className="row">

							<div className="col-3 col-sm-3 col-xs-3 col-md-3 col-lg-3 col-xl-3 col-2xl-3">
								<img src={process.env.PUBLIC_URL + "/images/version-control.png"} alt="botLogo" style={{ borderRadius: '50%' }} />
							</div>


							<div className="col-9 col-sm-9 col-xs-9 col-md-9 col-lg-9 col-xl-9 col-2xl-9" >
								<div className="skill-name">
									<h6>
										<b>
											{skillVersion['name']}
										</b>
									</h6>
								</div>
								<div className="row base-margin-top base-margin-right">
									<div style={{ textAlign: 'justify', wordBreak: 'keep-all', height: '70px', marginLeft: '10px' }}> {truncateStrings(skillVersion['description'], 100)}{len(skillVersion['description']) > 100 ? '...' : ''}</div>
								</div>
							</div>
						</div>

						<hr style={{ marginBottom: '3px' }} />
						<br />

						<div className="base-margin-left base-margin-right base-margin-bottom flex flex-wrap " style={{ position: 'absolute', right: '0', bottom: '0', paddingRight: '10px' }}>
							<div className="base-margin-right skill-action" data-balloon="Revert the skill to this version" data-balloon-pos="up" data-balloon-length="medium">
								<div className="" style={{ opacity: skillVersion['deletionFlag'] ? '0.25' : '' }}>
									<span className="icon-back icon-size-24" onClick={() => onSkillVersionRevert(skillVersion)}></span>
								</div>
							</div>
							<div className="base-margin-right skill-action" data-balloon="Delete this version" data-balloon-pos="up" data-balloon-length="medium">
								<div className="" style={{ opacity: skillVersion['deletionFlag'] ? '0.25' : '' }}>
									<span className="icon-delete icon-size-24" style={{ display: !skillVersion['deletionFlag'] ? 'block' : 'none' }} onClick={() => onSkillVersionDelete(skillVersion)}></span>
									<span className="icon-delete icon-size-24" style={{ display: skillVersion['deletionFlag'] ? 'block' : 'none' }} ></span>
								</div>
							</div>
							<div className="skill-action" data-balloon="Publish this version" data-balloon-pos="up" data-balloon-length="medium">
								<div className="" style={{ opacity: skillVersion['published'] ? '0.25' : '' }}>
									<span className="icon-share-contain icon-size-24" onClick={() => onSkillVersionPublish(skillVersion["_id"])}></span>
								</div>
							</div>
						</div>

					</div>
				</div>
			</div >
		</>
	);
}