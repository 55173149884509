

export const HomeHeader = () => {
    const botliteHomeUrl = process.env.REACT_APP_ROOT_URL.split("/api")[0]+"/home";

    return (
        <div className="header">
            <div className="container-fluid">
                <div className="header-panels">
                    <div className="header-panel">
                        <a className="header__logo" href="http://www.cisco.com" >
                            <span className="icon-cisco"></span>
                        </a>
                        <div className="header__title" >
                            <img src={process.env.PUBLIC_URL + "/images/common-images/BotliteLogo.png"} href={botliteHomeUrl} className="botliteTitle" alt="BotLiteLogo" width='100vw' style={{ marginTop: '1vh' }}></img>                            
                        </div>
                    </div>


                </div>
            </div>
        </div>
    )
}

export default HomeHeader;