import './BotLiteUpdatesViewComponent.css';
import isAlpha from 'validator/lib/isAlpha';
import isAlphanumeric from 'validator/lib/isAlphanumeric';

import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Spinner from "../../../../custom-components/spinner/Spinner";
import MessageBanner from "../../../../custom-components/MessageBanner/MessageBanner";
import BotLiteUpdatesService from "../../botlite-updates-services/BotLiteUpdatesService";
import { useEffect } from "react";

export const BotLiteUpdatesView = () => {

    const [userId, setUserId] = useState('');
    const [isDataLoading, setDataLoading] = useState(false);
    const [showMessageBanner, setShowMessageBanner] = useState(false);
    const [bannerMessage, setBannerMessage] = useState('');
    const [bannerMessageType, setBannerMessageType] = useState('success');
   
	const [layoutType, setLayoutType] = useState(window.innerWidth > 899.9 ? 'horizontal' : 'verticle');

    const handleResize = (() => {
        setLayoutType(window.innerWidth > 899.9 ? 'horizontal' : 'verticle');
    })

    const [botLiteMessageObj, setBotLiteMessageObj] = useState({
        id: '',
        title: 'BotLite Notification',
        notification_type: '',
        message: '',
        scheduled: false,
        startTime: '',
        endTime: '',
        timezone: 'PST',
        active : true
	})

    const [start_date, setStartDate] = useState('')
    const [start_date_hours, setStartDateHours] = useState('')
    const [start_date_minutes, setStartDateMinutes] = useState('')
    const [end_date, setEndDate] = useState('')
    const [end_date_hours, setEndDateHours] = useState('')
    const [end_date_minutes, setEndDateMinutes] = useState('')

    const [alertType, setAlertType] = useState('alert--info')
    const [alertIcon, setAlertIcon] = useState('icon-info-outline')
    const [showPreview, setShowPreview] = useState(false)

    const today = new Date();
    const DATE_HOURS = ['00','01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23'];
	const DATE_MINS = ['00','15','30','45'];

    const updateNotificationType = (selectedType) => {
        console.log("updateNotificationType: "+selectedType)
        if (selectedType == "info") {
			setAlertType("");
			setAlertIcon("icon-info-outline");
		} else if (selectedType == "warning") {
			setAlertType("alert--warning");
			setAlertIcon("icon-warning-outline");
		} else if (selectedType == "critical") {
			setAlertType("alert--danger");
			setAlertIcon("icon-error-outline");
		}
    };
	
	useEffect(() => {
        window.addEventListener('resize', handleResize);
        let userEmail = localStorage.getItem("email");
        if (userEmail !== undefined) {
            setUserId(userEmail);
        } else {
            setUserId("$context.authorizer.principalId")
        }
        fetchBotLiteUpdates()
    }, [])
			
    function truncateStrings(str, length) {
        if (length > str.length) {
            str = str + " ".repeat(length - str.length)
            return str
        }

        str = str.substring(0, length)
        return str;
    }

    const fetchBotLiteUpdates = () => {
        setDataLoading(true);
        console.log("userId: " + userId)
        BotLiteUpdatesService.getBotLiteUpdates().then(res => res.json()).then(response => {
            setDataLoading(false);
            if (response["status"] == 'success' && response['result']) {
                let result = response['result'];
                let notifyObj = {}
				notifyObj['id'] = result['_id'];
				notifyObj['title'] = result['title'];
				notifyObj['notification_type'] = result['notification_type'];
				notifyObj['message'] = result['message'];
				notifyObj['active'] = result['active'];
				notifyObj['scheduled'] = result['scheduled'];
				notifyObj['startTime'] = result['startTime'];
				notifyObj['endTime'] = result['endTime'];
				notifyObj['timezone'] = result['timezone'] != "" ? result['timezone'] : "PST";
					
                setBotLiteMessageObj(notifyObj);		
				if(result['startTime'] != "") {
					let dateObject = new Date(result['startTime'])
					let hours = ("0" + dateObject.getHours()).slice(-2);
					let minutes = ("0" + dateObject.getMinutes()).slice(-2);
					let month = dateObject.getMonth()+1;
					let monthStr = ""+month;
					if(month < 10) {
						monthStr = "0"+month;
					}
					setStartDate(dateObject.getFullYear()+"-"+monthStr+"-"+dateObject.getDate());
					setStartDateHours(hours);
					setStartDateMinutes(minutes);
				}
				if(result['endTime'] != "") {
					let dateObject = new Date(result['endTime'])
					let hours = ("0" + dateObject.getHours()).slice(-2);
					let minutes = ("0" + dateObject.getMinutes()).slice(-2);
					let month = dateObject.getMonth()+1;
					let monthStr = ""+month;
					if(month < 10) {
						monthStr = "0"+month;
					}
					
					setEndDate(dateObject.getFullYear()+"-"+monthStr+"-"+dateObject.getDate());
					setEndDateHours(hours);
					setEndDateMinutes(minutes);
				}
				updateNotificationType(notifyObj['notification_type']);
			}
        }).catch(err => {
            console.log(err);
            setDataLoading(false);
            setBannerMessageType('error');
            setBannerMessage("Failed to retrieve BotLite updates!");
            setShowMessageBanner(true);
        })
    };

    const changeToTimezone = (dateObj, tZone) => {
		let timezoneStr = "";
		if(tZone == "PST") {
			timezoneStr = "US/Pacific";
		}else if(tZone == "IST") {
			timezoneStr = "Asia/Calcutta";
		}
		let convertedDateStr = dateObj.toLocaleString('en-US', {timeZone: timezoneStr});
		let convertedDate = new Date(convertedDateStr);
		return convertedDate;
	}

    const isValidInputs = () => {
		let valid = false;
		let message = "";
		if(botLiteMessageObj['title'].trim() == "") {
			message = "Notification title cannot be blank";
			setShowMessageBanner(true);
            setBannerMessageType('error');
            setBannerMessage(message);
		} else if(botLiteMessageObj['notification_type'] == "") {
			message = "Please select Notification type";
			setShowMessageBanner(true);
            setBannerMessageType('error');
            setBannerMessage(message);
		} else if(botLiteMessageObj['message'].trim() == "") {
			message = "Message cannot be blank";
			setShowMessageBanner(true);
            setBannerMessageType('error');
            setBannerMessage(message);
		} else if(botLiteMessageObj['scheduled']) {
			if(start_date.trim() == "" && end_date.trim() == "") {
				message = "Please select Start time or End time";
				setShowMessageBanner(true);
                setBannerMessageType('error');
                setBannerMessage(message);
			} else {
				if(start_date.trim() != "") {
					if(start_date_hours.trim() == "") {
						message = "Please select Hour value for Start Date";
						setShowMessageBanner(true);
                        setBannerMessageType('error');
                        setBannerMessage(message);
						return valid;
					}else if(start_date_minutes.trim() == "") {
						message = "Please select Minutes value for Start Date";
						setShowMessageBanner(true);
                        setBannerMessageType('error');
                        setBannerMessage(message);
						return valid;
					} 
					valid = true;
				}
				if(end_date.trim() != "") {
					valid = false;
					if(end_date_hours.trim() == "") {
						message = "Please select Hour value for End Date";
						setShowMessageBanner(true);
                        setBannerMessageType('error');
                        setBannerMessage(message);
						return valid;
					} else if(end_date_minutes.trim() == "") {
						message = "Please select Minutes value for End Date";
						setShowMessageBanner(true);
                        setBannerMessageType('error');
                        setBannerMessage(message);
						return valid;
					} 
					let endTimeStr = end_date + " " + end_date_hours + ":" + end_date_minutes + ":00";
					let endTime = new Date(endTimeStr);
					let currentTimeInSelectedTimezone = changeToTimezone(new Date(), botLiteMessageObj['timezone']);
					if(currentTimeInSelectedTimezone > endTime) {
						message = "End time cannot be before current time";
						setShowMessageBanner(true);
                        setBannerMessageType('error');
                        setBannerMessage(message);
						return valid;
					}
					valid = true;
				} 
				if(start_date.trim() != "" && end_date.trim() != "") {
					let startTimeStr = start_date + " " + start_date_hours + ":" + start_date_minutes + ":00";
					let endTimeStr = end_date + " " + end_date_hours + ":" + end_date_minutes + ":00";
					let startTime = new Date(startTimeStr);
					let endTime = new Date(endTimeStr);
					if(startTime > endTime) {
						message = "Start time cannot be after end time";
						setShowMessageBanner(true);
                        setBannerMessageType('error');
                        setBannerMessage(message);
						valid = false;
						return valid;
					}
				}
			}
		} else {
			valid = true;
		}
		return valid;
	}

    const createMessageMarkup = () => {
        return {__html: botLiteMessageObj.message};
    }

    const preview = () => {
        if (isValidInputs()) {
			setShowPreview(true)
		}
    }

    const save = () => {
        console.log(JSON.stringify(botLiteMessageObj))
        if (isValidInputs()) {
            let inputObj = {}
                        
            inputObj.title = botLiteMessageObj.title
            inputObj.notification_type = botLiteMessageObj.notification_type
            inputObj.message = botLiteMessageObj.message
            inputObj.scheduled = botLiteMessageObj.scheduled
            inputObj.startTime = ''
            inputObj.endTime = ''
            inputObj.timezone = botLiteMessageObj.timezone
            inputObj.active = botLiteMessageObj.active
			//botLiteMessageObj['startTime'] = '';
			//botLiteMessageObj['endTime'] = '';
			if(start_date.trim() != "") {
				//botLiteMessageObj['startTime'] = start_date + " " + start_date_hours + ":" + start_date_minutes + ":00";
                inputObj.startTime = start_date + " " + start_date_hours + ":" + start_date_minutes + ":00";
			}
			if(end_date.trim() != "") {
				//botLiteMessageObj['endTime'] = end_date + " " + end_date_hours + ":" + end_date_minutes + ":00";
                inputObj.endTime = end_date + " " + end_date_hours + ":" + end_date_minutes + ":00";
			}
			if(botLiteMessageObj['id'].trim() == "") {
                addNewMessage(inputObj)
			}else {
                inputObj.id = botLiteMessageObj.id
				updateMessage(inputObj)
			}
		}
    }

    const addNewMessage = (inputObj) => {
        setDataLoading(true);
		setShowMessageBanner(false);
        BotLiteUpdatesService.addBotLiteUpdates(inputObj).then(res => res.json()).then(res => {
            if (res['status'] == 'success') {
                setBannerMessageType('success');
                setBannerMessage("New Message added successfully!");
                setShowMessageBanner(true);
                setDataLoading(false);
			}
            else {
                setBannerMessageType('error');
                setBannerMessage(res['details']);
                setShowMessageBanner(true);
                setDataLoading(false);
            }
        }).catch(err => {
            console.log(err);
            setDataLoading(false);
            setBannerMessageType('error');
            setBannerMessage("Failed to place the request! Please try again.");
            setShowMessageBanner(true);
        })
    }

    const updateMessage = (inputObj) => {
        setDataLoading(true);
		setShowMessageBanner(false);
        BotLiteUpdatesService.modifyBotLiteUpdates(inputObj).then(res => res.json()).then(res => {
            if (res['status'] == 'success') {
                setBannerMessageType('success');
                setBannerMessage("Updated the message successfully!");
                setShowMessageBanner(true);
                setDataLoading(false);
			}
            else {
                setBannerMessageType('error');
                setBannerMessage(res['details']);
                setShowMessageBanner(true);
                setDataLoading(false);
            }
        }).catch(err => {
            console.log(err);
            setDataLoading(false);
            setBannerMessageType('error');
            setBannerMessage("Failed to place the request! Please try again.");
            setShowMessageBanner(true);
        })
    }

    const validateAlphaNumericString = (data) => {
        let sanitisedData = data.replaceAll(/[@.,'"&\s]/g, "");
        return isAlphanumeric(sanitisedData);
    }

    return <div>
        
        {
            isDataLoading && (<Spinner></Spinner>)
        }

        <div style={{ borderBottom: "1px #ced4da solid" }}>
            <div className="row base-margin-top base-margin-right pageTour1">
                <h5 className="col-7 col-md-8 col-sm-8 col-lg-8 col-xl-8 col-xxl-9 col-xxxl-9 ">
                    BotLite Updates
                </h5>
                <div
                    className="col-5 col-md-4 col-sm-4 col-lg-4 col-xl-4 col-xxl-3 col-xxxl-3"
                    style={{ marginBottom: "15px" }}
                >
                    
                </div>
            </div>
        </div>

        {
            showMessageBanner && <MessageBanner message={bannerMessage} messageType={bannerMessageType} onCloseClicked={() => { setShowMessageBanner(false) }}></MessageBanner>
        }

        <div className='row base-margin notification-input-section' id="wrapper_div" style={{ 'display': 'flex', 'overflowY': 'auto', maxHeight: '75vh'  }}>
            <div className="col-md-9">
                <div className="panel panel--bordered">
                    <div style={{padding: "5px 0 15px 0", fontWeight: "400"}}><h5><b>Configure Message</b></h5></div>

                    <div className="form-group base-margin-bottom">
                        <div className="form-group__text">
                            <input type="text" autoFocus name="title" value={botLiteMessageObj.title}
                                onChange={(e) => setBotLiteMessageObj(prevState => ({...prevState, "title" : e.target.value }))}
                            />
                            <label htmlFor="input-type-number">Title <span className='required'>*</span></label>
                        </div>
                    </div>

                    <div className="form-group" style={{"marginTop": '5px'}} >
                        <div className="form-group__text select" value="first" >
                            <select name="notification_type" value={botLiteMessageObj.notification_type}  
                                onChange={(e) => { updateNotificationType(e.target.value); setBotLiteMessageObj(prevState => ({...prevState, "notification_type" : e.target.value }))}} >
                                <option value="">--Select--</option>
                                <option value="info">General Updates</option>
                                <option value="warning">Upcoming Release/Outage</option>
                                <option value="critical">Ongoing Issues</option>
                            </select>
                            <label for="noti_type">Type <span className='required'>*</span></label>
                        </div>
                    </div>

                    <div className="form-group base-margin-bottom">
                        <div className="form-group__text">
                        <textarea id="botlite-message" rows="3" require 
                            value={botLiteMessageObj.message}
                            onChange={(e) => setBotLiteMessageObj(prevState => ({...prevState, "message" : e.target.value }))}
                            ></textarea>
						<label for="botlite-message">
							<span>Message</span>
							<span title="this is a required field">*</span>
						</label>
                        </div>
                    </div>

                    <div className="col-md-2 padding-zero">
                        <div className="form-group">
                            <label className="switch">
                                <input type="checkbox" name="scheduled" checked={botLiteMessageObj.scheduled} 
                                    onChange={(e) => setBotLiteMessageObj(prevState => ({...prevState, "scheduled" : e.target.checked }))} />
                                <span className="switch__input"></span>
                                <span className="switch__label">Schedule</span>
                            </label>
                        </div>
                    </div>
                    {
                        botLiteMessageObj.scheduled && <> 
                            <div className="row" style={{marginTop: "15px", paddingLeft: '2%'}}>
                                <div className="col-md-4 form-group">
                                    <div className="form-group__text">
                                        <input type="date" 
                                            id="start-date"
                                            name="start_date"
                                            min={today.toISOString().slice(0, 10)}
                                            value={start_date}  
                                            onChange={event => { setStartDate(event.target.value) }}/>
                                        <label for="start-date">Start Time</label>
                                    </div>
                                </div>
                                
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <div className="form-group__text select">
                                            <label for="start-schedule-hr">Hour</label>
                                            <select id="start-schedule-hr"
                                                name="start_date_hours"
                                                value={start_date_hours} 
                                                onChange={event => {setStartDateHours(event.target.value) }} >
                                                    <option value="">--Select--</option>
                                                    {
                                                        DATE_HOURS.map((hourVal)=>{
                                                            return (<option value={hourVal} >{hourVal}</option>)
                                                        })
                                                    }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <div className="form-group__text select">
                                            <label for="end-schedule-minutes">Minutes</label>
                                            <select id="end-schedule-minutes"
                                                name="start_date_minutes"
                                                value={start_date_minutes} 
                                                onChange={event => {setStartDateMinutes(event.target.value) }}>
                                                    <option value="">--Select--</option>
                                                    {
                                                        DATE_MINS.map((minuteVal)=>{
                                                            return (<option value={minuteVal} >{minuteVal}</option>)
                                                        })
                                                    }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row" style={{marginTop: "15px", paddingLeft: '2%'}}>
                                <div className="col-md-4 form-group">
                                    <div className="form-group__text">
                                        <input type="date" 
                                            id="end-date"
                                            name="end_date"
                                            min={today.toISOString().slice(0, 10)}
                                            value={end_date}  
                                            onChange={event => { setEndDate(event.target.value) }}/>
                                        <label for="end-date">End Time</label>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="form-group">
                                        <div className="form-group__text select">
                                            <label for="end-schedule-hr">Hour</label>
                                            <select id="end-schedule-hr"
                                                name="end_date_hours"
                                                value={end_date_hours} 
                                                onChange={event => {setEndDateHours(event.target.value) }} >
                                                    <option value="">--Select--</option>
                                                    {
                                                        DATE_HOURS.map((hourVal)=>{
                                                            return (<option value={hourVal} >{hourVal}</option>)
                                                        })
                                                    }
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="form-group">
                                        <div className="form-group__text select">
                                            <label for="end-schedule-minutes">Minutes</label>
                                            <select id="end-schedule-minutes"
                                                name="end_date_minutes"
                                                value={end_date_minutes} 
                                                onChange={event => {setEndDateMinutes(event.target.value) }}>
                                                    <option value="">--Select--</option>
                                                    {
                                                        DATE_MINS.map((minuteVal)=>{
                                                            return (<option value={minuteVal} >{minuteVal}</option>)
                                                        })
                                                    }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row" style={{marginTop: "15px"}}>
                                <div className="col-md-6">
                                    <div className="subheader">Timezone</div>
                                    <div className="form-group form-group--inline">
                                        <label className="radio">
                                            <input type="radio" name="timezone" 
                                                value="PST"
                                                checked={botLiteMessageObj.timezone === 'PST'}
                                                onChange={(e) => setBotLiteMessageObj(prevState => ({...prevState, "timezone" : "PST" }))} />
                                            <span className="radio__input"></span>
                                            <span className="radio__label hidden-xs">PST</span>
                                        </label>
                                    </div>
                                    <div className="form-group form-group--inline">
                                        <label className="radio">
                                            <input type="radio" name="timezone" 
                                                value="IST"checked={botLiteMessageObj.timezone === 'IST'}
                                                onChange={(e) => setBotLiteMessageObj(prevState => ({...prevState, "timezone" : "IST" }))} />
                                            <span className="radio__input"></span>
                                            <span className="radio__label hidden-xs">IST</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                    <div className="col-md-2 padding-zero" style={{marginTop: '15px'}}>
                        <div className="form-group">
                            <label className="switch">
                                <input type="checkbox" name="active" checked={botLiteMessageObj.active} 
                                    onChange={(e) => setBotLiteMessageObj(prevState => ({...prevState, "active" : e.target.checked }))} />
                                <span className="switch__input"></span>
                                <span className="switch__label">Enable</span>
                            </label>
                        </div>
                    </div>

                    <div style={{paddingTop: "5%"}}>
                        <button className="btn btn--secondary" onClick={() => { preview() }}>Preview</button>
                        <button className="btn btn--secondary" onClick={() => { save() }}>Save</button>
                    </div>
                </div>
            </div>
        </div>

        <Modal size="lg" centered show={showPreview} animation={false} >
            <Modal.Header>
                <h5>{botLiteMessageObj.title}</h5>
                <div className=' btn--small btn--icon' onClick={() => { setShowPreview(false) }}>
                    <span className="icon-close icon-size-24"></span>
                </div>
            </Modal.Header>

            <Modal.Body>
                <div className='base-padding'>
                    <div className={`alert ${alertType} panel`} style={{"zIndex": "100px" }}>
                        <div class={`alert__icon ${alertIcon}`}></div>
						<div class="alert__message" dangerouslySetInnerHTML={createMessageMarkup()}></div>
                    </div>
                </div>
            </Modal.Body>

            <Modal.Footer>
                <button className="btn btn--secondary" onClick={() => { setShowPreview(false) }}>
                    Close
                </button>
            </Modal.Footer>
        </Modal>

    </div>

}

export default BotLiteUpdatesView;