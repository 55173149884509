import Wrapper from "../../../../common-components/wrapper/Wrapper";
import NotificationView from '../notification-view-component/NotificationViewComponent'

const NotificationScreen = () => {
  return (
    <Wrapper>
      <NotificationView />
    </Wrapper>
  );
};

export default NotificationScreen;
