import React, { useState, useEffect, useRef } from "react";
import DataGridComponent from "../../../../custom-components/data-grid/DataGrid";
import "./ApprovalViewComponent.css";

import Spinner from "../../../../custom-components/spinner/Spinner";
import MessageBanner from "../../../../custom-components/MessageBanner/MessageBanner";
import ApprovalService from "../../approval-services/ApprovalService";

import UserManagementService from "../../../user-management-module/user-management-service/UserManagementService";

const ApprovalView = () => {

	// const [tab_selected, switchTab] = useState('Externalisation');
	const [tab_selected, switchTab] = useState('Skill');
	const [activeApprovalTab, setActiveApprovalTab] = useState(1);
	const [activeSkillApprovalTab, setActiveSkillApprovalTab] = useState(1);
	const [selectedExpression, setSelectedExpression] = useState([]);

	const [rejectionReasonList, setRejectionReasonList] = useState({});

	const [skillPendingRequests, setSkillPendingRequests] = useState([]);
	const [skillApprovedRequests, setSkillApprovedRequests] = useState([]);
	const [skillRejectedRequests, setSkillRejectedRequests] = useState([]);

	const [externaliseApprove, setExternaliseApprove] = useState([]);
	const [externaliseRequests, setExternaliseRequests] = useState([]);

	const [isPendingSkillRequestsLoaded, setPendingSkillRequestsLoaded] = useState(false);
	const [isApprovedSkillRequestsLoaded, setApprovedSkillRequestsLoaded] = useState(false);
	const [isRejectedSkillRequestsLoaded, setRejectedSkillRequestsLoaded] = useState(false);

	const [activeBotApprovalTab, setActiveBotApprovalTab] = useState(1);

	const [botPendingRequests, setBotPendingRequests] = useState([]);
	const [botApprovedRequests, setBotApprovedRequests] = useState([]);
	const [botRejectedRequests, setBotRejectedRequests] = useState([]);

	const [isPendingBotRequestsLoaded, setPendingBotRequestsLoaded] = useState(false);
	const [isApprovedBotRequestsLoaded, setApprovedBotRequestsLoaded] = useState(false);
	const [isRejectedBotRequestsLoaded, setRejectedBotRequestsLoaded] = useState(false);

	const [isDataLoading, setDataLoading] = useState(false);

	const [showMessageBanner, setShowMessageBanner] = useState(false);
	const [bannerMessage, setBannerMessage] = useState('');
	const [bannerMessageType, setBannerMessageType] = useState('');

	const [botRequestData, setBotRequestData] = useState([]);

	const [isAdmin, setIsAdmin] = useState(false);

	const getComments = (params) => {
		return 'Manager: ' + params.row.comments.manager + '\\n' + 'Director: ' + params.row.comments.director
	}
	console.log(rejectionReasonList)

	const getApprovalClass = (id) => {
		if (id === activeApprovalTab) {
			return "tab active";
		} else {
			return "tab";
		}
	};

	const getSkillApprovalClass = (id) => {
		if (id === activeSkillApprovalTab) {
			return "tab active";
		} else {
			return "tab";
		}
	};

	const getApprovalTabContentClass = (id) => {
		if (id === activeApprovalTab) {
			return "tab-pane active";
		} else {
			return "tab-pane";
		}
	};

	const getSkillApprovalTabContentClass = (id) => {
		if (id === activeSkillApprovalTab) {
			return "tab-pane active";
		} else {
			return "tab-pane";
		}
	};

	const handleSkillRequestsTabClick = (tabIndex) => {
		setActiveSkillApprovalTab(tabIndex);
		if (tabIndex === 1) {
			if (!isPendingSkillRequestsLoaded) {
				fetchPendingSkillRequests();
			}
		} else if (tabIndex === 2) {
			if (!isApprovedSkillRequestsLoaded) {
				fetchApprovedSkillRequests();
			}
		} else if (tabIndex === 3) {
			if (!isRejectedSkillRequestsLoaded) {
				fetchRejectedSkillRequests();
			}
		}
	}

	const handleInputChange = (e, id) => {
		let rejectReason = e.target.value;
		let reasonList = { ...rejectionReasonList };
		reasonList[id] = rejectReason
		setRejectionReasonList(reasonList);
	};

	useEffect(() => {
		setDataLoading(true);
		UserManagementService.isBotLiteAdmin()
			.then((res) => res.json())
			.then((res) => {
				if (res["status"] === "success" && res["isBotLiteAdmin"]) {
					fetchPendingBotRequests();
					console.log(res)
					setIsAdmin(true);
				}
				setDataLoading(false);
			})
			.catch((err) => {
				console.log("Not BotLite admin.");
				setDataLoading(false);
			});
		fetchPendingSkillRequests();
		// Disable Externalization Feature For Time Being
		// fetchExternalizationData();
	}, [])

	const externalise_approve_col = [
		{
			field: 'botApproval',
			headerName: 'Bot Approval',
			flex: 0.2
		},
		{
			field: 'botDescription',
			headerName: 'Bot Description',
			flex: 0.3,
		},
		{
			field: 'status',
			headerName: 'Status',
			flex: 0.5,
			sortable: false,

			renderCell: (params) => {
				return <div className="col-12">
					<div className="steps">
						<div className="step visited">
							<div className="step__icon">
								<span className="icon-check"></span>
							</div>
							<div className="step__label">Request Submitted</div>
						</div>
						<div className="step active">
							<div className="step__icon">2</div>
							<div className="step__label">Manager Approval</div>
						</div>
						<div className="step">
							<div className="step__icon">3</div>
							<div className="step__label">Director Approval</div>
						</div>
						<div className="step">
							<div className="step__icon">4</div>
							<div className="step__label">Approved</div>
						</div>
					</div>
				</div>
			}

		},
		{
			field: 'expiryDate',
			headerName: 'Expiry Date',
			flex: 0.15, editable: false,
		},
		{
			field: 'comments',
			headerName: 'Comments',
			flex: 0.3,
			sortable: false,
			editable: true
		},
		{
			field: 'action',
			headerName: 'Action',
			sortable: false,
			flex: 0.25,
			renderCell: (params) => {
				const ApproveRequest = (e) => {
					e.stopPropagation();
					const api = params.api;
					const thisRow = {};
					console.log(params.row.id)
					approveExternalizationRequest(params.row.id)
				};

				const RejectRequest = (e) => {
					e.stopPropagation();
					const api = params.api;
					const thisRow = {};
					console.log(params.row.id)
					rejectExternalizationRequest(params.row.id)
				};

				return <div className="row base-margin-left">
					<button className="btn btn--small btn--secondary" onClick={ApproveRequest}>Approve</button>
					<button className="btn btn--small btn--secondary" onClick={RejectRequest} >Reject</button>
				</div>;
			},

		}
	]

	const externalise_approve_row = [
		{
			id: 1,
			botApproval: 'test_bot_rk@webex.bot',
			botDescription: 'test_bot_rk',
			status: '',
			expiryDate: '2024-06-05',
			comments: 'consectetur adipiscing elit consectetur adipiscing elit',
		},
		{
			id: 2,
			botApproval: 'zest_bot_rk@webex.bot',
			botDescription: 'zest_bot_rk',
			status: '',
			expiryDate: '2024-06-05',
			comments: 'consectetur adipiscing elit consectetur adipiscing elit',
		},
		{
			id: 3,
			botApproval: 'pest_bot_rk@webex.bot',
			botDescription: 'pest_bot_rk',
			status: '',
			expiryDate: '2024-06-05',
			comments: 'consectetur adipiscing elit consectetur adipiscing elit',
		},
		{
			id: 4,
			botApproval: 'aest_bot_rk@webex.bot',
			botDescription: 'aest_bot_rk',
			status: '',
			expiryDate: '2024-06-05',
			comments: 'consectetur adipiscing elit consectetur adipiscing elit',
		},
		{
			id: 5,
			botApproval: 'jest_bot_rk@webex.bot',
			botDescription: 'jest_bot_rk',
			status: '',
			expiryDate: '2024-06-05',
			comments: 'consectetur adipiscing elit consectetur adipiscing elit',
		},
		{
			id: 6,
			botApproval: 'sest_bot_rk@webex.bot',
			botDescription: 'sest_bot_rk',
			status: '',
			expiryDate: '2024-06-05',
			comments: 'consectetur adipiscing elit consectetur adipiscing elit',
		},
		{
			id: 7,
			botApproval: 'jest_bot_rk@webex.bot',
			botDescription: 'jest_bot_rk',
			status: '',
			expiryDate: '2024-06-05',
			comments: 'consectetur adipiscing elit consectetur adipiscing elit',
		}
	]

	const skillPendingRequestHeaders = [
		{
			field: 'skillName',
			headerName: 'Skill Name',
			flex: 0.2
		},
		{
			field: 'skillRequestedBy',
			headerName: 'Requested By',
			flex: 0.2
		},
		{
			field: 'skillRequestedOn',
			headerName: 'Requested On',
			flex: 0.2
		},
		{
			field: 'skillApproveReject',
			headerName: 'Action',
			flex: 0.2,
			sortable: false,
			renderCell: (params) => {
				const ApproveRequest = (e) => {
					e.stopPropagation();
					const api = params.api;
					const thisRow = {};
					console.log(params.row.id)
					handleSkillRequestAction(params.row.id, "approved")
				};

				const RejectRequest = (e) => {
					e.stopPropagation();
					const api = params.api;
					const thisRow = {};
					console.log(params.row.id)
					handleSkillRequestAction(params.row.id, "rejected")
				};
				console.log("rejectionReasonList1", rejectionReasonList)
				console.log("rejectionReasonList2", rejectionReasonList[params.row.id])
				console.log("rejectionReasonList3", params.row.id)
				return <div className="row">
					<button className="btn btn--small btn--secondary"
						onClick={ApproveRequest}>Approve</button>
					<button className="btn btn--small btn--secondary"
						disabled={rejectionReasonList && rejectionReasonList[params.row.id] && rejectionReasonList[params.row.id].trim().length < 15}
						onClick={RejectRequest}>Reject</button>
				</div>;
			}
		},
		{
			field: 'rejectReason',
			headerName: 'Reject Justification',
			flex: 0.35,
			sortable: false,
			renderCell: (params) => {
				return <div className="col-12">
					<textarea onKeyDown={e => e.stopPropagation()} onChange={e => handleInputChange(e, params.row.id)} placeholder="Enter the reason (Min. 15 characters required)"></textarea>
				</div>
			}
		}
	]

	const skillApprovedRequestHeaders = [
		{
			field: 'SkillName',
			headerName: 'Skill Name',
			flex: 0.25
		},
		{
			field: 'RequestedBy',
			headerName: 'Requested By',
			flex: 0.2
		},
		{
			field: 'RequestedOn',
			headerName: 'Requested On',
			flex: 0.2
		},
		{
			field: 'ApprovedBy',
			headerName: 'Approved By',
			flex: 0.2
		},
		{
			field: 'ApprovedOn',
			headerName: 'Approved On',
			flex: 0.2
		}
	]

	const skillRejectedRequestHeaders = [
		{
			field: 'SkillName',
			headerName: 'Skill Name',
			flex: 0.2
		},
		{
			field: 'RequestedBy',
			headerName: 'Requested By',
			flex: 0.15
		},
		{
			field: 'RequestedOn',
			headerName: 'Requested On',
			flex: 0.15
		},
		{
			field: 'RejectedBy',
			headerName: 'Rejected By',
			flex: 0.15
		},
		{
			field: 'RejectedOn',
			headerName: 'Rejected On',
			flex: 0.15
		},
		{
			field: 'RejectedReason',
			headerName: 'Rejection Reason',
			flex: 0.3
		}
	]

	const fetchPendingSkillRequests = () => {
		setDataLoading(true);
		let approval_status = "pending";
		ApprovalService.getRequestsByStatus(approval_status).then(res => res.json()).then(res => {
			if (res["requests"] != undefined) {
				setPendingSkillRequestsLoaded(true);
				let result = res["requests"];
				let pendingRequestList = []
				setSkillPendingRequests((prev) => {
					setDataLoading(false);
					let reasonList = {};
					if (result.length > 0) {
						for (let requestObj of result) {
							reasonList[requestObj._id] = "";
							pendingRequestList.push({
								id: requestObj._id,
								skillName: requestObj.name,
								skillRequestedBy: requestObj.created_by,
								skillRequestedOn: formatDate(requestObj.created_at),
								skillApproveReject: '',
								rejectReason: ''
							})
						}
						setRejectionReasonList(reasonList);
					}
					return pendingRequestList;
				});
			}
			else {
				setDataLoading(false);
				setBannerMessageType('error');
				setBannerMessage("Failed to retrieve Pending Skill requests!");
				setShowMessageBanner(true);
			}
		}).catch(err => {
			setDataLoading(false);
			setBannerMessageType('error');
			setBannerMessage("Failed to retrieve Pending Skill requests!");
			setShowMessageBanner(true);
		})
	};

	const fetchExternalizationData = () => {
		setDataLoading(true);

		ApprovalService.getExternalizationData().then(res => res.json()).then(res => {
			// console.log(res);

			let externaliseApproveRecords = res.approvals;
			setExternaliseApprove(() => {
				let externaliseApproveRow = []
				externaliseApproveRecords.forEach((entity, index, array) => {
					// console.log(entity)


					externaliseApproveRow.push({
						id: entity._id,
						botApproval: '',
						botDescription: entity.description,
						status: entity.status,
						expiryDate: '',
						comments: '',
					})
				})
				// console.log(externaliseApproveRow)
				return externaliseApproveRow

			})

			let externaliseRequestRecords = res.requests;
			setExternaliseRequests(() => {
				let externaliseRequestRow = []
				externaliseRequestRecords.forEach((entity) => {
					// console.log(entity)

					externaliseRequestRow.push({
						id: entity._id,
						botApproval: '',
						botDescription: entity.description,
						status: entity.status,
						expiryDate: '',
						comments: '',
					})
				})
				// console.log(externaliseRequestRow)
				return externaliseRequestRow

			})

		}).catch(err => {
			setDataLoading(false);
			setBannerMessageType('error');
			setBannerMessage("Failed to retrieve Externalization Records!");
			setShowMessageBanner(true);
		})
	};

	const fetchApprovedSkillRequests = () => {
		setDataLoading(true);
		let approval_status = "approved";
		ApprovalService.getRequestsByStatus(approval_status).then(res => res.json()).then(res => {
			if (res["requests"] != undefined) {
				setApprovedSkillRequestsLoaded(true);
				let result = res["requests"];
				let approvedRequestList = []
				setSkillApprovedRequests((prev) => {
					setDataLoading(false);
					if (result.length > 0) {
						for (let requestObj of result) {
							approvedRequestList.push({
								id: requestObj._id,
								SkillName: requestObj.name,
								RequestedBy: requestObj.created_by,
								RequestedOn: formatDate(requestObj.created_at),
								ApprovedBy: requestObj.updated_by,
								ApprovedOn: formatDate(requestObj.updated_at)
							})
						}
					}
					return approvedRequestList;
				});
			}
			else {
				setDataLoading(false);
				setBannerMessageType('error');
				setBannerMessage("Failed to retrieve Approved Skill requests!");
				setShowMessageBanner(true);
			}
		}).catch(err => {
			setDataLoading(false);
			setBannerMessageType('error');
			setBannerMessage("Failed to retrieve Approved Skill requests!");
			setShowMessageBanner(true);
		})
	};

	const fetchRejectedSkillRequests = () => {
		setDataLoading(true);
		let approval_status = "rejected";
		ApprovalService.getRequestsByStatus(approval_status).then(res => res.json()).then(res => {
			if (res["requests"] != undefined) {
				setRejectedSkillRequestsLoaded(true);
				let result = res["requests"];
				let rejectedRequestList = []
				setSkillRejectedRequests((prev) => {
					setDataLoading(false);
					if (result.length > 0) {
						for (let requestObj of result) {
							rejectedRequestList.push({
								id: requestObj._id,
								SkillName: requestObj.name,
								RequestedBy: requestObj.created_by,
								RequestedOn: formatDate(requestObj.created_at),
								RejectedBy: requestObj.updated_by,
								RejectedOn: formatDate(requestObj.updated_at),
								RejectedReason: requestObj.rejection_reason
							})
						}
					}
					return rejectedRequestList;
				});
			}
			else {
				setDataLoading(false);
				setBannerMessageType('error');
				setBannerMessage("Failed to retrieve Rejected Skill requests!");
				setShowMessageBanner(true);
			}
		}).catch(err => {
			setDataLoading(false);
			setBannerMessageType('error');
			setBannerMessage("Failed to retrieve Rejected Skill requests!");
			setShowMessageBanner(true);
		})
	};

	const approveExternalizationRequest = (id) => {
		console.log("approving ", id)
	}

	const rejectExternalizationRequest = (id) => {
		console.log("rejecting ", JSON.stringify(id))
		console.log("selectedExpression ", selectedExpression)
	}

	const handleSkillRequestAction = (id, approval_status) => {
		console.log("id ", id)
		console.log("action ", approval_status)
		setDataLoading(true);
		let request_obj = {}
		request_obj["_id"] = id
		request_obj["approval_status"] = approval_status
		if (approval_status == "rejected") {
			request_obj["rejection_reason"] = rejectionReasonList[id]
		}

		ApprovalService.updateSkillRequest(request_obj).then(res => res.json()).then(res => {
			setDataLoading(false);
			if (res["status"] == "success") {
				setBannerMessageType('success');
				setShowMessageBanner(true);
				fetchPendingSkillRequests();
				if (approval_status == "approved") {
					setBannerMessage("Approved the request successfully!");
					fetchApprovedSkillRequests();
				} else {
					setBannerMessage("Rejected the request successfully!");
					fetchRejectedSkillRequests();
				}
			}
			else {
				setBannerMessageType('error');
				setBannerMessage("Failed to perform the operation, please try again!");
				setShowMessageBanner(true);
			}
		}).catch(err => {
			setDataLoading(false);
			setBannerMessageType('error');
			setBannerMessage("Failed to perform the operation, please try again!");
			setShowMessageBanner(true);
		})
	}

	const formatDate = (dateStr) => {
		if (dateStr == undefined || dateStr == "")
			return "";
		let dateObj = new Date(dateStr);
		// let formattedDate = dateObj.getFullYear() + "-" + (dateObj.getMonth() + 1) + "-" + +dateObj.getDate();
		let formattedDate = (dateObj.getMonth() + 1) + '/' + dateObj.getDate() + '/' + dateObj.getFullYear();
		return formattedDate;
	}

	const handleBotRequestAction = (id, approval_status) => {
		console.log("id ", id);
		console.log("action ", approval_status);
		setDataLoading(true);
		let request_obj = {};
		let display_name = "";
		for (let pending of botRequestData["pending"]) {
			if (pending["_id"] === id) {
				display_name = pending["display_name"];
			}
		}
		console.log(botRequestData["pending"]);
		request_obj["display_name"] = display_name;
		request_obj["action"] = approval_status;
		if (approval_status == "reject") {
			request_obj["justification"] = rejectionReasonList[id];
		} else if (approval_status == "approve") {
			request_obj["justification"] = "approved";
		}

		ApprovalService.updateBotRequest(request_obj).then(res => res.json()).then(res => {
			setDataLoading(false);
			if (res["status"] == "success") {
				setBannerMessageType('success');
				setShowMessageBanner(true);
				fetchPendingBotRequests();
				if (approval_status == "approve") {
					setBannerMessage("Approved the request successfully!");
					fetchApprovedBotRequests();
				} else {
					setBannerMessage("Rejected the request successfully!");
					fetchRejectedBotRequests();
				}
			}
			else {
				setBannerMessageType('error');
				setBannerMessage("Failed to perform the operation, please try again!");
				setShowMessageBanner(true);
			}
		}).catch(err => {
			setDataLoading(false);
			setBannerMessageType('error');
			setBannerMessage("Failed to perform the operation, please try again!");
			setShowMessageBanner(true);
		})
	}

	const botPendingRequestHeaders = [
		{
			field: 'BotName',
			headerName: 'Bot Name',
			flex: 0.2
		},
		{
			field: 'BotRequestedBy',
			headerName: 'Requested By',
			flex: 0.2
		},
		{
			field: 'BotRequestedOn',
			headerName: 'Requested On',
			flex: 0.15
		},
		{
			field: 'BusinessUnit',
			headerName: 'Business Unit',
			flex: 0.2
		},
		{
			field: 'Justification',
			headerName: 'Justification',
			flex: 0.5
		},
		{
			field: 'BusinessBenefit',
			headerName: 'Business Benefit',
			flex: 0.5
		},
		{
			field: 'useGenAI',
			headerName: 'Generative AI',
			flex: 0.1
		},
		{
			field: 'BotApproveReject',
			headerName: 'Action',
			flex: 0.3,
			sortable: false,
			renderCell: (params) => {
				const ApproveRequest = (e) => {
					e.stopPropagation();
					const api = params.api;
					const thisRow = {};
					handleBotRequestAction(params.row.id, "approve")
				};

				const RejectRequest = (e) => {
					e.stopPropagation();
					const api = params.api;
					const thisRow = {};
					handleBotRequestAction(params.row.id, "reject")
				};

				return <div className="row">
					<button className="btn btn--small btn--secondary"
						onClick={ApproveRequest}>Approve</button>
					<button className="btn btn--small btn--secondary"
						disabled={rejectionReasonList[params.row.id].trim().length < 15}
						onClick={RejectRequest}>Reject</button>
				</div>;
			}
		},
		{
			field: 'BotRejectReason',
			headerName: 'Reject Justification',
			flex: 0.33,
			sortable: false,
			renderCell: (params) => {
				return <div className="col-12">
					<textarea onKeyDown={e => e.stopPropagation()} onChange={e => handleInputChange(e, params.row.id)} placeholder="Enter the reason (Min. 15 characters required)"></textarea>
				</div>
			}
		}
	]

	const botApprovedRequestHeaders = [
		{
			field: 'BotName',
			headerName: 'Bot Name',
			flex: 0.2
		},
		{
			field: 'RequestedBy',
			headerName: 'Requested By',
			flex: 0.15
		},
		{
			field: 'RequestedOn',
			headerName: 'Requested On',
			flex: 0.15
		},
		{
			field: 'BusinessUnit',
			headerName: 'Business Unit',
			flex: 0.2
		},
		{
			field: 'Justification',
			headerName: 'Justification',
			flex: 0.5
		},
		{
			field: 'BusinessBenefit',
			headerName: 'Business Benefit',
			flex: 0.5
		},
		{
			field: 'useGenAI',
			headerName: 'Generative AI',
			flex: 0.1
		},
		{
			field: 'ApprovedBy',
			headerName: 'Approved By',
			flex: 0.15
		},
		{
			field: 'ApprovedOn',
			headerName: 'Approved On',
			flex: 0.15
		}
	]

	const botRejectedRequestHeaders = [
		{
			field: 'BotName',
			headerName: 'Bot Name',
			flex: 0.2
		},
		{
			field: 'RequestedBy',
			headerName: 'Requested By',
			flex: 0.2
		},
		{
			field: 'RequestedOn',
			headerName: 'Requested On',
			flex: 0.2
		},
		{
			field: 'BusinessUnit',
			headerName: 'Business Unit',
			flex: 0.2
		},
		{
			field: 'Justification',
			headerName: 'Justification',
			flex: 0.5
		},
		{
			field: 'BusinessBenefit',
			headerName: 'Business Benefit',
			flex: 0.5
		},
		{
			field: 'useGenAI',
			headerName: 'Generative AI',
			flex: 0.1
		},
		{
			field: 'RejectedBy',
			headerName: 'Rejected By',
			flex: 0.2
		},
		{
			field: 'RejectedOn',
			headerName: 'Rejected On',
			flex: 0.2
		},
		{
			field: 'BotRejectedReason',
			headerName: 'Reject Justification',
			flex: 0.3
		}
	]

	const handleBotRequestsTabClick = (tabIndex) => {
		setActiveBotApprovalTab(tabIndex);
		if (tabIndex == 1) {
			if (!isPendingBotRequestsLoaded) {
				fetchPendingBotRequests();
			}
		} else if (tabIndex == 2) {
			if (!isApprovedBotRequestsLoaded) {
				fetchApprovedBotRequests();
			}
		} else if (tabIndex == 3) {
			if (!isRejectedBotRequestsLoaded) {
				fetchRejectedBotRequests();
			}
		}
	}

	const getBotApprovalClass = (id) => {
		if (id === activeBotApprovalTab) {
			return "tab active";
		} else {
			return "tab";
		}
	};

	const getBotApprovalTabContentClass = (id) => {
		if (id === activeBotApprovalTab) {
			return "tab-pane active";
		} else {
			return "tab-pane";
		}
	};

	const fetchPendingBotRequests = () => {
		setDataLoading(true);
		ApprovalService.getBotRequestsByStatus().then(res => res.json()).then(res => {
			if (res != undefined) {
				setPendingBotRequestsLoaded(true);
				setBotRequestData(res);
				console.log(res);
				let result = res["pending"];
				let pendingRequestList = []
				setBotPendingRequests((prev) => {
					setDataLoading(false);
					let reasonList = {};
					if (result.length > 0) {
						for (let requestObj of result) {
							reasonList[requestObj._id] = "";
							pendingRequestList.push({
								id: requestObj._id,
								BotName: requestObj.display_name,
								BotRequestedBy: requestObj.requested_by,
								BotRequestedOn: formatDate(requestObj.requested_at),
								BusinessUnit: requestObj.business_unit,
								Justification: requestObj.business_justification,
								BusinessBenefit: requestObj.business_benefit,
								BotApproveReject: '',
								BotRejectReason: '',
								useGenAI: requestObj["useGenAI"] || false
							})
						}
						setRejectionReasonList(reasonList);
					}
					return pendingRequestList;
				});
			}
			else {
				setDataLoading(false);
				setBannerMessageType('error');
				setBannerMessage("Failed to retrieve Pending Bot requests!");
				setShowMessageBanner(true);
			}
		}).catch(err => {
			setDataLoading(false);
			setBannerMessageType('error');
			setBannerMessage("Failed to retrieve Pending Bot requests!");
			setShowMessageBanner(true);
		})
	};

	const fetchApprovedBotRequests = () => {
		setDataLoading(true);
		ApprovalService.getBotRequestsByStatus().then(res => res.json()).then(res => {
			if (res != undefined) {
				setApprovedBotRequestsLoaded(true);
				let result = res["approved"];
				let approvedRequestList = []
				setBotApprovedRequests((prev) => {
					setDataLoading(false);
					if (result.length > 0) {
						for (let requestObj of result) {
							approvedRequestList.push({
								id: requestObj._id,
								BotName: requestObj.display_name,
								RequestedBy: requestObj.requested_by,
								RequestedOn: formatDate(requestObj.requested_at),
								BusinessUnit: requestObj.business_unit,
								Justification: requestObj.business_justification,
								BusinessBenefit: requestObj.business_benefit,
								ApprovedBy: requestObj.approved_by,
								ApprovedOn: formatDate(requestObj.approved_at),
								useGenAI: requestObj["useGenAI"] || false
							})
						}
					}
					return approvedRequestList;
				});
			}
			else {
				setDataLoading(false);
				setBannerMessageType('error');
				setBannerMessage("Failed to retrieve Approved Bot requests!");
				setShowMessageBanner(true);
			}
		}).catch(err => {
			setDataLoading(false);
			setBannerMessageType('error');
			setBannerMessage("Failed to retrieve Approved Bot requests!");
			setShowMessageBanner(true);
		})
	};

	const fetchRejectedBotRequests = () => {
		setDataLoading(true);
		ApprovalService.getBotRequestsByStatus().then(res => res.json()).then(res => {
			if (res != undefined) {
				setRejectedBotRequestsLoaded(true);
				let result = res["rejected"];
				let rejectedRequestList = []
				setBotRejectedRequests((prev) => {
					setDataLoading(false);
					if (result.length > 0) {
						for (let requestObj of result) {
							rejectedRequestList.push({
								id: requestObj._id,
								BotName: requestObj.display_name,
								RequestedBy: requestObj.requested_by,
								RequestedOn: formatDate(requestObj.requested_at),
								BusinessUnit: requestObj.business_unit,
								Justification: requestObj.business_justification,
								BusinessBenefit: requestObj.business_benefit,
								RejectedBy: requestObj.approved_by,
								RejectedOn: formatDate(requestObj.approved_at),
								BotRejectedReason: requestObj.justification,
								useGenAI: requestObj["useGenAI"] || false
							})
						}
					}
					return rejectedRequestList;
				});
			}
			else {
				setDataLoading(false);
				setBannerMessageType('error');
				setBannerMessage("Failed to retrieve Rejected Bot requests!");
				setShowMessageBanner(true);
			}
		}).catch(err => {
			setDataLoading(false);
			setBannerMessageType('error');
			setBannerMessage("Failed to retrieve Rejected Bot requests!");
			setShowMessageBanner(true);
		})
	};

	return (
		<>
			{
				isDataLoading && (<Spinner></Spinner>)
			}
			<div className="approvalView">
				<div className="row base-margin-top base-margin-right" style={{ borderBottom: "1px solid #ced4da" }} >
					<h5 className="base-margin-left">
						Approval View
					</h5>
				</div>
				<div className="row">
					<div className="col-lg-12">
						<div className='trainingTabWrapper'>
							<div className="col-lg-12">
								<div className='trainingtab-selector'>
									<ul className="tabs tabs--justified trainPage-tabSelector">
										{isAdmin && <li id="api-entity-tab" className={tab_selected == "Bots" ? "approvalview active" : "" + "approvalview tab"} onClick={() => switchTab("Bots")}>
											<a tabindex="0" >Bot Requests </a>
										</li>}
										{/* <li id="static-entity-tab" className={tab_selected == "Externalisation" ? "approvalview active" : "" + "approvalview tab"} onClick={() => switchTab("Externalisation")}>
									<a tabindex="0">Externalisation </a>
									</li> */}
										<li id="api-entity-tab" className={tab_selected == "Skill" ? "approvalview active" : "" + "approvalview tab"} onClick={() => switchTab("Skill")}>
											<a tabindex="0" >Skill </a>
										</li>
									</ul>
								</div>
								{
									showMessageBanner && <MessageBanner message={bannerMessage} messageType={bannerMessageType} onCloseClicked={() => { setShowMessageBanner(false) }}></MessageBanner>
								}
								<div className="tab-content approvalTab">
									{/* <div className={tab_selected == "Externalisation" ? "active tab-pane" : "tab-pane"}>
									<ul id="bordered" className="tabs tabs--bordered">
										<li id="bordered-1" onClick={() => setActiveApprovalTab(1)} className={getApprovalClass(1)}>
											<a tabindex="0">Approval Data</a>
										</li>
										<li id="bordered-2" onClick={() => setActiveApprovalTab(2)} className={getApprovalClass(2)}>
											<a tabindex="0">Request Data</a>
										</li>
									</ul>
									<div id="bordered-content" className="tab-content approvalTabContent">
										<div id="bordered-1-content" className={getApprovalTabContentClass(1)}>
											<DataGridComponent rows={externaliseApprove} columns={externalise_approve_col} rowHeight={70} />
										</div>
										<div id="bordered-2-content" className={getApprovalTabContentClass(2)}>
											<DataGridComponent rows={externaliseRequests} columns={externalise_approve_col} rowHeight={70} />
										</div>
									</div>
								</div> */}
									<div className={tab_selected == "Skill" ? "active tab-pane" : "tab-pane"}>
										<ul id="bordered" className="tabs tabs--bordered">
											<li id="bordered-1" onClick={() => handleSkillRequestsTabClick(1)} className={getSkillApprovalClass(1)}>
												<a tabindex="0">Pending Requests</a>
											</li>
											<li id="bordered-2" onClick={() => handleSkillRequestsTabClick(2)} className={getSkillApprovalClass(2)}>
												<a tabindex="0">Approved Requests</a>
											</li>
											<li id="bordered-2" onClick={() => handleSkillRequestsTabClick(3)} className={getSkillApprovalClass(3)}>
												<a tabindex="0">Rejected Requests</a>
											</li>
										</ul>
										<div id="bordered-content" style={{ maxHeight: '60vh', overflowX: 'auto' }} className="tab-content approvalTabContent">
											<div id="bordered-1-content" className={getSkillApprovalTabContentClass(1)}>
												<DataGridComponent rows={skillPendingRequests} columns={skillPendingRequestHeaders} rowHeight={50}
													setSelectedExpression={(data) => setSelectedExpression(data)} />
											</div>
											<div id="bordered-2-content" className={getSkillApprovalTabContentClass(2)}>
												<DataGridComponent rows={skillApprovedRequests} columns={skillApprovedRequestHeaders} rowHeight={50}
													setSelectedExpression={(data) => setSelectedExpression(data)} />
											</div>
											<div id="bordered-3-content" className={getSkillApprovalTabContentClass(3)}>
												<DataGridComponent rows={skillRejectedRequests} columns={skillRejectedRequestHeaders} rowHeight={50}
													setSelectedExpression={(data) => setSelectedExpression(data)} />
											</div>
										</div>
									</div>

									<div className={tab_selected == "Bots" ? "active tab-pane" : "tab-pane"}>
										<ul id="bordered" className="tabs tabs--bordered">
											<li id="bordered-1" onClick={() => handleBotRequestsTabClick(1)} className={getBotApprovalClass(1)}>
												<a tabindex="0">Pending Requests</a>
											</li>
											<li id="bordered-2" onClick={() => handleBotRequestsTabClick(2)} className={getBotApprovalClass(2)}>
												<a tabindex="0">Approved Requests</a>
											</li>
											<li id="bordered-2" onClick={() => handleBotRequestsTabClick(3)} className={getBotApprovalClass(3)}>
												<a tabindex="0">Rejected Requests</a>
											</li>
										</ul>
										<div id="bordered-content" style={{ maxHeight: '60vh', overflowX: 'auto' }} className="tab-content approvalTabContent">
											<div id="bordered-1-content" className={getBotApprovalTabContentClass(1)}>
												<DataGridComponent rows={botPendingRequests} columns={botPendingRequestHeaders} rowHeight={50}
													setSelectedExpression={(data) => setSelectedExpression(data)}
													getCellClassName={(params) => {
														if (params && params.row && params.row.useGenAI === true) {
															return 'enableGAI'
														}
														return ''
													}}
												/>
											</div>
											<div id="bordered-2-content" className={getBotApprovalTabContentClass(2)}>
												<DataGridComponent rows={botApprovedRequests} columns={botApprovedRequestHeaders} rowHeight={50}
													setSelectedExpression={(data) => setSelectedExpression(data)} />
											</div>
											<div id="bordered-3-content" className={getBotApprovalTabContentClass(3)}>
												<DataGridComponent rows={botRejectedRequests} columns={botRejectedRequestHeaders} rowHeight={50}
													setSelectedExpression={(data) => setSelectedExpression(data)} />
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);

};

export default ApprovalView;