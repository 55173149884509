import { useState, useRef, useEffect } from "react";
import AnalyticsFilterComponent from "../../analytics-component/analytics-filter-component/AnalyticsFilterComponent";
import { BarChartHeader } from "../../style";
import BotService from "../../../bot-module/bot-services/BotService";
import { CHART_KEYS } from "../../constants";
import ActionSelect from "../../../../custom-components/action-select/ActionSelect";

import exportFromJSON from "export-from-json";

const AnalyticsAdminBarChart = ({
  chart,
  unitHeight,
  unitWidth,
  chartHeight,
  chartWidth,
  layouts,
  index,
}) => {
  const [action, setAction] = useState({ value: "reset", label: "--Reset--" });
  const downloadDataRef = useRef();
  const [fileData, setFileData] = useState(null);
  const [topBots, setTopBots] = useState(null);

  const onActionChange = (data) => {
    setAction(data);
  };

  useEffect(() => {
    BotService.topBots()
      .then((res) => res.json())
      .then((res) => {
        if (res["bots"] !== undefined) {
          let top_bots = [];
          for (let bot of res["bots"]) {
            top_bots.push({ label: bot["bot"], value: bot["bot"] });
          }
          setTopBots(top_bots);
        }
      })
      .catch((err) => {
        console.log("Failed to fetch top bots. Error:", JSON.stringify(err));
        setTopBots([]);
      });
  }, []);

  const getDownloadData = (e) => {
    e.preventDefault();
    const downloadData = downloadDataRef?.current?.getDownloadData();
    console.log("my data.....", downloadData);
    setFileData(downloadData);
    ExportToExcel();
  };

  const ExportToExcel = () => {
    const data = fileData;
    const fileName = "download";
    const exportType = exportFromJSON.types.xls;
    exportFromJSON({ data, fileName, exportType });
  };

  return (
    <div>
      <div className="base-margin">
        <BarChartHeader>{chart?.header}</BarChartHeader>
        <span>
          {" "}
          &nbsp;
          <span
            tabindex="0"
            data-balloon-break
            data-balloon={chart?.chartInfo}
            data-balloon-pos="down"
            data-balloon-length="large"
          >
            <span
              className="icon-info-outline"
              style={{ "font-size": "1.8rem", top: "-3px" }}
            ></span>
          </span>
          {chart.downloadInfo !== null && (
            <span onClick={getDownloadData}>
              <span
                tabindex="1"
                data-balloon-break
                data-balloon={chart.downloadInfo}
                data-balloon-pos="down"
                data-balloon-length="large"
              >
                &nbsp;{" "}
                <span
                  className="icon-download"
                  style={{ "font-size": "1.8rem", top: "-3px" }}
                ></span>
              </span>
            </span>
          )}
        </span>
        {chart.key === CHART_KEYS.PLATFORM.MOST_USED_KEYWORDS && (
          <span
            style={{ display: "inline-block", padding: "10px" }}
            key={chart.key}
          >
            <ActionSelect
              key={chart.key}
              defaultValue={action}
              options={topBots}
              onValueChange={(data) => {
                onActionChange(data);
              }}
              noDataMessage="No Bots available"
            />
          </span>
        )}
      </div>
      <hr />
      <AnalyticsFilterComponent
        chartId={chart?.id}
        height={
          (Object.keys(layouts).length === 0
            ? chartHeight
            : layouts["lg"][index + 1]["h"]) * unitHeight
        }
        width={
          ((Object.keys(layouts).length === 0
            ? chartWidth
            : layouts["lg"][index + 1]["w"]) -
            1) *
          unitWidth
        }
        insight="platform"
        ref={downloadDataRef}
        action={action.value}
      />
    </div>
  );
};

export default AnalyticsAdminBarChart;
