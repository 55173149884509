import './SkillViewComponent.css';
import isAlpha from 'validator/lib/isAlpha';
import isAlphanumeric from 'validator/lib/isAlphanumeric';

import React, { useState, useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import MultiSelect from '../../../../custom-components/multi-select/MultiSelect';
import SearchSelect from "../../../../custom-components/search-select/SearchSelect";
import Spinner from "../../../../custom-components/spinner/Spinner";
import MessageBanner from "../../../../custom-components/MessageBanner/MessageBanner";
import Skill from '../skill-component/SkillComponent';
import SkillVersion from '../skill-version-component/SkillVersionComponent';
import SkillService from "../../skill-services/SkillService";
import SkillVersionService from "../../skill-services/SkillVersionService";
import FileDownloader from '../../../../utils/FileDownloader';
import EmailChip from '../../../../custom-components/email-chip/EmailChip';
import { useEffect } from "react";

import Joyride, { CallBackProps, STATUS, Step } from 'react-joyride';

export const SkillView = () => {

	const [newSkillForm, setNewSkillForm] = useState({
		"skillName": "",
		"skillDescription": ""
	})

	const [versionName, setVersionName] = useState("");
	const [versionDescription, setVersionDescription] = useState("");
	const [skillLabels, setSkillLabels] = useState([]);

	const [skillList, setSkillList] = useState([]);
	const [skillVersionList, setSkillVersionList] = useState([]);
	const [selectedSkillVersionList, setSelectedSkillVersionList] = useState([]);
	const [selectedSkillIndex, setSelectedSkillIndex] = useState(-1);
	const [activeSkillTab, setActiveSkillTab] = useState(1);
	const [isDataLoading, setDataLoading] = useState(false);
	const [showSpinner, setShowSpinner] = useState(false);
	const [showMessageBanner, setShowMessageBanner] = useState(false);
	const [checkDuplidate, setCheckDuplidate] = useState(false);
	const [isDuplidateSkillName, setIsDuplidateSkillName] = useState(false);

	const [bannerMessage, setBannerMessage] = useState('');
	const [bannerMessageType, setBannerMessageType] = useState('success');

	const [selectedSkillId, setSelectedSkillId] = useState('');
	const [selectedSkill, setSelectedSkill] = useState('');

	const [showEditSkill, setShowEditSkill] = useState(false);
	const [showDeleteSkill, setShowDeleteSkill] = useState(false);
	const [showSkillUsers, setShowSkillUsers] = useState(false);
	const [showCreateSkill, setShowCreateSkill] = useState(false);
	const [enableCreateSkillButton, setEnableCreateSkillButton] = useState(false);
	const [enableCreateSkillVersionButton, setEnableCreateSkillVersionButton] = useState(false);
	const [enableSkillPublishButton, setEnableSkillPublishButton] = useState(false);
	const [enableUpdateSkillButton, setEnableUpdateSkillButton] = useState(false);

	const [skillIdForDeletion, setSkillIdForDeletion] = useState('');
	const [skillNameForDeletion, setSkillNameForDeletion] = useState('');

	const [selectedSkillVersionId, setSelectedSkillVersionId] = useState(undefined);
	const [selectedSkillVersionName, setSelectedSkillVersionName] = useState("");
	const [selectedSkillVersionDescription, setSelectedSkillVersionDescription] = useState("");

	const [showCreateSkillVersion, setShowCreateSkillVersion] = useState(false);
	const [showDeleteSkillVersion, setShowDeleteSkillVersion] = useState(false);
	const [showPublishSkillVersion, setShowPublishSkillVersion] = useState(false);
	const [showRevertSkillVersion, setShowRevertSkillVersion] = useState(false);

	const [cloneName, setCloneName] = useState("");
	const [cloneDescription, setCloneDescription] = useState("");
	const [showCloneSkill, setShowCloneSkill] = useState(false);

	const [dataValidationErrorMessage, setDataValidationErrorMessage] = useState('');
	const [showValidationErrorMessage, setShowValidationErrorMessage] = useState(false);

	const [skillVersionClone, setSkillVersionClone] = useState(true);
	const [skillCloneType, setSkillCloneType] = useState("clone");
	const [skillCloneApprovalRequired, setSkillCloneApprovalRequired] = useState(false);
	const [skillPublishVersionType, setSkillPublishVersionType] = useState("new_version");
	const [skillForm, setSkillForm] = useState()
	const [showGuidance, setShowGuidance] = useState(false);

	const [layoutType, setLayoutType] = useState(window.innerWidth > 899.9 ? 'horizontal' : 'verticle');

	const createSkillInputRef = useRef(null);

	const handleResize = (() => {
		setLayoutType(window.innerWidth > 899.9 ? 'horizontal' : 'verticle');
	})

	const getSkillTabClass = (id) => {
		if (id === activeSkillTab) {
			return "tab active";
		} else {
			return "tab";
		}
	};

	const getSkillTabContentClass = (id) => {
		if (id === activeSkillTab) {
			return "tab-pane active";
		} else {
			return "tab-pane";
		}
	};

	const handleSkillPublishVersionTypeChange = (versionType) => {
		setSkillPublishVersionType(versionType);
	};

	const handleSkillCloneTypeChange = (cloneType) => {
		setSkillCloneType(cloneType);
		setSkillVersionClone(cloneType == "clone" ? true : false);
	};

	const handleSkillCopyApprovalFlagChange = (approvalRequired) => {
		setSkillCloneApprovalRequired(approvalRequired);
	};

	function truncateStrings(str, length) {
		if (length > str.length) {
			str = str + " ".repeat(length - str.length)
			return str
		}

		str = str.substring(0, length)
		return str;
	}

	useEffect(() => {
		window.addEventListener('resize', handleResize);
		fetchSkills();
	}, [])
		
	useEffect(() => {
		updateSkillVersionAttributes();
	}, [skillVersionList])

	useEffect(() => {
		setShowGuidance(false);
		if (isDataLoading === false && skillList && skillList.length === 0) {
			setShowGuidance(true);
		}
	}, [skillList, isDataLoading])
	
	useEffect(() => {
		if(showCreateSkillVersion) {
			activateCreateSkillVersion();
		}
		activateSkillPublish();
	}, [versionName, versionDescription])

	const fetchSkills = () => {
		setDataLoading(true);
		SkillService.listSkills().then(res => res.json()).then(res => {
			if (res['status'] === 'success') {
				let responseSkillList = res["skills"];
				if (responseSkillList.length > 0) {
					fetchSkillVersions();

					setSkillList((prev) => {
						for (let i = 0; i < responseSkillList.length; i++) {
							// add missing fields to skill list
							if (responseSkillList[i]["description"] === undefined) {
								responseSkillList[i]["description"] = ""
							}
						}
						return responseSkillList;
					});
					setSelectedSkillId(res["skills"][0]._id)
					//showSkillVersionsForSelectedSkill(res["skills"][0], 0)

					let options = [];
					for (let SkillObj of responseSkillList) {
						options.push({ label: SkillObj.name, value: SkillObj._id })
					}
				}
				setDataLoading(false)
			}
			else {
				setDataLoading(false);
				setBannerMessageType('error');
				setBannerMessage("Failed to retrieve skills!");
				setShowMessageBanner(true);
			}
		}).catch(err => {
			setDataLoading(false);
			setBannerMessageType('error');
			setBannerMessage("Failed to retrieve skills!");
			setShowMessageBanner(true);
		})
	}

	const fetchSkillVersions = () => {
		setDataLoading(true);
		SkillVersionService.listSkillVersions().then(res => res.json()).then(res => {
			if (res["skill_versions"] != undefined) {
				let responseSkillVersionList = res["skill_versions"];
				setSkillVersionList((prev) => {
					setDataLoading(false);
					return responseSkillVersionList;
				});
			}
			else {
				setDataLoading(false);
				setBannerMessageType('error');
				setBannerMessage("Failed to retrieve skill versions!");
				setShowMessageBanner(true);
			}
		}).catch(err => {
			setDataLoading(false);
			setBannerMessageType('error');
			setBannerMessage("Failed to retrieve skill versions!");
			setShowMessageBanner(true);
		})
	};

	const updateSkillVersionAttributes = () => {
		if (skillVersionList.length > 0) {
			let newSkillList = []
			for (let skillObj of skillList) {
				let versionCount = 0;
				let clonedCount = 0;
				let skillVersionObjList = []
				for (let skillVersionObj of skillVersionList) {
					if (skillObj._id == skillVersionObj.skill_id) {
						versionCount++;
						if (skillVersionObj.cloned_users && skillVersionObj.cloned_users.length > 0) {
							clonedCount = clonedCount + skillVersionObj.cloned_users.length;
						}
						skillVersionObjList.push(skillVersionObj);
					}
				}

				if (skillVersionObjList.length > 0) {
					skillObj.versionsList = skillVersionObjList;
				}
				if (clonedCount > 0) {
					skillObj.clonedCount = clonedCount;
				}
				skillObj.versionCount = versionCount;
				newSkillList.push(skillObj);
			}
			setSkillList(newSkillList);
		}
	}

	const showSkillVersionsForSelectedSkill = (skillObj, skillIndex) => {
		setSelectedSkillVersionList([]);
		setSelectedSkillIndex(-1);
		setSelectedSkillIndex(skillIndex);
		if (skillObj.versionsList && skillObj.versionsList.length > 0) {
			setSelectedSkillIndex(skillIndex);
			setSelectedSkillVersionList(skillObj.versionsList);
		}
	}

	const validateAlphaNumericString = (data) => {
		let sanitisedData = data.replaceAll(/[@.,'"&\s]/g, "");
		return isAlphanumeric(sanitisedData);
	}

	const handleSkillNameOnBlur = (event) => {
		let skillName = event.target.value;
		if (!checkDuplidate && skillName.trim().length > 3) {
			checkForDuplicateSkill(skillName);
		}
	}

	const validateSkillCreationForm = (key, skillForm) => {
		if (key == "skillName" || key == "all") {
			if (skillForm.skillName === undefined || skillForm.skillName.length < 3) {
				setShowValidationErrorMessage(true);
				setDataValidationErrorMessage('Skill name should be of minimum 3 characters.');
				return false;

			}

			if (!isAlphanumeric(skillForm.skillName, 'en-US', { "ignore": "_" })) {
				setShowValidationErrorMessage(true);
				setDataValidationErrorMessage('Special characters other than _ is not allowed in Skill name.');
				return false;
			}
		}
		if (key == "skillDescription" || key == "all") {
			if (skillForm.skillDescription === undefined || skillForm.skillDescription.length < 20) {
				setShowValidationErrorMessage(true);
				setDataValidationErrorMessage('Skill description should be minimum 20 characters.');
				return false;

			}
			if (!validateAlphaNumericString(skillForm.skillDescription)) {
				setShowValidationErrorMessage(true);
				setDataValidationErrorMessage('Special characters other than _ @ . " \' & is not allowed in Skill description.');
				return false;
			}
		}
		return true;
	}

	const inputChangeHandler = (e, type) => {
		setShowValidationErrorMessage(false);
		var validataTxt = "";
		let skillForm = newSkillForm;
		if (type === "skillName") {
			setCheckDuplidate(false)
			setIsDuplidateSkillName(false)
			var charATT = e.target.value.charAt(e.target.value.length - 1)
			if (e.target.value.length === 1 && (!isNaN(e.target.value) || e.target.value === "_")) {
				validataTxt = e.target.value.replace(e.target.value, "")
			} else {
				if (charATT === "_") {
					validataTxt = e.target.value.toLowerCase()
					//skillForm = { ...newSkillForm, "skillName": e.target.value.toLowerCase() }
				} else {
					validataTxt = e.target.value.replace(/[^\w\s]/gi, "")
					//skillForm = { ...newSkillForm, "skillName": validataTxt }
				}
			}
			validataTxt = validataTxt.replace(/ /g,"")
			skillForm = { ...newSkillForm, "skillName": validataTxt }
		}
		else if (type === "skillDescription") {
			skillForm = { ...newSkillForm, "skillDescription": e.target.value }
		}

		setNewSkillForm(skillForm)
		let empty = hasEmpty(skillForm)
		
		let valid = false;
		if (!empty) {
			valid = validateSkillCreationForm(type, skillForm)
		}
		else {
			valid = validateSkillCreationForm("all", skillForm)
		}
		
		const result = empty && valid && !isDuplidateSkillName;
		setEnableCreateSkillButton(result);
	}

	const inputChangeHandlerUpdate = (e) => {
		setShowValidationErrorMessage(false);
		
		let skillFormTemp = skillForm
		skillFormTemp = { ...skillFormTemp, "skillDescription": e.target.value }
		
		setSkillForm(skillFormTemp)
		let empty = hasEmpty(skillFormTemp)
		let valid = validateSkillCreationForm('skillDescription', skillFormTemp)
		const result = empty && valid
		setEnableUpdateSkillButton(result);
	}
	
	const inputChangeHandlerSkillVersion = (e, type) => {
		setEnableCreateSkillVersionButton(false);
		var validataTxt = "";
		
		if (type === "versionName") {
			let userText = e.target.value
			userText = userText.replace(/ /g,"")
			var charATT = e.target.value.charAt(e.target.value.length - 1)
			if (e.target.value.length === 1 && (!isNaN(e.target.value) || e.target.value === "_")) {
				validataTxt = e.target.value.replace(e.target.value, "")
			} else {
				if (charATT === "_") {
					validataTxt = e.target.value
				} else {
					validataTxt = e.target.value.replace(/[^\w\s]/gi, "")
				}
			}
			validataTxt = validataTxt.replace(/ /g,"")
			setVersionName(validataTxt)
		}
		else if (type === "versionDescription") {
			setVersionDescription(e.target.value)
		}
	}
	
	const inputChangeHandlerSkillPublish = (e, type) => {
		setEnableSkillPublishButton(false);
		var validataTxt = "";
		
		if (type === "versionName") {
			let userText = e.target.value
			userText = userText.replace(/ /g,"")
			var charATT = e.target.value.charAt(e.target.value.length - 1)
			if (e.target.value.length === 1 && (!isNaN(e.target.value) || e.target.value === "_")) {
				validataTxt = e.target.value.replace(e.target.value, "")
			} else {
				if (charATT === "_") {
					validataTxt = e.target.value
				} else {
					validataTxt = e.target.value.replace(/[^\w\s]/gi, "")
				}
			}
			validataTxt = validataTxt.replace(/ /g,"")
			setVersionName(validataTxt)
		}
		else if (type === "versionDescription") {
			setVersionDescription(e.target.value)
		}
	}

	const hasEmpty = (element) => {
		return (
			element.skillName.length >= 3 && (element.skillDescription && element.skillDescription.length >= 20)
		);
	};

	const clearForm = () => {
		setNewSkillForm({
			"skillName": "",
			"skillDescription": ""
		});
		setEnableCreateSkillButton(false);
	}

	const dummyValidateLabel = (newEmail) => {
		return true;
	}

	const refreshSkillLabels = (labels) => {
		let currLabelList = skillLabels;
		let difference = labels.filter(x => !currLabelList.includes(x)) + currLabelList.filter(x => !labels.includes(x));
		setSkillLabels(labels);
	}

	const checkForDuplicateSkill = (skillName) => {
		setShowValidationErrorMessage(false);
		let skillDesc = newSkillForm.skillDescription;
		
		setDataLoading(true);
		setIsDuplidateSkillName(false);
		SkillService.getSkillByName(skillName).then(res => res.json()).then(res => {
			setCheckDuplidate(true);
			
			setDataLoading(false);
			if (res["status"] == "success" && res["skill"]) {
				let response = res["skill"];
				if (response.name) {
					setIsDuplidateSkillName(true);
					//setShowValidationErrorMessage(true);
					//setDataValidationErrorMessage('A Skill with the same name already exists!');
					setShowMessageBanner(true);
					setBannerMessageType('error');
					setBannerMessage("A Skill with the same name already exists!");
					createSkillInputRef.current.focus();
					createSkillInputRef.current.select();
				}
			} else {

			}
		}).catch(err => {
			setDataLoading(false);
			
			setDataLoading(false);
			setBannerMessageType('error');
			setBannerMessage("Operation failed! Please try again.");
			setShowMessageBanner(true);
		})
	}

	const createSkill = () => {
		setShowValidationErrorMessage(false);
		setDataValidationErrorMessage('');
		// data validation
		if (!validateSkillCreationForm("all", newSkillForm)) {
			return;
		}

		setShowCreateSkill(false);
		setDataLoading(true);
		SkillService.createSkill({
			"name": newSkillForm.skillName,
			"description": newSkillForm.skillDescription,
			"shared": false
		}).then(res => res.json()).then(res => {
			clearForm();
			setDataLoading(false);
			setShowMessageBanner(true);
			if (res["status"] == "success" && "result" in res) {
				let skillResponse = res["result"];
				setSkillList([...skillList, skillResponse]);
				setBannerMessageType('success');
				setBannerMessage("A new Skill has been created successfully!");
			} else {
				if (res["details"] == "DUPLICATE") {
					setBannerMessageType('error');
					setBannerMessage("A Skill with the same name already exists!");
				} else {
					setBannerMessageType('error');
					setBannerMessage(res["details"]);
				}
			}
		}).catch(err => {
			setDataLoading(false);
			setBannerMessageType('error');
			setBannerMessage("Failed to create skill! Please try again.");
			setShowMessageBanner(true);
		})
	}

	const updateSkill = () => {
		setDataLoading(true);
		setShowEditSkill(false);
		setShowMessageBanner(false);
		let payload = {
			"_id": selectedSkillId,
			"name": skillForm.skillName,
			"description": skillForm.skillDescription,
			"shared": getSkillById(selectedSkillId)['shared']
		}
		SkillService.updateSkill(payload).then(res => res.json()).then(res => {
			if (res['status'] == 'success') {
				setShowEditSkill(false);
				setDataLoading(false);
				setBannerMessageType('success');
				setBannerMessage("Updated data successfully!");
				setShowMessageBanner(true);
				onSkillUpdateSuccess(payload);
			}
			else {
				setShowEditSkill(false);
				setDataLoading(false);
				setBannerMessageType('error');
				setBannerMessage(res['details']);
				setShowMessageBanner(true);
			}
		}).catch(err => {
			
			setShowEditSkill(false);
			setDataLoading(false);
			setBannerMessageType('error');
			setBannerMessage("Failed to update data! Please try again.");
			setShowMessageBanner(true);
		})
	}

	const onSkillUpdateSuccess = (skillObj) => {
		fetchSkills();
	}
	
	const activateCreateSkillVersion = () => {
		let validInputs = (versionName && versionName.length > 2) && (versionDescription && versionDescription.length > 19);
		setEnableCreateSkillVersionButton(validInputs);
	}
	
	const activateSkillPublish = () => {
		let validInputs = (versionName && versionName.length > 2) && (versionDescription && versionDescription.length > 19);
		setEnableSkillPublishButton(validInputs);
	}
	
	const createSkillVersion = (publishFlag) => {
		setShowValidationErrorMessage(false);
		setDataValidationErrorMessage('');
		// data validation

		setShowPublishSkillVersion(false);
		setDataLoading(true);
		let skillVersionObj = {
			"skill_id": selectedSkillId,
			"name": versionName,
			"description": versionDescription,
			"published": publishFlag,
			"replica_type": skillCloneType
		}

		SkillVersionService.createSkillVersion(selectedSkillId, skillVersionObj).then(res => res.json()).then(res => {
			setSelectedSkillVersionList([]);
			setSelectedSkillIndex(-1);
			setDataLoading(false);
			setShowMessageBanner(true);
			if (res["status"] == "failed") {
				setBannerMessageType('error');
				if(res["message"] === "NO_INTENTS") {
					setBannerMessage("To create a Version, the Skill should have at least one Intent along with Expressions!");
				}else {
					setBannerMessage(res["message"]);
				}
			} else {
				let skillResponse = res["result"];
				skillVersionObj["_id"] = skillResponse["_id"]
				setSkillVersionList([...skillVersionList, skillVersionObj]);
				setBannerMessageType('success');
				setBannerMessage("A new Skill Version has been successfully created!");
			}
		}).catch(err => {
			setDataLoading(false);
			setBannerMessageType('error');
			setBannerMessage("Failed to create skill version! Please try again.");
			setShowMessageBanner(true);
		})
	}

	const publishSkillVersion = () => {
		setShowValidationErrorMessage(false);
		setDataValidationErrorMessage('');
		// data validation
		
		setShowPublishSkillVersion(false);
		setDataLoading(true);
		let skillVersionObj = {
			"id": selectedSkillVersionId,
			"tags": skillLabels,
			"published": true,
			"replica_type": skillCloneType,
			"approval_required": skillCloneApprovalRequired
		}

		SkillVersionService.updateSkillVersion(selectedSkillId, skillVersionObj).then(res => res.json()).then(res => {
			setSelectedSkillVersionList([]);
			setSelectedSkillIndex(-1);
			setDataLoading(false);
			setShowMessageBanner(true);
			if (res["status"] == "success") {
				setBannerMessageType('success');
				setBannerMessage("Skill Version has been published successfully to the Skill Library!");
				fetchSkills();
			} else {
				setBannerMessageType('error');
				setBannerMessage(res["message"]);
			}
		}).catch(err => {
			setDataLoading(false);
			setBannerMessageType('error');
			setBannerMessage("Failed to create skill version! Please try again.");
			setShowMessageBanner(true);
		})
	}

	const resizer = document.getElementById('skill_view_panel_my_skills')

	const closeEditSkillPanel = () => {
		setShowEditSkill(false);
	}

	const handleSkillOnClick = (skill) => {
		if (!skill.shared) {
			//changeSkillListOrder(skill._id);
			showEditSkillPanel(skill._id);
		}
		setSelectedSkillId(skill._id)
		setSkillForm({ 'skillName': skill['name'], 'skillDescription': skill['description'] })
	}

	const showEditSkillPanel = (id) => {
		setSelectedSkillId(id);
		setShowEditSkill(true);
	}

	const enableSkillDelete = (skill) => {
		setShowDeleteSkill(true);
		setSkillIdForDeletion(skill._id);
		setSkillNameForDeletion(skill.name);
	}

	const enableSkillUsersView = (skill) => {
		setSelectedSkill(skill);
		setShowSkillUsers(true);
	}

	const downloadSkillUsers = () => {
		let csv_data_list = ['Parent Skill, Skill Version, Consumer User Id']
		let fileName = selectedSkill.name + '_users.csv';
		selectedSkill && selectedSkill.versionsList.map((skillVersion, index) => {
			if (skillVersion.cloned_users) {
				skillVersion.cloned_users.map((user, i) => {
					csv_data_list.push([selectedSkill.name, skillVersion.name, user]);
				})
			}
		})
		FileDownloader(csv_data_list, fileName);
	}

	const enableAddSkillVersion = (skill) => {
		setSelectedSkillId(skill._id);
		setShowCreateSkillVersion(true);
		setVersionName("");
		setVersionDescription("");
	}

	const enableDeleteSkillVersion = (skillVersion) => {
		setSelectedSkillId(skillVersion.skill_id)
		setSelectedSkillVersionId(skillVersion._id);
		setVersionName(skillVersion.name);
		setVersionDescription(skillVersion.description);
		setSelectedSkillVersionName(skillVersion.name);
		setShowDeleteSkillVersion(true);
	}

	const enableRevertSkillVersion = (skillVersion) => {
		setSelectedSkillVersionId(skillVersion._id);
		setSelectedSkillId(skillVersion.skill_id)
		setVersionName(skillVersion.name);
		setShowRevertSkillVersion(true);
	}

	const enablePublishSkillVersion = (skillVersion) => {
		if (skillVersion.published) {
			return;
		}
		setSelectedSkillVersionId(skillVersion._id);
		setShowPublishSkillVersion(true);
		setVersionName(skillVersion.name);
		setVersionDescription(skillVersion.description);
		setSelectedSkillVersionName(skillVersion.name);
		setSelectedSkillVersionDescription(skillVersion.description);
	}

	const getSkillById = (id) => {
		for (let skill of skillList) {
			if (skill["_id"] === id) {
				return skill;
			}
		}
	}

	function skillSubmission(e) {
		e.preventDefault()
	}

	function skillVersionSubmission(e) {
		e.preventDefault()
	}

	function changeSkillListOrder(id) {
		setSkillList((prev) => {
			let index = skillList.map(object => object._id).indexOf(id);
			let newSkillList = [skillList[index]];
			for (let i = 0; i < skillList.length; i++) {
				if (i != index) {
					newSkillList.push(skillList[i]);
				}

			}
			return newSkillList;
		})
	}

	function deleteSkill() {
		let update = { 'loading': true };
		setDataLoading(true);
		setShowValidationErrorMessage(false);
		setDataValidationErrorMessage('');
		SkillService.deleteSkill(skillIdForDeletion).then((res) => res.json()).then((res) => {
			setDataLoading(false);
			fetchSkills();
			if (res['status'] == 'success') {
				setBannerMessageType('success');
				setBannerMessage("Skill has been successfully deleted!");
			}
			else {
				if (res['details'] == 'SKILL_IN_USE') {
					setBannerMessageType('error');
					setBannerMessage("Skil has been marked for deletion!");
				}
			}
		}, (err) => {
			update = { 'loading': false, 'type': 'error', 'message': 'Failed to delete the skill. Please try later.', 'banner': true };
		})
	}

	function deleteSkillVersion() {

		setShowValidationErrorMessage(false);
		setDataValidationErrorMessage('');

		setDataLoading(true);

		SkillVersionService.deleteSkillVersion(selectedSkillId, selectedSkillVersionId).then(res => res.json()).then(res => {
			setSelectedSkillVersionList([]);
			setSelectedSkillIndex(-1);
			setDataLoading(false);
			setShowMessageBanner(true);
			if (res["status"] == "failure") {
				setBannerMessageType('error');
				setBannerMessage("Skill version has been marked for deletion");
			} else {
				setBannerMessageType('success');
				setBannerMessage("Skill Version has been successfully deleted!");
				fetchSkills();
			}
		}).catch(err => {
			setDataLoading(false);
			setBannerMessageType('error');
			setBannerMessage("Failed to create skill version! Please try again.");
			setShowMessageBanner(true);
		})
	}

	function revertSkillVersion() {

		setShowValidationErrorMessage(false);
		setDataValidationErrorMessage('');

		setDataLoading(true);

		let obj = { "skill_version_id": selectedSkillVersionId, "skill_id": selectedSkillId }
		SkillVersionService.revertSkillVersion(selectedSkillId, obj).then(res => res.json()).then(res => {
			setDataLoading(false);
			setShowMessageBanner(true);
			if (res["status"] == "failure") {
				setBannerMessageType('error');
				setBannerMessage("Some technical issues. Please try later!");
			} else {
				setBannerMessageType('success');
				setBannerMessage(res["message"]);
			}
		}).catch(err => {
			setDataLoading(false);
			setBannerMessageType('error');
			setBannerMessage("Failed to create skill version! Please try again.");
			setShowMessageBanner(true);
		})
	}

	const deletionInstruction = () => {
		/*switch(skill['shared']) {
			case false:
				return (<h6>This skill has not been published. No users will be notified if deleted.</h6>)
			case true:
				return (<h6>This skill has been published. All users with a read-only or cloned skill version will be notified of the deletion and deprecation</h6>) 
		}*/
	}

	const [{ run, steps }, setState] = useState({
		run: true,
		steps: [
			{
				content: <h6>Welcome to <b>Skill View</b>. Here, you view/create skills.  <br></br><br></br>Skills are a way to group your bot's Intents, Entities, and Dialog that work together to perform a set of actions for the user.</h6>,
				locale: { skip: <span aria-label="skip">Skip</span> },
				placement: 'center',
				target: '.pageTour1',
			},
			{
				content: <h6>By clicking this button, a popup will appear. There you can enter all the mandatory details and hit submit to create skill.</h6>,
				floaterProps: {
					disableAnimation: true,
				},
				spotlightPadding: 20,
				target: '.pageTour2',
			},
			{
				content: <h6>On click on the My Skills tab you can manage skills.</h6>,
				floaterProps: {
					disableAnimation: true,
				},
				spotlightPadding: 10,
				target: '.pageTour3',
			},
			{
				content: <h6>On click on the Shared Skills tab you can share skills.</h6>,
				floaterProps: {
					disableAnimation: true,
				},
				spotlightPadding: 10,
				target: '.pageTour4',
			}, {
				content: <h6>On click on the Skill Versions tab you can see skills versions details.</h6>,
				floaterProps: {
					disableAnimation: true,
				},
				spotlightPadding: 10,
				target: '.pageTour5',
			},
		]
	});
	const handleJoyrideCallback = (data) => {
		const { status, type } = data;
		const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

		if (finishedStatuses.includes(status)) {
			setState({ run: false });
		}
	};
	return (
		<>

			{isDataLoading != true && showGuidance ? <Joyride
				callback={handleJoyrideCallback}
				continuous
				hideCloseButton
				run={run}
				scrollToFirstStep
				showProgress
				showSkipButton
				steps={steps}
				styles={{
					options: {
						// zIndex: 1000,
					},
					buttonNext: {
						background: '#1e4471',
						border: '1px solid #1e4471',
						borderRadius: '5px'
					},
					buttonBack: {
						color: '#1e4471',
						border: '1px solid #1e4471',
						borderRadius: '5px'
					},
					buttonSkip: {
						color: '#1e4471',
						border: '1px solid #1e4471',
						borderRadius: '5px'
					},
				}}
			/> : ""}
			{
				isDataLoading && (<Spinner></Spinner>)
			}
			<div className="row base-margin-top" style={{ marginLeft: '0.2vw', marginRight: '0.2vw' }}>
				<ul id="skill_Tabs" className="tabs tabs--embossed tabs--justified tabs--bordered col-9">
					{!showEditSkill &&
						<>
							<li id="my_skills_tab" onClick={() => setActiveSkillTab(1)} className={getSkillTabClass(1)}>
								<a tabIndex="0" className="pageTour3">My Skills</a>
							</li>
							<li id="shared_skills_tab" onClick={() => setActiveSkillTab(2)} className={getSkillTabClass(2)}>
								<a tabIndex="0" className="pageTour4">Shared Skills</a>
							</li>
							<li id="skill_versions_tab" onClick={() => setActiveSkillTab(3)} className={getSkillTabClass(3)}>
								<a tabIndex="0" className="pageTour5">Skill Versions</a>
							</li>
						</>
					}
				</ul>
				<div className="col-3" style={{ borderBottom: '1px solid #dee2e6' }}>
					<button className='base-margin-top btn btn--secondary text-center-xs-down text-center-sm-down text-center-md-down text-center-lg-down text-center-xl-down text-center-xxl-down text-center-xxxl-down pageTour2'
						style={{ top: "-0.7vh", float: 'right' }}
						disabled={showEditSkill}
						onClick={() => { setShowValidationErrorMessage(false); setDataValidationErrorMessage(''); setShowCreateSkill(true) }}>
						Create
					</button>
				</div>
			</div>

			{
				showMessageBanner && <MessageBanner message={bannerMessage} messageType={bannerMessageType} onCloseClicked={() => { setShowMessageBanner(false) }}></MessageBanner>
			}

			<div id="bordered-content" className="tab-content">
				<div id="my_skills_tab-content" className={getSkillTabContentClass(1)} style={{ 'overflowY': 'auto', maxHeight: '70vh' }}>
					<div className="base-margin flex flex-wrap flex-center" style={{ 'display': 'flex' }}>{
						skillList.length === 0 ? <div className="alert alert--info  flex flex-center flex-middle center panel" style={{ "position": "relative", "left": "20%", width: '70%', marginTop: '15%', "transform": "translate(-300px,5px)", "zIndex": "100px" }}>
							<h6 style={{ lineHeight: '1.6', textAlign: 'center' }}>You have not created a skill yet.
								<br />
								<br />
								<button className='btn btn--secondary pageTour2' onClick={() => { setShowValidationErrorMessage(false); setDataValidationErrorMessage(''); setShowCreateSkill(true) }}>
									Create
									{/* <span className="icon-add-contain icon-size-18 base-margin-left"></span> */}
								</button>
								<br />
								<br />
								Require assistance? Click on <b>Help</b> or chat with our bot, <b>AskBotLite</b>.
							</h6>
						</div> : skillList.map((skill, index) => {
							if (!skill['shared'] && !skill['deletionFlag']) {
								return <Skill
									index={index}
									skill={skill}
									onSkillClick={(skill) => { handleSkillOnClick(skill); setSkillForm({ 'skillName': skill['name'], 'skillDescription': skill['description'] }) }}
									onSkillVersionClick={(id) => { enableAddSkillVersion(skill) }}
									onSkillUsersClick={(skill) => { enableSkillUsersView(skill) }}
									onSkillDelete={(skill) => { enableSkillDelete(skill) }}
								/>
							}
						})

					}</div>
				</div>

				<div id="shared_skills_tab-content" className={getSkillTabContentClass(2)} style={{ 'overflowY': 'auto', maxHeight: '70vh' }}>
					<div className='base-margin flex flex-wrap flex-center' id="wrapper_div_shared-skills" style={{ 'display': 'flex' }}>
						{
							skillList && skillList.map((skill, index) => {
								if (skill['shared'])
									return <Skill
										key={index}
										index={index}
										skill={skill}
										onSkillClick={(skill) => { handleSkillOnClick(skill); setSkillForm({ 'skillName': skill['name'], 'skillDescription': skill['description'] }) }}
										onSkillDelete={(skill) => { enableSkillDelete(skill) }}
									/>
							})
						}
					</div>
				</div>
				<div id="skill_versions_tab-content" className={getSkillTabContentClass(3)}>
					<div className='row base-margin' id="wrapper_div_skill-versions">
						<div className='col-12 col-xs-12  col-sm-12  col-md-4 col-lg-4  col-xl-4 col-xxl-4 initial_bot_width' style={{ width: '99% !important', borderRight: '1px solid #ced4da', 'paddingTop': '0px', 'borderRadius': '0px', height: '70vh', overflow: 'auto' }}>
							<h5 className='pageTour31'>
								Skills
							</h5>
							<hr />
							{skillList.length != 0 && skillList.map((skill, index) => {
								if (!skill['shared'] && !skill['deletionFlag'])
									return (
										<div className="panel panel--bordered panel--raised" style={{ "borderColor": index == selectedSkillIndex ? "#0175a2" : "#dee2e6", "width": "99%", "borderWidth": "1px", "borderRadius": "5px", "marginBottom": "5px", "padding": "5px 5px 0px 10px" }}>

											<div style={{ height: "64px" }} onClick={() => { showSkillVersionsForSelectedSkill(skill, index); }}>
												<div style={{ height: "100%" }}>
													<div className="row" style={{ margin: '5px' }}>
														<div className="col-8 col-sm-8 col-xs-8 col-md-8 col-lg-8 col-xl-8 col-2xl-8">
															<div className="row" >
																<div className="col-3 col-sm-3 col-xs-3 col-md-3 col-lg-3 col-xl-3 col-2xl-3">
																	<img src={process.env.PUBLIC_URL + "/images/skills.png"} alt="botLogo" style={{ borderRadius: '50%', width: '3vw' }} />
																</div>
																<div className="col-9 col-sm-9 col-xs-9 col-md-9 col-lg-9 col-xl-9 col-2xl-9" >
																	<div className="skill-name">
																		<h6 ><a style={{textDecoration: 'none', cursor: 'default' }}><b>{truncateStrings(skill['name'], 25)}</b></a></h6>
																	</div>
																</div>
															</div>
														</div>
														<div className="col-4 col-sm-4 col-xs-4 col-md-4 col-lg-4 col-xl-4 col-2xl-4" style={{ paddingTop: '0px' }}>
															<div style={{ display: !skill['shared'] ? 'block' : 'none', width: 'fit-content', float: 'right' }}>
																{
																	(skill['versionCount'] && skill['versionCount'] == 4) ? <div className="flex-center-vertical skill-action" data-balloon="You cannot create more than 4 Versions per Skill" data-balloon-pos="left" data-balloon-length="medium">
																		<span style={{ width: '30px', opacity: '0.5' }} ><img src={process.env.PUBLIC_URL + "/images/version-control.png"} /></span>
																	</div> 
																	: 
																	<div className="flex-center-vertical skill-action" data-balloon="Create a Version to save the current state of this Skill" data-balloon-pos="left" data-balloon-length="medium">
																		<span style={{ width: '30px' }} onClick={() => enableAddSkillVersion(skill)} ><img src={process.env.PUBLIC_URL + "/images/version-control.png"} />
																		</span>
																	</div>
																}
															</div>
															<div className="" style={{ display: (skill['versionCount'] && skill['versionCount'] > 0) ? 'block' : 'none', 'top': '10px', float: 'right', marginRight: '0.3vw' }}>
																<div className="btn btn--secondary btn--small" >
																	{skill['versionCount']} {skill['versionCount'] > 1 ? "Versions" : "Version"}
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									)
							})
							}
						</div>

						<div className='col-12 col-xs-12  col-sm-12  col-md-8 col-lg-8  col-xl-8 col-xxl-8' style={{ width: '99% !important', border: '0px solid #ced4da', 'paddingTop': '0px', 'borderRadius': '10px', maxHeight: '65vh', overflowY: 'auto' }}>
							<h5 className='pageTour31'>
								Skill Versions
							</h5>
							<hr />
							<div className="base-margin flex flex-wrap">
								{	
									/*selectedSkillVersionList.length === 0 ? <div className="alert alert--dark  flex flex-center flex-middle center panel" style={{ "position": "relative", "left": "20%", width: '70%', marginTop: '5%', "zIndex": "100" }}>
										<h6 style={{ lineHeight: '1.6', textAlign: 'center' }}><i>No versions created for the selected Skill</i>
											<br />
											<br />
										</h6>
									</div> : */
									selectedSkillVersionList.map((skillVersion, index) => {
										return <SkillVersion
											index={index}
											skillVersion={skillVersion}
											onSkillVersionPublish={(id) => { enablePublishSkillVersion(skillVersion) }}
											onSkillVersionDelete={(skillVersion) => { enableDeleteSkillVersion(skillVersion) }}
											onSkillVersionRevert={(skillVersion) => { enableRevertSkillVersion(skillVersion) }}
											selectedID={selectedSkillId}
										/>
									})
								}
							</div>
						</div>
					</div>
				</div>
			</div>

			<Modal size="md" centered show={showCreateSkill} animation={false} >
				<Modal.Header >
					<h5>Create a Skill</h5>
					<div className='btn--small btn--icon' onClick={() => { setShowCreateSkill(false); clearForm() }}>
						<span className="icon-close icon-size-24"></span>
					</div>
				</Modal.Header>
				<Modal.Body>
				
					{
						showValidationErrorMessage && (<MessageBanner message={dataValidationErrorMessage} messageType={'error'} onCloseClicked={() => { setShowValidationErrorMessage(false) }}></MessageBanner>)
					}

					{
						showMessageBanner && <MessageBanner message={bannerMessage} messageType={bannerMessageType} onCloseClicked={() => { setShowMessageBanner(false) }}></MessageBanner>
					}

					<form onSubmit={skillSubmission}>
						<div className='base-padding'>
							{
								layoutType === "horizontal" ? (
									<>
										<div className='warningMessage txtAlignRight'>Please fill the (*) mandatory fields</div>
										<div className="row">
											<div className="col">
												<div className="form-group base-margin-bottom">
													<div className="form-group__text">
														<input id="input-type-number" type="text" autoFocus name="skill_name" value={newSkillForm.skillName}
															ref={createSkillInputRef}
															onChange={e => { inputChangeHandler(e, "skillName") }}
															onBlur={handleSkillNameOnBlur}
														/>
														<label htmlFor="input-type-number">Skill Name <span className='required'>*</span></label>
													</div>
												</div>
											</div>
										</div>
										<div className="row">
											<div className="col">
												<div className="form-group base-margin-bottom">
													<div className="form-group__text">
														<textarea id="textarea-state-default" rows={6} name="skill_description" value={newSkillForm.skillDescription} onChange={e => { inputChangeHandler(e, "skillDescription") }}></textarea>
														<label htmlFor="input-type-number">Skill Description <span className='required'>*</span></label>
													</div>
												</div>
											</div>
										</div>

									</>
								) : (
									<>
										<div className='warningMessage txtAlignRight'>Please fill the (*) mandatory fields</div>
										<div className="row">
											<div className="col form-group base-margin-bottom">
												<div className="form-group__text">
													<input id="input-type-number" type="text" autoFocus name="skill_name" value={newSkillForm.skillName}
														ref={createSkillInputRef}
														onChange={e => { inputChangeHandler(e, "skillName") }}
														onBlur={handleSkillNameOnBlur}
													/>
													<label htmlFor="input-type-number">Skill Name <span className='required'>*</span></label>
												</div>
											</div>
										</div>
										<div className="row">
											<div className="col form-group base-margin-bottom">
												<div className="form-group__text">
													<textarea id="textarea-state-default" rows={6} name="skill_description" 
														value={newSkillForm.skillDescription} 
														onChange={e => { inputChangeHandler(e, "skillDescription") }}>
													</textarea>
													<label htmlFor="input-type-number">Skill Description <span className='required'>*</span></label>
												</div>
											</div>
										</div>
									</>
								)
							}

						</div>
					</form>
				</Modal.Body>
				<Modal.Footer>
					<button className="btn btn--secondary" disabled={!enableCreateSkillButton} onClick={() => createSkill()}>
						Create
					</button>
				</Modal.Footer>
			</Modal >

			<Modal size="md" centered show={showEditSkill} animation={false} >
				<Modal.Header >
					<h5>Update a Skill</h5>
					<div className='btn--small btn--icon' onClick={() => { setShowEditSkill(false); clearForm() }}>
						<span className="icon-close icon-size-24"></span>
					</div>
				</Modal.Header>
				<Modal.Body>
					{
						showValidationErrorMessage && (<MessageBanner message={dataValidationErrorMessage} messageType={'error'} onCloseClicked={() => { setShowValidationErrorMessage(false) }}></MessageBanner>)

					}
					<form onSubmit={skillSubmission}>

						{skillForm && <div className='base-padding'>
							{
								layoutType === "horizontal" ? (
									<>
										<div className="row">
											<div className="col">
												<div className="form-group base-margin-bottom">
													<div className="form-group__text">
														<input id="input-type-number" type="text" autoFocus name="skill_name" value={skillForm['skillName']} disabled />
														<label htmlFor="input-type-number">Skill Name <span className='required'>*</span></label>
													</div>
												</div>
											</div>
										</div>
										<div className="row">
											<div className="col">
												<div className="form-group base-margin-bottom">
													<div className="form-group__text">
														<textarea id="textarea-state-default" rows={6} name="skill_description" value={skillForm['skillDescription']} onChange={e => { inputChangeHandlerUpdate(e) }}></textarea>
														<label htmlFor="input-type-number">Skill Description <span className='required'>*</span></label>
													</div>
												</div>
											</div>
										</div>

									</>
								) : (
									<>
										<div className="row">
											<div className="col form-group base-margin-bottom">
												<div className="form-group__text">
													<input id="input-type-number" type="text" autoFocus name="skill_name" value={skillForm['skillName']} disabled />
													<label htmlFor="input-type-number">Skill Name <span className='required'>*</span></label>
												</div>
											</div>
										</div>
										<div className="row">
											<div className="col form-group base-margin-bottom">
												<div className="form-group__text">
													<textarea id="textarea-state-default" rows={6} name="skill_description" value={skillForm['skillDescription']} onChange={e => { inputChangeHandlerUpdate(e) }}></textarea>
													<label htmlFor="input-type-number">Skill Description <span className='required'>*</span></label>
												</div>
											</div>
										</div>

									</>
								)
							}
						</div>}
					</form>
				</Modal.Body>
				<Modal.Footer>
					<button className="btn btn--secondary" disabled={!enableUpdateSkillButton} onClick={() => updateSkill()}>
						Update
					</button>
				</Modal.Footer>
			</Modal>

			<Modal size="md" centered show={showCreateSkillVersion} animation={false} >
				<Modal.Header >
					<h5>Create Version</h5>
					<div className=' btn--small btn--icon' onClick={() => { setShowCreateSkillVersion(false) }}>
						<span className="icon-close icon-size-24"></span>
					</div>
				</Modal.Header>
				<Modal.Body>
					{
						showValidationErrorMessage && (<MessageBanner message={dataValidationErrorMessage} messageType={'error'} onCloseClicked={() => { setShowValidationErrorMessage(false) }}></MessageBanner>)

					}
					<div class="alert alert--warning">
						<div class="alert__icon icon-warning-outline"></div>
						<div class="alert__message">Intents with no Expressions will be ignored while generating the Snapshot.</div>
					</div>

					<div className='base-padding'>
						{
							<>
								<div className="row" style={{ marginBottom: '5px' }}>
									<div className="col">
										<div className="form-group base-margin-bottom">
											<div className="form-group__text">
												<input id="input-type-number" type="text" placeholder="Skill version name" autoFocus 
													name="skill_version_name" 
													value={versionName} 
													onChange={e => { inputChangeHandlerSkillVersion(e, "versionName") }} 
												/>
												<label htmlFor="input-type-number">Name <span className='required'>*</span> (minimum 3 characters)</label>
											</div>
										</div>
									</div>
								</div>
								<div className="row" style={{ marginBottom: '5px' }}>
									<div className="col">
										<div className="form-group base-margin-bottom">
											<div className="form-group__text">
												<textarea id="textarea-state-default"
													placeholder="Please give a detailed description of the skill"
													rows={6} name="skill_version_description" value={versionDescription} 
													onChange={e => { inputChangeHandlerSkillVersion(e, "versionDescription") }} >
												</textarea>
												<label htmlFor="input-type-number">Description <span className='required'>*</span>  (minimum 20 characters)</label>
											</div>
										</div>
									</div>
								</div>
							</>
						}
					</div>

				</Modal.Body>
				<Modal.Footer>
					<button className="btn btn--secondary" 
						disabled={!enableCreateSkillVersionButton} 
						onClick={() => { setShowCreateSkillVersion(false); createSkillVersion(false) }}>
						Submit
					</button>
				</Modal.Footer>
			</Modal>

			<Modal size="lg" centered show={showPublishSkillVersion} animation={false} >
				<Modal.Header >
					<h5>Publish Skill Version</h5>
					<div className=' btn--small btn--icon' onClick={() => { setShowPublishSkillVersion(false) }}>
						<span className="icon-close icon-size-24"></span>
					</div>
				</Modal.Header>
				<Modal.Body>
					{
						showValidationErrorMessage && (<MessageBanner message={dataValidationErrorMessage} messageType={'error'} onCloseClicked={() => { setShowValidationErrorMessage(false) }}></MessageBanner>)

					}

					<div className='base-padding'>
						{

							<>
								<div className="row" style={{ marginBottom: '5px' }}>
									<div className="col">
										<div className="form-group base-margin-bottom">
											<div className="form-group__text">
												<input id="input-type-number" type="text" placeholder="Enter a name to be appeared for the published skill" autoFocus name="skill_publish_name" 
												value={versionName} 
												onChange={e => { inputChangeHandlerSkillPublish(e, "versionName") }} />
												<label htmlFor="input-type-number">Name <span className='required'>*</span>  (minimum 3 characters)</label>
											</div>
										</div>
										
										<div>
											<label htmlFor="input-type-number"> Add Tags
												<b class="info">  (e.g: esp,smartbuy)</b>
												<span data-balloon-length="medium" data-balloon="Tagging a Skill will help end users find relevant to ." data-balloon-pos="down"><span class="icon-info-outline"></span></span>
											</label>
											<EmailChip email_list={skillLabels} placeholder="Please hit Enter after adding each Tag" onVerifyEmail={dummyValidateLabel} onDataChanged={(labels) => { refreshSkillLabels(labels) }} />
										</div>
									</div>
									<div className="col">
										<div className="form-group base-margin-bottom">
											<div className="form-group__text">
												<textarea id="textarea-state-default"
													placeholder="Please give a detailed description of the skill"
													rows={6} name="skill_version_description" 
													value={versionDescription} 
													onChange={e => { inputChangeHandlerSkillPublish(e, "versionDescription") }} >
												</textarea>
												<label htmlFor="input-type-number">Description <span className='required'>*</span>  (minimum 20 characters)</label>
											</div>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col">
										<div style={{ marginBottom: '5px' }}>How End users can consume this skill?</div>
										<div className="form-group form-group--inline form-group--compressed">
											<label className="radio">
												<input type="radio" value="clone"
													checked={skillCloneType === 'clone'}
													onChange={(e) => handleSkillCloneTypeChange("clone")} name="skill_clone_type" />
												<span className="radio__input"></span>
												<span className="radio__label">Clone&nbsp;</span>
												<span data-balloon-length="medium" data-balloon="End users can create their own Replica of the Skill by cloning this Skill from the Skill Library and they will be able to customize it." data-balloon-pos="up"><span class="icon-info-outline"></span></span>
											</label>
											&nbsp;&nbsp;&nbsp;&nbsp;
											<div className="form-group form-group--inline form-group--compressed">
												<label className="radio">
													<input type="radio" value="read_only"
														checked={skillCloneType === 'read_only'}
														onChange={(e) => handleSkillCloneTypeChange("read_only")} name="skill_clone_type" />
													<span className="radio__input"></span>
													<span className="radio__label">Share&nbsp;</span>
													<span data-balloon-length="large" data-balloon="A new copy of this Skill will be created the first time an End user tries to clone it. The same copy will be shared among all other users who try to clone it afterwards. Users will not be able to customize it." data-balloon-pos="right"><span class="icon-info-outline"></span></span>

												</label>
											</div>
										</div>
									</div>

									<div className="col">
										<div class="subheader"></div>
										<div class="form-group form-group--inline">
											<label class="checkbox">
												<input type="checkbox" checked={skillCloneApprovalRequired} onChange={(e) => handleSkillCopyApprovalFlagChange(e.target.checked)} name="skill_clone_approval_required" />
												<span class="checkbox__input"></span>
												<span class="checkbox__label">Check if Approval required to consume this Skill</span>
											</label>
										</div>
									</div>
								</div>
							</>

						}
					</div>

				</Modal.Body>
				<Modal.Footer>
					<button className="btn btn--secondary" 
						disabled={!enableSkillPublishButton}
						onClick={() => { setShowCreateSkillVersion(false); publishSkillVersion() }}>
						Publish
					</button>
				</Modal.Footer>
			</Modal>

			<Modal size="lg" centered show={showDeleteSkill} animation={false}>
				<Modal.Header>
					<h5>Confirm Deletion</h5>
					<div className='btn--small btn--icon' onClick={() => { setShowDeleteSkill(false) }}>
						<span className="icon-close icon-size-24"></span>
					</div>
				</Modal.Header>
				<Modal.Body>
					<div class="text-center">
						<h6>Do you wish to delete the following skill: <b>{skillNameForDeletion}</b>?</h6>
						<br></br>
						{deletionInstruction()}
						<br></br>
						<p><b>Note:</b> Contact the BotLite team to recover the deleted skill within 30 days of deletion.</p>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<div class="button-center">
						<button class="btn" onClick={() => { setShowDeleteSkill(false); deleteSkill() }}>Confirm</button>
					</div>
				</Modal.Footer>
			</Modal>

			<Modal size="md" centered show={showDeleteSkillVersion} animation={false}>
				<Modal.Header>
					<h5>Confirm Deletion</h5>
					<div className='btn--small btn--icon' onClick={() => { setShowDeleteSkillVersion(false) }}>
						<span className="icon-close icon-size-24"></span>
					</div>
				</Modal.Header>
				<Modal.Body>
					<div class="text-center">
						<h6>Do you wish to delete this version of the Skill: <b>{versionName}</b>?</h6>
						<br></br>
						{deletionInstruction()}
					</div>
				</Modal.Body>
				<Modal.Footer>
					<div class="button-center">
						<button class="btn" onClick={() => { setShowDeleteSkillVersion(false); deleteSkillVersion() }}>Confirm</button>
					</div>
				</Modal.Footer>
			</Modal>

			<Modal size="md" centered show={showRevertSkillVersion} animation={false}>
				<Modal.Header>
					<h5>Confirm</h5>
					<div className='btn--small btn--icon' onClick={() => { setShowRevertSkillVersion(false) }}>
						<span className="icon-close icon-size-24"></span>
					</div>
				</Modal.Header>
				<Modal.Body>
					<div class="text-center">
						<h6>Do you wish to revert your Skill to this version : <b>{versionName}</b>?</h6>
						<br></br>
						{deletionInstruction()}
					</div>
				</Modal.Body>
				<Modal.Footer>
					<div class="button-center">
						<button class="btn" onClick={() => { setShowRevertSkillVersion(false); revertSkillVersion() }}>Confirm</button>
					</div>
				</Modal.Footer>
			</Modal>

			<Modal size="lg" centered show={showSkillUsers} animation={false}>
				<Modal.Header>
					<h5>Skill Consumers</h5>
					<div className='btn--small btn--icon' onClick={() => { setShowSkillUsers(false) }}>
						<span className="icon-close icon-size-24"></span>
					</div>
				</Modal.Header>
				<Modal.Body>
					<div class="responsive-table">
						<table class="table table--lined table--fixed table--striped">
							<thead>
								<tr>
									<th>Parent Skill</th>
									<th>Version name</th>
									<th>User name</th>
								</tr>
							</thead>
							<tbody>
								{
									selectedSkill && selectedSkill.versionsList.map((skillVersion, index) => {
										if (skillVersion.cloned_users) {
											{
												return (
													skillVersion.cloned_users.map((user, i) => {
														return (
															<tr>
																<td>{selectedSkill.name}</td>
																<td>{skillVersion.name}</td>
																<td>{user}</td>
															</tr>
														)
													})
												)
											}
										}
									})
								}
							</tbody>

						</table>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<div class="button-center">
						<button class="btn btn--secondary" onClick={() => { downloadSkillUsers() }}>
							<span className='entity-menuText'>Download</span>
						</button>
					</div>
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default SkillView;