import Wrapper from "../../../../common-components/wrapper/Wrapper";
import ScheduleView from "../schedule-view-component/ScheduleViewComponent";

const ScheduleScreen = () => {
  return (
    <Wrapper>
      <ScheduleView />
    </Wrapper>
  );
};

export default ScheduleScreen;
