
import { CopyBlock, a11yLight } from "react-code-blocks";
const CodeViewCopier = ({ editorLanguage = "html", dataCode, showLineNumbers }) => {

  return <CopyBlock
    text={dataCode}
    language={editorLanguage}
    codeBlock
    showLineNumbers={showLineNumbers}
    theme={a11yLight}
  />

}

export default CodeViewCopier;

