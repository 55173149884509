import React, { useEffect, useState } from 'react';
import './EmailChip.css';

const EmailChip = (props) => {
    const [emails, setEmails] = useState(props.email_list);
    const [inputValue, setInputValue] = useState('');

    useEffect(() => {
        setEmails(props.email_list)
    }, [props.email_list])

    useEffect(() => {
        props.onDataChanged(emails);
    }, [emails])

    const handleInputChange = (event) => {
        setInputValue(event.target.value);

    };

    const handleInputKeyPress = (event) => {
        if (event.key === 'Enter' || event.key === 'Tab') {
            addEmail(inputValue.trim());
            setInputValue('');
        }
    };

    const addEmail = async (email) => {
        if (!email) return;

        if (emails.includes(email)) {
            // Ignore duplicates
            return;
        }
        let isEmailValid = await props.onVerifyEmail(email);
        if (isEmailValid) {
            setEmails([...emails, email]);
        }

    };

    const removeEmail = (emailToRemove) => {
        setEmails(emails.filter((email) => email !== emailToRemove));
    };

    return (<div className='flex'>
        <div className="email-chip-container" style={{ width: '95%' }}>
            <div className="email-chip-list">
                {emails.map((email, index) => (
                    <div key={index} className="email-chip">
                        {email}
                        <img class="icon_cancel closeIcon" onClick={() => removeEmail(email)} src="data:image/svg+xml,%3Csvg%20viewBox%3D%220%200%2096%2096%22%20%20%20%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%20%20%20%20%3Cstyle%20type%3D%22text%2Fcss%22%3E%20%20%20%20%20%20%20%20.st0%7B%20%20%20%20%20%20%20%20%20%20%20%20fill%3A%23fff%3B%20%20%20%20%20%20%20%20%7D%20%3C%2Fstyle%3E%20%20%20%20%3Cg%3E%20%20%20%20%20%20%20%20%3Cpath%20class%3D%22st0%22%20d%3D%22M48%2C0A48%2C48%2C0%2C1%2C0%2C96%2C48%2C48.0512%2C48.0512%2C0%2C0%2C0%2C48%2C0Zm0%2C84A36%2C36%2C0%2C1%2C1%2C84%2C48%2C36.0393%2C36.0393%2C0%2C0%2C1%2C48%2C84Z%22%2F%3E%20%20%20%20%20%20%20%20%3Cpath%20class%3D%22st0%22%20d%3D%22M64.2422%2C31.7578a5.9979%2C5.9979%2C0%2C0%2C0-8.4844%2C0L48%2C39.5156l-7.7578-7.7578a5.9994%2C5.9994%2C0%2C0%2C0-8.4844%2C8.4844L39.5156%2C48l-7.7578%2C7.7578a5.9994%2C5.9994%2C0%2C1%2C0%2C8.4844%2C8.4844L48%2C56.4844l7.7578%2C7.7578a5.9994%2C5.9994%2C0%2C0%2C0%2C8.4844-8.4844L56.4844%2C48l7.7578-7.7578A5.9979%2C5.9979%2C0%2C0%2C0%2C64.2422%2C31.7578Z%22%2F%3E%20%20%20%20%3C%2Fg%3E%3C%2Fsvg%3E"></img>
                    </div>
                ))}
            </div>
            <input
                type="text"
                value={inputValue}
                onChange={handleInputChange}
                onKeyDown={handleInputKeyPress}
                placeholder={props.placeholder}
                className="email-input"
                style={{ "border": "none", "boxShadow": "none" }}
            />

        </div>
        <span className='icon-add icon-size-18' style={{ width: "34px", height: "34px", padding: "6px", border: "solid", borderRadius: "50%", marginLeft: "8px", marginTop: "0.4%"}} onClick={() => {
            addEmail(inputValue.trim());
            setInputValue('');
        }}></span>
    </div>
    );
};


EmailChip.defaultProps = {
    email_list: [],
    placeholder: "Enter email and hit enter",
    onDataChanged: (emails) => { },
    onVerifyEmail: (email) => { return true }
}
export default EmailChip;
