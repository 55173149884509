const app_url = process.env.REACT_APP_ROOT_URL;

const SkillService = {

    createSkill: (data) => {
        var reqHeaders = new Headers();
        reqHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'POST',
            headers: reqHeaders,
            body: JSON.stringify(data)
        };

        return fetch(`${app_url}/skill`, requestOptions)

    },
	
	listSkills: () => {

        var reqHeaders = new Headers();
        reqHeaders.append("Content-Type", "application/json");
        var requestOptions = {
            method: 'GET',
            headers: reqHeaders
        };

        return fetch(`${app_url}/skill`, requestOptions);
    },

    getSkill: (skillId) => {

        var reqHeaders = new Headers();
        reqHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'GET',
            body: {}
        };

        return fetch(`${app_url}/skill/${skillId}`, requestOptions);

    },
	
	getSkillByName: (skillName) => {
		console.log("inside getSkillByName");
        var reqHeaders = new Headers();
        reqHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'GET',
            headers: reqHeaders
        };

        return fetch(`${app_url}/skill/name/${skillName}`, requestOptions);

    },

    updateSkill: (data) => {
        var reqHeaders = new Headers();
        reqHeaders.append("Content-Type", "application/json");
        console.log(data);
        var requestOptions = {
            method: 'PUT',
            headers: reqHeaders,
            body: JSON.stringify(data)
        };

        return fetch(`${app_url}/skill`, requestOptions);
    },

    deleteSkill: (skillId) => {

        var reqHeaders = new Headers();
        reqHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'DELETE',
            headers: reqHeaders
        };
        
        return fetch(`${app_url}/skill/${skillId}`, requestOptions);

    }
}

export default SkillService;