import { useState, useRef } from "react";
import { ACTION_TYPE_OPTIONS, CHART_KEYS } from "../../constants";
import AnalyticsFilterComponent from "../analytics-filter-component/AnalyticsFilterComponent";
import ActionSelect from "../../../../custom-components/action-select/ActionSelect";
import { ChartHeader } from "../../style";

const AnalyticsBotInternalChart = ({ chart, unitHeight, unitWidth, bot }) => {
  const [action, setAction] = useState(ACTION_TYPE_OPTIONS[0]);
  const downloadDataRef = useRef();
  const [fileData, setFileData] = useState(null);

  const onActionChange = (data) => {
    setAction(data);
  };

  const getDownloadData = (e) => {
    e.preventDefault();
    const downloadData = downloadDataRef?.current?.getDownloadData();
    setFileData(downloadData);
    exportToCsv();
  };

  const downloadFile = ({ data, fileName, fileType }) => {
    const blob = new Blob([data], { type: fileType });
    const a = document.createElement("a");
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };

  const exportToCsv = () => {
    let headers = ["Name, Bot"];
    let usersCsv = fileData.reduce((acc, user) => {
      const { group, group_series_0 } = user;
      acc.push([group, group_series_0].join(","));
      return acc;
    }, []);
    console.log("userscsv..", usersCsv);
    downloadFile({
      data: [...headers, ...usersCsv].join("\n"),
      fileName: "users.csv",
      fileType: "text/csv",
    });
  };

  return (
    <div>
      <div className="base-margin">
        <ChartHeader>{chart.header}</ChartHeader>
        <span className="base-margin-left info-display">
          {chart.subHeader} &nbsp;
          <span
            tabindex="0"
            data-balloon-break
            data-balloon={chart.chartInfo}
            data-balloon-pos="down"
            data-balloon-length="medium"
          >
            <span className="icon-info-outline"></span>
          </span>
          {chart.downloadInfo !== "" && (
            <span onClick={getDownloadData}>
              <span
                tabindex="1"
                data-balloon-break
                data-balloon={chart.downloadInfo}
                data-balloon-pos="down"
                data-balloon-length="medium"
              >
                &nbsp; <span className="icon-download"></span>
              </span>
            </span>
          )}
        </span>

        {chart.key === CHART_KEYS.BOT.AVG_RESPONSE_TIME_BY_ACTION && (
          <span
            style={{ display: "inline-block", padding: "10px" }}
            key={chart.key}
          >
            <ActionSelect
              key={chart.key}
              defaultValue={action}
              options={ACTION_TYPE_OPTIONS}
              onValueChange={(data) => {
                onActionChange(data);
              }}
            />
          </span>
        )}
      </div>
      <hr />
      {chart.id !== "" && (
        <AnalyticsFilterComponent
          chartKey={chart.key}
          chartId={chart.id}
          height={(7 * unitHeight) / 2}
          width={1.5 * unitWidth}
          bot={bot}
          action={action.value}
          insight="bot"
          ref={downloadDataRef}
        />
      )}
    </div>
  );
};

export default AnalyticsBotInternalChart;
