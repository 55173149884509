import Wrapper from "../../../../common-components/wrapper/Wrapper";
import IntegrationView from "../integration-view-component/IntegrationViewComponent";
const IntegrationScreen = () => {
    return (
        <Wrapper>
            <IntegrationView />
        </Wrapper>
    );
};

export default IntegrationScreen;
