import Wrapper from "../../../../common-components/wrapper/Wrapper";
import UserManagementView from "../user-management-view-component/UserManagementViewComponent";

const UserManagementScreen = () => {
  return (
    <Wrapper>
      <UserManagementView />
    </Wrapper>
  );
};

export default UserManagementScreen;
