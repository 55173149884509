import { Handle, Position, useStore, useReactFlow, getConnectedEdges } from 'react-flow-renderer';
import { useEffect, useState } from 'react';
import DialogService from '../../../../dialog-service/DialogService';
import './ConditionNode.css'
import { setDeletedNodes } from '../../../dialog-view-component/DialogViewComponent';


const ConnectableFn = (id, direction) => {
	const edgesCount = useStore((store) => store.edges.length);
	const { getNode, getEdges } = useReactFlow();

	const [hasConnections, setHasConnections] = useState(false);

	useEffect(() => {
		setHasConnections(() => {
			const edges = getConnectedEdges([getNode(id)], getEdges()).filter(
				(e) => e[direction] === id
			);

			return edges.length > 1;
		});
	}, [getNode, getEdges, id, edgesCount]);

	return hasConnections;
};

function ConditionNode({ id, data, xPos, yPos }) {
	const { getNodes, getEdges, setNodes, setEdges } = useReactFlow();
	const nodes = getNodes();
	const edges = getEdges();
	const [open, setOpen] = useState(false);
	console.log(data.showConfirmation)
	const onClick = () => {
		if (!open)
			setOpen(true);
		else
			setOpen(false);
	};

	const onDragOver = event => event.preventDefault();

	const onDrop = event => {
		event.preventDefault();
		var data = event.dataTransfer.getData('augment');
	};


	// const onOpenChange = () => {
	// 	if (open)
	// 		document.getElementById(`condition${id}`).style.display = 'block';
	// 	else
	// 		document.getElementById(`condition${id}`).style.display = 'none';
	// };

	// useEffect(onOpenChange, [open]);

	const hasSourceConnections = ConnectableFn(id, "source");
	const deleteEdges = (id) => {
		let connectedEdges = []
		for (let i of edges) {
			if (i.target == id || i.source == id) {
				connectedEdges.push(i)
			}
		}

		const remainingEdges = edges.filter((edge) => !connectedEdges.includes(edge));
		const remainingNodes = nodes.filter((node) => node.id != id)

		let targetId = ''
		let getedges = {}
		let temp = []

		for (let i of connectedEdges) {
			if (i.target == id) {
				getedges = i;
			}
			if (i.source == id) {
				targetId = i['target'];
			}
		}
		if (connectedEdges.length == 2) {
			getedges['target'] = targetId
			temp = [...remainingEdges, getedges];
		}
		else {
			temp = [...remainingEdges]
		}
		console.log("temp", temp)

		const finalList = [...temp]
		if (remainingNodes.length == 1) {
			setEdges([])
		}
		else {
			setEdges(finalList)
		}


		setNodes(remainingNodes)
		console.log(remainingNodes)
		console.log(finalList)
	}

	const deleteNodeById = (id) => {
		let node_values = { id, data, position: { x: xPos, y: yPos }, type: "ConditionNode" }
		setDeletedNodes(node_values)
		DialogService.deleteNodeById(data.skill, data.rule, id).then(res => res.json()).then(res => {
			if (res.status == 'success') {
				deleteEdges(id)
			}
			else {
				console.log(id + " doesn't exists" == res.details)
				console.log(res)
				if (res.details == id + " doesn't exists") {
					console.log("insidde")
					data.showConfirmation = false
					deleteEdges(id)
				}
				// setDataLoading(false);
				// setBannerMessageType('error');
				// setBannerMessage(res['details']);
				// setShowMessageBanner(true);
			}
		}).catch(err => {
			// setDataLoading(false);
			// setBannerMessageType('error');
			// setBannerMessage('Something went wrong. Please try again later!');
			// setShowMessageBanner(true);
		})
	}
	const truncateStrings = (str, length) => {
		if (str) {
			if (length > str.length) {
				return str
			}
			else {
				str = str.substring(0, length) + '...'
				return str;
			}
		}
		else {
			return '';
		}
	}

	return (
		<div className='nodeContainer conditionNodeContainer' id={`nodeContainer${id}`} onDragOver={onDragOver} onDrop={onDrop}>
			{data.showConfirmation == true &&
				<div style={{ margin: '3px' }}>
					<div style={{ fontSize: '0.8em' }}>
						Are you sure you want to delete the condition node?
					</div>
					<div className='text-center' style={{ marginTop: '2px' }}>
						<button className="btn btn--small btn--secondary" style={{ fontSize: '0.8em' }} onClick={() => { data.showConfirmation = false; }} >
							No
						</button>
						<button className="btn  btn--small btn--secondary" style={{ fontSize: '0.8em' }} onClick={() => { deleteNodeById(id) }} >
							Yes
						</button>
					</div>
				</div>
			}
			<div className='node' id={`node${id}`} >
				<Handle
					type='source'
					position={Position.Right}
					id='conditionSourceHandle'
					className="react-flow__handle-true"
					isConnectable={!hasSourceConnections}
				/>
				<Handle
					type='source'
					position={Position.Left}
					id='conditionSourceHandleFalse'
					className="react-flow__handle-right"
					isConnectable={!hasSourceConnections}
				/>
				<Handle
					type='target'
					position={Position.Top}
					id='conditionTargetHandle'
					className="react-flow_handle"
				/>
				<div>
					<div className='nodeType'>
						Condition
					</div>
					<div className='nodeDeleteType' >
						<span className='icon-delete' onClick={() => { console.log(id); data.showConfirmation = true }}></span>
					</div>
				</div>
				<div className='nodeName'>
					{/* Clear - {data.clear.clearType} */}
					{truncateStrings(data.name, 20)}
				</div>
				<div>
					<div className='nodeCount'>
						{data.conditions.length === 1 ? '1 Condition - ' : `${data.conditions.length} Conditions - `}
						{data.conditions.filter(value => value.group).length === 1 ? '1 Group' : `${data.conditions.filter(value => value.group).length} Groups`}
					</div>
				</div>
				{/* <div className='nodeName'>
					<div className='descriptiontxtContner'>
						<div className="tooltip"><label>{data.description}</label>
							<span className="tooltiptext">{data.description}</span>
						</div>
					</div>

				</div> */}
			</div>
			{/* <div className='augmentsContainer' id={`condition${id}`}></div> */}
		</div>
	);
}

export default ConditionNode;
