import Wrapper from "../../../../common-components/wrapper/Wrapper";
import SkillView from '../skill-view-component/SkillViewComponent';
import DialogScreen from "../../../dialog-module/dialog-components/dialog-screen-component/DialogScreenComponent";
import { useState } from "react";
import IntentView from "../../../intent-module/intent-component/intent-view-component/IntentsViewComponent";
import EntitiesView from "../../../entity-module/entity-component/entity-view-component/EntityViewComponent";
import { Link } from "react-router-dom";
import DialogView from "../../../dialog-module/dialog-components/dialog-view-component/DialogViewComponent";
const SkillScreen = () => {
    const [tab, setTab] = useState(0)
    return (
        <Wrapper>
            <ul id="hjustified" class="tabs tabs--justified">
                <li id="hjustified-1" className={"tab " + (tab == 0 ? "active" : "")} onClick={() => setTab(0)}>
                    <Link tabindex="0" to="/skills">Skill </Link>
                </li>
                <li id="hjustified-2" className={"tab " + (tab == 1 ? "active" : "")} onClick={() => setTab(1)}>
                    <Link tabindex="0" to="/entity">Entity </Link>
                </li>
                <li id="hjustified-3" className={"tab " + (tab == 2 ? "active" : "")} onClick={() => setTab(2)}>
                    <Link tabindex="0" to="/intents">Intent </Link>
                </li>
                <li id="hjustified-4" className={"tab " + (tab == 3 ? "active" : "")} onClick={() => setTab(3)}>
                    <Link tabindex="0" to="/dialog">Dialog </Link>
                </li>
            </ul>
            <div id="hjustified-content" class="tab-content ">
                {tab == 0 && <div id="hjustified-1-content" className={"tab-pane " + (tab == 0 ? "active" : "")}>
                    <SkillView />
                </div>}
                {tab == 1 && <div id="hjustified-2-content" className={"tab-pane " + (tab == 1 ? "active" : "")}>
                    <EntitiesView />
                </div>}
                {tab == 2 && <div id="hjustified-3-content" className={"tab-pane " + (tab == 2 ? "active" : "")}>
                    <IntentView />
                </div>}
                {tab == 3 && <div id="hjustified-4-content" className={"tab-pane " + (tab == 3 ? "active" : "")}>
                    <DialogView />
                </div>}
            </div>

        </Wrapper>
    )
}

export default SkillScreen;