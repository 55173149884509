import { useEffect } from 'react';
import './SplitScreenWrapper.css';

const SplitScreenWrapper = ({ leftPanelElement, rightPanelElement, isSplitScreenEnabled = false, onResize = (leftWidth, rightWidth) => { } }, scroll = true) => {

    let resizer, leftSide, rightSide, leftWidth, x, rightWidth;
    let windowInnerWidth = window.innerWidth;
    let minScreenWidth = 1199.9;

    useEffect(() => {
        resizer = document.getElementById("dragMe");
        resizer.addEventListener('mousedown', mouseDownHandler);
    }, [])

    useEffect(() => {
        if (isSplitScreenEnabled) {
            if (resizer == null) {
                resizer = document.getElementById("dragMe");
                resizer.addEventListener('mousedown', mouseDownHandler);
            }
            //register the resizer and its siblings once document object is init
            leftSide = resizer.previousElementSibling;
            rightSide = resizer.nextElementSibling;
        }
        else {
            leftSide = null;
            rightSide = null;
        }

    })

    //helper functions for resizer
    const mouseDownHandler = function (e) {
        if (leftSide != null && rightSide != null) {
            // Get the current mouse position
            x = e.clientX;
            leftWidth = leftSide.getBoundingClientRect().width;
            rightWidth = rightSide.getBoundingClientRect().width;

            // Attach the listeners to `document`
            document.addEventListener('mousemove', mouseMoveHandler);
            document.addEventListener('mouseup', mouseUpHandler);
        }

    };
    const mouseUpHandler = function () {
        if (document != null && resizer != null && leftSide != null && rightSide != null) {
            resizer.style.removeProperty('cursor');
            document.body.style.removeProperty('cursor');


            leftSide.style.removeProperty('user-select');
            leftSide.style.removeProperty('pointer-events');


            rightSide.style.removeProperty('user-select');
            rightSide.style.removeProperty('pointer-events');


            // Remove the handlers of `mousemove` and `mouseup`
            document.removeEventListener('mousemove', mouseMoveHandler);
            document.removeEventListener('mouseup', mouseUpHandler);
        }

    };

    const mouseMoveHandler = function (e) {
        if (document != null && resizer != null && leftSide != null && rightSide != null) {
            // How far the mouse has been moved
            const dx = e.clientX - x;
            // const dy = e.clientY - y;
            if (resizer.parentNode.getBoundingClientRect().width > 1199.98) {
                const newLeftWidth = ((leftWidth + dx) * 100) / resizer.parentNode.getBoundingClientRect().width;
                const newRightWidth = ((rightWidth - dx) * 100) / resizer.parentNode.getBoundingClientRect().width;
                leftSide.style.width = newLeftWidth < 40 ? `40%` : newRightWidth < 40 ? `58.5%` : `${newLeftWidth}%`;
                rightSide.style.width = newLeftWidth < 40 ? `58.5%` : newRightWidth < 40 ? `40%` : `${newRightWidth}%`;

                onResize(newLeftWidth < 40 ? 40 : newRightWidth < 40 ? 58.5 : newLeftWidth, newLeftWidth < 40 ? 58.5 : newRightWidth < 40 ? 40 : newRightWidth);
            }
            else {
                leftSide.style.width = `100%`;
                rightSide.style.width = `100%`;

                onResize(100, 100);
            }



            resizer.style.cursor = 'col-resize';
            document.body.style.cursor = 'col-resize';

            leftSide.style.userSelect = 'none';
            leftSide.style.pointerEvents = 'none';

            rightSide.style.userSelect = 'none';
            rightSide.style.pointerEvents = 'none';
        }

    };

    if (isSplitScreenEnabled) {
        return (
            <div className='row split-screen-main-container' >
                <div className='flex flex-wrap flex-center left-panel-flex-wrap scrollbox' style={{ "width": windowInnerWidth > minScreenWidth ? "50%" : "0%", "overflow": scroll ? "auto" : '' }}>
                    <div className='left-panel-wrap panel initial_bot_width'> {leftPanelElement} </div>
                </div>
                <div id="dragMe" className="resizer" style={{ "width": windowInnerWidth > minScreenWidth ? "1%" : "0%", "background": "white" }}>
                    <div className="resizer-bar"></div>
                </div>
                <div className='flex flex-wrap flex-center right-panel-flex-wrap' style={{ "width": windowInnerWidth > minScreenWidth ? "49%" : "100%" }}>
                    <div className='right-panel-wrap panel initial_edit_width'>{rightPanelElement}</div>
                </div>

            </div>
        )
    }
    else {
        return (
            <>
                <div id="dragMe" className="resizer" style={{ "display": "none" }}>
                    <div className="resizer-bar"></div>
                </div>
                <div style={{ "overflow": "auto", "height": "100%", "margin": "8px" }}>{leftPanelElement}</div></>

        )
    }

}

export default SplitScreenWrapper;