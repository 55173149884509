const app_url = process.env.REACT_APP_ROOT_URL

const BotLiteUpdatesService = {
	
	getBotLiteUpdates: () => {

        var reqHeaders = new Headers();
        reqHeaders.append("Content-Type", "application/json");
        var requestOptions = {
            method: 'GET',
            headers: reqHeaders
        };

        return fetch(`${app_url}/botlite_updates`, requestOptions);
    },

    addBotLiteUpdates: (data) => {

        var reqHeaders = new Headers(); 
        reqHeaders.append("Content-Type", "application/json");
        console.log(data);
        var requestOptions = {
            method: 'POST',
            headers: reqHeaders,
            body: JSON.stringify(data)
        };

        return fetch(`${app_url}/botlite_updates`, requestOptions);
    },

    /*addBotLiteUpdates: (data) => {
        var formdata = new FormData();
        formdata.append("title", data['title']);
        formdata.append("message", data['message']);
        formdata.append("scheduled", data['scheduled']);
        formdata.append("startTime", data['startTime']);
        formdata.append("endTime", data['endTime']);
        formdata.append("timezone", data['timezone']);
        formdata.append("active", data['active']);
        var reqHeaders = new Headers(); 
        reqHeaders.append("Content-Type", "application/json");
        console.log(data);
        var requestOptions = {
            method: 'POST',
            headers: reqHeaders,
            body: formdata
        };

        return fetch(`${app_url}/botlite_updates`, requestOptions);
    },*/
	
	modifyBotLiteUpdates: (data) => {

        var reqHeaders = new Headers();
        reqHeaders.append("Content-Type", "application/json");
        console.log(data);
        var requestOptions = {
            method: 'PUT',
            headers: reqHeaders,
            body: JSON.stringify(data)
        };

        return fetch(`${app_url}/botlite_updates`, requestOptions);
    },
	
};

export default BotLiteUpdatesService;