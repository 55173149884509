import React, { useState, useEffect, useRef } from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import SearchSelect from "../../../../custom-components/search-select/SearchSelect";
import "../../../../../node_modules/react-resizable/css/styles.css";
import "../../../../../node_modules/react-grid-layout/css/styles.css";
import MessageBanner from "../../../../custom-components/MessageBanner/MessageBanner";
import {
  LayoutBox,
  ChartBoxLayout,
  BarChartBoxLayout,
  BarChartHeader,
} from "../../style";
import AnalyticsFilterComponent from "../analytics-filter-component/AnalyticsFilterComponent";
import SkillService from "../../../skill-module/skill-services/SkillService";
import { SKILL_CHART_DETAILS, SKILL_CHART_DETAILS_2 } from "../../constants";
import AnalyticsSkillInternalChart from "./AnalyticsSkillInternalChart";
const ResponsiveGridLayout = WidthProvider(Responsive);
const AnalyticsSkill = () => {
  const getFromLS = (key) => {
    let ls = {};
    if (global.localStorage.getItem("skill_layout")) {
      try {
        ls = JSON.parse(global.localStorage.getItem("skill_layout")) || {};
      } catch (e) {
        /*Ignore*/
      }
    }
    return ls[key];
  };

  const originalLayouts = getFromLS("layouts") || {};
  const originalInternalLayouts = getFromLS("internalLayouts") || {};

  const [layouts, setLayouts] = useState(
    JSON.parse(JSON.stringify(originalLayouts))
  );
  const [internalLayouts, setInternalLayout] = useState(
    JSON.parse(JSON.stringify(originalInternalLayouts))
  );
  const [isDataLoading, setDataLoading] = useState(false);
  const [showMessageBanner, setShowMessageBanner] = useState(false);
  const [bannerMessage, setBannerMessage] = useState("");
  const [bannerMessageType, setBannerMessageType] = useState("success");

  const refWidth = useRef(null);

  const [skill, setSkill] = useState({ label: "Select a Skill", value: "" });
  const [skills, setSkillList] = useState([]);

  const availableHandles = ["s", "w", "e", "n", "sw", "nw", "se", "ne"];

  const getDivNum = (windowSize) => {
    if (windowSize >= 1200) {
      return 12;
    } else if (windowSize >= 992) {
      return 10;
    } else if (windowSize >= 768) {
      return 6;
    } else {
      return 4;
    }
  };

  let width = window.innerWidth;
  let unitWidth = width / getDivNum(width);
  const unitHeight = 33;
  useEffect(() => {
    setDataLoading(true);
    SkillService.listSkills()
      .then((res) => res.json())
      .then((res) => {
        if (res["skills"] != undefined) {
          let skill_list = [];
          for (let skill of res["skills"]) {
            skill_list.push({ label: skill?.name, value: skill._id });
          }
          console.log("skill_list:::", skill_list);
          setSkill(skill_list[0]);
          setSkillList(skill_list);
          setDataLoading(false);
        } else {
          console.log("Failed to retrieve skills");
          setDataLoading(false);
          setBannerMessageType("error");
          setBannerMessage("Failed to retrieve skills!");
          setShowMessageBanner(true);
        }
      })
      .catch((err) => {
        console.log(err);
        setDataLoading(false);
        setBannerMessageType("error");
        setBannerMessage("Failed to retrieve skills!");
        setShowMessageBanner(true);
      });
  }, []);

  useEffect(() => {
    width = refWidth.current ? refWidth.current.offsetWidth : window.innerWidth;
    unitWidth = width / getDivNum(width) - 10;
  }, [refWidth.current]);

  const saveToLS = (key, value) => {
    var existingData = {};
    if (global.localStorage.getItem("skill_layout")) {
      existingData = JSON.parse(global.localStorage.getItem("skill_layout"));
    }
    var uploadData = {
      ...existingData,
      [key]: value,
    };
    global.localStorage.setItem("skill_layout", JSON.stringify(uploadData));
    console.log("saveToLS:::", skill);
  };

  const onLayoutChange = (layout, elayouts) => {
    saveToLS("layouts", elayouts);
    setLayouts(elayouts);
  };

  const onInternalLayoutChange = (layout, elayouts) => {
    saveToLS("internalLayouts", elayouts);
    setInternalLayout(elayouts);
  };

  const getSkillCharts = (data) => {
    console.log("getSkillCharts:::", data);
    setSkill(data);
  };

  const SkillList = () => {
    return (
      <SearchSelect
        id="skillList"
        className="augmentSelect"
        defaultValue={skill}
        options={skills}
        onValueChange={(data) => {
          getSkillCharts(data);
        }}
        noDataMessage="No Skills available"
      />
    );
  };

  const chartWidth = 6;
  const internalChartWidth = 2;
  const chartHeight = 13.5;
  const internalChartHeight = 6;
  const chartMWidth = 6;
  const chartMHeight = 12;

  return (
    <div ref={refWidth}>
      <div>
        <div className="row base-margin-top base-margin-right">
          <h5 className="col-7 col-md-8 col-sm-8 col-lg-8 col-xl-8 col-xxl-9 col-xxxl-9 ">
            {/* Analytics View */}
          </h5>
          <div
            className="col-5 col-md-4 col-sm-4 col-lg-4 col-xl-4 col-xxl-3 col-xxxl-3"
            style={{ marginBottom: "15px" }}
          >
            {SkillList()}
          </div>
        </div>
      </div>
      {showMessageBanner && (
        <MessageBanner
          message={bannerMessage}
          messageType={bannerMessageType}
          onCloseClicked={() => {
            setShowMessageBanner(false);
          }}
        ></MessageBanner>
      )}

      <div className="charts">
        <ResponsiveGridLayout
          className="layout"
          cols={{ lg: 12, md: 10, sm: 6, xs: 4, xl: 12, xxl: 12, xxs: 4 }}
          rowHeight={30}
          layouts={layouts}
          margin={[10, 10]}
          onLayoutChange={(layout, layouts) => onLayoutChange(layout, layouts)}
        >
          <LayoutBox
            key="1"
            data-grid={{
              w: 6,
              h: chartHeight,
              x: 0,
              y: 0,
              minW: chartMWidth,
              minH: chartMHeight,
              resizeHandles: availableHandles,
            }}
          >
            <ResponsiveGridLayout
              className="layout"
              cols={{ lg: 12, md: 10, sm: 6, xs: 4, xl: 12, xxl: 12, xxs: 4 }}
              rowHeight={30}
              layouts={internalLayouts}
              margin={[10, 10]}
              onLayoutChange={(layout, layouts) =>
                onInternalLayoutChange(layout, layouts)
              }
              isBounded={true}
            >
              {SKILL_CHART_DETAILS.map((chart, index) => {
                return (
                  <ChartBoxLayout
                    key={"Skillchart1" + index + 1}
                    data-grid={{
                      w: internalChartWidth,
                      h: internalChartHeight,
                      x: chart.x,
                      y: chart.y,
                    }}
                  >
                    <AnalyticsSkillInternalChart
                      chart={chart}
                      index={index}
                      unitHeight={unitHeight}
                      unitWidth={unitWidth}
                      skill={skill}
                    />
                  </ChartBoxLayout>
                );
              })}
            </ResponsiveGridLayout>
          </LayoutBox>

          {SKILL_CHART_DETAILS_2.map((chart, index) => {
            return (
              <BarChartBoxLayout
                key={chart.key}
                data-grid={{
                  w: chartWidth,
                  h: chartHeight,
                  x: chart.x,
                  y: chart.y,
                  minW: chartMWidth,
                  minH: chartMHeight,
                  resizeHandles: availableHandles,
                }}
              >
                <div className="base-margin">
                  <BarChartHeader>{chart?.header}</BarChartHeader>
                  <span>
                    {" "}
                    &nbsp;
                    <span
                      tabindex="0"
                      data-balloon-break
                      data-balloon={chart?.chartInfo}
                      data-balloon-pos="down"
                      data-balloon-length="large"
                    >
                      <span
                        className="icon-info-outline"
                        style={{ "font-size": "1.8rem", top: "-3px" }}
                      ></span>
                    </span>
                  </span>
                </div>
                <hr />
                <AnalyticsFilterComponent
                  chartId={chart?.id}
                  height={
                    (Object.keys(layouts).length === 0
                      ? chartHeight
                      : layouts["lg"][index + 1]["h"]) * unitHeight
                  }
                  width={
                    ((Object.keys(layouts).length === 0
                      ? chartWidth
                      : layouts["lg"][index + 1]["w"]) -
                      1) *
                    unitWidth
                  }
                  skill={skill}
                  action={chart?.defaultAction?.value}
                  insight="skill"
                />
              </BarChartBoxLayout>
            );
          })}
        </ResponsiveGridLayout>
      </div>

      <div style={{ display: width < 992 ? "block" : "none" }}>
        <MessageBanner
          message={
            "This device doesn't support to view this screen. This view is restricted only for laptops and tablets. Please open it in a different device."
          }
          messageType={"error"}
        ></MessageBanner>
      </div>
    </div>
  );
};

export default AnalyticsSkill;
