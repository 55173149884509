import React, { useState, useEffect } from "react";
import Select from "react-select";

export const ActionSelect = ({
  placeholder,
  defaultValue,
  options,
  onValueChange,
  id = "select-bu",
  border = "lightgrey solid",
  isClearable = false,
  multiSelect = false,
  noDataMessage,
}) => {
  const [selected_item, setSelectedItem] = useState(defaultValue);

  const styles = {
    control: (provided, state) => ({
      ...provided,
      width: "max-content",
    }),
    menu: (base) => ({
      ...base,
      width: "max-content",
    }),
  };
  return (
    <Select
      id={id}
      value={selected_item}
      isSearchable={true}
      isMulti={multiSelect}
      isClearable={isClearable}
      name="select-search"
      placeholder={placeholder}
      options={options}
      onChange={(e) => {
        onValueChange(e);
        setSelectedItem(e);
      }}
      styles={styles}
      noOptionsMessage={() => noDataMessage}
    />
  );
};

export default ActionSelect;
