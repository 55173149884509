import Wrapper from "../../../../common-components/wrapper/Wrapper";
import BotLiteUpdatesView from '../botlite-updates-view-component/BotLiteUpdatesViewComponent';

const BotLiteUpdatesScreen = () => {
  return (
    <Wrapper>
      <BotLiteUpdatesView />
    </Wrapper>
  );
};

export default BotLiteUpdatesScreen;
