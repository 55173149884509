import './ConditionNode.css';
import { useState, useRef, useEffect } from 'react';
import SearchSelect from "../../../../../../custom-components/search-select/SearchSelect";
import QuizIcon from '@mui/icons-material/Quiz';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import AddBoxIcon from '@mui/icons-material/AddBox';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import DeleteIcon from '@mui/icons-material/Delete';
import InputSelect from '../../../../../../custom-components/CreatableSelect/CreatableSelect';
import Modal from 'react-bootstrap/Modal'
import isAlphanumeric from 'validator/lib/isAlphanumeric';

function ConditionNodeDetails({ node, skillEntityList, ruleEntityList, systemEntityList, closeFunction }) {
	const [forceReRender, setForceReRender] = useState(false)
	const [showTest, setShowTest] = useState(false)
	const [showValidationErrorMessage, setShowValidationErrorMessage] = useState(false)
	const [dataValidationErrorMessage, setDataValidationErrorMessage] = useState('')
	const closeSplit = () => { closeFunction() }

	const distinctEntities = useRef({})

	useEffect(() => {
		distinctEntities.current = {}
		node.data.conditions.forEach(condition => { getDistinctEntities(condition) })
		setForceReRender(!forceReRender)
	}, [])

	const operators = [
		{ label: '<', value: '<' },
		{ label: '>', value: '>' },
		{ label: '<=', value: '<=' },
		{ label: '>=', value: '>=' },
		{ label: '=', value: '=' },
		{ label: '!=', value: '!=' }
	]

	const options = [{ label: 'Entities', options: skillEntityList }, { label: 'Context Variables', options: ruleEntityList }, { label: 'System Entities', options: systemEntityList }]

	const getBorder = (condition, showColour) => {
		if (!showColour)
			return 'lightgrey solid 1px'
		else if (condition.result)
			return 'blue solid 1px'
		return 'red solid 1px'
	}

	const getEntity = (entity, index) => <div key={index} className='form-group__text base-margin'>
		<label>{entity}</label>
		<input value={distinctEntities.current[entity]} onChange={event => { distinctEntities.current[entity] = event.target.value; node.data.conditions.forEach((condition, index, array) => { setResult(condition, index, array) }); setGroupResult(); setForceReRender(!forceReRender) }} />
	</div>

	const getResult = condition => {
		let distinctEntitiesValue = distinctEntities.current[condition.condition.entityName.label]
		let conditionValue = condition.condition.entityValue
		if (Number.isInteger(Math.floor(distinctEntitiesValue)))
			distinctEntitiesValue = Number(distinctEntitiesValue)
		if (Number.isInteger(Math.floor(conditionValue)))
			conditionValue = Number(conditionValue)
		switch (condition.condition.entityCondition.value) {
			case '<':
				return distinctEntitiesValue < conditionValue
			case '>':
				return distinctEntitiesValue > conditionValue
			case '<=':
				return distinctEntitiesValue <= conditionValue
			case '>=':
				return distinctEntitiesValue >= conditionValue
			case '=':
				return distinctEntitiesValue === conditionValue
			case '!=':
				return distinctEntitiesValue !== conditionValue
		}
	}

	const setGroupResult = () => {
		let result
		for (let key in node.data.conditions)
			if (key == 0)
				result = node.data.conditions[key].result
			else if (node.data.conditions[key - 1].operator === 'and')
				result = result && node.data.conditions[key].result
			else if (node.data.conditions[key - 1].operator === 'or')
				result = result || node.data.conditions[key].result
		node.data.result = result
	}

	const setResult = (condition, index, array) => {
		if (condition.group) {
			condition.group.forEach((condition, index, array) => { setResult(condition, index, array) })
			let result
			for (let key in condition.group)
				if (key === '0')
					result = condition.group[key].result
				else if (condition.group[key - 1].operator === 'and')
					result = result && condition.group[key].result
				else if (condition.group[key - 1].operator === 'or')
					result = result || condition.group[key].result
			array[index].result = result
		}
		else if (condition.condition)
			array[index].result = getResult(condition)
	}

	const getDistinctEntities = condition => {
		if (condition.group)
			condition.group.forEach(condition => { getDistinctEntities(condition) })
		else if (condition.condition)
			if (!Object.keys(distinctEntities.current).reduce((total, value) => total || value === condition.condition.entityName.label, false))
				distinctEntities.current[condition.condition.entityName.label] = ''
	}

	const getCondition = (condition, index, array, showColour) => {
		if (condition.group)
			return (
				<div>
					<div style={{ marginLeft: '25px', borderRadius: '10px', border: getBorder(condition, showColour)}} className='panel panel--bordered'>
						<div className="base-margin">
							<button className="btn btn--small btn--secondary" onClick={() => { let id = 0; if (condition.group.length > 0) { array[index].group[condition.group.length - 1].operator = 'and'; id = condition.group[condition.group.length - 1].id + 1 } array[index].group.push({ condition: { entityName: systemEntityList[0], entityCondition: operators[4], entityValue: '0' }, id }); if (!distinctEntities.current[systemEntityList[0].label]) distinctEntities.current[systemEntityList[0].label] = '0'; setForceReRender(!forceReRender) }}>Add Condition</button>
							<button className="btn btn--small btn--secondary" onClick={() => { let id = 0; if (condition.group.length > 0) { array[index].group[condition.group.length - 1].operator = 'and'; id = condition.group[condition.group.length - 1].id + 1 } array[index].group.push({ group: [], id }); setForceReRender(!forceReRender) }}>Add Group</button>
							<button className="btn btn--small btn--secondary" onClick={() => { array.splice(index, 1); if (index > 0 && index === array.length) delete array[index - 1].operator; let copyEntities = { ...distinctEntities.current }; distinctEntities.current = {}; node.data.conditions.forEach(condition => { getDistinctEntities(condition) }); Object.keys(distinctEntities.current).forEach(entity => { distinctEntities.current[entity] = copyEntities[entity] }); setForceReRender(!forceReRender) }}>Delete Group</button>
						</div>
						{condition.group.map((subCondition, subIndex, subArray) => getCondition(subCondition, subIndex, subArray, showColour))}
					</div>
					{condition.operator ? <div style={{ width: 'fit-content', margin: 'auto' }}>
						<div className="btn-group btn-group--square base-margin">
							<button className={`btn btn--ghost ${condition.operator === 'and' ? 'selected' : null}`} onClick={() => { array[index].operator = 'and'; if (showTest) { node.data.conditions.forEach((condition, index, array) => { setResult(condition, index, array) }); setGroupResult() } setForceReRender(!forceReRender) }}>And</button>
							<button className={`btn btn--ghost ${condition.operator === 'or' ? 'selected' : null}`} onClick={() => { array[index].operator = 'or'; if (showTest) { node.data.conditions.forEach((condition, index, array) => { setResult(condition, index, array) }); setGroupResult() } setForceReRender(!forceReRender) }}>Or</button>
						</div>
					</div> : <div className='base-margin'></div>}
				</div>
			)
		else if (condition.condition)
			return (
				<div key={index}>
					<div>
						<div className="form-group form-group--inline base-margin-top base-margin-top" style={{ width: '95%' }}>
							<div className="col-4">
								<InputSelect defaultValue={condition.condition.entityName} options={options} border={getBorder(condition, showColour)} onValueChange={data => { array[index].condition.entityName = data; let copyEntities = { ...distinctEntities.current }; distinctEntities.current = {}; node.data.conditions.forEach(condition => { getDistinctEntities(condition) }); Object.keys(distinctEntities.current).forEach(entity => { distinctEntities.current[entity] = copyEntities[entity] }); if (showTest) { node.data.conditions.forEach((condition, index, array) => { setResult(condition, index, array) }); setGroupResult() } setForceReRender(!forceReRender) }} />
							</div>
							<div className="col-2">
								<SearchSelect defaultValue={condition.condition.entityCondition} options={operators} border={getBorder(condition, showColour)} onValueChange={data => { array[index].condition.entityCondition = data; if (showTest) { node.data.conditions.forEach((condition, index, array) => { setResult(condition, index, array) }); setGroupResult() } setForceReRender(!forceReRender) }} />
							</div>
							<div className="form-group__text col-4">
								<input id="conditionValue" value={condition.condition.entityValue} style={{ border: getBorder(condition, showColour), backgroundColor: 'white', borderRadius: '5px' }} onChange={event => { array[index].condition.entityValue = event.target.value; if (showTest) { node.data.conditions.forEach((condition, index, array) => { setResult(condition, index, array) }); setGroupResult() } setForceReRender(!forceReRender) }} />
							</div>
							<div className='col-2'>
								<button className='btn btn--icon btn--secondary' style={{ float: 'right' }} onClick={() => { array.splice(index, 1); if (index > 0) delete array[index - 1].operator; let copyEntities = { ...distinctEntities.current }; distinctEntities.current = {}; node.data.conditions.forEach(condition => { getDistinctEntities(condition) }); Object.keys(distinctEntities.current).forEach(entity => { distinctEntities.current[entity] = copyEntities[entity] }); setForceReRender(!forceReRender) }}><span className='icon-delete'></span></button>
							</div>
						</div>
					</div>
					{condition.operator ? <div style={{ width: 'fit-content', margin: 'auto' }}>
						<div className="btn-group btn-group--square base-margin">
							<button className={`btn btn--ghost ${condition.operator === 'and' ? 'selected' : null}`} onClick={() => { array[index].operator = 'and'; if (showTest) { node.data.conditions.forEach((condition, index, array) => { setResult(condition, index, array) }); setGroupResult() } setForceReRender(!forceReRender) }}>And</button>
							<button className={`btn btn--ghost ${condition.operator === 'or' ? 'selected' : null}`} onClick={() => { array[index].operator = 'or'; if (showTest) { node.data.conditions.forEach((condition, index, array) => { setResult(condition, index, array) }); setGroupResult() } setForceReRender(!forceReRender) }}>Or</button>
						</div>
					</div> : <div className='base-margin'></div>}
				</div>
			)
	}

	const inputHandlerNameChange = (data) => {

		setShowValidationErrorMessage(false);

		node.data.name = data
		console.log(data)
		const validName = () => {
			if (data === '' || data.length < 5) {
				setShowValidationErrorMessage(true);
				setDataValidationErrorMessage('Name should be greater than 5 characters.');
				return false;
			}

			if (!isAlphanumeric(data, 'en-US', { "ignore": "_" })) {
				setShowValidationErrorMessage(true);
				setDataValidationErrorMessage('Special characters other than _ is not allowed in name.');
				return false;
			}
		}

		let valid = validName();
		// node.data.name = temp;
		const empty = data != '' && data.length > 1;

		const result = empty && valid


	}

	return <div>
		<div className="panel panel--raised panel--bordered padding20 conditionNode">
			<h6>Edit Conditions <span onClick={() => { closeSplit() }} className='icon-close' style={{ float: 'right', position: 'sticky' }}></span></h6>
			<hr />
		<div style={{height: '70vh', overflowY: 'auto'}}>
			{
				showValidationErrorMessage && (<div className="popBanner">{dataValidationErrorMessage}</div>)
			}
			<div className="form-group base-margin-bottom base-margin-top" >
				<div className="form-group__text">
					{/* <input id="input-type-number" type="text" name="rule_name" onChange={event => { setNewNodeInfo(obj => { let objCopy = { ...obj }; objCopy.name = event.target.value; return objCopy }) }} /> */}
					<input id="input-type-number" type="text" name="rule_name" autoComplete='off' value={node.data.name} onChange={event => { inputHandlerNameChange(event.target.value); setForceReRender(!forceReRender) }} />
					<label htmlFor="input-type-number">Condition Node Name <span className='required'>*</span> </label>
				</div>
			</div>
			<div className="base-margin-bottom base-margin-top">
				<button className="btn btn--small btn--secondary" onClick={() => { let id = 0; if (node.data.conditions.length > 0) { node.data.conditions[node.data.conditions.length - 1].operator = 'and'; id = node.data.conditions[node.data.conditions.length - 1].id + 1 } node.data.conditions.push({ condition: { entityName: systemEntityList[0], entityCondition: operators[4], entityValue: '0' }, id }); if (!distinctEntities.current[systemEntityList[0].label]) distinctEntities.current[systemEntityList[0].label] = '0'; setForceReRender(!forceReRender) }} >Add Condition</button>
				<button className="btn btn--small btn--secondary" onClick={() => { let id = 0; if (node.data.conditions.length > 0) { node.data.conditions[node.data.conditions.length - 1].operator = 'and'; id = node.data.conditions[node.data.conditions.length - 1].id + 1 } node.data.conditions.push({ group: [], id }); setForceReRender(!forceReRender) }} >Add Group</button>
				<button className="btn btn--small btn--secondary" onClick={() => { setShowTest(true); node.data.conditions.forEach((condition, index, array) => { setResult(condition, index, array) }); setGroupResult() }}>Test Conditions</button>
			</div>
			<div>
				<div>{node.data.conditions.map((condition, index, array) => getCondition(condition, index, array, false))}</div>
			</div>
		</div>
			<Modal size='xl' show={showTest} centered onHide={() => { setShowTest(false) }} animation={false}>
				<Modal.Header>
					<Modal.Title>Test your Conditions</Modal.Title>
					<div className=' btn--small btn--icon' onClick={() => { setShowTest(false) }}>
                    				<span className="icon-close icon-size-24"></span>
                			</div>
				</Modal.Header>
				<Modal.Body>
					<div className='row'>
						<div className='col-4 form-group'>
							{Object.keys(distinctEntities.current).map((entity, index) => getEntity(entity, index))}
						</div>
						<div className='col-8' style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)', borderRadius: '25px', border: getBorder(node.data, true) }}>
							<div className="base-margin">
								<button className="btn btn--small btn--secondary" onClick={() => { let id = 0; if (node.data.conditions.length > 0) { node.data.conditions[node.data.conditions.length - 1].operator = 'and'; id = node.data.conditions[node.data.conditions.length - 1].id + 1 } node.data.conditions.push({ condition: { entityName: systemEntityList[0], entityCondition: operators[4], entityValue: '0' }, id }); if (!distinctEntities.current[systemEntityList[0].label]) distinctEntities.current[systemEntityList[0].label] = '0'; setForceReRender(!forceReRender) }} >Add Condition</button>
								<button className="btn btn--small btn--secondary" onClick={() => { let id = 0; if (node.data.conditions.length > 0) { node.data.conditions[node.data.conditions.length - 1].operator = 'and'; id = node.data.conditions[node.data.conditions.length - 1].id + 1 } node.data.conditions.push({ group: [], id }); setForceReRender(!forceReRender) }} >Add Group</button>
							</div>
							{node.data.conditions.map((condition, index, array) => getCondition(condition, index, array, true))}
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</div>
	</div>
}

export default ConditionNodeDetails;
