import { useEffect } from 'react';
import { useState } from 'react';
import './EntityComponent.css';

const Entity = ({ entity_list, skillReadyOnly, onEntityClicked = () => { }, onEntityChecked = () => { }, isEntitySelected, selectedEntityIndex, entityCheckboxListFromParent }) => {

    const [entityCheckboxList, setEntityCheckboxList] = useState([]);

    function truncateStrings(str, length) {
        if (length > str.length) {
            return str + " ".repeat(length - str.length);
        }
        return str.substring(0, length);
    }

    useEffect(() => {
        setEntityCheckboxList(() => {
            let temp = [];
            for (let i = 0; i < entityCheckboxListFromParent.length; i++) {
                temp.push(entityCheckboxListFromParent[i]);
            }
            return temp;
        })

    }, [entityCheckboxListFromParent])
		
    const EntityRow = ({ entity, skillReadyOnly, onCustomEntityClicked, entityIndex }) => {
        if (entity["description"] === undefined) {
            entity["description"] = "";
        }
        let background = "white";
        let titleColor = "black";
        let descriptionColor = "#6c757d";

        if (isEntitySelected && entityIndex == selectedEntityIndex) {
            background = "#1e4471";
            titleColor = "white";
            descriptionColor = "rgb(210,210,210)";
        }

        return (
            <div className='row entity-card panel panel--raised' style={{ "background": background, "color": titleColor }}>
                <div className='row entity-cardRow' onClick={() => { onCustomEntityClicked(); }}>
                    <div className='col-sm-6 col-md-6 col-6 col-lg-6 col-xl-6 col-xxl-6 col-xxl-6 entity-name'>
                        {truncateStrings(entity["name"], 200)}{entity["name"].length > 200 ? "..." : ""}
                    </div>
                </div>
                <div className='row entity-cardRow'>
                    <div className='col-sm-11 col-md-11 col-11 col-lg-11 col-xl-11 col-xxl-11 col-xxl-11 entity-description' onClick={() => { onCustomEntityClicked(); }} style={{ "color": descriptionColor }}>
                        {truncateStrings(entity["description"], 100)}{entity["description"].length > 100 ? "..." : ""}
                    </div>
                </div>
                <div className='entity-checkbox'>
                    {
                        (() => {
                            if (entityCheckboxList[entityIndex]) {
                                return (
                                    <input type="checkbox" style={{ "height": "20px", "width": "20px" }} checked onClick={() => {
                                        setEntityCheckboxList((prev) => {
                                            let newList = [];
                                            for (let i = 0; i < prev.length; i++) {
                                                if (entityIndex === i) {
                                                    newList.push(!prev[i]);
                                                }
                                                else {
                                                    newList.push(prev[i]);
                                                }
                                            }
                                            onEntityChecked(newList);
                                            return newList;
                                        })
                                    }} />
                                )
                            }
                            else {
                                return (
                                    <input type="checkbox" style={{ "height": "20px", "width": "20px" }} 
										onClick={() => {
											setEntityCheckboxList((prev) => {
												let newList = [];
												for (let i = 0; i < prev.length; i++) {
													if (entityIndex === i) {
														newList.push(!prev[i]);
													}
													else {
														newList.push(prev[i]);
													}
												}
												onEntityChecked(newList);
												return newList;
											})
										}} 
										disabled={skillReadyOnly}
										/>
                                )
                            }
                        })()
                    }

                </div>
            </div>
        )


    }

    return (
        <div style={{ "height": "100%" }}>
            {
                entity_list.map((entity, index) => {
                    return <EntityRow entity={entity} skillReadyOnly={skillReadyOnly} entityIndex={index} onCustomEntityClicked={() => { onEntityClicked(entity, index) }}></EntityRow>
                })
            }
        </div>
    )

}

export default Entity;
