import { useDispatch } from 'react-redux';
import { updateAnalyticsAccessToken } from './redux/analyticsSlice';


//Monkey patch the fetch function to add authorization header
const BotLiteHTTPInterceptor = ()=>{
    const { fetch: originalFetch } = window;
    const authPingUrl = `${process.env.REACT_APP_ROOT_URL}/authPing`;
    const accessTokenUrl = `${process.env.REACT_APP_ROOT_URL}/okta/accessToken`;
    const analyticsAccessTokenUrl = `${process.env.REACT_APP_ROOT_URL}/analytics/accessToken`;
    const code_challenge_url = `${process.env.REACT_APP_ROOT_URL}/okta/getLoginRedirectURL`
    const reduxDispather = useDispatch();
    const uid = ()=>{
      return Date.now().toString(36) + Math.random().toString(36).substr(2);
    }
    const generateAnalyticsAccessToken = ()=>{
      // generate a new analytics access token after regenerating the api access token
      fetch(analyticsAccessTokenUrl).then((res)=>res.json()).then((res)=>{
        let analyticsAccessToken = res["token"];
        if(analyticsAccessToken === "unauthorised"){
          fetch(accessTokenUrl).then((res)=>res.json()).then((res)=>{
            if(res["status"] !== "success"){
              // could not regenerate access token
              // redirect user to login
              fetch(code_challenge_url).then((res) => res.json()).then((res) => {
                localStorage.setItem("code_challenge", res["code_challenge"]);
                localStorage.removeItem("isFragmentUsed");
                localStorage.setItem("authLock","free");
                window.location.href = res["login_url"];
              })
            }else{
              // regenerate analytics access token when access token is refreshed
              generateAnalyticsAccessToken();
              localStorage.setItem("authLock","free");
            }
          },(err)=>{
            console.log(err);
            fetch(code_challenge_url).then((res) => res.json()).then((res) => {
                localStorage.setItem("code_challenge", res["code_challenge"]);
                localStorage.removeItem("isFragmentUsed");
                localStorage.setItem("authLock","free");
                window.location.href = res["login_url"];
            })
          })
        }
        else{
          reduxDispather(updateAnalyticsAccessToken(analyticsAccessToken));
          localStorage.setItem("authLock","free");
        }
        
      });
    }

    window.fetch = async (...args) => {
      
      let [resource, config ] = args;

      if(config){

        if (!config.headers){
          config.headers = new Headers();
        }
        
  
      }
  

      const response = await originalFetch(resource, config);


      //if response return 4XX/un-authorised then logout the user and redirect him to login url
      if(response.status >= 400 && response.status < 500){
        setTimeout(()=>{

          let apiCallUid = uid();
          let authLock = localStorage.getItem("authLock");

          if(authLock && authLock == "locked"){
            return;
          }
          localStorage.setItem("authLock","locked");
          localStorage.setItem("lockededUID",apiCallUid);
          setTimeout(()=>{
            let winnerAPICallUID = localStorage.getItem("lockededUID");
            if(!(winnerAPICallUID && winnerAPICallUID === apiCallUid)){
              return;
            }
            
            // API might throw 401 if user is not authorised to access some resource,
            // but user's Token might still be active
            // so call authPing to vefiy that
            let reqHeaders = new Headers();
            reqHeaders.append("Content-Type", "application/json");
            let requestOptions = {
              method: "GET",
              headers: reqHeaders
            }
  
            fetch(authPingUrl, requestOptions).then(res => res.json()).then((res)=>{
              if(res["params"]["header"]["Regenerate-Access-Token"] === "true"){
                // add part of logic to redirect him to login url again even if one of the API call fails with status code 4XX
                fetch(accessTokenUrl).then((res)=>res.json()).then((res)=>{
                  if(res["status"] !== "success"){
                    // could not regenerate access token. Possible Rfresh token is expire or not present
                    // redirect user to login
                    fetch(code_challenge_url).then((res) => res.json()).then((res) => {
                      localStorage.setItem("code_challenge", res["code_challenge"]);
                      localStorage.removeItem("isFragmentUsed");
                      localStorage.setItem("authLock","free");
                      window.location.href = res["login_url"];
                  })
                  }else{
                    // regenerate analytics access token when access token is refreshed
                    generateAnalyticsAccessToken();
                  }
                },(err)=>{
                  console.log(err);
                  fetch(code_challenge_url).then((res) => res.json()).then((res) => {
                    localStorage.setItem("code_challenge", res["code_challenge"]);
                    localStorage.removeItem("isFragmentUsed");
                    localStorage.setItem("authLock","free");
                    window.location.href = res["login_url"];
                })
                })
              }
              
            })
          }, Math.floor(Math.random() * 100))
          

        },Math.floor(Math.random() * 100))

        

      }

      return response;
  };

}

export default BotLiteHTTPInterceptor;