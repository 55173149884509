import './ActionNode.css';
import { Handle, Position, useStore, useReactFlow, getConnectedEdges } from 'react-flow-renderer';
import { useEffect, useState } from 'react';
import DialogService from '../../../../dialog-service/DialogService';
import { setDeletedNodes } from '../../../dialog-view-component/DialogViewComponent';

const ConnectableFn = (id, direction) => {
	const edgesCount = useStore((store) => store.edges.length);
	const { getNode, getEdges } = useReactFlow();

	const [hasConnections, setHasConnections] = useState(false);

	useEffect(() => {
		setHasConnections(() => {
			const edges = getConnectedEdges([getNode(id)], getEdges()).filter(
				(e) => e[direction] === id
			);

			return edges.length > 0;
		});
	}, [getNode, getEdges, id, edgesCount]);

	return hasConnections;
};
function ActionNode({ id, data, xPos, yPos }) {
	const [type, setType] = useState(data.type)
	const [forceReRender, setForceReRender] = useState(false)
	const { getNodes, getEdges, setNodes, setEdges } = useReactFlow();
	const nodes = getNodes();
	const edges = getEdges();

	useEffect(() => {
		setType(data.type)
	}, [])

	useEffect(() => {
		editActionFunction()
	})

	function updateReRender() {
		setForceReRender(!forceReRender)
	}

	function switchAPI() {
		setType('api')
	}

	function switchCode() {
		setType('code')
	}

	function switchDB() {
		setType('db')
	}

	function switchLink() {
		setType('link')
	}

	const editActionFunction = () => {
		if (document.getElementById(`node${id}name`))
			document.getElementById(`node${id}name`).onkeyup = updateReRender
		for (let element of document.getElementsByClassName(`node${id}switchAPIButton`))
			element.onmouseup = switchAPI
		for (let element of document.getElementsByClassName(`node${id}switchCodeButton`))
			element.onmouseup = switchCode
		for (let element of document.getElementsByClassName(`node${id}switchDBButton`))
			element.onmouseup = switchDB
		for (let element of document.getElementsByClassName(`node${id}switchLinkButton`))
			element.onmouseup = switchLink
	}

	console.log("data.showConfirmation", data.showConfirmation)
	function makeAPI() {
		data = { method: 'GET', URL: '', headers: [], payload: [], params: [] }
	}

	function makeDB() {
		data = { db: '', query: '' }
	}

	function makeLinkTo() {
		data.link = ''
	}

	const onDragOver = event => event.preventDefault();

	const onDrop = event => {
		event.preventDefault();
		const augmentData = event.dataTransfer.getData('augment');
		if (augmentData === 'action') {
			data.setSelected()
			const type = event.dataTransfer.getData('action');
			if (type === 'API')
				makeAPI()
			else if (type === 'DB')
				makeDB()
			else if (type === 'LinkTo')
				makeLinkTo()
		}
	};


	const hasSourceConnections = ConnectableFn(id, "source");

	const deleteEdges = (id) => {
		let connectedEdges = []
		for (let i of edges) {

			if (i.target == id || i.source == id) {
				connectedEdges.push(i)
			}
		}
		console.log("edges", edges)
		console.log("connectedEdges", connectedEdges)

		const remainingEdges = edges.filter((edge) => !connectedEdges.includes(edge));
		const remainingNodes = nodes.filter((node) => node.id != id)

		console.log("remainingEdges", remainingEdges)
		console.log("remainingNodes", remainingNodes)

		let targetId = ''
		let getedges = {}
		let temp = []

		for (let i of connectedEdges) {
			if (i.target == id) {
				getedges = i;
			}
			if (i.source == id) {
				targetId = i['target'];
			}
		}
		if (connectedEdges.length == 2) {
			getedges['target'] = targetId
			temp = [...remainingEdges, getedges];
		}
		else {
			temp = [...remainingEdges]
		}
		console.log("temp", temp)

		const finalList = [...temp]
		if (remainingNodes.length == 1) {
			setEdges([])
		}
		else {
			setEdges(finalList)
		}

		setNodes(remainingNodes)
		console.log(remainingNodes)
		console.log(finalList)
	}

	const deleteNodeById = (id) => {
		let node_values = { id, data, position: { x: xPos, y: yPos }, type: "ActionNode" }
		setDeletedNodes(node_values)
		DialogService.deleteNodeById(data.skill, data.rule, id).then(res => res.json()).then(res => {
			if (res.status == 'success') {
				deleteEdges(id)
			}
			else {
				console.log(id + " doesn't exists" == res.details)
				console.log(res)
				if (res.details == id + " doesn't exists") {
					console.log("insidde")
					data.showConfirmation = false
					deleteEdges(id)
				}
				// setDataLoading(false);
				// setBannerMessageType('error');
				// setBannerMessage(res['details']);
				// setShowMessageBanner(true);
			}
		}).catch(err => {
			// setDataLoading(false);
			// setBannerMessageType('error');
			// setBannerMessage('Something went wrong. Please try again later!');
			// setShowMessageBanner(true);
		})
	}
	const truncateStrings = (str, length) => {
		if (str) {
			if (length > str.length) {
				return str
			}
			else {
				str = str.substring(0, length) + '...'
				return str;
			}
		}
		else {
			return '';
		}
	}

	return (
		<div className='nodeContainer actionNodeContainer' onDragOver={onDragOver} onDrop={onDrop}>
			{data.showConfirmation == true &&
				<div style={{ margin: '3px' }}>
					<div style={{ fontSize: '0.8em' }}>
						Are you sure you want to delete the Action Node - {data.name}?
					</div>
					<div className='text-center' style={{ marginTop: '2px' }}>
						<button className="btn btn--small btn--secondary" style={{ fontSize: '0.8em' }} onClick={() => { data.showConfirmation = false; }} >
							No
						</button>
						<button className="btn  btn--small btn--secondary" style={{ fontSize: '0.8em' }} onClick={() => { deleteNodeById(id) }} >
							Yes
						</button>
					</div>
				</div>
			}
			<div className='node' id={`node${id}`}>
				<Handle
					type='source'
					position={Position.Bottom}
					id='actionSourceHandle'
					isConnectable={!hasSourceConnections}
				/>
				<Handle
					type='target'
					position={Position.Top}
					id='actionTargetHandle'
				/>
				{data.showConfirmation == false && <><div>
					<div className='nodeType'>
						Action
					</div>
					<div className='nodeDeleteType' >
						<span className='icon-delete' onClick={() => { console.log(id); data.showConfirmation = true }}></span>
					</div>
					<div className='nodeCount'>
						{type}
					</div>
				</div>
					<div className='nodeName'>
						{truncateStrings(data.name, 20)}
					</div></>}
			</div>
		</div>
	);
}

export default ActionNode;
