import React, { useState, useEffect } from 'react';
import EmailChip from '../email-chip/EmailChip';
import isAlphanumeric from 'validator/lib/isAlphanumeric';
import MessageBanner from '../MessageBanner/MessageBanner';
import ScheduleService from '../../modules/schedule-module/schedule-service/ScheduleService';
import Spinner from '../spinner/Spinner';
export const ScheduleForm = (props) => {

    // const [selected_item, setSelectedItem] = useState(defaultValue);

    // useEffect(() => {
    //     setSelectedItem(defaultValue);
    // }, [defaultValue])
    const frequencyList = [{ 'time': 900, 'frequency': 'Every 15 Mins' }, { 'time': 1800, 'frequency': 'Every 30 Mins' }, { 'time': 3600, 'frequency': 'Hourly' }, { 'time': 86400, 'frequency': 'Daily' }, { 'time': 604800, 'frequency': 'Weekly' }, { 'time': 1209600, 'frequency': 'Fortnightly' }, { 'time': 2592000, 'frequency': 'Monthly' }, { 'time': 7776000, 'frequency': 'Quarterly' }, { 'time': 15811200, 'frequency': 'Twice a year' }, { 'time': 31536000, 'frequency': 'Yearly' }];

    //
    // Need to write a backend API to get all time zone list
    //
    const [timeZoneList, setTimeZoneList] = useState([]);

    //default selection to UTC
    const defaultTimeZone = 'Europe/London';
    const [timeZone, setTimeZone] = useState(defaultTimeZone);

    //default selection to 15 mins
    const defaultFrequency = 900;
    const [frequency, setFrequency] = useState(defaultFrequency);
    const [roomIdList, setRoomIdList] = useState([]);
    const [userCecList, setUserCecList] = useState([]);
    const [myIdGroupsList, setMyIdGroupsList] = useState([]);
    const [isDataLoading, setDataLoading] = useState(false);
    const [showValidationErrorMessage, setShowValidationErrorMessage] = useState(false);
    const [validationErrorMessage, setValidationErrorMessage] = useState('');
    const [messageType, setMessageType] = useState('error')
    const [startDate, setStartDate] = useState(new Date().toISOString().split('T')[0])
    const [startHour, setStartHour] = useState('00')
    const [startMinute, setStartMinute] = useState('00')
    const [endDate, setEndDate] = useState(new Date().toISOString().split('T')[0])
    const [endHour, setEndHour] = useState('00')
    const [endMinute, setEndMinute] = useState('00')
    const [recursionDay, setRecursionDay] = useState([])

    useEffect(() => {
        // console.log(result)
        // if (result == undefined || (Object.keys(result).length === 0 && result.constructor === Object)) {
        //     console.log('inside if');
        // }
        // else {
        //     populateScheduleDetails();
        // }
        console.log("props2", props)
        if (props.skill && props.action && props.skill.value != 'default' && props.action.value != 'default') {
            console.log("props3", props.action)
            console.log("props4", props.skill)
            getScheduleDetails()
        } else {



        }

    }, [props.action])

    const clearScheduleForm = () => {
        setFrequency(900)
        setRoomIdList([])
        setUserCecList([]);
        setMyIdGroupsList([]);
        setStartDate(new Date().toISOString().split('T')[0])
        setStartHour('00')
        setStartMinute('00')

        setEndDate(new Date().toISOString().split('T')[0])
        setEndHour('00')
        setEndMinute('00')
        setRecursionDay([])
    }

    const getScheduleDetails = () => {
        console.log('imnside the function')
        setDataLoading(true);
        setShowValidationErrorMessage(false);
        setValidationErrorMessage('')
        setMessageType('success')
        clearScheduleForm();
        ScheduleService.getScheduledAction(props.skill.value, props.action.value).then(res => res.json()).then(res => {
            console.log('imnside the call')
            if (res.status == 'success') {
                setDataLoading(false);
                if (res.result == []) {
                    clearScheduleForm();
                    return
                }
                console.log('imnside the success')
                setDataLoading(false);
                let result = res['schedule_actions']
                setFrequency(result['freq'])
                console.log(result)
                setRoomIdList([...result['webex_rooms_ids']])
                console.log([...result['userlist_schedule']])
                setUserCecList([...result['userlist_schedule']]);

                let temp_date_start = result['start_time'].split(' ')
                let temp_date_time = temp_date_start[1].split(':')
                console.log("Date", new Date(temp_date_start[0]).toISOString().split('T')[0])
                setStartDate(new Date(temp_date_start[0]).toISOString().split('T')[0])
                console.log(temp_date_start)
                setStartHour(temp_date_start[2] == 'am' ? (temp_date_time[0] == '12' ? '00' : temp_date_time[0]) : temp_date_time[0] == '12' ? '12' : parseInt(temp_date_time[0]) + 12)
                setStartMinute(temp_date_time[1])

                let temp_date_end = result['end_time'].split(' ')
                let temp_date_time_end = temp_date_end[1].split(':')
                setEndDate(new Date(temp_date_end[0]).toISOString().split('T')[0])
                setEndHour(temp_date_end[2] == 'am' ? (temp_date_time_end[0] == '12' ? '00' : temp_date_time_end[0]) : temp_date_time_end[0] == '12' ? '12' : parseInt(temp_date_time_end[0]) + 12)
                setEndMinute(temp_date_time_end[1])
                if (result['freq'] == 604800) {
                    console.log(result['weekdays'])
                    setRecursionDay(result['weekdays'])
                }

                console.log(result['userlist_schedule'])
                setMyIdGroupsList([...result['myidgroup_schedule']]);

            }
            else {
                setShowValidationErrorMessage(true);
                setDataLoading(false);
                setValidationErrorMessage(res['details'])
                setMessageType('error')
                // setDataLoading(false);
                // setBannerMessage(res['details'])
                // setBannerMessageType('error')
                // setShowMessageBanner(true);
            }
        }).catch(err => {
            console.log(err)
            setShowValidationErrorMessage(true);
            setDataLoading(false);
            setValidationErrorMessage("Failed to retrieve schedule details. Please try again later!")
            setMessageType('error')
            // setDataLoading(false);
            // setBannerMessage("Failed to retrieve schedule details. Please try again later!")
            // setBannerMessageType('error')
            // setShowMessageBanner(true);
        })

    }
    // const populateScheduleDetails = () => {
    //     setFrequency(result.schedule_action_list['freq'])
    //     setRoomIdList([...result['webexRooms']])
    //     setUserCecList([...result['userlist_schedule']]);
    //     setMyIdGroupsList([...result['myidgroup_schedule']]);

    //     let temp_date_start = result['start_time'].split(' ')
    //     setStartDate(new Date(temp_date_start).toISOString().split('T')[0])
    //     setStartHour(temp_date_start[2] == 'am' ? (temp_date_start[1] == '12' ? '00' : temp_date_start[1].split(':')[0]) : parseInt(temp_date_start[1]) + 12)
    //     setStartMinute(temp_date_start[1].split(':')[1])

    //     let temp_date_end = result['end_time'].split(' ')
    //     setEndDate(new Date(temp_date_end[0]).toISOString().split('T')[0])
    //     setEndHour(temp_date_end[2] == 'am' ? (temp_date_end[1] == '12' ? '00' : temp_date_end[1].split(':')[0]) : parseInt(temp_date_end[1]) + 12)
    //     setEndMinute(temp_date_end[1].split(':')[1])

    //     setRecursionDay(result['weekdays'])
    //     console.log(result['userlist_schedule'])
    // }
    const validateScheduleFormData = () => {
        setShowValidationErrorMessage(false);
        setValidationErrorMessage('');
        setMessageType('success');
        setDataLoading(true);
        if (roomIdList.length === 0 && userCecList.length === 0 && myIdGroupsList.length === 0) {
            setShowValidationErrorMessage(true);
            setValidationErrorMessage('Room ID or User CEC or MyIdGroup should be added');
            setMessageType('error')
            setDataLoading(false);
            return false;
        }

        for (let roomId of roomIdList) {
            roomId = roomId.replaceAll(/-/g, "");
            if (!isAlphanumeric(roomId)) {
                setValidationErrorMessage('Room ID can not have any special characters other than -');
                setShowValidationErrorMessage(true);
                setMessageType('error')
                setDataLoading(false);
                return false;
            }
        }
        for (let useCec of userCecList) {
            if (!isAlphanumeric(useCec)) {
                setValidationErrorMessage('User CEC can not have any special characters');
                setShowValidationErrorMessage(true);
                setMessageType('error')
                setDataLoading(false);
                return false;
            }
        }
        for (let myIdGroup of myIdGroupsList) {
            myIdGroup = myIdGroup.replaceAll(/[-@.]/g, "");
            if (!isAlphanumeric(myIdGroup)) {
                setValidationErrorMessage('MyID Group can not have any special character other than @, . and -');
                setShowValidationErrorMessage(true);
                setMessageType('error')
                setDataLoading(false);
                return false;
            }
        }
        if (startDate == undefined) {
            setValidationErrorMessage('Enter the start date');
            setShowValidationErrorMessage(true);
            setMessageType('error')
            setDataLoading(false);
            return false;
        } if (endDate == undefined) {
            setValidationErrorMessage('Enter the end date');
            setShowValidationErrorMessage(true);
            setMessageType('error')
            setDataLoading(false);
            return false;
        }
        if (startDate > endDate) {
            setValidationErrorMessage('Start Date cant be greater than End Date');
            setShowValidationErrorMessage(true);
            setMessageType('error')
            setDataLoading(false);
            return false;
        }
        if (startDate == endDate && startHour > endHour) {

            setValidationErrorMessage('Start Date cant be greater than End Date');
            setShowValidationErrorMessage(true);
            setMessageType('error')
            setDataLoading(false);
            return false;
        }
        // props.onSubmit({
        //     roomIds: roomIdList,
        //     frequency: frequency,
        //     userCecList: userCecList,
        //     myIdGroupsList: myIdGroupsList,
        //     timeZone: timeZone
        // });
        return true;
    }

    useEffect(() => {
        const tzAPIUrl = process.env.REACT_APP_ROOT_URL + "/getTimezoneList";

        var requestOptions = {
            method: 'GET'
        };
        fetch(tzAPIUrl, requestOptions).then((res) => res.json()).then((res) => {
            if (res["status"] === "success") {
                let temp = [];
                for (let tz of res["timezones"]) {
                    temp.push([
                        tz["name"],
                        tz["value"]
                    ])
                }
                temp = temp.sort((s1, s2) => {
                    return s1[0].localeCompare(s2[0])
                })
                setTimeZoneList(temp);
            }

        }, (err) => {
            console.log(err)
        })
    }, [])

    const clearForm = () => {
        setFrequency(900)
        setRoomIdList([])
        setUserCecList([]);
        setMyIdGroupsList([]);

        setStartDate(new Date().toISOString().split('T')[0])
        setStartHour('00')
        setStartMinute('00')

        setEndDate(new Date().toISOString().split('T')[0])
        setEndHour('00')
        setEndMinute('00')

        setRecursionDay([])
    }


    const schedule = () => {
        if (validateScheduleFormData()) {
            setShowValidationErrorMessage(false);
            setDataLoading(true);
            let temp_start_ampm = 'am';
            let start_hour = parseInt(startHour);
            if (start_hour > 11) {
                temp_start_ampm = "pm";
                start_hour = start_hour - 12;
            }
            else if (start_hour == 0) {
                start_hour = 12;
            }

            let temp_end_ampm = 'am';
            let end_hour = parseInt(endHour)
            if (end_hour > 11) {
                temp_end_ampm = "pm"
                end_hour = end_hour - 12
            }
            else if (end_hour == 0) {
                end_hour = 12;
            }
            let payload = {
                "type": "ActionNode",
                "flag_scheduler": "True",
                "weekdays": recursionDay,
                "start_time": startDate + " " + start_hour + ":" + startMinute + " " + temp_start_ampm,
                "end_time": endDate + " " + end_hour + ":" + endMinute + " " + temp_end_ampm,
                "bot_id": props.bot.value,
                "webexRooms": roomIdList,
                "userlist_schedule": userCecList,
                "myidgroup_schedule": myIdGroupsList,
                "time_zone": timeZone,
                "freq": frequency
            }
            console.log(payload)
            if (props.type == 'actionType') {
                ScheduleService.scheduleAction(props.skill.value, props.action.value, payload).then(res => res.json()).then(res => {
                    if (res['status'] == 'success') {
                        setShowValidationErrorMessage(true);
                        setDataLoading(false);
                        setValidationErrorMessage("Action: " + props.action.label + " is successfully scheduled")
                        setMessageType('success')
                        clearForm();
                        getScheduleDetails();
                    }
                    else {
                        setShowValidationErrorMessage(true);
                        setDataLoading(false);
                        setValidationErrorMessage(res['details'])
                        setMessageType('error')
                    }
                }).catch(err => {
                    setShowValidationErrorMessage(true);
                    setDataLoading(false);
                    setValidationErrorMessage('Failed to Schedule the action. Please try again later!')
                    setMessageType('error')
                })
            }
        }


    }

    const stopSchedule = () => {
        setDataLoading(true);
        if (props.type == 'actionType') {
            let payload = {
                "type": "ActionNode",
                "flag_scheduler": "False",
                "bot_id": props.bot.value
            }
            ScheduleService.scheduleAction(props.skill.value, props.action.value, payload).then(res => res.json()).then(res => {
                if (res['status'] == 'success') {
                    setShowValidationErrorMessage(true);
                    setDataLoading(false);
                    setValidationErrorMessage(res['details'])
                    setMessageType('success')
                    clearScheduleForm();
                }
                else {
                    setShowValidationErrorMessage(true);
                    setDataLoading(false);
                    setValidationErrorMessage(res['details'])
                    setMessageType('error')
                }
            }).catch(err => {
                setShowValidationErrorMessage(true);
                setDataLoading(false);
                setValidationErrorMessage('Failed to stop the scheduled action. Please try again later!')
                setMessageType('error')
            })
        }
    }

    const disablePastDate = () => {
        const today = new Date();
        const dd = String(today.getDate()).padStart(2, "0");
        const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        const yyyy = today.getFullYear();
        const dateString = yyyy + "-" + mm + "-" + dd;
        return dateString;
    };
    const minuteOptions = ["00", "15", "30", "45"];
    const hoursOptions = ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"];


    return <div class="base-margin-top">
        {isDataLoading && <Spinner></Spinner>}
        <div>
            {showValidationErrorMessage && <MessageBanner isPersistant={true} message={validationErrorMessage} messageType={messageType} onCloseClicked={() => { setShowValidationErrorMessage(false); setValidationErrorMessage(''); setMessageType('error') }}></MessageBanner>}

            <div class="form-group">
                <div class="form-group__text select base-margin-bottom">
                    <select id="select-schedule-frequency" onChange={(e) => { setFrequency(e.target.value) }} value={frequency}>
                        {
                            frequencyList.map((value) => {
                                if (value.time === defaultFrequency) {
                                    return (
                                        <option value={value.time} selected>{value.frequency}</option>
                                    )
                                }
                                else {
                                    return (
                                        <option value={value.time}>{value.frequency}</option>
                                    )
                                }
                            })
                        }

                    </select>
                    <label for="select-schedule-frequency">Frequency*</label>
                </div>
                <div className='row'>
                    <div className="col form-group__text">
                        <label for="input-size-default">
                            Start Date, Hour and Minutes
                        </label>
                        <div className='row base-margin-bottom' style={{ "marginLeft": "0" }}>
                            <div style={{ "width": "130px", "marginRight": "2px" }} >
                                <input id="input-size-default" type="date" onChange={(e) => { setStartDate(e.target.value) }} min={disablePastDate()} value={startDate} />
                            </div>
                            <div style={{ "width": "50px", "marginRight": "10px" }}>
                                <select id="notification-hours" onChange={(e) => { setStartHour(e.target.value) }} value={startHour}>
                                    {
                                        hoursOptions.map((val) => {
                                            return (<option value={val} >{val}</option>)
                                        })
                                    }
                                </select>
                            </div>

                            <div style={{ "width": "50px" }}>
                                <select id="notification-minutes" onChange={(e) => { setStartMinute(e.target.value) }} value={startMinute}>
                                    {
                                        minuteOptions.map((val) => {
                                            return (<option value={val}>{val}</option>)
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className="col form-group__text">
                        <label for="input-size-default">
                            End Date, Hour and Minutes
                        </label>
                        <div className='row base-margin-bottom' style={{ "marginLeft": "0px" }}>
                            <div style={{ "width": "130px", "marginRight": "2px" }}>
                                <input id="input-size-default" type="date" onChange={(e) => { setEndDate(e.target.value) }} min={disablePastDate()} value={endDate} />
                            </div>
                            <div style={{ "width": "50px", "marginRight": "10px" }}>
                                <select id="notification-hours" onChange={(e) => { setEndHour(e.target.value) }} value={endHour}>
                                    {
                                        hoursOptions.map((val) => {
                                            return (<option value={val}>{val}</option>)
                                        })
                                    }
                                </select>
                            </div>

                            <div style={{ "width": "50px" }}>
                                <select id="notification-minutes" onChange={(e) => { setEndMinute(e.target.value) }} value={endMinute}>
                                    {
                                        minuteOptions.map((val) => {
                                            return (<option value={val}>{val}</option>)
                                        })
                                    }
                                </select>
                            </div>
                        </div>


                    </div>
                    {frequency == 604800 && <div className="col form-group__text">
                        <label for="input-size-default">
                            Recursion
                        </label>
                        <div className="btn-group btn-group--square btn-group--small ">
                            <button
                                className={
                                    "btn btn--ghost btn--secondary" +
                                    (recursionDay && recursionDay.includes(0) ? " selected" : "")
                                }
                                onClick={() => {
                                    console.log(recursionDay && recursionDay.includes(0))
                                    if (recursionDay && recursionDay.includes(0)) {
                                        let temp = recursionDay;
                                        temp = temp.filter(function (item) {
                                            return item !== 0
                                        })
                                        console.log(temp)
                                        setRecursionDay(temp);
                                    }
                                    else {
                                        setRecursionDay([...recursionDay, 0]);
                                    }
                                }}
                            >
                                S
                            </button>
                            <button
                                className={
                                    "btn btn--ghost btn--secondary" +
                                    (recursionDay && recursionDay.includes(1) ? " selected" : "")
                                }
                                onClick={() => {
                                    if (recursionDay && recursionDay.includes(1)) {
                                        let temp = recursionDay;
                                        temp = temp.filter(function (item) {
                                            return item !== 1
                                        })
                                        console.log(temp)
                                        setRecursionDay(temp);
                                    }
                                    else {
                                        setRecursionDay([...recursionDay, 1]);
                                    }

                                }}
                            >
                                M
                            </button>
                            <button
                                className={
                                    "btn btn--ghost btn--secondary" +
                                    (recursionDay && recursionDay.includes(2) ? " selected" : "")
                                }
                                onClick={() => {
                                    if (recursionDay && recursionDay.includes(2)) {
                                        let temp = recursionDay;
                                        temp = temp.filter(function (item) {
                                            return item !== 2
                                        })
                                        console.log(temp)
                                        setRecursionDay(temp);
                                    }
                                    else {
                                        setRecursionDay([...recursionDay, 2]);
                                    }

                                }}
                            >
                                T
                            </button>
                            <button
                                className={
                                    "btn btn--ghost btn--secondary" +
                                    (recursionDay && recursionDay.includes(3) ? " selected" : "")
                                }
                                onClick={() => {
                                    if (recursionDay && recursionDay.includes(3)) {
                                        let temp = recursionDay;
                                        temp = temp.filter(function (item) {
                                            return item !== 3
                                        })
                                        console.log(temp)
                                        setRecursionDay(temp);
                                    }
                                    else {
                                        setRecursionDay([...recursionDay, 3]);
                                    }

                                }}
                            >
                                W
                            </button>
                            <button
                                className={
                                    "btn btn--ghost btn--secondary" +
                                    (recursionDay && recursionDay.includes(4) ? " selected" : "")
                                }
                                onClick={() => {
                                    if (recursionDay && recursionDay.includes(4)) {
                                        let temp = recursionDay;
                                        temp = temp.filter(function (item) {
                                            return item !== 4
                                        })
                                        console.log(temp)
                                        setRecursionDay(temp);
                                    }
                                    else {
                                        setRecursionDay([...recursionDay, 4]);
                                    }

                                }}
                            >
                                T
                            </button>
                            <button
                                className={
                                    "btn btn--ghost btn--secondary" +
                                    (recursionDay && recursionDay.includes(5) ? " selected" : "")
                                }
                                onClick={() => {
                                    if (recursionDay && recursionDay.includes(5)) {
                                        let temp = recursionDay;
                                        temp = temp.filter(function (item) {
                                            return item !== 5
                                        })
                                        console.log(temp)
                                        setRecursionDay(temp);
                                    }
                                    else {
                                        setRecursionDay([...recursionDay, 5]);
                                    }

                                }}
                            >
                                F
                            </button>
                            <button
                                className={
                                    "btn btn--ghost btn--secondary" +
                                    (recursionDay && recursionDay.includes(6) ? " selected" : "")
                                }
                                onClick={() => {
                                    if (recursionDay && recursionDay.includes(6)) {
                                        let temp = recursionDay;
                                        temp = temp.filter(function (item) {
                                            return item !== 6
                                        })
                                        console.log(temp)
                                        setRecursionDay(temp);
                                    }
                                    else {
                                        setRecursionDay([...recursionDay, 6]);
                                    }

                                }}
                            >
                                S
                            </button>
                        </div>
                    </div>}
                </div>


                <div class="form-group__text ">
                    <label for="schedule-room-id">Room Id </label>
                    <EmailChip id="schedule-room-id" placeholder="Type the Room ID and hit enter or click on + to add it to the list" email_list={roomIdList} onDataChanged={setRoomIdList}></EmailChip>
                </div>

                <div class="form-group__text base-margin-top">
                    <label for="schedule-user-cec">User CEC</label>
                    <EmailChip id="schedule-user-cec" placeholder="Type the User CEC and hit enter or click on + to add it to the list" email_list={userCecList} onDataChanged={setUserCecList}></EmailChip>
                </div>

                <div class="form-group__text base-margin-top">
                    <label for="schedule-myid-groups">MyID Groups</label>
                    <EmailChip id="schedule-myid-groups" placeholder="Type the MyID Groups and hit enter or click on + to add it to the list" email_list={myIdGroupsList} onDataChanged={setMyIdGroupsList}></EmailChip>
                </div>

                <div class="form-group__text select base-margin-top">
                    <select id="select-schedule-timezone" onChange={(e) => { setTimeZone(e.target.value) }}>
                        <option value={'default'} disabled>Select a Timezone</option>
                        {
                            timeZoneList.map((value) => {
                                if (value[1] === defaultTimeZone) {
                                    return (
                                        <option value={value[1]} key={value[1]} selected>{value[0]}</option>
                                    )
                                }
                                else {
                                    return (
                                        <option value={value[1]} key={value[1]}>{value[0]}</option>
                                    )
                                }

                            })
                        }
                    </select>
                    <label for="select-schedule-timezone">Time Zone*</label>
                </div>
                <hr />
                <div class="select base-margin-top " style={{ marginLeft: '33%' }}>
                    <button className="btn btn--secondary" disabled={props && ((props.bot && props.bot.value == 'default') || (props.skill && props.skill.value == 'default') || (props.action && props.action.value == 'default'))} onClick={() => { schedule(); }}>Start Schedule</button>
                    <button className="btn  btn--primary" disabled={props && ((props.bot && props.bot.value == 'default') || (props.skill && props.skill.value == 'default') || (props.action && props.action.value == 'default'))} onClick={() => { stopSchedule(); }}>Stop Schedule</button>
                </div>
            </div>
        </div>
        <div>

        </div>

    </div>

}

// ScheduleForm.defaultProps = {
//     onSubmit: (data) => { console.log(data) }
// }

export default ScheduleForm;
