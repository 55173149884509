import "./Sidebar.css";

import { Link, useLocation } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import SchemaIcon from '@mui/icons-material/Schema';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import EqualizerIcon from '@mui/icons-material/Equalizer';

const Sidebar = () => {
  const navBarSelectionCSS = { background: "rgb(17 40 67)", boxShadow: "inset -3px 0px 0px 0px #00bceb" };
  const location = useLocation();
  const [showSkillsSubMenu, setShowSkillsSubMenu] = useState(false);
  const [showPlatformMetrics, setShowPlatformMetrics] = useState(true);
  const [showPlatformMetricsMenu, setShowPlatformMetricsMenu] = useState(false);

  const [activeTab, setActiveTab] = useState(() => {
    if (location.pathname === "/") return "bot-screen";
    // else if (location.pathname === "/entity") return "entity-screen";
    else if (location.pathname === "/integration") return "integration-screen";
    // else if (location.pathname === "/intents") return "intents-screen";
    else if (location.pathname === "/skills") return "skills-screen";
    else if (location.pathname === "/training") return "training-screen";
    else if (location.pathname === "/metrics") return "metrics-screen";
    else if (location.pathname === "/user") return "usermanagement-screen";
    else return "analytics-screen";
  });

  const [layoutType, setLayoutType] = useState(
    window.innerWidth > 799.9 ? "verticle" : "horizontal"
  );

  const handleResize = () => {
    setLayoutType(window.innerWidth > 799.9 ? "verticle" : "horizontal");
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
  }, []);

  if (layoutType === "verticle") {
    return (
      <nav
        className="col-lg-3 col-xl-2 sidebar sidebar--mini"
        role="navigation"
      >
        <div className="sidebar__header">
          <a href="#">
            <span className="icon-toggle-menu"></span>
          </a>
          <div className="sidebar__header-title">Title Area</div>
        </div>
        <ul>
          <li className="sidebar-item" style={location.pathname === "/" ? navBarSelectionCSS : {}}>
            <Link to="/" tabIndex="0">
              <span className="icon-bdb"></span>
              <span>Bots</span>
            </Link>
          </li>
          <li className="sidebar-item" style={location.pathname === "/skills" ? navBarSelectionCSS : {}}>
            <Link to="/skills" tabIndex="0">
              <span className="icon-devices"></span>
              <span>Skills</span>
            </Link>
          </li>
          {/* <li
            className={
              (showSkillsSubMenu ? "sidebar__drawer--opened " : "") +
              "sidebar__drawer"
            }
            onClick={() => {
              setShowSkillsSubMenu(showSkillsSubMenu ? false : true);
              setShowPlatformMetricsMenu(false);
            }}
          >
            <a tabIndex="0">
              <span className="icon-devices"></span>
              <span>Skill</span>
            </a>
            <ul style={{ backgroundColor: "#1e4471" }}>
              <li className="sidebar-item">
                <Link to="/skills" tabIndex="0">
                  <span>
                    <span className="icon-applications"></span> Skill View
                  </span>
                </Link>
              </li>
              <li className="sidebar-item">
                <Link to="/entity" tabIndex="0">
                  <span>
                    <span className="icon-text-color"></span> Entities
                  </span>
                </Link>
              </li>
              <li className="sidebar-item">
                <Link to="/intents" tabIndex="0">
                  <span>
                    <span className="icon-features"></span> Intents
                  </span>
                </Link>
              </li>
              <li className="sidebar-item">
                <Link to="/dialog" tabIndex="0">
                  <span className="row">
                    <span className="col-3"><SchemaIcon fontSize="large" /> </span>
                    <span className="" >Dialogue View</span>
                  </span>
                </Link>
              </li>
            </ul>
          </li> */}
          <li className="sidebar-item" style={location.pathname === "/faq" ? navBarSelectionCSS : {}}>
            <Link to="/faq" tabIndex="0">
              <span><QuestionAnswerIcon fontSize="large" /></span>
              <span style={{ fontSize: '0.8em' }}>FAQ Bot</span>
            </Link>
          </li>

          <li className="sidebar-item" style={location.pathname === "/training" ? navBarSelectionCSS : {}}>
            <Link to="/training" tabIndex="0">
              <span className="icon-insights"></span>
              <span>Training</span>
            </Link>
          </li>
          <li className="sidebar-item" style={location.pathname === "/integration" ? navBarSelectionCSS : {}}>
            <Link to="/integration" tabIndex="0">
              <span className="icon-link"></span>
              <span>Integration</span>
            </Link>
          </li>
          <li className="sidebar-item" style={location.pathname === "/skillLibrary" ? navBarSelectionCSS : {}}>
            <Link to="/skillLibrary" tabIndex="0">
              <span className="icon-applications"></span>
              <span>Skill Library</span>
            </Link>
          </li>
          <li className="sidebar-item" style={location.pathname === "/analytics/bot" ? navBarSelectionCSS : {}}>
            <Link to="/analytics/bot" tabIndex="0">
              <span className="icon-google-analytics"></span>
              <span>Analytics</span>
            </Link>
          </li>
          {/* {showPlatformMetrics ? (
            <li
              className={
                (showPlatformMetricsMenu ? "sidebar__drawer--opened " : "") +
                "sidebar__drawer"
              }
              onClick={() => {
                setShowPlatformMetricsMenu(
                  showPlatformMetricsMenu ? false : true
                );
                setShowSkillsSubMenu(false);

              }}
            >
              <a tabIndex="0">
                <span className="icon-google-analytics"></span>
                <span>Analytics</span>
              </a>
              <ul style={{ backgroundColor: "#1e4471" }}>
                <li className="sidebar-item">
                  <Link to="/analytics" tabIndex="0">
                    <span className="row" style={{ marginTop: '8px' }}>
                      <span style={{ marginRight: '3px' }}><EqualizerIcon fontSize="large" /></span>
                      <span style={{ fontSize: '0.95em' }}>Bot Insights</span>
                    </span>
                  </Link>
                </li>
                <li className="sidebar-item">
                  <Link to="/metrics" tabIndex="0">
                    <span className="row" style={{ marginTop: '8px' }}>
                      <span style={{ marginRight: '3px' }}><QueryStatsIcon fontSize="large" /></span>
                      <span style={{ fontSize: '0.95em' }}>Platform Metrics</span>
                    </span>
                  </Link>
                </li>
              </ul>
            </li>
          ) : (
            <li className="sidebar-item">
              {/* <Link to="/analytics" tabIndex="0">
                <span className="icon-google-analytics"></span>
                <span>Analytics</span>
              </Link> 
            </li>
          )} */}

          <li className="sidebar-item" style={location.pathname === "/notification" ? navBarSelectionCSS : {}}>
            <Link to="/notification" tabIndex="0">
              <span className="icon-broadcast-message"></span>
              <span>Notification</span>
            </Link>
          </li>
          <li className="sidebar-item" style={location.pathname === "/user" ? navBarSelectionCSS : {}}>
            <Link to="/user" tabIndex="0">
              <span className="icon-profile-settings"></span>
              <span>Settings</span>
            </Link>
          </li>
          <li className="sidebar-item" style={location.pathname === "/schedule" ? navBarSelectionCSS : {}}>
            <Link to="/schedule" tabIndex="0">
              <span className="icon-clock"></span>
              <span>Schedule</span>
            </Link>
          </li>
          {/* <li className="sidebar-item">
            <Link to="/approval" tabIndex="0">
              <span className="icon-checklist"></span>
              <span>Approvals</span>
            </Link>
          </li> */}
        </ul>
        <div className="askbotlite-icon">
          {/* <Link to="https://botlite-sdk.cisco.com/?id=64C7B71E62DA33BF20F98057&persistChat=false" target="_blank" rel="noopener noreferrer" tabIndex="0">
            <img src={process.env.PUBLIC_URL + "/images/common-images/Chatbot.png"} alt="AskBotLite"></img>
          </Link> */}
           <img src={process.env.PUBLIC_URL + "/images/common-images/Chatbot.png"} alt="AskBotLite"
           onClick={() => {
            window.open(
              "https://botlite-sdk.cisco.com/?id=64C7B71E62DA33BF20F98057&persistChat=false",
              "_blank"
            );
          }}
          style={{cursor: "pointer"}}
           ></img>
          
        </div>
      </nav>
    );
  } else {
    //horizontal orientation
    // for mobile view
    return (
      <div>
        <ul className="tabs tabs--centered mobile-nav-bar sidebar">
          <li
            id="bot-screen-li"
            className={"tab " + (activeTab === "bot-screen" ? "active" : "")}
          >
            <Link to="/" tabIndex="0">
              <div className="navbar-element">
                <div>
                  <span className="icon-bdb"></span>
                </div>
                <div>
                  <span>Bots</span>
                </div>
              </div>
            </Link>
          </li>
          <li
            id="analytics-screen-li"
            className={
              "tab " + (activeTab === "skills-screen" ? "active" : "")
            }
          // id="entity-screen-li"
          // className={
          //   "tab " +
          //   (activeTab === "entity-screen" || activeTab === "intents-screen"
          //     ? "active"
          //     : "")
          // }
          // onClick={() => {
          //   setShowSkillsSubMenu(showSkillsSubMenu ? false : true);
          // }}
          >


            {/* <Link to={location.pathname} tabIndex="0">
              <div className="navbar-element">
                <div>
                  <span className="icon-devices"></span>
                </div>
                <div>
                  <span>Skill</span>
                  <div
                    className={
                      (showSkillsSubMenu ? "active " : "") +
                      "form-group dropdown"
                    }
                    style={{ position: "fixed", zIndex: "100" }}
                  >
                    <div className="form-group__text">
                      <label></label>
                    </div> */}
            {/* <div className="dropdown__menu">
                      <Link to="/skills" tabIndex="0">
                        <div className="navbar-element">
                          <div>
                            <span>
                              <span
                                className="icon-applications"
                                style={{
                                  paddingRight: "10px",
                                  margin: "0px 8px",
                                }}
                              ></span>{" "}
                              Skill View
                            </span>
                          </div>
                        </div>
                      </Link>
                      <Link to="/entity" tabIndex="0">
                        <div className="navbar-element">
                          <div>
                            <span>
                              <span
                                className="icon-text-color"
                                style={{
                                  paddingRight: "10px",
                                  margin: "0px 8px",
                                }}
                              ></span>
                              Entity View
                            </span>
                          </div>
                        </div>
                      </Link>
                      <Link to="/intents" tabIndex="0">
                        <div className="navbar-element">
                          <div>
                            <span>
                              <span
                                className="icon-features"
                                style={{
                                  paddingRight: "10px",
                                  margin: "0px 8px",
                                }}
                              ></span>{" "}
                              Intents View
                            </span>
                          </div>
                        </div>
                      </Link>
                    </div> */}
            {/* </div>
      </div>
              </div >
            </Link > */}

            <Link to="/skills" tabIndex="0">
              <div className="navbar-element">
                <div>
                  <span className="icon-devices"></span>
                </div>
                <div>
                  <span>Skills</span>
                </div>
              </div>
            </Link>
          </li >
          <li
            id="analytics-screen-li"
            className={
              "tab " + (activeTab === "training-screen" ? "active" : "")
            }
          >
            <Link to="/training" tabIndex="0">
              <div className="navbar-element">
                <div>
                  <span className="icon-insights"></span>
                </div>
                <div>
                  <span>Training</span>
                </div>
              </div>
            </Link>
          </li>

          <li
            id="notification-screen-li"
            className={
              "tab " + (activeTab === "integration-screen" ? "active" : "")
            }
          >
            <Link to="/integration" tabIndex="0">
              <div className="navbar-element">
                <div>
                  <span className="icon-link"></span>
                </div>
                <div>
                  <span>Integration</span>
                </div>
              </div>
            </Link>
          </li>
          <li
            id="notification-screen-li"
            className={
              "tab " + (activeTab === "skill-library-screen" ? "active" : "")
            }
          >
            <Link to="/skillLibrary" tabIndex="0">
              <div className="navbar-element">
                <div>
                  <span className="icon-applications"></span>
                </div>
                <div>
                  <span>Skill Library</span>
                </div>
              </div>
            </Link>
          </li>
        </ul >
      </div >
    );
  }
};

export default Sidebar;
