import { useState, useEffect } from 'react';
import './RuleNode.css';
import SearchSelect from '../../../../../../custom-components/search-select/SearchSelect';
import FileDropZone from '../../../../../../custom-components/file-drop-zone/FileDropZone';
import EntityEditPanel from '../../../../../entity-module/entity-component/entity-edit-component/EntityEditComponent'
import Modal from 'react-bootstrap/Modal';
import MessageBanner from "../../../../../../custom-components/MessageBanner/MessageBanner";
import isAlphanumeric from 'validator/lib/isAlphanumeric';
import CheckUncheck from '../ActionNode/Checklist';
import IntentService from '../../../../../intent-module/intent-service/IntentService';
import InputSelect from '../../../../../../custom-components/CreatableSelect/CreatableSelect';
import ExpressionService from '../../../../../intent-module/expression-service/ExpressionService';

// let disabledCTT = true;
function RuleNodeDetails({ skill, node, closeFunction, expressions, getIntentList, intentList, entityList, setShowMessageBanner, setBannerMessage, setBannerMessageType, setDataLoading }) {
    const [selectedTab, setSelectedTab] = useState('rule')
    const [showCreateIntent, setShowCreateIntent] = useState(false)
    const [showCreateEntities, setShowCreateEntities] = useState(false)
    const [submitIntentBtnEnable, setSubmitIntentBtnEnable] = useState(false)
    const [counter, setCounter] = useState(true)
    const [intentData, setIntentData] = useState({ intentName: '', intentDescriptions: '', expressions: [] })
    const [entityData, setEntityData] = useState({ name: '', description: '', values: [], roles: [] })
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [expression, setExpression] = useState('')
    const [expressions_list, setExpressions] = useState(expressions)
    const [forceReRender, setForceReRender] = useState(false)
    const [showValidationErrorMessage, setShowValidationErrorMessage] = useState(false);
    const [dataValidationErrorMessage, setDataValidationErrorMessage] = useState('');
    const [showIntentNEntityValidationErrorMessage, setShowIntentNEntityValidationErrorMessage] = useState(false);
    const [dataIntentNEntityValidationErrorMessage, setDataIntentNEntityValidationErrorMessage] = useState('');
    const [showExpressionValidationErrorMessage, setShowExpressionValidationErrorMessage] = useState(false);
    const [dataExpressionValidationErrorMessage, setDataExpressionValidationErrorMessage] = useState('');
    const [newNodeInfo, setNewNodeInfo] = useState({})
    const [paraphraseExpressions, setParaphrasingExpressions] = useState([])
    const [tab_selected, switchUploadTab] = useState('form-upload');
    let expressionId = 0
    const getExpressionId = () => expressionId++;
    const [fileUploadData, setFileUploadData] = useState([]);
    const [checkedAll, setCheckedAll] = useState(false)
    const [loading, setLoading] = useState(false)
    const [showSlots, setShowSlots] = useState(Array(node.data.slots.length).fill(false))
    const [prompt, setPrompt] = useState('')
	const [showBanner, setShowBanner] = useState(true)

    useEffect(() => {
        let expressionsCopy = [...expressions_list];
        for (let element of fileUploadData) {
            if (element["value"] && element["value"].length > 0) {
                expressionsCopy.push({
                    "id": getExpressionId(),
                    "value": element["value"],
                    "name": element["value"],
                    "isChecked": false,
                    "entities": element["entities"]
                });
            }

        }
        setExpressions(expressionsCopy);
    }, [fileUploadData])

    useEffect(() => {
        if (node.data.targeted) {
            let temp = node.data;
            temp = {
                "slots": node.data.slots,
                "name": node.data.name,
                "description": node.data.description,
                "open": node.data.open,
                "skill": node.data.skill,
                "rule": node.data.rule,
                "showConfirmation": node.data.showConfirmation,
                "targeted": node.data.targeted
            }
            console.log("temp inside useeffect", temp);
            node = { ...node, "data": { ...temp } };
            console.log("temp inside useeffect2", node.data);
        }
    }, [node.data])

    if (localStorage.getItem('intent_name') && localStorage.getItem('intent_value') && !node.data.targeted) {
        console.log("inside this setting it back")
        node.data.intent = { label: localStorage.getItem('intent_name'), value: localStorage.getItem('intent_value') }
        node.data.entity = node.data.entity == undefined ? { label: 'Select an Entity', value: 'default' } : node.data.entity;
        console.log(node.data)
    }

    const displayBannerMessage = (messageType, message) => {
        setShowMessageBanner(true);
        setBannerMessageType(messageType);
        setBannerMessage(message);
    }

    const modifySlot = (id, key, value) => {
        if (key === 'regex' && !updateRegexSlots(value)) {
            console.log('The regular expression is not valid')
            return
        }
        for (let slot of node.data.slots)
            if (id === slot.id)
                slot[key] = value
        if (key === 'name') {
            for (let entity of entities)
                if (value === entity.label) {
                    for (let slot of node.data.slots)
                        if (id === slot.id) {
                            slot.type = 'entity'
                            // slot.save_as = 'var_e_' + slot.save_as.substring(6)
                        }
                    setForceReRender(!forceReRender)
                    return;
                }
            for (let slot of node.data.slots)
                if (id === slot.id) {
                    slot.type = 'custom'
                    // slot.save_as = 'var_c_' + slot.save_as.substring(6)
                }
        }
        setForceReRender(!forceReRender)
    }

    const closeSplit = () => { closeFunction() }
    console.log("entityList", entityList)
    const [entities, setEntities] = useState(entityList)
    const [entities_slots, setEntitySlots] = useState(entityList)
    // useEffect(() => {
    //     // let count = 0
    //     // let temp = []
    //     // for (let i of entityList) {
    //     //     if (count == 0) {
    //     //         count = 1
    //     //         continue
    //     //     }
    //     //     temp.push({ label: i.label, value: i.label, roles: i.roles })
    //     // }
    //     // setEntitySlots(temp)
    //     // console.log(temp)
    // }, [entityList])

    const [role_slots, setRoleSlots] = useState([])
    // const [role_slots, setRoleSlots] = useState(entityList[0].role);


    // const [intents, setIntents] = useState(intentList);

    const findIntent = (data) => {
        if (data.value == 'newIntent')
            return true
        return false
    }

    const findEntity = (data) => {
	    for (let element of data)
        	if (element.value == 'newEntity')
            		return true
        return false
    }
    // const findDuplicateIntent = (data) => {
    //     for (let intent of intentList) {
    //         if (intent.label == data)
    //             return true
    //     }
    //     return false

    // }
    // const findDuplicateEntity = (data) => {
    //     for (let entity of entities) {
    //         if (entity.label == data)
    //             return true
    //     }
    //     return false

    // }

    const updateRegexSlots = regex => {
        let isValid = true;
        try {
            new RegExp(regex);
        } catch (e) {
            isValid = false;
        }
        return isValid
    }

    // const updateEntities = data => {
    //     if (data['__isNew__']) {
    //         setShowCreateEntities(true);
    //     }
    // }
    const deleteSlot = (slotid) => {
        let elementPos = node.data.slots.map(function (x) { return x.id; }).indexOf(slotid);
        if (node.data.slots.length >= 1) {
            node.data.slots.splice(elementPos, 1);
            showSlots.splice(elementPos, 1)
        }
    }

    const getRoles = (roles) => {
        let temp = []
        for (let r of roles) {
            temp.push({ label: r, value: r })
        }
        setRoleSlots(temp)
    }

    const displaySlot = (slot, index) =>
        <li key={index} className='base-margin-top base-margin-bottom' id={`vleft-${slot.id}`}>
            <a className='accordion__title' onClick={() => { showSlots[index] = !showSlots[index]; setForceReRender(!forceReRender) }}>
                <span>{slot.save_as === '' ? `New Slot ${slot.id + 1}` : slot.type == 'custom' ? 'var_c_' + slot.save_as : 'var_e_' + slot.save_as}</span>

                <span className={`accordion__toggle ${showSlots[index] ? 'icon-chevron-up' : 'icon-chevron-down'}`}><button className={`btn btn--small node${node.id}deleteSlotButton`} style={{ float: 'left', marginRight: '10px' }} onClick={() => { deleteSlot(slot.id); setForceReRender(!forceReRender) }}>Delete Slot</button></span>

            </a>
            <div style={{ display: showSlots[index] ? 'block' : 'none' }}>
                <div className='base-margin' id={`node${node.id}slot${slot.id}`}>
                    <label className="switch">
                        <input type="checkbox" checked={slot.required} onChange={event => { modifySlot(slot.id, 'required', event.target.checked); setForceReRender(!forceReRender) }} />
                        <span className='switch__label'>Required</span>
                        <span className="switch__input"></span>
                    </label>
                    <div>
                        <div className="form-group base-margin-top base-margin-bottom">
                            <div className="form-group__text">
                                <label htmlFor={`node${node.id}slot${slot.id}entityName`}>
                                    <span>Entity</span>
					<span title="entity" className='required'>*</span>
                                </label>
                            </div>
                            <InputSelect
                                id={`node${node.id}slot${slot.id}entityName`}
                                className={`node${node.id}slotEntityName`}
                                defaultValue={{ label: slot.name, value: slot.name }}
                                options={entities}
                                onValueChange={data => {
                                    modifySlot(slot.id, 'name', data.label); if (slot.type == 'entity') {
                                        getRoles(data.roles)
                                    }
                                }}
                            />
                        </div>
                        {slot.type === 'entity' && <div className="form-group base-margin-top base-margin-bottom">
                            <div className="form-group__text">
                                <label htmlFor={`node${node.id}slot${slot.id}entityName`}>
                                    <span>Role</span>
                                </label>
                            </div>
                            <InputSelect
                                id={`node${node.id}slot${slot.id}entityName`}
                                className={`node${node.id}slotEntityName`}
                                defaultValue={{ label: slot.role, value: slot.role }}
                                options={role_slots}
                                onValueChange={data => { modifySlot(slot.id, 'role', data.label) }}
                            />
                        </div>}
                        <div>
                            <div className="form-group base-margin-bottom base-margin-top">
                                <div className="form-group__text">
                                    <div className="form-group__text slot_box">

                                        <input id="textarea-state-required" rows="3" placeholder="Enter the save as name for entity" defaultValue={slot.save_as} onChange={event => { modifySlot(slot.id, 'save_as', event.target.value) }} required style={{ paddingLeft: '50px' }} />
                                        <b>{slot.type == 'custom' ? 'var_c_' : slot.type == 'entity' ? 'var_e_' : ''}</b>
                                        <label htmlFor="textarea-state-required">
                                            <span>Save As</span>
                                            <span title="saveAs" className='required'>*</span>
                                        </label>
                                    </div>
                                    {/* <input id="textarea-state-required" rows="3" placeholder="Enter the save as name for entity" defaultValue={slot.save_as} onChange={event => { modifySlot(slot.id, 'save_as', event.target.value) }} required />
                                    <label htmlFor="textarea-state-required">
                                        <span>Save As</span>
                                        <span title="saveAs" className='required'>*</span>
                                    </label> */}
                                </div>
                            </div>
                        </div>

                        <div>
                            {slot.type === 'custom' &&
                                <div>
                                    <div className="form-group base-margin-bottom">
                                        <div className="form-group__text">
                                            <textarea id="textarea-state-required" rows="3" placeholder="Enter the Regex" defaultValue={slot.regex} onChange={event => { modifySlot(slot.id, 'regex', event.target.value) }} required></textarea>
                                            <label htmlFor="textarea-state-required">
                                                <span>Enter the Regex for the Custom Entity</span>
                                                <span title="Enter the Regex" className='required'>*</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                        <div>
                            {displaySlotPrompt(slot.id, slot)}
                        </div>
                    </div>
                </div>
            </div>
        </li>

    const closeEntitiesScreen = () => {
        setShowCreateEntities(false);
    }
    const clearIntentForm = () => {
        setIntentData({ intentName: '', intentDescriptions: '', expressions: [] })
        setExpression('')
        setExpressions([])
    }
    const createIntent = () => {
        setDataLoading(true);
        setLoading(true)
        let payload = intentData
        payload['expressions'] = expressions_list;
        payload['skill'] = skill.value

        console.log("data payload", payload)

        IntentService.addIntentExpressions(skill.value, payload).then(res => res.json()).then(res => {
            console.log(res)
            if (res['status'] == 'success') {
                setBannerMessage(res['details'])
                setShowMessageBanner(true)
                setBannerMessageType('success')
                setShowCreateIntent(false);
                clearIntentForm();
                getIntentList(skill);
                setDataLoading(false);
                setLoading(false)
                setExpressions([])
                localStorage.setItem('intent_name', payload.intentName)
                localStorage.setItem('intent_value', res.intent)
                node.data.intent = { 'label': payload.intentName, 'value': res.intent }
            }
            else {
                setBannerMessage(res['details'])
                setShowMessageBanner(true)
                setBannerMessageType('error')
                setDataLoading(false);
                setLoading(false)
                setShowIntentNEntityValidationErrorMessage(true);
                setDataIntentNEntityValidationErrorMessage(res['details'])
            }
        },
            err => {
                setBannerMessage('Something went wrong while creating the intent. Please try again later!')
                setShowMessageBanner(true)
                setBannerMessageType('error')
                setDataLoading(false);
                setLoading(false)
                setShowIntentNEntityValidationErrorMessage(true);
                setDataIntentNEntityValidationErrorMessage('Something went wrong while creating the intent. Please try again later!')
            })
    }

    // const createEntity = () => {
    //     let data = entityData.name
    //     if (findDuplicateEntity(data)) {
    //         setShowErrorMessage(true);
    //     }
    //     else {
    //         let temp = intentList;
    //         temp.push({ label: data, value: data });
    //         setEntities(temp);
    //         setEntity({ label: data, value: data });
    //         setShowErrorMessage(false);
    //         setShowCreateEntities(false)
    //     }
    // }

    const inputIntentHandlerChange = (event, type) => {
        setShowIntentNEntityValidationErrorMessage(false)
        if (type == 'name') {
            setIntentData({ ...intentData, intentName: event.target.value })
        }
        else {
            setIntentData({ ...intentData, intentDescriptions: event.target.value })
        }

        let newdata = { 'name': intentData.intentName ? intentData.intentName : '', 'description': intentData.intentDescriptions ? intentData.intentDescriptions : '' };
        newdata[type] = event.target.value

        const empty = hasEmpty(newdata);
        let valid = validateIntentCreationForm(type, newdata)

        const result = empty && valid
        setSubmitIntentBtnEnable(result)
    }
    const validateIntentCreationForm = (key, intentData) => {
        if (key == "name" || key == "all") {
            if (intentData.name === '' || intentData.name.length < 5) {
                setShowIntentNEntityValidationErrorMessage(true);
                setDataIntentNEntityValidationErrorMessage('Intent name should be greater than 5 characters.');
                return false;
            }

            if (!isAlphanumeric(intentData.name, 'en-US', { "ignore": "_" })) {
                setShowIntentNEntityValidationErrorMessage(true);
                setDataIntentNEntityValidationErrorMessage('Special characters other than _ is not allowed in intent name.');
                return false;
            }
        }
        else if (key == "description" || key == "all") {
            if (intentData.description === '' || intentData.description.length < 20) {
                setShowIntentNEntityValidationErrorMessage(true);
                setDataIntentNEntityValidationErrorMessage('Intent description should be greater than 20 characters.');
                return false;

            }
            if (!validateAlphaNumericString(intentData.description)) {
                setShowIntentNEntityValidationErrorMessage(true);
                setDataIntentNEntityValidationErrorMessage('Special characters other than _ @ . " \' & is not allowed in intent description.');
                return false;
            }
        }
        return true;
    }
    const processFileData = (data) => {
        console.log(data)
        let data_expressions = [];
        for (let d of data) {
            if (d.type === "text/plain") {
                d["data"] = d["data"].trim()
                data_expressions = data_expressions.concat(d["data"].split("\n"));
            }
            else {
                data_expressions = data_expressions.concat(d["data"]);
            }
        }

        data_expressions = handleAnnotation(data_expressions);
        console.log("data_expressions", data_expressions)
        let correct_exp = []
        for (let exp of data_expressions) {
            if (!isAlphanumeric(exp['value'], 'en-US', { "ignore": /[,.!?'"; ]/g })) {
                setShowExpressionValidationErrorMessage(true);
                setDataExpressionValidationErrorMessage('Special characters other than ,.!?\'\"; are not allowed in an expression. Please try to upload another file.');
            }
            else {
                correct_exp.push(exp)
            }
        }
        console.log("correct_exp", correct_exp)
        setFileUploadData(correct_exp);
    }
    const handleAnnotation = (data) => {
        let result = [];
        for (let i = 0; i < data.length; i++) {
            result.push({ "value": data[i], "entities": [] })
            let idx = result.length;
            while (true) {
                let res = result[idx - 1]["value"].match(/{[^}]*}/);
                if (res === undefined || res === null) {
                    break;
                }

                let start = res.index;
                let end = start + res[0].length;
                let entity_value = res.input.slice(start, end).split("|")[0].slice(1);

                let entity_name = res.input.slice(start, end).split("|")[1];
                if (entity_name[entity_name.length - 1] == '}') {
                    entity_name = entity_name.slice(0, -1)
                }
                console.log("eneity_name", entity_name)
                let entity_id = getEntityId(entity_name)

                result[idx - 1]["value"] = res.input.slice(0, start) + entity_value + res.input.slice(end, res.input.length);
                if (entity_id) {
                    result[idx - 1]["entities"].push({ "start": start, "end": start + entity_value.length, "value": entity_value, "entityId": entity_id });
                }

            }
        }
        console.log(result)
        return result;
    }

    const getEntityId = (entity) => {
        const checkEntity = (element) => element.label == entity;

        let index = entityList.findIndex(checkEntity)
        if (index == -1) {
            return false
        }
        return entityList[index].value

    }


    const handleExpressions = () => {
        let isRowSelected = false;
        const changeFunction = index => {
            let expressionsCopy = [...expressions_list]
            const temp = !expressionsCopy[index].isChecked
            expressionsCopy[index].isChecked = temp
            setExpressions(expressionsCopy);
            for (let element of expressionsCopy)
                if (element.isChecked === false) {
                    setCheckedAll(false)
                    return
                }
            setCheckedAll(true)
        }

        const changeAllFunction = () => {
            let expressionsCopy = [...expressions_list]
            let temp = !checkedAll
            for (let element of expressionsCopy)
                element.isChecked = temp
            setExpressions(expressionsCopy)
            setCheckedAll(temp)
        }

        const deleteFunction = () => {
            let expressionsCopy = []
            for (let element of expressions_list)
                if (element.isChecked === false)
                    expressionsCopy.push(element)
            setExpressions(expressionsCopy)
            setCheckedAll(false)
        }

        const paraphraseExpression = () => {
            let expressionsCopy = []
            for (let element of expressions_list)
                if (element.isChecked === true)
                    expressionsCopy.push(element.value)

            // paraphrase(expressionsCopy)
            setCheckedAll(false)
            setLoading(true);
            if (expressionsCopy.length > 20) {
                setLoading(false);
                displayBannerMessage('error', 'Paraphrasing feature is supported only for less than 20 expressions at a time.')
            }
            else {
                let payload = {
                    "queries": expressionsCopy,
                    "numberOfSequence": 10,
                    "max_length": 64,
                    "temperature": 0.95
                }
                console.log(payload)

                ExpressionService.paraphraseExpression(payload).then(res => res.json()).then(res => {
                    console.log(res)
                    if (res.status == 'success') {
                        setCounter(true);
                        let temp = []
                        let count = expressions_list.length
                        for (let expression of res['expressions']) {
                            temp.push({ id: count, name: expression, isChecked: false, annotations: [] })
                            count += 1
                        }

                        for (let element of expressions_list)
                            if (element.isChecked === true)
                                element.isChecked = false

                        let paraphrasedExpressions = expressions_list.concat(temp);
                        setExpressions(paraphrasedExpressions)
                        setLoading(false);
                    }
                    else {
                        setLoading(false);
                        setCounter(true);
                        // setShowParaphraseModal(false);
                        setBannerMessageType('error');
                        setBannerMessage("Something went wrong while paraphrasing. Please try after sometime.");
                        setShowMessageBanner(true);
                    }
                },
                    err => {
                        if (counter) {
                            setCounter(false);
                            paraphraseExpression()
                        }
                        else {
                            setLoading(false);
                            setCounter(true);
                            // setShowParaphraseModal(false);
                            setBannerMessageType('error');
                            setBannerMessage("Something went wrong while paraphrasing. Please try after sometime.");
                            setShowMessageBanner(true);
                        }

                    })
            }
        }

        for (let element of expressions_list) {
            if (element.isChecked === true) {
                isRowSelected = true;
                break;
            }

        }

        return (
            <div className="panel panel--raised">
                {loading && <div  >
                    <div class="subheader">Primary</div>
                    <div class="loading-dots" aria-label="Loading, please wait...">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>}

                <CheckUncheck listname="Expressions" checkboxlist={expressions_list} changeFunction={changeFunction} changeAllFunction={changeAllFunction} checkedAll={checkedAll} >
                    <div className="deletebtn base-margin">
                        <button disabled={isRowSelected === false} className="btn btn--small btn--secondary" onClick={() => { deleteFunction() }} style={{ paddingLeft: "16px", paddingRight: "16px" }}>Delete</button>
                        <button disabled={isRowSelected === false} className='btn btn--small btn--secondary' onClick={() => { paraphraseExpression() }} style={{ paddingLeft: "16px", paddingRight: "16px" }}>Paraphrase the below expressions</button>
                    </div>
                </CheckUncheck>
            </div>
        )
    }

    const createIntentModal = () =>
        <Modal size="lg" centered show={showCreateIntent} onHide={() => { setShowCreateIntent(false) }} animation={false} >
            <Modal.Header >
                <h5>Create an Intent</h5>
                <div className=' btn--small btn--icon' onClick={() => { setShowCreateIntent(false); setShowIntentNEntityValidationErrorMessage(false); setShowExpressionValidationErrorMessage(false); setExpressions([]); node.data.intent = { label: localStorage.getItem('intent_name'), value: localStorage.getItem('intent_value') } }}>
                    <span className="icon-close icon-size-24"></span>
                </div>
            </Modal.Header>
            <Modal.Body>
                {
                    showIntentNEntityValidationErrorMessage && (<MessageBanner message={dataIntentNEntityValidationErrorMessage} messageType={"error"} onCloseClicked={() => { setShowIntentNEntityValidationErrorMessage(false) }}></MessageBanner>)
                }
                <div>
                    <div style={{ display: showCreateIntent ? 'inherit' : 'none' }}>
                        <div key="create_intent"   >

                            <div className="form-group base-margin-bottom">
                                <div className="form-group__text">
                                    <input id="input-type-number" type="text" autoFocus name="intent_name" onChange={event => { inputIntentHandlerChange(event, "name") }} />
                                    <label htmlFor="input-type-number">Intent Name<span className='required'>*</span></label>
                                </div>
                            </div>
                            <div className="form-group base-margin-bottom">
                                <div className="form-group__text">
                                    <textarea id="textarea-state-default" rows={6} name="intent_description" onChange={event => { inputIntentHandlerChange(event, "description") }}></textarea>
                                    <label htmlFor="input-type-number">Intent Description <span className='required'>*</span></label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div >
                        <div className="panel">
                            <h6>Add Expressions <span className='required'>*</span></h6>
				<MessageBanner message={'Please provide at least five different expressions related to the given intent. This will help effectively train the model to identify the intent increasing the efficacy of the chatbot.'}></MessageBanner>
                            <ul className="tabs tabs--justified" style={{ "height": "100%" }}>
                                <li id="static-entity-tab" className={tab_selected == "form-upload" ? "active" : "" + "tab"} onClick={() => switchUploadTab("form-upload")}>
                                    <a tabindex="0">Static</a>
                                </li>
                                <li id="api-entity-tab" className={tab_selected == "file-upload" ? "active" : "" + "tab"} onClick={() => switchUploadTab("file-upload")}>
                                    <a tabindex="0">File Upload</a>
                                </li>
                            </ul>
                            <div className='tab-content'>
                                {
                                    showExpressionValidationErrorMessage && (<MessageBanner message={dataExpressionValidationErrorMessage} messageType={"error"} onCloseClicked={() => { setShowExpressionValidationErrorMessage(false) }}></MessageBanner>)
                                }
                                <div className={tab_selected === "form-upload" ? "active " : "" + 'panel panel--raised tab-pane'}>
                                    <div className="panel base-margin-top">
                                        <div className="row" style={{ "marginLeft": "0px" }}>
                                            <div className="col-10 col-sm-10 col-md-11 col-lg-11 col-xl-11 col-xxl-12 col-xxxl-11 form-group base-margin-bottom">
                                                <div className="form-group__text">
                                                    <input id="input-type-email" type="text" autoComplete='off' value={expression} onChange={event => { setExpression(event.target.value) }} onKeyDown={(e) => {
                                                        if (e.key === 'Enter' && expression.length > 0) {
                                                            setShowExpressionValidationErrorMessage(false);

                                                            if (isAlphanumeric(expression, 'en-US', { "ignore": /[,.!?'"; ]/g })) {
                                                                setExpressions(arr => [...arr, { id: getExpressionId(), value: expression, name: expression, isChecked: false, entities: [] }])
                                                                setExpression('');
                                                            }
                                                            else {

                                                                setShowExpressionValidationErrorMessage(true);
                                                                setDataExpressionValidationErrorMessage('Special characters other than ,.!?\'\"; are not allowed in an expression.');

                                                            }
                                                        }
                                                    }} />
                                                    <label htmlFor="input-type-email">Expression</label>
                                                </div>
                                            </div>
                                            <div className="col-2 col-sm-2 col-md-1 col-lg-1 col-xl-1 col-xxl-1 col-xxxl-1">
                                                <span className={(expression.length == 0 ? "disabled " : "") + "icon-add-outline icon-size-20"} style={{ "marginTop": "25px" }} onClick={() => {
                                                    setShowExpressionValidationErrorMessage(false);
                                                    if (isAlphanumeric(expression, 'en-US', { "ignore": /[,.!?'";{}| ]/g })) {
                                                        setExpressions(arr => [...arr, { id: getExpressionId(), value: expression, name: expression, isChecked: false, entities: [] }])
                                                        setExpression('');
                                                    }
                                                    else {
                                                        setShowExpressionValidationErrorMessage(true);
                                                        setDataExpressionValidationErrorMessage('Special characters other than ,.!?\'\"; are not allowed in an expression.');

                                                    }
                                                }}></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <FileDropZone className={(tab_selected === "file-upload" ? "active " : "") + 'tab-pane base-margin-top base-margin-bottom'} onFileuploaded={(data) => processFileData(data)} allowedFileTypes={["text/csv", "text/plain"]}></FileDropZone>

                            </div>

                            {expressions_list.length > 0 ? handleExpressions() : ""}
                        </div>
                    </div>
                </div>
            </Modal.Body >
            <Modal.Footer>
                {/* <button className="btn btn--secondary" onClick={() => { setShowCreateIntent(false); setExpressions([]) }}>
                    Cancel
                    <span className="icon-close icon-size-18 base-margin-left"></span>
                </button> */}
                <button className="btn btn--secondary" disabled={!submitIntentBtnEnable || expressions_list.length === 0} onClick={() => { console.log(expressions_list.length); createIntent(); }}>
                    Create Intent
                    {/* <span className="icon-save icon-size-18 base-margin-left"></span> */}
                </button>
            </Modal.Footer>
        </Modal >


    const createEntityModal = () =>
        <Modal size="lg" centered show={showCreateEntities} onHide={() => { setShowCreateEntities(false) }} animation={false} >
            <Modal.Header >
                <h5>Create Entity</h5>
                <div className=' btn--small btn--icon' onClick={() => { setShowCreateEntities(false) }}>
                    <span className="icon-close icon-size-24"></span>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div style={{ display: showErrorMessage ? 'inherit' : 'none' }} className="base-margin">
                    <MessageBanner message={"Duplicate Entity Name detected. Please select from an existing list of intents or create with a different name."} messageType={'error'} ></MessageBanner>
                </div>
                <EntityEditPanel entity_object={entityData} onCloseButtonClicked={closeEntitiesScreen} selectedSkill={skill} creationMode={true} setData={(data) => { node.data.entity = data }} dialogCreation={true}></EntityEditPanel>

            </Modal.Body >
        </Modal >

    // const updateEntity = (data) => {
    //     setEntity(data)
    // }
    const inputRuleHandlerChange = (event, type) => {
        setShowValidationErrorMessage(false);
        if (type == 'name')
            node.data.name = event.target.value
        else
            node.data.description = event.target.value
        let newdata = { 'name': node.data.name, 'description': node.data.description };
        newdata[type] = event.target.value
        setNewNodeInfo(obj => { let objCopy = { ...obj }; objCopy[type] = event.target.value; return objCopy })
        const empty = hasEmpty(newdata);
        let valid = validateRuleCreationForm(type, newdata)

        const result = empty && valid
    }
    const hasEmpty = (element) => {
        return (
            (element.name != "" && element.name.length > 1) &&
            (element.description != "" && element.description.length > 19)
        );
    };
    const validateAlphaNumericString = (data) => {
        let sanitisedData = data.replaceAll(/[@.,'"&\s]/g, "");
        return isAlphanumeric(sanitisedData);
    }

    const validateRuleCreationForm = (key, ruleData) => {
        if (key == "name" || key == "all") {
            if (ruleData.name === '' || ruleData.name.length < 5) {
                setShowValidationErrorMessage(true);
                setDataValidationErrorMessage('Rule name should be greater than 5 characters.');
                return false;
            }

            if (!isAlphanumeric(ruleData.name, 'en-US', { "ignore": "_" })) {
                setShowValidationErrorMessage(true);
                setDataValidationErrorMessage('Special characters other than _ is not allowed in rule name.');
                return false;
            }
        }
        else if (key == "description" || key == "all") {
            if (ruleData.description === '' || ruleData.description.length < 20) {
                setShowValidationErrorMessage(true);
                setDataValidationErrorMessage('Rule description should be greater than 20 characters.');
                return false;

            }
            if (!validateAlphaNumericString(ruleData.description)) {
                setShowValidationErrorMessage(true);
                setDataValidationErrorMessage('Special characters other than _ @ . " \' & is not allowed in rule description.');
                return false;
            }
        }
        return true;
    }

    const conditionToTrigger = () =>
        <div>
            <div id={`node${node.id}conditionToTrigger`}>
                <div className='form-group base-margin-left base-margin-right'>
			<MessageBanner><div style={{textAlign: 'justify'}}>The condition to trigger refers to the conditions which need to be fulfilled in order to trigger this rule. This includes exactly one intent and any number (including zero) of entities.<br /><br />The targeted-only property, if checked, makes the rule unreachable through any combination of intents and entities, leaving only the jump functionality of the Jump Node to access the rule.</div></MessageBanner>
                    <label className="switch">
                        <input type="checkbox" checked={node.data.targeted | false} value={node.data.targeted | false} onChange={event => {
                            node.data.targeted = event.target.checked;
                            if (event.target.checked) {
                                let temp = node.data;
                                temp = {
                                    "slots": node.data.slots,
                                    "name": node.data.name,
                                    "description": node.data.description,
                                    "open": node.data.open,
                                    "skill": node.data.skill,
                                    "rule": node.data.rule,
                                    "showConfirmation": node.data.showConfirmation,
                                    "targeted": node.data.targeted
                                }
                                console.log("temp", temp);
                                node = { ...node, "data": { ...temp } };
                                console.log("temp", node.data);
                            }
                            else {
                                let temp = node.data;
                                temp = {
                                    "slots": node.data.slots,
                                    "name": node.data.name,
                                    "description": node.data.description,
                                    "open": node.data.open,
                                    "skill": node.data.skill,
                                    "rule": node.data.rule,
                                    "showConfirmation": node.data.showConfirmation,
                                    "targeted": node.data.targeted,
                                    "intent": { label: 'Select an intent', value: 'default' },
                                    "entity": { label: 'Select an entity', value: 'default' }
                                }
                                console.log("temp", temp);
                                node = { ...node, "data": { ...temp } };
                                console.log("temp", node.data);
                            } setForceReRender(!forceReRender);
                        }} />
                        <span className='switch__label'>Targeted-Only Rule</span>
                        <span className="switch__input"></span>
                    </label>
                </div>
                {node.data.targeted === true ? '' :
                    <div>
                        <br />
                        <div>
                            <div className="form-group base-margin-left base-margin-right">
                                <div className='form-group__text'>
                                    <label htmlFor="intentList">
                                        <span>Intent</span>
                                        <span title="Please Enter the Rule Name" className='required'>*</span>
                                    </label>
                                </div>
                                <SearchSelect
                                    className='augmentSelect'
                                    id='intentList'
                                    placeholder={'Select an Intent'}
                                    defaultValue={{ label: localStorage.getItem('intent_name') && localStorage.getItem('intent_value') ? localStorage.getItem('intent_name') : (node.data.intent ? node.data.intent.label : 'Select an Intent'), value: localStorage.getItem('intent_name') && localStorage.getItem('intent_value') ? localStorage.getItem('intent_value') : (node.data.intent ? node.data.intent.value : 'default') }}
                                    options={intentList}
                                    onValueChange={(data) => {
                                        if (findIntent(data))
                                            setShowCreateIntent(true)
                                        else {
                                            console.log("Inside this")
                                            setShowCreateIntent(false)
                                            node.data.intent = { label: data.label, value: data.value };
                                            // forceReRender(true);
                                            localStorage.setItem('intent_name', node.data.intent.label)
                                            localStorage.setItem('intent_value', node.data.intent.value)
                                            // disabledCTT = false;
                                            setForceReRender(!forceReRender);
                                        }
                                    }}
                                />
                            </div>
                            <hr style={{ display: expressions.length > 0 ? 'inherit' : 'none' }} />
                            <div style={{ display: expressions.length > 0 ? 'inherit' : 'none' }}>
                                <div className='flex'>
                                    <span>Since you have added an expression. Click a create an intent option from the dropdown.</span>
                                </div>
                                <div>{createIntentModal()}</div>
                            </div>
                        </div>
                        <br />
                        <div>
                            <div className="form-group base-margin-left base-margin-right">
                                <div className="form-group__text">
                                    <label htmlFor="entityList">
                                        <span>Entity</span>
                                    </label>
                                </div>
                                {(()=>{
                                    if(node.data && node.data.entity){
                                        if(node.data.entity.value === 'default'){
                                            return(<SearchSelect
                                                        id='entityList'
                                                        placeholder={'Select an Entity'}
                                                        // defaultValue={node.data.entity}
                                                        options={entities_slots}
                                                        isClearable={true}
                                                        multiSelect={true}
                                                        onValueChange={(data) => {
                                                            node.data.entity = data
                                                            if (findEntity(data))
                                                                setShowCreateEntities(true)
                                                            else
                                                                setShowCreateEntities(false)
                                                        }}
                                                    />)
                                        }
                                        else{
                                            return(
                                                <SearchSelect
                                                    id='entityList'
                                                    // placeholder={'Select an Entity'}
                                                    defaultValue={node.data.entity}
                                                    options={entities_slots}
                                                    isClearable={true}
                                                    multiSelect={true}
                                                    onValueChange={(data) => {
                                                        node.data.entity = data
                                                        if (findEntity(data))
                                                            setShowCreateEntities(true)
                                                        else
                                                            setShowCreateEntities(false)
                                                    }}
                                                />
                                            )
                                        }
                                    }
                                    else{
                                        return(
                                            <SearchSelect
                                                id='entityList'
                                                placeholder={'Select an Entity'}
                                                options={entities_slots}
                                                isClearable={true}
                                                multiSelect={true}
                                                onValueChange={(data) => {
                                                    node.data.entity = data
                                                    if (findEntity(data))
                                                        setShowCreateEntities(true)
                                                    else
                                                        setShowCreateEntities(false)
                                                }}
                                            />
                                        )
                                    }
                                })()}
                            </div>
                            <hr style={{ display: showCreateEntities ? 'inherit' : 'none' }} />
                            <div style={{ display: showCreateEntities ? 'inherit' : 'none' }}>
                                {createEntityModal()}

                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    // const displayPrompt = () => {
    //     return <><div className="row" style={{ marginLeft: '1px' }} >
    //         <div className="col-10 col-sm-10 col-md-11 col-lg-11 col-xl-11 col-xxl-12 col-xxxl-11 form-group base-margin-bottom">
    //             <div className="form-group__text">
    //                 <input id="input-type-email" type="text" autoComplete='off' value={prompt} onChange={event => { setPrompt(event.target.value) }} onKeyDown={(e) => {
    //                     if (e.key === 'Enter' && prompt.length > 0) {
    //                         let temp = node.data.prompt
    //                         temp.push(prompt)
    //                         node.data.prompt = temp
    //                         setPrompt('')
    //                     }
    //                 }} />
    //                 <label htmlFor="input-type-email">Prompt Message <span className='required'>*</span></label>
    //             </div>
    //         </div>
    //         <div className="col-2 col-sm-2 col-md-1 col-lg-1 col-xl-1 col-xxl-1 col-xxxl-1">
    //             <span className={(prompt.length == 0 ? "disabled " : "") + "icon-add-outline icon-size-20"} style={{ "marginTop": "25px" }} onClick={() => {
    //                 let temp = node.data.prompt
    //                 temp.push(prompt)
    //                 node.data.prompt = temp
    //                 setPrompt('')
    //                 console.log(node.data.prompt)
    //             }}></span>
    //         </div>
    //     </div>
    //         {
    //             node.data.prompt && node.data.prompt.length > 0 && <div>
    //                 <table className='table table--bordered'>
    //                     {node.data.prompt.map((data, index) => {
    //                         return <tr>
    //                             <td style={{ width: '95%' }}>{data}</td>
    //                             <td onClick={() => {
    //                                 node.data.prompt.splice(index, 1)
    //                             }}><span className='icon-delete'></span></td>
    //                         </tr>
    //                     })}
    //                 </table>

    //             </div>
    //         }</>

    // }
    const displaySlotPrompt = (index, slot) => {
        return <><div className="row" style={{ marginLeft: '1px' }} key={index}>
            <div className="col-10 col-sm-10 col-md-11 col-lg-11 col-xl-11 col-xxl-12 col-xxxl-11 form-group base-margin-bottom">
                <div className="form-group__text">
                    <input id="input-type-email" type="text" autoComplete='off' value={prompt} onChange={e => { setPrompt(e.target.value) }} onKeyDown={e => { if (e.key === 'Enter' && prompt.length > 0) { slot.prompt.push(prompt); setPrompt(''); setForceReRender(!forceReRender) } }} />
                    <label htmlFor="input-type-email">Prompt Message<span className='required'>*</span></label>
                </div>
            </div>
            <div className="col-2 col-sm-2 col-md-1 col-lg-1 col-xl-1 col-xxl-1 col-xxxl-1">
                <span className="icon-add-outline icon-size-20" style={{ "marginTop": "25px" }} onClick={() => { if (prompt.length > 0) { slot.prompt.push(prompt); setPrompt(''); setForceReRender(!forceReRender) } }}></span>
            </div>
        </div>
            {
                slot.prompt.length > 0 && <div>
                    <table className='table table--bordered'>
                        {slot.prompt.map((data, index) => {
                            return <tr>
                                <td style={{ width: '95%' }}>{data}</td>
                                <td onClick={() => {
                                    slot.prompt.splice(index, 1);
                                    setForceReRender(!forceReRender)
                                }}><span className='icon-delete'></span></td>
                            </tr>
                        })}
                    </table>

                </div>
            }</>
    }

    const slotId = () => {
        if (node.data.slots.length)
            return node.data.slots[node.data.slots.length - 1].id + 1
        return 0
    }
    const checkEmptySlots = () => {
        if (node.data.slots != []) {
            for (let i of node.data.slots) {
                if (i.name == '' || i.save_as == '' || i.prompt == [])
                    return true
                if (i.type == 'custom' && i.regex == '')
                    return true
            }
        }
        return false
    }

    return <div className="panel panel--raised panel--bordered">
        <div style={{ position: 'sticky' }}>
            <h6 >Edit Rule Node <span onClick={() => closeSplit()} className='icon-close' style={{ float: 'right' }}></span></h6>
            <hr />
        </div> 
        <div className="stepper-wrapper">
            <div className={`stepper-item rule-stepper-item ${selectedTab === 'rule' ? 'active' : 'completed'}`} onClick={() => { setSelectedTab('rule') }}>
                <div className="step-counter" style={{ borderColor: '#00bceb', backgroundColor: selectedTab === 'rule' ? '#fff' : '#00bceb' }}></div>
                <div className="step-name">Rule Details</div>
            </div>
		<div className={`stepper-item rule-stepper-item ${selectedTab === 'rule' ? '' : selectedTab === 'slot' ? 'completed' : 'active'}`} onClick={() => { setSelectedTab('condition') }}>
                <div className="step-counter" style={{ borderColor: selectedTab === 'rule' ? '#ccc' : '#00bceb', backgroundColor: selectedTab === 'slot' ? '#00bceb' : '#fff' }}></div>
                <div className="step-name">Condition to Trigger</div>
            </div>
                <div className={`stepper-item rule-stepper-item ${selectedTab === 'slot' ? 'active' : ''}`} onClick={() => { setSelectedTab('slot') }}>
                <div className="step-counter" style={{ borderColor: selectedTab === 'slot' ? '#00bceb' : '#ccc' }}></div>
                <div className="step-name">Slots Details</div>
            </div>
        </div>
        <div style={{ maxHeight: '75vh', overflowY: 'auto' }}>
            <div className="panel" style={{ display: selectedTab === 'rule' ? 'block' : 'none', height: '60vh' }}>
                <div className='base-margin-left base-margin-right'>
                    {showValidationErrorMessage && (<div className="popBanner">{dataValidationErrorMessage}</div>)}
                    <div>
                        <div className="form-group base-margin-right base-margin-left">
                            <div className="form-group__text">
                                <input id={`node${node.id}name`} type="text" placeholder="Please Enter the Rule Name" value={node.data.name} onChange={(event) => { node.data.name = event.target.value; inputRuleHandlerChange(event, 'name'); }} required />
                                <label htmlFor={`node${node.id}name`}>
                                    <span>Name</span>
                                    <span title="Please Enter the Rule Name" className='required'>*</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div>
                        <div className="form-group base-margin-left base-margin-right">
                            <div className="form-group__text">
                                <textarea id="textarea-state-required" rows="3" placeholder="Please Enter the Rule Description" value={node.data.description} onChange={event => { inputRuleHandlerChange(event, 'description'); node.data.description = event.target.value }} required></textarea>
                                <label htmlFor="textarea-state-required">
                                    <span>Description</span>
                                    <span title="Please Enter the Rule Description" className='required'>*</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <br />
                <button className='btn  btn-primary  base-margin-right' onClick={() => { setSelectedTab('condition') }} style={{ float: 'right' }} disabled={(node.data.name == '' || node.data.description == '') || showValidationErrorMessage}>Next</button>
                </div>

            </div>
            <div className='panel ' style={{ display: selectedTab === 'condition' ? 'block' : 'none', height: '60vh' }}>
                {intentList.length == 0 && <div className="alert alert--info flex flex-center center base-margin col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12" style={{ "position": "relative", "left": "35%", "transform": "translate(-300px,5px)", "zIndex": "100px" }}>
                    <span className='base-margin'>It seems like no intent has been created. To create one, please click on <b>Create an Intent</b> option from the below intent dropdown. You can also create an intent by clicking on the <b>Intent View</b> in the header or you can click on <b>Intent View</b> from the sidebar menu. In case you need any help, please click on the <b>Help</b> option or converse with the <b>AskBotLite chatbot</b>.</span>
                </div>}

                {entityList.length == 0 && <div className="alert alert--info flex flex-center center base-margin col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12" style={{ "position": "relative", "left": "35%", "transform": "translate(-300px,5px)", "zIndex": "100px" }}>
                    <span className='base-margin'>It seems like no entity has been created. To create one, please click on <b>Create an Entity</b> option from the below entity dropdown. You can also create an entity by clicking on the <b>Entity View</b> in the header or you can click on <b>Entity View</b> from the sidebar menu. In case you need any help, please click on the <b>Help</b> option or converse with the <b>AskBotLite chatbot</b>.</span>
                </div>}
                <div className="base-margin-left base-margin-right base-margin-bottom">
                    <div id="conditionToTrigger">
                        {conditionToTrigger()}
                    </div>
                </div>
                <br />
                <div className='row base-margin-right ' style={{ float: 'right' }}>
                    <button className='btn  btn-primary  base-margin-right' onClick={() => { setSelectedTab('rule') }}>Back</button>
                    <button className='btn  btn-primary  base-margin-right' onClick={() => { setSelectedTab('slot') }} disabled={(node.data.targeted == false || node.data.targeted == undefined) && (node.data.intent === undefined || node.data.intent.value == 'default' || node.data.intent.value == 'newIntent')}>Next</button>
                </div>
            </div>
            <div className="panel base-margin-left base-margin-right" style={{ overflowY: 'auto', height: '60vh', display: selectedTab === 'slot' ? 'block' : 'none'}}>
		<MessageBanner><div style={{textAlign: 'justify'}}>Slots are contextual pieces of information that the bot expects from the user to be able to answer their query. If any required slots are missing, the user will be prompted to enter them. Slots can use existing entities, but custom entities can also be created just for use in slots.</div></MessageBanner>
                <div className='base-margin-left' style={{ textAlign: 'right' }}>
                    <input type='button' id={`node${node.id}addSlotButton`} onClick={() => { node.data.slots.push({ id: slotId(), name: '', role: '', save_as: `Slot ${slotId()}`, type: 'entity', prompt: [], required: false }); showSlots.push(true); setShowBanner(true); setForceReRender(!forceReRender) }} className='btn btn--primary btn--small base-margin-right' value='Add Slot' />
                </div>
                {checkEmptySlots() && showBanner && <MessageBanner message={'Enter all the mandatory fields in the slots created.'} messageType={'error'} onCloseClicked={() => {setShowBanner(false)}}></MessageBanner>}
                <div className="base-margin">
                    <div id="slots">
                        <ul className="accordion accordion--bordered">
                            {node.data.slots.map((slot, index) => displaySlot(slot, index))}
                        </ul>
                    </div>
                    {node.data.slots.length === 0 ? <div>No slots added yet!</div> : ''}
                </div>
                <br />
                <div className='row base-margin-right ' style={{ float: 'right' }}>
                    <button className='btn  btn-primary  base-margin-right' onClick={() => { setSelectedTab('condition') }}>Back</button>
                    <button className='btn  btn-primary  base-margin-right' onClick={() => { closeSplit() }} disabled={checkEmptySlots()}>Finish</button>
                </div>
            </div>
        </div>
    </div >
}

export default RuleNodeDetails;
