import React, { useEffect, useState } from 'react';
import './MessageBanner.css';

const MessageBanner = (props)=>{
    const [layoutType, setLayoutType] = useState(window.innerWidth > 1000 ? 'horizontal' : 'verticle');
    const TIMEOUT = 5000;
    const handleResize = (() => {
        setLayoutType(window.innerWidth > 1000 ? 'horizontal' : 'verticle');
    })
    useEffect(() => {
        window.addEventListener('resize', handleResize);
        if (((props.isPersistant === undefined && props.messageType === "success") || props.isPersistant === false) && props.onCloseClicked) {
            const interval = setInterval(() => {
                //auto close after 5 sec
                props.onCloseClicked();
                clearInterval(interval);
            }, TIMEOUT);
        }


    }, [])

    if (layoutType === "horizontal") {
        if(props.messageType === "error"){
            return(
                <div className="panel">
                    <div className="messageBanner-error">
                        <div style={{display:"flex"}}>
                            <span>{props.children || props.message}</span>
                            <span className="icon-close icon-size-18 closeIcon" onClick={() => { props.onCloseClicked() }}></span>
                        </div>
                    </div>
                </div>
            )
        }
        else if(props.messageType === "success"){
            return(
                <div className="panel">
                    <div className="messageBanner-success">
                        <div style={{display:"flex"}}>
                            <span>{props.children || props.message}</span>
                            <span className="icon-close icon-size-18 closeIcon" onClick={() => { props.onCloseClicked() }}></span>
                        </div>
                    </div>
                </div>
            )
        }
        else{
            return(
                <div className="panel">
                    <div className="messageBanner-info">
                        <span>{props.children || props.message}</span>
                    </div>
                </div>
            )
        }
    }
    else {
        if(props.messageType === "error"){
            return(
                <div className="panel" style={{ "padding": "0px"}}>
                    <div className="messageBanner-error alert row" style={{ "margin": "0px" }}>
                        <div className="col-11 col-sm-11 col-md-11">
                            <div className="alert__message">{props.children || props.message}</div>
                        </div>
                        <div className="col-1 col-sm-1 col-md-1">
                            <span className="icon-close icon-size-24 base-margin-right" onClick={() => { props.onCloseClicked() }}></span>
                        </div>
                    </div>
                </div>
            )
        }
        else if(props.messageType === "success"){
            return(
                <div className="panel" style={{ "padding": "0px"}}>
                    <div className="messageBanner-success alert row" style={{ "margin": "0px" }}>
                        <div className="col-11 col-sm-11 col-md-11">
                            <div className="alert__message">{props.children || props.message}</div>
                        </div>
                        <div className="col-1 col-sm-1 col-md-1">
                            <span className="icon-close icon-size-24 base-margin-right" onClick={() => { props.onCloseClicked() }}></span>
                        </div>
                    </div>
                </div>
            )
        }
        else{
            return(
                <div className="panel" style={{ "padding": "0px"}}>
                    <div className="messageBanner-info alert row" style={{ "margin": "0px" }}>
                        <div className="col-11 col-sm-11 col-md-11">
                            <div className="alert__message">{props.message}</div>
                        </div>
                    </div>
                </div>
            )
        }
    }

}

//setting few default values
MessageBanner.defaultProps = {
    isPersistant: undefined,
    onCloseClicked: ()=>{},
};

export default MessageBanner;