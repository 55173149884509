const schedule_url = process.env.REACT_APP_ROOT_URL

const ScheduleService = {
    getScheduledAction: (skill, action) => {
        var reqHeaders = new Headers();
        reqHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'GET',
            headers: reqHeaders
        };

        return fetch(`${schedule_url}/skill/${skill}/action/${action}/schedule`, requestOptions);
    },
    scheduleAction: (skill, action, data) => {
        var reqHeaders = new Headers();
        reqHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'POST',
            headers: reqHeaders,
            body: JSON.stringify(data)
        };

        return fetch(`${schedule_url}/skill/${skill}/action/${action}/schedule`, requestOptions);
    }
};

export default ScheduleService;
