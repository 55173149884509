import React, { useState, useEffect } from "react";
import "./UserManagementViewComponent.css";
import Spinner from "../../../../custom-components/spinner/Spinner";
import Modal from "react-bootstrap/Modal";
import MessageBanner from "../../../../custom-components/MessageBanner/MessageBanner";
import DataGridComponent from "../../../../custom-components/data-grid/DataGrid";
import MultiSelect from "../../../../custom-components/multi-select/MultiSelect";
import SearchSelect from "../../../../custom-components/search-select/SearchSelect";
import BotService from "../../../bot-module/bot-services/BotService";
import UserManagementService from "../../user-management-service/UserManagementService";
import IntegrationService from "../../../integration-module/integration-service/IntegrationService";
import SkillService from "../../../skill-module/skill-services/SkillService";
import DialogService from "../../../dialog-module/dialog-service/DialogService";
import FAQService from "../../../faq-module/faq-service/FAQService";

const UserManagementView = () => {
  const [userDetails, setUserDetails] = useState(undefined); 
  const [isDataLoading, setDataLoading] = useState(false);
  const [showMessageBanner, setShowMessageBanner] = useState(false);
  const [bannerMessage, setBannerMessage] = useState('');
  const [bannerMessageType, setBannerMessageType] = useState('success');
  const [selectedTab, setSelectedTab] = useState("roleManagement");
  // const [allowListOptions, setAllowListOptions] = useState([
  //   "Action" , "Externalization", , "Integration"
  // ])
  const [allowListOptions, setAllowListOptions] = useState([
    "Action" , "Integration"
  ])
  const [selectedAllowListOption, setSelectedAllowListOption] = useState("Action");
  const [allowListObject, setAllowListObject] = useState([])
  // const [allowListObject, setAllowListObject] = useState([
  //   {"values":[{"id":"webex","name":"webex"}, {"id":"widget","name":"widget"}], "users":[{"value":"abcd@cisco.com", "type":"user"}, {"value":"grp@cisco.com", "type":"MyID Group"}, {"value":"xzy@cisco.com", "type":"user"},{"value":"xzy@cisco.com", "type":"user"},{"value":"xzy@cisco.com", "type":"user"},{"value":"xzy@cisco.com", "type":"user"},{"value":"xzy@cisco.com", "type":"user"}]},
  //   {"values":[{"id":"widget_chathandler","name":"widget_chathandler"}], "users":[ {"value":"grp23@cisco.com", "type":"MyID Group"}, {"value":"jks@cisco.com", "type":"user"}]},
  //   {"values":[{"id":"widget_code","name":"widget_code"}], "users":[{"value":"abcd123@cisco.com", "type":"user"}]}
  // ])
  const [allowListValues, setAllowListValues]= useState([]);
  const [selectedAllowListOptions, setSelectedAllowListOptions] = useState([]);
  //add allow list value should have those values which are not added yet
  //const allowListValues = [{"id":"webex","name":"webex"}, {"id":"widget","name":"widget"},{"id":"widget_chathandler","name":"widget_chathandler"}, {"id":"widget_code","name":"widget_code"}];
  const [inputTextValue, setInputTextValue] = useState('');
  const [externalizationInputTextValue, setExternalizationInputTextValue] = useState('');
  const [addUserInputTextValue, setAddUserInputTextValue] = useState('');
  const [showAllowListModal, setShowAllowListModal] = useState(false);
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [inputType, setInputType] = useState('user');
  const [inputDataList, setInputDataList] = useState([]);
  const [addUserInputDataList, setAddUserInputDataList] = useState([]);
  const [isBotAdmin, setIsBotAdmin] = useState(false);
  const addInputToList = ()=>{
    if(inputTextValue.length > 0){
      setInputDataList((prev)=>{
        let temp = [...prev];
        temp.push({"id":Math.random().toString(16).slice(2),"text":inputTextValue,"type":inputType});
        setInputTextValue('');
        return temp;
      })
    }
  }
  const addExternalizationInputToList = ()=>{
    if(externalizationInputTextValue.length > 0){
      setNewExternalizationEmails((prev)=>{
        let temp = [...prev];
        temp.push({"id":Math.random().toString(16).slice(2),"email":externalizationInputTextValue});
        setExternalizationInputTextValue('');
        return temp;
      })
    }
  }
  const addUserToInputToList = ()=>{
    if(addUserInputTextValue){
      setAddUserInputDataList((prev)=>{
        let temp = [...prev];
        temp.push({"id":Math.random().toString(16).slice(2),"text":addUserInputTextValue,"type":inputType});
        setAddUserInputTextValue('');
        return temp;
      })
    }
  }
  const inputDataListCols = [
    {
      field: 'text',
      headerName: 'Text',
      flex: 1,
      editable: false
    },
    {
      field: 'type',
      headerName: 'Type',
      flex: 0.3,
      editable: false
    }
  ]
  const externalizationInputDataListCols = [
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
      editable: false
    }
  ]
  const [selectedInputRows, setSelectedInputRows] = useState([]);
  const [roleManagementList, setRoleManagementList] = useState([]);
  const roles = [
    {"id":"bot_admin","name":"bot_admin"},{"id":"bot_editor","name":"bot_editor"},{"id":"bot_viewer","name":"bot_viewer"},{"id":"analytics_admin","name":"analytics_admin"}
  ]
  const [newUserForRoleManagement, setNewUserForRoleManagement] = useState('');

  const [bot, setBot] = useState();
  const [bots, setBotList] = useState([]);
  const [skill, setSkill] = useState();
  const [skills, setSkillList] = useState([]);
  const [selectedAllowListUser, setAllowListUser] = useState([]);
  const [botExternalisationStatus, setBotExternalisationStatus] = useState("none");
  const [externalizationEmails, setExternalizationEmails] = useState([]);
  const [selectedExternalizationEmail, setSelectedExternalizationEmail] = useState([]);
  const [newExternalizationEmails, setNewExternalizationEmails] = useState([]);
  const [showAddExternalizationUserEmails, setShowAddExternalizationEmail] = useState(false);
  const [isBotIntegratedWithWebex, setIsBotIntegratedWithWebex] = useState(false);
  const [selectedIndexForAllowListUpdate, setSelectedIndexForForAllowListUpdate] = useState(-1);
  
  useEffect(() => {
    setDataLoading(true);
    setShowMessageBanner(false);
    setBannerMessage('');
    setBannerMessageType('success');
    setAllowListObject([]);
    BotService.listBots()
        .then((res) => res.json())
        .then((res) => {
            if (res["bots"] != undefined) {
                let bot_list = [];
                let bot_integration_details = {};
                let bidFromLS = localStorage.getItem('botSelectedId');
                let botFlg = false;
                let botName = "";
                let botSkills = {};
                for (let bot of res["bots"]) {
                  bot_list.push({ label: bot["display_name"], value: bot["_id"] , skills: bot["skills"]});
                  if(bot["_id"] === bidFromLS){
                    botFlg = true;
                    botName = bot["display_name"];
                    botSkills = bot["skills"];
                  }

                }

                
                if(bidFromLS !== undefined && botFlg){
                  setBot({
                    label: botName,
                    value: bidFromLS,
                    skills: botSkills
                  });
                }
                else{
                  localStorage.setItem("botSelectedId", bot_list[0]["_id"]);
                  setBot(bot_list[0])
                }
                setBotList(bot_list);
                
                setDataLoading(false);

            } else {
                setDataLoading(false);
                setBannerMessageType("error");
                setBannerMessage(res["details"]||"Failed to retrieve bots!");
                setShowMessageBanner(true);
            }
        })
        .catch((err) => {
            setDataLoading(false);
            setBannerMessageType("error");
            setBannerMessage("Failed to retrieve bots!");
            setShowMessageBanner(true);
        });

    FAQService.getUserDetails().then(res=>res.json()).then((res)=>{
      setUserDetails(undefined);
      if(res["status"] === "success"){
        setUserDetails(res["user"])
      }
      else{
        // redirect to login if user deatils not found
        window.location.href =  `${process.env.REACT_APP_CAE_URL}/home`;
      }
    },(err)=>{
      console.log(err);
      window.location.href = `${process.env.REACT_APP_CAE_URL}/home`;
    })
  }, []);

  useEffect(()=>{
    if(bot !== undefined){
      setAllowListObject([]);
      if(selectedTab === "roleManagement"){
        setIsBotAdmin(true);
        setRoleManagementList([]);
        setDataLoading(true);
        setShowMessageBanner(false);
        setBannerMessage('');
        setBannerMessageType('success');
        UserManagementService.getUserRoles(bot["value"]).then((res)=>res.json()).then((res)=>{
          if (res["status"] === "success"){
            if(res["isAdmin"]){
              let temp = [];
              for(let i=0;i<res["user_roles"].length;i++){
                let roles = [];
                for(let j=0;j<res["user_roles"][i]["role"].length;j++){
                  roles.push({
                    "id": res["user_roles"][i]["role"][j],
                    "name": res["user_roles"][i]["role"][j]
                  })
                }
                temp.push({
                  "user":res["user_roles"][i]["user"],
                  "roles":roles
                })
              }
              if(userDetails !== undefined){
                FAQService.getUserDetails().then((res)=>res.json()).then((res)=>{
                  if(res["status"] === "success"){
                    setUserDetails(res["user"]);
                    let userToMove = res["user"]["email"]; // The user name you want to move to the 0th index
  
                    let indexOfUserToMove = -1;
                    for (let i = 0; i < temp.length; i++) {
                      if (temp[i].user === userToMove) {
                        indexOfUserToMove = i;
                        break;
                      }
                    }
        
                    // Check if the user was found
                    if (indexOfUserToMove !== -1) {
                      // Remove the user from its current position
                      let user = temp.splice(indexOfUserToMove, 1)[0];
        
                      // Insert the user object at the 0th index
                      temp.unshift(user);
                    }
        
                    setRoleManagementList(temp);
                    setIsBotAdmin(true);

                  }
                },(err)=>{
                  console.log(err);
                  setDataLoading(false);
                  setBannerMessageType("error");
                  setBannerMessage(res["details"]||"Failed to fetch user details. Please contact botlite team!");
                  setShowMessageBanner(true);
                },(err)=>{
                  console.log(err);
                  setDataLoading(false);
                  setBannerMessageType("error");
                  setBannerMessage(res["details"]||"Failed to fetch user roles. Please contact botlite team!");
                  setShowMessageBanner(true);
                })
              }

            }
            else{
              setIsBotAdmin(false);
            }
            setDataLoading(false);
          }
          else{
            setDataLoading(false);
            setBannerMessageType("error");
            setBannerMessage(res["details"]||"Failed to fetch user roles. Please contact botlite dev team!");
            setShowMessageBanner(true);
            setIsBotAdmin(false);
          }
    
        },(err)=>{
          setDataLoading(false);
          setBannerMessageType("error");
          setBannerMessage("Failed to fetch user roles. Please contact dev team!");
          setShowMessageBanner(true);
          console.log(err)
          setIsBotAdmin(false);
        })
      }
      else if(selectedTab === "allowList"){
        if(selectedAllowListOption === "Action"){
          setDataLoading(true);
          setShowMessageBanner(false);
          setBannerMessage('');
          setBannerMessageType('success');
          setAllowListObject([]);
  
          UserManagementService.getAllowListForActions(skill["value"]).then((res)=>res.json()).then((res)=>{
            if(res["status"] === "success"){
              if(res["data"]){
                setAllowListObject(()=>{
                  let temp = [];
                  for(let d of res["data"]){
                    if(d["users"] && d["users"].length && d["users"][0].length > 0){
                      temp.push({
                        "values": d["actions"],
                        "users": d["users"][0]
                      })
                    }

                  }

                  //get action list for drop down
                  DialogService.getActions(skill["value"]).then((res)=>res.json()).then((res)=>{
                    if(res["status"] === "success"){
                      if(res["actions"]){
                        setAllowListValues(()=>{
                          let actions = [];
                          for(let action of res["actions"]){
                            actions.push({
                              "id": action["_id"],
                              "name": action["name"]
                            })
                          }
                          return actions;
                        });
                        setDataLoading(false);
                      }
                      else{
                        setDataLoading(false);
                        setBannerMessageType("info");
                        setBannerMessage(res["details"]||"Please configure actions to add allow list!");
                        setShowMessageBanner(true);   
                      }
                    }
                    else{
                      setDataLoading(false);
                      setBannerMessageType("error");
                      setBannerMessage(res["details"]||"Failed to add action list. Please contact botlite team!");
                      setShowMessageBanner(true);   
                    }
                  },(err)=>{
                    setDataLoading(false);
                    setBannerMessageType("error");
                    setBannerMessage("Failed to add action list. Please contact botlite team!");
                    setShowMessageBanner(true);   
                  })

                  setDataLoading(false);
                  return temp;
                })
              }
              else{
                setDataLoading(false);
              }
            }
            else{
              setDataLoading(false);
              setBannerMessageType("error");
              setBannerMessage(res["details"]||"Failed to fetch allow list for action. Please contact dev team!");
              setShowMessageBanner(true);
            }
  
          },(err)=>{
            setDataLoading(false);
            setBannerMessageType("error");
            setBannerMessage("Failed to fetch allow list for action. Please contact dev team!");
            setShowMessageBanner(true);
            console.log(err);
          })
        }
        else if(selectedAllowListOption === "Externalization"){
          setDataLoading(true);
          setShowMessageBanner(false);
          setBannerMessage('');
          setBannerMessageType('success');
          setBotExternalisationStatus("none");
          setExternalizationEmails([]);
          setIsBotIntegratedWithWebex(false);

          IntegrationService.getIntegrations(bot["value"]).then((res)=>res.json()).then((res)=>{
            if(res["status"] === "success"){
              for(let integration of res["integrations"]){
                if(integration["type"] === "webex"){
                  setIsBotIntegratedWithWebex(true);
                  UserManagementService.getExternalizationInfo(bot["value"]).then((res)=>res.json()).then((res)=>{
                    if(res["status"] === "success"){
                      if(res["requests"] !== null){
                        if(res["requests"]["status"] === "completed"){
                          UserManagementService.getExternalizationUserInfo(bot["value"]).then((res)=>res.json()).then((userRes)=>{
                            if(userRes["status"] === "success"){
                              setExternalizationEmails(()=>{
                                let temp = [];
                                let count = 0;
                                for(let user of userRes["extUsers"]){
                                  if(user["user"] && user["user"]){
                                    temp.push({
                                      id: count,
                                      Email: user["user"],
                                      LastAccessed:  user["last_active"]||"-",
                                      MessageCount: user["messages"]||0
                                    })
                                    count+=1;
                                  }
           
                                }
                                return temp;
                              })                        
                            }
                            else{
                              setDataLoading(false);
                              setBannerMessageType("error");
                              setBannerMessage(userRes["details"]||"Failed to fetch externalization user list. Please contact dev team!");
                              setShowMessageBanner(true);    
                            }
        
                          },(err)=>{
                            setDataLoading(false);
                            setBannerMessageType("error");
                            setBannerMessage("Failed to fetch externalization user list.. Please contact dev team!");
                            setShowMessageBanner(true);    
                          })
        
        
                          setBotExternalisationStatus("completed");
                        }
                        setBotExternalisationStatus(res["requests"]["status"]||"none");
                      }
                      setDataLoading(false);
                    }
                    else{
                      setDataLoading(false);
                      setBannerMessageType("error");
                      setBannerMessage(res["details"]||"Failed to fetch externalization details. Please contact dev team!");
                      setShowMessageBanner(true);              
                    }
                  }, (err)=>{
                    console.log(err);
                    setDataLoading(false);
                    setBannerMessageType("error");
                    setBannerMessage("Failed to fetch externalization details. Please contact dev team!");
                    setShowMessageBanner(true);
                  })
                  return;
                }
              }
              setDataLoading(false);
            }
            else{
              setDataLoading(false);
              setBannerMessageType("error");
              setBannerMessage(res["details"]||"Failed to fetch integration details. Please contact dev team!");
              setShowMessageBanner(true);  
            }
          },(err)=>{
            setDataLoading(false);
            setBannerMessageType("error");
            setBannerMessage("Failed to fetch integration details. Please contact dev team!");
            setShowMessageBanner(true);  
            console.log(err);
          })


          
        }
        else if (selectedAllowListOption === "Integration"){
          setDataLoading(true);
          setShowMessageBanner(false);
          setBannerMessage('');
          setBannerMessageType('success');
          setIsBotIntegratedWithWebex(false);
          setAllowListObject([]);

          IntegrationService.getIntegrations(bot["value"]).then((res)=>res.json()).then((res)=>{
            if(res["status"] === "success"){
              for(let integration of res["integrations"]){
                if(integration["type"] === "webex"){
                  setAllowListObject(()=>{
                    let users = [];
                    if(integration["integration_allow_list"]){
                      for(let user of integration["integration_allow_list"]["user"]){
                        if(user !== undefined && user !== null){
                          users.push({
                            "value": user,
                            "type":"user"
                          })
                        }

                      }
                      for(let user of integration["integration_allow_list"]["myId"]){
                        if(user !== undefined && user !== null){
                          users.push({
                            "value": user,
                            "type":"My ID Group"
                          })
                        }
                      }
                    }
                    return(
                      [{
                        "values":[{"id":"webex","name":"webex"}],
                        "users": users
                      }])
            
                  })
                  setAllowListValues([{
                    "id": "webex",
                    "name": "webex"
                  }]);
                  setIsBotIntegratedWithWebex(true);
                  setDataLoading(false);
                  return;
                }
              }
              setDataLoading(false);
            }
            else{
              setDataLoading(false);
              setBannerMessageType("error");
              setBannerMessage("Failed to fetch integration details. Please contact dev team!");
              setShowMessageBanner(true);  
            }
          },(err)=>{
            setDataLoading(false);
            setBannerMessageType("error");
            setBannerMessage("Failed to fetch integration details. Please contact dev team!");
            setShowMessageBanner(true);  
            console.log(err);
          })

        }
        
      }
  }

  },[bot])

  useEffect(()=>{
    setAllowListObject([]);
    if(selectedTab === "roleManagement" && bot !== undefined){
      setRoleManagementList([]);
      setDataLoading(true);
      setShowMessageBanner(false);
      setBannerMessage('');
      setBannerMessageType('success');
      UserManagementService.getUserRoles(bot["value"]).then((res)=>res.json()).then((res)=>{
        if (res["status"] === "success"){
          let temp = [];
          for(let i=0;i<res["user_roles"].length;i++){
            let roles = [];
            for(let j=0;j<res["user_roles"][i]["role"].length;j++){
              roles.push({
                "id": res["user_roles"][i]["role"][j],
                "name": res["user_roles"][i]["role"][j]
              })
            }
            temp.push({
              "user":res["user_roles"][i]["user"],
              "roles":roles
            })
          }
          setRoleManagementList(temp);
          setDataLoading(false);
        }
        else{
          setDataLoading(false);
          setBannerMessageType("error");
          setBannerMessage(res["details"]||"Failed to fetch user roles. Please contact botlite dev team!");
          setShowMessageBanner(true);
        }
  
      },(err)=>{
        setDataLoading(false);
        setBannerMessageType("error");
        setBannerMessage("Failed to fetch user roles. Please contact dev team!");
        setShowMessageBanner(true);
        console.log(err)
  
      })
    }
    else if(selectedTab === "allowList"){
      if(selectedAllowListOption === "Action"){
        setDataLoading(true);
        setShowMessageBanner(false);
        setBannerMessage('');
        setBannerMessageType('success');
        setAllowListObject([]);
        setSkillList([]);
        setSkill(undefined);
        SkillService.listSkills().then((res)=>res.json()).then((res)=>{
          if(res["status"] === "success"){
            setSkillList(()=>{
              let temp = [];
              for(let obj of res["skills"]){
                temp.push({
                  label: obj["name"],
                  value: obj["_id"]
                })
              }

              //get action list for drop down
              DialogService.getActions(temp[0]["value"]).then((res)=>res.json()).then((res)=>{
                if(res["status"] === "success"){
                  if(res["actions"]){
                    setAllowListValues(()=>{
                      let actions = [];
                      for(let action of res["actions"]){
                        actions.push({
                          "id": action["_id"],
                          "name": action["name"]
                        })
                      }
                      return actions;
                    });
                    setDataLoading(false);
                  }
                  else{
                    setDataLoading(false);
                    setBannerMessageType("info");
                    setBannerMessage(res["details"]||"Please configure actions to add allow list!");
                    setShowMessageBanner(true);   
                  }
                }
                else{
                  setDataLoading(false);
                  setBannerMessageType("error");
                  setBannerMessage(res["details"]||"Failed to add action list. Please contact botlite team!");
                  setShowMessageBanner(true);   
                }
              },(err)=>{
                setDataLoading(false);
                setBannerMessageType("error");
                setBannerMessage("Failed to add action list. Please contact botlite team!");
                setShowMessageBanner(true);   
              })


              setSkill(temp[0]);
              setDataLoading(false);
              return temp;
            })
          }
          else{
            setDataLoading(false);
            setBannerMessageType("error");
            setBannerMessage("Failed to fetch Skills. Please contact dev team!");
            setShowMessageBanner(true);
          }
        },(err)=>{
          setDataLoading(false);
          setBannerMessageType("error");
          setBannerMessage("Failed to fetch Skills. Please contact dev team!");
          setShowMessageBanner(true);
        })
      }
      else if(selectedAllowListOption === "Externalization" && bot !== undefined){
        setDataLoading(true);
        setShowMessageBanner(false);
        setBannerMessage('');
        setBannerMessageType('success');
        setBotExternalisationStatus("none");
        setExternalizationEmails([]);
        setIsBotIntegratedWithWebex(false);

        IntegrationService.getIntegrations(bot["value"]).then((res)=>res.json()).then((res)=>{
          if(res["status"] === "success"){
            for(let integration of res["integrations"]){
              if(integration["type"] === "webex"){
                setIsBotIntegratedWithWebex(true);
                UserManagementService.getExternalizationInfo(bot["value"]).then((res)=>res.json()).then((res)=>{
                  if(res["status"] === "success"){
                    if(res["requests"] !== null){
                      if(res["requests"]["status"] === "completed"){
                        UserManagementService.getExternalizationUserInfo(bot["value"]).then((res)=>res.json()).then((userRes)=>{
                          if(userRes["status"] === "success"){
                            setExternalizationEmails(()=>{
                              let temp = [];
                              let count = 0;
                              for(let user of userRes["extUsers"]){
                                if(user["user"] && user["user"]){
                                  temp.push({
                                    id: count,
                                    Email: user["user"],
                                    LastAccessed:  user["last_active"]||"-",
                                    MessageCount: user["messages"]||0
                                  })
                                  count+=1;
                                }
         
                              }
                              return temp;
                            })                        
                          }
                          else{
                            setDataLoading(false);
                            setBannerMessageType("error");
                            setBannerMessage(userRes["details"]||"Failed to fetch externalization user list. Please contact dev team!");
                            setShowMessageBanner(true);    
                          }
      
                        },(err)=>{
                          setDataLoading(false);
                          setBannerMessageType("error");
                          setBannerMessage("Failed to fetch externalization user list.. Please contact dev team!");
                          setShowMessageBanner(true);    
                        })
      
      
                        setBotExternalisationStatus("completed");
                      }
                      setBotExternalisationStatus(res["requests"]["status"]||"none");
                    }
                    setDataLoading(false);
                  }
                  else{
                    setDataLoading(false);
                    setBannerMessageType("error");
                    setBannerMessage(res["details"]||"Failed to fetch externalization details. Please contact dev team!");
                    setShowMessageBanner(true);              
                  }
                }, (err)=>{
                  console.log(err);
                  setDataLoading(false);
                  setBannerMessageType("error");
                  setBannerMessage("Failed to fetch externalization details. Please contact dev team!");
                  setShowMessageBanner(true);
                })
                return;
              }
            }
            setDataLoading(false);
          }
          else{
            setDataLoading(false);
            setBannerMessageType("error");
            setBannerMessage(res["details"]||"Failed to fetch integration details. Please contact dev team!");
            setShowMessageBanner(true);  
          }
        },(err)=>{
          setDataLoading(false);
          setBannerMessageType("error");
          setBannerMessage("Failed to fetch integration details. Please contact dev team!");
          setShowMessageBanner(true);  
          console.log(err);
        })


        
      }
      else if (selectedAllowListOption === "Integration" && bot !== undefined){
        setDataLoading(true);
        setShowMessageBanner(false);
        setBannerMessage('');
        setBannerMessageType('success');
        setIsBotIntegratedWithWebex(false);
        setAllowListObject([]);

        IntegrationService.getIntegrations(bot["value"]).then((res)=>res.json()).then((res)=>{
          if(res["status"] === "success"){
            for(let integration of res["integrations"]){
              if(integration["type"] === "webex"){
                setAllowListObject(()=>{
                  let users = [];
                  if(integration["integration_allow_list"]){
                    for(let user of integration["integration_allow_list"]["user"]){
                      if(user !== undefined && user !== null){
                        users.push({
                          "value": user,
                          "type":"user"
                        })
                      }

                    }
                    for(let user of integration["integration_allow_list"]["myId"]){
                      if(user !== undefined && user !== null){
                        users.push({
                          "value": user,
                          "type":"My ID Group"
                        })
                      }
                    }
                  }
                  return(
                    [{
                      "values":[{"id":"webex","name":"webex"}],
                      "users": users
                    }])
          
                })
                setAllowListValues([{
                  "id": "webex",
                  "name": "webex"
                }]);

                setIsBotIntegratedWithWebex(true);
                setDataLoading(false);
                return;
              }
            }
            setDataLoading(false);
          }
          else{
            setDataLoading(false);
            setBannerMessageType("error");
            setBannerMessage("Failed to fetch integration details. Please contact dev team!");
            setShowMessageBanner(true);  
          }
        },(err)=>{
          setDataLoading(false);
          setBannerMessageType("error");
          setBannerMessage("Failed to fetch integration details. Please contact dev team!");
          setShowMessageBanner(true);  
          console.log(err);
        })

      }
      
    }

  },[selectedTab, selectedAllowListOption])

  useEffect(()=>{
    setDataLoading(true);
    setBannerMessageType("error");
    setBannerMessage("");
    setShowMessageBanner(false);
    if(skill !== undefined){
      UserManagementService.getAllowListForActions(skill["value"]).then((res)=>res.json()).then((res)=>{
        if(res["status"] === "success"){
          if(res["data"]){
            setAllowListObject(()=>{
              let temp = [];
              for(let d of res["data"]){
                if(d["users"] && d["users"].length > 0 && d["users"][0].length > 0){
                  temp.push({
                    "values": d["actions"],
                    "users": d["users"][0]
                  })
                }

              }
            //get action list for drop down
            DialogService.getActions(skill["value"]).then((res)=>res.json()).then((res)=>{
              if(res["status"] === "success"){
                if(res["actions"]){
                  setAllowListValues(()=>{
                    let actions = [];
                    for(let action of res["actions"]){
                      actions.push({
                        "id": action["_id"],
                        "name": action["name"]
                      })
                    }
                    return actions;
                  });
                  setDataLoading(false);
                }
                else{
                  setDataLoading(false);
                  setBannerMessageType("info");
                  setBannerMessage(res["details"]||"Please configure actions to add allow list!");
                  setShowMessageBanner(true);   
                }
              }
              else{
                setDataLoading(false);
                setBannerMessageType("error");
                setBannerMessage(res["details"]||"Failed to add action list. Please contact botlite team!");
                setShowMessageBanner(true);   
              }
            },(err)=>{
              setDataLoading(false);
              setBannerMessageType("error");
              setBannerMessage("Failed to add action list. Please contact botlite team!");
              setShowMessageBanner(true);   
            })

                            
              setDataLoading(false);
              setDataLoading(false);
              return temp;
            })
          }
          else{
            setDataLoading(false);
          }
        }
        else{
          setDataLoading(false);
          setBannerMessageType("error");
          setBannerMessage(res["details"]||"Failed to fetch allow list for action. Please contact dev team!");
          setShowMessageBanner(true);
        }
      },(err)=>{
        setDataLoading(false);
        setBannerMessageType("error");
        setBannerMessage("Failed to fetch allow list for action. Please contact dev team!");
        setShowMessageBanner(true);
        console.log(err);
      })
    }
  },[skill])

  const BotOrSkillList = () => {
    if(selectedTab === "allowList" && selectedAllowListOption === "Action"){
      return (<SearchSelect
            id="skillList"
            className="augmentSelect"
            defaultValue={skill}
            options={skills}
            onValueChange={(data) => {
                setSkill(data);
                localStorage.setItem("skillSelectedId", data["value"]);
            }}
        />)
    }
    return (
        <SearchSelect
            id="botList"
            className="augmentSelect"
            defaultValue={bot}
            options={bots}
            onValueChange={(data) => {
                setBot(data);
                localStorage.setItem("botSelectedId", data["value"]);
            }}
        />
    );
  };
  const removeUserFromAllowList = async (index, allowListValues)=>{
    setDataLoading(true);
    setShowMessageBanner(false);
    setBannerMessage('');
    setBannerMessageType('success');
    let temp = [];
    let newUserList = [];
    let newMyIdList = [];
    for(let i=0;i<allowListObject.length;i++){
      if(i === index){
        temp.push({"values":allowListObject[i]["values"],"users":[]});
        for(let j=0;j<allowListObject[i]["users"].length;j++){
          if(selectedAllowListUser.indexOf(j) === -1){
            temp[i]["users"].push(allowListObject[i]["users"][j])
            if(allowListObject[i]["users"][j]["type"]==="user"){
              newUserList.push(allowListObject[i]["users"][j]["value"])
              
            }else{
              newMyIdList.push(allowListObject[i]["users"][j]["value"])
              
            }
          }
          
        }
      }
      else{
        temp.push(allowListObject[i]);
      }
    }

    if(selectedAllowListOption === "Action"){
      let payload = {
        "resource": "action",
        "data": {
          "actions":temp[index]["values"],
          "users":temp[index]["users"]
          
        }
      }
      let responseObj = await UserManagementService.createAllowListForActions(skill["value"], payload);
      if(responseObj.status !== 200){
        setDataLoading(false);
        setBannerMessageType("error");
        setBannerMessage("Failed to update action allow list details. Please contact botlite team!");
        setShowMessageBanner(true);
        return;
      }
      let response = await responseObj.json();
      if (response["status"] && response["status"] === "success"){
        setAllowListObject(temp);
        document.getElementsByClassName("PrivateSwitchBase-input").item(0).click();
        setDataLoading(false);
      }
      else{
        setDataLoading(false);
        setBannerMessageType("error");
        setBannerMessage(response["details"]||"Failed to update action allow list details. Please contact botlite team!");
        setShowMessageBanner(true);
        return;
      }
    }
    else if(selectedAllowListOption === "Integration"){
      let payload = {
        "resource": "integration",
        "data":{
          "integrations": ["webex"],
          "user": newUserList,
          "myId": newMyIdList
        }

      }
    
      let responseObj = await UserManagementService.modifyIntegrationAllowList(bot["value"], payload);
      if(responseObj.status !== 200){
        setDataLoading(false);
        setBannerMessageType("error");
        setBannerMessage("Failed to update integration allow list details. Please contact botlite team!");
        setShowMessageBanner(true);
        return;
      }
      let response = await responseObj.json();
      if (response["status"] && response["status"] === "success"){
        setAllowListObject(temp);
        document.getElementsByClassName("PrivateSwitchBase-input").item(0).click();
        setDataLoading(false);
      }
      else{
        setDataLoading(false);
        setBannerMessageType("error");
        setBannerMessage(response["details"]||"Failed to update integration allow list details. Please contact botlite team!");
        setShowMessageBanner(true);
        return;
      } 
    }



  }
  const addNewUserToRoleList = ()=>{
    setDataLoading(true);
    setShowMessageBanner(false);
    setBannerMessage('');
    setBannerMessageType('success');

    let validationPayload = {
      "users": [newUserForRoleManagement]
    }
    BotService.validateUsers(validationPayload).then((res)=>res.json()).then((res)=>{
      setRoleManagementList((prev)=>{
        let temp = [...prev];
        temp.push({"user":newUserForRoleManagement,"roles":[]});
        setNewUserForRoleManagement('');
        setDataLoading(false);
        return temp;
      })
    }, (err)=>{
      setDataLoading(true);
      setShowMessageBanner(false);
      setBannerMessage('Failed to valdate email. Please contact botlite dev team!');
      setBannerMessageType('error');
    })

  }
  const updateRolesToUser = (index, newRole)=>{
    let roles = [];
    for(let val of newRole){
      roles.push(val["id"]);
    }

    let payload = {
      "email": roleManagementList[index]["user"],
      "role": roles
    }
    UserManagementService.updateUserRole(bot["value"], payload).then((res)=>res.json()).then((res)=>{
      setDataLoading(false);
    },(err)=>{
      setDataLoading(false);
      setBannerMessageType("error");
      setBannerMessage("Failed to fetch allow list for action. Please contact dev team!");
      setShowMessageBanner(true);
    })
  }

  const addAllowList = ()=>{
    if(selectedAllowListOption === "Action"){
      setDataLoading(true);
      setShowMessageBanner(false);
      setBannerMessage('');
      setBannerMessageType('success');
      setShowAllowListModal(false);


      let allowListUsers = [];
      for(let user of inputDataList){
        allowListUsers.push({
          "value": user["text"],
          "type": user["type"]
        })
      }
      let payload = {
        "resource": "action",
        "data": {
          "actions":[...selectedAllowListOptions],
          "users": allowListUsers
        }
      };

      setSelectedAllowListOptions([]);
      setInputDataList([]);

      UserManagementService.createAllowListForActions(skill["value"],payload).then((res)=>res.json()).then((res)=>{
        setAllowListObject([]);
        if(res["status"] === "success"){
          UserManagementService.getAllowListForActions(skill["value"]).then((res)=>res.json()).then((res)=>{
            if(res["status"] === "success" && res["data"]){
              setAllowListObject(()=>{
                let temp = [];
                for(let d of res["data"]){
                  if(d["users"] && d["users"].length > 0 && d["users"][0].length > 0){
                    temp.push({
                      "values": d["actions"],
                      "users": d["users"][0]
                    })
                  }
                }
                setDataLoading(false);
                return temp;
              })
  
            }
            else{
              setDataLoading(false);
              setBannerMessageType("error");
              setBannerMessage(res["details"]||"Failed to fetch allow list for action. Please contact dev team!");
              setShowMessageBanner(true);
            }
  
          },(err)=>{
            setDataLoading(false);
            setBannerMessageType("error");
            setBannerMessage("Failed to fetch allow list for action. Please contact dev team!");
            setShowMessageBanner(true);
            console.log(err);
          })
        }
        else{
          setDataLoading(false);
          setBannerMessageType("error");
          setBannerMessage(res["details"]||"Failed to add allow list for action. Please contact dev team!");
          setShowMessageBanner(true);          
        }

      },(err)=>{
        setDataLoading(false);
        setBannerMessageType("error");
        setBannerMessage("Failed to add allow list for action. Please contact dev team!");
        setShowMessageBanner(true);
      })
    }
    else if(selectedAllowListOption === "Externalization"){

    }
    else if (selectedAllowListOption === "Integration"){
      setDataLoading(true);
      setShowAddUserModal(false);
      let newUserList = [];
      let newMyIdList = [];


      for(let i=0;i<addUserInputDataList.length;i++){
        if(addUserInputDataList[i]["type"] === "user"){
          newUserList.push(addUserInputDataList[i]["text"]);
        }
        else{
          newMyIdList.push(addUserInputDataList[i]["text"])
        }
      }
      let payload = {
        "resource": "integration",
        "data":{
          "integrations": ["webex"],
          "user": newUserList,
          "myId": newMyIdList
        }

      }
      UserManagementService.modifyIntegrationAllowList(bot["value"], payload).then((res)=>res.json()).then((res)=>{
        if(res["status"] && res["status"] === "success"){
          setAllowListObject([]);
          IntegrationService.getIntegrations(bot["value"]).then((res)=>res.json()).then((res)=>{
            if(res["status"] === "success"){
              for(let integration of res["integrations"]){
                if(integration["type"] === "webex"){
                  setAllowListObject(()=>{
                    let users = [];
                    if(integration["integration_allow_list"]){
                      for(let user of integration["integration_allow_list"]["user"]){
                        if(user !== undefined && user !== null){
                          users.push({
                            "value": user,
                            "type":"user"
                          })
                        }

                      }
                      for(let user of integration["integration_allow_list"]["myId"]){
                        if(user !== undefined && user !== null){
                          users.push({
                            "value": user,
                            "type":"My ID Group"
                          })
                        }
                      }
                    }

                    return(
                      [{
                        "values":[{"id":"webex","name":"webex"}],
                        "users": users
                      }])
            
                  })
                  setIsBotIntegratedWithWebex(true);
                  setDataLoading(false);  
                  setAddUserInputDataList([]);
                  return;
                }
              }
              setDataLoading(false);
            }
            else{
              setDataLoading(false);
              setBannerMessageType("error");
              setBannerMessage("Failed to fetch integration details. Please contact dev team!");
              setShowMessageBanner(true); 
            }
          },(err)=>{
            setDataLoading(false);
            setBannerMessageType("error");
            setBannerMessage("Failed to fetch integration details. Please contact dev team!");
            setShowMessageBanner(true);  
            console.log(err);
          })

        }
        else{
          setDataLoading(false);
          setBannerMessageType("error");
          setBannerMessage("Failed to add allow list for integration. Please contact botlite team!");
          setShowMessageBanner(true);
        }
      },(err)=>{
        setDataLoading(false);
        setBannerMessageType("error");
        setBannerMessage("Failed to add allow list for integration. Please contact botlite team!");
        setShowMessageBanner(true);
      })
    }

  }

  const updateAllowList = ()=>{
    if(selectedAllowListOption === "Action"){
      setDataLoading(true);
      setShowAddUserModal(false);
      setShowMessageBanner(false);
      setBannerMessage('');
      setBannerMessageType('success');
      setShowAllowListModal(false);

      let users = []

      for(let i=0;i<allowListObject[selectedIndexForAllowListUpdate]["users"].length;i++){
        users.push(allowListObject[selectedIndexForAllowListUpdate]["users"][i])
      }

      for(let i=0;i<addUserInputDataList.length;i++){
        if(addUserInputDataList[i]["type"] === "user"){
          users.push({
            "value":addUserInputDataList[i]["text"],
            "type":"user"
          })
        }
        else{
          users.push({
            "value":addUserInputDataList[i]["text"],
            "type":"myId"
          })
        }
      }

      let payload = {
        "resource": "action",
        "data": {
          "actions":[...allowListObject[selectedIndexForAllowListUpdate]["values"]],
          "users": users
        }
      };

      setSelectedAllowListOptions([]);
      setInputDataList([]);

      UserManagementService.createAllowListForActions(skill["value"],payload).then((res)=>res.json()).then((res)=>{
        setAllowListObject([]);
        if(res["status"] === "success"){
          UserManagementService.getAllowListForActions(skill["value"]).then((res)=>res.json()).then((res)=>{
            if(res["status"] === "success"){
              setAllowListObject(()=>{
                let temp = [];
                for(let d of res["data"]){
                  if(d["users"] && d["users"].length > 0 && d["users"][0].length > 0){
                    temp.push({
                      "values": d["actions"],
                      "users": d["users"][0]
                    })
                  }

                }
                setDataLoading(false);
                return temp;
              })
  
            }
            else{
              setDataLoading(false);
              setBannerMessageType("error");
              setBannerMessage(res["details"]||"Failed to fetch allow list for action. Please contact dev team!");
              setShowMessageBanner(true);
            }
  
          },(err)=>{
            setDataLoading(false);
            setBannerMessageType("error");
            setBannerMessage("Failed to fetch allow list for action. Please contact dev team!");
            setShowMessageBanner(true);
            console.log(err);
          })
        }
        else{
          setDataLoading(false);
          setBannerMessageType("error");
          setBannerMessage(res["details"]||"Failed to add allow list for action. Please contact dev team!");
          setShowMessageBanner(true);          
        }

      },(err)=>{
        setDataLoading(false);
        setBannerMessageType("error");
        setBannerMessage("Failed to add allow list for action. Please contact dev team!");
        setShowMessageBanner(true);
      })
    }
    else if(selectedAllowListOption === "Externalization"){

    }
    else if (selectedAllowListOption === "Integration"){
      setDataLoading(true);
      setShowAddUserModal(false);
      let newUserList = [];
      let newMyIdList = [];

      for(let i=0;i<allowListObject[selectedIndexForAllowListUpdate]["users"].length;i++){
        if(allowListObject[selectedIndexForAllowListUpdate]["users"][i]["type"] === "user"){
          newUserList.push(allowListObject[selectedIndexForAllowListUpdate]["users"][i]["value"]);
        }
        else{
          newMyIdList.push(allowListObject[selectedIndexForAllowListUpdate]["users"][i]["value"])
        }
      }

      for(let i=0;i<addUserInputDataList.length;i++){
        if(addUserInputDataList[i]["type"] === "user"){
          newUserList.push(addUserInputDataList[i]["text"]);
        }
        else{
          newMyIdList.push(addUserInputDataList[i]["text"])
        }
      }
      let payload = {
        "resource": "integration",
        "data":{
          "integrations": ["webex"],
          "user": newUserList,
          "myId": newMyIdList
        }

      }
      UserManagementService.modifyIntegrationAllowList(bot["value"], payload).then((res)=>res.json()).then((res)=>{
        if(res["status"] && res["status"] === "success"){
          setAllowListObject([]);
          IntegrationService.getIntegrations(bot["value"]).then((res)=>res.json()).then((res)=>{
            if(res["status"] === "success"){
              for(let integration of res["integrations"]){
                if(integration["type"] === "webex"){
                  setAllowListObject(()=>{
                    let users = [];
                    if(integration["integration_allow_list"]){
                      for(let user of integration["integration_allow_list"]["user"]){
                        if(user !== undefined && user !== null){
                          users.push({
                            "value": user,
                            "type":"user"
                          })
                        }

                      }
                      for(let user of integration["integration_allow_list"]["myId"]){
                        if(user !== undefined && user !== null){
                          users.push({
                            "value": user,
                            "type":"My ID Group"
                          })
                        }
                      }
                    }

                    return(
                      [{
                        "values":[{"id":"webex","name":"webex"}],
                        "users": users
                      }])
            
                  })
                  setIsBotIntegratedWithWebex(true);
                  setDataLoading(false);  
                  setAddUserInputDataList([]);
                  return;
                }
              }
              setDataLoading(false);
            }
            else{
              setDataLoading(false);
              setBannerMessageType("error");
              setBannerMessage("Failed to fetch integration details. Please contact dev team!");
              setShowMessageBanner(true); 
            }
          },(err)=>{
            setDataLoading(false);
            setBannerMessageType("error");
            setBannerMessage("Failed to fetch integration details. Please contact dev team!");
            setShowMessageBanner(true);  
            console.log(err);
          })

        }
        else{
          setDataLoading(false);
          setBannerMessageType("error");
          setBannerMessage("Failed to add allow list for integration. Please contact botlite team!");
          setShowMessageBanner(true);
        }
      },(err)=>{
        setDataLoading(false);
        setBannerMessageType("error");
        setBannerMessage("Failed to add allow list for integration. Please contact botlite team!");
        setShowMessageBanner(true);
      })
    }

  }

  const openAddAllowListModal = async ()=>{
    if(selectedAllowListOption === "Action"){
      setDataLoading(true);
      setShowMessageBanner(false);
      setBannerMessage('');
      setBannerMessageType('success');
      setAllowListObject([]);

      let actions = []

      DialogService.getActions(skill["value"]).then((res)=>res.json()).then((res)=>{
        if(res["status"] === "success"){
          if(res["actions"]){
            setAllowListValues(()=>{
              for(let action of res["actions"]){
                actions.push({
                  "id": action["_id"],
                  "name": action["name"]
                })
              }
              return actions;
            });
            setShowAllowListModal(true);
            setDataLoading(false);
          }
          else{
            setDataLoading(false);
            setBannerMessageType("info");
            setBannerMessage(res["details"]||"Please configure actions to add allow list!");
            setShowMessageBanner(true);   
          }
        }
        else{
          setDataLoading(false);
          setBannerMessageType("error");
          setBannerMessage(res["details"]||"Failed to add action list. Please contact botlite team!");
          setShowMessageBanner(true);   
        }
      },(err)=>{
        setDataLoading(false);
        setBannerMessageType("error");
        setBannerMessage("Failed to add action list. Please contact botlite team!");
        setShowMessageBanner(true);   
      })


    }
    else if(selectedAllowListOption === "Externalization"){

    }
    else if (selectedAllowListOption === "Integration"){
      setShowAllowListModal(true);
      setAllowListValues([{
        "id": "webex",
        "name": "webex"
      }]);
    }
  }

  const addExternalizationEmail = ()=>{
    setDataLoading(true);
    setShowMessageBanner(false);
    setBannerMessage('');
    setShowAddExternalizationEmail(false);
    let temp = [];
    for(let email of newExternalizationEmails){
      temp.push(email["email"])
    }
    for(let i=0;i<externalizationEmails.length;i++){
      temp.push(externalizationEmails[i]["Email"]);
    }

    UserManagementService.updateExternalizationUserList(bot["value"], temp).then((res)=>res.json()).then((res)=>{
      if(res["status"] === "success"){
        setDataLoading(false);
        
        setExternalizationEmails((prev)=>{
          let temp = [...prev];
          let count = temp.length;
          for(let email of newExternalizationEmails){
            temp.push(          {
              id: count,
              Email: email["email"],
              LastAccessed:  "-",
              MessageCount: 0
            })
            count+=1;
          }
          return temp;

        })
        setNewExternalizationEmails([]);
      }
      else{
        setDataLoading(false);
        setBannerMessageType("error");
        setBannerMessage(res["details"]||"Failed to add emails for externalization. Please contact dev team!");
        setShowMessageBanner(true);       
      }

    },(err)=>{
      setDataLoading(false);
      setBannerMessageType("error");
      setBannerMessage("Failed to add emails for externalization. Please contact dev team!");
      setShowMessageBanner(true);
    })
  }

  const removeExternalizationEmail = ()=>{
    setDataLoading(true);
    setShowMessageBanner(false);
    setBannerMessage('');

    let temp = [];
    let userList = [];
    for(let i=0;i<externalizationEmails.length;i++){
      if(selectedExternalizationEmail.indexOf(i) === -1){
        temp.push(externalizationEmails[i]);
        userList.push(externalizationEmails[i]["Email"]);
      }
    }
    
    UserManagementService.updateExternalizationUserList(bot["value"],userList).then((res)=>res.json()).then((res)=>{
      if(res && res["status"] === "success"){
        setExternalizationEmails(temp);
      }else{
        setBannerMessageType("error");
        setBannerMessage("Failed to remove emails from externalization list. Please contact dev team!");
        setShowMessageBanner(true);
      }
      setDataLoading(false);
    },(err)=>{
      setDataLoading(false);
      setBannerMessageType("error");
      setBannerMessage("Failed to remove emails from externalization list. Please contact dev team!");
      setShowMessageBanner(true);
    })
  }

  const createExternalizationRequest = ()=>{
    setDataLoading(true);
    setShowMessageBanner(false);
    setBannerMessage('');
    setBannerMessageType('success');
    UserManagementService.createExternalizationRequest(bot["value"]).then((res)=>res.json()).then((res)=>{
      if(res["status"] === "success"){
        setDataLoading(false);
        setShowMessageBanner(true);
        setBannerMessage(res["details"]||'Successfully created the externalization request.');
        setBannerMessageType('success');
        setBotExternalisationStatus("manager");
      }else{
        setDataLoading(false);
        setShowMessageBanner(true);
        setBannerMessage(res["details"]||'Failed to create externalization request. Please contact botlite dev team');
        setBannerMessageType('error');
      }
    },(err)=>{
      setDataLoading(false);
      setShowMessageBanner(true);
      setBannerMessage('Failed to create externalization request. Please contact botlite dev team');
      setBannerMessageType('error');
      console.log(err);
    })
  }

  const handleMultiSelectAllowListOptionUpdate = (data, index, tabelRows)=>{
    if(selectedAllowListOption === "Integration"){
      setDataLoading(true);
      setShowMessageBanner(false);
      setBannerMessage('');
      setBannerMessageType('success');

      let newUserList = [];
      let newMyIdList = [];
      for(let i=0;i<tabelRows.length;i++){
        if(tabelRows["Type"] === "user"){
          newUserList.push(tabelRows["Value"])
        }
        else{
          newUserList.push(tabelRows["Value"]);
        }
      }

      let payload = {
        "resource": "integration",
        "data":{
          "integrations": [...data],
          "user": newUserList,
          "myId": newMyIdList
        }
      }

      UserManagementService.modifyIntegrationAllowList(bot["value"], payload).then((res)=>res.json()).then((res)=>{
        if(res["status"] !== "success"){
          setBannerMessageType("error");
          setBannerMessage("Failed to update integration allow list. Please contact dev team!");
          setShowMessageBanner(true); 
        }
        setDataLoading(false);
      },(err)=>{
        console.log(err);
        setDataLoading(false);
        setBannerMessageType("error");
        setBannerMessage("Failed to update integration allow list. Please contact dev team!");
        setShowMessageBanner(true); 
      })
    }
    else if(selectedAllowListOption === "Action"){
      setDataLoading(true);
      setShowMessageBanner(false);
      setBannerMessage('');
      setBannerMessageType('success');

      let users = [];

      for(let i=0;i<tabelRows.length;i++){
        if(tabelRows[i]["Type"] === "user"){
          users.push({
            "value":tabelRows[i]["Value"],
            "type":"user"
          })
        }
        else{
          users.push({
            "value":tabelRows[i]["Value"],
            "type":"myId"
          })
        }
      }
      let payload = {
        "resource": "action",
        "data": {
          "actions":[...data],
          "users": users
        }
      };
      UserManagementService.createAllowListForActions(skill["value"], payload).then((res)=>res.json()).then((res)=>{
        if(res["status"] !== "success"){
          setBannerMessageType("error");
          setBannerMessage("Failed to action allow list. Please contact dev team!");
          setShowMessageBanner(true); 
        }
        setDataLoading(false);
      },(err)=>{
        console.log(err);
        setDataLoading(false);
        setBannerMessageType("error");
        setBannerMessage("Failed to action allow list. Please contact dev team!");
        setShowMessageBanner(true); 
      })

    }
  }

  const externalizationSection = ()=>{
    let colData = [{
      field: 'Email',
      headerName: 'Email',
      flex: 1,
      editable: false
    },{
      field: 'LastAccessed',
      headerName: 'Last Accessed',
      flex: 0.5,
      editable: false
    },{
      field: 'MessageCount',
      headerName: 'Message Count',
      flex: 0.25,
      editable: false
    }];

    let tableHeight = window.innerHeight - 336;
    if(botExternalisationStatus === "completed"){
      return (<div>
                <DataGridComponent noToolbar={true} rows={externalizationEmails} columns={colData} rowHeight={50} ht={tableHeight} checkbox={true} disableSelection={false} setSelectedExpression={(data)=>{setSelectedExternalizationEmail(data)}}/>   
                <div style={{position:"absolute", bottom: "16px", right: "16px"}}>
                  <button className="btn btn--secondary padding" onClick={()=>{removeExternalizationEmail()}}>Delete</button>
                  <button className="btn btn--secondary padding" onClick={()=>{setShowAddExternalizationEmail(true);setNewExternalizationEmails([])}}>Add</button>
                </div>
            </div>)
    }
    else if(botExternalisationStatus === "manager" || botExternalisationStatus === "director"){
      let externalizationInfoMessage = "Externalization request has been successfully raised for this bot and is in approval process. Please check approval dashboard for more info on the status."
      return(
        <div style={{position: "relative", top: "calc(50% - 27px)"}}>
          <MessageBanner messageType="info" message={externalizationInfoMessage}></MessageBanner>
        </div>
      )
    }
    else{
      let externalizationWarningMessage = "By requesting, you acknowledge that you are fully aware of the Cisco Data Protection policies and assuming the responsibility of data compliance measures before sharing the Cisco’s data with external users via the bot. This action will trigger a bot externalization request email to your manager and director. Are you sure you want to proceed?"
      return(
        <div style={{position: "relative", top: "calc(50% - 60px)"}}>
          <MessageBanner messageType="info" message={externalizationWarningMessage}></MessageBanner>
          <div style={{textAlign:"center"}}>
            <button className="btn btn--secondary padding" onClick={()=>{createExternalizationRequest()}}>Yes</button>
          </div>
        </div>
      )
    }

      
  }

  const allowListSection = ()=>{
    if(selectedAllowListOption === "Action" || selectedAllowListOption === "Integration"){
      if(selectedAllowListOption === "Integration" && !isBotIntegratedWithWebex){
        return(
          <MessageBanner message={"Please integrate the bot with webx to use this feature"} messageType={"info"}></MessageBanner>
        )
      }
      if (selectedAllowListOption === "Action" && allowListValues.length <=0) {
        return (
          <MessageBanner message={"It seems like no action has been created. To create one, please click on Add Button."} messageType={"info"}></MessageBanner>
        )
      }
      let dataRow = [];
      allowListObject.map((object, idx)=>{
        let colData = [{
          field: 'Value',
          headerName: 'Value',
          flex: 1,
          editable: false
        },{
          field: 'Type',
          headerName: 'Type',
          flex: 0.25,
          editable: false
        }];

        let rowData = [];
        for(let i=0;i<object["users"].length;i++){
          rowData.push({
            id: i,
            Value: object["users"][i]["value"],
            Type: object["users"][i]["type"]
          })
        }

        dataRow.push(
          <div className="panel panel--raised panel--bordered base-margin base-padding">
              <MultiSelect
                option={allowListValues}
                placeholder="Select the Value"
                selectedValues={object["values"]}
                onValueChange={(data) => { handleMultiSelectAllowListOptionUpdate(data, idx, rowData) }} />
              <br></br>
              <div style={{position:"absolute",right:"16px", zIndex:"20"}}>
                <button className="btn btn--secondary padding" onClick={()=>{
                    setSelectedIndexForForAllowListUpdate(idx);
                    setShowAddUserModal(true)
                  }}>Update</button>
                <button className="btn btn--secondary padding" onClick={()=>{
                  setSelectedIndexForForAllowListUpdate(idx);
                  removeUserFromAllowList(idx, allowListValues)
                  }}>Delete</button>
              </div>
              <DataGridComponent noToolbar={true} rows={rowData} columns={colData} rowHeight={50} ht={300} checkbox={true} disableSelection={false} setSelectedExpression={(data)=>{setAllowListUser(data)}}/>     
          </div>
        )
      })
      return(
        dataRow.map((value)=>{
          return value;
        })
      )
    }
    else if(selectedAllowListOption === "Externalization"){
      if(isBotIntegratedWithWebex){
        return externalizationSection();
      }
      else{
        return(
          <MessageBanner message={"Please integrate the bot with webx to use this feature"} messageType={"info"}></MessageBanner>
        )
      }
    }

  }

  const cropText = (text)=>{
    if(text.length > 20){
      return text.slice(0, 20)+"...";
    }
    return text
  }

  const deleteUserFromRoles = (index)=>{
    setDataLoading(true);
    setShowMessageBanner(false);
    setBannerMessage('');
    setBannerMessageType('success');

    let email = roleManagementList[index]["user"];
    UserManagementService.deleteUserFromRoles(bot["value"], email).then((res)=>res.json()).then((res)=>{
      if(res["status"] !== "success"){
        setShowMessageBanner(true);
        setBannerMessage(res['details']||'Failed to delete user. Please contact botlite dev team');
        setBannerMessageType('error');
        setDataLoading(false);
      }
      else{
        setRoleManagementList((prev)=>{
          let temp = [...prev];
          temp.splice(index,1);
          setDataLoading(false);
          return temp;

        })
      }
      
    },(err)=>{
      setShowMessageBanner(true);
      setBannerMessage('Failed to delete user. Please contact botlite dev team');
      setBannerMessageType('error');
      setDataLoading(false);
      console.log(err);
    })
  };

  return (
    <div className="userManagementTab-wrapper">
      {isDataLoading && <Spinner></Spinner>}
      {showMessageBanner && <MessageBanner message={bannerMessage} messageType={bannerMessageType} onCloseClicked={() => { setShowMessageBanner(false) }}></MessageBanner>}
      <div
        className="row base-margin-top base-margin-right pageTour1"
        style={{ borderBottom: "1px solid #ced4da" }}
      >
        <h5 className="col-6 col-md-6 col-sm-6 col-lg-6 col-xl-6 col-xxl-6 col-xxxl-6">
          User Management Screen
        </h5>
        <div className="col-3 col-md-3 col-sm-3 col-lg-3 col-xl-3 col-xxl-3 col-xxxl-3"></div>
        <div className="col-3 col-md-3 col-sm-3 col-lg-3 col-xl-3 col-xxl-3 col-xxxl-3 base-margin-bottom">
          {BotOrSkillList()}
        </div>
      </div>
      <div className="contentBox">
        <ul id="bordered" className="tabs tabs--bordered">
          <li
            id="bordered-1"
            onClick={() => {
              setSelectedTab("roleManagement");
            }}
            className={selectedTab === "roleManagement" ? "tab active" : "tab"}
          >
            <a tabindex="0">Role Management</a>
          </li>
          <li
            id="bordered-2"
            onClick={() => {
              setSelectedTab("allowList");
            }}
            className={selectedTab === "allowList" ? "tab active" : "tab"}
          >
            <a tabindex="0">Allow List</a>
          </li>
        </ul>
        {
          selectedTab === "allowList" && selectedAllowListOption !== "Externalization" && (
            <div style={{position:"absolute", top:(showMessageBanner?"152px":"94px"),right:"28px"}}>
              <button className="btn btn--secondary padding" onClick={()=>{openAddAllowListModal()}}>Add</button>
            </div>
          )
        }

        <div id="bordered-content" className="tab-content" style={{height:"calc(100% - 46px)"}}>
          <div
            id="myprofile"
            className={
              selectedTab === "roleManagement" ? "tab-pane active" : "tab-pane"
            }
          >
            {isBotAdmin && <>
              <div className="allowList-userInputWrapper row">
              <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 col-xxxl-6">
                <div className="form-group base-margin-bottom" style={{position:"relative",left:"28px"}}>
                    <div className="form-group__text">
                        <input id="input-type-user-cec-rm" type="email" autoComplete='off' autoFocus name="User Email" placeholder="Enter user's email and click on 'Add' button' " onChange={event => { setNewUserForRoleManagement(event.target.value) }} value={newUserForRoleManagement}/>
                        <label htmlFor="input-type-user-cec-rm">User Email<span className='required'>*</span></label>
                    </div>
                </div>
              </div> 
              <div className="col-3 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4 col-xxxl-5"></div>
              <div className="col-3 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2 col-xxxl-1" style={{paddingTop:"22px", textAlign:"right"}}>
                <button className="btn btn--secondary padding" disabled={
                  newUserForRoleManagement.length < 3 || 
                  !(newUserForRoleManagement.indexOf("@") !== -1 && newUserForRoleManagement.indexOf(".") !== -1 && newUserForRoleManagement.indexOf(".") !== (newUserForRoleManagement.length-1) && newUserForRoleManagement.indexOf("@") < newUserForRoleManagement.indexOf(".")) }
                   onClick={()=>{addNewUserToRoleList()}}>Add</button>
              </div>          
            </div>
            <div style={{overflowY:"auto", height:"calc(100% - 78px)", width:"100%"}}>
              {
                roleManagementList.map((value, index)=>{
                  return(
                    <div className="base-margin panel panel--raised panel--bordered" style={{borderRadius:"5px"}}>
                    <div className="row userRole-wrapper" style={{margin:"2px"}}>
                        <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-2 col-xxl-2 col-xxxl-1">
                          <div className="panel allowListOption" style={{"height":"54px", "textAlign":"left","fontSize":"medium"}}>{
                            cropText(value["user"])
                          }</div>
                        </div>
                        <div className="col-9 col-sm-9 col-md-9 col-lg-9 col-xl-10 col-xxl-10 col-xxxl-11">
                          <div style={{width:"100%"}}>
                            <MultiSelect  
                              option={roles}
                              placeholder="Select the Value"
                              selectedValues={value["roles"]}
                              onValueChange={(data) => { updateRolesToUser(index, data) }} ></MultiSelect>
                            
                          </div>
                          {userDetails["email"] !== value["user"] && <span className="icon-size-20 icon-delete" style={{position:"absolute",right:"-24px",top:"16px"}} onClick={()=>{deleteUserFromRoles(index)}}></span>}
                          {userDetails["email"] === value["user"] && <div style={{backgroundColor:"#8080803d",zIndex:"10", width:"calc(100% - 20px)",height:"100%", position:"absolute",top:"0px", left:"10px", borderRadius:"2px"}}></div>}
                        </div>
                      </div>
                  </div>
                  )

                })
              }
            </div>
            </>}
            {!isBotAdmin && <MessageBanner type="info" message="Only bot admin can access this page!"></MessageBanner>}

          </div>
          <div
            id="bordered-2-content"
            className={
              selectedTab === "allowList" ? "tab-pane active" : "tab-pane"
            }
          >
            <div className="row allowList-wrapper">
              <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-2 col-xxl-2 col-xxxl-1" style={{height:"100%", overflowY:"auto"}}>
                {
                  allowListOptions.map((value)=>{
                    return(
                      <div className={(selectedAllowListOption === value?'allowListOption-selected ':'')+"base-margin panel panel--bordered base-padding panel--raised allowListOption"}>
                        <a href="#!" onClick={()=>{setSelectedAllowListOption(value)}} style={{"cursor":"pointer"}}>{value}</a>
                      </div>
                    )
                  })
                }
              </div>
              <div className="col-9 col-sm-9 col-md-9 col-lg-9 col-xl-10 col-xxl-10 col-xxxl-11" style={{height:"100%"}}>
                <div className="base-margin panel panel--bordered base-padding panel--raised allowListSection">
                  {
                    allowListSection()
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={selectedAllowListOption !== "Externalization" && showAllowListModal} size='lg' animation={false}>
        <Modal.Header>
          <h6>Add Allow List</h6>
          <span className='icon-close icon-size-24' onClick={() => {setShowAllowListModal(false)}}></span>
        </Modal.Header>
        <Modal.Body>          
          <div className="row base-margin-bottom">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 col-xxxl-12 form-group">
              <MultiSelect
                  option={allowListValues}
                  placeholder="Select the Value"
                  onValueChange={(data) => { setSelectedAllowListOptions(data) }} />
            </div>  
          </div>
          <div className="row">
            <div className="col-9">
              <div className="row">
                <div className="col-10 col-sm-10 col-md-11 col-lg-11 col-xl-11 col-xxl-12 col-xxxl-11 form-group">
                  <div className="form-group__text">
                    <input id="input-type-email" type="text" autoComplete="off" value={inputTextValue} onChange={(e) => { setInputTextValue(e.target.value) }} hint={inputType === "user" ?"Enter user's Email Id":"Enter MyID Group"}/>
                    <label htmlFor="input-type-email">{inputType === "user" ? "User" : "My ID Group"}</label>
                  </div>
                </div>
              <div className="col-2 col-sm-2 col-md-1 col-lg-1 col-xl-1 col-xxl-1 col-xxxl-1">
                <span className="icon-add-outline icon-size-20 cursorPointer" style={{ "marginTop": "25px" }} onClick={() => { addInputToList() }}></span>
              </div>
              </div>
            </div>
            <div className="col-3">
              <div className="flexbox"><input type="radio" name="typeuser" checked={inputType === "user" ? true : false} onClick={() => { setInputType("user") }} /><span>User</span></div>
              <div className="flexbox"><input type="radio" name="typeuser" checked={inputType === "myid" ? true : false} onClick={() => { setInputType("myid") }} /><span>My ID Group</span></div>
            </div>
          </div>
          {
            (inputDataList.length > 0 && <div className="row base-margin-top">
            <DataGridComponent noToolbar={true} rows={inputDataList} columns={inputDataListCols} rowHeight={50} ht={250} checkbox={true} disableSelection={false} setSelectedExpression={(data)=>{setSelectedInputRows(data)}}/>  
            <div>
              <button className="btn btn--secondary" disabled={selectedInputRows.length <= 0} style={{zIndex:"20"}} onClick={()=>{
                setInputDataList((prev)=>{
                  let temp = [];
                  let counter = 0;
                  for(let i=0;i<prev.length;i++){
                    if(selectedInputRows.indexOf(prev[i]["id"]) === -1){
                      temp.push(prev[i]);
                      counter+=1;
                      setSelectedInputRows([]);
                    }
                  }
                  return temp;
                })
              }}>Delete</button>
              <button className="btn btn--secondary padding" onClick={()=>{addAllowList()}} disabled={selectedAllowListOptions.length === 0}>Save</button>
            </div>
          </div>)
          }

        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
      <Modal show={showAddUserModal} size='lg' animation={false}>
        <Modal.Header>
          <h6>Update Allow List</h6>
          <span className='icon-close icon-size-24' onClick={() => {setShowAddUserModal(false)}}></span>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-9">
              <div className="row">
                <div className="col-10 col-sm-10 col-md-11 col-lg-11 col-xl-11 col-xxl-12 col-xxxl-11 form-group">
                  <div className="form-group__text">
                    <input id="input-type-email" type="text" autoComplete="off" value={addUserInputTextValue} onChange={(e) => { setAddUserInputTextValue(e.target.value) }} hint={inputType === "user" ?"Enter user's Email Id":"Enter MyID Group"}/>
                    <label htmlFor="input-type-email">{inputType === "user" ? "User" : "My ID Group"}</label>
                  </div>
                </div>
              <div className="col-2 col-sm-2 col-md-1 col-lg-1 col-xl-1 col-xxl-1 col-xxxl-1">
                <span className="icon-add-outline icon-size-20 cursorPointer" style={{ "marginTop": "25px" }} onClick={() => { addUserToInputToList() }}></span>
              </div>
              </div>
            </div>
            <div className="col-3">
              <div className="flexbox"><input type="radio" name="typeuser" checked={inputType === "user" ? true : false} onClick={() => { setInputType("user") }} /><span>User</span></div>
              <div className="flexbox"><input type="radio" name="typeuser" checked={inputType === "myid" ? true : false} onClick={() => { setInputType("myid") }} /><span>My ID Group</span></div>
            </div>
          </div>
          {
            (addUserInputDataList.length > 0 && <div className="row base-margin-top">
            <DataGridComponent noToolbar={true} rows={addUserInputDataList} columns={inputDataListCols} rowHeight={50} ht={250} checkbox={true} disableSelection={false} setSelectedExpression={(data)=>{setSelectedInputRows(data)}}/>  
            <div>
              <button className="btn btn--secondary" disabled={selectedInputRows.length <= 0} style={{zIndex:"20"}} onClick={()=>{
                setAddUserInputDataList((prev)=>{
                  let temp = [];
                  let counter = 0;
                  for(let i=0;i<prev.length;i++){
                    if(selectedInputRows.indexOf(prev[i]["id"]) === -1){
                      temp.push(prev[i]);
                      counter+=1;
                      setSelectedInputRows([]);
                    }
                  }
                  return temp;
                })
              }}>Delete</button>
              <button className="btn btn--secondary padding" onClick={()=>{updateAllowList()}}>Save</button>
            </div>
          </div>)
          }

        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <Modal show={showAddExternalizationUserEmails} size='lg' animation={false}>
        <Modal.Header>
          <h6>Add Externalization List</h6>
          <span className='icon-close icon-size-24' onClick={() => {setShowAddExternalizationEmail(false)}}></span>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-10 col-sm-10 col-md-11 col-lg-11 col-xl-11 col-xxl-12 col-xxxl-11 form-group">
              <div className="form-group__text">
                <input id="input-type-email" type="text" autoComplete="off" value={externalizationInputTextValue} onChange={(e) => { setExternalizationInputTextValue(e.target.value) }} hint="Enter user's Email Id"/>
                <label htmlFor="input-type-email">Email</label>
              </div>
            </div>
            <div className="col-2 col-sm-2 col-md-1 col-lg-1 col-xl-1 col-xxl-1 col-xxxl-1">
              <span className="icon-add-outline icon-size-20 cursorPointer" style={{ "marginTop": "25px" }} onClick={() => { addExternalizationInputToList() }}></span>
            </div>
          </div>
          {
            (newExternalizationEmails.length > 0 && <div className="row base-margin-top">
            <DataGridComponent noToolbar={true} rows={newExternalizationEmails} columns={externalizationInputDataListCols} rowHeight={50} ht={250} checkbox={true} disableSelection={false} setSelectedExpression={(data)=>{setSelectedInputRows(data)}}/>  
            <div>
              <button className="btn btn--secondary" disabled={selectedInputRows.length <= 0} style={{zIndex:"20"}} onClick={()=>{
                setNewExternalizationEmails((prev)=>{
                  let temp = [];
                  let counter = 0;
                  for(let i=0;i<prev.length;i++){
                    if(selectedInputRows.indexOf(prev[i]["id"]) === -1){
                      temp.push(prev[i]);
                      counter+=1;
                      setSelectedInputRows([]);
                    }
                  }
                  return temp;
                })
              }}>Delete</button>
              <button className="btn btn--secondary padding" onClick={()=>{addExternalizationEmail()}}>Save</button>
            </div>
          </div>)
          }

        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </div>
  );
};

export default UserManagementView;
