import './APIForm.css';
import AceEditor from "react-ace";
import CodeEditor from '../code-editor/CodeEditor';
import MessageBanner from '../MessageBanner/MessageBanner';
import { useState } from 'react';
import { useEffect } from 'react';


const APIForm = ({ validatedata = () => { }, executionTrigger, executionTriggerCallBack = () => { }, changeValue = () => { }, scriptString = '', URL = '', method = '', headers = [], params = [], payload = '', showScript = false, oAuth = false, client_ID = '', client_Secret = '', o_Auth_URL = '', grant_Type = '' }) => {
    if (typeof (URL) !== "string") {
        URL = "";
    }
    const [tab_selected, switch_tab] = useState('headers');
    const apiRequestTypeList = ["GET", "POST", "PUT", "DELETE"];
    const [apiReqType, setApiRequestType] = useState(method);
    const [apiUrl, setApiUrl] = useState(URL);
    const [apiHeaderPair, setApiHeaderPair] = useState({ "key": "", "value": "" });
    const [apiParamPair, setApiParamPair] = useState({ "key": "", "value": "" });
    const [headerList, setHeaderList] = useState(/*[{"key":"Auth","value":"uua646%4232#425272hnaj"}]*/headers);
    const [paramList, setParamList] = useState(/*[{"key":"Auth","value":"uua646%4232#425272hnaj"}]*/params);
    const [payloadList, setPayloadList] = useState(payload);
    const [formatScript, setFormatScript] = useState(scriptString);
    const [show2Factor, setShow2Factor] = useState(oAuth);
    const [client_id, setClientID] = useState(client_ID);
    const [grant_type, setGrantType] = useState(grant_Type);
    const [client_secret, setClientSecret] = useState(client_Secret);
    const [oauth_url, setoAuthURL] = useState(o_Auth_URL);


    const divstyle = {
        height: 'calc(100% - 50px)',
        fallbacks: [
            { height: '-moz-calc(100% - 50px)' },
            { height: '-webkit-calc(100% - 50px)' },
            { height: '-o-calc(100% - 50px)' }
        ],
    }

    useEffect(() => {
        setApiRequestType(method)
    }, [method])

    useEffect(() => {
        setApiUrl(URL)
    }, [URL])

    useEffect(() => {
        setHeaderList(headers)
    }, [headers])

    useEffect(() => {
        setParamList(params)
    }, [params])

    useEffect(() => {
        setClientID(client_ID)
        if (client_ID) setShow2Factor('oauth2')
        else setShow2Factor('')
    }, [client_ID])

    useEffect(() => {
        setClientSecret(client_Secret)
    }, [client_Secret])

    useEffect(() => {
        setGrantType(grant_Type)
    }, [grant_Type])

    useEffect(() => {
        setoAuthURL(o_Auth_URL)
    }, [o_Auth_URL])

    useEffect(() => {
        validatedata(apiUrl);
    }, [apiUrl])

    useEffect(() => {
        changeValue('headers', headerList)
        console.log(headerList)
    }, [headerList])
    useEffect(() => {
        changeValue('params', paramList)
        console.log(paramList)
    }, [paramList])
    useEffect(() => {
        changeValue('payload', payloadList)
    }, [payloadList])

    const addKeyValePairToHeader = () => {
        if (apiHeaderPair.key != '' && apiHeaderPair.value != '') {
            setHeaderList(() => {
                let result = [apiHeaderPair];
                //looping though each row and pushing bcz state change will not get triggered is list's reference is change b/w variables
                for (let i = 0; i < headerList.length; i++) {
                    result.push(headerList[i])
                }
                setApiHeaderPair({ "key": "", "value": "" });
                return result;
            })
        }
    }

    const addKeyValePairToParams = () => {
        if (apiParamPair.key != '' && apiParamPair.value != '') {
            setParamList(() => {
                let result = [apiParamPair];
                //looping though each row and pushing bcz state change will not get triggered is list's reference is change b/w variables
                for (let i = 0; i < paramList.length; i++) {
                    result.push(paramList[i]);
                }
                setApiParamPair({ "key": "", "value": "" })

                return result;
            })
        }
    }
    const executeAPICall = async () => {
        let reqHeader = new Headers();
        if (headerList.length > 0) {
            for (let headerKeyValuePair of headerList) {
                reqHeader[headerKeyValuePair["key"]] = headerKeyValuePair["value"];
            }
        }
        console.log(reqHeader)

        // let reqParam = new Headers();
        // for (let headerKeyValuePair of headerList) {
        //     reqHeader.append(headerKeyValuePair["key"], headerKeyValuePair["value"]);
        // }

        let payload = {}
        if (payloadList.length > 0) {
            for (let payloadKeyValuePair of payloadList) {
                payload[payloadKeyValuePair["key"]] = payloadKeyValuePair["value"];
            }
        }
        let reqObject = {
            method: apiReqType,
            headers: reqHeader
        }
        if (apiReqType !== "GET" && apiReqType !== "HEAD") {
            reqObject.payload = payload;
        }
        let resposenObj = await fetch(apiUrl, reqObject)

        let responseBody = await resposenObj.json();
        let formatScriptResponse = await executeFormatScript(responseBody);

        executionTriggerCallBack(formatScriptResponse);
    }

    const executeFormatScript = async (data) => {
        console.log(data);
        console.log(formatScript);
        let reqHeader = new Headers();
        //reqHeader.append("Content-Type","application/json");

        let reqPayload = {
            method: "POST",
            headers: reqHeader,
            body: JSON.stringify({
                "script": formatScript,
                "input": data
            })

        }

        let responseObject = await fetch(process.env.REACT_APP_FORMAT_SCRIPT_LAMBDA_URL, reqPayload);
        let responseBody = await responseObject.json();

        return responseBody;
    }

    // this method is executed when save button is clicked in Parent Component
    useEffect(() => {
        executeAPICall();
    }, [executionTrigger]);

    const onChangeAce = (newvalue) => {
        setPayloadList(newvalue)
    }

    const deleteHeader = (i) => {
        console.log(i)
        let temp = headerList.filter((data, index) => index != i);
        console.log("temp", temp)
        setHeaderList(temp)

    }
    const deleteParams = (i) => {
        console.log(i)
        let temp = paramList.filter((data, index) => index != i);
        console.log("temp", temp)
        setParamList(temp)

    }

    const truncateStr = (str, length) => {
        if (str) {
            if (length > str.length) {
                return str
            }
            else {
                str = str.substring(0, length) + '...'
                return str;
            }
        }
        else {
            return '';
        }
    }



    return (
        <div style={{ overflowY: 'hidden' }}>
            <div style={{ margin: '10px' }}>
                <button className='btn btn--small btn--primary base-margin-top  base-margin-bottom' onClick={() => { if (show2Factor == '') { setShow2Factor('oauth2'); changeValue('auth_type', 'oauth2') } else { setShow2Factor(''); changeValue('auth_type', '') } }} >{show2Factor == 'oauth2' ? "Remove Authentication" : "Add Authentication"}</button>
                {show2Factor && <div className='panel panel--bordered'>
                    <div>
                        <div className='row'>
                            <div className='col'>
                                <div class="form-group base-margin-bottom">
                                    <div class="form-group__text">
                                        <input id="input-type-email" type="text" value={client_id} onInput={(e) => { setClientID(e.target.value); changeValue('client_id', e.target.value) }} />
                                        <label for="input-type-email">Client ID <span style={{ color: 'red' }}>*</span></label>
                                    </div>
                                </div>
                            </div>
                            <div className='col'>
                                <div class="form-group base-margin-bottom">
                                    <div class="form-group__text">
                                        <input id="input-type-email" type="password" value={client_secret} onInput={(e) => { setClientSecret(e.target.value); changeValue('client_secret', e.target.value) }} />
                                        <label for="input-type-email">Client Secret <span style={{ color: 'red' }}>*</span></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col'>
                                <div class="form-group">
                                    <div class="form-group__text select">
                                        <select id="select-type-basic" value={grant_type} onChange={(e) => { setGrantType(e.target.value); changeValue('grant_type', e.target.value) }}>
                                            <option value="" selected disabled>Select the Grant Type</option>
                                            <option value="authorization_code">Authorization Code</option>
                                            <option value="pkce">Proof Key for Code Exchange</option>
                                            <option value="device_code">Device Code</option>
                                            <option value="client_credentials">Client Credentials</option>
                                            <option value="refresh_token">Refresh Token</option>
                                        </select>
                                        <label for="select-type-basic">Grant Type <span style={{ color: 'red' }}>*</span></label>
                                    </div>
                                </div>
                            </div>
                            <div className='col'>
                                <div class="form-group base-margin-bottom">
                                    <div class="form-group__text">
                                        <input id="input-type-email" type="text" value={oauth_url} onInput={(e) => { setoAuthURL(e.target.value); changeValue('oauth_url', e.target.value) }} />
                                        <label for="input-type-email">OAuth URL <span style={{ color: 'red' }}>*</span></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
            </div>
            <div className='row form-group base-margin-top'>
                <div className='col-5 col-sm-5 col-md-4 col-lg-4 col-xl-3 col-xxl-2 col-xxxl-1'>
                    <div className="form-group__text select" value={apiReqType} >
                        <select id="select-api-req-type" style={{ "background": "linear-gradient(90deg, transparent calc(100% - 25px), #00bceb60 25px)" }} name="api_request_type" value={apiReqType} onChange={(e) => { setApiRequestType(e.target.value); changeValue('method', e.target.value) }} >
                            <option value="" disabled selected>Select a method</option>
                            {
                                apiRequestTypeList.map((method, index) => {
                                    return (<option value={method} key={method} >{method}</option>)
                                })
                            }
                        </select>
                        <label htmlFor="select-api-req-type">Method<span className='required' style={{ "color": "red" }}>*</span></label>
                    </div>
                </div>
                <div className='col-7 col-sm-7 col-md-8 col-lg-8 col-xl-9 col-xxl-10 col-xxxl-11'>
                    <div className="form-group__text">
                        <input id="input-api-url" type="text" autoFocus name="api_url" value={apiUrl} onInput={(e) => { setApiUrl(e.target.value); changeValue('URL', e.target.value) }} />
                        <label htmlFor="input-api-url">URL<span className='required' style={{ "color": "red" }}>*</span></label>
                    </div>
                </div>
            </div>
            <div className='row base-margin'>
                <div style={{ "width": "100%" }}>
                    <div className='api-selection-tab-wrapper'>
                        <ul className="tabs tabs--justified api-selection-tab" >
                            <li id="api-header" className={tab_selected == "headers" ? "active" : "" + "tab"} onClick={() => switch_tab("headers")}>
                                <a tabIndex="0">Headers</a>
                            </li>
                            <li id="api-payload" className={tab_selected == "params" ? "active" : "" + "tab"} onClick={() => switch_tab("params")}>
                                <a tabIndex="0">Params</a>
                            </li>
                            <li id="api-payload" className={tab_selected == "payload" ? "active" : "" + "tab"} onClick={() => switch_tab("payload")}>
                                <a tabIndex="0">Payload</a>
                            </li>
                        </ul>
                    </div>
                    <div className="tab-content base-margin-top" style={divstyle}>
                        <div id="api-header" className={tab_selected == "headers" ? "active" : "" + "tab-pane"}>
                            <div>
                                <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 col-xxxl-12'>
                                    <table className="table table--bordered" >
                                        <thead style={{ "width": "100%", "background": "#00bceb60" }}>
                                            <tr style={{ "width": "100%" }}>
                                                <th style={{ "width": "30%" }}>Key</th>
                                                <th style={{ "width": "65%" }}>Value</th>
                                                <th style={{ "width": "5%" }}>Delete</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <input className="api-table-input-box" id="api-payload-key" type="text" autoFocus name="api_payload_key" placeholder="Enter Key" value={apiHeaderPair["key"]} onInput={(e) => { setApiHeaderPair({ "key": e.target.value, "value": apiHeaderPair["value"] }) }} />
                                                </td>
                                                <td>
                                                    <input className="api-table-input-box" id="api-payload-value" type="text" autoFocus name="api_payload_value" placeholder="Enter Value" value={apiHeaderPair["value"]} onInput={(e) => { setApiHeaderPair({ "key": apiHeaderPair["key"], "value": e.target.value }) }} />
                                                </td>
                                                <td><button className='btn-icon btn btn--primary btn--small' onClick={() => { addKeyValePairToHeader() }}>
                                                    <span className="icon-add-outline"></span>
                                                </button></td>
                                            </tr>
                                            {
                                                headerList.map((row, index) =>
                                                    <tr key={index}>
                                                        <td>{row["key"]}</td>
                                                        <td>{truncateStr(row["value"], 30)}</td>
                                                        <td><button className='btn-icon btn btn--primary btn--small' onClick={() => {
                                                            deleteHeader(index)
                                                        }}><span className='icon-delete'></span></button></td>
                                                    </tr>

                                                )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                {/* <div className='col-2 col-sm-2 col-md-2 col-lg-2 col-xl-1 col-xxl-1 col-xxxl-1'>

                                </div> */}
                            </div>
                        </div>
                        <div id="api-header" className={tab_selected == "params" ? "active" : "" + "tab-pane"}>
                            <div >
                                <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 col-xxxl-12'>
                                    <table className="table table--bordered" style={{ "width": "100%" }}>
                                        <thead style={{ "width": "100%", "background": "#00bceb60" }}>
                                            <tr style={{ "width": "100%" }}>
                                                <th style={{ "width": "30%" }}>Key</th>
                                                <th style={{ "width": "65%" }}>Value</th>
                                                <th style={{ "width": "5%" }}>Delete</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <input className="api-table-input-box" id="api-payload-key" type="text" autoFocus name="api_payload_key" placeholder="Enter Key" value={apiParamPair["key"]} onInput={(e) => setApiParamPair({ "key": e.target.value, "value": apiParamPair["value"] })} />
                                                </td>
                                                <td>
                                                    <input className="api-table-input-box" id="api-payload-value" type="text" autoFocus name="api_payload_value" placeholder="Enter Value" value={apiParamPair["value"]} onInput={(e) => setApiParamPair({ "key": apiParamPair["key"], "value": e.target.value })} />
                                                </td>
                                                <td><button className='btn-icon btn btn--primary btn--small' onClick={() => { addKeyValePairToParams() }}>
                                                    <span className="icon-add-outline"></span>
                                                </button></td>
                                            </tr>
                                            {
                                                paramList.map((row, index) =>
                                                    <tr key={index}>
                                                        <td>{row["key"]}</td>
                                                        <td>{row["value"]}</td>
                                                        <td><button className='btn-icon btn btn--primary btn--small' onClick={() => {
                                                            deleteParams(index)
                                                        }}><span className='icon-delete'></span></button></td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div id="api-payload" className={tab_selected == "payload" ? "active" : "" + "tab-pane"}>
                            <div className='row' style={{padding:"0px 8px"}}>
                                <AceEditor
                                    mode="json"
                                    theme="dawn"
                                    width="100%"
                                    height="200px"
                                    onChange={onChangeAce}
                                    value={payloadList}
                                />
                                {/* <div className='col-10 col-sm-10 col-md-10 col-lg-10 col-xl-11 col-xxl-11 col-xxxl-11'>
                                    <table className="table table--bordered" style={{"width":"100%"}}>
                                        <thead style={{"width":"100%","background":"#00bceb60"}}>
                                            <tr style={{"width":"100%"}}>
                                                <th style={{"width":"30%"}}>Key</th>
                                                <th style={{"width":"70%"}}>Value</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <input className="api-table-input-box" id="api-header-key" type="text" autoFocus name="api_header_key" value={apiPayloadPair["key"]} onInput={(e)=>setApiPayloadPair({"key":e.target.value,"value":apiPayloadPair["value"]})}/>
                                                </td>
                                                <td>
                                                    <input className="api-table-input-box" id="api-header-value" type="text" autoFocus name="api_header_value" value={apiPayloadPair["value"]} onInput={(e)=>setApiPayloadPair({"key":apiPayloadPair["key"],"value":e.target.value})}/>
                                                </td>
                                            </tr>
                                            {
                                                payloadList.map((row, index) =>
                                                        <tr key={index} >
                                                            <td>{row["key"]}</td>
                                                            <td>{row["value"]}</td>
                                                        </tr>
                                                    )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <div className='col-2 col-sm-2 col-md-2 col-lg-2 col-xl-1 col-xxl-1 col-xxxl-1'>
                                    <div className='row-add-button' onClick={()=>{addKeyValePairToPayload()}}>
                                        <span className="icon-add-outline icon-size-18"></span>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showScript && <MessageBanner messageType={"info"}>
                <div className="base-margin-top base-margin-bottom">
                    Format script editor allows the users to provide the python code required to format the output of the API. The final output to be returned should be an array of json where each json must have two key value pairs:
                    <p><b>value:</b> string and <b>synonyms:</b> array of strings</p>
                    <img src={process.env.PUBLIC_URL + "/images/entity_api.png"} />
                </div>
            </MessageBanner>
            }
            {showScript && <div className='row api-format-script-editor-wrapper'>
                <CodeEditor editorLanguage="python" updateFormatScriptFromEditor={(data) => setFormatScript(data)}></CodeEditor>
            </div>}
        </div>
    )
}
export default APIForm;
