import './JumpToNode.css';
import DialogService from '../../../../dialog-service/DialogService';
import { Handle, Position, useReactFlow } from 'react-flow-renderer';
import { useEffect, useState } from 'react';
import { setDeletedNodes } from '../../../dialog-view-component/DialogViewComponent';

function JumpToNode({ id, data, xPos, yPos }) {
	const { getNodes, getEdges, setNodes, setEdges } = useReactFlow();
	const nodes = getNodes();
	const edges = getEdges();
	const [toNode, setToNode] = useState('')

	useEffect(() => {
		setToNode(data.toNode)
	}, [])

	useEffect(() => {
		updateFunction()
	})

	const updateFunction = () => {
		if (document.getElementById(`node${id}jumpToNode`))
			document.getElementById(`node${id}jumpToNode`).onclick = updateToNode
	}

	function updateToNode(event) {
		if (event.target !== document.getElementById(`node${id}jumpToNode`))
			setToNode({ label: event.target.innerText, value: event.target.innerText })
	}
	const deleteEdges = (id) => {
		let connectedEdges = []
		for (let i of edges) {
			if (i.target == id || i.source == id) {
				connectedEdges.push(i)
			}
		}

		const remainingEdges = edges.filter((edge) => !connectedEdges.includes(edge));
		const remainingNodes = nodes.filter((node) => node.id != id)

		let targetId = ''
		let getedges = {}
		let temp = []

		for (let i of connectedEdges) {
			if (i.target == id) {
				getedges = i;
			}
			if (i.source == id) {
				targetId = i['target'];
			}
		}
		if (connectedEdges.length == 2) {
			getedges['target'] = targetId
			temp = [...remainingEdges, getedges];
		}
		else {
			temp = [...remainingEdges]
		}
		console.log("temp", temp)

		const finalList = [...temp]
		if (remainingNodes.length == 1) {
			setEdges([])
		}
		else {
			setEdges(finalList)
		}


		setNodes(remainingNodes)
		console.log(remainingNodes)
		console.log(finalList)
	}

	const deleteNodeById = (id) => {
		let node_values = { id, data, position: { x: xPos, y: yPos }, type: "JumpToNode" }
		setDeletedNodes(node_values)
		DialogService.deleteNodeById(data.skill, data.rule, id).then(res => res.json()).then(res => {
			if (res.status == 'success') {
				deleteEdges(id)
			}
			else {
				if (res.details == id + " doesn't exists") {
					console.log("insidde")
					data.showConfirmation = false
					deleteEdges(id)
				}
				// setDataLoading(false);
				// setBannerMessageType('error');
				// setBannerMessage(res['details']);
				// setShowMessageBanner(true);
			}
		}).catch(err => {
			// setDataLoading(false);
			// setBannerMessageType('error');
			// setBannerMessage('Something went wrong. Please try again later!');
			// setShowMessageBanner(true);
		})
	}

	const truncateStrings = (str, length) => {
		if (str) {
			if (length > str.length) {
				return str
			}
			else {
				str = str.substring(0, length) + '...'
				return str;
			}
		}
		else {
			return '';
		}
	}

	return (
		<div className='nodeContainer jumpToNodeContainer'>
			{data.showConfirmation == true &&
				<div style={{ margin: '3px' }}>
					<div style={{ fontSize: '0.8em' }}>
						Are you sure you want to delete the Jumpto Node?
					</div>
					<div className='text-center' style={{ marginTop: '2px' }}>
						<button className="btn btn--small btn--secondary" style={{ fontSize: '0.8em' }} onClick={() => { data.showConfirmation = false; }} >
							No
						</button>
						<button className="btn  btn--small btn--secondary" style={{ fontSize: '0.8em' }} onClick={() => { deleteNodeById(id) }} >
							Yes
						</button>
					</div>
					<hr />
				</div>
			}
			<div className='node' id={`node${id}`}>
				<Handle
					type='target'
					position={Position.Top}
					id='jumpHandle'
					className="react-flow_handle"
				/>
				<div>
					<div className='nodeType'>
						Jump To
					</div>
					<div className='nodeDeleteType' >
						<span className='icon-delete' onClick={() => { console.log(id); data.showConfirmation = true }}></span>
					</div>
				</div>
				<div className='nodeName' style={{ paddingTop: '30px' }}>
					<div>{truncateStrings(data.name, 16)}</div><div><span className='icon-chevron-right-circle icon--small'></span> {toNode.label ? truncateStrings(toNode.label, 16) : ''}</div>
				</div>
				<div className='augmentsContainer' id={`jump${id}`}></div>
			</div>

		</div>
	);
}

export default JumpToNode;
