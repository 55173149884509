import React from "react";
import './BotComponent.css';

export default function Bot({ bot, onBotClick, index, show_edit_bot, selectedID, onBotDelete }) {

    let activate_bg = bot['activate'] ? "white" : "#ced4da";
    // let activate = bot['activate'] ? "black" : "#212529"

    // console.log(activate_bg)
    function truncateStrings(str, length) {
        if (length > str.length) {
            str = str + " ".repeat(length - str.length)
            return str
        }

        str = str.substring(0, length)
        return str;
    }
    function len(str) {
        return str.length
    }
    function roundToTwo(num) {
        return +(Math.round(num + "e+2") + "e-2");
    }

    const findIndex = (integrationList, integration) => {
        // console.log(integrationList.findIndex(data => data.type == integration))
        return integrationList.findIndex(data => data.type == integration)
    }
    return (

        <>
            <div className={show_edit_bot ? bot["_id"] === selectedID ? "align-items-center panel panel--well panel--bordered base-margin col-12 col-xs-12 col-sm-12 col-md-12 col-lg-5 col-xl-12 col-xxl-12 col-xxxl-12" : "align-items-center panel panel--raised panel--bordered base-margin col-12 col-xs-12 col-sm-12 col-md-12 col-lg-5 col-xl-12 col-xxl-12 col-xxxl-12" : "align-items-center panel panel--raised panel--bordered base-margin col-12 col-xs-12 col-sm-12 col-md-8 col-lg-5 col-xl-3 col-xxl-4 col-xxxl-4"} style={{ borderRadius: '3vh', height: 'fit-content', width: "calc(100% - 40px)", background: activate_bg }} key={index}>
                <div className="externalised-icon" style={{ visibility: bot['externalise'] ? 'visible' : 'hidden', float: 'right', marginTop: '15px', marginRight: '10px' }}>
                    <button className="btn btn--circle btn--ghost">
                        <span className="icon-star"></span>
                    </button>
                </div>
                {/*incase we need to delete the bot*/}
                {/* <div  >
                    <button className="btn btn--circle btn--ghost float-right" onClick={() => { onBotDelete(bot["_id"]) }}>
                        <span className="icon-delete"></span>
                    </button>
                </div> */}
                <div onClick={() => onBotClick(bot["_id"])}>
                    <div className="base-margin">
                        <div className="row">
                            <div className="col-3 col-sm-3 col-xs-3 col-md-3 col-lg-3 col-xl-3 col-2xl-3">
                                <img src={process.env.PUBLIC_URL + "/images/boticon.png"} alt="botLogo" style={{ borderRadius: '50%' }} />
                            </div>
                            <div className="col-9 col-sm-9 col-xs-9 col-md-9 col-lg-9 col-xl-9 col-2xl-9" >
                                <div className="bot-name">
                                    <h6 ><b>{truncateStrings(bot['display_name'], 25)}</b></h6>
                                </div>
                                <div className="row base-margin-top base-margin-right">
                                    <div style={{ textAlign: 'justify', wordBreak: 'keep-all', height: '70px', marginLeft: '10px' }}> {truncateStrings(bot['description'], 100)}{len(bot['description']) > 100 ? '...' : ''}</div>
                                </div>
                            </div>
                        </div>

                        <div className="base-margin-top base-margin-bottom flex flex-wrap flex-center col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 col-xxxl-12">
                            <div style={{ display: bot['skills'].length == 0 ? 'none' : 'block' }}>
                                <div className="panel panel--well panel--bordered" style={{ "width": "fit-content" }}>

                                    <div className="flex">
                                        <div style={{ "margin": "0px 5px" }}>
                                            <span className='icon-insights icon-size-24' ></span>
                                        </div>
                                        <div style={{ "margin": "0px 5px" }}>
                                            <span>{bot['skill_classifier'] == 'logreg' ? 'LR' : ''}</span>
                                        </div>
                                        <div style={{ "margin": "0px 5px" }}>
                                            <span>{roundToTwo(bot['skill_classifier_accuracy'])}%</span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div>
                                <div className="panel panel--well panel--bordered" style={{ "width": "fit-content" }}>
                                    <div className="flex">
                                        <div style={{ "margin": "0px 5px" }}>
                                            <span className='icon-communities icon-size-24' ></span>
                                        </div>
                                        <div style={{ "margin": "0px 5px" }}>
                                            <span>{bot['active_users'].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <hr />
                        {/* <br/> */}
                        <div className="col" style={{ visibility: bot['skills'].length === 0 ? 'hidden' : 'visible' }}>
                            <div className="btn btn--secondary btn--small " data-balloon-break data-balloon={bot['skills'].map(skill => skill.name).join('\n')} data-balloon-pos={show_edit_bot ? "right" : "right"} data-balloon-length="medium">
                                {bot['skills'].length} Skills
                            </div>
                        </div>
                        <div className="base-margin-left base-margin-right base-margin-bottom flex flex-wrap " style={{ position: 'absolute', right: '0', bottom: '0' }}>
                            {findIndex(bot['integrations'], "webex") != -1 && <div style={{ display: findIndex(bot['integrations'], "webex") != -1 ? 'block' : 'none' }}>
                                <div data-balloon-break data-balloon={bot['integrations'][findIndex(bot['integrations'], "webex")].username} data-balloon-pos={show_edit_bot ? "left" : "down"} data-balloon-length="medium">
                                    <img src={process.env.PUBLIC_URL + "/images/web-logos/WebexLogo.svg"} alt="Webex" style={{ borderRadius: '50%', width: '30px', height: '30px', marginLeft: '10px' }} />
                                </div>
                            </div>}
                            {findIndex(bot['integrations'], "widget") != -1 && <div style={{ display: findIndex(bot['integrations'], "widget") != -1 ? 'block' : 'none' }}>
                                <div data-balloon-break data-balloon={bot['integrations'][findIndex(bot['integrations'], "widget")].username} data-balloon-pos={show_edit_bot ? "left" : "down"} data-balloon-length="medium">
                                    <img src={process.env.PUBLIC_URL + "/images/web-logos/widget.svg"} alt="Widget" style={{ borderRadius: '50%', width: '30px', height: '30px', marginLeft: '10px' }} />
                                </div>
                            </div>}
                            {findIndex(bot['integrations'], "widget_code") != -1 && <div style={{ display: findIndex(bot['integrations'], "widget_code") != -1 ? 'block' : 'none' }}>
                                <div data-balloon-break data-balloon={bot['integrations'][findIndex(bot['integrations'], "widget_code")].username} data-balloon-pos={show_edit_bot ? "left" : "down"} data-balloon-length="medium">
                                    <img src={process.env.PUBLIC_URL + "/images/web-logos/code.svg"} alt="Widget Code" style={{ borderRadius: '50%', width: '30px', height: '30px', marginLeft: '10px' }} />
                                </div>
                            </div>}
                            {findIndex(bot['integrations'], "widget_chathandler") != -1 && <div style={{ display: findIndex(bot['integrations'], "widget_chathandler") != -1 ? 'block' : 'none' }}>
                                <div data-balloon-break data-balloon={bot['integrations'][findIndex(bot['integrations'], "widget_chathandler")].username} data-balloon-pos={show_edit_bot ? "left" : "down"} data-balloon-length="medium">
                                    <img src={process.env.PUBLIC_URL + "/images/web-logos/chathandler.svg"} alt="Widget Chathandler" style={{ borderRadius: '50%', width: '30px', height: '30px', marginLeft: '10px' }} />
                                </div>
                            </div>}
                            {/* <div style={{ display: bot['integrations'].hasOwnProperty("widget_chathandler") ? 'block' : 'none' }}>
                                <img src={process.env.PUBLIC_URL + "/images/web-logos/SlackLogo.svg"} alt="slackLogo" style={{ borderRadius: '50%', width: '30px', height: '30px', marginLeft: '10px' }} />
                            </div>
                            <div style={{ display: bot['integrations'].hasOwnProperty("widget") ? 'block' : 'none' }}>
                                <img src={process.env.PUBLIC_URL + "/images/web-logos/SlackLogo.svg"} alt="slackLogo" style={{ borderRadius: '50%', width: '30px', height: '30px', marginLeft: '10px' }} />
                            </div> */}
                        </div>


                    </div>
                </div>
            </div>
        </>
    );
}