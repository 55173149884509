import Multiselect from 'multiselect-react-dropdown';
import React,{useState,useEffect} from 'react'; 
import './MultiSelect.css';

export const MultiSelect=({option, placeholder, selectedValues,onValueChange})=>{
    const [selectedSkills,setSkillsSelected] = useState([]);
   
    function addSkillsSelected(){
        let values = document.getElementById("selected-skills").value;
        document.getElementById("selected-skills").value=[];

        setSkillsSelected(()=>{
            let result = []
            if(selectedValues !== undefined){
                for(let value of selectedValues){
                    result.push(value);
                }
            }

            if(values !== undefined){
                for(let value of values){
                    result.push(value);
                }
            }

            return result;
        });
    }

    function onSelect(selectedList, selectedItem) {
 
        setSkillsSelected(()=>{
            let temp = [];
            for(let i=0;i<selectedSkills.length;i++){
                console.log(selectedSkills[i])
                temp.push(selectedSkills[i]);
            }
            temp.push(selectedItem);
            onValueChange(temp);
            return temp;
        });
       
    }
    
    function onRemove(selectedList, removedItem) {
        setSkillsSelected(()=>{
            let result = [];
            for(let value of selectedList){
                if(value["id"] !== removedItem["id"]){
                    result.push(value)
                }
            }
            onValueChange(result);
            return result;
        });   
    }
    useEffect(()=>{
        addSkillsSelected();
    },[selectedValues])

    return  <>
        <Multiselect
            id="selected-skills"
            displayValue="name"
            options={option}
            onRemove={onRemove}
            onSelect={onSelect}
            placeholder = {placeholder}
            selectedValues={selectedSkills}
            />
    </>
}


export default MultiSelect;