const root_url = process.env.REACT_APP_ROOT_URL;

const SkillVersionService = {
	
    createSkillVersion: (skill_id, data) => {
        var reqHeaders = new Headers();
        reqHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'POST',
            headers: reqHeaders,
            body: JSON.stringify(data)
        };

        return fetch(`${root_url}/skill/${skill_id}/skill_version`, requestOptions)

    },
	
	revertSkillVersion: (skill_id, data) => {
        var reqHeaders = new Headers();
        reqHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'POST',
            headers: reqHeaders,
            body: JSON.stringify(data)
        };

        return fetch(`${root_url}/skill/${skill_id}/skill_revert`, requestOptions)

    },

    getSkillVersion: (skill_id, versionId) => {
        var reqHeaders = new Headers();
        reqHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'GET',
            body: {}
        };

        return fetch(`${root_url}/skill_version/${versionId}`, requestOptions);

    },

    listSkillVersions: () => {
        var reqHeaders = new Headers();
        reqHeaders.append("Content-Type", "application/json");
        var requestOptions = {
            method: 'GET',
            headers: reqHeaders
        };

        return fetch(`${root_url}/skill_version`, requestOptions);
    },

    updateSkillVersion: (skill_id, data) => {
        var reqHeaders = new Headers();
        reqHeaders.append("Content-Type", "application/json");
        console.log(data);
        var requestOptions = {
            method: 'PUT',
            headers: reqHeaders,
            body: JSON.stringify(data)
        };

        return fetch(`${root_url}/skill/${skill_id}/skill_version`, requestOptions);
    },
	
	deleteSkillVersion: (skill_id, versionId) => {
		var reqHeaders = new Headers();
        reqHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'DELETE',
            headers: reqHeaders
        };
        
        return fetch(`${root_url}/skill/${skill_id}/skill_version/${versionId}`, requestOptions);

    }
}

export default SkillVersionService;