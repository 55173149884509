import React, { useState, useEffect } from "react";
import "./ScheduleViewComponent.css";
import Spinner from "../../../../custom-components/spinner/Spinner";
import Modal from "react-bootstrap/Modal";
import MessageBanner from "../../../../custom-components/MessageBanner/MessageBanner";
import DataGridComponent from "../../../../custom-components/data-grid/DataGrid";
import MultiSelect from "../../../../custom-components/multi-select/MultiSelect";
import SearchSelect from "../../../../custom-components/search-select/SearchSelect";
import BotService from "../../../bot-module/bot-services/BotService";
import ScheduleService from "../../schedule-service/ScheduleService";
import DialogService from "../../../dialog-module/dialog-service/DialogService";
import ScheduleForm from "../../../../custom-components/schedule-form/ScheduleForm";

const ScheduleView = () => {
  const [isDataLoading, setDataLoading] = useState(false);
  const [showMessageBanner, setShowMessageBanner] = useState(false);
  const [bannerMessage, setBannerMessage] = useState('');
  const [bannerMessageType, setBannerMessageType] = useState('success');
  const [selectedTab, setSelectedTab] = useState(1);
  const [schedule_details, setScheduleDetails] = useState()
  const [bot, setBot] = useState();
  const [bots, setBotList] = useState([]);
  const [skill, setSkill] = useState();
  const [skills, setSkillList] = useState([]);
  const [action, setAction] = useState();
  const [actions, setActions] = useState([]);

  const getBotSkillList = (data) => {
    let temp = []
    for (let i of data) {
      temp.push({ label: i.name, value: i.id })
    }
    return temp
  }
  const getBotList = () => {
    setDataLoading(true);
    setShowMessageBanner(false);
    setBannerMessage('');
    setBannerMessageType('success');

    BotService.listBots().then((res) => res.json()).then((res) => {
      console.log('inside')
      if (res['status'] == 'success') {
        let bot_list = []
        for (let bot of res["bots"]) {
          bot_list.push({ label: bot["display_name"], value: bot["_id"], skills: getBotSkillList(bot['skills']) });
        }

        setBotList(bot_list);
        console.log('bot_list', bot_list)
        if (bot_list.length != 0) {
          setBot(bot_list[0])
          console.log("skills", bot_list[0]['skills'])
          if (bot_list[0]['skills'].length != 0) {
            console.log('inside 2')
            setSkillList(bot_list[0]['skills'])
            setSkill(bot_list[0]['skills'][0])
            getActions(bot_list[0]['skills'][0])
          }
          else {
            setActions([])
            setAction({ label: 'Select an action', value: 'default' })
            setSkillList([])
            setSkill({ label: 'Select a skill', value: 'default' })
          }

        }
        else {
          setBot({ label: 'Select a Bot', value: 'default' })
          setActions([])
          setAction({ label: 'Select an action', value: 'default' })
          setSkillList([])
          setSkill({ label: 'Select a skill', value: 'default' })
        }

        setDataLoading(false);

      }
      else {
        setDataLoading(false);
        setBannerMessageType("error");
        setBannerMessage(res['details']);
        setShowMessageBanner(true);
      }
    })
      .catch((err) => {
        console.log('inside catch')
        setDataLoading(false);
        setBannerMessageType("error");
        setBannerMessage("Failed to retrieve bots!");
        setShowMessageBanner(true);
      });
  }
  useEffect(() => {
    getBotList();
  }, []);

  const getActions = (skill) => {
    if (skill.id != 'default') {
      setDataLoading(true);
      setShowMessageBanner(false);
      setBannerMessage('');
      setBannerMessageType('success');
      DialogService.getActions(skill.value).then(res => res.json()).then(res => {
        if (res.status == 'success') {
          setDataLoading(false);
          if (res.actions.length == 0) {
            setAction({ label: 'Select an Action', value: 'default' })
            setActions([])
          }
          else {
            let temp = res.actions;
            let action_list = []
            for (let a of temp) {
              action_list.push({ label: a.name, value: a.id })
            }

            setActions(action_list)
            if (action_list != []) {
              setAction(action_list[0])
              // getScheduleDetails(action_list[0]);
            }
            else {
              setAction({ label: 'Select an Action', value: 'default' })
            }

          }
        }
        else {
          setDataLoading(false);
          setBannerMessageType('error');
          setBannerMessage(res['details']);
          setShowMessageBanner(true);
        }
      }).catch((err) => {
        setDataLoading(false);
        setBannerMessageType("error");
        setBannerMessage("Failed to retrieve rules!");
        setShowMessageBanner(true);
      });
    }
    else {
      setAction({ label: 'Select an Action', value: 'default' })
      setActions([])
    }
  }



  const BotList = () => {
    return (
      <SearchSelect
        id="botList"
        className="augmentSelect"
        defaultValue={bot}
        options={bots}
        onValueChange={(data) => {
          console.log("Data Bot", data.skills)
          setBot(data);

          if (data.skills.length != 0) {
            console.log("Data Skill", data.skills[0])
            setSkill(data.skills[0])
            getActions(data.skills[0])
            setSkillList(data.skills)
          }
          else {
            setSkillList([])
            setSkill({ label: 'Select a Skill', value: 'default' })
            setActions([])
            setAction({ label: 'Select an Action', value: 'default' })
          }
          // if()
          // setSkill(data.skills[0])
        }}
      />
    );
  };

  const SkillList = () => {
    return (
      <SearchSelect
        id="botList"
        className="augmentSelect"
        defaultValue={skill}
        options={skills}
        onValueChange={(data) => {
          console.log("Data", data)
          setSkill(data);
          getActions(data)
        }}
      />
    );
  };
  // const getScheduleDetails = (data) => {
  //   setDataLoading(true);
  //   setBannerMessage('')
  //   setBannerMessageType('success')
  //   setShowMessageBanner(false);
  //   ScheduleService.getScheduledAction(skill.value, data.value).then(res => res.json()).then(res => {
  //     if (res['status'] == 'success') {
  //       setDataLoading(false);
  //       setScheduleDetails(res['result'])
  //     }
  //     else {
  //       setDataLoading(false);
  //       setBannerMessage(res['details'])
  //       setBannerMessageType('error')
  //       setShowMessageBanner(true);
  //     }
  //   }).catch(err => {
  //     setDataLoading(false);
  //     setBannerMessage("Failed to retrieve schedule details. Please try again later!")
  //     setBannerMessageType('error')
  //     setShowMessageBanner(true);
  //   })

  // }
  const ActionList = () => {
    return (
      <SearchSelect
        id="botList"
        className="augmentSelect"
        defaultValue={action}
        options={actions}
        onValueChange={(data) => {
          setAction(data);
          // getScheduleDetails(data);
        }}
      />
    );
  };

  const getScheduleTabClass = (id) => {
    if (id === selectedTab) {
      return "tab active";
    } else {
      return "tab";
    }
  };





  return (
    <>
      {isDataLoading && <Spinner></Spinner>}
      <div className="row base-margin-top" style={{ marginLeft: '0.2vw', marginRight: '0.2vw' }}>
        <ul id="skill_Tabs" className="tabs tabs--embossed tabs--justified tabs--bordered col-9">
          {
            <>
              {/* <li id="my_skills_tab" onClick={() => setSelectedTab(0)} className={getScheduleTabClass(0)}>
                <a tabIndex="0" className="pageTour3">Schedule Training</a>
              </li> */}
              <li id="shared_skills_tab" onClick={() => setSelectedTab(1)} className={getScheduleTabClass(1)}>
                <a tabIndex="0" className="pageTour4">Schedule Action</a>
              </li>
            </>

          }
        </ul>
        <div className="col-3" style={{ borderBottom: '1px solid #dee2e6' }}>
          {BotList()}
        </div>
      </div>

      {
        showMessageBanner && <MessageBanner message={bannerMessage} messageType={bannerMessageType} onCloseClicked={() => { setShowMessageBanner(false) }}></MessageBanner>
      }

      <div id="bordered-content" className="tab-content">
        {/* {selectedTab == 0 && <div id="my_skills_tab-content" className="base-padding" style={{ maxHeight: '70vh' }}>
          <div className="center " style={{ border: '1px solid rgb(222, 226, 230)', padding: '20px' }}>
            <ScheduleForm type={'training'} />
          </div>
        </div>} */}

        {selectedTab == 1 && <div id="schedule_tab-content" style={{ maxHeight: '70vh' }}>
          <div className="row base-margin-top">
            <div className="col">
              {SkillList()}
            </div>
            <div className="col">
              {ActionList()}
            </div>
            <div className="col">
            </div>
          </div>
          <hr />
          <br />
          <div className="col-10 center " style={{ border: '1px solid rgb(222, 226, 230)', padding: '20px' }}>

            <ScheduleForm type={'actionType'} skill={skill} action={action} bot={bot} />
          </div>
        </div>}

      </div>
    </>
  );
};

export default ScheduleView;
