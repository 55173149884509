const intents_url = process.env.REACT_APP_ROOT_URL
const IntentService = {

    addIntent: (data, skill) => {
        var reqHeaders = new Headers();
        reqHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'POST',
            headers: reqHeaders,
            body: JSON.stringify(data)
        };

        return fetch(`${intents_url}/skill/${skill}/intent`, requestOptions)

    },

    getIntent: (intentId, skill) => {

        var reqHeaders = new Headers();
        reqHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'GET',
            body: {}
        };

        return fetch(`${intents_url}/skill/${skill}/intent/${intentId}`, requestOptions);

    },

    // getIntentBySkill: (skill) => {
    //     var reqHeaders = new Headers();
    //     reqHeaders.append("Content-Type", "application/json");
    //     var requestOptions = {
    //         method: 'GET',
    //         headers: reqHeaders
    //     };

    //     return fetch(`${intents_url}/skill/${skill}`, requestOptions);

    // },

    listIntents: (skill) => {

        var reqHeaders = new Headers();
        reqHeaders.append("Content-Type", "application/json");
        var requestOptions = {
            method: 'GET',
            headers: reqHeaders
        };

        return fetch(`${intents_url}/skill/${skill}/intent`, requestOptions);
    },

    updateIntent: (data, skill) => {
        var reqHeaders = new Headers();
        reqHeaders.append("Content-Type", "application/json");
        console.log(data);
        var requestOptions = {
            method: 'PUT',
            headers: reqHeaders,
            body: JSON.stringify(data)
        };

        return fetch(`${intents_url}/skill/${skill}/intent`, requestOptions);
    },

    deleteIntent: (id, skill) => {
        var reqHeaders = new Headers();
        reqHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'DELETE',
            headers: reqHeaders
        };

        return fetch(`${intents_url}/skill/${skill}/intent/${id}`, requestOptions);
    },

    deleteIntents: (skill, intentList) => {
        var reqHeaders = new Headers();
        reqHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'DELETE',
            headers: reqHeaders,
            body: JSON.stringify(intentList)
        };

        return fetch(`${intents_url}/skill/${skill}/intent`, requestOptions);
    },


    addAnnotations: (data, skillId, intentId) => {
        var reqHeaders = new Headers();
        reqHeaders.append("Content-Type", "application/json");
        console.log(data);
        var requestOptions = {
            method: 'PUT',
            headers: reqHeaders,
            body: JSON.stringify(data)
        };

        return fetch(`${intents_url}/skill/${skillId}/intent/${intentId}/expression`, requestOptions);
    },
    addIntentExpressions: (skill, data) => {
        // let url = 'https://alkppcs4f8.execute-api.us-east-1.amazonaws.com/Prod/api/skill'
        var reqHeaders = new Headers();
        reqHeaders.append("Content-Type", "application/json");
        var requestOptions = {
            method: 'POST',
            headers: reqHeaders,
            body: JSON.stringify(data)
        };
        return fetch(`${intents_url}/skill/${skill}/intentexpression`, requestOptions);
    }
}

export default IntentService;